<app-modal-report (statusChange)='getReports()' #reportModal [admin]='true'></app-modal-report>
<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Signalements</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                          <label  class="mb-2">Signalements archivés</label>

                          <div class="">
                            <div class="switch">
                              <label>

                                <input [(ngModel)]='archived' (ngModelChange)='getReports()' type="checkbox">
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                          <table

                            id="tableSort"
                            mdbTable
                            #tableEl="mdbTable"
                            class="display custom-table mt-5"

                          >
                            <thead>
                            <tr>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="reports" sortBy="date">
                                Date <fa-icon [icon]="faSort"></fa-icon>
                              </th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="reports" sortBy="plaignant.nom">
                                Plaignant <fa-icon [icon]="faSort"></fa-icon>
                              </th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="reports" sortBy="plaignantRole">
                                Rôle plaignant <fa-icon [icon]="faSort"></fa-icon>
                              </th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="reports" sortBy="plaignantNb">
                                Nombre de signalements <fa-icon [icon]="faSort"></fa-icon>
                              </th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="reports" sortBy="accuse.nom">
                                Accusé <fa-icon [icon]="faSort"></fa-icon>
                              </th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="reports" sortBy="accuseRole">
                                Rôle accusé <fa-icon [icon]="faSort"></fa-icon>
                              </th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="reports" sortBy="accuseNb">
                                Nombre de signalements <fa-icon [icon]="faSort"></fa-icon>
                              </th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="reports" sortBy="status">
                                Statut <fa-icon [icon]="faSort"></fa-icon>
                              </th>
                              <th aria-controls="tableSortExample" scope="col" >
                                Actions
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngIf="reports.length === 0">
                              Aucun signalement
                            </tr>
                            <tr *ngFor="let r of reports; let i = index">
                              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                {{ r.date | timestamp }}
                              </td>
                              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                <a *ngIf='r.plaignant'
                                  style='text-decoration: underline !important;'
                                  target="_blank"
                                  [routerLink]="'/administration/' + r.plaignantRole + '/' + r.plaignant._id"
                                >{{ r.plaignant.nom }} {{ r.plaignant.prenom }}</a
                                >
                                <span *ngIf='!r.plaignant'>Compte supprimé</span>
                              </td>
                              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                {{ r.plaignantRole | firstUpperText }}
                              </td>
                              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                {{ r.plaignantNb }}
                              </td>
                              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">

                                <a
                                  *ngIf='r.accuse'
                                  style='text-decoration: underline !important;'
                                  target="_blank"
                                  [routerLink]="'/administration/' + r.accuseRole + '/' + r.accuse._id"
                                > {{ r.accuse.nom }} {{ r.accuse.prenom }} </a>
                                <span *ngIf='!r.accuse'>Compte supprimé</span>
                              </td>
                              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                {{ r.accuseRole | firstUpperText }}
                              </td>
                              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                {{ r.accuseNb }}
                              </td>
                              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                 <span *ngIf="!r.status || r.status === '' || r.status === null || r.status === 'a-traiter'"
                                 ><h6>
                                            <span class="badge badge-danger badge-pill text-white">A traiter</span>
                                        </h6></span
                                 >
                                <span *ngIf="r.status === 'en-cours'"
                                ><h6>
                                            <span class="badge badge-warning badge-pill text-white">En cours</span>
                                        </h6></span
                                >
                                <span *ngIf="r.status === 'traite'"
                                ><h6>
                                            <span class="badge badge-success badge-pill text-white">Traité</span>
                                        </h6></span
                                >
                              </td>
                              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                <a
                                  style="color: white"
                                  target="_blank"
                                  class="btn btn-info"
                                  (click)='loadReport(r._id)'
                                >Voir</a
                                >
                                <a
                                  style="color: white"
                                  target="_blank"
                                  (click)='archive(r._id, r.note)'

                                  class="btn btn-warning ml-2"
                                > {{ archived ? 'Dé-archiver' : 'Archiver'}}</a
                                >
                              </td>
                            </tr>
                            </tbody>
                            <tfoot class="grey lighten-5 w-100">
                            <tr>
                              <td [colSpan]="9">
                                <mdb-table-pagination ofKeyword="de" [tableEl]="tableEl" [searchDataSource]="reports"></mdb-table-pagination>
                              </td>
                            </tr>
                            <tr>
                              <td [colSpan]="9" style="border-top: none !important; border-bottom: none !important">
                                <mdb-select-2
                                  placeholder="Afficher 10/ page"
                                  (selected)="changeItemsByPage($event)"
                                  style="float: right; min-width: 174px"
                                  name="itemsByPage"
                                  [outline]="true"
                                >
                                  <mdb-select-option *ngFor="let option of displayByPages" [value]="option.value">{{
                                    option.label
                                    }}</mdb-select-option>
                                </mdb-select-2>
                              </td>
                            </tr>
                            </tfoot>
                          </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
