import { Component, OnInit } from '@angular/core';
import { faCheckCircle, faEye, faEyeSlash, faTimes, faFilePdf, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-informations-personnelles',
    templateUrl: './informations-personnelles.component.html',
    styleUrls: [
        './informations-personnelles.component.css',
        './../../../../assets/css/inscription/style.css',
        './../../../../assets/css/administration/space.min.css',
    ],
})
export class InformationsPersonnellesComponent implements OnInit {
    faArrowRight = faArrowRight;

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private router: Router
    ) {}

    ngOnInit(): void {}
}
