<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Multilingue</h3>
        </div>
        <div class="row">
            <div class="col-md-4">
                <button
                    class="btn"
                    (click)="changerChoix('Accueil')"
                    [ngClass]="{
                        'btn-success': page == 'Accueil',
                        'btn-danger': page != 'Accueil'
                    }"
                >
                    Accueil
                </button>
            </div>
            <div class="col-md-4">
                <button
                    class="btn"
                    (click)="changerChoix('Accueil Candidat')"
                    [ngClass]="{
                        'btn-success': page == 'Accueil Candidat',
                        'btn-danger': page != 'Accueil Candidat'
                    }"
                >
                    Accueil Candidat
                </button>
            </div>
            <div class="col-md-4">
                <button
                    class="btn"
                    (click)="changerChoix('Accueil Recruteur')"
                    [ngClass]="{
                        'btn-success': page == 'Accueil Recruteur',
                        'btn-danger': page != 'Accueil Recruteur'
                    }"
                >
                    Accueil Recruteur
                </button>
            </div>
        </div>
        <div>
            <div class="row espace" *ngFor="let t of text">
                <div class="col-6">
                    <textarea class="form-control" [(ngModel)]="t.fr" rows="6"> </textarea>
                </div>
                <div class="col-6">
                    <button class="form-control btn btn-success" (click)="updateTexte(t._id)">Modifier</button>
                </div>
            </div>
        </div>
    </div>
</div>
