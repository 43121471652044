<div
  mdbModal
  #offerModal='mdbModal'
  class='modal fade'
  tabindex='-1'
  role='dialog'
  aria-labelledby='myBasicModalLabel'
  aria-hidden='true'
  style='overflow: auto'
>
  <div class='modal-dialog modal-full-width' role='document' *ngIf="(offer | json) != '{}'">
    <div class='modal-content'>
      <div class='modal-header'>
        <button type='button' class='close pull-right' aria-label='Close' (click)='offerModal.hide()'>
          <span aria-hidden='true'>×</span>
        </button>
        <h4 class='modal-title w-100' id='myModalLabel'>Fiche d'offre</h4>
      </div>
      <div class='modal-body'>
        <div [hidden]='!loading'>
          <div  class="d-flex mt-4 ml-auto mr-auto justify-content-center text-center spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div [hidden]='loading' class='mt-4 mb-4 animate__animated animate__fadeIn animate__fast'>
          <div class='container-fluid'>
          <div class='row'>
            <div class='col-4 col-md-2' style='z-index: 3'>
              <div class='offer-picture'>
                <img
                  style='object-fit: cover; width: 100px; height: 100px'
                  class='img-preload rounded-circle img-fluid'
                  [src]="!offer.entreprise.logo ? 'https://www.1min30.com/wp-content/uploads/2018/05/Couleur-logo-P%C3%B4le-Emploi.jpg' : offer.entreprise.logo"
                />
              </div>
            </div>
            <div class='col-8 col-md-5'>
              <div class='center-text'>
                                                <span class='offer-name'>
                                                   {{ offer.entreprise.nom }} </span
                                                ><br />
                <span>
                                                    Adresse postale :
                  {{ offer.lieuTravail.libelle }}
                  {{ offer.lieuTravail.codePostal ? '( ' + offer.lieuTravail.codePostal + ' )' : '' }}</span
                ><br />
                <span>
                                                    Pays de résidence : </span
                >
                <div class='d-flex flex-wrap'>
                  <h6 class='mr-2'>
                    <span class='badge badge-warning badge-pill text-white'>{{offer.typeContrat}}</span>
                  </h6>
                  <h6 class='mr-2'>
                    <span class='badge badge-success badge-pill text-white'>{{offer.dureeTravailLibelle}}</span>
                  </h6>
                  <h6 class='mr-2'>
                    <span class='badge badge-info badge-pill text-white'>{{offer.dureeTravailLibelleConverti}}</span>
                  </h6>
                  <h6 class='' *ngIf='offer.experienceLibelle'>
                    <span class='badge badge-dark badge-pill text-white'>{{offer.experienceLibelle && !offer.experienceLibelle.includes("Expérience") ? 'Experience :' : ''}} {{offer.experienceLibelle}}</span>
                  </h6>

                </div>
                <h6>
                  <span style='white-space: normal' class='badge badge-primary badge-pill text-white'>{{offer.salaire.libelle}}</span>
                </h6>
              </div>
            </div>
            <div class='col-12 mt-5 mt-md-0 col-md-5'>
              <div class='center-text' style='word-break: break-word'>
                <h3 class='d-none d-md-block font-weight-bold offer-poste'>
                  Poste : <br />{{ offer.intitule }}
                </h3>
                <h3 class='d-sm-block d-md-none font-weight-bold offer-poste'>
                  Poste : {{ offer.intitule }}
                </h3>
              </div>
            </div>
            <div class='mt-4 col-12 d-flex flex-wrap'>
              <h6 class='ml-2' *ngFor='let comp of offer.competences'>
                <span style='white-space: normal' class='badge badge-secondary badge-pill text-white'> {{comp.libelle}}</span>
              </h6>

            </div>
            <div class='col-12'>
              <hr class='mt-4 mb-3'>
              <p class='offer-description'> {{offer.description }}</p>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class='modal-footer'>
        <a
          type='button'
          mdbBtn
          [rounded]='true'
          color='danger'
          style='color: white'
          class='waves-light'
          target='_blank'
          aria-label='Signaler'
          [href]='urlPostulation'
          mdbWavesEffect
        >
          Postuler
        </a>
        <button
          type='button'
          mdbBtn
          [rounded]='true'
          color='warning'
          class='waves-light'
          aria-label='Fermer'
          (click)='offerModal.hide()'
          mdbWavesEffect
        >
          Fermer
        </button>
      </div>
    </div>
  </div>
</div>
