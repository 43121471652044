<app-site-header></app-site-header>
<app-modal-recruiter [recruteur]='recruteur' #recruteurModal></app-modal-recruiter>
<div
  mdbModal
  #newCartModal="mdbModal"
  class="modal fade"
  id="basicExample"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="back()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-9">
            <p>Vous êtes sur le point de créer un panier.</p>
            <p>Avant de commencer, veuillez donner un nom à votre panier (qui ne sera pas communiqué aux recruteurs).</p>
          </div>
          <div class="col-3">
            <p></p>
            <p class="text-center">
              <fa-icon [icon]="faShoppingCart" class="fa-4x inputs-color"></fa-icon>
            </p>
          </div>
          <div class="col-12">
            <div class="custom-search">
              <input
                type="text"
                class="form-control custom-search-input"
                [(ngModel)]="this.nom"
                placeholder="Nom du panier"
              />
              <button (click)="ajouter()" class="btn btn-pink custom-search-botton">Ajouter</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-header"></div>
    </div>
  </div>
</div>
<div class="row" style="width: 100%; margin: 0">
  <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
    <app-candidat-header></app-candidat-header>
  </div>
  <div class="main-page-layout col-xl-10 col-xs-10 pl-0 pr-0 pt-0 pt-md-4">
    <a href="espace-candidat/mes-selections" class="text-dark d-none d-md-block" style="text-decoration: underline">
      <fa-icon [icon]="faArrowLeft" class="ml-2"></fa-icon> Retour
    </a>
    <div class="page-inner">
      <div class="page-title">
        <div class="row d-md-none mr-0">
          <div class="col-12 pl-0 pr-0">
            <h2 class="breadcrumb-header">
              <a href="espace-candidat/mes-selections" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                ><fa-icon [icon]="faArrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
              ><span>Consultation panier {{ this.nom }}</span>
            </h2>
          </div>
        </div>
        <h3 class="breadcrumb-header d-none d-md-block">Consultation panier {{ this.nom }}</h3>
      </div>

      <div *ngIf="isMobile">
        <div class="panier-wrapper-mobile">
          <div [hidden]="showFiltersMobile" [class]="!showFiltersMobile ? 'animate__animated animate__fadeIn animate__fast' : ''">
            <div class='md-form'>
              <app-filter-secteur (refresh)='getRecruteur()' [(data)]='recherche.secteurs' [label]="'Secteurs d\'activités'">
              </app-filter-secteur>
            </div>

<!--            <div class="md-form">-->
<!--              <input-->
<!--                type="text"-->
<!--                name="motcles"-->
<!--                id="motcles"-->
<!--                class="form-control bc-white mb-0"-->
<!--                [(ngModel)]="recherche.motCles"-->
<!--                placeholder="Mots-clés : secteur d'activité…"-->
<!--              />-->
<!--            </div>-->
            <!--                        <div class="md-form">-->
            <!--                            <input-->
            <!--                                type="text"-->
            <!--                                name="metiersRechercher"-->
            <!--                                class="completer-input form-control mdb-autocomplete mb-0 bc-white"-->
            <!--                                [ngModel]="searchMetiers | async"-->
            <!--                                (ngModelChange)="searchMetiers.next($event)"-->
            <!--                                [mdbAutoCompleter]="autoMetier"-->
            <!--                                (clearBtnClicked)="clearMetier()"-->
            <!--                                placeholder="Rechercher un métier"-->
            <!--                            />-->
            <!--                            <mdb-auto-completer class="visible" #autoMetier="mdbAutoCompleter" textNoResults="Pas de résultat">-->
            <!--                                <mdb-option-->
            <!--                                    *ngFor="let option of resultsMetiers | async"-->
            <!--                                    [value]="option.nomMetier"-->
            <!--                                    (click)="chooseMetiers(option.codeOgr, option.nomMetier)"-->
            <!--                                >-->
            <!--                                    <div class="d-flex flex-column">-->
            <!--                                        <strong>{{ option.nomMetier }}</strong>-->
            <!--                                    </div>-->
            <!--                                </mdb-option>-->
            <!--                            </mdb-auto-completer>-->
            <!--                        </div>-->

            <mdb-select-2
              placeholder="Pays"
              [notFoundMsg]="'Pays non trouvé'"
              [(ngModel)]="recherche.paysCode"
              name="paysCode"
              [outline]="true"
            >
              <mdb-select-filter class="bc-white" [ngModel]="searchPays | async" (inputChange)="searchPays.next($event)">
              </mdb-select-filter>
              <mdb-select-option
                *ngFor="let option of filteredOptions | async"
                [value]="option.code"
                (click)="choosePays(option.pays, option.code)"
              >{{ option.pays }}</mdb-select-option
              >
            </mdb-select-2>

            <app-select-city [ville]="recherche"></app-select-city>

            <div class="">
              <label for="range" class="mb-2">Rayon</label>

              <div class="d-flex">
                <span class="font-weight-bold indigo-text mr-2">{{ min }}</span>
                <form class="w-100">
                  <mdb-range-input
                    class=""
                    id="range"
                    name="range"
                    [min]="min"
                    [(ngModel)]="rayon"
                    [max]="max"
                  ></mdb-range-input>
                </form>
                <span class="font-weight-bold indigo-text ml-2">{{ max }}</span>
              </div>
            </div>

            <br>
            <div class="">
              <label for="range" class="mb-2 d-flex justify-content-center">Masquer les recruteurs déjà contactés</label>

              <div class="d-flex justify-content-center">
                <div class="switch">
                  <label>
                    Non
                    <input [(ngModel)]='hideAlreadyContacted' (ngModelChange)='getRecruteur()' type="checkbox">
                    <span class="lever"></span> Oui
                  </label>
                </div>
              </div>
            </div>

            <div class="md-form pt-2">
              <button
                (click)="getRecruteur(); showFiltersMobile = true"
                [rounded]="true"
                mdbBtn
                [block]="true"
                type="button"
                color="warning"
                size="lg"
                mdbWavesEffect
              >
                <fa-icon [icon]="faSearch" class="mr-1"></fa-icon>Rechercher
              </button>
            </div>
          </div>

          <div [hidden]="!showFiltersMobile" [class]="showFiltersMobile ? 'animate__animated animate__fadeIn animate__fast' : ''">
            <div class="md-form">
              <button
                (click)="showFiltersMobile = false"
                [rounded]="true"
                mdbBtn
                [block]="true"
                type="button"
                color="warning"
                size="lg"
                mdbWavesEffect
              >
                <fa-icon [icon]="faArrowLeft" class="mr-1"></fa-icon>Retour à la recherche
              </button>
            </div>
            <div class="row mr-0">
              <div class="col-6">
                <mdb-select-2
                  placeholder="Type d'organisme"
                  name="typesEntreprise"
                  [(ngModel)]="recherche.typeEntreprise"
                  (ngModelChange)="getRecruteur()"
                  class="bottom-select"
                  [multiple]="true"
                  [outline]="true"
                >
                  <mdb-select-option *ngFor="let option of typesEntreprises" [value]="option.value">{{
                    option.label
                    }}</mdb-select-option>
                </mdb-select-2>
              </div>
              <div class="col-6">
                <mdb-select-2
                  placeholder="Nombre de salariés"
                  name="workersNumbers"
                  [(ngModel)]="recherche.nombreSalarie"
                  (ngModelChange)="getRecruteur()"
                  class="bottom-select"
                  [multiple]="true"
                  [outline]="true"
                >
                  <mdb-select-option *ngFor="let option of workersNumbers" [value]="option.value">{{
                    option.label
                    }}</mdb-select-option>
                </mdb-select-2>
              </div>
              <div class="col-6">
                <mdb-select-2
                  placeholder="Activité(s) spécifique(s)"
                  name="activiteSpecifique"
                  class="bottom-select"
                  [(ngModel)]="recherche.activiteSpecifique"
                  (ngModelChange)="getRecruteur()"
                  [multiple]="true"
                  [outline]="true"
                >
                  <mdb-select-option *ngFor="let option of activities" [value]="option.value">{{
                    option.label
                    }}</mdb-select-option>
                </mdb-select-2>
              </div>
              <!--                            <div class="col-6">-->
              <!--                                <button-->
              <!--                                    routerLink="/espace-candidat/valider-panier/{{ cartId }}/panier"-->
              <!--                                    [disabled]="ids.length === 0"-->
              <!--                                    [rounded]="true"-->
              <!--                                    mdbBtn-->
              <!--                                    type="button"-->
              <!--                                    class="ml-3 mt-3"-->
              <!--                                    color="secondary"-->
              <!--                                    size="lg"-->
              <!--                                    mdbWavesEffect-->
              <!--                                >-->
              <!--                                    Panier-->
              <!--                                    <fa-icon [icon]="faShoppingCart" class="ml-1 mr-1"></fa-icon>-->
              <!--                                    {{ ids.length }}-->
              <!--                                </button>-->
              <!--                            </div>-->
              <div class="col-6">
                <button
                  [disabled]="recruteurs.length === 0"
                  [rounded]="true"
                  mdbBtn
                  type="button"
                  class="ml-3"
                  color="secondary"
                  (click)="selectAll()"
                  mdbWavesEffect
                >
                  Ajouter tous ({{ allRecruteursAddAll.length }})
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showFiltersMobile === true">
          <div class="text-center p-3" *ngIf="recruteurs.length === 0">Aucun résultat</div>
          <div *ngIf="recruteurs.length > 0"
               infiniteScroll
               [infiniteScrollDistance]="0.5"
               [infiniteScrollThrottle]="450"
               (scrolled)="loadMore()"
               [scrollWindow]="true">
            <div class="mobile-checkbox">
              <div *ngFor="let r of recruteurs; let i = index" class="mobile-check-container">
                <div class="mobile-check col-10 pl-0 pr-0">
                  <h5>
                    <span [class]="r.alreadyContacted ? 'text-primary' : ''"> {{ r.referenceRecruteur }} </span>
                    -
                    {{ r.typeEntreprise | typesEntreprises }}
                  </h5>
                  <a (click)='loadRecruteurForm(r._id)'>
                    <p>
                      Fiche
                      <fa-icon [icon]='faFileContract'></fa-icon>
                    </p>
                  </a>
                  <p>
                    {{
                    r.secteurEntreprise.length > 0
                      ? 'Secteur(s) d\'activité(s) : ' + (r.secteurEntreprise | arrayToLineText: true) : ''
                    }}
                  </p>

                  <p>
                    {{
                    r.activiteSpecifique.length > 0
                      ? 'Activité(s) spécifique(s) : ' + (r.activiteSpecifique | arrayToLineText: false : 'activities')
                      : ''
                    }}
                  </p>
                  <p>Nb de salariés : {{ r.nombreSalarie | workersNumber }}</p>
                  <p>Lieu : {{ r.codePostal }}</p>
                </div>
                <div class="col-2">
                  <mdb-checkbox
                    class="ml-3"
                    (click)="ajouterSupprimer(r._id)"
                    [checked]="ids.includes(r._id)"
                  ></mdb-checkbox>
                </div>
              </div>
            </div>
            <div *ngIf='loadingMore' class="d-flex justify-content-center spinner-border spinner-border-sm ml-auto mr-auto text-dark mb-3" role="status">
            </div>
            <!--                        <div class="d-flex justify-content-center">-->
            <!--                            <button-->
            <!--                                [disabled]="!canLoadMore"-->
            <!--                                (click)="loadMore()"-->
            <!--                                [rounded]="true"-->
            <!--                                mdbBtn-->
            <!--                                type="button"-->
            <!--                                color="success"-->
            <!--                                size="lg"-->
            <!--                                class="mb-5"-->
            <!--                                mdbWavesEffect-->
            <!--                            >-->
            <!--                                <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>-->
            <!--                                Afficher plus-->
            <!--                            </button>-->
            <!--                        </div>-->
          </div>
        </div>
      </div>

      <div [hidden]="isMobile">
        <div class="panier-wrapper">
          <div class="panier-top-container">
            <div>
              <p>
                Trouvez les recruteurs qui vous conviennent en utilisant le moteur de recherche ! Nous vous conseillons par exemple de mettre un mot-clé correspondant au secteur d’activité que vous recherchez. Etant un service de candidatures spontanées, il faut être le plus généraliste possible.
              </p>
            </div>
            <div class="panier-filter flex-wrap">
              <div class="col-4 md-form">
                <app-filter-secteur  #searchSecteurs (refresh)='getRecruteur()' [(data)]='recherche.secteurs' [label]="'Secteurs d\'activités'">
                </app-filter-secteur>
<!--                <div class="md-form">-->
<!--                  <input-->
<!--                    type="text"-->
<!--                    name="motcles"-->
<!--                    id="motcles"-->
<!--                    class="form-control bc-white mb-0"-->
<!--                    (input)="getRecruteur()"-->
<!--                    [(ngModel)]="recherche.motCles"-->
<!--                    placeholder="Mots-clés : secteur d'activité…"-->
<!--                  />-->
                  <!--                                    <input-->
                  <!--                                        type="text"-->
                  <!--                                        name="metiersRechercher"-->
                  <!--                                        class="completer-input form-control mdb-autocomplete mb-0 bc-white"-->
                  <!--                                        [ngModel]="searchMetiers | async"-->
                  <!--                                        (ngModelChange)="searchMetiers.next($event)"-->
                  <!--                                        [mdbAutoCompleter]="autoMetier"-->
                  <!--                                        (clearBtnClicked)="clearMetier()"-->
                  <!--                                        placeholder="Rechercher un métier"-->
                  <!--                                    />-->
                  <!--                                    <mdb-auto-completer class="visible" #autoMetier="mdbAutoCompleter" textNoResults="Pas de résultat">-->
                  <!--                                        <mdb-option-->
                  <!--                                            *ngFor="let option of resultsMetiers | async"-->
                  <!--                                            [value]="option.nomMetier"-->
                  <!--                                            (click)="chooseMetiers(option.codeOgr, option.nomMetier)"-->
                  <!--                                        >-->
                  <!--                                            <div class="d-flex flex-column">-->
                  <!--                                                <strong>{{ option.nomMetier }}</strong>-->
                  <!--                                            </div>-->
                  <!--                                        </mdb-option>-->
                  <!--                                    </mdb-auto-completer>-->
<!--                </div>-->
              </div>
              <div class="col-4 pt-1">
                <div class='md-form'>
                  <mdb-select-2
                    placeholder="Pays"
                    [notFoundMsg]="'Pays non trouvé'"
                    [(ngModel)]="recherche.paysCode"
                    name="paysCode"
                    [outline]="true"
                  >
                    <mdb-select-filter
                      class="bc-white"
                      [ngModel]="searchPays | async"
                      (inputChange)="searchPays.next($event)"
                    >
                    </mdb-select-filter>
                    <mdb-select-option
                      *ngFor="let option of filteredOptions | async"
                      [value]="option.code"
                      (click)="choosePays(option.pays, option.code)"
                    >{{ option.pays }}</mdb-select-option
                    >
                  </mdb-select-2>
                </div>

              </div>
              <div class="col-4 pt-2">
                <app-select-city #searchCity (selected)="getRecruteur()" (clear)="clearCity()" [ville]="recherche"></app-select-city>
              </div>
              <!--                            <div class="col-3 md-form">-->

              <!--                            </div>-->
              <div class="col-4">
                <label for="range" class="mb-2 d-flex justify-content-center">Masquer les recruteurs déjà contactés</label>

                <div class="d-flex justify-content-center">
                  <div class="switch">
                    <label>
                      Non
                      <input [(ngModel)]='hideAlreadyContacted' (ngModelChange)='getRecruteur()' type="checkbox">
                      <span class="lever"></span> Oui
                    </label>
                  </div>
                </div>
              </div>
              <div class="offset-4 col-4">
                <label for="range" class="mb-2">Rayon</label>

                <div class="d-flex">
                  <span class="font-weight-bold indigo-text mr-2">{{ min }}</span>
                  <form class="w-100">
                    <mdb-range-input
                      (ngModelChange)="getRecruteur()"
                      class=""
                      id="range"
                      name="range"
                      [min]="min"
                      [(ngModel)]="rayon"
                      [max]="max"
                    ></mdb-range-input>
                  </form>
                  <span class="font-weight-bold indigo-text ml-2">{{ max }}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="py-3">
          <div class="d-flex flex-wrap justify-content-between">
            <div class="col-3 panier-bottom-select">
              <div class="ins-select d-flex align-items-center">
                <img src="./assets/img/org.png" alt="org" />
                <h6>Type d’organisme</h6>
              </div>
              <mdb-select-2
                placeholder="Choisir.."
                name="typesEntreprise"
                [(ngModel)]="recherche.typeEntreprise"
                (ngModelChange)='getRecruteur()'
                class="bottom-select"
                [multiple]="true"
                [outline]="true"
              >
                <mdb-select-option *ngFor="let option of typesEntreprises" [value]="option.value">{{
                  option.label
                  }}</mdb-select-option>
              </mdb-select-2>
            </div>
            <div class="col-3 panier-bottom-select">
              <div class="ins-select d-flex align-items-center">
                <img src="./assets/img/employee.png" alt="employee" />
                <h6>Nombre de salariés</h6>
              </div>
              <mdb-select-2
                placeholder="Choisir.."
                name="workersNumbers"
                [(ngModel)]="recherche.nombreSalarie"
                (ngModelChange)='getRecruteur()'
                class="bottom-select"
                [multiple]="true"
                [outline]="true"
              >
                <mdb-select-option *ngFor="let option of workersNumbers" [value]="option.value">{{
                  option.label
                  }}</mdb-select-option>
              </mdb-select-2>
            </div>
            <div class="col-3 panier-bottom-select">
              <div class="ins-select d-flex align-items-center">
                <img src="./assets/img/activity.png" alt="activity" />
                <h6>Activité(s) spécifique(s)</h6>
              </div>
              <mdb-select-2
                placeholder="Choisir.."
                name="activiteSpecifique"
                class="bottom-select"
                [(ngModel)]="recherche.activiteSpecifique"
                (ngModelChange)='getRecruteur()'
                [multiple]="true"
                [outline]="true"
              >
                <mdb-select-option *ngFor="let option of activities" [value]="option.value">{{
                  option.label
                  }}</mdb-select-option>
              </mdb-select-2>
            </div>
            <div class="col-3 panier-bottom-search">
              <!--                            <button (click)="getRecruteur()" [rounded]="true" mdbBtn type="button" color="warning" size="lg" mdbWavesEffect>-->
              <!--                                <fa-icon [icon]="faSearch" class="mr-1"></fa-icon>Rechercher-->
              <!--                            </button>-->
              <button (click)="reinitiliaser()" [rounded]="true" mdbBtn type="button" color="success" size="lg" mdbWavesEffect>
                Reinitialiser
              </button>
            </div>
          </div>
          <div class="panier-bottom-right d-flex justify-content-between align-items-center">
            <p class='col-10'>
              Lorsque vous ajoutez/sélectionnez des recruteurs, ils sont automatiquement enregistrés dans votre panier. Pour
              consulter son contenu et passer à l’étape suivante, vous devez cliquer sur le bouton « Panier ». Les recruteurs déjà contactés apparaissent en <span class='text-primary'>bleu</span>.
            </p>
            <div class='col-2 pl-0 pr-0'>
              <button
                [disabled]="recruteurs.length === 0"
                [rounded]="true"
                mdbBtn
                type="button"
                color="secondary"
                size="lg"
                (click)="selectAll()"
                mdbWavesEffect
              >
                Ajouter tous les recruteurs <br />
                de ma recherche ({{ recruteurs.length }})
              </button>
            </div>

            <!--                        <div class="panier-basket">-->
            <!--                            <button-->
            <!--                                routerLink="/espace-candidat/valider-panier/{{ cartId }}/panier"-->
            <!--                                [disabled]="ids.length === 0"-->
            <!--                                [rounded]="true"-->
            <!--                                mdbBtn-->
            <!--                                type="button"-->
            <!--                                color="secondary"-->
            <!--                                size="lg"-->
            <!--                                mdbWavesEffect-->
            <!--                            >-->
            <!--                                Panier-->
            <!--                                <fa-icon [icon]="faShoppingCart" class="ml-1 mr-1"></fa-icon>-->
            <!--                                {{ ids.length }}-->
            <!--                            </button>-->
            <!--                        </div>-->
          </div>
        </div>
        <p *ngIf="rechercheBase" class="form-row" style="display: block; width: 20px; margin: auto">
          <img src="./assets/img/gif.gif" style="max-width: 25px; text-align: center" />
        </p>
        <table [ngStyle]="{ display: display() }" id="tableSort" mdbTable #tableEl="mdbTable" class="display custom-table mt-2">
          <thead>
          <tr>
            <th aria-controls="tableSortExample" [mdbTableSort]="recruteurs" sortBy="selectionned" scope="col">
              - <fa-icon [icon]="faSort"></fa-icon>
            </th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="recruteurs" sortBy="referenceRecruteur">
              Référence du recruteur
              <fa-icon [icon]="faSort"></fa-icon>
            </th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="recruteurs" sortBy="codePostal">
              Localisation <fa-icon [icon]="faSort"></fa-icon>
            </th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="recruteurs" sortBy="typeEntreprise">
              Type d'organisme
              <fa-icon [icon]="faSort"></fa-icon>
            </th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="recruteurs" sortBy="nombreSalarie">
              Nombre de salariés
              <fa-icon [icon]="faSort"></fa-icon>
            </th>
            <th aria-controls="tableSortExample" scope="col">Activité(s) spécifique(s)</th>
            <th aria-controls="tableSortExample" scope="col">Secteur(s) d'activité(s)</th></tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let r of recruteurs; let i = index">
            <tr>
              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                <mdb-checkbox
                  (click)="ajouterSupprimer(r._id)"
                  [checked]="ids.includes(r._id)"
                ></mdb-checkbox>
              </td>
              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                <span [class]="r.alreadyContacted ? 'text-primary' : ''"> {{ r.referenceRecruteur }} </span>
                <br/><a (click)='loadRecruteurForm(r._id)'>
                <p>
                  Fiche
                  <fa-icon [icon]='faFileContract'></fa-icon>
                </p>
              </a>
              </td>
              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                {{ r.codePostal }}
              </td>
              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                {{ r.typeEntreprise | typesEntreprises }}
              </td>
              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                {{ r.nombreSalarie | workersNumber }}
              </td>
              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                <div *ngFor="let a of r.activiteSpecifique">
                  {{ a | activities }}
                </div>
              </td>
              <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                <div *ngFor="let s of r.secteurEntreprise; let index = index; let isOdd = odd">
                  <span *ngIf="index <= 1">{{ s.nom }}</span>
                  <span *ngIf="isOdd && index == 3">...</span>
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
          <tfoot class="grey lighten-5 w-100">
          <tr>
            <td [colSpan]="headElements.length">
              <mdb-table-pagination
                ofKeyword="de"
                [tableEl]="tableEl"
                [searchDataSource]="recruteurs"
              ></mdb-table-pagination>
            </td>
          </tr>
          <tr>

            <td [colSpan]="headElements.length" style="border-top: none !important; border-bottom: none !important">
              <mdb-select-2
                placeholder="Afficher 10/ page"
                (selected)="changeItemsByPage($event)"
                style="float: right; min-width: 174px"
                name="itemsByPage"
                [outline]="true"
              >
                <mdb-select-option *ngFor="let option of displayByPages" [value]="option.value">{{
                  option.label
                  }}</mdb-select-option>
              </mdb-select-2>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <button *ngIf='!isMobile || (isMobile && showFiltersMobile)' routerLink="/espace-candidat/valider-panier/{{ cartId }}/panier" class="float btn-warning"  [disabled]="ids.length === 0">
    <fa-icon [icon]='faShoppingCart' class='fa-2x justify-content-center d-flex'></fa-icon>
    <span class="float-badge">{{ ids.length }}</span>
  </button>
</div>
<app-site-footer></app-site-footer>
