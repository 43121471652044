<div class="row" style="width: 100%">
    <div class="col-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div>
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">{{ candidat.nom }} - {{ candidat.prenom }}</h3>
                    <h4 class="breadcrumb-header">{{ candidat.referenceCandidat }}</h4>
                    <img style="width: 150px" src="{{ url }}/images/candidat/{{ candidat.photo }}" />
                    <div>{{ candidat.dateNaissance }}</div>
                </div>
                <div id="wizard_container">
                    <div id="middle-wizard">
                        <div>
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Ma recherche d'emploi</h5>
                                    <table style='table-layout: fixed' class="display table dataTable">
                                        <tr *ngIf="candidat.situation">
                                            <td>Etat</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.situation === 'active'">En recherche active</div>
                                                <div *ngIf="candidat.situation === 'ecoute'">A l'écoute</div>
                                                <div *ngIf="candidat.situation === 'ne-recherche-pas'">Ne recherche pas</div>
                                            </td>
                                        </tr>
                                      <tr *ngIf="candidat.teleTravail">
                                        <td>Télétravail souhaité</td>
                                        <td style="text-align: right">
                                          {{ candidat.teleTravail | teletravail }}
                                        </td>
                                      </tr>
                                        <tr *ngIf="candidat.dispo && candidat.dispo.travail">
                                            <td>Est en poste ?</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.dispo.travail == '1'">Oui</div>
                                                <div *ngIf="candidat.dispo.travail == '2'">Non</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.dispo && candidat.dispo.preavis">
                                            <td>Connaît sa date ?</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.dispo.preavis == '1'">Oui</div>
                                                <div *ngIf="candidat.dispo.preavis == '2'">Non</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.dispo && (candidat.dispo.travail == '2' || candidat.dispo.preavis == '1')">
                                            <td>Disponible le</td>
                                            <td style="text-align: right">
                                                <div>
                                                    {{ candidat.dateDisponibilite | date: 'dd-MM-yyyy' }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.disponibilite && candidat.dispo.travail == '1' && candidat.dispo.preavis == '2'">
                                            <td>Disponible (préavis)</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.disponibilite === 'immediatement'">Immédiatement</div>
                                                <div *ngIf="candidat.disponibilite === '1-semaine'">Sous 1 semaine</div>
                                                <div *ngIf="candidat.disponibilite === '15-jours'">Sous 15 jours</div>
                                                <div *ngIf="candidat.disponibilite === '1-mois'">Sous 1 mois</div>
                                                <div *ngIf="candidat.disponibilite === '2-mois'">Sous 2 mois</div>
                                                <div *ngIf="candidat.disponibilite === '3-mois'">Sous 3 mois</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.posteRechercher && candidat.posteRechercher.length > 0">
                                          <td colspan='2'>
                                            <app-poste class='d-flex flex-wrap' [(data)]='candidat.posteRechercher' [admin]='true' [idCompte]='candidat._id' [typeCompte]='"candidat"'>
                                              <label for="fonction">Poste(s) souhaité(s) <small>Ce champ est multiple</small></label>
                                            </app-poste>
                                          </td>
<!--                                            <td>Poste(s) souhaité(s)</td>-->
<!--                                            <td style="text-align: right">-->
<!--                                                <div *ngFor="let p of candidat.posteRechercher">-->
<!--                                                    {{ p.nom }}-->
<!--                                                </div>-->
<!--                                            </td>-->
                                        </tr>
                                        <tr *ngIf="candidat.secteurRechercher  && candidat.secteurRechercher.length > 0">
                                          <td colspan='2'>
                                            <app-secteur class='d-flex flex-wrap' [(data)]='candidat.secteurRechercher' [admin]='true' [idCompte]='candidat._id' [typeCompte]='"candidat"'>
                                              <label for="secteursRechercher">Secteur(s) d'activité souhaité(s) <small>Ce champ est multiple</small></label>
                                            </app-secteur>
                                          </td>
                                        </tr>
                                        <tr *ngIf="candidat.typePoste && candidat.typePoste.length > 0">
                                            <td>Type(s) de poste recherché(s)</td>
                                            <td style="text-align: right">
                                                <div *ngFor="let po of candidat.typePoste">
                                                    <div *ngIf="po == 'salarie'">Salarié</div>
                                                    <div *ngIf="po == 'independant'">Indépendant</div>
                                                    <div *ngIf="po == 'stagiaire'">Stagiaire</div>
                                                    <div *ngIf="po == 'alternant'">Alternant</div>
                                                    <div *ngIf="po == 'benevole'">Bénévole</div>
                                                    <div *ngIf="po == 'autre'">Autre</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.typePosteVoulu && candidat.typePosteVoulu.length > 0">
                                            <td>Type(s) de contrat recherché(s)</td>
                                            <td style="text-align: right; text-transform: uppercase">
                                                <div *ngFor="let po of candidat.typePosteVoulu">
                                                    {{ po }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.temps && candidat.temps.length > 0">
                                            <td>Temps</td>
                                            <td style="text-align: right; text-transform: uppercase">
                                                <div *ngFor="let te of candidat.temps">
                                                    {{ te }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.mobilite_locale">
                                            <td>Mobilité(s) locale(s)</td>
                                            <td style="text-align: right" *ngIf="candidat.mobilite_locale.length > 0">
                                                <div *ngFor="let m of candidat.mobilite_locale">
                                                    {{ m.ville }} - {{ m.pays }} : {{ m.rayon }} km
                                                </div>
                                            </td>
                                            <td style="text-align: right" *ngIf="candidat.mobilite_locale.length <= 0">
                                                <div>Aucune mobilité locale</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.mobilite_nationale">
                                            <td>Mobilité(s) nationale(s)</td>
                                            <td style="text-align: right" *ngIf="candidat.mobilite_nationale.length > 0">
                                                <div *ngFor="let m of candidat.mobilite_nationale">
                                                    {{ m }}
                                                </div>
                                            </td>
                                            <td style="text-align: right" *ngIf="candidat.mobilite_nationale.length <= 0">
                                                <div>Aucune mobilité nationale</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.mobilite_internationale">
                                            <td>Mobilité internationale</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.mobilite_internationale">Oui</div>
                                                <div *ngIf="!candidat.mobilite_internationale">Non</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.remunerationSouhaiter">
                                            <td>Rémunération souhaitée (brut)</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.remunerationSouhaiter[0] !== 0">
                                                    {{ candidat.remunerationSouhaiter[0] }}
                                                    € / Heure
                                                </div>
                                                <div *ngIf="candidat.remunerationSouhaiter[1] !== 0">
                                                    {{ candidat.remunerationSouhaiter[1] }}
                                                    € / Jour
                                                </div>
                                                <div *ngIf="candidat.remunerationSouhaiter[2] !== 0">
                                                    {{ candidat.remunerationSouhaiter[2] }}
                                                    € / Mois
                                                </div>
                                                <div *ngIf="candidat.remunerationSouhaiter[3] !== 0">
                                                    {{ candidat.remunerationSouhaiter[3] }}
                                                    € / Année
                                                </div>
                                                <div
                                                    *ngIf="
                                                        candidat.remunerationSouhaiter[0] === 0 &&
                                                        candidat.remunerationSouhaiter[1] === 0 &&
                                                        candidat.remunerationSouhaiter[2] === 0 &&
                                                        candidat.remunerationSouhaiter[3] === 0
                                                    "
                                                >
                                                    Aucune information spécifiée par le candidat
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.atoutConcurrenciel">
                                            <td>Atouts concurrentiels</td>
                                            <td style="text-align: right">
                                                {{ candidat.atoutConcurrenciel }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.lettreMotivation">
                                            <td>Lettre de motivation</td>
                                            <td [innerHTML]='candidat.lettreMotivation' style="text-align: right">
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.cv">
                                            <td>CV</td>
                                            <td style="text-align: right">
                                                <a target="_blank" href="{{ url }}/documents/cv/{{ candidat.cv }}">Télécharger</a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <table class="display table dataTable">
                                        <tr>
                                            <td colspan="2">
                                                <button class="form-control btn btn-success" (click)="envoiSansConnexion()">
                                                    Lien modification compte sans connexion
                                                </button>
                                            </td>
                                        </tr>
                                      <tr>
                                        <td colspan="2">
                                          <button class="form-control btn btn-danger" (click)="supprimer(candidat._id)">Supprimer le compte</button>
                                        </td>
                                      </tr>
                                        <tr *ngIf="candidat.status">
                                            <td colspan="2">
                                                <button class="form-control btn btn-danger" (click)="bloquer()">Bloquer</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <textarea
                                                    class="form-control"
                                                    type="text"
                                                    rows="6"
                                                    [(ngModel)]="nouvelleNote"
                                                    placeholder="Nouvelle note..."
                                                ></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <button class="form-control btn btn-success" (click)="ajouterNote()">Ajouter</button>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let n of candidat.notes">
                                            <td colspan="2">
                                                {{ n }}
                                                <button class="btn btn-danger form-control" (click)="supprimerNote(n)">Supprimer</button>
                                            </td>
                                        </tr>
                                        <tr *ngIf="!candidat.status">
                                            <td colspan="2">
                                                <button class="form-control btn btn-success" (click)="debloquer()">Débloquer</button>
                                            </td>
                                        </tr>
                                        <h5>Mon profil</h5>
                                        <tr *ngIf="candidat.fonctionLibre">
                                            <td>Intitulé du poste actuellement ou dernièrement occupé</td>
                                            <td style="text-align: right">
                                                {{ candidat.fonctionLibre }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.fonction">
                                          <td colspan='2'>
                                            <app-poste class='d-flex flex-wrap' [single]='true' [(data)]='candidat.fonction' [admin]='true' [idCompte]='candidat._id' [typeCompte]='"candidat"'>
                                              <label for="fonction">Métier s'en rapprochant le plus</label>
                                            </app-poste>
                                          </td>
                                        </tr>
                                        <tr *ngIf="candidat.experienceSecteur ">
                                            <td>Expérience dans cette fonction</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.experienceSecteur === '1'">Moins d'un an</div>
                                                <div *ngIf="candidat.experienceSecteur === '1-3'">1-3 ans</div>
                                                <div *ngIf="candidat.experienceSecteur === '4-9'">4-9 ans</div>
                                                <div *ngIf="candidat.experienceSecteur === '9+'">10 ans et +</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.secteurActivite && candidat.secteurActivite.length > 0">
                                          <td colspan='2'>
                                            <app-secteur class='d-flex flex-wrap' [(data)]='candidat.secteurActivite' [admin]='true' [idCompte]='candidat._id' [typeCompte]='"candidat"'>
                                              <label for="secteursActuels">Secteur(s) d’activité actuel(s) <small>Ce champ est multiple</small></label>
                                            </app-secteur>
                                          </td>
<!--                                            <td>Secteur(s) d’activité actuel(s)</td>-->
<!--                                            <td style="text-align: right">-->
<!--                                                <div *ngFor="let s of candidat.secteurActivite">-->
<!--                                                    {{ s.nom }}-->
<!--                                                </div>-->
<!--                                            </td>-->
                                        </tr>
                                        <tr *ngIf="candidat.experience">
                                            <td>Expérience globale</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.experience === '1'">Moins d'un an</div>
                                                <div *ngIf="candidat.experience === '1-3'">1-3 ans</div>
                                                <div *ngIf="candidat.experience === '4-9'">4-9 ans</div>
                                                <div *ngIf="candidat.experience === '9+'">10 ans et +</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.niveauEtude">
                                            <td>Niveau d'étude</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.niveauEtude === 'Autodidacte'">Autodidacte</div>
                                                <div *ngIf="candidat.niveauEtude === 'CAP'">
                                                    CAP (certificat d’aptitude professionnelle)
                                                </div>
                                                <div *ngIf="candidat.niveauEtude === 'BAAEP'">BEP (brevet d’études primaires)</div>
                                                <div *ngIf="candidat.niveauEtude === 'BAC'">BAC (baccalauréat)</div>
                                                <div *ngIf="candidat.niveauEtude === 'BAC+2'">BAC+2</div>
                                                <div *ngIf="candidat.niveauEtude === 'BAC+3'">BAC+3 - Licence</div>
                                                <div *ngIf="candidat.niveauEtude === 'BAC+4'">BAC+4</div>
                                                <div *ngIf="candidat.niveauEtude === 'BAC+5'">BAC+5 - Master 2</div>
                                                <div *ngIf="candidat.niveauEtude === 'BAC+6'">BAC+6</div>
                                                <div *ngIf="candidat.niveauEtude === 'BAC+7'">BAC+7</div>
                                                <div *ngIf="candidat.niveauEtude === 'BAC+8'">BAC+8 - Doctorat</div>
                                                <div *ngIf="candidat.niveauEtude === 'BAC+9'">BAC+9 - Doctorat</div>
                                                <div *ngIf="candidat.niveauEtude === 'Bac+9+'">BAC+10</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.langues && candidat.langues.length > 0">
                                            <td>Langue(s)</td>
                                            <td style="text-align: right">
                                                <div *ngFor="let l of candidat.langues">
                                                    {{ l.langue }} : parlé {{ l.parle }}, écrit
                                                    {{ l.ecrit }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.permis && candidat.permis.length > 0">
                                            <td>Permis de conduire</td>
                                            <td style="text-align: right">
                                                <span *ngFor="let p of candidat.permis" style="color: black !important">
                                                    {{ p }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.handicape && candidat.etape > 2">
                                            <td>Est reconnu(e) en qualité de travailleur/euse handicapé(e)</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.handicape">Oui</div>
                                                <div *ngIf="!candidat.handicape">Non</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.nationalites && candidat.nationalites.length > 0">
                                            <td>Nationalité(s)</td>
                                            <td style="text-align: right">
                                                <div *ngFor="let n of candidat.nationalites">
                                                    {{ n }}
                                                </div>
                                            </td>
                                        </tr>
                                        <h5>Mes coordonnées</h5>
                                        <tr *ngIf="candidat.ficheComplete || candidat.etape >= 2">
                                            <td>Adresse</td>
                                            <td style="text-align: right">
                                                {{ candidat.adresse }}<span *ngIf="candidat.complementAdresse !== ''">, </span>
                                                {{ candidat.complementAdresse }}, {{ candidat.codePostal }} {{ candidat.ville }} -
                                                {{ candidat.pays }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.ficheComplete || candidat.etape >= 2">
                                            <td>Pays de résidence</td>
                                            <td style="text-align: right">
                                                {{ candidat.paysCode }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.ficheComplete || candidat.etape >= 2">
                                            <td>Téléphone(s)</td>
                                            <td style="text-align: right">
                                                {{ candidat.telephoneFixe }}
                                                <span *ngIf="candidat.telephonePortable !== null && candidat.telephoneFixe !== null"
                                                    >/</span
                                                >
                                                {{ candidat.telephonePortable }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.ficheComplete || candidat.etape >= 2">
                                            <td>Email</td>
                                            <td style="text-align: right">
                                                <a href="mailto:{{ candidat.email }}">{{ candidat.email }}</a>
                                            </td>
                                        </tr>
                                        <h5>Autorisations</h5>
                                        <tr *ngIf="candidat.ficheComplete || candidat.etape >= 7">
                                            <td>
                                                Autorisation : Enregistrement automatique de ma fiche contenant mon CV et ma Lettre de
                                                Motivation dans la CVthèque des recruteurs auxquels j’ai adressé mon CV (fortement
                                                conseillé)
                                            </td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.etreRecruteur">Oui</div>
                                                <div *ngIf="!candidat.etreRecruteur">Non</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.ficheComplete || candidat.etape >= 7">
                                            <td>
                                                Autorisation : Enregistrement automatique de ma fiche contenant mon CV dans la CVthèque du
                                                site (accessible à tous les recruteurs)
                                            </td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.etreDansCVTech">Oui</div>
                                                <div *ngIf="!candidat.etreDansCVTech">Non</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.ficheComplete || candidat.etape >= 7">
                                            <td>Autorisation : Affichage de sa photo et de son métier sur la page d'accueil du site</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.autorisationAccueil">Oui</div>
                                                <div *ngIf="!candidat.autorisationAccueil">Non</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="candidat.ficheComplete || candidat.etape >= 7">
                                            <td>Autorisation : Autoriser les recruteurs à consulter ma photo de profil dans ma fiche</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.photoProfil">Oui</div>
                                                <div *ngIf="!candidat.photoProfil">Non</div>
                                            </td>
                                        </tr>
                                      <tr *ngIf="candidat.ficheComplete || candidat.etape >= 7">
                                        <td>Autoriser les recruteurs à m’envoyer des offres d’emploi par sms et mail</td>
                                        <td style="text-align: right">
                                          <div *ngIf="candidat.sendOffer">Oui</div>
                                          <div *ngIf="!candidat.sendOffer">Non</div>
                                          <div *ngIf="!candidat.sendOffer && candidat.sendOfferType">Type : {{ candidat.sendOfferType }}</div>

                                        </td>
                                      </tr>
                                        <h5>Etape d'inscription</h5>
                                        <tr *ngIf="candidat.ficheComplete">
                                            <td colspan="2">Fini</td>
                                        </tr>
                                        <tr *ngIf="!candidat.ficheComplete">
                                            <td>Etape</td>
                                            <td style="text-align: right">
                                                <div *ngIf="candidat.etape == 0">Password</div>
                                                <div *ngIf="candidat.etape == 1">Informations personnelles</div>
                                                <div *ngIf="candidat.etape == 2">Situation actuelle</div>
                                                <div *ngIf="candidat.etape == 3">Sa recherche</div>
                                                <div *ngIf="candidat.etape == 4">Sa situation</div>
                                                <div *ngIf="candidat.etape == 5">Mobilité et rémunération</div>
                                                <div *ngIf="candidat.etape == 6">Autorisation</div>
                                                <div *ngIf="candidat.etape == 7">Fin</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
