<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="margin-top: 25px">
        <div class="page-title">
            <h3>Gestion des paliers de tarifs</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table class="display table dataTable">
                                    <thead>
                                        <tr>
                                            <th class="sorting">Nombre de courriels</th>
                                            <th class="sorting_asc">Prix unitaire HT</th>
                                            <th>Modifier</th>
                                            <th>Supprimer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let t of tarifs">
                                            <td>
                                                <input type="number" class="form-control" [(ngModel)]="t.nombreDeCourrielMinimum" />
                                            </td>
                                            <td>
                                                <input type="number" class="form-control" [(ngModel)]="t.prixUnitaireHt" />
                                            </td>
                                            <td>
                                                <div>
                                                    <button class="btn btn-success" (click)="modifier(t._id)">Modifier</button>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <button class="btn btn-danger" (click)="supprimer(t._id)">Supprimer</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input
                                                    type="number"
                                                    placeholder="Nouveau nombre de mail minimum..."
                                                    class="form-control"
                                                    [(ngModel)]="nouveauTarif.nombreDeCourrielMinimum"
                                                    [ngStyle]="{
                                                        border: erreurAjout ? '1px solid red' : ''
                                                    }"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    placeholder="Nouveau prix unitaire HT..."
                                                    class="form-control"
                                                    [(ngModel)]="nouveauTarif.prixUnitaireHt"
                                                    [ngStyle]="{
                                                        border: erreurAjout ? '1px solid red' : ''
                                                    }"
                                                />
                                            </td>
                                            <td colspan="2">
                                                <div>
                                                    <button class="btn btn-info" (click)="ajouter()">Ajouter un nouveau palier</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
