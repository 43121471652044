<app-site-header></app-site-header>

<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-recruteur-header></app-recruteur-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10">
        <div class="page-inner">
            <div class="page-title">
                <h3 class="breadcrumb-header">Accueil</h3>
            </div>
            <section>
                <div class="row row-background p-3">
                    <div class="col-xl-4 col-sm-12 col-12 col-md-6 mb-2">
                      <a routerLink="/espace-recruteur/candidatures-recues">
                        <div class="card">
                            <div class="card-body" style='padding: 1rem'>
                                <div class="d-flex justify-content-between px-md-1">
                                    <div>
                                        <p class="mb-0">Candidatures reçues</p>
                                        <h3 class="text-warning">
                                            {{ candidatureRecues }}
                                        </h3>
                                    </div>
                                    <div class="align-self-center">
                                        <fa-icon class="text-warning fa-3x" [icon]="faEnvelope"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-xl-4 col-sm-12 col-12 col-md-6 mb-2">
                      <a routerLink="/espace-recruteur/cvtheque">
                        <div class="card">
                            <div class="card-body" style='padding: 1rem'>
                                <div class="d-flex justify-content-between px-md-1">
                                    <div>
                                        <p class="mb-0">Candidatures dans la CVthèque</p>
                                        <h3 class="text-pink">
                                            {{ candidatCvTheque }}
                                        </h3>
                                    </div>
                                    <div class="align-self-center">
                                        <fa-icon class="text-pink fa-3x" [icon]="faUsers"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-xl-4 col-sm-12 col-12 col-md-6 mb-2">
                      <a href="/espace-recruteur/cvtheque?filter=active-search">
                        <div class="card">
                            <div class="card-body" style='padding: 1rem'>
                                <div class="d-flex justify-content-between px-md-1">
                                    <div>
                                        <p class="mb-0">Candidat(e)s en recherche active</p>
                                        <h3 class="text-primary">
                                            {{ candidatRechercheActive }}
                                        </h3>
                                    </div>
                                    <div class="align-self-center">
                                        <fa-icon class="text-primary fa-3x" [icon]="faPaperPlane"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </a>
                    </div>
                </div>
            </section>

            <div id="main-wrapper">
              <div *ngIf='devMode'>
                <div class="div mt-3 mb-3">
                  <h2 class="breadcrumb-header">Candidatures spontanées</h2>
                </div>

                <b>Votre lien personnalisé pour vos candidatures spontanées : {{candUrl}}</b> <button mdbBtn color='warning' [rounded]='true' (click)='urlCopied = true' [cdkCopyToClipboard]="candUrl" size='sm' class="ml-4" ><fa-icon [icon]='urlCopied ? faCheck : faCopy'></fa-icon> Copier</button> <br><br>
                Atouts de ce service 100 % gratuit pour les candidats et les recruteurs :
                <ul>
                  <li>
                    Centralise les candidatures spontanées dans votre espace Recruteur (rubrique « Candidatures reçues »)
                  </li>
                  <li>Evite de perdre des candidatures (qui pourraient être noyées dans vos courriels ou considérées comme
                    des SPAM)
                  </li>
                  <li>Candidatures reçues mieux renseignées (disponibilité, mobilité…)</li>

                  <li>Système de recherche, de tri et de suivi
                  </li>

                  <li>Mise à jour régulière des candidatures</li>

                  <li>Facilite le respect de la législation (RGPD)
                  </li>
                </ul>
              </div>
                <div class="div mt-4 mb-3">
                    <h2 class="breadcrumb-header">Votre espace en quelques étapes</h2>
                </div>

                <div class="hero-dashboard position-relative d-none d-md-block" style="margin-top: 3.5rem">
                    <div class="login-slider mt-3">
                        <div class="login-slide">
                            <div
                                class="slide-content"
                                *ngFor="let slide of recruteurSlides; let title = index"
                                [ngClass]="title === this.index ? 'slide-active' : ''"
                            >
                                <h3>
                                    {{ slide.title }}
                                </h3>
                                <div class="d-flex align-items-center py-2">
                                    <h1>{{ title + 1 }}</h1>
                                    <h5>
                                        {{ slide.text }}
                                    </h5>
                                </div>
                            </div>
                            <div class="slide-icons d-flex justify-content-between align-items-center">
                                <img class="prev" (click)="prev()" src="./assets/img/arrow-left.png" alt="next" />
                                <img class="next" (click)="next()" src="./assets/img/arrow-right.png" alt="next" />
                            </div>
                        </div>
                    </div>
                </div>

                <mdb-carousel
                    #carousel
                    [isControls]="true"
                    class="carousel-multi-item multi-animation d-sm-none"
                    [type]="'carousel-multi-item'"
                    [animation]="'slide'"
                >
                    <mdb-carousel-item *ngFor="let item of slides; let i = index">
                        <div class="col-md-4 pb-2" *ngFor="let card of item; let cardIndex = index">
                            <mdb-card class="my-1" style="height: 300px">
                                <mdb-card-body>
                                    <mdb-card-title>
                                        <h4>{{ card.title }}</h4>
                                    </mdb-card-title>
                                    <p>{{ card.description }}</p>
                                </mdb-card-body>
                            </mdb-card>
                        </div>
                    </mdb-carousel-item>
                </mdb-carousel>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
