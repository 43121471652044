<div class='d-flex flex-wrap align-items-center'>
  <div class='col-4 col-md-2 pl-0'>

    <span class='font-weight-bold'>Mon CV (.pdf) *</span>
  </div>
  <div class='col-8 col-md-3'>
    <div *ngIf="ancienCv !== ''">
      <a target='_blank' href='{{ url }}/documents/cv/{{ ancienCv }}'
      ><small>{{ ancienCv }}</small>
        <fa-icon class='ml-2' [icon]='faFilePdf'></fa-icon
        >
      </a>
    </div>
  </div>
  <div class='col-12 col-md-2 mt-3 mt-md-0'>
    <div class='file-field ml-3'>
      <div mdbBtn [rounded]='true' [outline]='false' color='secondary'>
        <span>{{ ancienCv !== '' ? 'Modifier' : 'Ajouter' }}</span>
        <input type='file' mdbFileSelect (change)='uploadCv($event)' />
      </div>
    </div>
  </div>
</div>
