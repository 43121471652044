export const GlobalVariable = Object.freeze({
    ACTIVITES: [
        {
            value: 'handicap',
            label: 'Handicap',
        },
        {
            value: 'agence_interim',
            label: "Agence d'intérim",
        },
        {
            value: 'management_transition',
            label: 'Management de transition',
        },
        {
            value: 'outplacement',
            label: 'Outplacement',
        },
        {
            value: 'cabinet_recrutement',
            label: 'Cabinet de recrutement',
        },
        {
            value: 'service_domicile',
            label: 'Services à domicile',
        },
    ],
    DISPOS: [
        {
            value: 'immediatement',
            label: 'Immédiatement',
        },
        {
            value: '1-semaine',
            label: 'Sous 1 semaine',
        },
        {
            value: '15-jours',
            label: 'Sous 15 jours',
        },
        {
            value: '1-mois',
            label: 'Sous 1 mois',
        },
        {
            value: '2-mois',
            label: 'Sous 2 mois',
        },
        {
            value: '3-mois',
            label: 'Sous 3 mois',
        },
    ],
    ETUDES: [
        {
            value: 'Autodidacte',
            label: 'Autodidacte',
        },
        {
            value: 'CAP',
            label: 'CAP',
        },
        {
            value: 'BAAEP',
            label: 'BEP',
        },
        {
            value: 'BAC',
            label: 'BAC',
        },
        {
            value: 'BAC+2',
            label: 'BAC+2',
        },
        {
            value: 'BAC+3',
            label: 'BAC+3 (Licence)',
        },
        {
            value: 'BAC+4',
            label: 'BAC+4',
        },
        {
            value: 'BAC+5',
            label: 'BAC+5',
        },
        {
            value: 'BAC+6',
            label: 'BAC+6',
        },
        {
            value: 'BAC+7',
            label: 'BAC+7',
        },
        {
            value: 'BAC+8',
            label: 'BAC+8',
        },
        {
            value: 'BAC+9',
            label: 'BAC+9',
        },
        {
            value: 'Bac+9+',
            label: 'Bac+10',
        },
    ],
    EXPERIENCES: [
        {
            value: '1',
            label: "Moins d'un an",
        },
        {
            value: '1-3',
            label: '1-3 ans',
        },
        {
            value: '4-9',
            label: '4-9 ans',
        },
        {
            value: '9+',
            label: '10 ans et +',
        },
    ],
    EXPERIENCES_SECTEURS: [
        {
            value: '1',
            label: "Moins d'un an",
        },
        {
            value: '1-3',
            label: '1-3 ans',
        },
        {
            value: '4-9',
            label: '4-9 ans',
        },
        {
            value: '9+',
            label: '10 ans et +',
        },
    ],
    PAGES: [
        {
            value: '10',
            label: 'Afficher 10/ page',
        },
        {
            value: '20',
            label: 'Afficher 20/ page',
        },
        {
            value: '50',
            label: 'Afficher 50/ page',
        },
        {
            value: '100',
            label: 'Afficher 100/ page',
        },
    ],
    REMINDERS: [
        {
            value: '',
            label: 'Jamais',
        },
        {
            value: 'week',
            label: 'Toutes les semaines',
        },
        {
            value: 'month',
            label: 'Tous les mois',
        },
    ],
    OFFER_TYPES: [
        {
            value: 'mail',
            label: 'Autoriser les recruteurs à m’envoyer des offres uniquement par mail',
        },
        {
            value: 'sms',
            label: 'Autoriser les recruteurs à m’envoyer des offres uniquement par sms',
        },
        {
            value: '',
            label: 'Ne pas autoriser les recruteurs à m’envoyer d’offres',
        },
    ],
    SITUATIONS: [
        {
            value: 'active',
            label: 'En recherche active',
        },
        {
            value: 'ecoute',
            label: "A l'écoute",
        },
        {
            value: 'ne-recherche-pas',
            label: 'Ne recherche pas',
        },
    ],
    TELETRAVAIL: [
        {
            value: 'non',
            label: 'Non',
        },
        {
            value: 'partiel',
            label: 'Oui, à temps partiel',
        },
        {
            value: 'complet',
            label: 'Oui, à temps complet',
        },
        {
            value: 'peu-importe',
            label: 'Peu importe',
        },
    ],
    TYPES_ENTREPRISES: [
        {
            value: 'independant',
            label: 'Indépendant(e)',
        },
        {
            value: 'societe',
            label: 'Société',
        },
        {
            value: 'association',
            label: 'Association',
        },
        {
            value: 'administration',
            label: 'Administration',
        },
    ],
    TYPES_POSTES: [
        {
            value: 'salarie',
            label: 'Salarié',
        },
        {
            value: 'independant',
            label: 'Indépendant',
        },
        {
            value: 'stagiaire',
            label: 'Stagiaire',
        },
        {
            value: 'alternant',
            label: 'Alternant',
        },
        {
            value: 'benevole',
            label: 'Bénévole',
        },
        {
            value: 'autre',
            label: 'Autre',
        },
    ],
    WORKERS_NUMBERS: [
        {
            value: '0',
            label: '0',
        },
        {
            value: '1-9',
            label: '1-9',
        },
        {
            value: '10-49',
            label: '10-49',
        },
        {
            value: '50-499',
            label: '50-499',
        },
        {
            value: '500-999',
            label: '500-999',
        },
        {
            value: '1000+',
            label: '1000+',
        },
    ],
    REPORTS: [
        {
            value: 'a-traiter',
            label: 'A traiter',
        },
        {
            value: 'en-cours',
            label: 'En cours',
        },
        {
            value: 'traite',
            label: 'Traité',
        },
    ],
    CANDIDAT_DASHBOARD: [
        {
            title: 'COMPLETER',
            text: 'Mentionnez vos atouts concurrentiels afin de vous démarquer des autres candidats',
        },
        {
            title: 'VERIFIER',
            text: 'Vérifiez que les informations vous concernant sur Internet sont positives et cohérentes avec votre CV et votre lettre de motivation',
        },
        {
            title: 'VIDEO',
            text: 'Dans le cas d’un entretien vidéo, vérifiez préalablement la qualité de votre connexion Internet et des accessoires (casque et micro)',
        },
        {
            title: 'MESSAGERIE',
            text: 'Assurez vous de la qualité du message délivré par votre messagerie. Ecoutez les messages laissés sur votre répondeur et enregistrez les plus importants. Supprimez ensuite les messages afin que votre messagerie ne soit pas saturée',
        },
        {
            title: 'TELEPHONE',
            text: 'Laissez votre téléphone sur vibreur. En cas d’appel d’un recruteur, ne décrochez pas pour avoir le temps de vous renseigner sur lui et son organisme avant de le recontacter',
        },
    ],
    CANDIDAT_SLIDES: [
        {
            text: 'Pour commencer, vous devez vous inscrire ou vous connecter sur le site, en remplissant les informations demandées',
        },
        {
            text: 'Vous recherchez les recruteurs par type d’organisme, secteur d’activité, effectif, localisation, mot clé...',
        },
        {
            text: 'Vous créez votre panier et vous y ajoutez ceux qui correspondent à vos critères de recherche',
        },
        {
            text: 'Lorsque vous avez terminé, il vous sera indiqué le coût de la diffusion de votre CV et de votre lettre de motivation aux recruteurs concernés',
        },
        {
            text: 'Vous pourrez copier/coller votre lettre de motivation, indiquer l’objet de votre email, importer votre CV et mentionner la date et l’heure souhaitées de diffusion',
        },
    ],
    RECRUTEUR_SLIDES: [
        {
            text: 'Pour commencer, vous devez vous inscrire ou vous connecter sur le site, en remplissant les informations demandées',
        },
        {
            text: 'Votre fiche recruteur sera (si accord de votre part) soumise au référencement à Google',
        },
        {
            text: 'Vous recevez gratuitement par courriel les candidatures qui vous concernent et pourrez les retrouver dans votre CVthèque personnelle',
        },
        {
            text: 'Vous pourrez accéder gratuitement à la CVthèque du site',
        },
    ],
    RECRUTEUR_DASHBOARD: [
        {
            title: 'INSCRIPTION',
            text: 'Vous vous inscrivez sur le site en remplissant les informations demandées.',
        },
        {
            title: 'VOTRE FICHE',
            text: 'En cas d’accord de votre part, votre fiche recruteur sera soumise au référencement à Google.',
        },
        {
            title: 'INSCRIPTION',
            text: 'Vous recevrez gratuitement par courriel les candidatures qui vous concernent et pourrez les retrouver dans votre CVthèque personnelle (Candidatures reçues).',
        },
        {
            title: 'CVTHEQUE',
            text: 'Vous pourrez accéder gratuitement à la CVthèque du site.',
        },
    ],
});
