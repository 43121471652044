<div class="page-sidebar">
    <a class="logo-box">
        <img src="./assets/img/logo-hd.png" />
    </a>
    <div class="page-sidebar-inner">
        <div class="page-sidebar-menu">
            <ul class="accordion-menu">
                <li *ngIf="type == 'admin' || type == 'super_admin'" [ngClass]="{ 'active-page': url === 'dashboard' }">
                    <a href="administration/dashboard"> <fa-icon [icon]="faHome"></fa-icon><span>Tableau de bord</span> </a>
                </li>
                <li
                    *ngIf="type == 'admin' || type == 'super_admin'"
                    [ngClass]="{
                        open: openCandidats,
                        'active-page': url === 'candidats'
                    }"
                    (click)="candidats()"
                >
                    <a>
                        <fa-icon [icon]="faUser"></fa-icon
                        ><span>Candidats ({{ nombreCandidats.fini + nombreCandidats.nonFini + nombreCandidats.bloque }})</span
                        ><fa-icon [icon]="faAngleLeft" class='accordion-icon'></fa-icon>
                    </a>
                    <ul
                        class="sub-menu"
                        [ngStyle]="{
                            display: openCandidats ? 'block' : 'none'
                        }"
                    >
                        <li [ngClass]="{ animation: openCandidats }">
                            <a target="_blank" href="administration/comptes-candidats/non-terminer"
                                >Non finalisé{{ nombreCandidats.nonFini | plural}} ({{ nombreCandidats.nonFini }})</a
                            >
                        </li>
                        <li [ngClass]="{ animation: openCandidats }">
                            <a target="_blank" href="administration/comptes-candidats/terminer">Finalisé{{ nombreCandidats.fini | plural}} ({{ nombreCandidats.fini }})</a>
                        </li>
                        <li [ngClass]="{ animation: openCandidats }">
                            <a target="_blank" href="administration/comptes-candidats/bloquer">Bloqué{{ nombreCandidats.bloque | plural}} ({{ nombreCandidats.bloque }})</a>
                        </li>
                      <li [ngClass]="{ animation: openCandidats }">
                        <a target="_blank" href="administration/comptes-candidats/desinscrits">Désinscrit{{ nombreCandidats.desinscrits | plural}} ({{ nombreCandidats.desinscrits }})</a>
                      </li>
                      <li [ngClass]="{ animation: openCandidats }">
                        <a target="_blank" href="administration/comptes-candidats/supprimes">Supprimé{{ nombreCandidats.supprime | plural}} ({{ nombreCandidats.supprime }})</a>
                      </li>
                    </ul>
                </li>
                <li
                    *ngIf="type == 'admin' || type == 'super_admin'"
                    [ngClass]="{
                        open: openRecruteurs,
                        'active-page': url === 'recruteurs'
                    }"
                    (click)="recruteurs()"
                >
                    <a>
                        <fa-icon [icon]="faUserTie"></fa-icon
                        ><span>Recruteurs ({{ nombreRecruteurs.fini + nombreRecruteurs.nonFini + nombreRecruteurs.bloque }})</span
                        ><fa-icon [icon]="faAngleLeft" class='accordion-icon'></fa-icon>
                    </a>
                    <ul
                        class="sub-menu"
                        [ngStyle]="{
                            display: openRecruteurs ? 'block' : 'none'
                        }"
                    >
                        <li [ngClass]="{ animation: openRecruteurs }">
                            <a target="_blank" href="administration/comptes-recruteurs/non-terminer"
                                >Non finalisé{{ nombreRecruteurs.nonFini | plural}} ({{ nombreRecruteurs.nonFini }})</a
                            >
                        </li>
                        <li [ngClass]="{ animation: openRecruteurs }">
                            <a target="_blank" href="administration/comptes-recruteurs/terminer">Finalisé{{ nombreRecruteurs.fini | plural}} ({{ nombreRecruteurs.fini }})</a>
                        </li>
                        <li [ngClass]="{ animation: openRecruteurs }">
                            <a target="_blank" href="administration/comptes-recruteurs/bloquer">Bloqué{{ nombreRecruteurs.bloque | plural}} ({{ nombreRecruteurs.bloque }})</a>
                        </li>
                      <li [ngClass]="{ animation: openRecruteurs }">
                        <a target="_blank" href="administration/comptes-recruteurs/desinscrits">Désinscrit{{ nombreRecruteurs.desinscrits | plural}} ({{ nombreRecruteurs.desinscrits }})</a>
                      </li>
                      <li [ngClass]="{ animation: openRecruteurs }">
                        <a target="_blank" href="administration/comptes-recruteurs/supprimes">Supprimé{{ nombreRecruteurs.supprime | plural}} ({{ nombreRecruteurs.supprime }})</a>
                      </li>
                    </ul>
                </li>
                <li [ngClass]="{ 'active-page': url === 'administrateurs' }" *ngIf="type == 'super_admin'">
                    <a href="administration/comptes-administrateurs">
                        <fa-icon [icon]="faUserCog"></fa-icon><span>Administrateurs ({{ nombreAdministrateurs }})</span>
                    </a>
                </li>
                <li [ngClass]="{ open: openDiffusions }" (click)="diffusions()">
                    <a>
                        <fa-icon [icon]="faHome"></fa-icon><span>Diffusions ({{ nombreDiffusions.total }})</span
                        ><fa-icon [icon]="faAngleLeft" class='accordion-icon'></fa-icon>
                    </a>
                    <ul
                        class="sub-menu"
                        [ngStyle]="{
                            display: openDiffusions ? 'block' : 'none'
                        }"
                    >
                        <li [ngClass]="{ animation: openDiffusions }">
                            <a target="_blank" href="administration/commande-en-attente">En attente ({{ nombreDiffusions.enAttente }})</a>
                        </li>
                        <li [ngClass]="{ animation: openDiffusions }">
                            <a target="_blank" href="administration/commande-en-refuser-temporairement"
                                >Annulé temporairement ({{ nombreDiffusions.annulerTemporairement }})</a
                            >
                        </li>
                        <li [ngClass]="{ animation: openDiffusions }">
                            <a target="_blank" href="administration/commande-en-refuser-definitivement"
                                >Annulé définitivement ({{ nombreDiffusions.annulerDefinitivement }})</a
                            >
                        </li>
                        <li [ngClass]="{ animation: openDiffusions }">
                            <a target="_blank" href="administration/commande-en-cours">En cours ({{ nombreDiffusions.enCours }})</a>
                        </li>
                        <li [ngClass]="{ animation: openDiffusions }">
                            <a target="_blank" href="administration/commande-terminer">Terminé ({{ nombreDiffusions.fini }})</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="type == 'super_admin'">
                    <a href="administration/tarifs"> <fa-icon [icon]="faBarcode"></fa-icon><span>Tarifs</span> </a>
                </li>
                <li *ngIf="type == 'super_admin'">
                    <a href="administration/codes-reductions"> <fa-icon [icon]="faPercent"></fa-icon><span>Codes réduction</span> </a>
                </li>
                <li>
                    <a href="administration/surveillance-photos">
                        <fa-icon [icon]="faPortrait"></fa-icon
                        ><span
                            >Surveillance images ({{
                                nombrePhotos.candidat + nombrePhotos.recruteurLogo + nombrePhotos.recruteurPhoto
                            }})</span
                        >
                    </a>
                </li>
                <li *ngIf="type == 'admin' || type == 'super_admin'" [ngClass]="{ open: openCourriels }" (click)="courriels()">
                    <a id="">
                        <fa-icon [icon]="faMailBuck"></fa-icon><span>Courriels</span> <fa-icon [icon]="faAngleLeft" class='accordion-icon'></fa-icon>
                    </a>
                    <ul
                        class="sub-menu"
                        [ngStyle]="{
                            display: openCourriels ? 'block' : 'none'
                        }"
                    >
                        <li [ngClass]="{ animation: openCourriels }" *ngFor="let m of mails">
                            <a target="_blank" href="administration/modifier-mail/{{ m._id }}">{{ m.nom }}</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="type == 'admin' || type == 'super_admin'">
                    <a href="administration/faqs"> <fa-icon [icon]="faQuestion"></fa-icon><span>FAQ</span> </a>
                </li>
                <li *ngIf="type == 'admin' || type == 'super_admin'">
                    <a href="administration/desabonnements"> <fa-icon [icon]="faAt"></fa-icon><span>Désabonnements</span> </a>
                </li>
              <li *ngIf="type == 'admin' || type == 'super_admin'" [ngClass]="{ open: openMetiers }" (click)="metiers()">
                <a >
                  <fa-icon [icon]="faBriefcase"></fa-icon><span>Métiers</span> <fa-icon [icon]="faAngleLeft" class='accordion-icon'></fa-icon>
                </a>
                <ul
                  class="sub-menu"
                  [ngStyle]="{
                            display: openMetiers ? 'block' : 'none'
                        }"
                >
                  <li [ngClass]="{ animation: openMetiers }" >
                    <a target="_blank" href="administration/liste-metiers">Liste</a>
                  </li>
                  <li [ngClass]="{ animation: openMetiers }" >
                    <a target="_blank" href="administration/metiers-non-assignes">Non assignés ( {{ nombreDemandesMetiers }} )</a>
                  </li>
                </ul>
              </li>
              <li *ngIf="type == 'admin' || type == 'super_admin'" [ngClass]="{ open: openMetiers }" (click)="secteurs()">
                <a >
                  <fa-icon [icon]="faChartPie"></fa-icon><span>Secteurs</span> <fa-icon [icon]="faAngleLeft" class='accordion-icon'></fa-icon>
                </a>
                <ul
                  class="sub-menu"
                  [ngStyle]="{
                            display: openSecteurs ? 'block' : 'none'
                        }"
                >
                  <li [ngClass]="{ animation: openSecteurs }" >
                    <a target="_blank" href="administration/liste-secteurs">Liste</a>
                  </li>
                  <li [ngClass]="{ animation: openSecteurs }" >
                    <a target="_blank" href="administration/secteurs-non-assignes">Non assignés ( {{ nombreDemandesSecteurs }} )</a>
                  </li>
                </ul>
              </li>
                <li *ngIf="type == 'admin' || type == 'super_admin'">
                    <a href="administration/temoignages-candidat">
                        <fa-icon [icon]="faPortrait"></fa-icon><span>Témoignages page candidat(s)</span>
                    </a>
                </li>
                <li *ngIf="type == 'admin' || type == 'super_admin'">
                    <a href="administration/temoignages-recruteur">
                        <fa-icon [icon]="faPortrait"></fa-icon><span>Témoignages page recruteur(s)</span>
                    </a>
                </li>
                <li *ngIf="type == 'super_admin'">
                    <a href="administration/traducteur"> <fa-icon [icon]="faPortrait"></fa-icon><span>Traducteur</span> </a>
                </li>
              <li *ngIf="type == 'admin' || type == 'super_admin'">
                <a href="administration/signalements"> <fa-icon [icon]="faFlag"></fa-icon><span>Signalements</span> </a>
              </li>
                <li *ngIf="type == 'super_admin'">
                    <a href="administration/multilingues"> <fa-icon [icon]="faPortrait"></fa-icon><span>Multilingues</span> </a>
                </li>
                <li *ngIf="type == 'super_admin'">
                    <a href="administration/sauvegardes"> <fa-icon [icon]="faFolder"></fa-icon><span>Sauvegardes</span> </a>
                </li>
                <li *ngIf="type == 'super_admin'">
                    <a href="administration/newsletters"> <fa-icon [icon]="faFolder"></fa-icon><span>Newsletters</span> </a>
                </li>
              <li *ngIf="type == 'super_admin'">
                <a href="administration/avantages/recruteur"> <fa-icon [icon]="faGift"></fa-icon><span>Avantages Recruteurs</span> </a>
              </li>
              <li *ngIf="type == 'super_admin'">
                <a href="administration/avantages/candidat"> <fa-icon [icon]="faGift"></fa-icon><span>Avantages Candidats</span> </a>
              </li>
                <!--li *ngIf="type=='super_admin'">
          <a href="administration/linkedin">
            <fa-icon [icon]="faFolder"></fa-icon><span>Linkedin</span>
          </a>
        </li-->
                <li>
                    <a (click)="deconnexion()"> <fa-icon [icon]="faSignOut"></fa-icon><span>Déconnexion</span> </a>
                </li>
            </ul>
        </div>
    </div>
</div>
