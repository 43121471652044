<app-site-header></app-site-header>
<div
    mdbModal
    #removeModal="mdbModal"
    class="modal fade top"
    id="frameModalTop"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
        <!--Content-->
        <div class="modal-content text-center">
            <!--Body-->
            <div class="modal-body">
                <h4 class="font-weight-bold pb-2">REFUSER UNE CANDIDATURE RECUE</h4>
                Vous êtes sur le point de refuser une candidature reçue. Une fois supprimée, vous ne pourrez plus la consulter.
            </div>
            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a
                    (click)="retrait(currentCand); removeModal.hide()"
                    mdbBtn
                    color="danger"
                    style="border: 2px solid #d89e9e !important; color: #d89e9e !important"

                    size="lg"
                    [outline]="true"
                    class="waves-effect"
                    mdbWavesEffect
                    >Oui</a
                >
                <a
                    type="button"
                    mdbBtn
                    color="danger"
                    class="waves-effect"
                    size="lg"
                    mdbWavesEffect
                    data-dismiss="modal"
                    (click)="removeModal.hide()"
                    >Non</a
                >
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<app-modal-candidat [candidat]='candidat' [lettreMotivation]='lettreMotivation' [cv]='cv' #candidatModal></app-modal-candidat>

<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-recruteur-header></app-recruteur-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10 pl-0 pr-0" style="margin-top: 25px">
        <div class="page-inner">
            <div class="page-title">
                <h3 class="breadcrumb-header">Candidatures reçues</h3>
            </div>
            <div class="candidature-wrapper">
                <div class="candidature-top-container">
                    <div class="candidature-filter flex-wrap">
                        <div class="col-6 col-md-3 md-form">
                            <input
                                type="text"
                                name="motcles"
                                id="motcles"
                                [(ngModel)]="recherche.motCles"
                                (ngModelChange)="getCandidature()"
                                class="form-control bc-white mb-0"
                                placeholder="Mot clés..."
                            />
                        </div>
                        <div class="col-6 col-md-3 md-form">
                            <mdb-select-2
                                placeholder="Situation du candidat"
                                name="situation"
                                class="custom-selectbox"
                                [(ngModel)]="recherche.situation"
                                [outline]="true"
                            >
                                <mdb-select-option *ngFor="let option of typesSituations" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </div>
                        <div class="col-6 col-md-3 md-form">
                            <mdb-select-2
                                placeholder="Expérience.."
                                name="secteurActivite"
                                class="custom-selectbox"
                                [multiple]="true"
                                [(ngModel)]="recherche.experience"
                                [outline]="true"
                            >
                                <mdb-select-option *ngFor="let option of typesExperiencesSecteurs" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </div>
                        <div class="col-6 col-md-3 md-form">
                            <input
                                type="text"
                                name="metiersRechercher"
                                class="completer-input form-control mdb-autocomplete mb-0 bc-white"
                                [ngModel]="searchMetiers | async"
                                (ngModelChange)="searchMetiers.next($event)"
                                [mdbAutoCompleter]="autoMetier"
                                (clearBtnClicked)="clearMetier()"
                                placeholder="Rechercher un métier"
                            />
                            <mdb-auto-completer class="visible" #autoMetier="mdbAutoCompleter" textNoResults="Pas de résultat">
                                <mdb-option
                                    *ngFor="let option of resultsMetiers | async"
                                    [value]="option.nomMetier"
                                    (click)="chooseMetiers(option.codeOgr, option.nomMetier)"
                                >
                                    <div class="d-flex flex-column">
                                        <strong>{{ option.nomMetier }}</strong>
                                    </div>
                                </mdb-option>
                            </mdb-auto-completer>
                        </div>
                        <div class="col-6 col-md-3 md-form">
                          <app-filter-secteur  #searchSecteurs (refresh)='getCandidature()' [(data)]='recherche.secteurs' [label]="'Secteurs d\'activités'">
                          </app-filter-secteur>
                        </div>
                        <div class="col-6 col-md-3 md-form">
                            <mdb-select-2
                                placeholder="Niveau d'étude"
                                name="typeEntreprise"
                                class="custom-selectbox"
                                [(ngModel)]="recherche.niveauEtude"
                                [outline]="true"
                                [multiple]="true"
                            >
                                <mdb-select-option *ngFor="let option of typesEtudes" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </div>
                        <div class="col-6 col-md-3 md-form">
                            <mdb-select-2
                                placeholder="Pays"
                                [notFoundMsg]="'Pays non trouvé'"
                                [(ngModel)]="recherche.paysCode"
                                name="paysCode"
                                [outline]="true"
                            >
                                <mdb-select-filter class="bc-white" [ngModel]="searchPays | async" (inputChange)="searchPays.next($event)">
                                </mdb-select-filter>
                                <mdb-select-option
                                    *ngFor="let option of filteredOptions | async"
                                    [value]="option.code"
                                    (click)="choosePays(option.pays, option.code)"
                                    >{{ option.pays }}</mdb-select-option
                                >
                            </mdb-select-2>
                        </div>
                        <div class="col-6 col-md-3">
                            <app-select-city
                                #searchCity
                                (selected)="getCandidature()"
                                (clear)="clearCity()"
                                [ville]="recherche"
                            ></app-select-city>
                        </div>
                        <div class="offset-6 offset-md-9 col-6 col-md-3 mb-3">
                            <label for="range" class="mb-2">Rayon</label>

                            <div class="d-flex">
                                <span class="font-weight-bold indigo-text mr-2">{{ min }}</span>
                                <form class="w-100">
                                    <mdb-range-input
                                        class=""
                                        (ngModelChange)="getCandidature()"
                                        id="range"
                                        name="range"
                                        [min]="min"
                                        [(ngModel)]="rayon"
                                        [max]="max"
                                    ></mdb-range-input>
                                </form>
                                <span class="font-weight-bold indigo-text ml-2">{{ max }}</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <button
                                (click)="getCandidature()"
                                [rounded]="true"
                                mdbBtn
                                type="button"
                                color="warning"
                                size="lg"
                                class="float-right mb-4 mb-md-2"
                                mdbWavesEffect
                                [block]="isMobile"
                            >
                                <fa-icon [icon]="faSearch" class="mr-1"></fa-icon>Rechercher
                            </button>
                            <button
                                (click)="reinitiliaser()"
                                [rounded]="true"
                                mdbBtn
                                type="button"
                                color="success"
                                size="lg"
                                class="float-right"
                                mdbWavesEffect
                                [block]="isMobile"
                            >
                                Réinitialiser
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <p *ngIf="rechercheBase" class="form-row" style="display: block; width: 20px; margin: auto">
                <img src="./assets/img/gif.gif" style="max-width: 25px; text-align: center" />
            </p>

            <div *ngIf="isMobile">
                <div class="mobile-checkbox">
                    <div *ngFor="let c of candidatures; let i = index" class="mobile-check-container">
                        <div class="mobile-check col-10 pl-0 pr-0">
                            <h5>
                                {{ c.candidature.nom }} / {{ c.candidature.prenom }} -
                                {{ c.candidature.situation | situation }}
                            </h5>
                            <h6>
                                {{ c.candidature.posteRechercher != null ? (c.candidature.posteRechercher[0].nom | firstUpperText) + ',' : '' }}
                                {{ c.candidature.disponibilite != '' ? c.candidature.disponibilite + ',' : '' }}
                                {{ c.candidature.experience != null ? (c.candidature.experience | experience) + ',' : '' }}
                              <span *ngIf="c.remunerationSouhaiter != null && (c.remunerationSouhaiter[0] !== 0 || c.remunerationSouhaiter[1] !== 0 || c.remunerationSouhaiter[2] !== 0 || c.remunerationSouhaiter[3] !== 0)">
                                    {{ c.candidature.remunerationSouhaiter | remuneration }}
                                </span>
                            </h6>
                            <a (click)="loadCandidat(c.candidature._id)">
                                <p>
                                    Fiche candidat
                                    <fa-icon [icon]="faFileContract"></fa-icon></p
                            ></a>
                        </div>
                        <a class="btn btn-danger" target="_blank" (click)="retrait(c.candidature._id)"
                            ><fa-icon [icon]="faTimes"></fa-icon
                        ></a>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <button
                        [disabled]="!canLoadMore"
                        (click)="loadMore()"
                        [rounded]="true"
                        mdbBtn
                        type="button"
                        color="success"
                        size="lg"
                        class="mb-5"
                        mdbWavesEffect
                    >
                        <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>
                        Afficher plus
                    </button>
                </div>
            </div>

            <table
                [ngStyle]="{ display: display() }"
                id="tableSort"
                mdbTable
                #tableEl="mdbTable"
                class="display custom-table mt-5"
                [responsive]="true"
            >
                <thead>
                    <tr>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="candidatures" sortBy="date">
                            Reçue le <fa-icon [icon]="faSort"></fa-icon>
                        </th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="candidatures" sortBy="candidature.nom">
                            Nom / Prénom <fa-icon [icon]="faSort"></fa-icon>
                        </th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="candidatures" sortBy="candidature.fonction.nom">
                            Métier actuel <fa-icon [icon]="faSort"></fa-icon>
                        </th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="candidatures" sortBy="candidature.experience">
                            Expérience globale
                            <fa-icon [icon]="faSort"></fa-icon>
                        </th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="candidatures" sortBy="candidature.niveauEtude">
                            Niveau d'étude <fa-icon [icon]="faSort"></fa-icon>
                        </th>
                        <th aria-controls="tableSortExample" scope="col">Secteur d’activité</th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="candidatures" sortBy="candidature.situation">
                            Situation <fa-icon [icon]="faSort"></fa-icon>
                        </th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="candidatures" sortBy="derniereLecture">
                            Dernière lecture <fa-icon [icon]="faSort"></fa-icon>
                        </th>
                        <th aria-controls="tableSortExample" scope="col">Fiche</th>
                        <th aria-controls="tableSortExample" scope="col">Retirer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="candidatures.length === 0">
                        Aucune fiche candidat ne répond à vos critères de recherche.
                    </tr>
                    <tr *ngFor="let c of candidatures; let i = index">
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            {{ c.date * 1000 | date: 'dd-MM-yyyy' }}
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            {{ c.candidature.nom }} / {{ c.candidature.prenom }}
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            {{ c.candidature.fonction.nom }}
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            {{ c.candidature.experience | experience }}
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            {{ c.candidature.niveauEtude | etudes }}
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                          <div *ngFor="let s of c.candidature.secteurActivite; let index = index; let isOdd = odd">
                            <span *ngIf="index <= 1">{{ s.nom }}</span>
                            <span *ngIf="isOdd && index == 3">...</span>
                          </div>
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            {{ c.candidature.situation | situation }}
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            <div *ngIf="c.derniereLecture === '0'">En attente de lecture</div>
                            <div *ngIf="c.derniereLecture !== '0'">
                                {{ c.derniereLecture * 1000 | date: 'dd-MM-yyyy HH:mm' }}
                            </div>
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            <a class="btn btn-success" (click)="loadCandidat(c.candidature._id)"
                                ><fa-icon [icon]="faFileContract"></fa-icon
                            ></a>
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            <a class="btn btn-danger" target="_blank" (click)="currentCand = c.candidature._id; removeModal.show()"
                                ><fa-icon [icon]="faTimes"></fa-icon
                            ></a>
                        </td>
                    </tr>
                </tbody>
                <tfoot class="grey lighten-5 w-100">
                    <tr>
                        <td [colSpan]="headElements.length">
                            <mdb-table-pagination
                                ofKeyword="de"
                                [tableEl]="tableEl"
                                [searchDataSource]="candidatures"
                            ></mdb-table-pagination>
                        </td>
                    </tr>
                    <tr>
                        <td [colSpan]="headElements.length" style="border-top: none !important; border-bottom: none !important">
                            <mdb-select-2
                                placeholder="Afficher 10/ page"
                                (selected)="changeItemsByPage($event)"
                                style="float: right; min-width: 174px"
                                name="itemsByPage"
                                [outline]="true"
                            >
                                <mdb-select-option *ngFor="let option of displayByPages" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
