<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-candidat/informations-personnelles" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pl-0 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-candidat/informations-personnelles" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span style="margin-right: 5rem">Ma situation</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Ma situation</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Gardez vos informations concernant votre situation à jour</h2>
            </div>

            <div class="form-group inscription-row col-12 col-md-12">
                <label for="fonction">Intitulé du poste actuellement ou dernièrement occupé *</label>
                <span
                    for="fonction"
                    class="error"
                    [ngStyle]="{
                        display: this.error.fonctionLibre ? 'block' : 'none'
                    }"
                    >Obligatoire</span
                >
                <input type="text" [(ngModel)]="candidat.fonctionLibre" name="fonction" id="fonction" class="form-control col-12 col-md-6" />
            </div>

            <div class="inscription-row">
              <app-poste class='d-flex flex-wrap' [single]='true' [(data)]='candidat.fonction' [(recherche)]='actuel' [typeMetier]='"Fonction"'>
                <label for="fonction">Métier s'en rapprochant le plus *</label>
                <span
                  for="secteurActivite"
                  class="error"
                  [ngStyle]="{
                                        display: this.error.fonction ? 'block' : 'none'
                                    }"
                >Obligatoire</span
                >
              </app-poste>
            </div>

            <div class="inscription-row">
              <app-secteur class='d-flex flex-wrap' [showHelp]='true' [(data)]='candidat.secteurActivite' [typeSecteur]='"Perso"'>
                <label for="secteursRechercher">Secteur(s) d'activité * <small>Ce champ est multiple</small></label>
                <span
                  for="secteurActivite"
                  class="error"
                  [ngStyle]="{
                                        display: this.error.secteurActivite ? 'block' : 'none'
                                    }"
                >Obligatoire</span
                >
              </app-secteur>
            </div>

            <div class="d-flex flex-wrap inscription-row">
                <div class="cold-12 col-md-4">
                    <label>Expérience dans ce métier *</label>
                    <mdb-select-2
                        [(ngModel)]="candidat.experienceSecteur"
                        placeholder="Choisir.."
                        name="secteurActivite"
                        class="custom-selectbox"
                        [outline]="true"
                    >
                        <mdb-select-option *ngFor="let option of typesExperiencesSecteurs" [value]="option.value">{{
                            option.label
                        }}</mdb-select-option>
                    </mdb-select-2>
                </div>

                <div class="cold-12 col-md-4 mt-3 mt-md-0">
                    <label>Expérience globale *</label>
                    <span
                        for="secteurActivite"
                        class="error"
                        [ngStyle]="{
                            display: this.error.experience ? 'block' : 'none'
                        }"
                        >Obligatoire</span
                    >
                    <mdb-select-2
                        [(ngModel)]="candidat.experience"
                        placeholder="Choisir.."
                        name="typeEntreprise"
                        class="custom-selectbox"
                        [outline]="true"
                    >
                        <mdb-select-option *ngFor="let option of typesExperiences" [value]="option.value">{{
                            option.label
                        }}</mdb-select-option>
                    </mdb-select-2>
                </div>

                <div class="cold-12 col-md-4 mt-3 mt-md-0">
                    <label>Niveau d'étude *</label>
                    <span
                        for="secteurActivite"
                        class="error"
                        [ngStyle]="{
                            display: this.error.niveauEtude ? 'block' : 'none'
                        }"
                        >Obligatoire</span
                    >
                    <mdb-select-2
                        [(ngModel)]="candidat.niveauEtude"
                        placeholder="Choisir.."
                        name="typeEntreprise"
                        class="custom-selectbox"
                        [outline]="true"
                    >
                        <mdb-select-option *ngFor="let option of typesEtudes" [value]="option.value">{{ option.label }}</mdb-select-option>
                    </mdb-select-2>
                </div>
            </div>

            <div class="d-flex flex-wrap inscription-row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="langue">Langues * <small>( Ce champ est multiple )</small></label>
                        <span
                            for="secteurActivite"
                            class="error"
                            [ngStyle]="{
                                display: this.error.langues ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >

                        <div class="md-form">
                            <input
                                type="text"
                                name="langue"
                                class="completer-input form-control mdb-autocomplete mb-0"
                                [ngModel]="searchLangues | async"
                                (ngModelChange)="searchLangues.next($event)"
                                [mdbAutoCompleter]="autoLangues"
                                placeholder="Rechercher une langue"
                            />
                            <mdb-auto-completer class="visible" #autoLangues="mdbAutoCompleter" textNoResults="Pas de résultat">
                                <mdb-option
                                    *ngFor="let option of resultsLangues | async"
                                    [value]="option.l"
                                    (click)="chooseLangues(option.langue)"
                                >
                                    <div class="d-flex flex-column">
                                        <strong>{{ option.langue }}</strong>
                                    </div>
                                </mdb-option>
                            </mdb-auto-completer>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div style="position: relative" *ngFor="let l of candidat.langues">
                        <div style="position: relative">
                            <label>{{ l.langue }}</label>
                            <div class="form-group radio_input">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Parlé *</label>
                                        <div class="form-group">
                                            <label class="container_radio mr-3" style="width: 100%"
                                                >Langue maternelle
                                                <input
                                                    type="radio"
                                                    name="parler_{{ l.langue }}"
                                                    value="maternelle"
                                                    [(ngModel)]="l.parle"
                                                    [checked]="l.parle === 'maternelle'"
                                                />
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="container_radio mr-3" style="width: 100%"
                                                >Excellent
                                                <input
                                                    type="radio"
                                                    name="parler_{{ l.langue }}"
                                                    value="excellent"
                                                    [(ngModel)]="l.parle"
                                                    [checked]="l.parle === 'excellent'"
                                                />
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="container_radio mr-3" style="width: 100%"
                                                >Bon
                                                <input
                                                    type="radio"
                                                    name="parler_{{ l.langue }}"
                                                    value="bon"
                                                    [(ngModel)]="l.parle"
                                                    [checked]="l.parle === 'bon'"
                                                />
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="container_radio mr-3" style="width: 100%"
                                                >Scolaire
                                                <input
                                                    type="radio"
                                                    name="parler_{{ l.langue }}"
                                                    value="scolaire"
                                                    [(ngModel)]="l.parle"
                                                    [checked]="l.parle === 'maternelle'"
                                                />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Ecrit *</label>
                                        <div class="form-group">
                                            <label class="container_radio mr-3" style="width: 100%"
                                                >Langue maternelle
                                                <input
                                                    type="radio"
                                                    name="ecrit_{{ l.langue }}"
                                                    value="maternelle"
                                                    [(ngModel)]="l.ecrit"
                                                    [checked]="l.ecrit === 'maternelle'"
                                                />
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="container_radio mr-3" style="width: 100%"
                                                >Excellent
                                                <input
                                                    type="radio"
                                                    name="ecrit_{{ l.langue }}"
                                                    value="excellent"
                                                    [(ngModel)]="l.ecrit"
                                                    [checked]="l.ecrit === 'excellent'"
                                                />
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="container_radio mr-3" style="width: 100%"
                                                >Bon
                                                <input
                                                    type="radio"
                                                    name="ecrit_{{ l.langue }}"
                                                    value="bon"
                                                    [(ngModel)]="l.ecrit"
                                                    [checked]="l.ecrit === 'bon'"
                                                />
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="container_radio mr-3" style="width: 100%"
                                                >Scolaire
                                                <input
                                                    type="radio"
                                                    name="ecrit_{{ l.langue }}"
                                                    value="scolaire"
                                                    [(ngModel)]="l.ecrit"
                                                    [checked]="l.ecrit === 'scolaire'"
                                                />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="container-cross" (click)="removeLangue(l.langue)">
                                <div class="cross">x</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center" style="position: relative">
                <p class="mention-obligatoire" style="color: red !important">* Obligatoire</p>
            </div>
            <div class="d-flex float-right mb-3">
                <button mdbBtn class="text-center" [rounded]="true" color="warning" size="lg" (click)="sendSituation()">Valider</button>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
