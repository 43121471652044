import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-desabonnement',
    templateUrl: './desabonnement.component.html',
    styleUrls: ['./desabonnement.component.css'],
})
// tslint:disable-next-line:component-class-suffix
export class Desabo implements OnInit {
    idCommande = '';
    fini = false;
    emailStatus = false;
    emailErr = false;
    emailRecruteur = '';
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.idCommande = this.route.snapshot.params.idCommande;
    }
    valider(): void {
        this.emailErr = false;
        if (!this.emailStatus) {
            this.httpClient
                .post<any>(this.globalService.url + '/commande/verifier-email-desabonnement', {
                    idCommande: this.idCommande,
                    emailRecruteur: this.emailRecruteur,
                })
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this.emailStatus = true;
                            this.fini = true;
                        } else {
                            this.emailErr = true;
                        }
                    },
                    () => {
                        this.emailErr = true;
                    }
                );
        }
    }
}
