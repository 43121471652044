<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10" style="margin-top: 25px">
        <div class="page-inner">
            <div class="page-title">
                <h3 class="breadcrumb-header">Recruteurs ne souhaitant plus recevoir ma candidature</h3>
            </div>
            <div id="main-wrapper">
                <div class="row">
                    <div class="col-md-12">
                        <div class="" [hidden]="desabonnements.length > 0">Vous n'avez pas de désabonnement</div>
                        <div class="" [hidden]="desabonnements.length === 0">
                            <div class="">
                                <div class="table-responsive">
                                    <table id="tableSort" mdbTable #tableEl="mdbTable" class="custom-table">
                                        <thead>
                                            <tr>
                                                <th
                                                    *ngFor="let head of headElements; let i = index"
                                                    aria-controls="tableSortExample"
                                                    scope="col"
                                                    [mdbTableSort]="desabonnements"
                                                    [sortBy]="headElements[i]"
                                                >
                                                    {{ head }}
                                                    <mdb-icon fas icon="sort"></mdb-icon>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let el of desabonnements; let i = index">
                                                <td
                                                    *ngIf="
                                                        i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex
                                                    "
                                                >
                                                    {{ el.referenceRecruteur }}
                                                </td>
                                                <td
                                                    *ngIf="
                                                        i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex
                                                    "
                                                >
                                                    {{ el.timestamp * 1000 | date: 'dd-MM-yyyy HH:mm' }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="grey lighten-5 w-100">
                                            <tr>
                                                <td colspan="4">
                                                    <mdb-table-pagination
                                                        ofKeyword="de"
                                                        [tableEl]="tableEl"
                                                        [searchDataSource]="desabonnements"
                                                    ></mdb-table-pagination>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
