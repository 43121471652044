<app-site-header page="renouvellement-mot-de-passe"></app-site-header>
<div class="row light_bg full-height align-items-center d-flex contact-1" style="width: 100%; margin: 0">
    <div class="container">
        <div class="vc_column-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="oi_vc_heading oi_border_position_center">
                        <h1 class="oi_icon_titile" style="text-align: center">RENOUVELER MON MOT DE PASSE</h1>
                        <div class="mention-text row">
                            <div class="woocommerce offset-md-3 col-md-6">
                                <div style="margin: 15px 0">
                                    <div class="mention-text">
                                        <div
                                            style="color: green !important"
                                            [ngStyle]="{
                                                color: renouveller.longueur ? 'green' : 'red'
                                            }"
                                        >
                                            <fa-icon [icon]="faOk" *ngIf="renouveller.longueur"></fa-icon>
                                            <fa-icon [icon]="faError" *ngIf="!renouveller.longueur"></fa-icon>
                                            Contient 10 caractères.
                                        </div>
                                        <div
                                            style="color: green !important"
                                            [ngStyle]="{
                                                color: renouveller.miniscule ? 'green' : 'red'
                                            }"
                                        >
                                            <fa-icon [icon]="faOk" *ngIf="renouveller.miniscule"></fa-icon>
                                            <fa-icon [icon]="faError" *ngIf="!renouveller.miniscule"></fa-icon>
                                            Contient au moins une minuscule.
                                        </div>
                                        <div
                                            style="color: green !important"
                                            [ngStyle]="{
                                                color: renouveller.majuscule ? 'green' : 'red'
                                            }"
                                        >
                                            <fa-icon [icon]="faOk" *ngIf="renouveller.majuscule"></fa-icon>
                                            <fa-icon [icon]="faError" *ngIf="!renouveller.majuscule"></fa-icon>
                                            Contient au moins une majuscule.
                                        </div>
                                        <div
                                            style="color: green !important"
                                            [ngStyle]="{
                                                color: renouveller.chiffre ? 'green' : 'red'
                                            }"
                                        >
                                            <fa-icon [icon]="faOk" *ngIf="renouveller.chiffre"></fa-icon>
                                            <fa-icon [icon]="faError" *ngIf="!renouveller.chiffre"></fa-icon>
                                            Contient au moins un chiffre.
                                        </div>
                                        <div
                                            style="color: green !important"
                                            [ngStyle]="{
                                                color: renouveller.caractereSpeciale ? 'green' : 'red'
                                            }"
                                        >
                                            <fa-icon [icon]="faOk" *ngIf="renouveller.caractereSpeciale"></fa-icon>
                                            <fa-icon [icon]="faError" *ngIf="!renouveller.caractereSpeciale"></fa-icon>
                                            Contient au moins un caractère spécial.
                                        </div>
                                        <div
                                            style="color: green !important"
                                            [ngStyle]="{
                                                color: renouveller.identique ? 'green' : 'red'
                                            }"
                                        >
                                            <fa-icon [icon]="faOk" *ngIf="renouveller.identique"></fa-icon>
                                            <fa-icon [icon]="faError" *ngIf="!renouveller.identique"></fa-icon>
                                            {{ errorIdentique }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" style="position: relative">
                                    <label for="password">Nouveau mot de passe</label>
                                    <input [type]="type1" name="password" id="password" [(ngModel)]="password.un" />
                                    <fa-icon class="eye" (click)="eye(1)" [icon]="eye1"></fa-icon>
                                </div>
                                <div class="form-group" style="position: relative">
                                    <label for="passwordRenew">Répéter nouveau Mot de passe</label>
                                    <input [type]="type2" name="passwordRenew" id="passwordRenew" [(ngModel)]="password.deux" />
                                    <fa-icon class="eye" (click)="eye(2)" [icon]="eye2"></fa-icon>
                                </div>
                                <div class="form-row" style="display: block">
                                    <div
                                        *ngIf="afficher"
                                        [ngClass]="status ? 'green' : 'red'"
                                        style="text-align: center; margin: auto; width: auto; margin-bottom: 15px"
                                    >
                                        {{ finText }}
                                    </div>
                                    <div style="display: flex; margin: auto">
                                        <button
                                            (click)="sauvegarder()"
                                            class="btn"
                                            style="background: rgb(228, 167, 0); color: white; margin: auto"
                                        >
                                            Changer mon mot de passe
                                        </button>
                                    </div>
                                    <div
                                        *ngIf="afficher && status && typeCompte == 'recruteur'"
                                        style="display: flex; margin: auto; margin-top: 15px"
                                    >
                                        <a
                                            class="btn"
                                            routerLink="/recruteur"
                                            style="background: rgb(1, 73, 179); color: white; margin: auto"
                                            >Me connecter</a
                                        >
                                    </div>
                                    <div
                                        *ngIf="afficher && status && typeCompte == 'candidat'"
                                        style="display: flex; margin: auto; margin-top: 15px"
                                    >
                                        <a
                                            class="btn"
                                            routerLink="/candidat"
                                            style="background: rgb(1, 73, 179); color: white; margin: auto"
                                            >Me connecter</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
