import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'workersNumber',
})
export class WorkersNumberPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (value === '0') {
            return '0';
        } else if (value === '1-9') {
            return '1 à 9';
        } else if (value === '10-49') {
            return '10 à 49';
        } else if (value === '50-499') {
            return '50 à 499';
        } else if (value === '500-999') {
            return '500 à 999';
        } else if (value === '1000+') {
            return 'plus de 1000';
        }
    }
}
