import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IMyOptions, MDBDatePickerComponent, LocaleService } from '../mdb/pro/date-picker';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.css'],
})
export class DatepickerComponent implements OnInit {
    @ViewChild('datePicker', { static: true }) datePicker!: MDBDatePickerComponent;

    myDatePickerOptions: IMyOptions = {
        closeAfterSelect: true,
        minYear: 1920,
    };
    model: any;

    @Input() sel: any;

    @Input() date: any;
    @Output() dateChange = new EventEmitter<string>();

    locales = {
        fr: {
            dayLabels: { su: 'Lun', mo: 'Mar', tu: 'Mer', we: 'Je', th: 'Ven', fr: 'Sam', sa: 'Dim' },
            dayLabelsFull: { su: 'Dimanche', mo: 'Lundi', tu: 'Mardi', we: 'Mercredi', th: 'Jeudi', fr: 'Vendredi', sa: 'Samedi' },
            monthLabels: {
                1: 'Jan',
                2: 'Fev',
                3: 'Mar',
                4: 'Avr',
                5: 'Mai',
                6: 'Juin',
                7: 'Jui',
                8: 'Août',
                9: 'Sep',
                10: 'Oct',
                11: 'Nov',
                12: 'Dec',
            },
            monthLabelsFull: {
                1: 'Janvier',
                2: 'Fevrier',
                3: 'Mars',
                4: 'Avril',
                5: 'Mai',
                6: 'Juin',
                7: 'Juillet',
                8: 'Août',
                9: 'Septembre',
                10: 'Octobre',
                11: 'Novembre',
                12: 'Decembre',
            },
            dateFormat: 'dd-mm-yyyy',
            todayBtnTxt: 'Auj',
            clearBtnTxt: 'Effacer',
            closeBtnTxt: 'Fermer',
            firstDayOfWeek: 'mo',
            sunHighlight: true,
        },
    };

    constructor(private localeService: LocaleService) {
        this.localeService.setLocaleOptions(this.locales);
    }

    ngOnInit(): void {}

    onDateChange($event: any): void {
        this.dateChange.emit($event.actualDateFormatted);
    }
}
