import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { ToastService } from '../../../components/mdb/pro/alerts';

@Component({
    selector: 'app-secteurs-non-assignes',
    templateUrl: './secteurs-non-assignes.component.html',
    styleUrls: [
        './secteurs-non-assignes.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/jquery.datatables.min.css',
    ],
})
export class SecteursNonAssignesComponent implements OnInit {
    type: string | null = '';
    tri = 'dateCreation';
    sens = 'desc';
    recherche = '';
    requests: any = [];
    nouveauSecteur = {
        groupe: '',
        sousGroupe: '',
        groupeErr: false,
        sousGroupeErr: false,
    };
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {}
    ngOnInit(): void {
        this.getRequests();
    }
    getRequests(): void {
        this.httpClient
            .post<any>(
                this.globalService.url + '/requests',
                {
                    type: 'secteur',
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.requests = response.requests;
                    } else {
                        this.requests = [];
                    }
                },
                () => {
                    this.requests = [];
                }
            );
    }

    ajouter(): void {
        if (this.nouveauSecteur.groupe === '') {
            this.nouveauSecteur.groupeErr = true;
            return;
        }

        if (this.nouveauSecteur.sousGroupe === '') {
            this.nouveauSecteur.sousGroupeErr = true;
            return;
        }
        this.httpClient
            .post(this.globalService.url + '/secteurs/add', {
                groupe: this.nouveauSecteur.groupe,
                sousGroupe: this.nouveauSecteur.sousGroupe,
            })
            .subscribe(
                (response) => {
                    this.toastrService.info('Secteur ajouté', '', { opacity: 1 });
                    this.nouveauSecteur = {
                        groupe: '',
                        sousGroupe: '',
                        groupeErr: false,
                        sousGroupeErr: false,
                    };
                },
                () => {}
            );
    }

    supprimer(id: string): void {
        this.httpClient.delete(this.globalService.url + '/requests/' + id).subscribe(
            (response) => {
                this.getRequests();
                // this.toastrService.info('Métier supprimé', '', { opacity: 1 });
            },
            () => {}
        );
    }
}
