<div class="col-12 col-md-6 pl-0 pr-0">
  <div class="form-group">
    <ng-content></ng-content>
    <div class="md-form"  *ngIf="((data === '' || data === null || data === undefined || data === {}) && single) || !single">
      <input
        type="text"
        name="posteRechercher"
        class="completer-input form-control mdb-autocomplete mb-0"
        [ngModel]="searchMetiers | async"
        (ngModelChange)="searchMetiers.next($event)"
        [mdbAutoCompleter]="autoMetier"
        placeholder="Rechercher un métier"
      />
      <mdb-auto-completer class="visible" #autoMetier="mdbAutoCompleter" textNoResults="Pas de résultat">
        <mdb-option
          *ngFor="let option of resultsMetiers | async"
          [value]="option.nomMetier"
          (click)="single ? setSingle(option.codeOgr, option.nomMetier) : choosePosteRecherche(option.codeOgr, option.nomMetier)"
        >
          <div class="d-flex flex-column">
            <strong>{{ option.nomMetier }}</strong>
          </div>
        </mdb-option>
      </mdb-auto-completer>
      <div *ngIf='!admin'>
      <br />
      <a target="_blank" href="{{ this.url }}/documents/site/metiers.pdf"
      ><fa-icon [icon]="faFilePdf"></fa-icon> Liste des métiers</a
      >
      <br/>
      <br/>
      <a *ngIf='!showRequest' (click)='showRequest=true' class='font-weight-bold' style='text-decoration: underline; font-size: 15px '>Signaler au responsable du site que je ne trouve pas le métier afin qu'il soit ajouté à la liste</a>

      <div *ngIf='showRequest' class='row'>
        <div class='col-8'>
          <input
            type="text"
            class="form-control"
            [(ngModel)]='request'
            placeholder="Saisir"
          />
        </div>
        <div class='col-4'>
          <a
            type="button"
            style="color: white"
            (click)="sendPosteRequest()"
            mdbBtn
            [rounded]="true"
            color="warning"
            class="waves-effect"
            mdbWavesEffect
          >Envoyer
          </a>
        </div>
      </div>
        </div>

    </div>
  </div>
</div>

<div class="col-12 col-md-6">
  <div *ngIf="(data !== '' && data !== {} && data !== null && data !== undefined && single)">
  <div style="position: relative">
    <label class="container_radio version_2" style="padding-left: 16px"
    >{{ data.nom }}
      <input type="radio" disabled />
    </label>
    <div class="container-cross" (click)="removeSingle()">
      <div class="cross">x</div>
    </div>
  </div>
  </div>
  <div *ngIf='!single'>
    <div style="position: relative" *ngFor="let p of data">
      <label class="container_radio version_2" style="padding-left: 16px"
      >{{ p.nom }}
        <input type="radio" disabled />
      </label>
      <div class="container-cross" (click)="removePosteRecherche(p.codeOgr)">
        <div class="cross">x</div>
      </div>
    </div>
  </div>
</div>
