import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import 'quill-mention';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-lettre-motivation-editor',
    templateUrl: './lettre-motivation-editor.component.html',
    styleUrls: ['./lettre-motivation-editor.component.css', './../../../../assets/css/administration/space.min.css'],
})
export class LettreMotivationEditorComponent implements OnInit {
    @ViewChild(QuillEditorComponent, { static: true }) editor!: QuillEditorComponent;

    @Input() lettreMotivation: any;
    @Output() lettreMotivationChange = new EventEmitter<string>();

    @Input() required = false;

    faInfoCircle = faInfoCircle;

    modules = {
        counter: { container: '#mat-quill-counter', unit: 'caractère' },
        mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['('],
            dataAttributes: ['id', 'value', 'template'],

            onSelect: (item: any, insertItem: (arg0: any) => void) => {
                const editor = this.editor.quillEditor;
                const selection = editor.getSelection(true);

                editor.insertText(selection.index, item.template, 'user');
                // @ts-ignore
                setTimeout(() => editor.setSelection(editor.getSelection().index + item.template.length, 0), 0);
            },
            source: (searchTerm: string, renderList: (arg0: { id: number; value: string }[], arg1: any) => void) => {
                const values = [
                    { id: 1, value: 'Civilité du recruteur', template: 'civilite_recruteur)' },
                    { id: 2, value: 'Nom du recruteur', template: 'nom_recruteur)' },
                    { id: 3, value: 'Prénom du recruteur', template: 'prenom_recruteur)' },
                    { id: 4, value: "Nom de l'organisme", template: 'nom_organisme)' },
                ];
                if (searchTerm.length === 0) {
                    renderList(values, searchTerm);
                } else {
                    const matches: { id: number; value: string }[] = [];

                    values.forEach((entry) => {
                        if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                            matches.push(entry);
                        }
                    });
                    renderList(matches, searchTerm);
                }
            },
        },
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ['clean'], // remove formatting button
        ],
    };
    constructor() {}
    ngOnInit(): void {}
    updateLm(event: any): void {
        this.lettreMotivationChange.emit(event);
    }
}
