import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCheckCircle, faTimes, faArrowLeft, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-informations-complementaires-candidats',
    templateUrl: './informations-complementaires.component.html',
    styleUrls: [
        './informations-complementaires.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class InformationsComplementairesComponent implements OnInit {
    faFilePdf = faFilePdf;
    arrowLeft = faArrowLeft;
    faError = faTimes;
    faOk = faCheckCircle;

    permis = {
        am: false,
        a1: false,
        c1: false,
        a2: false,
        a: false,
        d1: false,
        b1: false,
        b: false,
        be: false,
        c: false,
        ce: false,
        d1e: false,
        d: false,
        de: false,
        fluvial: false,
        c1e: false,
        cotier: false,
        hauturier: false,
        bia: false,
        bb: false,
        atpl: false,
        ppl: false,
        cpl: false,
        lapl: false,
        ulm_h: false,
        atpl_h: false,
        ppl_h: false,
        cpl_h: false,
        lapl_h: false,
        ulm: false,
    };

    candidat: any = {
        password: '',
    };
    errorPage = {
        status: false,
        message: '',
    };

    actuel = {
        metier: '',
        metierCache: '',
        secteur: '',
        secteurCache: '',
        langue: '',
        langueCache: '',
        secteurRechercher: '',
        secteurRechercherCache: '',
    };
    error = {
        lettreMotivation: false,
    };

    url = '';
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private userService: UserService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {
        this.candidat._id = localStorage.getItem('id');
        this.url = this.globalService.url;

        this.getAccount();
    }
    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.candidat = response.candidat;
                    for (const p of this.candidat.permis) {
                        if (p === 'AM') {
                            this.permis.am = true;
                        } else if (p === 'A1') {
                            this.permis.a1 = true;
                        } else if (p === 'C1') {
                            this.permis.c1 = true;
                        } else if (p === 'A2') {
                            this.permis.a2 = true;
                        } else if (p === 'A') {
                            this.permis.a = true;
                        } else if (p === 'D1') {
                            this.permis.d1 = true;
                        } else if (p === 'B1') {
                            this.permis.b1 = true;
                        } else if (p === 'B') {
                            this.permis.b = true;
                        } else if (p === 'BE') {
                            this.permis.be = true;
                        } else if (p === 'C') {
                            this.permis.c = true;
                        } else if (p === 'CE') {
                            this.permis.ce = true;
                        } else if (p === 'D1E') {
                            this.permis.d1e = true;
                        } else if (p === 'D') {
                            this.permis.d = true;
                        } else if (p === 'DE') {
                            this.permis.de = true;
                        } else if (p === 'FLUVIAL') {
                            this.permis.fluvial = true;
                        } else if (p === 'C1E') {
                            this.permis.c1e = true;
                        } else if (p === 'COTIER') {
                            this.permis.cotier = true;
                        } else if (p === 'HAUTURIER') {
                            this.permis.hauturier = true;
                        } else if (p === 'BIA') {
                            this.permis.bia = true;
                        } else if (p === 'BB') {
                            this.permis.bb = true;
                        } else if (p === 'ATPL') {
                            this.permis.atpl = true;
                        } else if (p === 'PPL') {
                            this.permis.ppl = true;
                        } else if (p === 'CPL') {
                            this.permis.cpl = true;
                        } else if (p === 'LAPL') {
                            this.permis.lapl = true;
                        } else if (p === 'ATPL_H') {
                            this.permis.atpl_h = true;
                        } else if (p === 'PPL_H') {
                            this.permis.ppl_h = true;
                        } else if (p === 'CPL_H') {
                            this.permis.cpl_h = true;
                        } else if (p === 'LAPL_H') {
                            this.permis.lapl_h = true;
                        } else if (p === 'ULM_H') {
                            this.permis.ulm_h = true;
                        } else if (p === 'ULM') {
                            this.permis.ulm = true;
                        }
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    renouvellerErreur(): void {
        this.errorPage.status = false;
        this.error = {
            lettreMotivation: false,
        };
    }
    sendInformationsComplementaire(): void {
        this.renouvellerErreur();
        let newPermis = [];
        if (this.permis.am) {
            newPermis.push('AM');
        }
        if (this.permis.a1) {
            newPermis.push('A1');
        }
        if (this.permis.c1) {
            newPermis.push('C1');
        }
        if (this.permis.a2) {
            newPermis.push('A2');
        }
        if (this.permis.a) {
            newPermis.push('A');
        }
        if (this.permis.d1) {
            newPermis.push('D1');
        }
        if (this.permis.b1) {
            newPermis.push('B1');
        }
        if (this.permis.b) {
            newPermis.push('B');
        }
        if (this.permis.be) {
            newPermis.push('BE');
        }
        if (this.permis.c) {
            newPermis.push('C');
        }
        if (this.permis.ce) {
            newPermis.push('CE');
        }
        if (this.permis.d1e) {
            newPermis.push('D1E');
        }
        if (this.permis.d) {
            newPermis.push('D');
        }
        if (this.permis.de) {
            newPermis.push('DE');
        }
        if (this.permis.fluvial) {
            newPermis.push('FLUVIAL');
        }
        if (this.permis.c1e) {
            newPermis.push('C1E');
        }
        if (this.permis.cotier) {
            newPermis.push('COTIER');
        }
        if (this.permis.hauturier) {
            newPermis.push('HAUTURIER');
        }
        if (this.permis.bia) {
            newPermis.push('BIA');
        }
        if (this.permis.bb) {
            newPermis.push('BB');
        }
        if (this.permis.atpl) {
            newPermis.push('ATPL');
        }
        if (this.permis.ppl) {
            newPermis.push('PPL');
        }
        if (this.permis.cpl) {
            newPermis.push('CPL');
        }
        if (this.permis.lapl) {
            newPermis.push('LAPL');
        }
        if (this.permis.atpl_h) {
            newPermis.push('ATPL_H');
        }
        if (this.permis.ppl_h) {
            newPermis.push('PPL_H');
        }
        if (this.permis.cpl_h) {
            newPermis.push('CPL_H');
        }
        if (this.permis.lapl_h) {
            newPermis.push('LAPL_H');
        }
        if (this.permis.ulm_h) {
            newPermis.push('ULM_H');
        }
        if (this.permis.ulm) {
            newPermis.push('ULM');
        }
        let ok = true;
        if (this.candidat.lettreMotivation !== '' && this.candidat.lettreMotivation.length <= 100) {
            this.error.lettreMotivation = true;
            this.errorPage.status = true;
            this.errorPage.message = 'Votre lettre de motivation doit contenir au moins 100 caractères';
            ok = false;
        }
        if (ok) {
            this.httpClient
                .patch(
                    this.globalService.url + '/candidat/other',
                    {
                        id: this.candidat._id,
                        permis: newPermis,
                        handicape: this.candidat.handicape,
                        cv: this.candidat.cv,
                        atoutConcurrenciel: this.candidat.atoutConcurrenciel,
                        lettreMotivation: this.candidat.lettreMotivation,
                    },
                    {}
                )
                .subscribe(
                    (response: any) => {
                        if (!response.status) {
                            this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                        } else {
                            this.toastrService.info('Informations complémentaires enregistrées', '', { opacity: 1 });
                            this.getAccount();
                        }
                    },
                    () => {}
                );
        }
    }
    sendCv(): void {
        this.renouvellerErreur();
        this.httpClient.patch(this.globalService.url + '/candidat/cv', { id: this.candidat._id, cv: this.candidat.cv }, {}).subscribe(
            (response: any) => {
                if (!response.status) {
                    alert('Erreur lors de la modification');
                } else {
                    this.candidat.cv = response.cv;
                    this.toastrService.info('Cv mis à jour', '', {
                        opacity: 1,
                    });
                }
            },
            () => {}
        );
    }
    cv(f: any): void {
        const file = f.target.files.item(0);
        const reader = new FileReader();
        if (file.type === 'application/pdf') {
            if (file.size <= 3000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.candidat.cv = reader.result;
                    this.sendCv();
                };
            } else {
                alert('Le document importé est supérieur à 3Mo. veuillez réduire sa taille');
            }
        } else {
            alert(
                "Le document que vous venez d'essayer d'importer n'est pas au format PDF. Vous pouvez uniquement importer un CV au format PDF."
            );
        }
    }
    handicap(val: boolean): void {
        this.candidat.handicape = val;
    }
}
