<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div *ngIf="commandes.length === 0">Aucune commande en attente</div>
        <div *ngIf="commandes.length > 0">
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">{{ commandes.length }} commande(s) en attente</h3>
                </div>
                <div id="wizard_container">
                    <div id="middle-wizard">
                        <div>
                            <div class="row">
                                <div class="col-md-6">
                                    <table class="display table dataTable">
                                        <tr>
                                            <td>Nombre de candidatures dans le panier</td>
                                            <td style="text-align: right">
                                                {{ commandes[numero].nombreCourriel }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Nombre de candidatures retirées</td>
                                            <td style="text-align: right">
                                                {{ retraitDesabo }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Nombre de candidatures à envoyer</td>
                                            <td style="text-align: right">
                                                {{ commandes[numero].nombreCourriel - commandes[numero].recruteursRetrait.length }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Prix unitaire TTC</td>
                                            <td style="text-align: right">
                                                {{ commandes[numero].prix.ttc }}
                                                €
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Sous-total TTC</td>
                                            <td style="text-align: right">{{ sousTotalTtc }} €</td>
                                        </tr>
                                        <tr>
                                            <td>Code réduction</td>
                                            <td style="text-align: right">
                                                <div *ngIf="commandes[numero].reduction.nomCode === ''">Aucun</div>
                                                <div *ngIf="commandes[numero].reduction.nomCode !== ''" style="position: relative">
                                                    <div style="margin-right: 15px">
                                                        {{ commandes[numero].reduction.nomCode }}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Montant de la réduction TTC</td>
                                            <td style="text-align: right">
                                                <div *ngIf="commandes[numero].reduction.nomCode === ''">0 €</div>
                                                <div *ngIf="commandes[numero].reduction.nomCode !== ''">
                                                    {{ commandes[numero].reduction.montantTtc.toFixed(2) }}
                                                    €
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Crédit TTC utilisé</td>
                                            <td style="text-align: right">{{ creditUtiliser }} €</td>
                                        </tr>
                                        <tr>
                                            <td>MONTANT &Agrave; PAYER</td>
                                            <td style="text-align: right">
                                                <div *ngIf="totalTtc <= 0">Gratuit</div>
                                                <div *ngIf="totalTtc > 0">{{ totalTtc }} €</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>(dont TVA 20%)</td>
                                            <td style="text-align: right">
                                                <div *ngIf="totalTtc <= 0">Pas de TVA</div>
                                                <div *ngIf="totalTtc > 0">{{ tva }} €</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Crédit TTC restant après commande</td>
                                            <td style="text-align: right">{{ creditRestant }} €</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <button
                                                    style="width: 100%"
                                                    (click)="accepter(commandes[numero]._id)"
                                                    class="btn btn-success"
                                                >
                                                    Accepter
                                                </button>
                                            </td>

                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td colspan="2">
                                                <input
                                                    type="text"
                                                    [(ngModel)]="motif"
                                                    class="form-control"
                                                    placeholder="Motif de refus..."
                                                />
                                            </td>

                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td colspan="2">
                                                <button
                                                    style="width: 100%"
                                                    (click)="refuserTemporairement(commandes[numero]._id)"
                                                    class="btn btn-danger"
                                                >
                                                    Refuser temporairement
                                                </button>
                                            </td>

                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td colspan="2">
                                                <button
                                                    style="width: 100%"
                                                    (click)="refuserDefinitivement(commandes[numero]._id)"
                                                    class="btn btn-danger"
                                                >
                                                    Refuser définitivement
                                                </button>
                                            </td>

                                            <td></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <table class="display table dataTable">
                                        <tr>
                                            <td>Sujet</td>
                                            <td>
                                                {{ commandes[numero].sujet }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Email expédition</td>
                                            <td>
                                                {{ commandes[numero].email }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Lettre de motivation</td>
                                            <td [innerHTML]='commandes[numero].lettreMotivation'>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CV</td>
                                            <td>
                                                <a target="_blank" href="{{ url }}/documents/cv/{{ commandes[numero].lienCv }}"
                                                    >Télécharger le CV</a
                                                >
                                            </td>
                                        </tr>
                                    </table>
                                    <table class="display table dataTable">
                                        <tr>
                                            <td style="width: 49%">
                                                <button class="form-control btn btn-success" (click)="precedent()">
                                                    Commande précédente
                                                </button>
                                            </td>
                                            <td style="width: 49%">
                                                <button class="form-control btn btn-success" (click)="suivant()">Commande suivante</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
