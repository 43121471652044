import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { faSearch, faShoppingBasket, faArrowLeft, faSort, faPlus, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { GlobalService } from '../../../services/global.service';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { ModalDirective } from '../../../components/mdb/free/modals';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MdbTablePaginationComponent, MdbTableDirective } from '../../../components/mdb/free/tables';
import { GlobalVariable } from '../../../global';
import { DeviceDetectorService } from 'ngx-device-detector';
import { animate, style, transition, trigger } from '@angular/animations';
import Utils from '../../../utils';
import { RecruiterFormComponent } from '../../../components/modals/recruiter-form/recruiter-form.component';
import { AuthService } from '../../../services/auth.service';
import { CitySelectComponent } from '../../../components/selects/city-select/city-select.component';
import { SecteurFilterComponent } from '../../../components/filters/secteur/secteur.component';

@Component({
    selector: 'app-panier',
    templateUrl: './panier.component.html',
    styleUrls: [
        './panier.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
    animations: [
        trigger('inOutAnimation', [transition(':enter', [style({ opacity: 0 }), animate('.25s ease-out', style({ opacity: 1 }))])]),
    ],
})
export class PanierComponent implements OnInit, AfterViewInit {
    @ViewChild('newCartModal', { static: true }) newCartModal!: ModalDirective;
    @ViewChild(MdbTablePaginationComponent, { static: true })
    mdbTablePagination!: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true })
    mdbTable!: MdbTableDirective;
    @ViewChild('recruteurModal', { static: true })
    recruteurModal!: RecruiterFormComponent;
    @ViewChild('searchCity') searchCityComponent!: CitySelectComponent;
    @ViewChild('searchSecteurs') searchSecteursComponent!: SecteurFilterComponent;

    faFileContract = faFileContract;
    faShoppingCart = faShoppingBasket;
    faSearch = faSearch;
    faArrowLeft = faArrowLeft;
    faSort = faSort;
    faPlus = faPlus;
    loadingMore = false;
    id: string | null = '';
    cartId: string | null = '';
    nom = '';
    paniers: any = [];
    recruteur: any = [];

    min = 5;
    max = 200;

    rayon = 5;

    searchPays = new Subject<string>();
    filteredOptions: Observable<any[]>;
    listePays = [
        {
            pays: '',
            code: '',
        },
    ];

    searchMetiers = new Subject<string>();
    resultsMetiers: Observable<any> | undefined;
    dataMetiers: any = [];

    showFiltersMobile = false;
    recruteurs: any = [];
    allRecruteurs: any = [];
    allRecruteursAddAll: any = [];
    previous: any = [];
    nombreTotal = 0;
    ids: any = [];
    nbTotal = 0;
    selection = 0;
    actuel = {
        paysCache: '',
        metierCache: '',
    };
    metiers = [
        {
            codeOgr: '',
            groupePrincipal: '',
            groupeSecondaire: '',
            nomMetier: '',
        },
    ];
    hideAlreadyContacted = false;
    recherche = {
        motCles: '',
        adresse: '',
        latitude: 0,
        longitude: 0,
        ville: '',
        pays: '',
        paysCode: '',
        codeNaf: '',
        fonction: '',
        typeEntreprise: [],
        nombreSalarie: [],
        activiteSpecifique: [],
        secteurs: [],
    };
    pays: any = [];
    paysVide = true;

    rechercheBase = true;

    addrs: any = [];
    ville = '';
    activities = GlobalVariable.ACTIVITES;
    displayByPages = GlobalVariable.PAGES;
    workersNumbers = GlobalVariable.WORKERS_NUMBERS;

    typesEntreprises = GlobalVariable.TYPES_ENTREPRISES;
    isMobile = false;

    headElements = [
        '-',
        'Référence du recruteur',
        'Localisation',
        "Type d'organisme",
        'Nombre de salariés',
        'Activité(s) spécifique(s)',
        'Secteur(s) recherché(s)',
        'Métier(s) recherché(s)',
    ];
    canLoadMore = true;
    loadNumber = 12;

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private toastrService: ToastService,
        private cdRef: ChangeDetectorRef,
        private authService: AuthService,
        private deviceService: DeviceDetectorService,
        private router: Router
    ) {
        this.filteredOptions = this.searchPays.pipe(
            startWith(''),
            map((value: string) => Utils.filter(value, this.listePays))
        );
        this.httpClient.get(this.globalService.url + '/metiers').subscribe((data: any) => {
            this.dataMetiers = data;
            this.resultsMetiers = this.searchMetiers.pipe(
                startWith(''),
                map((value: string) => Utils.filterMetier(value, this.dataMetiers))
            );
        });
    }

    ngOnInit(): void {
        this.id = this.authService.id;
        this.nettoyer();
        this.getPays();
        this.isMobile = this.deviceService.isMobile();

        this.getRecruteur();
        setInterval(() => {
            if (this.recherche.fonction.length > 3 && this.actuel.metierCache !== this.recherche.fonction) {
                this.changePosteRecherche();
            } else if (this.recherche.fonction.length === 0) {
            }
            if (this.recherche.pays === '' && !this.paysVide) {
                this.paysVide = true;
                this.getRecruteur();
            }
        }, 200);

        if (!this.isMobile) {
            setInterval(() => {
                this.updateFilterChecked();
            }, 1000);
        }

        if (
            this.route.snapshot.params.id !== undefined &&
            this.route.snapshot.params.id !== null &&
            this.route.snapshot.params.id !== 'new'
        ) {
            this.cartId = this.route.snapshot.params.id;
            this.getPanier();
        } else {
            this.countPaniers();
        }
    }

    ngAfterViewInit(): void {
        if (!this.isMobile) {
            this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

            this.mdbTablePagination.calculateFirstItemIndex();
            this.mdbTablePagination.calculateLastItemIndex();
            this.cdRef.detectChanges();
        }

        if (!this.cartId) {
            this.newCartModal.show();
        }
    }
    removeItems(arr: void[], item: number): void[] {
        for (let i = 0; i < item; i++) {
            arr.shift();
        }
        return arr;
    }
    getPanier(): void {
        this.ids = [];
        this.httpClient.get<any>(this.globalService.url + '/panier/' + this.cartId).subscribe(
            (response) => {
                if (response.status) {
                    this.nom = response.panier.nomPanier;
                    let ids: any[] = [];
                    for (let r of response.panier.recruteurSelectionner) {
                        ids.push(r.idRecruteur);
                    }
                    this.ids = ids;

                    const orderSelectionned = setInterval(() => {
                        if (this.recruteurs.length === 0) {
                            return;
                        }
                        this.recruteurs = this.recruteurs.map((recruteur: any) => {
                            recruteur.selectionned = this.ids.includes(recruteur._id);
                            return recruteur;
                        });
                        this.recruteurs.sort(
                            ({ selectionned: stateA = false }, { selectionned: stateB = false }) => Number(stateB) - Number(stateA)
                        );
                        clearInterval(orderSelectionned);
                    }, 200);
                }
            },
            () => {}
        );
    }
    loadMore(): void {
        if (this.canLoadMore) {
            this.loadingMore = true;
            setTimeout(() => {
                let concat = [];
                if (this.allRecruteurs.length >= this.loadNumber) {
                    concat = this.recruteurs.concat(this.allRecruteurs.slice(0, this.loadNumber));
                    this.allRecruteurs = this.removeItems(this.allRecruteurs, this.loadNumber);
                    if (this.allRecruteurs.length === 0) {
                        this.canLoadMore = false;
                    }
                } else {
                    concat = this.recruteurs.concat(this.allRecruteurs.slice(0, this.allRecruteurs.length));
                    this.allRecruteurs = this.removeItems(this.allRecruteurs, this.loadNumber);
                    this.canLoadMore = false;
                }
                this.recruteurs = concat;
                this.loadingMore = false;
            }, 1000);
        }
    }
    changePosteRecherche(): void {
        if (this.recherche.fonction !== '' && this.recherche.fonction !== this.actuel.metierCache) {
            this.actuel.metierCache = this.recherche.fonction;
            this.getPosteRecherche();
        }
    }
    clearMetier(): void {
        this.searchMetiers.next('');
        this.actuel.metierCache = '';
        this.recherche.fonction = '';
        this.getRecruteur();
    }
    clearCity(): void {
        this.recherche.ville = '';
        this.recherche.latitude = 0;
        this.recherche.longitude = 0;
        this.recherche.adresse = '';
        this.getRecruteur();
    }
    getPosteRecherche(): void {
        this.httpClient.post<any>(this.globalService.url + '/metiers', { text: this.recherche.fonction }, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.metiers = [];
                    for (const m of response.metiers) {
                        // @ts-ignore
                        // tslint:disable-next-line:prefer-const
                        let ns = {
                            groupePrincipal: m.groupePrincipal,
                            groupeSecondaire: m.groupeSecondaire,
                            codeOgr: m.codeOgr,
                            nomMetier: m.nomMetier,
                        };
                        this.metiers.push(ns);
                    }
                }
            },
            () => {}
        );
    }
    display(): string {
        if (this.rechercheBase || this.isMobile) {
            return 'none';
        }
        return 'table';
    }
    updateFilterChecked(): void {
        this.recruteurs = this.recruteurs.map((recruteur: any) => {
            recruteur.selectionned = this.ids.includes(recruteur._id);
            return recruteur;
        });
    }

    ajouter(): void {
        if (this.nom !== '') {
            this.httpClient
                .put<any>(this.globalService.url + '/panier', { id: this.id, nomPanier: this.nom, recruteurs: [] }, {})
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this.toastrService.info('Panier créé', '', {
                                opacity: 1,
                            });

                            this.newCartModal.hide();
                            this.httpClient.get<any>(this.globalService.url + '/panier/' + this.id + '/user').subscribe(
                                (response) => {
                                    if (response.status) {
                                        const paniers = response.paniers;
                                        this.cartId = paniers.find((p: { nomPanier: string }) => p.nomPanier === this.nom)._id;
                                    } else {
                                        alert('Erreur lors de la modification');
                                    }
                                },
                                () => {}
                            );
                        }
                    },
                    () => {
                        this.toastrService.error('Le nom du panier existe déjà', '', { opacity: 1 });
                    }
                );
        } else {
            this.toastrService.error('Veuillez saisir le nom du panier avant de le créer', '', { opacity: 1 });
        }
    }
    nettoyer(): void {
        this.httpClient.get<any>(this.globalService.url + '/panier/nettoyerPanier/' + this.id, {}).subscribe(
            (response) => {},
            () => {}
        );
    }
    getPays(): void {
        this.httpClient.get<any>(this.globalService.url + '/pays/getPays').subscribe(
            (response) => {
                if (response.status) {
                    this.listePays = response.pays;
                    this.searchPays.next('');
                }
            },
            () => {}
        );
    }

    // chooseMetiers(codeOgr: string, nomMetier: string): void {
    //     this.actuel.metierCache = nomMetier;
    //     this.recherche.fonction = nomMetier;
    //     this.getRecruteur();
    // }
    back(): void {
        this.router.navigate(['/espace-candidat/mes-selections']);
    }
    reinitiliaser(): void {
        this.searchMetiers.next('');
        this.rayon = 5;
        this.searchCityComponent.clearField();
        this.searchPays.next('');
        this.searchMetiers.next('');
        this.searchSecteursComponent.unselectAll();

        this.recherche = {
            motCles: '',
            adresse: '',
            latitude: 0,
            longitude: 0,
            ville: '',
            pays: '',
            paysCode: '',
            codeNaf: '',
            fonction: '',
            typeEntreprise: [],
            nombreSalarie: [],
            activiteSpecifique: [],
            secteurs: [],
        };
        this.getRecruteur();
    }
    getRecruteur(): void {
        this.rechercheBase = true;
        this.httpClient
            .post<any>(
                this.globalService.url + '/recruteur/recherche',
                {
                    page: 0,
                    nbParPage: 100000,
                    motCles: this.recherche.motCles,
                    latitude: this.recherche.latitude,
                    longitude: this.recherche.longitude,
                    pays: this.recherche.pays,
                    codeNaf: this.recherche.codeNaf,
                    hideAlreadyContacted: this.hideAlreadyContacted,
                    fonction: this.recherche.fonction,
                    typeEntreprise: this.recherche.typeEntreprise,
                    nombreSalarie: this.recherche.nombreSalarie,
                    activiteSpecifique: this.recherche.activiteSpecifique,
                    rayon: this.rayon,
                    secteurs: this.recherche.secteurs,
                    candidatId: this.id,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.allRecruteursAddAll = JSON.parse(JSON.stringify(response.recruteurs));
                        if (response.recruteurs.length >= this.loadNumber && this.isMobile) {
                            this.allRecruteurs = response.recruteurs;
                            this.recruteurs = this.allRecruteurs.slice(0, this.loadNumber);
                            this.allRecruteurs = this.removeItems(this.allRecruteurs, this.loadNumber);
                            if (this.allRecruteurs.length === 0) {
                                this.canLoadMore = false;
                            }
                        } else {
                            this.recruteurs = response.recruteurs;
                            this.canLoadMore = false;
                        }
                        this.mdbTable.setDataSource(this.recruteurs);
                        this.recruteurs = this.mdbTable.getDataSource();
                        this.previous = this.mdbTable.getDataSource();
                        this.nbTotal = response.nombre;
                        this.nombreTotal = response.nb;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                    this.rechercheBase = false;
                },
                () => {
                    this.rechercheBase = false;
                }
            );
    }
    selectAll(): void {
        this.ids = [];
        for (const r of this.allRecruteursAddAll) {
            this.ids.push(r._id);
        }

        this.ajouterLot(this.ids);
    }
    choosePays(pays: string, code: string): void {
        this.paysVide = false;
        this.actuel.paysCache = '';
        this.recherche.adresse = '';
        this.recherche.latitude = 0;
        this.recherche.longitude = 0;
        this.recherche.ville = '';
        this.recherche.pays = pays;
        this.recherche.paysCode = code;
        this.getRecruteur();
    }
    changeItemsByPage(event: any): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(event);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
    }
    supprimer(id: string): void {
        let ids = [];
        ids.push(id);
        this.httpClient.patch<any>(this.globalService.url + '/panier/' + this.cartId + '/recruteurs', { recruteurs: ids }, {}).subscribe(
            (response) => {
                let newIds = [];
                for (let i of this.ids) {
                    if (i !== id) {
                        newIds.push(i);
                    }
                }
                this.ids = newIds;
                if (this.ids.length <= 12) {
                    this.loadNumber = 12;
                } else {
                    this.loadNumber = this.ids.length;
                }
            },
            () => {}
        );
    }
    ajouterSupprimer(id: string): void {
        if (this.ids.includes(id)) {
            this.supprimer(id);
        } else {
            this.ajouterUnite(id);
        }
    }
    countPaniers(): void {
        this.httpClient.get<any>(this.globalService.url + '/panier/' + this.id + '/user').subscribe(
            (response) => {
                if (response.status) {
                    this.nom = 'Panier ' + (response.paniers.length + 1);
                } else {
                }
            },
            () => {}
        );
    }
    loadRecruteurForm(id: string): void {
        this.httpClient.get<any>(this.globalService.url + '/recruteur/' + id).subscribe(
            (response) => {
                if (response.status) {
                    this.recruteur = response.recruteur;
                } else {
                    alert('Erreur');
                }
            },
            () => {}
        );
        this.recruteurModal.openForm();
    }
    ajouterUnite(i: string): void {
        let ii = [];
        ii.push(i);
        this.httpClient.put<any>(this.globalService.url + '/panier/' + this.cartId, { recruteurs: ii }, {}).subscribe(
            (response) => {
                this.ids.push(i);
            },
            () => {}
        );
    }
    ajouterLot(recruteurs: []): void {
        this.httpClient.put<any>(this.globalService.url + '/panier/' + this.cartId, { recruteurs }, {}).subscribe(
            (response) => {},
            () => {}
        );
    }
}
