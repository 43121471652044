import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCheckCircle, faTimes, faFilePdf, faRedo, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalVariable } from '../../../global';
import Utils from '../../../utils';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { ModalDirective } from '../../../components/mdb/free/modals';
import { ValidateService } from '../../../services/validate.service';
import * as dayjs from 'dayjs';
import { UserService } from '../../../services/user.service';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
@Component({
    selector: 'app-inscription-candidat',
    templateUrl: './inscription-candidat.component.html',
    styleUrls: ['./inscription-candidat.component.css', './../../../../assets/css/inscription/style.css'],
})
export class InscriptionCandidatComponent implements OnInit {
    @ViewChild('verificationCode', { static: true })
    verificationCode!: ModalDirective;
    dob = '';
    offerTypes = GlobalVariable.OFFER_TYPES;

    photoUpload = '1';
    searchPays = new Subject<string>();
    filteredOptions: Observable<any[]>;
    eye1 = faEye;
    faArrowCircleRight = faRedo;
    isMobile = false;
    eye2 = faEye;
    type1 = 'password';
    type2 = 'password';

    typesExperiencesSecteurs = GlobalVariable.EXPERIENCES_SECTEURS;
    typesDispos = GlobalVariable.DISPOS;
    typesSituations = GlobalVariable.SITUATIONS;
    typesTeletravail = GlobalVariable.TELETRAVAIL;
    typesExperiences = GlobalVariable.EXPERIENCES;

    code = '';

    loading = false;
    majeur = false;

    typesEtudes = GlobalVariable.ETUDES;

    searchNatio = new Subject<string>();
    resultsNatio: Observable<any> | undefined;
    dataNatio: any = [];

    searchLangues = new Subject<string>();
    resultsLangues: Observable<any> | undefined;
    dataLangues: any = [];

    searchCity = new Subject<string>();
    resultsCity: Observable<any> | undefined;

    chargement = 15;
    url = '';
    photoCandidat: string | ArrayBuffer | null = '';
    fin = false;
    acceptation = false;
    acceptation2 = false;
    dateDisponibiliteHistorique = '';
    candidat: any = {
        _id: '',
        fonctionLibre: '',
        estFrancais: true,
        password: '',
        nationalites: [],
        remunerationSouhaiter: [0, 0, 0, 0],
        adresse: '',
        complementAdresse: '',
        codePostal: '',
        ville: '',
        pays: '',
        latitude: '',
        longitude: '',
        paysCode: '',
        mobilite_locale: [
            {
                latitude: '',
                longitude: '',
                ville: '',
                pays: '',
                rayon: -1,
            },
        ],
        mobilite_nationale: [],
        mobilite_internationale: false,
        secteurActivite: [],
        langues: [],
        posteRechercher: [],
        secteurRechercher: [],
        typePosteVoulu: '',
        emailVerifier: false,
        etape: 0,
    };
    auth = {
        cvTechRecruteur: true,
        cvTech: false,
        accueil: false,
        photoProfil: false,
        sendOffer: false,
        offerType: false,
    };
    mobiliteRayon = {
        ville: '',
        villeSave: '',
        pays: '',
        latitude: '',
        longitude: '',
        rayon: 5,
    };
    listePays: any = [];
    passwordDeux = '';
    faError = faTimes;
    faOk = faCheckCircle;
    faFilePdf = faFilePdf;
    addrs: any = [];
    nationalites: any = [];
    pays: any = [];
    metiers = [
        {
            codeOgr: '',
            groupePrincipal: '',
            groupeSecondaire: '',
            nomMetier: '',
        },
    ];
    addr = {
        adresse: '',
        postcode: '',
        city: '',
    };
    errorPage = {
        status: false,
        message: '',
    };
    errorMessage = {
        metier: 'Obligatoire',
        secteur: 'Obligatoire',
    };
    error = {
        emailInscription: false,
        email: false,
        majeur: false,
        mobilite: false,
        nationalites: false,
        sendOffer: false,
        acceptation: false,
        acceptation2: false,
        password: false,
        situation: false,
        secteurRechercher: false,
        passwordDeux: false,
        civilite: false,
        nom: false,
        dateNaissance: false,
        typePosteVoulu: false,
        prenom: false,
        adresse: false,
        codePostal: false,
        ville: false,
        pays: false,
        telephonePortable: false,
        telephoneFixe: false,
        photo: false,
        fonction: false,
        fonctionLibre: false,
        secteurActivite: false,
        experienceSecteur: false,
        cv: false,
        paysCode: false,
        posteRechercher: false,
        experience: false,
        teleTravail: false,
        niveauEtude: false,
        langues: false,
        disponibilite: false,
        disponibiliteDate: false,
        atoutConcurrenciel: false,
        lettreMotivation: false,
        remunerationSouhaiter: false,
        etreDansCVTech: false,
        etreRecruteur: false,
        autorisationAccueil: false,
        photoProfil: false,
    };
    errorPassword = {
        status: false,
        message: '',
    };
    errorRemuneration = {
        status: false,
        message: '',
    };
    analyse = {
        longueur: false,
        majuscule: false,
        miniscule: false,
        chiffre: false,
        caractereSpeciale: false,
        identique: false,
    };
    actuel = {
        metier: '',
        metierCache: '',
        nationalite: '',
        nationaliteCache: '',
        secteur: '',
        secteurCache: '',
        langue: '',
        langueCache: '',
        secteurRechercher: '',
        secteurRechercherCache: '',
        pays: '',
        paysCache: '',
    };
    languesCheck = false;

    secteurs: any = [];
    langues = [];
    mobilite = {
        regional: false,
        national: false,
        international: false,
    };
    permis = {
        am: false,
        a1: false,
        c1: false,
        a2: false,
        a: false,
        d1: false,
        b1: false,
        b: false,
        be: false,
        c: false,
        ce: false,
        d1e: false,
        d: false,
        de: false,
        fluvial: false,
        c1e: false,
        cotier: false,
        hauturier: false,
        bia: false,
        bb: false,
        atpl: false,
        ppl: false,
        cpl: false,
        lapl: false,
        ulm_h: false,
        atpl_h: false,
        ppl_h: false,
        cpl_h: false,
        lapl_h: false,
        ulm: false,
    };
    poste = {
        salarie: false,
        independant: false,
        stagiaire: false,
        alternant: false,
        benevole: false,
        autre: false,
    };
    posteVoulu = {
        cdd: false,
        cdi: false,
    };
    temps = {
        plein: false,
        partiel: false,
    };
    dernierPays = '';
    dernierIndicatif = '';
    dispo = {
        travail: '2',
        preavis: '1',
    };
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private validateService: ValidateService,
        private userService: UserService,
        private router: Router,
        private toastrService: ToastService,
        private deviceService: DeviceDetectorService
    ) {
        this.filteredOptions = this.searchPays.pipe(
            startWith(''),
            map((value: string) => Utils.filter(value, this.listePays))
        );
        this.httpClient
            .post<any>(this.globalService.url + '/nationalites', {
                text: this.actuel.nationalite,
            })
            .subscribe((data: any) => {
                this.dataNatio = data;
                this.resultsNatio = this.searchNatio.pipe(
                    startWith(''),
                    map((value: string) => Utils.filterNatio(value, this.dataNatio))
                );
            });

        this.httpClient.get<any>(this.globalService.url + '/langues/' + this.actuel.langue).subscribe((data: any) => {
            this.dataLangues = data;
            this.resultsLangues = this.searchLangues.pipe(
                startWith(''),
                map((value: string) => Utils.filterLangue(value, this.dataLangues))
            );
        });

        this.resultsCity = this.searchCity.pipe(
            startWith(''),
            map((value: string) => Utils.filterCity(value, this.addrs))
        );
    }
    ngOnInit(): void {
        this.url = this.globalService.url;
        this.isMobile = this.deviceService.isMobile();
        this.getPays();
        if (this.route.snapshot.params.email !== undefined && this.route.snapshot.params.email !== null) {
            this.candidat.email = this.route.snapshot.params.email;
            if (localStorage.hasOwnProperty('social_picture') && localStorage.getItem('social_picture') !== null) {
                const pictureUrl = localStorage.getItem('social_picture');
                const xhr = new XMLHttpRequest();
                // @ts-ignore
                xhr.open('GET', pictureUrl);
                xhr.responseType = 'blob';
                xhr.send();
                xhr.addEventListener('load', () => {
                    const reader = new FileReader();
                    reader.readAsDataURL(xhr.response);
                    reader.addEventListener('loadend', () => {
                        this.candidat.photo = reader.result;
                        this.sendPhoto();
                        localStorage.removeItem('social_picture');
                    });
                });
            }
        }

        if (this.route.snapshot.params.id !== undefined && this.route.snapshot.params.id !== null) {
            this.candidat._id = this.route.snapshot.params.id;
            this.candidat.referenceCandidat = this.route.snapshot.params.ref;
            this.pair_exist();
        } else {
            const candidatId = localStorage.getItem('id') !== undefined && localStorage.getItem('id') !== null;
            if (candidatId) {
                this.candidat._id = localStorage.getItem('id');
                this.getAccount();
            } else {
                this.router.navigate(['/candidat']);
            }
        }

        setInterval(() => {
            if (this.candidat.etape === 0) {
                this.analyserPassword();
            }

            if (this.candidat.paysCode === 'FR' || this.candidat.paysCode === '') {
                this.candidat.estFrancais = true;
                this.candidat.paysCode = 'FR';
            } else {
                this.candidat.estFrancais = false;
            }
            if ((this.dernierPays === '' && this.candidat.paysCode !== '') || this.candidat.paysCode !== this.dernierPays) {
                this.dernierPays = this.candidat.paysCode;
                for (const pays of this.listePays) {
                    if (pays.code === this.dernierPays) {
                        this.candidat.telephonePortable = pays.indicatif;
                        this.candidat.telephoneFixe = pays.indicatif;
                        this.dernierIndicatif = pays.indicatif;
                    }
                }
            }
        }, 200);
    }
    async pair_exist(): Promise<any> {
        await this.httpClient
            .get<any>(this.globalService.url + '/candidat/pair_exist/' + this.candidat._id + '/' + this.candidat.referenceCandidat)
            .subscribe(
                (response) => {
                    if (response.status) {
                        localStorage.setItem('id', this.candidat._id);
                        localStorage.setItem('token', response.token);
                        localStorage.setItem('type', 'Candidat');
                        this.getAccount();
                    }
                },
                () => {}
            );
    }
    dispoTravail(event: any): void {
        const travail = event.currentTarget.checked;
        if (travail) {
            this.dispo.travail = '2';
        } else {
            this.dispo.travail = '1';
        }
    }
    dispoPreavis(event: any): void {
        const travail = event.currentTarget.checked;
        if (travail) {
            this.dispo.preavis = '2';
        } else {
            this.dispo.preavis = '1';
        }
    }
    clicAutorisation(num: number, bool: boolean): void {
        if (num === 1) {
            this.auth.cvTechRecruteur = true;
            this.candidat.etreRecruteur = bool;
        } else if (num === 2) {
            this.auth.cvTech = true;
            this.candidat.etreDansCVTech = bool;
        } else if (num === 3) {
            this.auth.accueil = true;
            this.candidat.autorisationAccueil = bool;
        } else if (num === 4) {
            this.auth.photoProfil = true;
            this.candidat.photoProfil = bool;
        } else if (num === 5) {
            this.auth.sendOffer = true;
            this.candidat.sendOffer = bool;
        }
    }
    eye(num: number): void {
        if (num === 1) {
            if (this.eye1 === faEye) {
                this.eye1 = faEyeSlash;
                this.type1 = 'text';
            } else {
                this.eye1 = faEye;
                this.type1 = 'password';
            }
        } else {
            if (this.eye2 === faEye) {
                this.eye2 = faEyeSlash;
                this.type2 = 'text';
            } else {
                this.eye2 = faEye;
                this.type2 = 'password';
            }
        }
    }
    setPays(event: any) {
        for (const p of this.listePays) {
            if (p.code === event) {
                this.candidat.pays = p.pays;
                break;
            }
        }
    }
    // this called every time when user changed the code
    onCodeChanged(code: any) {}

    // this called only if user entered full code
    onCodeCompleted(code: any) {
        this.code = code;
        this.verifierCode();
    }
    francais(): void {
        this.candidat.estFrancais = true;
        this.candidat.etape = 0;
    }
    autre_pays(): void {
        this.candidat.estFrancais = false;
        this.candidat.etape = 0;
    }
    getPays(): void {
        this.httpClient.get<any>(this.globalService.url + '/pays/getPays').subscribe(
            (response) => {
                if (response.status) {
                    this.listePays = response.pays;
                    this.searchPays.next('');
                }
            },
            () => {}
        );
    }
    choosePays(pays: any): void {
        this.actuel.pays = '';
        this.actuel.paysCache = '';
        let ajouter = true;
        for (const p of this.candidat.mobilite_nationale) {
            if (p == pays) {
                ajouter = false;
                break;
            }
        }
        if (ajouter) {
            this.candidat.mobilite_nationale.push(pays);
        }
        this.searchPays.next('');
    }
    removePays(p: string): void {
        const newPays = [];
        for (const pays of this.candidat.mobilite_nationale) {
            if (p !== pays) {
                newPays.push(pays);
            }
        }
        this.candidat.mobilite_nationale = newPays;
    }
    checkMailVerification(): void {
        // if (this.candidat.idGmail === '' && this.candidat.idFacebook === '' && this.candidat.idLinkedin === '' && !this.candidat.emailVerifier && this.globalService.envType !== 'local') {
        if (
            this.candidat.idGmail === '' &&
            this.candidat.idFacebook === '' &&
            this.candidat.idLinkedin === '' &&
            !this.candidat.emailVerifier
        ) {
            this.verificationCode.show();
            this.sendMailVerificationEmail();
        }
    }
    chooseNationalite(nationalite: string): void {
        this.searchNatio.next('');
        this.actuel.nationalite = '';
        this.actuel.nationaliteCache = '';
        let ajouter = true;
        for (const n of this.candidat.nationalites) {
            if (n == nationalite) {
                ajouter = false;
                break;
            }
        }
        if (ajouter) {
            this.candidat.nationalites.push(nationalite);
        }
        this.searchNatio.next('');
    }
    removeNationalite(n: string): void {
        const nationalites = [];
        for (const nationalite of this.candidat.nationalites) {
            if (n !== nationalite) {
                nationalites.push(nationalite);
            }
        }
        this.candidat.nationalites = nationalites;
    }
    analyserPassword(): void {
        if (this.candidat.password.length >= 10) {
            this.analyse.longueur = true;
        } else {
            this.analyse.longueur = false;
        }
        if (this.candidat.password.match(/[a-z]/g)) {
            this.analyse.miniscule = true;
        } else {
            this.analyse.miniscule = false;
        }
        if (this.candidat.password.match(/[A-Z]/g)) {
            this.analyse.majuscule = true;
        } else {
            this.analyse.majuscule = false;
        }
        if (this.candidat.password.match(/[0-9]/g)) {
            this.analyse.chiffre = true;
        } else {
            this.analyse.chiffre = false;
        }
        if (this.candidat.password.match(/[@#&"';.,?:\/\\!]/g)) {
            this.analyse.caractereSpeciale = true;
        } else {
            this.analyse.caractereSpeciale = false;
        }
        if (this.candidat.password === this.passwordDeux) {
            this.analyse.identique = true;
        } else {
            this.analyse.identique = false;
        }
    }
    getChargement(): number {
        return this.chargement;
    }
    monsieur(): void {
        this.candidat.civilite = 'Monsieur';
    }
    madame(): void {
        this.candidat.civilite = 'Madame';
    }
    autre(): void {
        this.candidat.civilite = 'Autre';
    }
    handicap(val: boolean): void {
        this.candidat.handicape = val;
    }
    photo(f: any): void {
        this.photoUpload = '2';
        const file = f.target.files.item(0);
        const reader = new FileReader();
        const extensions = ['image/jpg', 'image/jpeg', 'image/png'];

        if (extensions.indexOf(file.type) > -1) {
            if (file.size <= 5000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.candidat.photo = reader.result;
                    this.photoCandidat = reader.result;
                    this.sendPhoto();
                };
            } else {
                this.photoUpload = '';
                alert("L'image importé est supérieur à 5Mo. veuillez réduire sa taille");
            }
        } else {
            this.photoUpload = '';
            alert("Le document que vous venez d'importer n'est pas dans un format valide");
        }
    }
    suivant(): void {
        this.renouvellerErreur();
        if (this.candidat._id === '') {
            if (this.candidat.email === '' || !this.validateService.validateEmail(this.candidat.email)) {
                this.error.emailInscription = true;
            } else {
            }
        } else if (this.candidat.etape === 0) {
            if (
                this.analyse.longueur &&
                this.analyse.miniscule &&
                this.analyse.majuscule &&
                this.analyse.chiffre &&
                this.analyse.caractereSpeciale &&
                this.analyse.identique
            ) {
                this.sendPassword();
                this.candidat.etape = 1;
                this.chargement = (this.candidat.etape / 6) * 100;
            } else if (this.candidat.password === '') {
                this.error.password = true;
            } else if (this.passwordDeux === '') {
                this.error.passwordDeux = true;
            } else {
                this.errorPage.status = true;
                this.errorPage.message = "Votre mot de passe n'est pas assez fort";
                if (this.candidat.password !== this.passwordDeux) {
                    this.errorPage.message = 'Les deux mot de passe ne sont pas identique';
                }
            }
        } else if (this.candidat.etape === 1) {
            let ok = true;
            if (this.candidat.civilite === '') {
                this.error.civilite = true;
                ok = false;
            }
            if (this.candidat.nom === '') {
                this.error.nom = true;
                ok = false;
            }

            if (this.candidat.nationalites.length == 0) {
                this.error.nationalites = true;
                ok = false;
            }
            if (this.candidat.paysCode === '') {
                this.error.paysCode = true;
                ok = false;
            }
            if (this.candidat.prenom === '') {
                this.error.prenom = true;
                ok = false;
            }
            if (this.candidat.adresse === '') {
                this.error.adresse = true;
                ok = false;
            }

            if (this.candidat.codePostal === '') {
                this.error.codePostal = true;
                ok = false;
            }
            if (this.candidat.ville === '') {
                this.error.ville = true;
                ok = false;
            }
            if (this.candidat.pays === '') {
                this.error.pays = true;
                ok = false;
            }

            // if (this.candidat.dateNaissance === '') {
            //     this.error.dateNaissance = true;
            //     ok = false;
            // }
            // if (this.candidat.photo === '') {
            //     this.error.photo = true;
            //     ok = false;
            // }
            // const age = dayjs().diff(dayjs(this.candidat.dateNaissance, 'DD-MM-YYYY'), 'years');
            //
            // if (age < 18) {
            //     ok = false;
            //     this.errorPage.status = true;
            //     this.errorPage.message = 'Vous ne pouvez pas vous inscrire sur notre site si vous avez moins de 18 ans';
            // }
            // if (age >= 100) {
            //     ok = false;
            //     this.errorPage.status = true;
            //     this.errorPage.message = 'La date de naissance est erronée';
            // }
            if (this.candidat.telephonePortable === '' && this.candidat.telephoneFixe === '') {
                this.error.telephonePortable = true;
                this.errorPage.status = true;
                this.errorPage.message = "Certains champs obligatoires n'ont pas été remplis";
                ok = false;
            }

            // @ts-ignore
            document.getElementById('telephonePortable').focus();
            // @ts-ignore
            document.getElementById('telephoneFixe').focus();
            // @ts-ignore
            const errorPortable = document.getElementById('telephonePortable').style.borderColor === 'red';
            // @ts-ignore
            const errorFixe = document.getElementById('telephoneFixe').style.borderColor === 'red';
            if (errorPortable) {
                this.errorPage.status = true;
                this.errorPage.message = "Le téléphone portable n'est pas au bon format";
                ok = false;
            }
            if (this.candidat.telephonePortable === this.dernierIndicatif && this.candidat.telephoneFixe === this.dernierIndicatif) {
                this.error.telephonePortable = true;
                this.errorPage.status = true;
                this.errorPage.message = "Certains champs obligatoires n'ont pas été remplis";
                ok = false;
            }
            if (errorFixe && this.candidat.telephoneFixe !== '' && this.candidat.telephoneFixe !== this.dernierIndicatif) {
                this.errorPage.status = true;
                this.errorPage.message = "Le téléphone fixe n'est pas au bon format";
                ok = false;
            }

            if (ok) {
                this.sendActuel();
                this.candidat.etape = 2;
                this.chargement = (this.candidat.etape / 6) * 100;
                // @ts-ignore
                window.scroll(0, 0);
            } else {
                console.log(this.error);
            }
        } else if (this.candidat.etape === 2) {
            this.renouvellerErreur();
            let ok = true;
            if (this.candidat.fonction === '') {
                this.error.fonction = true;
                ok = false;
            }
            if (this.actuel.metier) {
                this.errorMessage.metier = 'Erreur de saisie, veuillez consulter la liste des fonctions possibles.';
                this.error.fonction = true;
                ok = false;
            }
            if (this.candidat.fonctionLibre === '') {
                this.error.fonctionLibre = true;
                ok = false;
            }
            if (this.candidat.secteurActivite.length == 0) {
                this.errorMessage.secteur = 'Obligatoire';
                this.error.secteurActivite = true;
                ok = false;
            }
            if (this.actuel.secteur) {
                this.errorMessage.secteur = "Erreur de saisie, veuillez consulter la liste des secteurs d'activités possibles.";
                this.error.secteurActivite = true;
                ok = false;
            }
            if (this.candidat.experienceSecteur === '') {
                this.error.experienceSecteur = true;
                ok = false;
            }
            if (this.candidat.experience === '') {
                this.error.experience = true;
                ok = false;
            }
            if (this.candidat.niveauEtude === '') {
                this.error.niveauEtude = true;
                ok = false;
            }
            if (this.candidat.langues.length == 0) {
                this.error.langues = true;
                ok = false;
            }
            if (ok) {
                this.sendSituation();
                this.candidat.etape = 3;
                // @ts-ignore
                window.scroll(0, 0);
            }
        } else if (this.candidat.etape === 3) {
            this.sendInformationsComplementaires();
            this.candidat.etape = 4;
            // @ts-ignore
            window.scroll(0, 0);
        } else if (this.candidat.etape === 4) {
            let ok = true;
            if (this.candidat.situation === '') {
                this.error.situation = true;
                ok = false;
            }
            if (this.candidat.teleTravail === '') {
                this.error.teleTravail = true;
                ok = false;
            }
            if (this.candidat.disponibilite === '' && this.candidat.dateDisponibilite === '') {
                this.error.disponibilite = true;
                ok = false;
            }
            if (this.candidat.posteRechercher.length === 0) {
                this.error.posteRechercher = true;
                ok = false;
            }
            if (this.dispo.travail === '1' && this.dispo.preavis === '2' && this.candidat.disponibilite === '') {
                this.error.disponibilite = true;
                ok = false;
            } else if (this.dispo.travail === '2' || this.dispo.preavis === '1') {
                if (this.dispo.travail === '2') {
                    this.dispo.preavis = '1';
                }
                if (this.candidat.dateDisponibilite !== '') {
                    const isAfter = dayjs(this.candidat.dateDisponibilite, 'DD-MM-YYYY').isAfter(dayjs().subtract(1, 'day'));
                    if (!isAfter) {
                        this.error.disponibiliteDate = true;
                        ok = false;
                    }
                } else {
                    this.error.disponibiliteDate = true;
                    ok = false;
                }
                if (!this.mobilite.regional && !this.mobilite.national && !this.mobilite.international) {
                    this.error.mobilite = true;
                    ok = false;
                }
            }
            if (ok) {
                this.sendRecherche();
                this.candidat.etape = 5;
                this.sendComplementSituation();
                // @ts-ignore
                window.scroll(0, 0);
            }
        } else if (this.candidat.etape === 5) {
            let ok = true;
            if (!this.acceptation) {
                this.error.acceptation = true;
                ok = false;
            }
            if (!this.acceptation2) {
                this.error.acceptation2 = true;
                ok = false;
            }
            if (!this.majeur) {
                this.error.majeur = true;
                ok = false;
            }
            if (!this.auth.cvTechRecruteur || !this.auth.cvTech || !this.auth.accueil || !this.auth.photoProfil || !this.auth.sendOffer) {
                this.error.acceptation2 = true;
                ok = false;
            }
            if (ok) {
                this.sendAutorisation();
                this.fin = true;

                this.toastrService.info('Inscription terminée !', '', {
                    opacity: 1,
                });
                setTimeout(() => {
                    window.location.href = 'espace-candidat/dashboard';
                }, 1500);
            } else {
                if (!this.auth.cvTechRecruteur) {
                    this.error.etreRecruteur = true;
                }
                if (!this.auth.cvTech) {
                    this.error.etreDansCVTech = true;
                }
                if (!this.auth.accueil) {
                    this.error.autorisationAccueil = true;
                }
                if (!this.auth.photoProfil) {
                    this.error.photoProfil = true;
                }
                if (!this.auth.sendOffer) {
                    this.error.sendOffer = true;
                }
            }
        }
    }
    accepter(num: number): void {
        if (num === 1) {
            this.acceptation = !this.acceptation;
        } else if (num === 2) {
            this.acceptation2 = !this.acceptation2;
        } else {
            this.majeur = !this.majeur;
        }
    }
    precedent(): void {
        this.candidat.etape = this.candidat.etape - 1;
        // @ts-ignore
        window.scroll(0, 0);
    }
    renouvellerErreur(): void {
        this.errorPage.status = false;
        this.errorPassword.status = false;
        this.errorRemuneration.status = false;
        this.error = {
            disponibiliteDate: false,
            emailInscription: false,
            sendOffer: false,
            nationalites: false,
            teleTravail: false,
            mobilite: false,
            email: false,
            situation: false,
            paysCode: false,
            secteurRechercher: false,
            acceptation: false,
            acceptation2: false,
            password: false,
            passwordDeux: false,
            civilite: false,
            nom: false,
            dateNaissance: false,
            posteRechercher: false,
            typePosteVoulu: false,
            prenom: false,
            adresse: false,
            codePostal: false,
            ville: false,
            pays: false,
            telephonePortable: false,
            telephoneFixe: false,
            photo: false,
            fonction: false,
            fonctionLibre: false,
            majeur: false,
            secteurActivite: false,
            experienceSecteur: false,
            cv: false,
            experience: false,
            disponibilite: false,
            niveauEtude: false,
            langues: false,
            atoutConcurrenciel: false,
            lettreMotivation: false,
            remunerationSouhaiter: false,
            etreDansCVTech: false,
            etreRecruteur: false,
            autorisationAccueil: false,
            photoProfil: false,
        };
    }
    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.candidat = response.candidat;
                    if (this.candidat.ficheComplete) {
                        this.router.navigate(['/espace-candidat/dashboard']);
                    }
                    if (localStorage.hasOwnProperty('social_first_name')) {
                        this.candidat.prenom = localStorage.getItem('social_first_name');
                    }
                    if (localStorage.hasOwnProperty('social_last_name')) {
                        this.candidat.nom = localStorage.getItem('social_last_name');
                    }
                    this.checkMailVerification();
                    if (this.candidat.dispo !== undefined && this.candidat.dispo !== null) {
                        this.dispo.travail = this.candidat.dispo.travail;
                        this.dispo.preavis = this.candidat.dispo.preavis;
                    }
                    if (this.candidat.rayon === -1) {
                        this.candidat.rayon = 0;
                    }
                    if (this.candidat.rayon < -1) {
                        this.candidat.rayon = 0;
                    }
                    if (this.candidat.etape === 7) {
                        this.fin = true;
                    }
                    if (this.candidat.paysCode === '' && this.candidat.paysCode === undefined && this.candidat.paysCode === null) {
                        this.candidat.paysCode = 'FR';
                        this.candidat.pays = 'France';
                    } else if (this.candidat.paysCode === 'FR') {
                        this.candidat.paysCode = 'FR';
                        this.candidat.pays = 'France';
                    } else {
                        for (const p of this.pays) {
                            if (p.code === this.candidat.paysCode) {
                                this.candidat.pays = p.pays;
                            }
                        }
                    }
                    if (this.candidat.sendOfferType === '' && this.candidat.sendOffer) {
                        this.candidat.sendOfferType = 'mail';
                    }
                    this.addr.adresse = this.candidat.adresse;
                    this.addr.postcode = this.candidat.codePostal;
                    this.addr.city = this.candidat.ville;
                    this.chargement = (this.candidat.etape / 7) * 100;
                    this.dernierPays = this.candidat.paysCode;
                    if (this.candidat.dateNaissance) {
                        this.dob = this.candidat.dateNaissance;

                        // this.candidat.dateNaissance = dayjs(this.candidat.dateNaissance, 'DD-MM-YYYY').toDate();
                    }
                    this.candidat.password = '';
                    if (this.candidat.remunerationSouhaiter === []) {
                        this.candidat.remunerationSouhaiter = [0, 0, 0, 0];
                    }
                    if (this.candidat.photo !== '') {
                        this.candidat.photo = this.globalService.url + '/images/candidat/' + this.candidat.photo;
                    }
                    if (this.candidat.mobilite_locale.length > 0) {
                        this.mobilite.regional = true;
                    }
                    if (this.candidat.mobilite_nationale.length > 0) {
                        this.mobilite.national = true;
                    }
                    if (this.candidat.mobilite_internationale) {
                        this.mobilite.international = true;
                    } else {
                        this.mobilite.international = false;
                    }
                    for (const p of this.candidat.permis) {
                        if (p === 'AM') {
                            this.permis.am = true;
                        } else if (p === 'A1') {
                            this.permis.a1 = true;
                        } else if (p === 'C1') {
                            this.permis.c1 = true;
                        } else if (p === 'A2') {
                            this.permis.a2 = true;
                        } else if (p === 'A') {
                            this.permis.a = true;
                        } else if (p === 'D1') {
                            this.permis.d1 = true;
                        } else if (p === 'B1') {
                            this.permis.b1 = true;
                        } else if (p === 'B') {
                            this.permis.b = true;
                        } else if (p === 'BE') {
                            this.permis.be = true;
                        } else if (p === 'C') {
                            this.permis.c = true;
                        } else if (p === 'CE') {
                            this.permis.ce = true;
                        } else if (p === 'D1E') {
                            this.permis.d1e = true;
                        } else if (p === 'D') {
                            this.permis.d = true;
                        } else if (p === 'DE') {
                            this.permis.de = true;
                        } else if (p === 'FLUVIAL') {
                            this.permis.fluvial = true;
                        } else if (p === 'C1E') {
                            this.permis.c1e = true;
                        } else if (p === 'COTIER') {
                            this.permis.cotier = true;
                        } else if (p === 'HAUTURIER') {
                            this.permis.hauturier = true;
                        } else if (p === 'BIA') {
                            this.permis.bia = true;
                        } else if (p === 'BB') {
                            this.permis.bb = true;
                        } else if (p === 'ATPL') {
                            this.permis.atpl = true;
                        } else if (p === 'PPL') {
                            this.permis.ppl = true;
                        } else if (p === 'CPL') {
                            this.permis.cpl = true;
                        } else if (p === 'LAPL') {
                            this.permis.lapl = true;
                        } else if (p === 'ATPL_H') {
                            this.permis.atpl_h = true;
                        } else if (p === 'PPL_H') {
                            this.permis.ppl_h = true;
                        } else if (p === 'CPL_H') {
                            this.permis.cpl_h = true;
                        } else if (p === 'LAPL_H') {
                            this.permis.lapl_h = true;
                        } else if (p === 'ULM_H') {
                            this.permis.ulm_h = true;
                        } else if (p === 'ULM') {
                            this.permis.ulm = true;
                        }
                    }
                    for (const t of this.candidat.typePoste) {
                        if (t === 'salarie') {
                            this.poste.salarie = true;
                        } else if (t === 'independant') {
                            this.poste.independant = true;
                        } else if (t === 'stagiaire') {
                            this.poste.stagiaire = true;
                        } else if (t === 'alternant') {
                            this.poste.alternant = true;
                        } else if (t === 'benevole') {
                            this.poste.benevole = true;
                        } else if (t === 'autre') {
                            this.poste.autre = true;
                        }
                    }
                    for (const t of this.candidat.typePosteVoulu) {
                        if (t === 'cdd') {
                            this.posteVoulu.cdd = true;
                        } else if (t === 'cdi') {
                            this.posteVoulu.cdi = true;
                        }
                    }
                    for (const t of this.candidat.temps) {
                        if (t === 'plein') {
                            this.temps.plein = true;
                        } else if (t === 'partiel') {
                            this.temps.partiel = true;
                        }
                    }
                    this.candidat.etreRecruteur = -1;
                    this.candidat.etreDansCVTech = -1;
                    this.candidat.autorisationAccueil = -1;
                    this.candidat.photoProfil = -1;
                    this.candidat.sendOffer = -1;
                    this.dateDisponibiliteHistorique = this.candidat.dateDisponibilite;
                    this.candidat.dateDisponibilite = '';
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }

    chooseAddrMobiliteAjout(): void {
        if (this.mobiliteRayon.rayon < 0) {
            this.mobiliteRayon.rayon = 0;
        }
        let pays = '';
        for (let p of this.listePays) {
            if (p.code === this.mobiliteRayon.pays) {
                pays = p.pays;
                break;
            }
        }
        const newMobilite = {
            latitude: this.mobiliteRayon.latitude,
            longitude: this.mobiliteRayon.longitude,
            ville: this.mobiliteRayon.villeSave,
            pays: pays,
            rayon: this.mobiliteRayon.rayon,
        };
        this.candidat.mobilite_locale.push(newMobilite);
        this.mobiliteRayon.ville = '';
        this.mobiliteRayon.pays = '';
        this.mobiliteRayon.rayon = 5;
        this.addrs = [];
        this.searchCity.next('');
    }

    removeMobilite(m: any): void {
        const newMobilites = [];
        for (const mo of this.candidat.mobilite_locale) {
            if (mo.ville !== m.ville) {
                newMobilites.push(mo);
            }
        }
        this.candidat.mobilite_locale = newMobilites;
    }
    chooseLangues(nom: string): void {
        this.searchLangues.next('');
        this.actuel.langue = '';
        this.actuel.langueCache = '';
        let exist = false;
        for (const l of this.candidat.langues) {
            if (nom === l.langue) {
                exist = true;
                break;
            }
        }
        if (!exist) {
            this.candidat.langues.push({ langue: nom, parle: '', ecrit: '' });
        }
    }

    removeLangue(nom: string): void {
        const newLangues = [];
        for (const l of this.candidat.langues) {
            if (l.langue !== nom) {
                newLangues.push(l);
            }
        }
        this.candidat.langues = newLangues;
    }
    cv(f: any): void {
        const file = f.target.files.item(0);
        const reader = new FileReader();
        if (file.type === 'application/pdf') {
            if (file.size <= 3000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.candidat.cv = reader.result;
                    this.sendCv();
                };
            } else {
                alert("L'image importé est supérieur à 3Mo. veuillez réduire sa taille");
            }
        } else {
            alert(
                "Le document que vous venez d'essayer d'importer n'est pas au format PDF. Vous pouvez uniquement importer un CV au format PDF."
            );
        }
    }
    afficherTypeContrat(): boolean {
        if (this.poste.salarie) {
            return true;
        }
        this.posteVoulu.cdd = false;
        this.posteVoulu.cdi = false;
        return false;
    }
    sendPassword(): void {
        this.httpClient
            .patch(this.globalService.url + '/candidat/password', { id: this.candidat._id, password: this.candidat.password }, {})
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    } else {
                        window.location.reload();
                    }
                },
                () => {}
            );
    }
    test(event: any): void {
        console.log(event);
    }
    sendActuel(): void {
        if (this.candidat.paysCode === 'FR') {
            this.candidat.estFrancais = true;
            let exist = false;
            for (const l of this.candidat.langues) {
                if ('Français' === l.langue) {
                    exist = true;
                    break;
                }
            }
            if (!exist) {
                this.candidat.langues.push({ langue: 'Français', parle: 'maternelle', ecrit: 'maternelle' });
            }
        } else {
            this.candidat.estFrancais = false;
        }
        this.httpClient
            .patch(
                this.globalService.url + '/candidat/informationsPersonnelles',
                {
                    id: this.candidat._id,
                    estFrancais: this.candidat.estFrancais,
                    civilite: this.candidat.civilite,
                    nom: this.candidat.nom,
                    prenom: this.candidat.prenom,
                    nationalites: this.candidat.nationalites,
                    dateNaissance: dayjs(this.candidat.dateNaissance, 'DD-MM-YYYY').format('DD-MM-YYYY'),
                    adresse: this.candidat.adresse,
                    complementAdresse: this.candidat.complementAdresse,
                    codePostal: this.candidat.codePostal,
                    ville: this.candidat.ville,
                    pays: this.candidat.pays,
                    latitude: this.candidat.pays,
                    longitude: this.candidat.pays,
                    paysCode: this.candidat.paysCode,
                    telephonePortable: this.candidat.telephonePortable,
                    telephoneFixe: this.candidat.telephoneFixe,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    console.log(response);
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    } else {
                        if (localStorage.hasOwnProperty('social_first_name')) {
                            localStorage.removeItem('social_first_name');
                        }
                        if (localStorage.hasOwnProperty('social_last_name')) {
                            localStorage.removeItem('social_last_name');
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    sendPhoto(): void {
        let photo = '';
        if (this.candidat.photo.split(';base64,').length > 1) {
            photo = this.candidat.photo;
        }
        this.httpClient.patch(this.globalService.url + '/candidat/photo-de-profil', { id: this.candidat._id, photo }, {}).subscribe(
            (response: any) => {
                if (!response.status) {
                    alert('Erreur lors de la modification');
                    this.photoUpload = '';
                } else {
                    this.photoUpload = '';
                    localStorage.setItem('photo', response.photo);
                }
            },
            () => {
                this.photoUpload = '';
            }
        );
    }
    sendSituation(): void {
        if (this.candidat.fonction.codeOgr !== 'autre') {
            let exist = false;
            for (const p of this.candidat.posteRechercher) {
                if (this.candidat.fonction.codeOgr === p.codeOgr) {
                    exist = true;
                    break;
                }
            }
            if (!exist) {
                this.candidat.posteRechercher.push(this.candidat.fonction);
            }
        }
        let exist = false;
        for (const s of this.candidat.secteurActivite) {
            exist = false;
            for (const sResearch of this.candidat.secteurRechercher) {
                if (sResearch.nom == s.nom) {
                    exist = true;
                }
            }

            if (!exist && s.nom !== 'Autre') {
                this.candidat.secteurRechercher.push(s);
            }
        }

        this.httpClient
            .patch(
                this.globalService.url + '/candidat/actuel',
                {
                    id: this.candidat._id,
                    fonction: this.candidat.fonction,
                    fonctionLibre: this.candidat.fonctionLibre,
                    secteurActivite: this.candidat.secteurActivite,
                    experienceSecteur: this.candidat.experienceSecteur,
                    experience: this.candidat.experience,
                    niveauEtude: this.candidat.niveauEtude,
                    langues: this.candidat.langues,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }

    sendComplementSituation(): void {
        if (this.mobilite.international) {
            this.candidat.mobilite_internationale = true;
        }
        const remu = [];
        // tslint:disable-next-line:radix
        if (Number.parseInt(this.candidat.remunerationSouhaiter[0]) > 0) {
            remu.push(this.candidat.remunerationSouhaiter[0]);
        } else {
            remu.push(0);
        }
        // tslint:disable-next-line:radix
        if (Number.parseInt(this.candidat.remunerationSouhaiter[1]) > 0) {
            remu.push(this.candidat.remunerationSouhaiter[1]);
        } else {
            remu.push(0);
        }
        // tslint:disable-next-line:radix
        if (Number.parseInt(this.candidat.remunerationSouhaiter[2]) > 0) {
            remu.push(this.candidat.remunerationSouhaiter[2]);
        } else {
            remu.push(0);
        }
        // tslint:disable-next-line:radix
        if (Number.parseInt(this.candidat.remunerationSouhaiter[3]) > 0) {
            remu.push(this.candidat.remunerationSouhaiter[3]);
        } else {
            remu.push(0);
        }
        this.httpClient
            .patch(
                this.globalService.url + '/candidat/complementSituation',
                {
                    id: this.candidat._id,
                    mobilite_locale: this.candidat.mobilite_locale,
                    mobilite_nationale: this.candidat.mobilite_nationale,
                    mobilite_internationale: this.candidat.mobilite_internationale,
                    remunerationSouhaiter: remu,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    sendRecherche(): void {
        const typePoste = [];
        if (this.poste.alternant) {
            typePoste.push('alternant');
        }
        if (this.poste.autre) {
            typePoste.push('autre');
        }
        if (this.poste.benevole) {
            typePoste.push('benevole');
        }
        if (this.poste.independant) {
            typePoste.push('independant');
        }
        if (this.poste.salarie) {
            typePoste.push('salarie');
        }
        if (this.poste.stagiaire) {
            typePoste.push('stagiaire');
        }

        const typePosteVoulu = [];
        if (this.posteVoulu.cdd) {
            typePosteVoulu.push('cdd');
        }
        if (this.posteVoulu.cdi) {
            typePosteVoulu.push('cdi');
        }

        const temps = [];
        if (this.temps.partiel) {
            temps.push('partiel');
        }
        if (this.temps.plein) {
            temps.push('plein');
        }

        this.httpClient
            .patch(
                this.globalService.url + '/candidat/situation',
                {
                    id: this.candidat._id,
                    situation: this.candidat.situation,
                    teleTravail: this.candidat.teleTravail,
                    disponibilite: this.candidat.disponibilite,
                    dateDisponibilite: this.candidat.dateDisponibilite
                        ? dayjs(this.candidat.dateDisponibilite, 'DD-MM-YYYY').format('YYYY-MM-DD')
                        : '',
                    posteRechercher: this.candidat.posteRechercher,
                    secteurRechercher: this.candidat.secteurRechercher,
                    typePoste,
                    typePosteVoulu,
                    temps,
                    dispo: this.dispo,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    sendInformationsComplementaires(): void {
        const permis = [];
        if (this.permis.am) {
            permis.push('AM');
        }
        if (this.permis.a1) {
            permis.push('A1');
        }
        if (this.permis.c1) {
            permis.push('C1');
        }
        if (this.permis.a2) {
            permis.push('A2');
        }
        if (this.permis.a) {
            permis.push('A');
        }
        if (this.permis.d1) {
            permis.push('D1');
        }
        if (this.permis.b1) {
            permis.push('B1');
        }
        if (this.permis.b) {
            permis.push('B');
        }
        if (this.permis.be) {
            permis.push('BE');
        }
        if (this.permis.c) {
            permis.push('C');
        }
        if (this.permis.ce) {
            permis.push('CE');
        }
        if (this.permis.d1e) {
            permis.push('D1E');
        }
        if (this.permis.d) {
            permis.push('D');
        }
        if (this.permis.de) {
            permis.push('DE');
        }
        if (this.permis.fluvial) {
            permis.push('FLUVIAL');
        }
        if (this.permis.c1e) {
            permis.push('C1E');
        }
        if (this.permis.cotier) {
            permis.push('COTIER');
        }
        if (this.permis.hauturier) {
            permis.push('HAUTURIER');
        }
        if (this.permis.bia) {
            permis.push('BIA');
        }
        if (this.permis.bb) {
            permis.push('BB');
        }
        if (this.permis.atpl) {
            permis.push('ATPL');
        }
        if (this.permis.ppl) {
            permis.push('PPL');
        }
        if (this.permis.cpl) {
            permis.push('CPL');
        }
        if (this.permis.lapl) {
            permis.push('LAPL');
        }
        if (this.permis.atpl_h) {
            permis.push('ATPL_H');
        }
        if (this.permis.ppl_h) {
            permis.push('PPL_H');
        }
        if (this.permis.cpl_h) {
            permis.push('CPL_H');
        }
        if (this.permis.lapl_h) {
            permis.push('LAPL_H');
        }
        if (this.permis.ulm_h) {
            permis.push('ULM_H');
        }
        if (this.permis.ulm) {
            permis.push('ULM');
        }
        this.httpClient
            .patch(
                this.globalService.url + '/candidat/other',
                {
                    id: this.candidat._id,
                    permis,
                    handicape: this.candidat.handicape,
                    atoutConcurrenciel: this.candidat.atoutConcurrenciel,
                    lettreMotivation: this.candidat.lettreMotivation,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    sendCv(): void {
        this.renouvellerErreur();
        this.httpClient.patch(this.globalService.url + '/candidat/cv', { id: this.candidat._id, cv: this.candidat.cv }, {}).subscribe(
            (response: any) => {
                if (!response.status) {
                    alert('Erreur lors de la modification');
                } else {
                    this.candidat.cv = response.cv;
                }
            },
            () => {}
        );
    }
    sendAutorisation(): void {
        this.httpClient
            .patch(
                this.globalService.url + '/candidat/autorisation',
                {
                    id: this.candidat._id,
                    autorisationAccueil: this.candidat.autorisationAccueil,
                    etreDansCVTech: this.candidat.etreDansCVTech,
                    photoProfil: this.candidat.photoProfil,
                    sendOffer: this.candidat.sendOffer,
                    sendOfferType: this.candidat.sendOfferType,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    sendMailVerificationEmail(): void {
        this.httpClient
            .put(this.globalService.url + '/mail/verifier-email', {
                id: this.candidat._id,
                email: this.candidat.email,
                type: 'Candidat',
            })
            .subscribe(
                (response) => {},
                () => {}
            );
    }
    verifierCode(): void {
        this.httpClient
            .patch(
                this.globalService.url +
                    '/candidat/mail/' +
                    this.candidat.referenceCandidat.replace('CAND', this.candidat._id) +
                    '/' +
                    this.code,
                {}
            )
            .subscribe(
                (response) => {
                    this.toastrService.info('Email vérifié', '', {
                        opacity: 1,
                    });
                    this.candidat.emailVerifier = true;
                    this.verificationCode.hide();
                },
                () => {
                    this.toastrService.error('Le code saisi est incorrect', '', { opacity: 1 });
                }
            );
    }
}
