import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-temoignages',
    templateUrl: './temoignages.component.html',
    styleUrls: ['./temoignages.component.css'],
})
export class TemoignagesComponent implements OnInit {
    temoignages: any = [];
    nouveau = {
        photo: '',
        nom: '',
        texte: '',
        ordre: 0,
    };
    url = '';
    tableau: any[] = [];
    erreurAjout = false;
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.getTemoignages();
        this.url = this.globalService.url;
    }
    ajouter(): void {
        this.erreurAjout = false;
        if (this.nouveau.nom === '' || this.nouveau.texte === '' || this.nouveau.photo === '') {
            this.erreurAjout = true;
        } else {
            this.httpClient
                .put(
                    this.globalService.url + '/temoignage',
                    {
                        nom: this.nouveau.nom,
                        texte: this.nouveau.texte,
                        photo: this.nouveau.photo,
                        ordre: this.temoignages.length + 1,
                        type: 'candidat',
                    },
                    {}
                )
                .subscribe(
                    (response: any) => {
                        if (response.status) {
                            this.nouveau = {
                                nom: '',
                                photo: '',
                                texte: '',
                                ordre: 0,
                            };
                            this.getTemoignages();
                            this.tableau.push(this.tableau.length + 1);
                        } else {
                            alert('Erreur lors de la modification');
                        }
                    },
                    () => {}
                );
        }
    }
    modifier(id: string): void {
        for (let t of this.temoignages) {
            if (t._id === id) {
                let nouvellePhoto = '';
                if (t.nouvellePhoto !== undefined && t.nouvellePhoto !== null) {
                    nouvellePhoto = t.nouvellePhoto;
                }
                this.httpClient
                    .post(
                        this.globalService.url + '/temoignage/' + id,
                        {
                            nom: t.nom,
                            texte: t.texte,
                            photo: t.photo,
                            nouvellePhoto: nouvellePhoto,
                        },
                        {}
                    )
                    .subscribe(
                        (response: any) => {
                            if (response.status) {
                                this.getTemoignages();
                                alert('Témoignage enregistré');
                            } else {
                                alert('Erreur lors de la modification');
                            }
                        },
                        () => {}
                    );
            }
        }
    }
    estOrdre(id: string, ordre: number): boolean {
        for (let t of this.temoignages) {
            if (t._id === id) {
                if (t.ordre === ordre) {
                    return true;
                }
                break;
            }
        }
        return false;
    }
    photo(f: any, id: string): void {
        const file = f.target.files.item(0);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (id === '-1') {
                // @ts-ignore
                this.nouveau.photo = reader.result;
            } else {
                for (let t of this.temoignages) {
                    if (t._id === id) {
                        t.nouvellePhoto = reader.result;
                    }
                }
            }
        };
    }
    modifierOrdre(id: string, event: any): void {
        let ordre = event.target.value;
        let ordreAncien = -1;
        for (let t of this.temoignages) {
            if (t._id === id) {
                ordreAncien = t.ordre;
                break;
            }
        }
        let ordres = [];
        for (let t of this.temoignages) {
            if (t._id === id) {
                console.log(t.ordre + ' - ' + ordre);
                t.ordre = ordre;
            } else if (t.ordre >= ordre && ordreAncien > t.ordre) {
                t.ordre++;
            } else if (t.ordre <= ordre && ordreAncien < t.ordre) {
                t.ordre--;
            }
            ordres.push({
                id: t._id,
                ordre: t.ordre,
            });
        }
        this.httpClient.patch<any>(this.globalService.url + '/temoignage/update-ordre', { ordres: ordres }, {}).subscribe(
            (response) => {},
            () => {}
        );
    }
    supprimer(id: string): void {
        this.httpClient.delete(this.globalService.url + '/temoignage/' + id).subscribe(
            (response: any) => {
                if (response.status) {
                    this.getTemoignages();
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    getTemoignages(): void {
        this.temoignages = [];
        this.httpClient.get<any>(this.globalService.url + '/temoignage/candidat').subscribe(
            (response) => {
                if (response.status) {
                    for (const f of response.temoignages) {
                        this.temoignages.push(f);
                    }
                    if (this.tableau.length === 0) {
                        for (let i = 1; i <= this.temoignages.length; i++) {
                            this.tableau.push(i);
                        }
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
