<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Gestion des candidats</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table style="width: 100%">
                                    <tr>
                                        <td style="width: 50%; text-align: left">
                                            <button class="btn btn-success" (click)="download()">Exporter XLS</button>
                                        </td>
                                        <td style="width: 50%; text-align: right">
                                            <input
                                                class="form-control"
                                                type="text"
                                                (input)="getCandidat()"
                                                [(ngModel)]="recherche"
                                                placeholder="Rechercher..."
                                            />
                                        </td>
                                    </tr>
                                </table>
                                <table class="display table dataTable">
                                    <thead>
                                        <tr>
                                            <th
                                                (click)="changeSens('dateCreation')"
                                                [ngClass]="tri == 'dateCreation' ? sensClass : 'sorting'"
                                            >
                                                Inscription
                                            </th>
                                            <th
                                                (click)="changeSens('dateMiseAJour')"
                                                [ngClass]="tri == 'dateMiseAJour' ? sensClass : 'sorting'"
                                            >
                                                Mise à jour
                                            </th>
                                          <th (click)="changeSens('referenceCandidat')" [ngClass]="tri == 'referenceCandidat' ? sensClass : 'sorting'">Référence</th>
                                          <th (click)="changeSens('nom')" [ngClass]="tri == 'nom' ? sensClass : 'sorting'">Nom</th>
                                            <th (click)="changeSens('prenom')" [ngClass]="tri == 'prenom' ? sensClass : 'sorting'">
                                                Prénom
                                            </th>
                                            <th (click)="changeSens('email')" [ngClass]="tri == 'email' ? sensClass : 'sorting'">Email</th>
                                            <th
                                                (click)="changeSens('telephonePortable')"
                                                [ngClass]="tri == 'telephonePortable' ? sensClass : 'sorting'"
                                            >
                                                Téléphone
                                            </th>
                                            <th *ngIf="type != 'non-terminer'">Nombre désabo</th>
                                            <th *ngIf="type != 'non-terminer'">Crédit</th>
                                            <th *ngIf="type != 'non-terminer'">Commandes</th>
                                            <th>Actions</th>
                                            <th>Fiche</th>
                                            <th>
                                                <div *ngIf="type == 'non-terminer'">E-mail</div>
                                                <div *ngIf="type == 'terminer'">Bloquer</div>
                                                <div *ngIf="type == 'bloquer'">Débloquer</div>
                                            </th>
                                            <th *ngIf="type != 'desinscrits' && type != 'supprimes'">Supp</th>
                                            <th *ngIf="type == 'bloquer'">Motif</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let c of candidats">
                                            <td>
                                                {{ c.dateCreation * 1000 | date: 'dd-MM-yyyy' }}
                                            </td>
                                            <td>
                                                {{ c.dateMiseAJour * 1000 | date: 'dd-MM-yyyy' }}
                                            </td>
                                          <td>
                                            {{ c.referenceCandidat }}
                                          </td>
                                            <td>
                                                {{ c.nom }}
                                            </td>
                                            <td>
                                                {{ c.prenom }}
                                            </td>
                                            <td [style]='type === "non-terminer" && c.candidatureSpontanees && c.candidatureSpontanees.length > 0 ? "color:blue;" : ""'>
                                                {{ c.email }}
                                            </td>
                                            <td>
                                                {{ c.telephonePortable }}
                                                <span *ngIf="c.telephonePortable !== '' && c.telephoneFixe !== ''"><br /></span>
                                                <div *ngIf="c.telephoneFixe.length >= 6">
                                                    {{ c.telephoneFixe }}
                                                </div>
                                            </td>
                                            <td *ngIf="type != 'non-terminer'">
                                                {{ c.desabonnement.length }}
                                            </td>
                                            <td *ngIf="type != 'non-terminer'">{{ c.credit }} &euro;</td>
                                            <td *ngIf="type != 'non-terminer'">
                                                <a target="_blank" href="/administration/commande-candidat/{{ c._id }}">{{
                                                    c.commandes
                                                }}</a>
                                            </td>
                                            <td>
                                                <a routerLink="/administration/tracking/{{ c._id }}/Candidat" class="btn btn-info"
                                                    >Tracking</a
                                                >
                                            </td>
                                            <td>
                                                <a

                                                    target="_blank"
                                                    routerLink="/administration/candidat/{{ c._id }}"
                                                    class="btn btn-info"
                                                    >Fiche</a
                                                >
                                            </td>
                                            <td>
                                                <button
                                                    *ngIf="type == 'non-terminer'"
                                                    (click)="envoyer(c._id, c.email)"
                                                    class="btn btn-success"
                                                    [disabled]="c.emailVerifier"
                                                >
                                                    Envoyer
                                                </button>
                                                <button *ngIf="type == 'terminer'" (click)="bloquer(c._id)" class="btn btn-success">
                                                    Bloquer
                                                </button>
                                                <button *ngIf="type == 'bloquer'" (click)="debloquer(c._id)" class="btn btn-success">
                                                    Débloquer
                                                </button>
                                            </td>
                                            <td *ngIf="type != 'desinscrits' && type != 'supprimes'">
                                                <button (click)="supprimer(c._id)" class="btn btn-danger">Supprimer</button>
                                            </td>
                                            <td *ngIf="type == 'bloquer'">
                                                <div *ngIf="c.desinscription">Désinscription</div>
                                                <div *ngIf="!c.desinscription">Bloqué</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td [colSpan]="colspanUn"></td>
                                            <td [colSpan]="colspanDeux">
                                                <select class="form-control" [(ngModel)]="nbParPage" (ngModelChange)="changeNombre()">
                                                    <option value="10" [selected]="nbParPage === 10">Afficher 10 candidats</option>
                                                    <option value="25" [selected]="nbParPage === 25">Afficher 25 candidats</option>
                                                    <option value="50" [selected]="nbParPage === 50">Afficher 50 candidats</option>
                                                    <option value="100" [selected]="nbParPage === 100">Afficher 100 candidats</option>
                                                    <option value="250" [selected]="nbParPage === 250">Afficher 250 candidats</option>
                                                    <option value="500" [selected]="nbParPage === 500">Afficher 500 candidats</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="container-pagination">
                        <span>
                            <a *ngIf="page != 0" (click)="changePage(page - 1)" class="paginate_button">Précedent</a>
                            <span *ngFor="let p of pagination">
                                <span *ngIf="p == -1">...</span>
                                <a *ngIf="p != -1" (click)="changePage(p)" class="paginate_button" [ngClass]="{ current: page == p }">{{
                                    p + 1
                                }}</a>
                            </span>
                            <a *ngIf="page != nombrePage" (click)="changePage(page + 1)" class="paginate_button">Suivant</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
