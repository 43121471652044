import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';

import {
    faFilePdf,
    faHome,
    faShoppingBasket,
    faTimes,
    faGift,
    faEye,
    faEuroSign,
    faShoppingBag,
    faBriefcase,
    faInfoCircle,
    faQuestionCircle,
    faUnlock,
} from '@fortawesome/free-solid-svg-icons';

import { faCheckSquare, faIdBadge, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-candidat-header',
    templateUrl: './candidat-header.component.html',
    styleUrls: [
        './candidat-header.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/site/header.css',
    ],
    animations: [
        trigger('inOutAnimation', [transition(':enter', [style({ opacity: 0 }), animate('.25s ease-out', style({ opacity: 1 }))])]),
    ],
})
export class CandidatHeaderComponent implements OnInit {
    faHome = faHome;
    faCheck = faCheckSquare;
    faUser = faIdBadge;
    faEye = faEye;
    faPdf = faFilePdf;
    faTime = faTimes;
    faCommande = faShoppingBasket;
    faEuroSign = faEuroSign;
    faShoppingBag = faShoppingBag;
    faTrash = faTrash;
    faGift = faGift;
    faInfoCircle = faInfoCircle;
    faBriefCase = faBriefcase;
    faQuestionCircle = faQuestionCircle;
    faUnlock = faUnlock;

    url = '';

    cookie = false;
    gif = true;

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        let minimal = false;
        const url = window.location.href;
        if (url.split('home').length > 1) {
            this.url = 'home';
            this.tracking('Home');
        } else if (url.split('dashboard').length > 1 || url.split('mon-cv').length > 1) {
            this.url = 'dashboard';
            this.tracking('Dashboard');
        } else if (
            url.split('informations-personnelles').length > 1 ||
            url.split('mot-de-passe').length > 1 ||
            url.split('informations').length > 1 ||
            url.split('informations-complementaires').length > 1 ||
            url.split('recherche').length > 1 ||
            url.split('autorisations').length > 1 ||
            url.split('situation').length > 1
        ) {
            this.url = 'informations-personnelles';
            this.tracking('Informations personnelles');
        } else if (url.split('mes-selections').length > 1 || url.split('detail-panier').length > 1) {
            this.url = 'mes-selections';
            this.tracking('Mes sélections');
        } else if (url.split('panier').length > 1) {
            this.url = 'panier';
            this.tracking('Paniers');
        } else if (url.split('diffusion-non-souhaitee').length > 1) {
            this.url = 'diffusion-non-souhaitee';
            this.tracking('Diffusion non souhaitée');
        } else if (url.split('mes-commandes').length > 1) {
            this.url = 'mes-commandes';
            this.tracking('Mes commandes');
            minimal = true;
        } else if (url.split('mes-factures').length > 1) {
            this.url = 'mes-factures';
            this.tracking('Mes factures');
            minimal = true;
        } else if (url.split('desabonnement-recruteur').length > 1) {
            this.url = 'desabonnement-recruteur';
            this.tracking('Désabonnement recruteur');
        } else if (url.split('conseils').length > 1) {
            this.url = 'conseils';
            this.tracking('Conseils');
        }
    }
    tracking(requete: string): void {
        this.httpClient
            .put<any>(this.globalService.url + '/tracking', {
                identifiantUtilisateur: this.authService.id,
                typeCompte: 'Candidat',
                url: requete,
            })
            .subscribe(
                (response) => {},
                () => {}
            );
    }
    deconnexion(): void {
        this.authService.logout();
    }
}
