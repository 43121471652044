import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedin, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GlobalVariable } from '../../../global';
import { animate, style, transition, trigger } from '@angular/animations';
import { ModalDirective } from '../../../components/mdb/free/modals';
import { AuthService } from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-accueil-recruteur',
    templateUrl: './accueil-recruteur.component.html',
    styleUrls: ['./accueil-recruteur.component.css', './../../../../assets/css/site/main.css'],
    animations: [
        trigger('inOutAnimation', [transition(':enter', [style({ opacity: 0 }), animate('.25s ease-out', style({ opacity: 1 }))])]),
    ],
})
export class AccueilRecruteurComponent implements OnInit, AfterViewInit {
    @ViewChild('confirmMailModal') public confirmMailModal!: ModalDirective;

    register = {
        email: '',
        emailPro: '',
    };
    faFacebook = faFacebook;
    faLinkedin = faLinkedin;
    faGoogle = faGoogle;
    temoignages: any = [];
    dernierInscrit: any = [];
    index = 0;
    index2 = 1;
    url = '';

    nombreRecruteurs = 0;
    nombreCandidats = 0;

    type1 = 'password';
    eye1 = faEye;
    login = {
        identifiant: '',
        password: '',
    };
    error = '';
    tentativeConnexion = false;
    domaine = '';
    isMobile = false;

    inscription = false;

    stop1 = false;
    stop2 = false;

    recruteurSlides = GlobalVariable.RECRUTEUR_SLIDES;

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private authService: AuthService,
        private router: Router,
        private userService: UserService,
        private deviceService: DeviceDetectorService
    ) {}

    async ngOnInit(): Promise<void> {
        this.url = this.globalService.url;
        this.isMobile = this.deviceService.isMobile();

        if (this.userService.loggedInForRole('recruteur') && (await this.authService.isUserEnabled())) {
            this.router.navigate(['/espace-recruteur/dashboard']);
        }

        if (this.route.snapshot.queryParams.type !== undefined && this.route.snapshot.queryParams.type !== null) {
            this.inscription = this.route.snapshot.queryParams.type === 'register';
        }

        this.domaine = this.globalService.domain;

        this.getTemoignages();
        this.getNombreCandidats();
        this.getNombreRecruteurs();
        this.getDernierInscrit();
    }
    ngAfterViewInit(): void {
        if (!this.isMobile) {
            setInterval(() => {
                if (!this.stop1) {
                    this.index = (this.index + 1) % this.recruteurSlides.length;
                }
            }, 10000);
            setInterval(() => {
                if (!this.stop2) {
                    this.index2 = (this.index2 + 1) % this.temoignages.length;
                }
            }, 10000);
        }
    }
    getDernierInscrit(): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/dernierInscrit').subscribe(
            (response) => {
                if (response.status) {
                    this.dernierInscrit = response.accounts;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    getTemoignages(): void {
        this.temoignages = [];
        this.httpClient.get<any>(this.globalService.url + '/temoignage/recruteur').subscribe(
            (response) => {
                if (response.status) {
                    for (const f of response.temoignages) {
                        this.temoignages.push(f);
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    next(): void {
        this.index = (this.index + 1) % this.recruteurSlides.length;
        this.stop1 = true;
    }
    prev(): void {
        this.index = (this.index - 1 + this.recruteurSlides.length) % this.recruteurSlides.length;
        this.stop1 = true;
    }
    right(): void {
        this.index2 = (this.index2 + 1) % this.temoignages.length;
        this.stop2 = true;
    }

    left(): void {
        this.index2 = (this.index2 - 1 + this.temoignages.length) % this.temoignages.length;
        this.stop2 = true;
    }
    eye(): void {
        if (this.eye1 === faEye) {
            this.eye1 = faEyeSlash;
            this.type1 = 'text';
        } else {
            this.eye1 = faEye;
            this.type1 = 'password';
        }
    }
    getNombreCandidats(): void {
        this.httpClient.post(this.globalService.url + '/candidat/nombreCompte', { ficheComplete: true, status: true }).subscribe(
            (response: any) => {
                if (response.status) {
                    setTimeout(() => {
                        this.nombreCandidats = response.nombre;
                    }, 1500);
                } else {
                }
            },
            () => {}
        );
    }
    getNombreRecruteurs(): void {
        this.httpClient.post(this.globalService.url + '/recruteur/nombreCompte', { ficheComplete: true, status: true }).subscribe(
            (response: any) => {
                if (response.status) {
                    setTimeout(() => {
                        this.nombreRecruteurs = response.nombre;
                    }, 1500);
                } else {
                }
            },
            () => {}
        );
    }
    connexion(): void {
        this.tentativeConnexion = true;
        this.error = '';
        if (this.login.identifiant === '') {
            this.error = "Veuillez saisir vos codes d'accès";
            this.tentativeConnexion = false;
        } else if (this.login.password === '') {
            this.error = 'Veuillez saisir votre mot de passe';
            this.tentativeConnexion = false;
        } else {
            this.httpClient.post<any>(this.globalService.url + '/recruteur/login', this.login).subscribe(
                (response) => {
                    this.tentativeConnexion = false;
                    if (response.status) {
                        if (response.ficheComplete) {
                            this.authService.storeUserData(response, 'recruteur');
                            this.router.navigate(['/espace-recruteur/dashboard']);
                        } else {
                            this.tentativeConnexion = false;
                            this.error =
                                "Vous n'avez pas encore accès à votre compte. Vous recevrez un courriel une fois votre compte débloqué.";
                        }
                    } else {
                        this.tentativeConnexion = false;
                        this.error = 'Identifiant ou mot de passe incorrect';
                    }
                },
                () => {
                    this.tentativeConnexion = false;
                    this.error = 'Identifiant ou mot de passe incorrect';
                }
            );
        }
    }
    inscriptionAction(): void {
        this.tentativeConnexion = true;
        this.error = '';
        if (this.register.email === '') {
            this.error = 'Veuillez saisir un email';
            this.tentativeConnexion = false;
        } else {
            this.confirmMailModal.show();
        }
    }
    showInscription(): void {
        this.inscription = !this.inscription;
    }
    socialLogin(provider: string): void {
        if (provider === 'facebook') {
            window.open(`${this.url}/auth/facebook?type=recruteur`, '_self');
        } else if (provider === 'google') {
            window.open(`${this.url}/auth/google?type=recruteur`, '_self');
        } else {
            window.open(`${this.url}/auth/linkedin?type=recruteur`, '_self');
        }
    }
    createAccount(): void {
        window.scroll(0, 0);
        this.confirmMailModal.hide();
        this.register.emailPro = this.register.email;

        this.httpClient
            .put<any>(this.globalService.url + '/recruteur', {
                email: this.register.email,
                estFrancaise: true,
                derniereConnexionUtiliser: 'Manuel',
            })
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert("L'email est déjà utilisé pour un autre compte");
                        this.register.email = '';
                        this.tentativeConnexion = false;
                    } else {
                        this.authService.storeUserData(response, 'recruteur');
                        this.router.navigate(['/inscription-recruteur']);
                    }
                },
                () => {
                    alert("L'email est déjà utilisé pour un autre compte");
                    this.tentativeConnexion = false;
                    this.register.email = '';
                }
            );
    }
    setUsername(): void {
        //  this.login.identifiant = this.login.identifiant;
    }
}
