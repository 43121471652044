<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-candidat/informations-personnelles" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pl-0 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-candidat/informations-personnelles" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span style="">Informations complémentaires</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Informations complémentaires</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Gardez vos informations à jour</h2>
            </div>

            <div class="inscription-row">
                <label class="custom mb-3">Permis de conduire <small>( Ce champ est multiple )</small></label>
                <div class="d-flex flex-wrap">
                    <div class="col-md-3">
                        <label class="custom mb-3">Permis moto</label>
                        <div class="form-group">
                            <label class="container_check"
                                >AM
                                <img src="assets/img/permis/am.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.am" [checked]="permis.am" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >A1
                                <img src="assets/img/permis/a1.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.a1" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >A2
                                <img src="assets/img/permis/a2.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.a2" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >A
                                <img src="assets/img/permis/a.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.a" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="custom mb-3">Permis voiture</label>
                        <div class="form-group">
                            <label class="container_check"
                                >B
                                <img src="assets/img/permis/b.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.b" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >B1
                                <img src="assets/img/permis/b1.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.b1" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >BE
                                <img src="assets/img/permis/be.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.be" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="custom mb-3">Permis poids lourds</label>
                        <div class="form-group">
                            <label class="container_check"
                                >C
                                <img src="assets/img/permis/c.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.c" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >C1
                                <img src="assets/img/permis/c1.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.c1" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >CE
                                <img src="assets/img/permis/ce.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.ce" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >C1E
                                <img src="assets/img/permis/c1e.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.c1e" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label class="custom mb-3">Permis véhicule de transport en commun</label>
                        <div class="form-group">
                            <label class="container_check"
                                >D
                                <img src="assets/img/permis/d.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.d" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >D1
                                <img src="assets/img/permis/d1.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.d1" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >D1E
                                <img src="assets/img/permis/d1e.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.d1e" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >DE
                                <img src="assets/img/permis/de.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.de" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12">
                        <hr />
                    </div>
                    <div class="col-md-3">
                        <label class="custom mb-3">Permis bateau</label>
                        <div class="form-group">
                            <label class="container_check"
                                >Bateau hauturier
                                <img src="assets/img/permis/hauturier.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.hauturier" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >Bateau côtier
                                <img src="assets/img/permis/cotier.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.cotier" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >Bateau fluvial
                                <img src="assets/img/permis/fluvial.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.fluvial" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="custom mb-3">Brevet / licence pour avions et hélicoptères</label>

                        <div class="form-group">
                            <label class="container_check"
                                >Vol PPL (H)
                                <img src="assets/img/permis/ppl_h.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.ppl_h" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >Vol LAPL (H)
                                <img src="assets/img/permis/lapl_h.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.lapl_h" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >Vol CPL (H)
                                <img src="assets/img/permis/cpl_h.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.cpl_h" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >Vol ATPL (H)
                                <img src="assets/img/permis/atpl_h.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.atpl_h" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >Vol ULM (H)
                                <img src="assets/img/permis/ulm_h.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.ulm_h" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="custom mb-3">Brevet / licence pour avions et hélicoptères (Suite)</label>
                        <div class="form-group">
                            <label class="container_check"
                                >BB
                                <img src="assets/img/permis/bb.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.bb" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >BIA
                                <img src="assets/img/permis/bia.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.bia" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >PPL
                                <img src="assets/img/permis/ppl.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.ppl" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >LAPL
                                <img src="assets/img/permis/lapl.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.lapl" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >CPL
                                <img src="assets/img/permis/cpl.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.cpl" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >ATPL
                                <img src="assets/img/permis/atpl.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.atpl" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >ULM
                                <img src="assets/img/permis/ulm.png" style="width: 40px" />
                                <input type="checkbox" [(ngModel)]="permis.ulm" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="inscription-row">
                <div class="row">
                    <div class="col-md-6">
                        <label>Etes-vous reconnu(e) en qualit&eacute; de travailleur/euse handicap&eacute;(e) ?</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                                >Oui
                                <input type="radio" name="handicape" [checked]="this.candidat.handicape" (click)="handicap(true)" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                                >Non
                                <input type="radio" name="handicape" [checked]="!this.candidat.handicape" (click)="handicap(false)" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group inscription-row">
                <div class="d-flex flex-wrap align-items-center">
                    <div class="col-4 col-md-2">
                        <span class="font-weight-bold">Mon CV (.pdf)</span>
                    </div>
                    <div class="col-8 col-md-3">
                        <div *ngIf="candidat.cv !== ''">
                            <a target="_blank" href="{{ url }}/documents/cv/{{ candidat.cv }}"
                                ><small>{{ candidat.cv }}</small> <fa-icon class="ml-2" [icon]="faFilePdf"></fa-icon
                            ></a>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mt-3 mt-md-0">
                        <div class="file-field ml-3">
                            <div mdbBtn [rounded]="true" [outline]="false" color="secondary">
                                <span>{{ candidat.cv !== '' ? 'Modifier' : 'Ajouter' }}</span>
                                <input type="file" accept='.pdf' name="cvFile" mdbFileSelect (change)="cv($event)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group inscription-row">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <label>Quels sont vos atouts concurrentiels (pourquoi vous embaucher) ?</label>
                    </div>
                    <div class="col-12 col-md-6">
                        <textarea
                            placeholder="Exemple :&#10; 20 ans d’expérience en management d’équipes, de projets et de centres de projet en France et à l’international; Formation supérieure (MBA); Bilingue Français Anglais; Bon relationnel, pédagogue, dynamique, impliqué, adaptable, efficace"
                            style="height: auto"
                            rows="4"
                            type="text"
                            name="atoutConcurrenciel"
                            id="atoutConcurrenciel"
                            class="form-control"
                            [(ngModel)]="this.candidat.atoutConcurrenciel"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="form-group inscription-row">
              <app-lettre-motivation-editor [(lettreMotivation)]='candidat.lettreMotivation'></app-lettre-motivation-editor>

            </div>

            <div class="d-flex justify-content-center" style="position: relative">
                <p
                    style="color: red !important"
                    [ngStyle]="{
                        display: this.errorPage.status ? 'block' : 'none'
                    }"
                >
                    {{ errorPage.message }}
                </p>
                <p class="mention-obligatoire" style="color: red !important">* Obligatoire</p>
            </div>
            <div class="d-flex float-right mb-3">
                <button mdbBtn class="text-center" [rounded]="true" color="warning" size="lg" (click)="sendInformationsComplementaire()">
                    Valider
                </button>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
