<span style="color: red !important" *ngIf="paysNonSelectionner">Choisissez d'abord le pays</span>
<div class="md-form">
    <input
        type="text"
        name="villeRechercher"
        class="completer-input form-control mdb-autocomplete mb-0 bc-white"
        [ngModel]="ville.ville"
        (ngModelChange)="changeAddr($event)"
        (clearBtnClicked)="emitClear()"
        [mdbAutoCompleter]="autoAdresse"
        placeholder="Ville (remplir d'abord le pays)..."
    />
    <mdb-auto-completer class="visible" #autoAdresse="mdbAutoCompleter" textNoResults="Pas de résultat">
        <mdb-option *ngIf="loading">
            <div class="spinner-border spinner-border-sm ml-auto mr-auto text-dark" role="status">
                <span class="sr-only">Chargement...</span>
            </div>
        </mdb-option>
        <mdb-option
            [hidden]="loading"
            *ngFor="let addr of resultsCity | async"
            [value]="addr.adresse"
            (click)="chooseAddr(addr.text, addr.latitude, addr.longitude)"
        >
            <div class="d-flex flex-column">
                <strong>{{ addr.text }} {{ addr.postcode ? '( ' + addr.postcode + ' )' : '' }}</strong>
            </div>
        </mdb-option>
    </mdb-auto-completer>
</div>
