import { Component, OnInit, AfterViewInit, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GlobalVariable } from '../../../global';
import { CarouselComponent } from '../../../components/mdb/free/carousel';

@Component({
    selector: 'app-about-candidat',
    templateUrl: './about-candidat.component.html',
    styleUrls: ['./about-candidat.component.css'],
})
export class AboutCandidatComponent implements OnInit, AfterViewInit {
    @ViewChild('carousel', { static: true }) carousel!: CarouselComponent;

    temoignages: any = [];
    dernierInscrit: any = [];
    index = 0;
    index2 = 1;
    url = '';

    isMobile = false;
    slides: any = [[]];

    stop1 = false;
    stop2 = false;

    nombreRecruteurs = 0;
    nombreCandidats = 0;

    candidatSlides = GlobalVariable.CANDIDAT_SLIDES;
    cards = [
        {
            title: 'Durée \n' + 'd’inscription\n' + 'Pôle emploi\n',
            description:
                'Au 3e trimestre 2020, la durée d’inscription à Pôle emploi s’établit à 394 jours, en hausse de 8 jours par rapport au 1er trimestre 2020.',
        },
        {
            title: 'Inscription Pole emploi pour les plus de 50 ans',
            description:
                'La durée d’inscription à Pôle emploi au 3e trimestre 2020 est de 625 jours pour les hommes, et 687 jours pour les femmes.',
        },
        {
            title: 'Candidatures spontanées',
            description:
                'D’après une étude réalisée fin 2020 par l’équipe d’AvenueDesCandidats, 100% des candidats aiment recevoir des candidatures spontanées. 95% des candidats ont déjà recruté par ce biais.',
        },
        {
            title: 'Candidatures motivées',
            description:
                'D’après l’étude réalisée fin 2020 par l’équipe d’Avenue Des Recruteurs, les recruteurs apprécient tout particulièrement la démarche spontanée de candidat(e)s motivé(e)s.',
        },
        {
            title: 'Candidats',
            description:
                'D’après notre étude réalisée fin 2020, 89% des candidats en recherche d’emploi ont déjà envoyés des candidatures spontanées.',
        },
        {
            title: 'Temps par candidature',
            description:
                'Les candidats passent en moyenne 47 minutes par candidature (pour trouver l’employeur correspondant à leur critères, le nom du candidat, son email, puis lui adresser son CV et sa candidature par courriel).',
        },
    ];
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private router: Router,
        private deviceService: DeviceDetectorService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.deviceService.isMobile();

        if (!this.isMobile) this.router.navigate(['/candidat']);

        this.url = this.globalService.url;
        this.slides = this.chunk(this.cards, 1);

        this.getTemoignages();
        this.getDernierInscrit();
        this.getNombreCandidats();
        this.getNombreRecruteurs();
    }
    ngAfterViewInit(): void {
        setInterval(() => {
            if (!this.stop1) {
                this.index = (this.index + 1) % this.candidatSlides.length;
            }
        }, 10000);
        setInterval(() => {
            if (!this.stop2) {
                this.index2 = (this.index2 + 1) % this.temoignages.length;
            }
        }, 10000);
    }
    chunk(arr: any, chunkSize: number) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            R.push(arr.slice(i, i + chunkSize));
        }
        return R;
    }
    getNombreCandidats(): void {
        this.httpClient.post(this.globalService.url + '/candidat/nombreCompte', { ficheComplete: true, status: true }).subscribe(
            (response: any) => {
                if (response.status) {
                    setTimeout(() => {
                        this.nombreCandidats = response.nombre;
                    }, 1500);
                } else {
                }
            },
            (e) => {
                console.log(e);
            }
        );
    }
    getNombreRecruteurs(): void {
        this.httpClient.post(this.globalService.url + '/recruteur/nombreCompte', { ficheComplete: true, status: true }).subscribe(
            (response: any) => {
                if (response.status) {
                    setTimeout(() => {
                        this.nombreRecruteurs = response.nombre;
                    }, 1500);
                } else {
                }
            },
            () => {}
        );
    }
    getDernierInscrit(): void {
        this.httpClient.get<any>(this.globalService.url + '/recruteur/dernierInscrit').subscribe(
            (response) => {
                if (response.status) {
                    this.dernierInscrit = response.accounts;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    getTemoignages(): void {
        this.temoignages = [];
        this.httpClient.get<any>(this.globalService.url + '/temoignage/candidat').subscribe(
            (response) => {
                if (response.status) {
                    for (const f of response.temoignages) {
                        this.temoignages.push(f);
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    next(): void {
        this.index = (this.index + 1) % this.candidatSlides.length;
        this.stop1 = true;
    }
    prev(): void {
        this.index = (this.index - 1 + this.candidatSlides.length) % this.candidatSlides.length;
        this.stop1 = true;
    }
    right(): void {
        this.index2 = (this.index2 + 1) % this.temoignages.length;
        this.stop2 = true;
    }

    left(): void {
        this.index2 = (this.index2 - 1 + this.temoignages.length) % this.temoignages.length;
        this.stop2 = true;
    }
    @HostListener('swipeleft')
    swipePrev(): any {
        this.carousel.previousSlide();
    }
    @HostListener('swiperight')
    swipeNext(): any {
        this.carousel.nextSlide();
    }
}
