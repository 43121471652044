import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { GlobalVariable } from '../../../global';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Utils from '../../../utils';
import { LoginModalComponent } from '../../../components/modals/login-modal/login-modal.component';
import { UserService } from '../../../services/user.service';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { AuthService } from '../../../services/auth.service';
import * as dayjs from 'dayjs';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { RegisterModalComponent } from '../../../components/modals/register-modal/register-modal.component';

@Component({
    selector: 'app-candidatures-spontanees',
    templateUrl: './candidatures-spontanees.component.html',
    styleUrls: ['./candidatures-spontanees.component.css', './../../../../assets/css/inscription/style.css'],
})
export class CandidaturesSpontaneesComponent implements OnInit {
    @ViewChild('loginModal', { static: true }) loginModal!: LoginModalComponent;
    @ViewChild('registerModal', { static: true }) registerModal!: RegisterModalComponent;

    searchPays = new Subject<string>();
    filteredOptions: Observable<any[]>;
    listePays: any = [];

    faFilePdf = faFilePdf;

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private httpClient: HttpClient,
        private toastrService: ToastService,
        private authService: AuthService,
        private globalService: GlobalService
    ) {
        this.filteredOptions = this.searchPays.pipe(
            startWith(''),
            map((value: string) => Utils.filter(value, this.listePays))
        );
        this.resultsCity = this.searchCity.pipe(
            startWith(''),
            map((value: string) => Utils.filterCity(value, this.addrs))
        );
    }

    reference = '';
    url = '';
    dateDisponibilite = '';
    dateDisponibiliteHistorique = '';
    ancienDateDisponibilite = '';

    acceptation = false;
    updateInformations = false;
    displayForm = false;
    alreadyExist = false;
    sent = false;
    loading = false;
    haveAccount = 0;

    recruteur: any = {};
    candidat: any = {};
    addrs: any = [];

    typesExperiences = GlobalVariable.EXPERIENCES;
    typesEtudes = GlobalVariable.ETUDES;
    typesExperiencesSecteurs = GlobalVariable.EXPERIENCES_SECTEURS;
    typesDispos = GlobalVariable.DISPOS;

    searchCity = new Subject<string>();
    resultsCity: Observable<any> | undefined;

    cvFileName = '';

    dispo = {
        travail: '2',
        preavis: '1',
    };
    mobiliteRayon = {
        ville: '',
        villeSave: '',
        pays: '',
        latitude: '',
        longitude: '',
        rayon: 5,
    };
    actuel = {
        pays: '',
    };
    mobilite = {
        regional: false,
        national: false,
        international: false,
    };

    error = {
        prenom: false,
        nom: false,
        disponibilite: false,
        disponibiliteDate: false,
        civilite: false,
        cv: false,
        lettreMotivation: false,
        acceptation: false,
    };

    ngOnInit(): void {
        this.url = this.globalService.url;
        this.reference = this.route.snapshot.params.reference;

        if (this.authService.role && this.authService.role === 'candidat') {
            this.getAccount();
        }
        this.getPays();
        this.getRecruteur();
    }
    getPays(): void {
        this.httpClient.get<any>(this.globalService.url + '/pays/getPays').subscribe(
            (response) => {
                if (response.status) {
                    this.listePays = response.pays;
                    this.searchPays.next('');
                }
            },
            () => {}
        );
    }

    getRecruteur(): void {
        this.loading = true;
        this.httpClient.get<any>(this.globalService.url + '/recruteur/ref/' + this.reference).subscribe(
            (response: any) => {
                if (response.status) {
                    this.loading = false;
                    this.recruteur = response.recruteur;
                } else {
                    alert('Erreur');
                }
            },
            () => {}
        );
    }
    dispoTravail(event: any): void {
        const travail = event.currentTarget.checked;
        if (travail) {
            this.dispo.travail = '2';
        } else {
            this.dispo.travail = '1';
        }
    }
    dispoPreavis(event: any): void {
        const travail = event.currentTarget.checked;
        if (travail) {
            this.dispo.preavis = '2';
        } else {
            this.dispo.preavis = '1';
        }
    }
    chooseAddrMobiliteAjout(): void {
        if (this.mobiliteRayon.rayon < 0) {
            this.mobiliteRayon.rayon = 0;
        }
        let pays = '';
        for (let p of this.listePays) {
            if (p.code === this.mobiliteRayon.pays) {
                pays = p.pays;
                break;
            }
        }
        const newMobilite = {
            latitude: this.mobiliteRayon.latitude,
            longitude: this.mobiliteRayon.longitude,
            ville: this.mobiliteRayon.villeSave,
            pays,
            rayon: this.mobiliteRayon.rayon,
        };
        this.candidat.mobilite_locale.push(newMobilite);
        this.mobiliteRayon.ville = '';
        this.mobiliteRayon.pays = '';
        this.mobiliteRayon.rayon = 5;
        this.addrs = [];
        this.searchCity.next('');
    }
    removeMobilite(m: any): void {
        const newMobilites = [];
        for (const mo of this.candidat.mobilite_locale) {
            if (mo.ville !== m.ville) {
                newMobilites.push(mo);
            }
        }
        this.candidat.mobilite_locale = newMobilites;
    }
    choosePays(pays: any): void {
        this.actuel.pays = '';
        let ajouter = true;
        for (const p of this.candidat.mobilite_nationale) {
            if (p == pays) {
                ajouter = false;
                break;
            }
        }
        if (ajouter) {
            this.candidat.mobilite_nationale.push(pays);
        }
        this.searchPays.next('');
    }
    removePays(p: string): void {
        const newPays = [];
        for (const pays of this.candidat.mobilite_nationale) {
            if (p !== pays) {
                newPays.push(pays);
            }
        }
        this.candidat.mobilite_nationale = newPays;
    }

    cv(f: any): void {
        const file = f.target.files.item(0);
        const reader = new FileReader();
        this.cvFileName = file.name;
        if (file.type === 'application/pdf') {
            if (file.size <= 3000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.candidat.cv = reader.result;
                };
            } else {
                alert("L'image importé est supérieur à 3Mo. veuillez réduire sa taille");
            }
        } else {
            alert(
                "Le document que vous venez d'essayer d'importer n'est pas au format PDF. Vous pouvez uniquement importer un CV au format PDF."
            );
        }
    }
    async send(): Promise<void> {
        let validation = true;
        if (this.candidat.civilite === '') {
            this.error.civilite = true;
            validation = false;
        }
        if (this.candidat.nom === '') {
            this.error.nom = true;
            validation = false;
        }

        if (this.candidat.prenom === '') {
            this.error.prenom = true;
            validation = false;
        }

        if (this.candidat.cv === '') {
            this.error.cv = true;
            validation = false;
        }

        if (this.candidat.lettreMotivation === '' || this.candidat.lettreMotivation.length <= 100) {
            this.error.lettreMotivation = true;
            validation = false;
        }

        if (!this.acceptation) {
            this.error.acceptation = true;
            validation = false;
        }

        if (this.dispo.travail == '1' && (this.dispo.preavis == '' || this.dispo.preavis == '2') && this.candidat.disponibilite === '') {
            this.error.disponibilite = true;
            validation = false;
        }
        if (this.dispo.travail === '1' && this.dispo.preavis === '2' && this.candidat.disponibilite === '') {
            this.error.disponibilite = true;
            validation = false;
        } else if (this.dispo.travail === '2' || this.dispo.preavis === '1') {
            if (this.dispo.travail === '2') {
                this.dispo.preavis = '1';
            }
            // if (this.dateDisponibilite === '' && this.ancienDateDisponibilite !== '') {
            //     this.dateDisponibilite = dayjs(this.ancienDateDisponibilite, 'YYYY-MM-DD').format('DD-MM-YYYY');
            // }
            if (this.dateDisponibilite !== '') {
                const isAfter = dayjs(this.dateDisponibilite, 'DD-MM-YYYY').isAfter(dayjs().subtract(1, 'day'));
                if (!isAfter) {
                    this.error.disponibiliteDate = true;
                    validation = false;
                }
            } else if (this.dateDisponibilite === '' && this.ancienDateDisponibilite !== '') {
                this.dateDisponibilite = dayjs(this.ancienDateDisponibilite, 'YYYY-MM-DD').format('DD-MM-YYYY');
            } else {
                this.error.disponibiliteDate = true;
                validation = false;
            }
        }

        if (validation) {
            await this.sendMobilite();
            await this.sendComplement();
            await this.sendExperience();
            this.httpClient
                .post(
                    this.globalService.url + '/candidat/add-candidature-spontanee',
                    {
                        id: this.candidat._id,
                        cv: this.candidat.cv,
                        lettreMotivation: this.candidat.lettreMotivation,
                        refRecruteur: this.reference,
                        updateInformation: this.updateInformations,
                        civilite: this.candidat.civilite,
                        nom: this.candidat.nom,
                        prenom: this.candidat.prenom,
                    },
                    {}
                )
                .subscribe(
                    (response: any) => {
                        if (!response.status) {
                            this.toastrService.error('Une erreur est survenue', '', { opacity: 1 });
                        } else {
                            this.sent = true;
                            this.displayForm = false;
                            // @ts-ignore
                            window.scroll(0, 0);
                            this.toastrService.info('Candidature envoyée', '', { opacity: 1 });
                        }
                    },
                    () => {}
                );
        }
    }
    sendMobilite(): void {
        this.httpClient
            .patch(
                this.globalService.url + '/candidat/situation',
                {
                    id: this.candidat._id,
                    situation: this.candidat.situation,
                    disponibilite: this.candidat.disponibilite,
                    dateDisponibilite: this.dateDisponibilite ? dayjs(this.dateDisponibilite, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
                    posteRechercher: this.candidat.posteRechercher,
                    teleTravail: this.candidat.teleTravail,
                    secteurRechercher: this.candidat.secteurRechercher,
                    typePoste: this.candidat.typePoste,
                    typePosteVoulu: this.candidat.typePosteVoulu,
                    temps: this.candidat.temps,
                    dispo: this.dispo,
                },
                {}
            )
            .subscribe(
                (response: any) => {},
                (error) => {
                    console.log(error);
                }
            );
    }
    sendComplement(): void {
        if (this.mobilite.international) {
            this.candidat.mobilite_internationale = true;
        } else {
            this.candidat.mobilite_internationale = false;
        }
        this.httpClient
            .patch(
                this.globalService.url + '/candidat/complementSituation',
                {
                    id: this.candidat._id,
                    mobilite_locale: this.candidat.mobilite_locale,
                    mobilite_regionale: this.candidat.mobilite_regionale,
                    mobilite_nationale: this.candidat.mobilite_nationale,
                    mobilite_internationale: this.candidat.mobilite_internationale,
                    remunerationSouhaiter: this.candidat.remunerationSouhaiter,
                },
                {}
            )
            .subscribe(
                (response: any) => {},
                (error) => {
                    console.log(error);
                }
            );
    }
    sendExperience(): void {
        this.httpClient
            .patch(
                this.globalService.url + '/candidat/actuel',
                {
                    id: this.candidat._id,
                    fonction: this.candidat.fonction,
                    fonctionLibre: this.candidat.fonctionLibre,
                    secteurActivite: this.candidat.secteurActivite,
                    experienceSecteur: this.candidat.experienceSecteur,
                    experience: this.candidat.experience,
                    niveauEtude: this.candidat.niveauEtude,
                    langues: this.candidat.langues,
                },
                {}
            )
            .subscribe(
                (response: any) => {},
                () => {}
            );
    }
    handleChangeHaveAccount(event: any): void {
        console.log(event);
        const target = event.target;
        if (target.value === 'no') {
            this.registerModal.openForm();
        } else {
            this.loginModal.openForm();
        }
    }
    getAccount(): void {
        this.userService.getAccount().then((response: any) => {
            if (response.status) {
                this.candidat = response.candidat;
                if (this.candidat.nom !== '') {
                    this.alreadyExist = true;
                }
                if (this.candidat.emailVerifier) {
                    this.haveAccount = 1;
                    this.displayForm = true;
                }

                this.ancienDateDisponibilite = this.candidat.dateDisponibilite;

                if (this.candidat.mobilite_locale.length > 0) {
                    this.mobilite.regional = true;
                }
                this.mobilite.national = this.candidat.mobilite_nationale.length > 0;

                this.mobilite.international = this.candidat.mobilite_internationale;

                this.dateDisponibiliteHistorique = this.candidat.dateDisponibilite;
            } else {
                this.toastrService.error('Erreur lors de la récupération des informations', '', { opacity: 1 });
            }
        });
    }
    checkLogin(event: any): void {
        if (event) {
            this.getAccount();
        }
    }
    checkRegister(event: any): void {
        if (event) {
            this.getAccount();
        } else {
            this.registerModal.closeForm();
            this.loginModal.openForm();
        }
    }
}
