import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { MaterialChipsComponent } from './chips.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [CommonModule, FormsModule, FontAwesomeModule],
    declarations: [MaterialChipsComponent],
    exports: [MaterialChipsComponent],
})
export class ChipsModule {}
