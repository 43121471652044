<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Codes téléphoniques</h3>
        </div>
        <div class="row">
            <div class="col-md-6">
                <button
                    class="btn"
                    (click)="changerChoix('pays')"
                    [ngClass]="{
                        'btn-success': choix == 'pays',
                        'btn-danger': choix != 'pays'
                    }"
                >
                    Pays
                </button>
            </div>
            <div class="col-md-6">
                <button
                    class="btn"
                    (click)="changerChoix('langues')"
                    [ngClass]="{
                        'btn-success': choix == 'langues',
                        'btn-danger': choix != 'langues'
                    }"
                >
                    Langues
                </button>
            </div>
        </div>
        <div *ngIf="choix == 'pays'">
            <div class="row espace" *ngFor="let p of pays">
                <div class="col-4">
                    <input type="text" class="form-control" [(ngModel)]="p.pays" />
                </div>
                <div class="col-4">
                    <input type="text" class="form-control" [(ngModel)]="p.indicatif" />
                </div>
                <div class="col-4">
                    <button class="form-control btn btn-success" (click)="updatePays(p._id)">Modifier</button>
                </div>
            </div>
        </div>
        <div *ngIf="choix == 'langues'">
            <div class="row espace" *ngFor="let l of langues">
                <div class="col-6">
                    <input type="text" class="form-control" [(ngModel)]="l.langue" />
                </div>
                <div class="col-6">
                    <button class="form-control btn btn-success" (click)="updateLangue(l._id)">Modifier</button>
                </div>
            </div>
        </div>
    </div>
</div>
