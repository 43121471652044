import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit {
    faqs: any = [];
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.httpClient.get<any>(this.globalService.url + '/faq').subscribe(
            (response) => {
                if (response.status) {
                    for (const f of response.faqs) {
                        this.faqs.push(f);
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
