import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCartArrowDown, faShoppingBag, faPaperPlane, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { GlobalVariable } from '../../../global';
import { CarouselComponent } from '../../../components/mdb/free/carousel';

@Component({
    selector: 'app-dashboard-candidat',
    templateUrl: './dashboard.component.html',
    styleUrls: [
        './dashboard.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class DashboardCandidatComponent implements OnInit, AfterViewInit {
    @ViewChild('carousel', { static: true }) carousel!: CarouselComponent;

    faCartArrowDown = faCartArrowDown;
    faShoppingBag = faShoppingBag;
    faPaperPlane = faPaperPlane;
    faTimesCircle = faTimesCircle;

    id: string | null = '';
    nombrePanier = 0;
    nombreCommande = 0;
    nombreMailEnvoyer = 0;
    nombreRetrait = 0;

    index = 0;
    candidatSlides = GlobalVariable.CANDIDAT_DASHBOARD;

    stop1 = false;
    cvSet = false;

    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    cards = [
        {
            title: '1',
            description: 'Mentionnez vos atouts concurrentiels afin de vous démarquer des autres candidats',
        },
        {
            title: '2',
            description:
                'Vérifiez que les informations vous concernant sur Internet sont positives et cohérentes avec votre CV et votre lettre de motivation',
        },
        {
            title: '3',
            description:
                'Dans le cas d’un entretien vidéo, vérifiez préalablement la qualité de votre connexion Internet et des accessoires (casque et micro)',
        },
        {
            title: '4',
            description:
                'Assurez vous de la qualité du message délivré par votre messagerie. Ecoutez les messages laissés sur votre répondeur et enregistrez les plus importants. Supprimez ensuite les messages afin que votre messagerie ne soit pas saturée',
        },
        {
            title: '5',
            description:
                'Laissez votre téléphone sur vibreur. En cas d’appel d’un recruteur, ne décrochez pas pour avoir le temps de vous renseigner sur lui et son organisme avant de le recontacter',
        },
    ];

    slides: any = [[]];

    chunk(arr: any, chunkSize: number) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            R.push(arr.slice(i, i + chunkSize));
        }
        return R;
    }

    ngOnInit(): void {
        this.id = localStorage.getItem('id');
        this.getInfo();
        this.slides = this.chunk(this.cards, 1);
    }
    ngAfterViewInit(): void {
        setInterval(() => {
            if (!this.stop1) {
                this.index = (this.index + 1) % this.candidatSlides.length;
            }
        }, 10000);
    }
    getInfo(): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/dashboard/' + this.id, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.cvSet = response.cvSet;
                    this.nombrePanier = response.nombrePanier ?? 0;
                    this.nombreCommande = response.nombreCommande ?? 0;
                    this.nombreMailEnvoyer = response.nombreMailEnvoyer ?? 0;
                    this.nombreRetrait = response.nombreRetrait ?? 0;
                }
            },
            () => {}
        );
    }
    next(): void {
        this.index = (this.index + 1) % this.candidatSlides.length;
        this.stop1 = true;
    }
    prev(): void {
        this.index = (this.index - 1 + this.candidatSlides.length) % this.candidatSlides.length;
        this.stop1 = true;
    }
    @HostListener('swipeleft', ['$event'])
    swipePrev(event: any) {
        this.carousel.previousSlide();
    }

    @HostListener('swiperight', ['$event'])
    swipeNext(event: any) {
        this.carousel.nextSlide();
    }
}
