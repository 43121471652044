import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
import { ValidateService } from '../../../services/validate.service';
import { map, startWith } from 'rxjs/operators';
import Utils from '../../../utils';
import { Observable, Subject } from 'rxjs';
import { ReusableService } from '../../../services/reusable.service';

@Component({
    selector: 'app-select-country',
    templateUrl: './country-select.component.html',
    styleUrls: ['./country-select.component.css', './../../../../assets/css/site/header.css'],
})
export class CountrySelectComponent implements OnInit {
    @Input() model: any;
    @Output() selected: EventEmitter<any> = new EventEmitter();
    searchPays = new Subject<string>();
    filteredOptions: Observable<any[]>;
    listePays = [
        {
            pays: '',
            code: '',
        },
    ];
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService,
        private validateService: ValidateService,
        private router: Router,
        private reusableService: ReusableService
    ) {
        this.filteredOptions = this.searchPays.pipe(
            startWith(''),
            map((value: string) => Utils.filter(value, this.listePays))
        );
    }
    ngOnInit(): void {
        this.getPays();
    }
    getPays(): void {
        this.reusableService.getPays().then((result: any) => {
            this.listePays = result;
            this.searchPays.next('');
        });
    }
    emit(event: any): void {
        this.selected.emit(event);
    }
}
