import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCheckCircle, faTimes, faArrowLeft, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-recherche-recruteurs',
    templateUrl: './recherche.component.html',
    styleUrls: [
        './recherche.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class MaRechercheComponent implements OnInit {
    faFilePdf = faFilePdf;
    arrowLeft = faArrowLeft;
    faError = faTimes;
    faOk = faCheckCircle;
    error = {
        password: false,
        secteurEntreprise: false,
        activiteNom: false,
        passwordDeux: false,
        civilite: false,
        nom: false,
        prenom: false,
        fonctionRecruteur: false,
        informationComplementaireFonction: false,
        telephonePortable: false,
        telephoneFixe: false,
        email: false,
        emailPro: false,
        photo: false,
        siret: false,
        nomSociete: false,
        nomGroupe: false,
        adresse: false,
        complementAdresse: false,
        codePostal: false,
        ville: false,
        pays: false,
        numeroTva: false,
        codeNaf: false,
        nomActivite: false,
        ancienPassword: false,
        logoGroupe: false,
        presentationActivite: false,
        activiteTexte: false,
        typeEntreprise: false,
        nombreSalarie: false,
        activiteSpecifique: false,
        siteInternet: false,
        profilRechercher: false,
        metiersRechercher: false,
        secteurActivites: false,
    };
    url = '';
    recruteur: any = {};
    recherche = {
        metier: '',
        metierCache: '',
    };

    poste = {
        salarie: false,
        independant: false,
        stagiaire: false,
        alternant: false,
        benevole: false,
        autre: false,
    };
    posteVoulu = {
        cdd: false,
        cdi: false,
    };
    temps = {
        plein: false,
        partiel: false,
    };

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private userService: UserService,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {
        this.recruteur._id = localStorage.getItem('id');
        this.url = this.globalService.url;
        this.getAccount();
    }

    afficherTypeContrat(): boolean {
        if (this.poste.salarie) {
            return true;
        }
        this.posteVoulu.cdd = false;
        this.posteVoulu.cdi = false;
        return false;
    }

    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.recruteur = response.recruteur;

                    for (const t of this.recruteur.typePoste) {
                        if (t === 'salarie') {
                            this.poste.salarie = true;
                        } else if (t === 'independant') {
                            this.poste.independant = true;
                        } else if (t === 'stagiaire') {
                            this.poste.stagiaire = true;
                        } else if (t === 'alternant') {
                            this.poste.alternant = true;
                        } else if (t === 'benevole') {
                            this.poste.benevole = true;
                        } else if (t === 'autre') {
                            this.poste.autre = true;
                        }
                    }
                    for (const t of this.recruteur.typePosteVoulu) {
                        if (t === 'cdd') {
                            this.posteVoulu.cdd = true;
                        } else if (t === 'cdi') {
                            this.posteVoulu.cdi = true;
                        }
                    }
                    for (const t of this.recruteur.temps) {
                        if (t === 'plein') {
                            this.temps.plein = true;
                        } else if (t === 'partiel') {
                            this.temps.partiel = true;
                        }
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    renouvellerErreur(): void {
        this.error = {
            password: false,
            secteurEntreprise: false,
            activiteNom: false,
            passwordDeux: false,
            civilite: false,
            nom: false,
            prenom: false,
            fonctionRecruteur: false,
            informationComplementaireFonction: false,
            telephonePortable: false,
            telephoneFixe: false,
            email: false,
            emailPro: false,
            photo: false,
            siret: false,
            nomSociete: false,
            nomGroupe: false,
            adresse: false,
            complementAdresse: false,
            codePostal: false,
            ville: false,
            pays: false,
            numeroTva: false,
            codeNaf: false,
            nomActivite: false,
            ancienPassword: false,
            logoGroupe: false,
            presentationActivite: false,
            activiteTexte: false,
            typeEntreprise: false,
            nombreSalarie: false,
            activiteSpecifique: false,
            siteInternet: false,
            profilRechercher: false,
            metiersRechercher: false,
            secteurActivites: false,
        };
    }
    sendRecherche(): void {
        this.renouvellerErreur();
        const typePoste = [];
        if (this.poste.alternant) {
            typePoste.push('alternant');
        }
        if (this.poste.autre) {
            typePoste.push('autre');
        }
        if (this.poste.benevole) {
            typePoste.push('benevole');
        }
        if (this.poste.independant) {
            typePoste.push('independant');
        }
        if (this.poste.salarie) {
            typePoste.push('salarie');
        }
        if (this.poste.stagiaire) {
            typePoste.push('stagiaire');
        }
        const posteVoulu = [];
        if (this.posteVoulu.cdd) {
            posteVoulu.push('cdd');
        }
        if (this.posteVoulu.cdi) {
            posteVoulu.push('cdi');
        }
        const temps = [];
        if (this.temps.partiel) {
            temps.push('partiel');
        }
        if (this.temps.plein) {
            temps.push('plein');
        }
        this.httpClient
            .patch(
                this.globalService.url + '/recruteur/recherche',
                {
                    id: this.recruteur._id,
                    profilRechercher: this.recruteur.profilRechercher,
                    metiersRechercher: this.recruteur.metiersRechercher,
                    secteurActivites: this.recruteur.secteurActivites,
                    typePoste,
                    typePosteVoulu: posteVoulu,
                    temps: temps,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                    } else {
                        this.toastrService.info('Recherche modifiée', '', {
                            opacity: 1,
                        });
                        this.getAccount();
                    }
                },
                () => {}
            );
    }
}
