import { CardsModule } from './cards/cards.module';
import { ButtonsModule } from './buttons/buttons.module';
import { DropdownModule } from './dropdown/dropdown.module';
import { CarouselModule } from './carousel/carousel.module';
import { ModalModule } from './modals/modal.module';
import { InputsModule } from './inputs/inputs.module';
import { WavesModule } from './waves/waves.module';
import { CheckboxModule } from './checkbox/checkbox.module';
import { TableModule } from './tables/tables.module';

export { TableModule } from './tables';

export { CheckboxModule } from './checkbox';

export { ButtonsModule } from './buttons';
export { CollapseComponent, CollapseModule } from './collapse';

export { CardsModule } from './cards';

export { WavesModule } from './waves';

export { InputsModule } from './inputs';

export { DropdownModule } from './dropdown';

export { CarouselModule } from './carousel';

export { ModalModule } from './modals';
export { TooltipModule } from './tooltip';
export { PopoverModule } from './popover';
