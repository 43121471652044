import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { faArrowLeft, faTimes, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { MdbTableDirective, MdbTablePaginationComponent } from '../../../components/mdb/free/tables';
import { ModalDirective } from '../../../components/mdb/free/modals';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GlobalVariable } from '../../../global';
import { ValidateService } from '../../../services/validate.service';
import { PaymentService } from '../../../services/payment.service';

declare let paypal: any;

@Component({
    selector: 'app-valider-panier',
    templateUrl: './valider-panier.component.html',
    styleUrls: [
        './valider-panier.component.css',
        './../../../../assets/css/inscription/style.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        '../../../../../node_modules/quill-mention/dist/quill.mention.min.css',
    ],
})
export class ValiderPanierComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @ViewChild('mdbTablePagination', { static: true })
    mdbTablePagination!: MdbTablePaginationComponent;
    @ViewChild('mdbTable', { static: true }) mdbTable!: MdbTableDirective;

    @ViewChild('mdbTablePaginationNoContact', { static: true })
    mdbTablePaginationNoContact!: MdbTablePaginationComponent;
    @ViewChild('mdbTableNoContact', { static: true })
    mdbTableNoContact!: MdbTableDirective;

    @ViewChild('paypalModal', { static: true }) paypalModal!: ModalDirective;

    public retrait: string[] = [];
    retraitRecruteurs: any = [];
    public nouveau = '';
    public arrowLeft = faArrowLeft;
    public faTimes = faTimes;
    id = '';
    idCommande = '';
    idCandidat = '';
    isMobile = false;
    etape = 0;
    acceptation = false;

    modification = true;
    modifier = 1;

    faFilePdf = faFilePdf;

    headElements = [
        'Référence du recruteur',
        'Localisation',
        "Type d'organisme",
        'Nombre de salariés',
        'Activité(s) spécifique(s)',
        "Secteur(s) d'activité(s)",
    ];
    headElementsNoContact = ['Email / domaine à retirer', 'Supprimer'];

    previous: any = [];
    previousNoContact: any = [];

    recruteurs: any = [];
    ids: any = [];
    retour = '';

    nomCandidat = '';
    sujet = '';
    emailDiffusion = '';
    url = '';
    erreurTexte = '';
    cvUpload = '1';
    ancienCv = '';
    lettreMotivation = '';
    lettreMotivationOriginal = '';
    errorImport = false;
    error = false;
    cv = '';

    date = 0;
    heure = 0;
    tableauDate: any = [];
    refreshPaypal = false;

    errorValidation = {
        acceptation: false,
        cv: false,
        paymentMethod: false,
        code: false,
        objet: false,
        mail: false,
    };
    candidat: any = {};
    commande: any = {};
    sousTotalTtc = 0;
    sousTotalHt = 0;
    totalTtc = 0;
    totalHt = 0;
    tva = 0;
    retraitDesabo = 0;
    credit = 0;
    creditUtiliser = 0;
    creditRestant = 0;
    nomCode = '';
    paymentMethod = '';

    displayByPages = GlobalVariable.PAGES;

    addScript = false;
    paypalLoad = true;

    paypalConfig = {
        env: this.globalService.envType === 'prod' ? 'production' : 'sandbox',
        payment: (data: any, actions: any) => {
            return actions.request.post(this.globalService.url + '/my-api/create-payment/' + this.idCommande).then((res: any) => {
                return res.id;
            });
        },
        onAuthorize: (data: any, actions: any) => {
            return actions.request
                .post(this.globalService.url + '/my-api/execute-payment/', {
                    paymentID: data.paymentID,
                    payerID: data.payerID,
                })
                .then(() => {});
        },
    };

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private router: Router,
        private validateService: ValidateService,
        private cdRef: ChangeDetectorRef,
        private deviceService: DeviceDetectorService,
        private toastrService: ToastService,
        private paymentService: PaymentService
    ) {}

    ngOnInit(): void {
        if (this.route.snapshot.params.id !== undefined && this.route.snapshot.params.id !== null) {
            this.id = this.route.snapshot.params.id;
            this.retour = this.route.snapshot.params.retour;
            this.getIdCommande();
            this.getNonSouhaitee();
            this.getPanier();
        }
        this.url = this.globalService.url;

        this.isMobile = this.deviceService.isMobile();

        setInterval(() => {
            if (this.refreshPaypal) {
                this.getCommande();
                if (this.commande.idPaypal !== '' && this.commande.paypalOk) {
                    this.router.navigate(['espace-candidat/commande-accepter/' + this.idCommande]);
                }
            }
        }, 2000);
    }
    ngAfterViewInit(): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();

        this.mdbTablePaginationNoContact.setMaxVisibleItemsNumberTo(10);
        this.mdbTablePaginationNoContact.calculateFirstItemIndex();
        this.mdbTablePaginationNoContact.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
    ngAfterViewChecked(): void {
        if (!this.addScript) {
            this.addPaypalScript().then(() => {
                paypal.Button.render(this.paypalConfig, '#paypal-button');
                this.paypalLoad = false;
            });
        }
    }
    changeItemsByPage(event: any): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(event);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
    }
    changeItemsByPageNoContact(event: any): void {
        this.mdbTablePaginationNoContact.setMaxVisibleItemsNumberTo(event);

        this.mdbTablePaginationNoContact.calculateFirstItemIndex();
        this.mdbTablePaginationNoContact.calculateLastItemIndex();
    }
    getIdCommande(): void {
        this.httpClient
            .put<any>(this.globalService.url + '/commande', { idPanier: this.id, idCandidat: localStorage.getItem('id') }, {})
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.idCommande = response.idCommande;
                        this.getCommande();
                    }
                },
                (err) => {
                    console.log(err);
                }
            );
    }
    display(): string {
        if (this.recruteurs.length === 0) {
            return 'none';
        }
        return this.isMobile ? 'block' : 'table';
    }
    displayNoContact(): string {
        if (this.retrait.length === 0) {
            return 'none';
        }
        return 'table';
    }
    ajout(): void {
        if (this.validateService.containAt(this.nouveau)) {
            this.retrait.push(this.nouveau);
            this.nouveau = '';
            this.mdbTableNoContact.setDataSource(this.retrait);
            this.retrait = this.mdbTableNoContact.getDataSource();
            this.previous = this.mdbTableNoContact.getDataSource();
            this.modifierNoContact();
        } else {
            this.toastrService.error('Le texte doit contenir un @', '', {
                opacity: 1,
            });
        }
    }
    refreshData(retrait: any): void {
        this.retrait = [];
        retrait.forEach((element: string) => {
            this.retrait.push(element);
        });
        this.mdbTable.setDataSource(this.retrait);
        this.retrait = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
    }
    modifierNoContact(): void {
        this.refreshData(this.retrait);
        this.httpClient
            .patch<any>(
                this.globalService.url + '/candidat/diffusion-non-souhaitee',
                {
                    id: localStorage.getItem('id'),
                    diffusionNonSouhaitee: this.retrait,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.toastrService.info('Votre liste de retrait a bien été sauvegardée', '', { opacity: 1 });
                    }
                },
                () => {}
            );
    }
    getCandidat(id: string): void {
        this.httpClient
            .get<any>(this.globalService.url + '/candidat/' + id, {
                headers: this.globalService.hideProgressBarHttp(),
            })
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.candidat = response.candidat;
                        this.credit = this.candidat.credit;
                        this.calculPrix();
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    getNonSouhaitee(): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/diffusion-non-souhaitee/' + localStorage.getItem('id')).subscribe(
            (response) => {
                if (response.status) {
                    this.retrait = response.retrait;
                    this.mdbTableNoContact.setDataSource(this.retrait);
                    this.retrait = this.mdbTableNoContact.getDataSource();
                    this.previous = this.mdbTableNoContact.getDataSource();
                }
            },
            () => {}
        );
    }
    retirer(text: string): void {
        let newRetrait = [];
        for (let r of this.retrait) {
            if (r !== text) {
                newRetrait.push(r);
            }
        }
        this.retrait = newRetrait;
        this.modifierNoContact();
    }
    retourFonction(): void {
        if (this.retour === 'retour') {
            this.router.navigate(['/espace-candidat/mes-selections']);
        } else {
            this.router.navigate(['/espace-candidat/' + this.retour + '/' + this.id]);
        }
    }
    getPanier(): void {
        this.ids = [];
        this.httpClient.get<any>(this.globalService.url + '/panier/' + this.id).subscribe(
            (response) => {
                if (response.status) {
                    this.ids = response.panier.recruteurSelectionner;
                    this.getAccountRecruteurs();
                }
            },
            () => {}
        );
    }
    getAccount(idCandidat: string): void {
        this.httpClient
            .get<any>(this.globalService.url + '/candidat/' + idCandidat, {
                headers: this.globalService.hideProgressBarHttp(),
            })
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.nomCandidat = response.candidat.prenom + ' ' + response.candidat.nom;
                        this.emailDiffusion = response.candidat.email;
                        this.lettreMotivation = response.candidat.lettreMotivation;
                        this.lettreMotivationOriginal = response.candidat.lettreMotivation;
                        this.cv = response.candidat.cv;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    getAccountRecruteurs(): void {
        this.recruteurs = [];
        let ids = [];
        for (let i of this.ids) {
            ids.push(i.idRecruteur);
        }
        this.httpClient.post<any>(this.globalService.url + '/recruteur/getWithIds', { ids: ids }, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.recruteurs = response.recruteurs;
                    this.mdbTable.setDataSource(this.recruteurs);
                    this.recruteurs = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                }
            },
            () => {}
        );
    }
    nextStep(): void {
        this.etape++;
        // @ts-ignore
        window.scroll(0, 0);
    }
    uploadCv(f: any): void {
        this.cvUpload = '2';
        const file = f.target.files.item(0);
        const reader = new FileReader();
        if (file.type === 'application/pdf') {
            if (file.size <= 3000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.cv = reader.result;
                    this.errorImport = false;
                    this.save(2);
                    this.modification = true;
                };
            } else {
                this.cvUpload = '';
                alert('Le document importé est supérieur à 3Mo. veuillez réduire sa taille');
                this.errorImport = true;
            }
        } else {
            this.cvUpload = '';
            alert(
                "Le document que vous venez d'essayer d'importer n'est pas au format PDF. Vous pouvez uniquement importer un CV au format PDF."
            );
            this.errorImport = true;
        }
    }
    getCommande(): void {
        this.httpClient
            .get<any>(this.globalService.url + '/commande/' + this.idCommande, {
                headers: this.globalService.hideProgressBarHttp(),
            })
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.sujet = response.commande.sujet;
                        this.emailDiffusion = response.commande.email;
                        this.lettreMotivation = response.commande.lettreMotivation;
                        this.ancienCv = response.commande.lienCv;
                        this.getAccount(response.commande.idCandidat);
                        this.idCandidat = response.commande.idCandidat;
                        this.commande = response.commande;
                        if (response.commande.sujet !== '') {
                            this.sujet = response.commande.sujet;
                        } else {
                            this.sujet = 'Candidature spontanée';
                        }
                        if (response.commande.email !== '') {
                            this.emailDiffusion = response.commande.email;
                        }
                        if (response.commande.lettreMotivation !== '') {
                            this.lettreMotivation = response.commande.lettreMotivation;

                            this.lettreMotivationOriginal = response.commande.lettreMotivation;
                        }
                        if (response.commande.lettreMotivation !== '') {
                            this.lettreMotivation = response.commande.lettreMotivation;
                        }
                        if (response.commande.lienCv !== '') {
                            this.ancienCv = response.commande.lienCv;
                        }
                        if (this.etape > 3) {
                            this.getCandidat(this.idCandidat);
                        }
                    }
                },
                () => {}
            );
    }
    save(choix: number): void {
        let cv = this.cv;
        let m = 2;
        if (choix === 1) {
            m = this.modifier;
            if (this.cv !== this.ancienCv) {
                cv = this.ancienCv;
            }
        }
        this.httpClient
            .patch<any>(
                this.globalService.url + '/commande/lettre-motivation',
                {
                    idCommande: this.idCommande,
                    sujet: this.sujet,
                    email: this.emailDiffusion,
                    lettreMotivation: this.lettreMotivation,
                    cv: cv,
                    modification: m,
                },
                {}
            )
            .subscribe(
                (response) => {
                    this.cvUpload = '';
                    // this.getAccount(this.idCandidat);
                    // this.getCommande();
                    this.ancienCv = response.cv;
                    this.cv = response.cv;
                },
                () => {
                    this.cvUpload = '';
                }
            );
    }
    precedent(): void {
        this.etape--;
        window.scroll(0, 0);
    }
    renouvellerErreur(): void {
        this.errorValidation = {
            acceptation: false,
            cv: false,
            paymentMethod: false,
            code: false,
            objet: false,
            mail: false,
        };
    }
    suivant(): void {
        this.renouvellerErreur();
        window.scroll(0, 0);
        this.error = false;
        if (
            this.sujet !== '' &&
            this.emailDiffusion !== '' &&
            this.lettreMotivation !== '' &&
            this.lettreMotivation.length >= 100 &&
            this.validateService.validateEmail(this.emailDiffusion) &&
            !this.errorImport &&
            (this.ancienCv !== '' || this.cv !== '')
        ) {
            this.save(1);
            this.etape++;
            let dateActuel = Math.round(new Date().getTime());

            let d = new Date();
            dateActuel = dateActuel - d.getSeconds() * 1000;
            dateActuel = dateActuel - d.getMinutes() * 60 * 1000;
            dateActuel = dateActuel - d.getHours() * 60 * 60 * 1000;
            this.tableauDate.push({
                date: 0,
                texte: 'Dès que possible',
            });
            for (let i = 1; i <= 7; i++) {
                let text = 'Dans ' + i + ' jours';
                if (i === 1) {
                    text = 'Demain';
                }
                dateActuel = dateActuel + 86400000;
                this.tableauDate.push({
                    date: dateActuel,
                    texte: text,
                });
            }
        } else if (this.errorImport) {
            this.error = true;
            this.erreurTexte = "Le CV que vous avez tenté d'importer n'est pas conforme";
        } else if (this.lettreMotivation.length <= 100) {
            this.error = true;
            this.erreurTexte = 'Votre lettre de motivation doit contenir au moins 100 caractères';
        } else {
            if (this.sujet === '') {
                this.errorValidation.objet = true;
            }
            if (this.emailDiffusion === '') {
                this.errorValidation.mail = true;
            }
            if (this.cv === '') {
                this.errorValidation.cv = true;
            }
            this.error = true;
            this.erreurTexte = 'Des informations sont manquantes';
        }
    }
    suivantDate(): void {
        this.error = false;
        this.httpClient
            .patch<any>(
                this.globalService.url + '/commande/date-commande',
                {
                    idCommande: this.idCommande,
                    date: this.date,
                    heure: this.heure,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.etape++;
                        this.getCandidat(this.idCandidat);
                    }
                },
                () => {}
            );
    }
    suivantValidation(): void {
        if (this.paymentMethod === '' && this.totalTtc > 0) {
            this.errorValidation.paymentMethod = true;
        }
        if (!this.acceptation) {
            this.errorValidation.acceptation = true;
        } else {
            this.httpClient
                .patch<any>(
                    this.globalService.url + '/commande/sauvegarder-retrait',
                    {
                        idCommande: this.idCommande,
                        retrait: this.retraitRecruteurs,
                    },
                    {}
                )
                .subscribe(
                    () => {},
                    () => {}
                );
            if (this.totalTtc <= 0) {
                this.router.navigate(['/espace-candidat/commande-accepter/' + this.idCommande]);
            } else {
                if (this.paymentMethod === 'cb') {
                    // @ts-ignore
                    // document.getElementById('cb-button').click();

                    this.httpClient.post<any>(this.globalService.url + '/create-checkout-session/' + this.idCommande, {}).subscribe(
                        (response) => {
                            this.paymentService.redirectToStripeCheckout(response.sessionId);
                        },
                        (error) => {
                            this.toastrService.error('Erreur Stripe', '', { opacity: 1 });
                        }
                    );
                } else {
                    // @ts-ignore
                    // document.getElementById("paypal-button").click();
                    this.refreshPaypal = true;
                    this.paypalModal.show();
                }
            }
        }
    }
    calculPrix(): void {
        this.retraitDesabo = 0;
        let alreadyDone = [];
        this.retraitRecruteurs = [];
        for (let d of this.candidat.desabonner) {
            let desabo = false;
            for (let r of this.commande.recruteurs) {
                if (d.id === r.idRecruteur) {
                    desabo = true;
                    this.retraitRecruteurs.push({ idRecruteur: r.idRecruteur, email: r.email });
                    alreadyDone.push(r.idRecruteur);
                    break;
                }
            }
            if (desabo) {
                this.retraitDesabo = this.retraitDesabo + 1;
            }
        }

        for (let d of this.retrait) {
            const split = d.split('@');
            if (split[0] !== '') {
                for (let r of this.commande.recruteurs) {
                    if (d == r.email) {
                        if (!alreadyDone.includes(r.idRecruteur)) {
                            this.retraitDesabo = this.retraitDesabo + 1;
                            this.retraitRecruteurs.push({ idRecruteur: r.idRecruteur, email: r.email });
                            alreadyDone.push(r.idRecruteur);
                            break;
                        }
                    }
                }
            } else {
                for (let r of this.commande.recruteurs) {
                    const domaine = r.email.split('@')[1];
                    if (split[1] == domaine) {
                        if (!alreadyDone.includes(r.idRecruteur)) {
                            this.retraitDesabo = this.retraitDesabo + 1;
                            this.retraitRecruteurs.push({ idRecruteur: r.idRecruteur, email: r.email });
                            alreadyDone.push(r.idRecruteur);
                        }
                    }
                }
            }
        }
        this.sousTotalTtc = this.commande.prix.ttc * (this.commande.nombreCourriel - this.retraitDesabo);
        this.sousTotalTtc = parseFloat(this.sousTotalTtc.toFixed(2));
        this.sousTotalHt = parseFloat((this.sousTotalTtc / 1.2).toFixed(2));
        this.totalTtc =
            this.commande.prix.ttc * (this.commande.nombreCourriel - this.retraitDesabo) - this.commande.reduction.montantTtc - this.credit;
        this.totalTtc = parseFloat(this.totalTtc.toFixed(2));
        this.totalHt = parseFloat((this.totalTtc / 1.2).toFixed(2));
        this.tva = parseFloat((this.totalTtc - this.totalHt).toFixed(2));

        if (this.totalTtc > 0 && this.totalTtc <= 1) {
            this.totalTtc = 1;
            this.tva = 0.17;
        }
        if (this.credit === 0) {
            this.creditUtiliser = 0;
        } else {
            const ttc = this.commande.prix.ttc * (this.commande.nombreCourriel - this.retraitDesabo) - this.commande.reduction.montantTtc;
            if (ttc <= 0) {
                this.creditUtiliser = 0;
            } else if (ttc <= this.credit) {
                this.creditUtiliser = this.credit + this.totalTtc;
            } else {
                this.creditUtiliser = this.credit;
            }
        }
        if (this.creditUtiliser == 0) {
            this.creditRestant = parseFloat(
                (
                    this.credit +
                    parseFloat(this.commande.reduction.montantTtc) -
                    this.commande.prix.ttc * (this.commande.nombreCourriel - this.retraitDesabo)
                ).toFixed(2)
            );
        } else {
            this.creditRestant = parseFloat((this.credit - this.creditUtiliser).toFixed(2));
        }
        if (this.creditRestant < 0) {
            this.creditRestant = 0;
        }
    }
    testerCode(): void {
        this.errorValidation.code = false;
        this.httpClient.get<any>(this.globalService.url + '/reduction/' + this.nomCode + '/' + this.idCommande).subscribe(
            (response) => {
                if (response.status) {
                    const typeReduction = response.reduction.type;
                    const montant = response.reduction.montant;
                    let montantTtc = 0;
                    if (typeReduction === 'euro') {
                        montantTtc = montant;
                    } else if (typeReduction === 'pourcentage') {
                        montantTtc =
                            (this.commande.nombreCourriel * parseFloat((this.commande.prix.ht * 1.2).toFixed(2)) - this.credit) *
                            (1 - (100 - montant) / 100);
                    } else if (typeReduction === 'mail') {
                        montantTtc = montant * parseFloat((this.commande.prix.ht * 1.2).toFixed(2));
                    }
                    this.sauvegarderReduction(this.nomCode, montantTtc);
                } else {
                    this.errorValidation.code = true;
                }
            },
            () => {}
        );
    }
    removeCode(): void {
        const nouveauCredit = parseFloat((this.credit + (0 - this.sousTotalTtc)).toFixed(2));
        if (nouveauCredit > 0) {
            this.creditRestant = nouveauCredit;
        } else {
            this.creditRestant = 0;
        }
        this.nomCode = '';
        this.sauvegarderReduction('', 0);
    }
    sauvegarderReduction(nomCode: string, montantTtc: number): void {
        this.httpClient
            .patch<any>(
                this.globalService.url + '/commande/modifier-reduction',
                {
                    idCommande: this.idCommande,
                    nomCode,
                    montantTtc,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.getCommande();
                    }
                },
                () => {}
            );
    }

    addPaypalScript(): Promise<any> {
        this.addScript = true;
        return new Promise((resolve, reject) => {
            const scripttagElement = document.createElement('script');
            scripttagElement.src = 'https://www.paypalobjects.com/api/checkout.min.js';
            scripttagElement.onload = resolve;
            document.body.appendChild(scripttagElement);
        });
    }
}
