import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from '../../mdb/free/modals';
import { faFileContract, faEye } from '@fortawesome/free-solid-svg-icons';
import { GlobalService } from '../../../services/global.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-modal-offer',
    templateUrl: './offer-form.component.html',
    styleUrls: ['./offer-form.component.css', './../../../../assets/css/administration/space.min.css'],
})
export class OfferFormComponent implements OnInit {
    @ViewChild('offerModal', { static: true }) offerModal!: ModalDirective;

    faEye = faEye;
    offer: any = {};
    loading = false;

    url = '';
    urlPostulation = '';
    constructor(private globalService: GlobalService, private httpClient: HttpClient) {}
    ngOnInit(): void {
        this.url = this.globalService.url;
    }
    openForm(id: any): void {
        this.offerModal.show();
        this.loadOffer(id);
    }
    loadOffer(id: any): void {
        this.loading = true;
        this.httpClient.get<any>(this.globalService.url + '/offres/' + id, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.offer = response.offre;
                    if (this.offer && this.offer.origineOffre) {
                        if (this.offer.origineOffre.origine === '1') {
                            this.urlPostulation = this.offer.origineOffre.urlOrigine;
                        } else {
                            this.urlPostulation = this.offer.origineOffre.partenaires[0].url;
                        }
                    } else {
                        this.urlPostulation = '';
                    }

                    this.loading = false;
                }
            },
            () => {}
        );
    }
}
