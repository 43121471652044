<div class="header" style="background-color: #f8f7f2; height: 125px">
    <div class="logo_holder">
        <a routerLink="/">
            <img class="logo_for_dark_bg" src="./assets/img/logo-hd.png" alt="Gravity" style="max-width: 200px" />
        </a>
    </div>
</div>
<div class="row light_bg full-height align-items-center d-flex contact-1" style="width: 100%; margin: 0">
    <div class="container">
        <div class="vc_column-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="oi_vc_heading oi_border_position_center">
                        <h1 class="oi_icon_titile" style="text-align: center">ACC&Egrave;S INTERMEDIAIRE</h1>
                        <div class="mention-text row">
                            <div class="woocommerce offset-md-3 col-md-6">
                                <p>
                                    <label for="email">Identifiant</label>
                                    <input type="text" name="email" id="email" [(ngModel)]="login.identifiant" />
                                </p>
                                <p>
                                    <label for="password">Mot de passe</label>
                                    <input type="password" name="password" id="password" [(ngModel)]="login.password" />
                                </p>
                                <p *ngIf="error !== ''" class="error">
                                    {{ error }}
                                </p>
                                <p class="form-row">
                                    <button
                                        class="btn"
                                        style="background: rgb(228, 167, 0); color: white; margin: auto"
                                        (click)="connexion()"
                                    >
                                        Connexion
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
