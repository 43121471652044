<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Modifier courriels : {{ mail.nom }}</h3>
            <p>Attention : Toutes valeurs entre [] sont des informations dynamiques générées par le système. Il ne faut pas les retirer.</p>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="offset-md-3 col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <label>Objet du courriel</label>
                            <input type="text" class="form-control" [(ngModel)]="mail.objet" placeholder="Sujet du courriel..." />
                        </div>
                        <div class="col-md-12" style="margin-top: 15px">
                            <label>Titre du texte</label>
                            <input type="text" class="form-control" [(ngModel)]="mail.titre" placeholder="Titre du message..." />
                        </div>
                        <div class="col-md-12" style="margin-top: 15px">
                            <label>Texte du message</label>
                            <textarea
                                class="form-control"
                                [(ngModel)]="mail.texte"
                                rows="6"
                                style="height: auto"
                                placeholder="Texte du message..."
                            ></textarea>
                        </div>
                        <div class="col-md-12" style="margin-top: 15px">
                            <button class="btn btn-success" (click)="modifier()" style="width: 100%">Modifier</button>
                        </div>
                    </div>
                </div>
                <div class="offset-md-3 col-md-6" style="margin-top: 20px">
                    Liste des variables dynamiques par courriel : <br />
                    Pour tous : (lien_site)<br />
                    Inscription - Validation de votre e-mail : (prenom), (nom), (lien), (code)<br />
                    Inscription - Image non conforme : (prenom), (nom), (lien), (document)<br />
                    Compte - Récupération du mot de passe : (prenom), (nom), (lien)<br />
                    Compte - Confirmation du mot de passe : (prenom), (nom), (type)<br />
                    Compte – CA - Compte accessible : (prenom), (nom)<br />
                    Compte – RE - Compte accessible : (prenom), (nom)<br />
                    Compte - Compte bloqué : (prenom), (nom)<br />
                    Compte - Code de réduction personnel : (prenom), (nom), (nom_code), (montant), (type_reduction), (date_debut),
                    (date_fin), (minimum_panier)<br />
                    Relance - Envoi d'un lien de modification des informations sans connexion : (prenom), (nom), (lien)<br />
                    Commande - Demande de confirmation de commande : (prenom), (nom), (numero_commande)<br />
                    Commande - Refus d'exécution de la commande (temporaire) : (prenom), (nom), (motif)<br />
                    Commande - Refus d'exécution de la commande (définitif) : (prenom), (nom), (motif)<br />
                    Commande - Envoi de facture : (prenom), (nom), (numero_commande)<br />
                    Commande - Autorisation SendinBlue : (prenom), (nom)<br />
                    Désabonnement - Retrait candidat : (prenom), (nom), (prenom_candidat), (nom_candidat), (email_candidat)<br />
                    Prestation - Début de l'envoi de votre CV : (prenom), (nom)<br />
                    Prestation - Fin de l'envoi de votre CV : (prenom), (nom)<br />
                    Relance - Inscription non finalisée : (prenom), (nom), (lien)<br />
                    Relance - Validation modification information sans connexion : (prenom), (nom)<br />
                    Relance - Compte inactif depuis 3 mois : (prenom), (nom), (lien)<br />
                    Relance - Compte inactif depuis 2 ans - suppression : (prenom), (nom), (lien)<br />
                    Relance - CV non mis à jour depuis 3 mois : (prenom), (nom), (lien)<br />
                    Désinscription - Un utilisateur se désinscrit : (prenom), (nom)<br />
                    Code - Désabonnement : (prenom), (nom), (code)<br />
                    Commande - Non terminée : (prenom), (nom), (lien_commande)<br />
                    Inscription - Non terminée : (prenom), (nom), (type)<br />
                </div>
            </div>
        </div>
    </div>
</div>
