import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from '../../mdb/free/modals';
import { ToastService } from '../../mdb/pro/alerts';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-modal-register',
    templateUrl: './register-modal.component.html',
    styleUrls: ['./register-modal.component.css', './../../../../assets/css/administration/space.min.css'],
})
export class RegisterModalComponent implements OnInit {
    @ViewChild('registerModal', { static: true }) registerModal!: ModalDirective;
    @ViewChild('verificationCode', { static: true }) verificationCode!: ModalDirective;

    @Input() redirect = '';
    @Output() successRegister = new EventEmitter();

    error = '';
    loading = false;
    url = '';
    code = '';
    type1 = 'password';
    faFacebook = faFacebook;
    faGoogle = faGoogle;
    faLinkedin = faLinkedin;
    faArrowCircleRight = faRedo;

    email = '';

    candidat: any = {};

    constructor(
        private toastrService: ToastService,
        private httpClient: HttpClient,
        private authService: AuthService,
        private router: Router,
        private userService: UserService,
        private globalService: GlobalService
    ) {}
    ngOnInit(): void {
        this.url = this.globalService.url;
    }
    openForm(): void {
        this.registerModal.show();
    }
    closeForm(): void {
        this.registerModal.hide();
    }
    createAccount(): void {
        window.scroll(0, 0);
        this.httpClient
            .put<any>(this.globalService.url + '/candidat', {
                estFrancais: true,
                email: this.email,
                derniereConnexionUtiliser: 'Manuel',
            })
            .subscribe(
                async (response: any) => {
                    if (!response.status) {
                        this.toastrService.error("L'email est déjà dans notre base", '', { opacity: 1 });
                        this.successRegister.emit(false);
                        this.email = '';
                    } else {
                        await this.authService.storeUserData(response, 'candidat');
                        await this.getAccount();

                        if (this.redirect !== '') {
                            this.router.navigate([this.redirect]);
                        }
                    }
                    this.loading = false;
                },
                () => {
                    this.toastrService.error("L'email est déjà dans notre base", '', { opacity: 1 });
                    this.successRegister.emit(false);
                    this.email = '';
                    this.loading = false;
                }
            );
    }
    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.candidat = response.candidat;
                    this.registerModal.hide();
                    this.verificationCode.show();
                    this.sendMailVerificationEmail();
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    register(): void {
        this.loading = true;
        this.error = '';
        if (this.email === '') {
            this.error = 'Veuillez saisir une adresse email';
            this.loading = false;
        } else {
            this.createAccount();
        }
    }
    // this called every time when user changed the code
    onCodeChanged(code: any): void {}

    // this called only if user entered full code
    onCodeCompleted(code: any): void {
        this.code = code;
        this.verifierCode();
    }
    sendMailVerificationEmail(): void {
        this.httpClient
            .put(this.globalService.url + '/mail/verifier-email', {
                id: this.candidat._id,
                email: this.candidat.email,
                type: 'Candidat',
            })
            .subscribe(
                (response) => {
                    this.toastrService.info('Code envoyé', '', { opacity: 1 });
                },
                () => {}
            );
    }
    verifierCode(): void {
        this.httpClient
            .patch(
                this.globalService.url +
                    '/candidat/mail/' +
                    this.candidat.referenceCandidat.replace('CAND', this.candidat._id) +
                    '/' +
                    this.code,
                {}
            )
            .subscribe(
                (response) => {
                    this.toastrService.info('Email vérifié', '', {
                        opacity: 1,
                    });
                    this.verificationCode.hide();
                    this.successRegister.emit(true);
                },
                () => {
                    this.toastrService.error('Le code saisi est incorrect', '', { opacity: 1 });
                }
            );
    }
}
