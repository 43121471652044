import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-liste-compte-recruteur',
    templateUrl: './liste-compte-recruteur.component.html',
    styleUrls: [
        './liste-compte-recruteur.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/jquery.datatables.min.css',
    ],
})
export class ListeCompteRecruteurComponent implements OnInit {
    type: string | null = '';
    page = 0;
    nbParPage = 10;
    nombreTotal = 0;
    nombrePage = 0;
    pagination: Array<number> = [];
    premierPoint = false;
    deuxiemePoint = false;
    tri = 'dateCreation';
    sens = 'desc';
    sensClass = 'sorting_desc';
    recherche = '';
    recruteurs: any = [];
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        if (this.route.snapshot.params.type !== undefined && this.route.snapshot.params.type !== null) {
            this.type = this.route.snapshot.params.type;
            this.getRecruteur();
        }
    }
    getRecruteur(): void {
        this.httpClient
            .post<any>(
                this.globalService.url + '/recruteur/all/' + this.page + '/' + this.nbParPage,
                {
                    recherche: this.recherche,
                    type: this.type,
                    tri: this.tri,
                    sens: this.sens,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.recruteurs = [];
                        this.nombreTotal = response.nb;
                        const nombrePage = Math.ceil(this.nombreTotal / this.nbParPage) - 1;
                        this.nombrePage = nombrePage;
                        this.pagination = [];
                        if (this.nombrePage > 10) {
                            if (this.page < 3) {
                                this.premierPoint = true;
                            } else if (this.page >= this.nombrePage - 2) {
                                this.deuxiemePoint = true;
                            } else if (this.page >= 3 && this.page < this.nombrePage - 2) {
                                this.premierPoint = true;
                                this.deuxiemePoint = true;
                            }
                        }
                        if (this.premierPoint && this.deuxiemePoint) {
                            this.pagination.push(0);
                            if (this.nombrePage >= 2) {
                                this.pagination.push(1);
                            }
                            if (this.nombrePage >= 3 && this.page !== 3) {
                                this.pagination.push(2);
                            }
                            if (this.nombrePage >= this.page) {
                                this.pagination.push(-1);
                                this.pagination.push(this.page - 1);
                            }
                            if (this.nombrePage >= this.page + 1) {
                                this.pagination.push(this.page);
                            }
                            if (this.nombrePage >= this.page + 2) {
                                this.pagination.push(this.page + 1);
                            }
                            if (this.nombrePage - 2 >= this.page) {
                                this.pagination.push(-1);
                                if (this.page + 1 !== this.nombrePage - 2) {
                                    this.pagination.push(this.nombrePage - 2);
                                }
                            }
                            if (this.nombrePage - 1 >= this.page) {
                                this.pagination.push(this.nombrePage - 1);
                                this.pagination.push(this.nombrePage);
                            }
                        } else if (!this.premierPoint && !this.deuxiemePoint) {
                            for (let p = 0; p <= this.nombrePage; p++) {
                                this.pagination.push(p);
                            }
                        } else {
                            for (let p = 0; p <= 2; p++) {
                                this.pagination.push(p);
                            }
                            if (this.premierPoint || this.deuxiemePoint) {
                                this.pagination.push(-1);
                            }
                            for (let p = this.nombrePage - 2; p <= this.nombrePage; p++) {
                                this.pagination.push(p);
                            }
                        }
                        for (let r of response.recruteurs) {
                            r.desabonnement = [];
                            this.recruteurs.push(r);
                        }
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    changeNombre(): void {
        this.page = 0;
        this.getRecruteur();
    }
    changePage(page: number): void {
        this.page = page;
        this.getRecruteur();
    }
    bloquer(id: string): void {
        if (confirm('Bloquer ce compte ?')) {
            let newRecruteur = [];
            for (let r of this.recruteurs) {
                if (r._id === id) {
                    r.status = false;
                    this.httpClient.patch<any>(this.globalService.url + '/recruteur/status', { id: id, status: false }, {}).subscribe(
                        (response) => {},
                        () => {}
                    );
                } else {
                    newRecruteur.push(r);
                }
            }
            this.recruteurs = newRecruteur;
        }
    }
    changeSens(choix: string): void {
        if (this.tri !== choix) {
            this.tri = choix;
            this.sens = 'desc';
            this.sensClass = 'sorting_desc';
        } else {
            if (this.sens === 'desc') {
                this.sens = 'asc';
                this.sensClass = 'sorting_asc';
            } else {
                this.sens = 'desc';
                this.sensClass = 'sorting_desc';
            }
        }
        this.getRecruteur();
    }
    debloquer(id: string): void {
        if (confirm('Débloquer ce compte ?')) {
            let newRecruteur = [];
            for (let r of this.recruteurs) {
                if (r._id === id) {
                    r.status = true;
                    this.httpClient.patch<any>(this.globalService.url + '/recruteur/status', { id: id, status: true }, {}).subscribe(
                        (response) => {},
                        () => {}
                    );
                } else {
                    newRecruteur.push(r);
                }
            }
            this.recruteurs = newRecruteur;
        }
    }
    download(): void {
        this.httpClient
            .post<any>(
                this.globalService.url + '/recruteur/exporter',
                {
                    recherche: this.recherche,
                    type: this.type,
                    tri: this.tri,
                    sens: this.sens,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        window.open(this.globalService.url + '/documents/excel/' + response.filename + '.xlsx');
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    envoyer(id: string, email: string): void {
        this.httpClient.put(this.globalService.url + '/mail/verifier-email', { id: id, email: email, type: 'Recruteur' }, {}).subscribe(
            (response) => {
                alert('Envoyé');
            },
            () => {}
        );
    }
    supprimer(id: string): void {
        if (confirm('Voulez-vous vraiment supprimer ce compte ?')) {
            this.httpClient.delete(this.globalService.url + '/recruteur/' + id).subscribe(
                (response) => {
                    this.getRecruteur();
                },
                () => {}
            );
        }
    }
}
