import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: [
        './dashboard.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class DashboardComponent implements OnInit {
    dateDebut = '';
    dateFin = '';
    connexion = 0;
    candidats = 0;
    candidatsNonFinaliser = 0;
    recruteurs = 0;
    recruteursNonFinaliser = 0;
    commandes = 0;
    ca = 0;
    panierMoyen = 0;
    envoyer = 0;
    uniqueCandidat = 0;
    nombreConnexionCandidat = 0;
    uniqueRecruteur = 0;
    nombreConnexionRecruteur = 0;
    git = '';
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.getGit();
        this.genererData();
        setInterval(() => {
            this.genererData();
        }, 60000);
    }
    genererData(): void {
        this.getCommandes();
        this.getCandidats();
        this.getRecruteurs();
    }
    getGit(): void {
        this.httpClient.get<any>(this.globalService.url + '/git').subscribe(
            (response) => {
                if (response.status) {
                    this.git = response.git;
                }
            },
            () => {}
        );
    }
    getCandidats(): void {
        this.httpClient
            .post<any>(this.globalService.url + '/candidat/statistiques', { dateDebut: this.dateDebut, dateFin: this.dateFin }, {})
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.candidats = response.candidats;
                        this.candidatsNonFinaliser = response.candidatsNonFinaliser;
                        this.uniqueCandidat = response.unique;
                        this.nombreConnexionCandidat = response.nombreConnexion;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    getRecruteurs(): void {
        this.httpClient
            .post<any>(this.globalService.url + '/recruteur/statistiques', { dateDebut: this.dateDebut, dateFin: this.dateFin }, {})
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.recruteurs = response.recruteurs;
                        this.recruteursNonFinaliser = response.recruteursNonFinaliser;
                        this.uniqueRecruteur = response.unique;
                        this.nombreConnexionRecruteur = response.nombreConnexion;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    getCommandes(): void {
        this.httpClient
            .post<any>(this.globalService.url + '/commande/statistiques', { dateDebut: this.dateDebut, dateFin: this.dateFin }, {})
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.commandes = response.commandes;
                        this.ca = response.ca;
                        this.panierMoyen = response.panierMoyen;
                        this.envoyer = response.envoyer;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
}
