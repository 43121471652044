<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="margin-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Gestion des codes de réduction</h3>
        </div>
        <div>
            <p>Pour créer un code de réduction illimité en nombre d'utilisation, utiliser la valeur -1.</p>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table class="display table dataTable">
                                    <thead>
                                        <tr>
                                            <!--th class="sorting_asc">Nb utilisation</th-->
                                            <th class="sansPadding">Nb utilisation</th>
                                            <th class="sansPadding">Nom</th>
                                            <th class="sansPadding">Début</th>
                                            <th class="sansPadding">Fin</th>
                                            <th class="sansPadding">Pour</th>
                                            <th class="sansPadding">Max utilisation</th>
                                            <th class="sansPadding">Montant (TTC)</th>
                                            <th class="sansPadding">Min panier</th>
                                            <th class="sansPadding">Min HT/TTC</th>
                                            <th class="sansPadding">Type réduc</th>
                                            <th class="sansPadding">Première commande</th>
                                            <th class="sansPadding">Usage unique</th>
                                            <th class="sansPadding">Supprimer</th>
                                            <th class="sansPadding">Dupliquer</th>
                                            <th class="sansPadding">Envoyer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="sansPadding" colspan="2">
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    [(ngModel)]="nouveauCode.nom"
                                                    [ngStyle]="{
                                                        border: nomNonSaisie ? '1px solid red' : ''
                                                    }"
                                                />
                                            </td>
                                            <td class="sansPadding">
                                                <input
                                                    class="form-control"
                                                    type="datetime-local"
                                                    [(ngModel)]="nouveauCode.dateDeDebut"
                                                    [ngStyle]="{
                                                        border: dateDebutNonSaisie ? '1px solid red' : ''
                                                    }"
                                                />
                                            </td>
                                            <td class="sansPadding">
                                                <input class="form-control" type="datetime-local" [(ngModel)]="nouveauCode.dateDeFin" />
                                            </td>
                                            <td class="sansPadding">
                                                <input class="form-control" type="text" [(ngModel)]="recherche.pourCandidat" />
                                                <div
                                                    [ngStyle]="{
                                                        display: this.pourCandidatCheck && this.candidats.length > 0 ? 'block' : 'none'
                                                    }"
                                                    style="
                                                        position: fixed;
                                                        z-index: 11111;
                                                        background-color: white;
                                                        margin-bottom: 15px;
                                                        top: 165px;
                                                        display: block;
                                                    "
                                                >
                                                    <label>Candidat :</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div>
                                                                <div class="form-group" style="margin: 0">
                                                                    <div
                                                                        class="addr"
                                                                        *ngFor="let c of candidats"
                                                                        (click)="chooseCandidats(c._id, c.prenom + ' ' + c.nom)"
                                                                    >
                                                                        {{ c.prenom }}
                                                                        {{ c.nom }}
                                                                        /
                                                                        {{ c.dateNaissance }}
                                                                        /
                                                                        {{ c.email }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="sansPadding">
                                                <input
                                                    class="form-control"
                                                    type="number"
                                                    [(ngModel)]="nouveauCode.nombreUtilisationMaximum"
                                                />
                                            </td>
                                            <td class="sansPadding">
                                                <input
                                                    class="form-control"
                                                    type="number"
                                                    [(ngModel)]="nouveauCode.montant"
                                                    [ngStyle]="{
                                                        border: montantNonSaisie ? '1px solid red' : ''
                                                    }"
                                                />
                                            </td>
                                            <td class="sansPadding">
                                                <input
                                                    class="form-control"
                                                    type="number"
                                                    [(ngModel)]="nouveauCode.montantMinimumDuPanier"
                                                />
                                            </td>
                                            <td class="sansPadding">
                                                <select class="form-control" [(ngModel)]="nouveauCode.montantMinimumHtOuTtc">
                                                    <option value="ttc">TTC</option>
                                                    <option value="ht">HT</option>
                                                </select>
                                            </td>
                                            <td class="sansPadding">
                                                <select
                                                    class="form-control"
                                                    [(ngModel)]="nouveauCode.typeReduction"
                                                    [ngStyle]="{
                                                        border: typeReductionNonSaisie ? '1px solid red' : ''
                                                    }"
                                                >
                                                    <option value="euro">€</option>
                                                    <option value="pourcentage">%</option>
                                                    <option value="mail">mail</option>
                                                </select>
                                            </td>
                                            <td class="sansPadding">
                                                <select class="form-control" [(ngModel)]="nouveauCode.pourUnePremiereCommande">
                                                    <option value="true">Oui</option>
                                                    <option value="false">Non</option>
                                                </select>
                                            </td>
                                            <td class="sansPadding">
                                                <select class="form-control" [(ngModel)]="nouveauCode.usageUnique">
                                                    <option value="true">Oui</option>
                                                    <option value="false">Non</option>
                                                </select>
                                            </td>
                                            <td class="sansPadding" colspan="3">
                                                <button class="btn btn-info" (click)="ajouter()">Ajouter</button>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let c of codeReductions">
                                            <td>
                                                {{ c.commandes.length }}
                                            </td>
                                            <td>
                                                {{ c.nom }}
                                            </td>
                                            <td>
                                                <div *ngIf="c.dateDeDebut === ''">Sans Début</div>
                                                <div *ngIf="c.dateDeDebut !== ''">
                                                    {{ c.dateDeDebut | date: 'dd-MM-yyyy HH:mm' }}
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="c.dateDeFin === ''">Sans Fin</div>
                                                <div *ngIf="c.dateDeFin !== ''">
                                                    {{ c.dateDeFin | date: 'dd-MM-yyyy HH:mm' }}
                                                </div>
                                            </td>
                                            <td>
                                                {{ c.pourLeCandidat }}
                                            </td>
                                            <td>
                                                {{ c.nombreUtilisationMaximum }}
                                            </td>
                                            <td>
                                                {{ c.montant }}
                                            </td>
                                            <td>
                                                {{ c.montantMinimumDuPanier }}
                                            </td>
                                            <td>
                                                <div *ngIf="c.montantMinimumHtOuTtc === 'ht'">HT</div>
                                                <div *ngIf="c.montantMinimumHtOuTtc !== 'ht'">TTC</div>
                                            </td>
                                            <td>
                                                <div *ngIf="c.typeReduction === 'euro'">€</div>
                                                <div *ngIf="c.typeReduction === 'pourcentage'">%</div>
                                                <div *ngIf="c.typeReduction === 'mail'">mail</div>
                                            </td>
                                            <td>
                                                <div *ngIf="c.pourUnePremiereCommande">Oui</div>
                                                <div *ngIf="!c.pourUnePremiereCommande">Non</div>
                                            </td>
                                            <td>
                                                <div *ngIf="c.usageUnique">Oui</div>
                                                <div *ngIf="!c.usageUnique">Non</div>
                                            </td>
                                            <td>
                                                <button class="btn btn-danger" (click)="supprimer(c._id)">
                                                    <fa-icon [icon]="faTrash"></fa-icon>
                                                </button>
                                            </td>
                                            <td>
                                                <button class="btn btn-info" (click)="dupliquer(c._id)">
                                                    <fa-icon [icon]="faCopy"></fa-icon>
                                                </button>
                                            </td>
                                            <td>
                                                <button *ngIf='c.pourLeCandidat' class="btn btn-success" (click)="envoyerMail(c._id)">
                                                    <fa-icon [icon]="faEnvelope"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
