import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-faqs',
    templateUrl: './faqs.component.html',
    styleUrls: [
        './faqs.component.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/jquery.datatables.min.css',
    ],
})
export class FaqsComponent implements OnInit {
    faqs: any = [];
    nouveau = {
        question: '',
        reponse: '',
        ordre: 0,
    };
    tableau: any[] = [];
    erreurAjout = false;
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.getFaqs();
    }
    ajouter(): void {
        this.erreurAjout = false;
        if (this.nouveau.question === '' || this.nouveau.reponse === '') {
            this.erreurAjout = true;
        } else {
            this.httpClient
                .put(
                    this.globalService.url + '/faq',
                    {
                        question: this.nouveau.question,
                        reponse: this.nouveau.reponse,
                        ordre: this.faqs.length + 1,
                    },
                    {}
                )
                .subscribe(
                    (response: any) => {
                        if (response.status) {
                            this.nouveau = {
                                question: '',
                                reponse: '',
                                ordre: 0,
                            };
                            this.getFaqs();
                            this.tableau.push(this.tableau.length + 1);
                        } else {
                            alert('Erreur lors de la modification');
                        }
                    },
                    () => {}
                );
        }
    }
    modifier(id: string): void {
        for (let f of this.faqs) {
            if (f._id === id) {
                this.httpClient.post(this.globalService.url + '/faq/' + id, { question: f.question, reponse: f.reponse }, {}).subscribe(
                    (response: any) => {
                        if (response.status) {
                            this.getFaqs();
                        } else {
                            alert('Erreur lors de la modification');
                        }
                    },
                    () => {}
                );
            }
        }
    }
    estOrdre(id: string, ordre: number): boolean {
        for (let f of this.faqs) {
            if (f._id === id) {
                if (f.ordre === ordre) {
                    return true;
                }
                break;
            }
        }
        return false;
    }
    modifierOrdre(id: string, event: any): void {
        let ordre = event.target.value;
        let ordreAncien = -1;
        for (let f of this.faqs) {
            if (f._id === id) {
                ordreAncien = f.ordre;
                break;
            }
        }
        let ordres = [];
        for (let f of this.faqs) {
            if (f._id === id) {
                console.log(f.ordre + ' - ' + ordre);
                f.ordre = ordre;
            } else if (f.ordre >= ordre && ordreAncien > f.ordre) {
                f.ordre++;
            } else if (f.ordre <= ordre && ordreAncien < f.ordre) {
                f.ordre--;
            }
            ordres.push({
                id: f._id,
                ordre: f.ordre,
            });
        }
        this.httpClient.patch<any>(this.globalService.url + '/faq/update-ordre', { ordres: ordres }, {}).subscribe(
            (response) => {},
            () => {}
        );
    }
    supprimer(id: string): void {
        this.httpClient.delete(this.globalService.url + '/faq/' + id).subscribe(
            (response: any) => {
                if (response.status) {
                    this.getFaqs();
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    getFaqs(): void {
        this.faqs = [];
        this.httpClient.get<any>(this.globalService.url + '/faq').subscribe(
            (response) => {
                if (response.status) {
                    for (const f of response.faqs) {
                        this.faqs.push(f);
                    }
                    if (this.tableau.length === 0) {
                        for (let i = 1; i <= this.faqs.length; i++) {
                            this.tableau.push(i);
                        }
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
