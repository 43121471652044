import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-refus-commande',
    templateUrl: './refus-commande.component.html',
    styleUrls: ['./refus-commande.component.css', './../../../../assets/css/inscription/style.css'],
})
export class RefusCommandeComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
