import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCheckCircle, faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { ToastService } from '../../../components/mdb/pro/alerts';
import Utils from '../../../utils';
import { ReusableService } from '../../../services/reusable.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-informations-candidats',
    templateUrl: './informations.component.html',
    styleUrls: [
        './informations.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class InformationsComponent implements OnInit {
    photoUpload = '1';
    arrowLeft = faArrowLeft;
    faError = faTimes;
    faOk = faCheckCircle;
    candidat: any = {
        password: '',
    };
    errorPage = {
        status: false,
        message: '',
    };

    dateNaissance = '';
    actuel = {
        nationalite: '',
        nationaliteCache: '',
        pays: '',
        paysCache: '',
    };
    searchNatio = new Subject<string>();
    resultsNatio: Observable<any> | undefined;
    dataNatio: any = [];
    listePays = [
        {
            pays: '',
            code: '',
        },
    ];
    paysNonSelectionner = false;
    pays: any = [];
    nationalitesCheck = false;
    nationalites: any = [];
    addr = {
        adresse: '',
        postcode: '',
        city: '',
    };

    error = {
        adresse: false,
        nationalites: false,
        photo: false,
        codePostal: false,
        ville: false,
        telephonePortable: false,
        telephoneFixe: false,
        pays: false,
    };
    photoCandidat: string | ArrayBuffer | null = '';

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private toastrService: ToastService,
        private userService: UserService,
        private reusableService: ReusableService
    ) {
        this.httpClient
            .post<any>(this.globalService.url + '/nationalites', {
                text: this.actuel.nationalite,
            })
            .subscribe((data: any) => {
                this.dataNatio = data;
                this.resultsNatio = this.searchNatio.pipe(
                    startWith(''),
                    map((value: string) => Utils.filterNatio(value, this.dataNatio))
                );
            });
    }

    ngOnInit(): void {
        this.candidat._id = localStorage.getItem('id');
        this.getAccount();
        this.getPays();
    }
    getPays(): void {
        this.reusableService.getPays().then((result: any) => {
            this.listePays = result;
        });
    }
    chooseNationalite(nationalite: string): void {
        this.actuel.nationalite = '';
        this.actuel.nationaliteCache = '';
        let ajouter = true;
        for (const n of this.candidat.nationalites) {
            if (n == nationalite) {
                ajouter = false;
                break;
            }
        }
        if (ajouter) {
            this.candidat.nationalites.push(nationalite);
        }
        this.searchNatio.next('');
    }
    removeNationalite(n: string): void {
        const nationalites = [];
        for (const nationalite of this.candidat.nationalites) {
            if (n !== nationalite) {
                nationalites.push(nationalite);
            }
        }
        this.candidat.nationalites = nationalites;
    }
    photo(f: any): void {
        this.photoUpload = '2';
        const file = f.target.files.item(0);
        const reader = new FileReader();
        const extensions = ['image/jpg', 'image/jpeg', 'image/png'];

        if (extensions.indexOf(file.type) > -1) {
            if (file.size <= 5000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.candidat.photo = reader.result;
                    this.photoCandidat = reader.result;
                    this.sendPhoto();
                };
            } else {
                this.photoUpload = '';
                alert("L'image importé est supérieur à 5Mo. veuillez réduire sa taille");
            }
        } else {
            this.photoUpload = '';
            alert("Le document que vous venez d'importer n'est pas dans un format valide");
        }
    }
    sendPhoto(): void {
        let photo = '';
        if (this.candidat.photo.split(';base64,').length > 1) {
            photo = this.candidat.photo;
        }
        this.httpClient.patch(this.globalService.url + '/candidat/photo-de-profil', { id: this.candidat._id, photo: photo }, {}).subscribe(
            (response: any) => {
                if (!response.status) {
                    alert('Erreur lors de la modification');
                    this.photoUpload = '';
                } else {
                    this.photoUpload = '';
                    localStorage.setItem('photo', response.photo);
                }
            },
            () => {
                this.photoUpload = '';
            }
        );
    }
    setPaysCode(event: any): void {
        this.candidat.paysCode = event;
        if (this.candidat.paysCode === 'FR') {
            this.candidat.estFrancais = true;
        } else {
            this.candidat.estFrancais = false;
        }
        for (let p of this.listePays) {
            if (p.code === event) {
                this.candidat.pays = p.pays;
                break;
            }
        }
    }

    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.candidat = response.candidat;

                    if (this.candidat.paysCode === '') {
                        this.candidat.paysCode = 'FR';
                        this.candidat.pays = 'France';
                    }
                    let dateNaissance = this.candidat.dateNaissance.split('-');
                    if (dateNaissance.length == 1) {
                        dateNaissance = this.candidat.dateNaissance.split('/');
                    }
                    dateNaissance = dateNaissance[0] + '-' + dateNaissance[1] + '-' + dateNaissance[2];
                    this.dateNaissance = dateNaissance;

                    this.addr.adresse = this.candidat.adresse;
                    this.addr.postcode = this.candidat.codePostal;
                    this.addr.city = this.candidat.ville;
                    if (this.candidat.photo !== '') {
                        this.candidat.photo = this.globalService.url + '/images/candidat/' + this.candidat.photo;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    renouvellerErreur(): void {
        this.errorPage.status = false;
        this.error = {
            adresse: false,
            nationalites: false,
            codePostal: false,
            ville: false,
            pays: false,
            telephonePortable: false,
            telephoneFixe: false,
            photo: false,
        };
    }
    sendActuel(): void {
        this.renouvellerErreur();
        let ok = true;
        if (this.candidat.nationalites.length == 0) {
            this.error.nationalites = true;
            ok = false;
        }
        if (this.candidat.adresse === '') {
            this.error.adresse = true;
            ok = false;
        }
        if (this.candidat.codePostal === '') {
            this.error.codePostal = true;
            ok = false;
        }
        if (this.candidat.ville === '') {
            this.error.ville = true;
            ok = false;
        }
        if (this.candidat.pays === '') {
            this.error.pays = true;
            ok = false;
        }
        if (this.candidat.telephonePortable === '' && this.candidat.telephoneFixe === '') {
            this.errorPage.status = true;
            this.errorPage.message = "Certains champs obligatoires n'ont pas été remplis";
            ok = false;
        }
        // @ts-ignore
        document.getElementById('telephonePortable').focus();
        // @ts-ignore
        document.getElementById('telephoneFixe').focus();
        // @ts-ignore
        const errorPortable = document.getElementById('telephonePortable').style.borderColor === 'red';
        // @ts-ignore
        const errorFixe = document.getElementById('telephoneFixe').style.borderColor === 'red';
        if (errorPortable) {
            this.errorPage.status = true;
            this.errorPage.message = "Le téléphone portable n'est pas au bon format";
            ok = false;
        }
        if (errorFixe && this.candidat.telephoneFixe !== '' && this.candidat.telephoneFixe !== '+33') {
            this.errorPage.status = true;
            this.errorPage.message = "Le téléphone fixe n'est pas au bon format";
            ok = false;
        }
        if (ok) {
            this.httpClient
                .patch(
                    this.globalService.url + '/candidat/informationsPersonnelles',
                    {
                        id: this.candidat._id,
                        civilite: this.candidat.civilite,
                        nom: this.candidat.nom,
                        prenom: this.candidat.prenom,
                        estFrancais: this.candidat.estFrancais,
                        nationalites: this.candidat.nationalites,
                        dateNaissance: this.candidat.dateNaissance,
                        adresse: this.candidat.adresse,
                        complementAdresse: this.candidat.complementAdresse,
                        codePostal: this.candidat.codePostal,
                        ville: this.candidat.ville,
                        pays: this.candidat.pays,
                        paysCode: this.candidat.paysCode,
                        telephonePortable: this.candidat.telephonePortable,
                        telephoneFixe: this.candidat.telephoneFixe,
                    },
                    {}
                )
                .subscribe(
                    (response: any) => {
                        if (!response.status) {
                            this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                        } else {
                            this.toastrService.info('Informations personnelles enregistrées', '', { opacity: 1 });
                            this.getAccount();
                        }
                    },
                    () => {}
                );
        }
    }
}
