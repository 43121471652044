<app-site-header page="mentions-legales"></app-site-header>
<div id="primary" class="content-area">
    <main id="main" class="site-main">
        <div id="contacts" class="row light_bg full-height align-items-center d-flex contact-1">
            <div class="container">
                <div class="vc_column-inner">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="vc_column-inner">
                                <div class="oi_vc_text item_height_x2 contact-1-1">
                                    <div class="oi_vc_text_span">
                                        <div class="oi_custom_heading_holder">
                                            <div class="oi_vc_heading oi_border_position_center">
                                                <h1 class="oi_icon_titile" style="text-align: center" *ngIf="!fini">
                                                    Demande de désabonnement
                                                </h1>
                                                <div class="mention-text" style="text-align: center" *ngIf="!fini && !emailStatus">
                                                    Merci de saisir l'email sur lequel vous avez reçu une candidature spontanée
                                                    <input
                                                        type="text"
                                                        [(ngModel)]="emailRecruteur"
                                                        class="form-control"
                                                        placeholder="Email..."
                                                    />
                                                </div>
                                              <div  class="mention-text" *ngIf="emailErr" style="color: red; font-size: 14px; text-align: center">
                                                L'email saisie n'a pas reçu cette candidature
                                              </div>
                                                <div class="mention-text" style="text-align: center" *ngIf="!fini">
                                                    <button (click)="valider()" class="form-control btn btn-success">Valider</button>
                                                </div>
                                                <h1 class="oi_icon_titile" style="text-align: center" *ngIf="fini">
                                                    Votre désabonnement a bien été pris en compte
                                                </h1>
                                                <div class="mention-text" style="text-align: center" *ngIf="fini">
                                                    Votre désabonnement a bien été pris en compte<br />
                                                    Vous ne recevrez plus de message de sa part<br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
<app-site-footer></app-site-footer>
