import { Component, OnInit, ViewChild } from '@angular/core';
import { faSearch, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { GlobalService } from '../../../services/global.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { OfferFormComponent } from '../../../components/modals/offer-form/offer-form.component';
import { PoleEmploiVariables } from '../../../pole-emploi';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { DrawerComponent } from '../../../components/modals/drawer/drawer.component';

@Component({
    selector: 'app-offres',
    templateUrl: './offres.component.html',
    styleUrls: [
        './offres.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class OffresComponent implements OnInit {
    @ViewChild('offerModal', { static: true })
    offerModal!: OfferFormComponent;

    @ViewChild('drawer', { static: true })
    drawer!: DrawerComponent;

    faSearch = faSearch;
    faArrowLeft = faArrowLeft;
    faArrowRight = faArrowRight;
    offres: any = [];
    filtres = {
        localisation: {
            code: '',
            libelle: '',
            type: '',
        },
        motsCles: '',
        date: '',
        typesContrat: [],
        durees: [],
        domaines: [],
        experiences: [],
        rayon: {
            min: 10,
            max: 200,
            valeur: 10,
        },
    };
    indexStart = 0;
    indexEnd = 10;
    nbResultats = 0;
    loading = false;
    openFilters = false;

    typesContrat = PoleEmploiVariables.TYPE_CONTRAT;
    durees = PoleEmploiVariables.DUREE;
    experiences = PoleEmploiVariables.EXPERIENCES;
    domainesPro = PoleEmploiVariables.DOMAINES;
    datesCreation = PoleEmploiVariables.DATE;
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {}

    previous(): void {
        this.indexStart -= 10;
        this.indexEnd -= 10;
        scroll(0, 0);
        this.search(false);
    }

    next(): void {
        this.indexStart += 10;
        this.indexEnd += 10;
        scroll(0, 0);
        this.search(false);
    }
    loadForm(id: any): void {
        this.offerModal.openForm(id);
    }
    applySelection(event: any, arrayData: any, data: string): void {
        const checked = event.checked;
        if (arrayData.includes(data) && !checked) {
            arrayData = arrayData.filter((e: string) => e !== data);
        } else if (!arrayData.includes(data) && checked) {
            arrayData.push(data);
        }
    }
    reset(): void {
        /*    this.filtres.date = '';
        this.filtres.typesContrat = [];
        this.filtres.durees = [];
        this.filtres.domaines = [];
        this.filtres.experiences = [];*/
        this.filtres = {
            localisation: {
                code: '',
                libelle: '',
                type: '',
            },
            motsCles: '',
            date: '',
            typesContrat: [],
            durees: [],
            domaines: [],
            experiences: [],
            rayon: {
                min: 10,
                max: 200,
                valeur: 10,
            },
        };
        this.offres = [];
    }
    search(resetIndex: boolean): void {
        if (resetIndex) {
            this.indexStart = 0;
            this.indexEnd = 10;
        }
        this.loading = true;
        this.httpClient
            .post<any>(
                this.globalService.url + '/offres/search',
                {
                    search: this.filtres.motsCles,
                    indexStart: this.indexStart,
                    indexEnd: this.indexEnd,
                    typesContrat: this.filtres.typesContrat,
                    date: this.filtres.date,
                    durees: this.filtres.durees,
                    domaines: this.filtres.domaines,
                    experiences: this.filtres.experiences,
                    localisation: this.filtres.localisation,
                    distance: this.filtres.rayon.valeur,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        if (response.offres.length === 0) {
                            this.toastrService.info(' Aucun résultat', '', { opacity: 1 });
                            this.offres = [];
                            this.nbResultats = 0;
                        } else {
                            this.offres = response.offres.resultats;

                            const firstFilter = response.offres.filtresPossibles[0].agregation;
                            this.nbResultats = firstFilter.reduce((prev: any, curr: any) => prev + curr.nbResultats, 0);
                        }

                        this.loading = false;
                    } else {
                        this.toastrService.error(
                            ' La recherche d’offres est victime de son succès. Merci de réessayer dans quelques secondes',
                            '',
                            { opacity: 1 }
                        );
                        this.loading = false;
                    }
                },
                () => {
                    this.toastrService.error(
                        ' La recherche d’offres est victime de son succès. Merci de réessayer dans quelques secondes',
                        '',
                        { opacity: 1 }
                    );
                    this.loading = false;
                }
            );
    }
}
