<app-site-header></app-site-header>
<div
    mdbModal
    #lmModal="mdbModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myBasicModalLabel"
    aria-hidden="true"
    style="overflow: auto; overflow-x: hidden; z-index: 1051"
>
    <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="lmModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100">Lettre de motivation envoyée</h4>
            </div>
          <div class="modal-body" [innerHTML]='commande.lettreMotivation'></div>

          <div class="modal-footer">
                <button type="button" mdbBtn color="danger" class="waves-light" aria-label="Fermer" (click)="lmModal.hide()" mdbWavesEffect>
                    Fermer
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <div>
            <div class="page-inner">
                <a href="espace-candidat/mes-commandes" class="text-dark text-decoration-none">
                    <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
                </a>
                <div class="page-title mt-3">
                    <h3 class="breadcrumb-header">Ma commande {{ commande.numeroCommande }}</h3>
                </div>
                <div id="wizard_container" *ngIf='commande'>
                    <div id="middle-wizard">
                        <div>
                            <div class="row">
                                <div class="col-md-6">
                                    <table class="display table dataTable custom-table">
                                        <tr>
                                            <td>Nombre de candidatures dans le panier</td>
                                            <td style="text-align: right">
                                                {{ commande.nombreCourriel }}
                                            </td>
                                        </tr>
                                        <tr *ngIf='commande.recruteursRetrait'>
                                            <td>Nombre de candidatures retirées</td>
                                            <td style="text-align: right">
                                                {{ commande.recruteursRetrait.length }}
                                            </td>
                                        </tr>
                                        <tr *ngIf='commande.recruteursRetrait'>
                                            <td>Nombre de candidatures à envoyer</td>
                                            <td style="text-align: right">
                                                {{ commande.nombreCourriel - commande.recruteursRetrait.length }}
                                            </td>
                                        </tr>
                                        <tr *ngIf='commande.prix'>
                                            <td>Prix unitaire TTC</td>
                                            <td style="text-align: right">{{ commande.prix.ttc }} €</td>
                                        </tr>
                                        <tr>
                                            <td>Sous-total TTC</td>
                                            <td style="text-align: right">{{ sousTotalTtc }} €</td>
                                        </tr>
                                        <tr *ngIf='commande.reduction'>
                                            <td>Code de réduction</td>
                                            <td style="text-align: right">
                                                <div *ngIf="commande.reduction.nomCode === ''">Aucun</div>
                                                <div *ngIf="commande.reduction.nomCode !== ''" style="position: relative">
                                                    <div style="margin-right: 15px">
                                                        {{ commande.reduction.nomCode }}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf='commande.reduction'>
                                            <td>Montant de la réduction TTC</td>
                                            <td style="text-align: right">
                                                <div *ngIf="commande.reduction.nomCode === ''">0 €</div>
                                                <div *ngIf="commande.reduction.nomCode !== ''">
                                                    {{ commande.reduction.montantTtc.toFixed(2) }}
                                                    €
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Crédit TTC utilisé</td>
                                            <td style="text-align: right">{{ creditUtiliser }} €</td>
                                        </tr>
                                        <tr>
                                            <td>Montant payé</td>
                                            <td style="text-align: right">
                                                <div *ngIf="totalTtc <= 0">Gratuit</div>
                                                <div *ngIf="totalTtc > 0">{{ totalTtc }} €</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>(dont TVA 20%)</td>
                                            <td style="text-align: right">
                                                <div *ngIf="totalTtc <= 0">Pas de TVA</div>
                                                <div *ngIf="totalTtc > 0">{{ tva }} €</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Crédit TTC restant après commande</td>
                                            <td style="text-align: right">{{ commande.creditRestant }} €</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <table class="display table dataTable custom-table">
                                        <tr>
                                            <td>Sujet</td>
                                            <td>
                                                {{ commande.sujet }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Email d'expédition</td>
                                            <td>
                                                {{ commande.email }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Lettre de motivation</td>
                                            <td>
                                                <a *ngIf="commande.lettreMotivation != ''" (click)="lmModal.show()"
                                                    ><fa-icon [icon]="faPdf"></fa-icon
                                                ></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CV</td>
                                            <td>
                                                <a
                                                    target="_blank"
                                                    style="text-decoration: underline"
                                                    href="{{ url }}/documents/cv/{{ commande.lienCv }}"
                                                    >Télécharger le CV</a
                                                >
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-site-footer></app-site-footer>
