import { Component, OnInit } from '@angular/core';
import { faCheckCircle, faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { ToastService } from '../../../components/mdb/pro/alerts';

@Component({
    selector: 'app-renouveller-mot-de-passe',
    templateUrl: './renouveller-mot-de-passe.component.html',
    styleUrls: ['./renouveller-mot-de-passe.component.css'],
})
export class RenouvellerMotDePasseComponent implements OnInit {
    eye1 = faEye;
    eye2 = faEye;
    type1 = 'password';
    type2 = 'password';
    faError = faTimes;
    faOk = faCheckCircle;
    renouveller = {
        longueur: false,
        majuscule: false,
        miniscule: false,
        chiffre: false,
        caractereSpeciale: false,
        identique: false,
    };
    finText = '';
    afficher = false;
    status = false;
    password = {
        un: '',
        deux: '',
    };
    id = '';
    token: string | null = '';
    typeCompte = '';
    errorIdentique = 'Les deux mots de passe sont identiques.';
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {
        this.id = this.route.snapshot.params.id;
        this.typeCompte = this.route.snapshot.params.typeCompte;
        this.token = this.route.snapshot.queryParamMap.get('token');

        setInterval(() => {
            this.analyser();
        }, 200);
    }
    eye(num: number): void {
        if (num === 1) {
            if (this.eye1 === faEye) {
                this.eye1 = faEyeSlash;
                this.type1 = 'text';
            } else {
                this.eye1 = faEye;
                this.type1 = 'password';
            }
        } else if (num === 2) {
            if (this.eye2 === faEye) {
                this.eye2 = faEyeSlash;
                this.type2 = 'text';
            } else {
                this.eye2 = faEye;
                this.type2 = 'password';
            }
        }
    }
    analyser(): void {
        if (this.password.un.length >= 10) {
            this.renouveller.longueur = true;
        } else {
            this.renouveller.longueur = false;
        }
        if (this.password.un.match(/[a-z]/g)) {
            this.renouveller.miniscule = true;
        } else {
            this.renouveller.miniscule = false;
        }
        if (this.password.un.match(/[A-Z]/g)) {
            this.renouveller.majuscule = true;
        } else {
            this.renouveller.majuscule = false;
        }
        if (this.password.un.match(/[0-9]/g)) {
            this.renouveller.chiffre = true;
        } else {
            this.renouveller.chiffre = false;
        }
        if (this.password.un.match(/[@#&"';.,?:\/\\!]/g)) {
            this.renouveller.caractereSpeciale = true;
        } else {
            this.renouveller.caractereSpeciale = false;
        }
        if (this.password.un === this.password.deux) {
            this.renouveller.identique = true;
            this.errorIdentique = 'Les deux mots de passe sont identiques.';
        } else {
            this.renouveller.identique = false;
            this.errorIdentique = 'Les deux mots de passe ne sont pas identiques.';
        }
    }
    sauvegarder(): void {
        this.afficher = true;
        if (
            this.renouveller.longueur &&
            this.renouveller.miniscule &&
            this.renouveller.majuscule &&
            this.renouveller.chiffre &&
            this.renouveller.caractereSpeciale &&
            this.renouveller.identique
        ) {
            this.save();
        } else {
            this.status = false;
            this.toastrService.error('Le mot de passe ne répond pas à toutes les règles.', '', { opacity: 1 });
        }
    }
    save(): void {
        this.httpClient
            .patch(this.globalService.url + '/' + this.typeCompte + '/reset-password', {
                id: this.id,
                password: this.password.un,
                token: this.token,
            })
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.status = true;
                        this.sendModification(response.type);
                        this.toastrService.info('Votre mot de passe a bien été modifié.', '', { opacity: 1 });
                    } else {
                        this.status = false;
                        this.toastrService.error('Une erreur est survenue lors de la modification du mot de passe.', '', { opacity: 1 });
                    }
                },
                () => {
                    this.status = false;
                    this.toastrService.error('Une erreur est survenue lors de la modification du mot de passe.', '', { opacity: 1 });
                }
            );
    }
    sendModification(type: string): void {
        this.httpClient
            .put<any>(this.globalService.url + '/mail/password-modifier', {
                id: this.id,
                type: type,
            })
            .subscribe(
                (response) => {},
                () => {}
            );
    }
}
