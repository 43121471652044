import { Component, OnInit } from '@angular/core';

import { faFacebook, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
@Component({
    selector: 'app-connexion-administrateur',
    templateUrl: './connexion-administrateur.component.html',
    styleUrls: ['./connexion-administrateur.component.css'],
})
export class ConnexionAdministrateurComponent implements OnInit {
    faFacebook = faFacebook;
    faGoogle = faGoogle;
    faLinkedin = faLinkedin;
    login = {
        identifiant: '',
        password: '',
    };
    error = '';

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService,
        private router: Router
    ) {}

    ngOnInit(): void {
        const id = localStorage.getItem('id') !== undefined && localStorage.getItem('id') !== null;
        const type = localStorage.getItem('type') !== undefined && localStorage.getItem('type') !== null;
        const administrateurId = localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null;
        if (id && type && administrateurId && localStorage.getItem('type') === 'Administrateur') {
            this.router.navigate(['/administration/dashboard']);
        }
    }
    connexion(): void {
        this.error = '';
        if (this.login.identifiant === '') {
            this.error = 'Veuillez saisir votre identifiant';
        } else if (this.login.password === '') {
            this.error = 'Veuillez saisir votre mot de passe';
        } else {
            this.httpClient.post<any>(this.globalService.url + '/admin/login', this.login).subscribe(
                (response) => {
                    if (response.status) {
                        this.authService.storeUserData(response, 'admin');
                        if (response.typeAdministrateur == 'validateur') {
                            this.router.navigate(['/administration/surveillance-photos']);
                        } else {
                            this.router.navigate(['/administration/dashboard']);
                        }
                    } else {
                        this.error = 'Identifiant ou mot de passe incorrect';
                    }
                },
                () => {
                    this.error = 'Identifiant ou mot de passe incorrect';
                }
            );
        }
    }
    facebook(): void {
        alert('facebook');
    }
    gmail(): void {
        alert('gmail');
    }
    linkedin(): void {
        alert('linkedin');
    }
}
