<app-site-header></app-site-header>
<!-- Login Hero Section HTML Start -->

<section id="Login-hero">
    <div class="login-hero-wrapper">
        <div class="row">
            <div class="col-12">
                <div class="login-hero-candidat-right position-relative">
                    <div class="login-slider">
                        <div class="login-slide">
                            <h3>
                                Espace candidat <br />
                                Comment ça fonctionne ?
                            </h3>
                            <div
                                class="slide-content align-items-center py-2"
                                *ngFor="let slide of candidatSlides; let title = index"
                                [ngClass]="title === this.index ? 'slide-active' : ''"
                            >
                                <h1>{{ title + 1 }}</h1>
                                <h5>
                                    {{ slide.text }}
                                </h5>
                            </div>
                            <div class="slide-icons d-flex justify-content-between align-items-center">
                                <img class="prev" (click)="prev()" src="./assets/img/prev.png" alt="next" />
                                <img class="next" (click)="next()" src="./assets/img/next.png" alt="next" />
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-12 text-center">
                                <a
                                    href="/inscription-candidat"
                                    mdbBtn
                                    color="pink"
                                    [outline]="true"
                                    [rounded]="true"
                                    mdbWavesEffect
                                    style="border: 2px solid #d89e9e !important; color: #d89e9e !important"
                                    >S'inscrire</a
                                >
                                <a
                                    href="/candidat"
                                    mdbBtn
                                    color="warning"
                                    [rounded]="true"
                                    mdbWavesEffect
                                    style="color: white; background-color: #d89e9e !important"
                                    >Se connecter</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Avenue Hero Section HTML Start -->

<section id="Avenue">
    <div class="avenue-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="avenue-content">
                        <h2 class="section-title pl-3">Avenue Des Recruteurs vous permet :</h2>
                        <div class="avenue-list">
                            <ul>
                                <li>
                                    de trouver des recruteuses et recruteurs et de leur envoyer des candidatures spontanées individualisées
                                    par courriel
                                </li>
                                <li>
                                    de déposer gratuitement votre CV dans l’espaces des recruteuses et recruteurs ciblés et/ou dans la
                                    CVthèque du site afin d’augmenter vos chances d’être recruté(e)
                                </li>
                            </ul>
                        </div>
                        <div class="avenue-counters d-none justify-content-center align-items-center">
                            <div class="counter-item text-center">
                              <h3 [countUp]="nombreRecruteurs" [options]="{ enableScrollSpy: true }">0</h3>
                              <h6 style='font-size: 20px; font-weight: bold'>Recruteurs</h6>
                            </div>
                            <div class="counter-item text-center">
                              <h3 [countUp]="nombreCandidats" [options]="{ enableScrollSpy: true }">0</h3>
                              <h6 style='font-size: 20px; font-weight: bold'>Candidats</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Avenue Hero Section HTML End -->

<!-- Strengths Section HTML Start -->

<section id="Strengths">
    <div class="container strengths-wrapper">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="strengths-content">
                    <h2 class="section-title">Nos principaux atouts :</h2>
                    <div class="avenue-list pt-2">
                        <ul>
                            <li>Mise à jour régulière de la base de données des recruteurs</li>
                            <li>Nombreux critères de recherche</li>
                            <li>Personnalisation automatique possible de votre lettre de motivation</li>
                            <li>
                                Dans le courriel envoyé au recruteur seul votre email apparaîtra en qualité d’expéditeur et non Avenue des
                                Recruteurs
                            </li>
                            <li>
                                Envoi de votre courriel si possible à la date et à l’heure de votre choix (dans un délai maximum de 7 jours)
                            </li>
                            <li>Grande expérience dans l’envoi en nombre de candidatures spontanées par Internet</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Strengths Section HTML End -->

<!-- Service Section HTML Start -->

<section id="Service">
    <div class="service-wrapper">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12" style="padding: 30px">
                    <div class="service-left">
                        <h2 class="section-title">Le savez-vous ?</h2>
                        <p>
                            Avenue Des Recruteurs réalise régulièrement des études auprès de candidats et de candidats afin de vous aider
                            dans votre recherche.
                        </p>
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <mdb-carousel
                        #carousel
                        class="carousel-multi-item multi-animation d-sm-none"
                        [type]="'carousel-multi-item'"
                        [animation]="'slide'"
                    >
                        <mdb-carousel-item *ngFor="let item of slides; let i = index">
                            <div class="col-md-4 pb-2" *ngFor="let card of item; let cardIndex = index">
                                <mdb-card [ngClass]="i % 2 === 0 ? 'carousel-odd' : 'carousel-even'" class="my-1" style="height: 300px">
                                    <mdb-card-body>
                                        <mdb-card-title>
                                            <h4>{{ card.title }}</h4>
                                        </mdb-card-title>
                                        <p>{{ card.description }}</p>
                                    </mdb-card-body>
                                </mdb-card>
                            </div>
                        </mdb-carousel-item>
                    </mdb-carousel>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Service Section HTML End -->

<!-- Testimonial Section HTML Start -->

<section id="Testimonial" style='display: none'>
    <div class="container">
        <div class="testimonial-wrapper">
            <div class="testimonial-slide">
                <div class="testimonial-item d-md-flex justify-content-between align-items-center">
                    <div class="testimonial-content">
                        <h2 class="section-title pb-3">Témoignages de candidat(e)s</h2>

                        <div
                            class="client-slide"
                            *ngFor="let t of temoignages"
                            [ngClass]="this.index2 + 1 === t.ordre ? 'active-class' : ''"
                            [ngStyle]="{
                                display: this.index2 + 1 === t.ordre ? 'block' : 'none'
                            }"
                        >
                            <img
                                class="rounded-circle img-fluid d-block ml-auto mr-auto p-2"
                                style="object-fit: cover; width: 100px; height: 100px"
                                src="{{ url }}/images/temoignages/{{ t.photo }}"
                                alt="user"
                            />

                            <p>
                                {{ t.texte }}
                            </p>
                            <div class="client-name">
                                <h4>{{ t.nom }}</h4>
                            </div>
                        </div>

                        <div class="testimonial-icon text-center pt-4">
                            <img class="arrow-left" (click)="left()" src="./assets/img/arrow-left.png" alt="arrow-left" />
                            <img class="arrow-right" (click)="right()" src="./assets/img/arrow-right.png" alt="arrow-right" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Testimonial Section HTML End -->

<!-- Recruiters Section HTML Start -->

<section id="Recruiters">
    <div class="recruiters-wrapper">
        <h2>Derniers recruteurs inscrits</h2>
    </div>
    <div class="recruiters-item-wrapper">
        <div class="candidats d-flex justify-content-center flex-wrap">
            <div
                [class]="'candidats-item ' + (i >= 1 && i <= 3 ? 'bg-dif' : '')"
                class="candidats-item"
                *ngFor="let account of dernierInscrit.slice(0, 5); let i = index"
            >
                <img
                    class="rounded-circle img-fluid"
                    style="object-fit: cover; width: 100px; height: 100px"
                    src="{{ url }}/images/recruteur/{{ account.photo }}"
                    alt="user"
                />
              <h6>{{ account.fonctionRecruteur }}</h6>
            </div>
        </div>
    </div>
</section>
<app-site-footer></app-site-footer>
