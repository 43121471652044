import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare const Stripe: (arg0: string) => any;

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    constructor() {}

    redirectToStripeCheckout(sessionId: string): void {
        const stripe = Stripe(environment.stripeKey);

        stripe.redirectToCheckout({
            sessionId,
        });
    }
}
