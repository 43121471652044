<app-modal-report [reportedId]='recruteur._id' reportedRole='recruteur' [modal]='recruteurModal' #reportModal></app-modal-report>
<div
  mdbModal
  #recruteurModal="mdbModal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myBasicModalLabel"
  aria-hidden="true"
  style="overflow: auto; overflow-x: hidden;"

>
  <div class="modal-dialog modal-full-width" role="document">
    <div class="modal-content">
      <div class="modal-header" style='display: block'>
        <button type="button" class="close pull-right" aria-label="Close" (click)="recruteurModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Fiche recruteur</h4>
        <p>Par soucis de confidencialité, les informations nominatives des recruteurs ne sont pas dévoilées.</p>
      </div>
      <div class="modal-body" *ngIf='recruteur && recruteur.referenceRecruteur'>
        <div>
          <div class="row">
            <div class="col-12 pb-4">
              <span class="recruteur-name">{{ recruteur.referenceRecruteur }} </span>
            </div>
            <div class="col-12 pb-4">
              <span class='recruteur-informations' *ngIf='recruteur.pays'>Pays de l'établissement de l'organisme: {{recruteur.pays}}</span>
              <br/>
              <span class='recruteur-informations' *ngIf='recruteur.ville'>Ville de l'établissement de l'organisme: {{recruteur.ville}}</span>
            </div>
            <div class="col-md-6" *ngIf='recruteur.metiersRechercher.length || recruteur.typePoste.length || recruteur.typePosteVoulu.length || recruteur.temps.length || recruteur.profilRechercher'>
              <h5>Profil(s) recherché(s)</h5>
              <table class="display table dataTable custom-table">
                <tr *ngIf="recruteur.metiersRechercher.length">
                  <td>Métier(s) recherché(s) par le recruteur</td>
                  <td style="text-align: right">
                    <div *ngFor="let f of recruteur.metiersRechercher">
                      {{ f.nom }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf="recruteur.typePoste.length">
                  <td>Type(s) de poste proposé(s)</td>
                  <td style="text-align: right">
                    <div *ngFor="let t of recruteur.typePoste">
                      {{ t | typesPoste }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf="recruteur.typePosteVoulu.length">
                  <td>Type(s) de contrat proposé(s)</td>
                  <td style="text-align: right">
                    <div *ngFor="let t of recruteur.typePosteVoulu">
                      <div *ngIf="t === 'cdd'">CDD</div>
                      <div *ngIf="t === 'cdi'">CDI</div>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="recruteur.temps.length">
                  <td>Temps proposé(s)</td>
                  <td style="text-align: right">
                    <div *ngFor="let t of recruteur.temps">
                      <div>
                        {{ t | firstUpperText }}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="recruteur.profilRechercher">
                  <td>Complément d'information(s) sur les profil(s) recherché(s)</td>
                  <td style="text-align: right">
                    {{ recruteur.profilRechercher }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-md-6 mt-3 mt-md-0">
              <h5>Organisme du recruteur</h5>
              <table class="display table dataTable custom-table">
                <tr *ngIf="recruteur.typeEntreprise">
                  <td>Type d'organisme</td>
                  <td style="text-align: right">
                    {{ recruteur.typeEntreprise | typesEntreprises }}
                  </td>
                </tr>
                <tr *ngIf="recruteur.nombreSalarie">
                  <td>Nombre de salarié(s)</td>
                  <td style="text-align: right">
                    {{ recruteur.nombreSalarie | workersNumber }}
                  </td>
                </tr>
                <tr *ngIf="recruteur.activite.nom || recruteur.activite.codeNaf">
                  <td>Activité principale</td>
                  <td style="text-align: right">
                    {{ recruteur.activite.nom ? recruteur.activite.nom + ' - ' : '' }}
                    {{ recruteur.activite.codeNaf }}
                  </td>
                </tr>
                <tr *ngIf="recruteur.secteurEntreprise.length">
                  <td>Autre(s) activité(s)</td>
                  <td style="text-align: right">
                    <div *ngFor="let s of recruteur.secteurEntreprise">
                      {{ s.nom }} -
                      {{ s.codeNaf }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf="recruteur.activiteSpecifique.length">
                  <td>Activité(s) spécifique(s)</td>
                  <td style="text-align: right">
                    <div *ngFor="let s of recruteur.activiteSpecifique">
                      {{ s | activities }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          mdbBtn
          [rounded]='true'
          color="danger"
          class="waves-light"
          aria-label="Signaler"
          (click)="reportModal.openForm(); recruteurModal.hide()"
          mdbWavesEffect
        >
          Signaler
        </button>
        <button
          type="button"
          mdbBtn
          [rounded]='true'
          color="warning"
          class="waves-light"
          aria-label="Fermer"
          (click)="recruteurModal.hide()"
          mdbWavesEffect
        >
          Fermer
        </button>
      </div>
    </div>
  </div>
</div>
