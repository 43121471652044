import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-connexion-intermediaire',
    templateUrl: './connexion-intermediaire.component.html',
    styleUrls: ['./connexion-intermediaire.component.css'],
})
export class ConnexionIntermediaireComponent implements OnInit {
    login = {
        identifiant: '',
        password: '',
    };
    error = '';
    constructor(private router: Router) {}

    ngOnInit(): void {}
    connexion(): void {
        this.error = '';
        if (this.login.identifiant === '') {
            this.error = 'Veuillez saisir votre identifiant';
        } else if (this.login.password === '') {
            this.error = 'Veuillez saisir votre mot de passe';
        } else {
            if (this.login.identifiant === 'avenuedesrecruteurs' && this.login.password === '05051995') {
                localStorage.setItem('intermediaire', 'OK');
                this.router.navigate(['/']);
            }
        }
    }
}
