import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCheckCircle, faEye, faEyeSlash, faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../components/mdb/pro/alerts';

@Component({
    selector: 'app-mot-de-passe-candidats',
    templateUrl: './mot-de-passe.component.html',
    styleUrls: [
        './mot-de-passe.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class MotDePasseComponent implements OnInit {
    eye1 = faEye;
    eye2 = faEye;
    eye3 = faEye;
    arrowLeft = faArrowLeft;
    faError = faTimes;
    faOk = faCheckCircle;
    candidat: any = {
        password: '',
    };
    ancienPassword = '';
    type1 = 'password';
    type2 = 'password';
    type3 = 'password';
    analyse = {
        longueur: false,
        majuscule: false,
        miniscule: false,
        chiffre: false,
        caractereSpeciale: false,
        identique: false,
    };
    passwordDeux = '';
    errorPassword = {
        status: false,
        message: '',
    };
    error = {
        password: false,
        passwordDeux: false,
        ancienPassword: false,
    };

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {
        this.candidat._id = localStorage.getItem('id');

        setInterval(() => {
            this.analyserPassword();
        }, 200);
    }
    renouvellerErreur(): void {
        this.error = {
            password: false,
            passwordDeux: false,
            ancienPassword: false,
        };
        this.errorPassword = {
            status: false,
            message: '',
        };
    }
    sendPassword(): void {
        this.renouvellerErreur();
        if (
            this.analyse.longueur &&
            this.analyse.miniscule &&
            this.analyse.majuscule &&
            this.analyse.chiffre &&
            this.analyse.caractereSpeciale &&
            this.analyse.identique
        ) {
            this.httpClient
                .patch(
                    this.globalService.url + '/candidat/password',
                    {
                        id: this.candidat._id,
                        password: this.candidat.password,
                        ancienPassword: this.ancienPassword,
                    },
                    {}
                )
                .subscribe(
                    (response: any) => {
                        if (!response.status) {
                            this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                        } else {
                            this.toastrService.info('Mot de passe modifié', '', { opacity: 1 });
                        }
                    },
                    () => {
                        this.toastrService.error("Votre ancien mot de passe n'est pas correct", '', { opacity: 1 });
                    }
                );
        } else if (this.candidat.password === '') {
            this.error.password = true;
        } else if (this.passwordDeux === '') {
            this.error.passwordDeux = true;
        } else {
            this.errorPassword.status = true;
            this.errorPassword.message = "Votre mot de passe n'est pas assez fort";
            if (this.candidat.password !== this.passwordDeux) {
                this.errorPassword.message = 'Les deux mot de passe ne sont pas identique';
            }
        }
    }
    analyserPassword(): void {
        if (this.candidat.password.length >= 10) {
            this.analyse.longueur = true;
        } else {
            this.analyse.longueur = false;
        }
        if (this.candidat.password.match(/[a-z]/g)) {
            this.analyse.miniscule = true;
        } else {
            this.analyse.miniscule = false;
        }
        if (this.candidat.password.match(/[A-Z]/g)) {
            this.analyse.majuscule = true;
        } else {
            this.analyse.majuscule = false;
        }
        if (this.candidat.password.match(/[0-9]/g)) {
            this.analyse.chiffre = true;
        } else {
            this.analyse.chiffre = false;
        }
        if (this.candidat.password.match(/[@#&"';.,?:\/\\!]/g)) {
            this.analyse.caractereSpeciale = true;
        } else {
            this.analyse.caractereSpeciale = false;
        }
        if (this.candidat.password === this.passwordDeux) {
            this.analyse.identique = true;
        } else {
            this.analyse.identique = false;
        }
    }
    eye(num: number): void {
        if (num === 1) {
            if (this.eye1 === faEye) {
                this.eye1 = faEyeSlash;
                this.type1 = 'text';
            } else {
                this.eye1 = faEye;
                this.type1 = 'password';
            }
        } else if (num === 2) {
            if (this.eye2 === faEye) {
                this.eye2 = faEyeSlash;
                this.type2 = 'text';
            } else {
                this.eye2 = faEye;
                this.type2 = 'password';
            }
        } else {
            if (this.eye3 === faEye) {
                this.eye3 = faEyeSlash;
                this.type3 = 'text';
            } else {
                this.eye3 = faEye;
                this.type3 = 'password';
            }
        }
    }
}
