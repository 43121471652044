import { faArrowLeft, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-detail-commande',
    templateUrl: './detail-commande.component.html',
    styleUrls: [
        './detail-commande.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class DetailCommandeComponent implements OnInit {
    id = '';
    commande: any = {};
    candidat: any = {};
    faPdf = faFilePdf;
    url = '';
    sousTotalTtc = 0;
    sousTotalHt = 0;
    totalTtc = 0;
    totalHt = 0;
    tva = 0;
    credit = 0;
    creditUtiliser = 0;
    creditRestant = 0;
    retraitDesabo = 0;
    public arrowLeft = faArrowLeft;
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.url = this.globalService.url;
        if (this.route.snapshot.params.id !== undefined && this.route.snapshot.params.id !== null) {
            this.id = this.route.snapshot.params.id;

            this.httpClient.get<any>(this.globalService.url + '/commande/' + this.id).subscribe(
                (response) => {
                    if (response.status) {
                        this.commande = response.commande;
                        // this.commande.lettreMotivation = this.commande.lettreMotivation.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '');
                        this.getCandidat(this.commande.idCandidat);
                    }
                },
                () => {}
            );
        }
    }
    getCandidat(id: string): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/' + id).subscribe(
            (response) => {
                if (response.status) {
                    this.candidat = response.candidat;
                    this.calculPrix();
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    calculPrix(): void {
        this.retraitDesabo = this.commande.recruteursRetrait.length;
        this.sousTotalTtc = this.commande.prix.ttc * (this.commande.nombreCourriel - this.retraitDesabo);
        this.sousTotalTtc = parseFloat(this.sousTotalTtc.toFixed(2));
        this.sousTotalHt = parseFloat((this.sousTotalTtc / 1.2).toFixed(2));
        this.totalTtc =
            this.commande.prix.ttc * (this.commande.nombreCourriel - this.retraitDesabo) -
            this.commande.reduction.montantTtc -
            this.commande.credit;
        this.totalTtc = parseFloat(this.totalTtc.toFixed(2));
        this.totalHt = parseFloat((this.totalTtc / 1.2).toFixed(2));
        this.tva = parseFloat((this.totalTtc - this.totalHt).toFixed(2));
        if (this.totalTtc > 0 && this.totalTtc < 1) {
            this.totalTtc = 1;
            this.tva = 0.17;
        }
        this.creditUtiliser = this.commande.credit;
        this.creditRestant = this.commande.creditRestant;
    }
}
