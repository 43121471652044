export const PoleEmploiVariables = Object.freeze({
    TYPE_CONTRAT: [
        {
            value: 'CDI',
            label: 'CDI',
        },
        {
            value: 'DIN',
            label: 'CDI Intérimaire',
        },
        {
            value: 'CDD',
            label: 'CDD',
        },
        {
            value: 'DDI',
            label: 'Contrat durée déterminée insertion',
        },
        {
            value: 'TTI',
            label: 'Contrat travail temporaire insertion',
        },
        {
            value: 'FRA',
            label: 'Franchise',
        },
        {
            value: 'MIS',
            label: 'Mission intérimaire',
        },
        {
            value: 'CCE',
            label: 'Profession commerciale',
        },
        {
            value: 'LIB',
            label: 'Profession libérale',
        },
        {
            value: 'REP',
            label: "Reprise d'entreprise",
        },
        {
            value: 'SAI',
            label: 'Saisonnier',
        },
    ],
    DATE: [
        {
            value: '1',
            label: 'Un jour',
        },
        {
            value: '3',
            label: 'Trois jours',
        },
        {
            value: '7',
            label: 'Une semaine',
        },
        {
            value: '14',
            label: 'Deux semaines',
        },
        {
            value: '31',
            label: 'Un mois',
        },
        {
            value: '',
            label: 'Toutes les offres',
        },
    ],
    DUREE: [
        {
            value: '1',
            label: 'Temps plein',
        },
        {
            value: '2',
            label: 'Temps partiel',
        },
        {
            value: '0',
            label: 'Non renseignée',
        },
    ],
    DOMAINES: [
        {
            value: 'M',
            label: 'Achats / Comptabilité / Gestion',
        },
        {
            value: 'B',
            label: "Arts / Artisanat d'art",
        },
        {
            value: 'C',
            label: 'Banque / Assurance',
        },
        {
            value: 'F',
            label: 'Bâtiment / Travaux Publics',
        },
        {
            value: 'D',
            label: 'Commerce / Vente',
        },
        {
            value: 'E',
            label: 'Communication / Multimédia',
        },
        {
            value: 'M14',
            label: 'Conseil / Etudes',
        },
        {
            value: 'M13',
            label: "Direction d'entreprise",
        },
        {
            value: 'A',
            label: 'Espaces verts et naturels / Agriculture / Pêche / Soins aux animaux',
        },
        {
            value: 'G',
            label: 'Hôtellerie - Restauration / Tourisme / Animation',
        },
        {
            value: 'C15',
            label: 'Immobilier',
        },
        {
            value: 'H',
            label: 'Industrie',
        },
        {
            value: 'M18',
            label: 'Informatique / Télécommunication',
        },
        {
            value: 'I',
            label: 'Installation / Maintenance',
        },
        {
            value: 'M17',
            label: 'Marketing / Stratégie commerciale',
        },
        {
            value: 'M15',
            label: 'Ressources Humaines',
        },
        {
            value: 'J',
            label: 'Santé',
        },
        {
            value: 'M16',
            label: 'Secrétariat / Assistanat',
        },
        {
            value: 'K',
            label: 'Services à la personne / à la collectivité',
        },
        {
            value: 'L',
            label: 'Spectacle',
        },
        {
            value: 'L14',
            label: 'Sport',
        },
        {
            value: 'N',
            label: 'Transport / Logistique',
        },
    ],
    EXPERIENCES: [
        {
            value: '1',
            label: 'Moins de 1 an',
        },
        {
            value: '2',
            label: 'De 1 à 3 ans',
        },
        {
            value: '3',
            label: 'Plus de 3 ans',
        },
        {
            value: '0',
            label: 'Non renseigné',
        },
    ],
});
