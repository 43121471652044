<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10">
        <div class="page-title">
            <h3 class="breadcrumb-header">Gestion des désabonnements globaux</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table style="width: 100%">
                                    <tr>
                                        <td style="width: 50%; text-align: right">
                                            <input class="form-control" type="text" placeholder="Rechercher..." />
                                        </td>
                                    </tr>
                                </table>
                                <table class="display table dataTable">
                                    <thead>
                                        <tr>
                                            <th>Type de compte</th>
                                            <th>Date(s) désabonnement</th>
                                            <th>Référence</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let d of candidats">
                                            <td>Candidat</td>
                                            <td>
                                                {{ d.dateDesinscription * 1000 | date: 'dd-MM-yyyy' }}
                                            </td>
                                            <td>
                                                {{ d.referenceCandidat }}
                                            </td>
                                        </tr>
                                        <tr *ngFor="let d of recruteurs">
                                            <td>Recruteur</td>
                                            <td>
                                                {{ d.dateDesinscription * 1000 | date: 'dd-MM-yyyy' }}
                                            </td>
                                            <td>
                                                {{ d.referenceRecruteur }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="container-pagination">
                        <a class="paginate_button previous disabled">Précédent</a>
                        <span>
                            <a class="paginate_button current">1</a>
                            <a class="paginate_button">2</a>
                            <a class="paginate_button">3</a>
                            <a class="paginate_button">4</a>
                            <a class="paginate_button">5</a>
                            <a class="paginate_button">6</a>
                            <a class="paginate_button">7</a>
                            <a class="paginate_button">8</a>
                            <a class="paginate_button">9</a>
                        </span>
                        <a class="paginate_button next">Suivant</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
