import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { UserService } from '../../../services/user.service';
import { GlobalVariable } from '../../../global';

@Component({
    selector: 'app-autorisations-candidats',
    templateUrl: './autorisations.component.html',
    styleUrls: [
        './autorisations.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class AutorisationsComponent implements OnInit {
    arrowLeft = faArrowLeft;
    offerTypes = GlobalVariable.OFFER_TYPES;

    candidat: any = {
        password: '',
    };
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private userService: UserService,
        private toastrService: ToastService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.candidat._id = localStorage.getItem('id');
        this.getAccount();
    }
    getAccount(): void {
        this.userService.getAccount().then((response: any) => {
            if (response.status) {
                this.candidat = response.candidat;
                if (this.candidat.sendOfferType === '' && this.candidat.sendOffer) {
                    this.candidat.sendOfferType = 'mail';
                }
            } else {
                this.toastrService.error('Erreur lors de la récupération des informations', '', { opacity: 1 });
            }
        });
    }
    sendAutorisation(): void {
        this.httpClient
            .patch(
                this.globalService.url + '/candidat/autorisation',
                {
                    id: this.candidat._id,
                    autorisationAccueil: this.candidat.autorisationAccueil,
                    etreDansCVTech: this.candidat.etreDansCVTech,
                    photoProfil: this.candidat.photoProfil,
                    sendOffer: this.candidat.sendOffer,
                    sendOfferType: this.candidat.sendOfferType,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                        this.getAccount();
                    } else {
                        this.toastrService.info('Autorisations modifiées', '', {
                            opacity: 1,
                        });
                    }
                },
                () => {}
            );
    }

    desinscrire(): void {
        this.httpClient
            .patch<any>(this.globalService.url + '/candidat/status', { id: this.candidat._id, status: false, desinscription: true }, {})
            .subscribe(
                (response) => {
                    if (response.status) {
                        localStorage.clear();
                        this.router.navigate(['/']);
                    } else {
                        this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                    }
                },
                () => {}
            );
    }
}
