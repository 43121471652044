import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { from } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    async canActivate(): Promise<any> {
        if (this.authService.loggedIn() && (await this.authService.isUserEnabled())) {
            return true;
        } else {
            this.router.navigate(['/']);
            return false;
        }
    }
}
