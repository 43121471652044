<div class="header" style="background-color: #f8f7f2; height: 125px">
    <div class="logo_holder">
        <a href="index.html"><img class="logo_for_dark_bg" src="./assets/img/logo-hd.png" alt="Gravity" style="max-width: 200px" /></a>
    </div>
</div>
<div class="row light_bg full-height align-items-center d-flex contact-1" style="width: 100%; margin: 0">
    <div class="container">
        <div class="vc_column-inner">
            <div class="row reverseOrder">
                <div class="img-mobile">
                    <img src="./assets/img/404.jpg" style="width: 30%;border-radius: 50%" />
                </div>
                <div class="col-md-6 c-vertical">
                    <h2>Cette page n'existe pas</h2>
                    <p>
                        La page que vous recherchez semble introuvable. Sans détour, il n'est point de voyage... Retournez à la page
                        d'accueil.
                    </p>
                    <button routerLink="/" class="btn" style="background: rgb(228, 167, 0); color: white; margin: auto">
                        Retour à l'accueil
                    </button>
                </div>
                <div class="col-md-6 img-web">
                    <img src="./assets/img/404.jpg" style="width: 30%;border-radius: 50%" />
                </div>
            </div>
        </div>
    </div>
</div>
