import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class GlobalService {
    accounts = [];
    url = environment.apiUrl;
    domain = environment.domain;
    envType = environment.type;

    constructor() {}
    hideProgressBarHttp(): HttpHeaders {
        return new HttpHeaders({
            ignoreLoadingBar: '',
        });
    }
}
