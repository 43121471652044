import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { MdbTableDirective, MdbTablePaginationComponent } from '../../../components/mdb/free/tables';
import { Observable, Subject } from 'rxjs';
import { GlobalVariable } from '../../../global';
import { faSearch, faTimes, faFileContract, faPlus, faSort } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { map, startWith } from 'rxjs/operators';
import Utils from '../../../utils';
import { CitySelectComponent } from '../../../components/selects/city-select/city-select.component';
import { CandidatFormComponent } from '../../../components/modals/candidat-form/candidat-form.component';
import { SecteurFilterComponent } from '../../../components/filters/secteur/secteur.component';

@Component({
    selector: 'app-cv-theque',
    templateUrl: './cv-theque.component.html',
    styleUrls: [
        './cv-theque.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class CvThequeComponent implements OnInit, AfterViewInit {
    @ViewChild(MdbTablePaginationComponent, { static: true })
    mdbTablePagination!: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true })
    mdbTable!: MdbTableDirective;
    @ViewChild('candidatModal', { static: true })
    candidatModal!: CandidatFormComponent;
    @ViewChild('searchCity') searchCityComponent!: CitySelectComponent;
    @ViewChild('searchSecteurs') searchSecteursComponent!: SecteurFilterComponent;

    isMobile = false;

    faSearch = faSearch;
    faTimes = faTimes;
    faPlus = faPlus;
    faSort = faSort;
    faFileContract = faFileContract;

    id: string | null = '';
    recherche = {
        motCles: '',
        adresse: '',
        latitude: 0,
        longitude: 0,
        ville: '',
        pays: '',
        paysCode: '',
        experience: [],
        fonction: '',
        fonctionText: '',
        secteurs: [],
        situation: '',
        niveauEtude: [],
    };
    min = 5;
    max = 200;
    rayon = 5;
    addrs: any = [];
    addr = '';
    ville = '';
    pays: any = [];
    actuel = {
        paysCache: '',
    };
    metiers = [
        {
            codeOgr: '',
            groupePrincipal: '',
            groupeSecondaire: '',
            nomMetier: '',
        },
    ];
    secteurs: any = [
        {
            nom: '',
            codeNaf: '',
        },
    ];
    nombreTotal = 0;

    candidats: any = [];
    allCandidats: any = [];

    rechercheBase = true;

    searchPays = new Subject<string>();
    filteredOptions: Observable<any[]>;
    listePays = [
        {
            pays: '',
            code: '',
        },
    ];

    searchMetiers = new Subject<string>();
    resultsMetiers: Observable<any> | undefined;
    dataMetiers: any = [];

    searchSecteur = new Subject<string>();
    resultsSecteurs: Observable<any> | undefined;
    dataSecteurs: any = [];

    headElements = ["Niveau d'études", 'Expérience globale', 'Fonction actuellement occupée', "Secteur d'activité", 'Situation', 'Fiche'];

    previous: any = [];

    displayByPages = GlobalVariable.PAGES;

    typesEtudes = GlobalVariable.ETUDES;
    typesSituations = GlobalVariable.SITUATIONS;

    typesExperiencesSecteurs = GlobalVariable.EXPERIENCES_SECTEURS;

    candidat: any = {};
    url = '';

    canLoadMore = true;
    loadNumber = 12;

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private cdRef: ChangeDetectorRef,
        private deviceService: DeviceDetectorService
    ) {
        this.filteredOptions = this.searchPays.pipe(
            startWith(''),
            map((value: string) => Utils.filter(value, this.listePays))
        );
        this.httpClient.get(this.globalService.url + '/metiers').subscribe((data: any) => {
            this.dataMetiers = data;
            this.resultsMetiers = this.searchMetiers.pipe(
                startWith(''),
                map((value: string) => Utils.filterMetier(value, this.dataMetiers))
            );
        });
        this.httpClient.get(this.globalService.url + '/secteurs').subscribe((data: any) => {
            this.dataSecteurs = data;
            this.resultsSecteurs = this.searchSecteur.pipe(
                startWith(''),
                map((value: string) => Utils.filterSecteur(value, this.dataSecteurs))
            );
        });
    }
    ngOnInit(): void {
        this.id = localStorage.getItem('id');
        this.getPays();
        this.url = this.globalService.url;

        this.isMobile = this.deviceService.isMobile();

        if (this.route.snapshot.queryParams.filter !== undefined && this.route.snapshot.queryParams.filter !== null) {
            if (this.route.snapshot.queryParams.filter === 'active-search') {
                this.recherche.situation = 'active';
                this.getCandidat();
            }
        }

        this.getCandidat();
    }
    ngAfterViewInit(): void {
        if (!this.isMobile) {
            this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

            this.mdbTablePagination.calculateFirstItemIndex();
            this.mdbTablePagination.calculateLastItemIndex();
            this.cdRef.detectChanges();
        }
    }
    changeItemsByPage(event: any): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(event);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
    }
    reinitiliaser(): void {
        this.searchSecteur.next('');
        this.rayon = 5;
        this.searchCityComponent.clearField();
        this.searchPays.next('');
        this.searchMetiers.next('');
        this.searchSecteursComponent.unselectAll();

        this.recherche = {
            motCles: '',
            adresse: '',
            latitude: 0,
            longitude: 0,
            ville: '',
            pays: '',
            paysCode: '',
            experience: [],
            fonction: '',
            fonctionText: '',
            secteurs: [],
            situation: '',
            niveauEtude: [],
        };
        this.getCandidat();
    }
    removeItems(arr: void[], item: number): void[] {
        for (let i = 0; i < item; i++) {
            arr.shift();
        }
        return arr;
    }
    loadMore(): void {
        let concat = [];
        if (this.allCandidats.length >= this.loadNumber) {
            concat = this.candidats.concat(this.allCandidats.slice(0, this.loadNumber));
            this.allCandidats = this.removeItems(this.allCandidats, this.loadNumber);
            if (this.allCandidats.length === 0) {
                this.canLoadMore = false;
            }
        } else {
            concat = this.candidats.concat(this.allCandidats.slice(0, this.allCandidats.length));
            this.allCandidats = this.removeItems(this.allCandidats, this.loadNumber);
            this.canLoadMore = false;
        }
        this.candidats = concat;
    }
    rechercheNull(): boolean {
        if (
            this.recherche.motCles === '' &&
            this.recherche.adresse === '' &&
            this.recherche.latitude === 0 &&
            this.recherche.longitude === 0 &&
            this.recherche.ville === '' &&
            this.recherche.pays === '' &&
            this.recherche.paysCode === '' &&
            this.recherche.experience === [] &&
            this.recherche.fonction === '' &&
            this.recherche.secteurs === [] &&
            this.recherche.situation === '' &&
            this.recherche.niveauEtude === []
        ) {
            return true;
        }
        return false;
    }
    chooseMetiers(codeOgr: string, nomMetier: string): void {
        this.recherche.fonction = codeOgr;
        this.recherche.fonctionText = nomMetier;
        this.getCandidat();
    }

    clearMetier(): void {
        this.searchMetiers.next('');
        this.recherche.fonction = '';
        this.recherche.fonctionText = '';
        this.getCandidat();
    }
    clearSecteur(): void {
        this.searchSecteur.next('');
        this.recherche.secteurs = [];
        this.getCandidat();
    }
    clearCity(): void {
        this.recherche.ville = '';
        this.recherche.latitude = 0;
        this.recherche.longitude = 0;
        this.recherche.adresse = '';
        this.getCandidat();
    }
    getPaysMobilite(): void {
        this.httpClient.post<any>(this.globalService.url + '/pays/getPays', { text: this.recherche.pays }, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.pays = [];
                    for (const n of response.pays) {
                        this.pays.push(n);
                    }
                }
            },
            () => {}
        );
    }
    choosePays(pays: string, code: string): void {
        this.recherche.adresse = '';
        this.recherche.latitude = 0;
        this.recherche.longitude = 0;
        this.recherche.ville = '';
        this.recherche.pays = pays;
        this.recherche.paysCode = code;
        this.getCandidat();
    }
    display(): string {
        if (this.rechercheBase || this.isMobile) {
            return 'none';
        }
        return 'table';
    }
    getCandidat(): void {
        this.rechercheBase = true;
        this.httpClient
            .post<any>(
                this.globalService.url + '/candidat/cvtheque',
                {
                    page: 0,
                    nbParPage: 10000000,
                    motCles: this.recherche.motCles,
                    latitude: this.recherche.latitude,
                    longitude: this.recherche.longitude,
                    pays: this.recherche.pays,
                    experience: this.recherche.experience,
                    fonction: this.recherche.fonction,
                    fonctionText: this.recherche.fonctionText,
                    secteurs: this.recherche.secteurs,
                    situation: this.recherche.situation,
                    niveauEtude: this.recherche.niveauEtude,
                    rayon: this.rayon,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.nombreTotal = response.nb;
                        if (response.candidats.length >= this.loadNumber && this.isMobile) {
                            this.allCandidats = response.candidats;
                            this.candidats = this.allCandidats.slice(0, this.loadNumber);
                            this.allCandidats = this.removeItems(this.allCandidats, this.loadNumber);
                            if (this.allCandidats.length === 0) {
                                this.canLoadMore = false;
                            }
                        } else {
                            this.candidats = response.candidats;
                            this.canLoadMore = false;
                        }
                        this.mdbTable.setDataSource(this.candidats);
                        this.candidats = this.mdbTable.getDataSource();
                        this.previous = this.mdbTable.getDataSource();
                    } else {
                        alert('Erreur');
                    }
                    this.rechercheBase = false;
                },
                () => {
                    this.rechercheBase = false;
                }
            );
    }

    getPays(): void {
        this.httpClient.get<any>(this.globalService.url + '/pays/getPays').subscribe(
            (response) => {
                if (response.status) {
                    this.listePays = response.pays;
                    this.searchPays.next('');
                }
            },
            () => {}
        );
    }
    loadCandidat(id: any): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/' + id).subscribe(
            (response) => {
                if (response.status) {
                    this.candidat = response.candidat;
                    if (this.candidat.photo !== '') {
                        this.candidat.photo = this.globalService.url + '/images/candidat/' + this.candidat.photo;
                    }
                } else {
                    alert('Erreur');
                }
            },
            () => {}
        );
        this.candidatModal.openForm();
    }
}
