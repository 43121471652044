<div style='padding: 0.5rem'>
<div class='d-flex align-items-center tree-group'>
  <button type='button' class='tree-toggler' (click)='toggled = !toggled; $event.stopPropagation()'>
    <fa-icon [icon]='toggled ? faArrowUp : faArrowDown'></fa-icon>
  </button>
  <span
    class="mdb-option-text ml-2 mr-2" style='  color: #9e9e9e;'
  >{{ label }}</span
  >
  <span class="mdb-option-checkbox-wrapper ml-1" (click)='switchSelected()'>
  <input type="checkbox" [checked]="selected" class="form-check-input mdb-option-checkbox" />
  <label class="mdb-option-checkbox-label"></label>
</span>
</div>

<span [hidden]='!toggled' [class]="toggled ? 'animate__animated animate__fadeIn animate__fast' : 'animate__animated animate__fadeOut animate__fast'">
  <ng-content select="mdb-select-option"></ng-content>
</span>
</div>
