<!-- Line 27: Deleted (focus)="onFocusInput($event)" for better use in Firefox. If other strange problems will occur, please paste it in line 27. -->
<div
  class="mydp picker"
  [ngClass]="{ 'picker--opened': showSelector }"
  [ngStyle]="{ width: opts.width }"
  *ngIf="!inline"
>
  <div
    class="md-form"
    [ngClass]="{
      'md-outline': outlineInput,
      'd-flex align-items-center justify-content-center': opts.inputIcon
    }"
  >
    <input
      [id]="id"
      type="text"
      class="form-control mydp-date"
      [readonly]="!opts.editableDateField"
      [attr.aria-label]="opts.ariaLabelInputField"
      [attr.maxlength]="opts.dateFormat.length"
      [ngClass]="{
        selectiondisabled: opts.componentDisabled,
        disabled: opts.componentDisabled
      }"
      placeholder="{{ placeholder }}"
      [ngModel]="selectionDayTxt"
      (ngModelChange)="onUserDateInput($event)"
      [value]="selectionDayTxt"
      [ngStyle]="{
        'font-size': opts.selectionTxtFontSize
      }"
      (blur)="onBlurInput($event)"
      (focus)="onFocusInput($event)"
      [disabled]="opts.componentDisabled || isDisabled"
      autocomplete="off"
      [tabindex]="tabIndex"
    />
    <label
      [for]="id"
      (click)="openBtnClicked()"
      *ngIf="label.length > 0"
      [ngClass]="{
        active: checkActive(),
        disabled: opts.componentDisabled
      }"
      >{{ label }}</label
    >
    <i
      *ngIf="opts.inputIcon"
      [ngClass]="inlineIcon"
      class="datepicker-icon"
      (click)="openBtnClicked()"
    ></i>
  </div>
  <div
    *ngIf="showSelector"
    class="selector picker__holder selectorarrow selectorarrowleft selectorarrowright"
    #divFocus
    [ngClass]="{ alignselectorright: opts.alignSelectorRight }"
    tabindex="0"
  >
    <div class="picker__frame picker__box" #pickerFrame>
      <div class="picker__header">
        <div class="picker__date-display">
          <div class="picker__weekday-display">
            {{ weekText(getWeekday(tmp)) }}
          </div>
          <div class="picker__day-display">
            <div>{{ tmp.day }}</div>
          </div>
          <div class="picker__month-display">
            <div>{{ monthText(tmp.month) }}</div>
          </div>
          <div class="picker__year-display">
            <div>{{ tmp.year }}</div>
          </div>
        </div>
        <select
          class="picker__select--year"
          [(ngModel)]="visibleMonth.year"
          (ngModelChange)="onUserYearInput($event)"
          role="menu"
          aria-label="Year selector"
        >
          <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </select>
        <select
          class="picker__select--month"
          [(ngModel)]="visibleMonth.monthTxt"
          (ngModelChange)="onUserMonthInput($event)"
          role="menu"
          aria-label="Month selector"
        >
          <option *ngFor="let month of months" [ngValue]="month.short">{{ month.label }}</option>
        </select>
        <a
          href="javascript:;"
          role="button"
          class="picker__nav--prev"
          data-nav="-1"
          aria-controls="date-picker-example_table"
          title="Previous month"
          (click)="prevMonth($event)"
          (keydown.enter)="prevMonth($event)"
          [ngClass]="{
            headerbtnenabled: !prevMonthDisabled,
            headerbtndisabled: prevMonthDisabled,
            'disabled grey-text': prevMonthDisabled
          }"
        ></a>
        <a
          role="button"
          href="javascript:;"
          class="picker__nav--next"
          data-nav="1"
          aria-controls="date-picker-example_table"
          title="Next month"
          (click)="nextMonth($event)"
          (keydown.enter)="nextMonth($event)"
          [ngClass]="{
            headerbtnenabled: !nextMonthDisabled,
            headerbtndisabled: nextMonthDisabled,
            'disabled grey-text': nextMonthDisabled
          }"
        ></a>
      </div>
      <table class="picker__table">
        <thead>
          <tr>
            <th
              class="picker__weekday weekdaytitleweeknbr"
              *ngIf="opts.showWeekNumbers && opts.firstDayOfWeek === 'mo'"
            >
              #
            </th>
            <th class="picker__weekday" scope="col" *ngFor="let d of weekDays">{{ d }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let w of dates">
            <td
              class="picker__day daycellweeknbr"
              *ngIf="opts.showWeekNumbers && opts.firstDayOfWeek === 'mo'"
            >
              {{ w.weekNbr }}
            </td>
            <td
              class="picker__day"
              *ngFor="let d of w.week"
              [ngClass]="{
                'picker__day--infocus': d.cmo === currMonthId && !d.disabled,
                disabled: d.disabled,
                tablesingleday: d.cmo === currMonthId && !d.disabled
              }"
            >
              <div
                *ngIf="d.markedDate.marked && d.cmo !== nextMonthId && d.cmo !== prevMonthId"
                class="markdate"
                [ngStyle]="{ 'background-color': d.markedDate.color }"
              ></div>
              <div
                class="picker__day"
                [ngClass]="{
                  'picker__day--infocus': d.cmo === currMonthId,
                  'picker__day--outfocus': d.cmo === nextMonthId || d.cmo === prevMonthId,
                  'picker__day--today': d.currDay && opts.markCurrentDay,
                  'picker__day--selected picker__day--highlighted':
                    selectedDate.day === d.dateObj.day &&
                    selectedDate.month === d.dateObj.month &&
                    selectedDate.year === d.dateObj.year &&
                    d.cmo === currMonthId
                }"
                (click)="!d.disabled && cellClicked(d); $event.stopPropagation()"
                (keydown)="cellKeyDown($event, d)"
                tabindex="0"
              >
                {{ d.dateObj.day }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="picker__footer">
        <button
          type="button"
          *ngIf="opts.showTodayBtn"
          class="picker__button--today"
          (click)="todayClicked()"
          role="button"
          [attr.aria-label]="opts.todayBtnTxt"
        >
          {{ opts.todayBtnTxt }}
        </button>
        <button
          type="button"
          *ngIf="opts.showClearDateBtn"
          class="picker__button--clear"
          (click)="removeBtnClicked()"
          role="button"
          [attr.aria-label]="opts.clearBtnTxt"
        >
          {{ opts.clearBtnTxt }}
        </button>
        <button
          type="button"
          [ngClass]="{ 'ml-auto': !opts.showTodayBtn }"
          class="picker__button--close"
          (click)="closeBtnClicked()"
          role="button"
          [attr.aria-label]="opts.closeBtnTxt"
        >
          {{ opts.closeBtnTxt }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="md-form my-0 d-flex align-items-center justify-content-center"
  *ngIf="inline"
  [ngClass]="{ 'md-outline': outlineInput }"
>
  <input
    #inlineInput
    [id]="id"
    type="text"
    class="form-control mydp-date"
    [readonly]="!opts.editableDateField"
    [attr.aria-label]="opts.ariaLabelInputField"
    [attr.maxlength]="opts.dateFormat.length"
    [ngClass]="{
      selectiondisabled: opts.componentDisabled,
      disabled: opts.componentDisabled
    }"
    placeholder="{{ placeholder }}"
    [ngModel]="selectionDayTxt"
    (ngModelChange)="onUserDateInput($event)"
    [value]="selectionDayTxt"
    [ngStyle]="{
      'font-size': opts.selectionTxtFontSize
    }"
    (focus)="onFocusInput($event)"
    (blur)="onBlurInput($event)"
    [disabled]="opts.componentDisabled || isDisabled"
    autocomplete="off"
    [tabindex]="tabIndex"
  />
  <label
    [for]="id"
    (click)="openBtnClicked()"
    #inlineLabel
    *ngIf="label.length > 0"
    [ngClass]="{
      active: checkActive(),
      disabled: opts.componentDisabled
    }"
    >{{ label }}</label
  >
  <i
    *ngIf="opts.inlineInputIcon"
    [ngClass]="inlineIcon"
    #inlineIconToggle
    class="datepicker-icon datepicker-inline-icon"
    (click)="toggleInlineDatePicker()"
  ></i>
</div>
<ng-template #inlineTemplate>
  <div
    class="mydp picker datepicker-inline"
    [ngClass]="{ 'picker--opened': showSelector }"
    *ngIf="inline && isOpen"
  >
    <div class="picker__frame picker__box z-depth-1" #pickerFrame [ngClass]="{ 'd-none': !isOpen }">
      <div class="picker__header d-flex flex-center">
        <select
          class="picker__select--year"
          [(ngModel)]="visibleMonth.year"
          (ngModelChange)="onUserYearInput($event)"
          role="menu"
          aria-label="Year selector"
        >
          <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </select>
        <select
          class="picker__select--month"
          [(ngModel)]="visibleMonth.monthTxt"
          (ngModelChange)="onUserMonthInput($event)"
          role="menu"
          aria-label="Month selector"
        >
          <option *ngFor="let month of months" [ngValue]="month.short">{{ month.label }}</option>
        </select>
        <a
          href="javascript:;"
          role="button"
          class="picker__nav--prev"
          data-nav="-1"
          aria-controls="date-picker-example_table"
          title="Previous month"
          (click)="prevMonth($event)"
          (keydown.enter)="prevMonth($event)"
          [ngClass]="{
            headerbtnenabled: !prevMonthDisabled,
            headerbtndisabled: prevMonthDisabled,
            'disabled grey-text': prevMonthDisabled
          }"
        ></a>
        <a
          href="javascript:;"
          role="button"
          class="picker__nav--next"
          data-nav="1"
          aria-controls="date-picker-example_table"
          title="Next month"
          (click)="nextMonth($event)"
          (keydown.enter)="nextMonth($event)"
          [ngClass]="{
            headerbtnenabled: !nextMonthDisabled,
            headerbtndisabled: nextMonthDisabled,
            'disabled grey-text': nextMonthDisabled
          }"
        ></a>
      </div>
      <table class="picker__table">
        <thead>
          <tr>
            <th
              class="picker__weekday weekdaytitleweeknbr"
              *ngIf="opts.showWeekNumbers && opts.firstDayOfWeek === 'mo'"
            >
              #
            </th>
            <th class="picker__weekday" scope="col" *ngFor="let d of weekDays">{{ d }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let w of dates">
            <td
              class="picker__day daycellweeknbr"
              *ngIf="opts.showWeekNumbers && opts.firstDayOfWeek === 'mo'"
            >
              {{ w.weekNbr }}
            </td>
            <td
              class="picker__day"
              *ngFor="let d of w.week"
              [ngClass]="{
                'picker__day--infocus': d.cmo === currMonthId && !d.disabled,
                disabled: d.disabled,
                tablesingleday: d.cmo === currMonthId && !d.disabled
              }"
            >
              <div
                *ngIf="d.markedDate.marked && d.cmo !== nextMonthId && d.cmo !== prevMonthId"
                class="markdate"
                [ngStyle]="{ 'background-color': d.markedDate.color }"
              ></div>
              <div
                class="picker__day"
                [ngClass]="{
                  'picker__day--infocus': d.cmo === currMonthId,
                  'picker__day--outfocus': d.cmo === nextMonthId || d.cmo === prevMonthId,
                  'picker__day--today': d.currDay && opts.markCurrentDay,
                  'picker__day--selected picker__day--highlighted':
                    selectedDate.day === d.dateObj.day &&
                    selectedDate.month === d.dateObj.month &&
                    selectedDate.year === d.dateObj.year &&
                    d.cmo === currMonthId
                }"
                (click)="!d.disabled && cellClicked(d); $event.stopPropagation()"
                (keydown)="cellKeyDown($event, d)"
                tabindex="0"
              >
                {{ d.dateObj.day }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="picker__footer">
        <button
          type="button"
          *ngIf="opts.showTodayBtn"
          class="picker__button--today"
          (click)="todayClicked()"
          role="button"
          [attr.aria-label]="opts.todayBtnTxt"
        >
          {{ opts.todayBtnTxt }}
        </button>
        <button
          type="button"
          *ngIf="opts.showClearDateBtn"
          class="picker__button--clear"
          (click)="removeBtnClicked()"
          role="button"
          [attr.aria-label]="opts.clearBtnTxt"
        >
          {{ opts.clearBtnTxt }}
        </button>
        <button
          type="button"
          [ngClass]="{ 'ml-auto': !opts.showTodayBtn }"
          class="picker__button--close"
          (click)="closeBtnClicked()"
          role="button"
          [attr.aria-label]="opts.closeBtnTxt"
        >
          {{ opts.closeBtnTxt }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
