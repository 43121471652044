<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-recruteur-header></app-recruteur-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-recruteur/informations-personnelles" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-recruteur/informations-personnelles" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span style="margin-right: 5rem">Profils recherchés</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Profils recherchés</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Quels types de profils recherchez-vous actuellement ?</h2>
            </div>

            <div class="inscription-form">
                <h5 style="color: red !important">
                    Aucune information nominative, adresse, téléphone, email et site Internet ne doit être mentionné dans le champ "Profils
                    recherchés" sous peine de voir votre compte bloqué.
                </h5>
                <div class="form-group add_bottom_30">
                    <div class="inscription-row">
                      <app-poste class='d-flex flex-wrap' [(data)]='recruteur.metiersRechercher' [(recherche)]='recherche' [typeMetier]='"Profils recherchés"'>
                        <label for="metiersRechercher">Profil(s) recherché(s) <small>Ce champ est multiple</small></label>
                      </app-poste>

                    </div>

                    <div class="form-group inscription-row mt-2">
                        <label
                            for="profilRechercher"
                            [ngClass]="{
                                'fl-label-empty': this.recruteur.profilRechercher === '',
                                'fl-label-not-empty': this.recruteur.profilRechercher !== ''
                            }"
                            >Détailler en quelques mots le(s) profil(s) recherché(s)</label
                        >
                        <span
                            for="profilRechercher"
                            class="error"
                            [ngStyle]="{
                                display: this.error.profilRechercher ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <textarea
                            style="height: auto"
                            name="profilRechercher"
                            id="profilRechercher"
                            class="form-control"
                            [(ngModel)]="this.recruteur.profilRechercher"
                            rows="4"
                        ></textarea>
                    </div>
                    <div class="inscription-row">
                      <app-secteur class='d-flex flex-wrap' [showHelp]='true' [(data)]='recruteur.secteurActivites' [typeSecteur]='"Souhaités"'>
                        <label for="secteursRechercher">Secteur(s) d'activité recherché(s) <small>Ce champ est multiple</small></label>
                      </app-secteur>
                    </div>
                    <div class="inscription-row">
                        <label class="custom mb-3">Type(s) de poste proposé(s)</label>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="container_check"
                                        >Salarié
                                        <input type="checkbox" [(ngModel)]="poste.salarie" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="container_check"
                                        >Alternant
                                        <input type="checkbox" [(ngModel)]="poste.alternant" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="container_check"
                                        >Indépendant
                                        <input type="checkbox" [(ngModel)]="poste.independant" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="container_check"
                                        >Bénévole
                                        <input type="checkbox" [(ngModel)]="poste.benevole" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="container_check"
                                        >Stagiaire
                                        <input type="checkbox" [(ngModel)]="poste.stagiaire" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="container_check"
                                        >Autre
                                        <input type="checkbox" [(ngModel)]="poste.autre" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="inscription-row">
                        <label class="custom mb-3" *ngIf="afficherTypeContrat()">Type(s) de contrat proposé(s)</label>
                        <div class="row" *ngIf="afficherTypeContrat()">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="container_check"
                                        >CDD
                                        <input type="checkbox" [(ngModel)]="posteVoulu.cdd" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="container_check"
                                        >CDI
                                        <input type="checkbox" [(ngModel)]="posteVoulu.cdi" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <label class="custom mb-3 mt-3">Temps</label>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="container_check"
                                        >Complet
                                        <input type="checkbox" [(ngModel)]="temps.plein" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="container_check"
                                        >Partiel
                                        <input type="checkbox" [(ngModel)]="temps.partiel" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center" style="position: relative">
                <p class="mention-obligatoire" style="color: red !important">* Obligatoire</p>
            </div>
            <div class="d-flex float-right mb-3">
                <button mdbBtn class="text-center" [rounded]="true" color="warning" size="lg" (click)="sendRecherche()">Valider</button>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
