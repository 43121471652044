import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
import { debounceTime, map, startWith } from 'rxjs/operators';
import Utils from '../../../utils';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-select-city',
    templateUrl: './city-select.component.html',
    styleUrls: [
        './city-select.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class CitySelectComponent implements OnInit {
    @Input() ville: any;
    @Output() selected: EventEmitter<any> = new EventEmitter();
    @Output() clear: EventEmitter<any> = new EventEmitter();

    paysNonSelectionner = false;
    oldSearch = '';
    loading = false;
    addrs: any = [];
    searchCity = new Subject<string>();
    resultsCity: Observable<any> | undefined;
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService
    ) {
        this.resultsCity = this.searchCity.pipe().pipe(
            startWith(''),
            map((value: string) => Utils.filterCity(value, this.addrs)),
            debounceTime(500)
        );
    }
    ngOnInit(): void {}
    changeAddr(event: any): void {
        this.ville.ville = event;

        if (this.ville.pays !== '') {
            this.paysNonSelectionner = false;

            this.loading = true;
            setTimeout(() => {
                if (this.oldSearch !== this.ville.ville) {
                    this.oldSearch = this.ville.ville;
                    this.getAddrs();
                }
            }, 2000);
        } else {
            this.paysNonSelectionner = true;
        }
    }
    getAddrs(): void {
        let addr = this.ville.ville.split(' ').join('+');
        if (addr === '') {
            return;
        }
        let pays = this.ville.pays.length > 2 ? this.ville.paysCode : this.ville.pays;
        this.httpClient.get<any>(this.globalService.url + '/adresse/' + pays + '/' + addr, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.addrs = response.addrs;
                    this.searchCity.next(this.ville.ville);
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                }
            },
            () => {}
        );
    }
    chooseAddr(ville: string, latitude: number, longitude: number): void {
        this.ville.ville = ville;
        this.ville.villeSave = ville;
        this.ville.latitude = latitude.toString();
        this.ville.longitude = longitude.toString();

        this.emit(ville);

        this.addrs = [];
    }
    emit(event: any): void {
        this.selected.emit(event);
    }
    emitClear(): void {
        this.clear.emit();
    }
    clearField(): void {
        this.searchCity.next('');
    }
}
