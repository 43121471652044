<app-site-header page="mentions-legales"></app-site-header>
<div id="primary" class="content-area">
    <main id="main" class="site-main">
        <div
            id="contacts"
            style="width: 100%; margin: 0; padding-top: 80px; padding-bottom: 80px"
            class="row light_bg full-height align-items-center d-flex contact-1"
        >
            <div class="container">
                <div class="vc_column-inner">
                    <div class="row">
                        <div class="col-12">
                            <div class="vc_column-inner">
                                <div class="oi_vc_text item_height_x2 contact-1-1">
                                    <div class="oi_vc_text_span">
                                        <div class="oi_custom_heading_holder">
                                            <div class="oi_vc_heading oi_border_position_center">
                                                <h1 class="oi_icon_titile text-center pb-3">MENTIONS LÉGALES</h1>
                                                <h6 class="pb-3" style="text-align: center">Mise à jour : 09/11/2021</h6>
                                                <div class="mention-text">
                                                    <h2 style='font-size: 2rem'>Identification de l'éditeur et de l'hébergeur du site</h2>
                                                    Le site https://avenuedesrecruteurs.fr est édité par RECRUT'INNOV SARL, au capital de
                                                    10000 €, entreprise immatriculée au RCS de Paris sous le numéro 49892950400013, dont le
                                                    siège social est sis au 5 rue de l'Abbé de l'Epée, 75005 PARIS.<br /><br />
                                                    N° de TVA intracommunautaire : FR 51498929504.<br /><br />
                                                    Directeur de la publication : François AUER, Gérant de RECRUT-INNOV SARL, joignable à
                                                    l'adresse
                                                    <a href="mailto:contact@avenuedesrecruteurs.fr">contact@avenuedesrecruteurs.fr</a
                                                    >.<br /><br />
                                                    Le site est hébergé par INFOMANIAK, Rue Eugène-Marziano 25, 1227 Genève, Suisse. Les
                                                    informations concernant la collecte et le traitement des données personnelles (politique
                                                    et déclaration) sont fournies dans la charte de données personnelles du site.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
<app-site-footer></app-site-footer>
