import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { ToastService } from '../../mdb/pro/alerts';
import { faLinkedin, faYoutube, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { ValidateService } from '../../../services/validate.service';

@Component({
    selector: 'app-site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.css'],
})
export class SiteFooterComponent implements OnInit {
    faFacebook = faFacebook;
    faYoutube = faYoutube;
    faLinkedin = faLinkedin;
    emailNewsletters = '';
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private validateService: ValidateService,
        private router: Router,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {}
    addNewsletters(): void {
        if (this.validateService.validateEmail(this.emailNewsletters)) {
            this.httpClient
                .put<any>(this.globalService.url + '/newsletters', {
                    email: this.emailNewsletters,
                })
                .subscribe(
                    (response: any) => {
                        if (!response.status) {
                            this.toastrService.error("L'email est déjà dans notre base", '', { opacity: 1 });
                        } else {
                            this.toastrService.info('Merci pour votre inscription', '', { opacity: 1 });
                        }
                    },
                    () => {
                        this.toastrService.error('Erreur', '', { opacity: 1 });
                    }
                );
        } else {
            this.toastrService.error('Veuillez renseigner une adresse email correcte.', '', { opacity: 1 });
        }
    }
}
