import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../services/auth.service';
import { MdbSelectComponent } from '../../mdb/pro/select';

@Component({
    selector: 'app-filter-secteur',
    templateUrl: './secteur.component.html',
    styleUrls: ['./secteur.component.css', '../../../../assets/css/site/form.scss'],
})
export class SecteurFilterComponent implements OnInit {
    @ViewChild('select') select!: MdbSelectComponent;
    groupe = '';
    sousGroupe = '';
    prefixe = '';
    listeGroupe: any = [];
    listeSousGroupe: any = [];
    faFilePdf = faFilePdf;
    url = '';
    role = '';
    request = '';
    secteurs = [
        {
            name: '',
            options: [{ value: '', label: '', selected: false }],
            selected: false,
        },
    ];

    @Input() label = 'Choisir';

    @Input() data: any;
    @Output() dataChange = new EventEmitter<any>();

    @Output() refresh = new EventEmitter<any>();

    constructor(private httpClient: HttpClient, private globalService: GlobalService, private authService: AuthService) {}

    ngOnInit(): void {
        this.url = this.globalService.url;
        this.role = this.authService.role;
        this.getGroupe();
    }

    chooseSecteurs(nom: string, selectAll = false, selectAllValue = false): void {
        let exist = false;
        for (const s of this.data) {
            if (s === nom) {
                exist = true;
                break;
            }
        }
        if (!exist) {
            this.data.push(nom);
        } else if (!selectAll || (selectAll && !selectAllValue)) {
            const indexOfObject = this.data.findIndex((object: string) => {
                return object === nom;
            });
            this.data.splice(indexOfObject, 1);
        }
        if (!selectAll) {
            this.refresh.emit('');
        }
    }
    getGroupe(): void {
        this.listeGroupe = [];
        this.listeSousGroupe = [];
        this.httpClient
            .post<any>(this.globalService.url + '/secteurs/groupe', { groupe: this.groupe, sousGroupe: this.sousGroupe }, {})
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.secteurs = [];
                        const categoriesDones = [];
                        for (const g of response.liste) {
                            if (categoriesDones.indexOf(g.groupe) !== -1) {
                                continue;
                            }
                            const categorie = g.groupe;
                            const sousCategories = [];

                            for (const g2 of response.liste) {
                                if (g2.groupe === categorie) {
                                    sousCategories.push({
                                        value: g2.sousGroupe,
                                        label: g2.sousGroupe,
                                        selected: false,
                                    });
                                }
                            }

                            sousCategories.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

                            let i = sousCategories.length;
                            while (i--) {
                                if (sousCategories[i].label.includes('- autre')) {
                                    // @ts-ignore
                                    const backup = sousCategories[i];
                                    sousCategories.splice(i, 1);
                                    sousCategories.push(backup);
                                }
                            }

                            this.secteurs.push({
                                // @ts-ignore
                                name: categorie,
                                // @ts-ignore
                                options: sousCategories,
                                selected: false,
                            });
                            categoriesDones.push(categorie);
                        }
                    }
                },
                () => {}
            );
    }
    unselectAll(): void {
        this.select.handleSelectionClear();
        for (const secteur of this.secteurs) {
            secteur.selected = false;
        }
    }
    selectAll(select: any, secteur: any): void {
        const result = this.secteurs.filter((obj: any) => {
            return obj.name === secteur.name;
        })[0];
        for (let act of result.options) {
            this.chooseSecteurs(act.value, true, select);
            act.selected = select;
        }
        this.refresh.emit('');
    }
}
