<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Surveillance des photos / logos en attente</h3>
        </div>
        <div class="row">
            <div class="col-md-6">
                <button
                    class="btn"
                    (click)="getForCandidat()"
                    [ngClass]="{
                        'btn-success': typeCompte == 'candidat',
                        'btn-danger': typeCompte != 'candidat'
                    }"
                    [disabled]="disabledRecherche"
                >
                    Candidat ({{ nombrePhotos.candidat }})
                </button>
            </div>
            <div class="col-md-6">
                <button
                    class="btn"
                    (click)="getForRecruteur('')"
                    [ngClass]="{
                        'btn-success': typeCompte == 'recruteur',
                        'btn-danger': typeCompte != 'recruteur'
                    }"
                    [disabled]="disabledRecherche"
                >
                    Recruteur ({{ nombrePhotos.recruteurLogo + nombrePhotos.recruteurPhoto }})
                </button>
            </div>
        </div>
        <div style="margin-top: 10px" class="row" *ngIf="typeCompte == 'recruteur'">
            <div class="col-md-6">
                <button
                    class="btn"
                    (click)="getForRecruteur('photo')"
                    [ngClass]="{
                        'btn-success': sousTypeCompte == 'photo',
                        'btn-danger': sousTypeCompte != 'photo'
                    }"
                    [disabled]="disabledRecherche"
                >
                    Photo ({{ nombrePhotos.recruteurPhoto }})
                </button>
            </div>
            <div class="col-md-6">
                <button
                    class="btn"
                    (click)="getForRecruteur('logo')"
                    [ngClass]="{
                        'btn-success': sousTypeCompte == 'logo',
                        'btn-danger': sousTypeCompte != 'logo'
                    }"
                    [disabled]="disabledRecherche"
                >
                    Logo ({{ nombrePhotos.recruteurLogo }})
                </button>
            </div>
        </div>
        <div></div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-3" *ngFor="let p of photos">
                    <div class="row espace">
                        <div class="col-12">
                            <img src="{{ p.photo }}" />
                        </div>
                        <div class="col-12">
                            <button class="btn btn-success" (click)="validerPhoto(p._id)">Valider</button>
                        </div>
                        <div class="col-12">
                            <button (click)="supprimer(p._id, p.doc)" class="btn btn-danger">Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="photos.length !== 0">
                <div class="col-12">
                    <button class="btn btn-success" (click)="validerPage()">Valider toutes ces photos</button>
                </div>
            </div>
        </div>
    </div>
</div>
