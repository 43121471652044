import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-conseils',
    templateUrl: './conseils.component.html',
    styleUrls: ['./conseils.component.css', './../../../../assets/css/administration/space.min.css'],
})
export class ConseilsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
