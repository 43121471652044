<app-site-header></app-site-header>
<!-- Sign Up section HTML Start -->
<div
    mdbModal
    #verificationCode="mdbModal"
    class="modal fade top"
    id="verificationModalTop"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    [config]="{ backdrop: 'static', keyboard: false }"
    aria-hidden="true"
>
    <div class="modal-dialog modal-notify modal-warning" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header justify-content-center">
                <p class="heading lead">VERIFICATION REQUISE</p>
            </div>
            <!--Body-->
            <div class="modal-body">
                <div class="text-center">
                    <!--          <div class="animate__animated animate__rotateIn">-->
                    <!--            <fa-icon [icon]="faCheck" class="mb-3 fa-4x"></fa-icon>-->

                    <!--          </div>-->
                    <p>Merci de consulter votre messagerie et d'écrire ci-dessous le code envoyé à l'adresse {{ candidat.email }}.</p>
                    <code-input
                        [isCodeHidden]="false"
                        [codeLength]="5"
                        (codeChanged)="onCodeChanged($event)"
                        (codeCompleted)="onCodeCompleted($event)"
                    >
                    </code-input>

                    <a
                        type="button"
                        style="color: white"
                        (click)="sendMailVerificationEmail()"
                        mdbBtn
                        [rounded]="true"
                        color="warning"
                        class="waves-effect mt-4"
                        mdbWavesEffect
                        ><fa-icon class="mr-2" [icon]="faArrowCircleRight"></fa-icon>Renvoyer le code
                    </a>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" style="color: white" href="/" mdbBtn [rounded]="true" color="warning" class="waves-effect" mdbWavesEffect
                    >Retour à l'accueil
                </a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<section id="Sign-up">
    <div class="container-fluid">
        <div class="sign-up-top">
            <h2 *ngIf="this.candidat.etape === 0 && this.candidat._id !== ''" class="section-title">Mot de passe</h2>
            <h2 *ngIf="this.candidat.etape === 1" class="section-title">Vos informations</h2>
            <h2 *ngIf="this.candidat.etape === 2" class="section-title">Votre situation</h2>
            <h2 *ngIf="this.candidat.etape === 3" class="section-title">Informations complémentaires</h2>
            <h2 *ngIf="this.candidat.etape === 4" class="section-title">Votre recherche</h2>
            <h2 *ngIf="this.candidat.etape === 5" class="section-title">Vos autorisations</h2>
            <div class="signup-top-wrapper">
                <div class="steps-container text-center">
                    <div
                        [ngStyle]="{
                            display: (this.isMobile && this.candidat.etape < 1) || !this.isMobile ? 'inline-block' : 'none'
                        }"
                        [ngClass]="this.candidat.etape === 0 && this.candidat._id !== '' ? 'active' : ''"
                        class="steps"
                    >
                        <span
                            ><h1>1</h1>
                            <h6>Mot de passe</h6></span
                        >
                    </div>
                    <div
                        [ngStyle]="{
                            display:
                                (this.isMobile && ((this.candidat.etape == 0 && this.candidat._id !== '') || this.candidat.etape == 1)) ||
                                !this.isMobile
                                    ? 'inline-block'
                                    : 'none'
                        }"
                        [ngClass]="this.candidat.etape === 1 ? 'active' : ''"
                        class="steps"
                    >
                        <span
                            ><h1>2</h1>
                            <h6>Vos informations</h6></span
                        >
                    </div>
                    <div
                        [ngStyle]="{
                            display:
                                (this.isMobile && (this.candidat.etape == 2 || this.candidat.etape == 1)) || !this.isMobile
                                    ? 'inline-block'
                                    : 'none'
                        }"
                        [ngClass]="this.candidat.etape === 2 ? 'active' : ''"
                        class="steps"
                    >
                        <span
                            ><h1>3</h1>
                            <h6>Votre situation</h6></span
                        >
                    </div>
                    <div
                        [ngStyle]="{
                            display:
                                (this.isMobile && (this.candidat.etape == 3 || this.candidat.etape == 2)) || !this.isMobile
                                    ? 'inline-block'
                                    : 'none'
                        }"
                        [ngClass]="this.candidat.etape === 3 ? 'active' : ''"
                        class="steps"
                    >
                        <span
                            ><h1>4</h1>
                            <h6>
                                Informations <br />
                                complémentaires
                            </h6></span
                        >
                    </div>
                    <div
                        [ngStyle]="{
                            display:
                                (this.isMobile && (this.candidat.etape == 4 || this.candidat.etape == 3 || this.candidat.etape == 5)) ||
                                !this.isMobile
                                    ? 'inline-block'
                                    : 'none'
                        }"
                        [ngClass]="this.candidat.etape === 4 ? 'active' : ''"
                        class="steps"
                    >
                        <span
                            ><h1>5</h1>
                            <h6>Votre recherche</h6></span
                        >
                    </div>
                    <div
                        [ngStyle]="{
                            display:
                                (this.isMobile && (this.candidat.etape == 5 || this.candidat.etape == 4)) || !this.isMobile
                                    ? 'inline-block'
                                    : 'none'
                        }"
                        [ngClass]="this.candidat.etape === 5 ? 'active' : ''"
                        class="steps"
                    >
                        <span
                            ><h1>6</h1>
                            <h6>Vos autorisations</h6></span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Sign Up section HTML End -->

<section>
    <div class="inscription-wrapper">
        <!-- STEP 2 -->

        <div
            class="row"
            [ngStyle]="{
                display: this.candidat.etape === 0 && this.candidat._id !== '' ? 'block' : 'none'
            }"
        >
            <div class="col-md-10">
                <div class="inscription-left">
                    <h5>Mot de passe</h5>
                    <div class="inscription-form">
                        <div class="form-group inscription-row col-12 col-md-6">
                            <label
                                for="password"
                                [ngClass]="{
                                    'fl-label-empty': this.candidat.password === '',
                                    'fl-label-not-empty': this.candidat.password !== ''
                                }"
                                >Mot de passe *</label
                            >
                            <span
                                for="password"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.password ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <input [type]="type1" name="password" id="password" class="form-control" [(ngModel)]="this.candidat.password" />
                            <fa-icon class="eye position-absolute" (click)="eye(1)" [icon]="eye1"></fa-icon>
                        </div>
                        <div class="form-group inscription-row col-12 col-md-6">
                            <label
                                for="passwordRenew"
                                [ngClass]="{
                                    'fl-label-empty': this.passwordDeux === '',
                                    'fl-label-not-empty': this.passwordDeux !== ''
                                }"
                                >Répéter le mot de passe *</label
                            >
                            <span
                                for="passwordRenew"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.passwordDeux ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <input
                                [type]="type2"
                                name="passwordRenew"
                                id="passwordRenew"
                                class="form-control"
                                [(ngModel)]="this.passwordDeux"
                            />
                            <fa-icon class="eye position-absolute" (click)="eye(2)" [icon]="eye2"></fa-icon>
                        </div>

                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.longueur ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.longueur"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.longueur"></fa-icon>
                            Contient 10 caractères.
                        </div>
                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.miniscule ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.miniscule"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.miniscule"></fa-icon>
                            Contient au moins une minuscule.
                        </div>
                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.majuscule ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.majuscule"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.majuscule"></fa-icon>
                            Contient au moins une majuscule.
                        </div>
                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.chiffre ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.chiffre"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.chiffre"></fa-icon>
                            Contient au moins un chiffre.
                        </div>
                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.caractereSpeciale ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.caractereSpeciale"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.caractereSpeciale"></fa-icon>
                            Contient au moins un caractère spécial (@ # & " ' ; . , ? : \ / !).
                        </div>
                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.identique ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.identique"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.identique"></fa-icon>
                            Les deux mots de passe sont identiques.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- END STEP 2 -->

        <!-- STEP 3 -->

        <div class="row" [ngStyle]="{ display: this.candidat.etape === 1 ? 'flex' : 'none' }">
            <div class="col-md-10">
                <div class="inscription-left">
                    <h5>Renseignez vos informations personnelles</h5>
                    <div class="inscription-form">
                        <h6 style="color: orange !important">Votre nom et votre prénom ne seront plus modifiables après cette étape</h6>

                        <div class="form-group d-flex justify-content-center">
                            <img
                                style="object-fit: cover; width: 100px; height: 100px"
                                class="img-preload rounded-circle img-fluid"
                                *ngIf="this.candidat.photo !== ''"
                                [src]="this.candidat.photo"
                            />
                        </div>
                        <div class="form-group d-flex justify-content-center">
                            <label for="photo"
                                >Photo d'identité<br /><small>(Fichier accepté: .png, .jpg - Taille maximum: 5Mo)</small></label
                            >

                            <div class="file-field md-form mt-0 ml-2">
                                <div mdbBtn [rounded]="true" [outline]="true" color="secondary" size="md" mdbWavesEffect>
                                    <span class="pt-1">Choisir un fichier</span>
                                    <input type="file" name="photo" mdbFileSelect (change)="photo($event)" />
                                </div>
                                <span
                                    for="photo"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.photo ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >
                            </div>
                        </div>
                        <div class="d-flex inscription-row d-flex flex-wrap">
                            <div class="col-12 col-md-6 mb-4 mb-md-0 p-0" style="text-align: center; margin-top: auto">
                                <div class="form-group radio_input d-inline">
                                    <span
                                        for="gender"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.civilite ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <label class="container_radio mr-1"
                                        >Monsieur
                                        <input
                                            type="radio"
                                            name="gender"
                                            (click)="monsieur()"
                                            [checked]="candidat.civilite === 'Monsieur'"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="container_radio"
                                        >Madame
                                        <input type="radio" name="gender" (click)="madame()" [checked]="candidat.civilite === 'Madame'" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="container_radio ml-1"
                                        >Autre
                                        <input type="radio" name="gender" (click)="autre()" [checked]="candidat.civilite === 'Autre'" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label
                                        for="nom"
                                        [ngClass]="{
                                            'fl-label-empty': this.candidat.nom === '',
                                            'fl-label-not-empty': this.candidat.nom !== ''
                                        }"
                                        >Nom *</label
                                    >
                                    <span
                                        for="nom"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.nom ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <input type="text" name="nom" id="nom" class="form-control" [(ngModel)]="this.candidat.nom" />
                                </div>
                            </div>
                            <div class="col-12 offset-md-6 col-md-6">
                                <div class="form-group">
                                    <label
                                        for="prenom"
                                        [ngClass]="{
                                            'fl-label-empty': this.candidat.prenom === '',
                                            'fl-label-not-empty': this.candidat.prenom !== ''
                                        }"
                                        >Prénom *</label
                                    >
                                    <span
                                        for="prenom"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.prenom ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <input type="text" name="prenom" id="prenom" class="form-control" [(ngModel)]="this.candidat.prenom" />
                                </div>
                            </div>
                        </div>
                        <div class="inscription-row d-flex flex-wrap">
                            <div class="col-12 col-md-6">
                                <label for="nationalite">Nationalité(s) * <small>( Ce champ est multiple )</small></label>
                                <span
                                    for="nationalite"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.nationalites ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >
                                <div class="md-form">
                                    <input
                                        type="text"
                                        name="nationalite"
                                        class="completer-input form-control mdb-autocomplete mb-0"
                                        [ngModel]="searchNatio | async"
                                        (ngModelChange)="searchNatio.next($event)"
                                        [mdbAutoCompleter]="auto"
                                        placeholder="Rechercher"
                                    />
                                    <mdb-auto-completer class="visible" #auto="mdbAutoCompleter" textNoResults="Pas de résultat">
                                        <mdb-option
                                            *ngFor="let option of resultsNatio | async"
                                            [value]="option.nationalite"
                                            (click)="chooseNationalite(option.nationalite)"
                                        >
                                            <div class="d-flex flex-column">
                                                <strong>{{ option.nationalite }}</strong>
                                            </div>
                                        </mdb-option>
                                    </mdb-auto-completer>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <div style="position: relative" *ngFor="let n of candidat.nationalites">
                                        <label class="container_radio version_2" style="padding-left: 16px"
                                            >{{ n }}
                                            <input type="radio" name="positions" value="Backend-Developer" disabled />
                                        </label>
                                        <div class="container-cross" (click)="removeNationalite(n)">
                                            <div class="cross">x</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
<!--                        <div class="d-flex flex-wrap inscription-row">-->
<!--                            <div class="col-12 col-md-6">-->
<!--                                <label>Date de naissance *</label>-->
<!--                            </div>-->
<!--                            <div class="col-12 col-md-6">-->
<!--                                <div class="form-group">-->
<!--                                    <span-->
<!--                                        for="dateNaissance"-->
<!--                                        class="error"-->
<!--                                        [ngStyle]="{-->
<!--                                            display: this.error.dateNaissance ? 'block' : 'none'-->
<!--                                        }"-->
<!--                                        >Obligatoire</span-->
<!--                                    >-->
<!--                                  <app-datepicker [sel]='dob' [(date)]='candidat.dateNaissance'></app-datepicker>-->

<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="d-flex flex-wrap inscription-row">
                            <div class="col-12 col-md-6">
                                <label>Lieu de résidence *</label>
                            </div>
                            <div class="col-12 col-md-6">
                                <mdb-select-2
                                    [notFoundMsg]="'Pays non trouvé'"
                                    name="paysCode"
                                    [(ngModel)]="candidat.paysCode"
                                    (selected)="setPays($event)"
                                    [outline]="true"
                                >
                                    <mdb-select-filter [ngModel]="searchPays | async" (inputChange)="searchPays.next($event)">
                                    </mdb-select-filter>
                                    <mdb-select-option *ngFor="let option of filteredOptions | async" [value]="option.code">{{
                                        option.pays
                                    }}</mdb-select-option>
                                </mdb-select-2>
                            </div>
                        </div>
                        <div class="inscription-row d-flex flex-wrap">
                            <div class="col-12 col-md-6 mt-2">
                                <div class="form-group">
                                    <label
                                        for="adresse"
                                        [ngClass]="{
                                            'fl-label-empty': this.candidat.adresse === '',
                                            'fl-label-not-empty': this.candidat.adresse !== ''
                                        }"
                                        >Adresse *</label
                                    >
                                    <span
                                        for="adresse"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.adresse ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <app-select-adress [user]="candidat"></app-select-adress>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mt-2">
                                <div class="form-group">
                                    <label
                                        for="complementAdresse"
                                        [ngClass]="{
                                            'fl-label-empty': this.candidat.complementAdresse === '',
                                            'fl-label-not-empty': this.candidat.complementAdresse !== ''
                                        }"
                                        >Complément d'adresse</label
                                    >
                                    <input
                                        type="text"
                                        name="complementAdresse"
                                        id="complementAdresse"
                                        class="form-control"
                                        [(ngModel)]="this.candidat.complementAdresse"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mt-3">
                                <div class="form-group">
                                    <label
                                        for="codePostal"
                                        [ngClass]="{
                                            'fl-label-empty': this.candidat.codePostal === '',
                                            'fl-label-not-empty': this.candidat.codePostal !== ''
                                        }"
                                        >Code postal *</label
                                    >
                                    <span
                                        for="codePostal"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.codePostal ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <input
                                        type="text"
                                        name="codePostal"
                                        id="codePostal"
                                        class="form-control"
                                        [(ngModel)]="this.candidat.codePostal"
                                    />
                                </div>
                            </div>
                            <div class="col-6 mt-3">
                                <div class="form-group">
                                    <label
                                        for="ville"
                                        [ngClass]="{
                                            'fl-label-empty': this.candidat.ville === '',
                                            'fl-label-not-empty': this.candidat.ville !== ''
                                        }"
                                        >Ville *</label
                                    >
                                    <span
                                        for="ville"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.ville ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <input type="text" name="ville" id="ville" class="form-control" [(ngModel)]="this.candidat.ville" />
                                </div>
                            </div>
                        </div>

                        <div class="form-group inscription-row">
                            <label name="phone">Téléphone * (au moins un)</label>
                            <span
                                for="phone"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.telephonePortable ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <div class="row mt-2">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="telephonePortable">Téléphone Portable</label>
                                        <input
                                            type="tel"
                                            name="telephonePortable"
                                            id="telephonePortable"
                                            class="form-control"
                                            [(ngModel)]="this.candidat.telephonePortable"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="telephoneFixe">Téléphone Fixe</label>
                                        <input
                                            type="tel"
                                            name="telephoneFixe"
                                            id="telephoneFixe"
                                            class="form-control"
                                            [(ngModel)]="this.candidat.telephoneFixe"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 align-self-center">
                <div class="inscription-right"></div>
            </div>
        </div>

        <!-- END STEP 3 -->

        <!-- STEP 4 -->

        <div class="row" [ngStyle]="{ display: this.candidat.etape === 2 ? 'flex' : 'none' }">
            <div class="col-md-10">
                <div class="inscription-left">
                    <h5>Parlez nous de votre situation actuelle</h5>

                    <div class="d-flex flex-wrap inscription-row">
                        <div class="col-12 col-md-6">
                            <label for="fonction">Intitulé du poste actuellement ou dernièrement occupé *</label>
                        </div>
                        <div class="col-12 col-md-6">
                            <span
                                for="fonction"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.fonctionLibre ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <input type="text" [(ngModel)]="candidat.fonctionLibre" name="fonction" id="fonction" class="form-control" />
                        </div>
                    </div>

                    <div class="inscription-row">
                      <app-poste class='d-flex flex-wrap' [single]='true' [(data)]='candidat.fonction' [(recherche)]='actuel' [typeMetier]='"Fonction"'>
                        <label for="fonction">Métier s'en rapprochant le plus *</label>
                        <span
                          for="secteurActivite"
                          class="error"
                          [ngStyle]="{
                                        display: this.error.fonction ? 'block' : 'none'
                                    }"
                        >{{ errorMessage.metier }}</span
                        >
                      </app-poste>
                    </div>

                    <div class="inscription-row">

                      <app-secteur class='d-flex flex-wrap' [showHelp]='true' [(data)]='candidat.secteurActivite' [typeSecteur]='"Perso"'>
                        <label for="secteursRechercher">Secteur(s) d'activité * <small>Ce champ est multiple</small></label>
                        <span
                          for="secteursRechercher"
                          class="error"
                          [ngStyle]="{
                                    display: this.error.secteurActivite ? 'block' : 'none'
                                }"
                        >{{ errorMessage.secteur }}</span
                        >
                      </app-secteur>
                    </div>

                    <div class="d-flex flex-wrap inscription-row">
                        <div class="cold-12 col-md-4">
                            <label>Expérience dans ce métier *</label>
                            <span
                                for="secteurActivite"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.experienceSecteur ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <mdb-select-2
                                placeholder="Choisir.."
                                name="secteurActivite"
                                class="custom-selectbox"
                                [(ngModel)]="candidat.experienceSecteur"
                                [outline]="true"
                            >
                                <mdb-select-option *ngFor="let option of typesExperiencesSecteurs" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </div>

                        <div class="cold-12 col-md-4 mt-3 mt-md-0">
                            <label>Expérience globale *</label>
                            <span
                                for="secteurActivite"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.experience ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <mdb-select-2
                                placeholder="Choisir.."
                                name="typeEntreprise"
                                class="custom-selectbox"
                                [(ngModel)]="candidat.experience"
                                [outline]="true"
                            >
                                <mdb-select-option *ngFor="let option of typesExperiences" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </div>

                        <div class="cold-12 col-md-4 mt-3 mt-md-0">
                            <label>Niveau d'étude *</label>
                            <span
                                for="secteurActivite"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.niveauEtude ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <mdb-select-2
                                placeholder="Choisir.."
                                name="typeEntreprise"
                                class="custom-selectbox"
                                [(ngModel)]="candidat.niveauEtude"
                                [outline]="true"
                            >
                                <mdb-select-option *ngFor="let option of typesEtudes" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap inscription-row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="langue">Langues * <small>( Ce champ est multiple )</small></label>
                                <span
                                    for="secteurActivite"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.langues ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >

                                <div class="md-form">
                                    <input
                                        type="text"
                                        name="langue"
                                        class="completer-input form-control mdb-autocomplete mb-0"
                                        [ngModel]="searchLangues | async"
                                        (ngModelChange)="searchLangues.next($event)"
                                        [mdbAutoCompleter]="autoLangues"
                                        placeholder="Rechercher une langue"
                                    />
                                    <mdb-auto-completer class="visible" #autoLangues="mdbAutoCompleter" textNoResults="Pas de résultat">
                                        <mdb-option
                                            *ngFor="let option of resultsLangues | async"
                                            [value]="option.l"
                                            (click)="chooseLangues(option.langue)"
                                        >
                                            <div class="d-flex flex-column">
                                                <strong>{{ option.langue }}</strong>
                                            </div>
                                        </mdb-option>
                                    </mdb-auto-completer>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div style="position: relative" *ngFor="let l of candidat.langues">
                                <div style="position: relative">
                                    <label>{{ l.langue }}</label>
                                    <div class="form-group radio_input">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Parlé *</label>
                                                <div class="form-group">
                                                    <label class="container_radio mr-3" style="width: 100%"
                                                        >Langue maternelle
                                                        <input
                                                            type="radio"
                                                            name="parler_{{ l.langue }}"
                                                            value="maternelle"
                                                            [(ngModel)]="l.parle"
                                                            [checked]="l.parle === 'maternelle'"
                                                        />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label class="container_radio mr-3" style="width: 100%"
                                                        >Excellent
                                                        <input
                                                            type="radio"
                                                            name="parler_{{ l.langue }}"
                                                            value="excellent"
                                                            [(ngModel)]="l.parle"
                                                            [checked]="l.parle === 'excellent'"
                                                        />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label class="container_radio mr-3" style="width: 100%"
                                                        >Bon
                                                        <input
                                                            type="radio"
                                                            name="parler_{{ l.langue }}"
                                                            value="bon"
                                                            [(ngModel)]="l.parle"
                                                            [checked]="l.parle === 'bon'"
                                                        />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label class="container_radio mr-3" style="width: 100%"
                                                        >Scolaire
                                                        <input
                                                            type="radio"
                                                            name="parler_{{ l.langue }}"
                                                            value="scolaire"
                                                            [(ngModel)]="l.parle"
                                                            [checked]="l.parle === 'maternelle'"
                                                        />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label>Ecrit *</label>
                                                <div class="form-group">
                                                    <label class="container_radio mr-3" style="width: 100%"
                                                        >Langue maternelle
                                                        <input
                                                            type="radio"
                                                            name="ecrit_{{ l.langue }}"
                                                            value="maternelle"
                                                            [(ngModel)]="l.ecrit"
                                                            [checked]="l.ecrit === 'maternelle'"
                                                        />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label class="container_radio mr-3" style="width: 100%"
                                                        >Excellent
                                                        <input
                                                            type="radio"
                                                            name="ecrit_{{ l.langue }}"
                                                            value="excellent"
                                                            [(ngModel)]="l.ecrit"
                                                            [checked]="l.ecrit === 'excellent'"
                                                        />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label class="container_radio mr-3" style="width: 100%"
                                                        >Bon
                                                        <input
                                                            type="radio"
                                                            name="ecrit_{{ l.langue }}"
                                                            value="bon"
                                                            [(ngModel)]="l.ecrit"
                                                            [checked]="l.ecrit === 'bon'"
                                                        />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label class="container_radio mr-3" style="width: 100%"
                                                        >Scolaire
                                                        <input
                                                            type="radio"
                                                            name="ecrit_{{ l.langue }}"
                                                            value="scolaire"
                                                            [(ngModel)]="l.ecrit"
                                                            [checked]="l.ecrit === 'scolaire'"
                                                        />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container-cross" (click)="removeLangue(l.langue)">
                                        <div class="cross">x</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 align-self-center">
                <div class="inscription-right"></div>
            </div>
        </div>

        <!-- Step 5 -->

        <div class="row" [ngStyle]="{ display: this.candidat.etape === 3 ? 'flex' : 'none' }">
            <div class="col-md-10">
                <div class="inscription-left">
                    <h5>Renseignez quelques informations complémentaires</h5>
                    <div class="inscription-form">
                        <div class="inscription-row">
                            <label class="custom mb-3">Permis de conduire <small>( Ce champ est multiple )</small></label>
                            <div class="d-flex flex-wrap">
                                <div class="col-md-3">
                                    <label class="custom mb-3">Permis moto</label>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >AM
                                            <img src="assets/img/permis/am.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.am" [checked]="permis.am" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >A1
                                            <img src="assets/img/permis/a1.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.a1" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >A2
                                            <img src="assets/img/permis/a2.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.a2" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >A
                                            <img src="assets/img/permis/a.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.a" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label class="custom mb-3">Permis voiture</label>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >B
                                            <img src="assets/img/permis/b.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.b" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >B1
                                            <img src="assets/img/permis/b1.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.b1" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >BE
                                            <img src="assets/img/permis/be.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.be" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label class="custom mb-3">Permis poids lourds</label>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >C
                                            <img src="assets/img/permis/c.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.c" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >C1
                                            <img src="assets/img/permis/c1.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.c1" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >CE
                                            <img src="assets/img/permis/ce.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.ce" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >C1E
                                            <img src="assets/img/permis/c1e.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.c1e" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <label class="custom mb-3">Permis véhicule de transport en commun</label>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >D
                                            <img src="assets/img/permis/d.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.d" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >D1
                                            <img src="assets/img/permis/d1.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.d1" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >D1E
                                            <img src="assets/img/permis/d1e.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.d1e" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >DE
                                            <img src="assets/img/permis/de.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.de" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <hr />
                                </div>
                                <div class="col-md-3">
                                    <label class="custom mb-3">Permis bateau</label>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Bateau hauturier
                                            <img src="assets/img/permis/hauturier.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.hauturier" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Bateau côtier
                                            <img src="assets/img/permis/cotier.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.cotier" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Bateau fluvial
                                            <img src="assets/img/permis/fluvial.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.fluvial" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label class="custom mb-3">Brevet / licence pour avions et hélicoptères</label>

                                    <div class="form-group">
                                        <label class="container_check"
                                            >Vol PPL (H)
                                            <img src="assets/img/permis/ppl_h.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.ppl_h" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Vol LAPL (H)
                                            <img src="assets/img/permis/lapl_h.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.lapl_h" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Vol CPL (H)
                                            <img src="assets/img/permis/cpl_h.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.cpl_h" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Vol ATPL (H)
                                            <img src="assets/img/permis/atpl_h.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.atpl_h" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Vol ULM (H)
                                            <img src="assets/img/permis/ulm_h.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.ulm_h" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label class="custom mb-3">Brevet / licence pour avions et hélicoptères (Suite)</label>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >BB
                                            <img src="assets/img/permis/bb.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.bb" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >BIA
                                            <img src="assets/img/permis/bia.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.bia" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >PPL
                                            <img src="assets/img/permis/ppl.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.ppl" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >LAPL
                                            <img src="assets/img/permis/lapl.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.lapl" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >CPL
                                            <img src="assets/img/permis/cpl.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.cpl" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >ATPL
                                            <img src="assets/img/permis/atpl.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.atpl" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >ULM
                                            <img src="assets/img/permis/ulm.png" style="width: 40px" />
                                            <input type="checkbox" [(ngModel)]="permis.ulm" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="inscription-row">
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Etes-vous reconnu(e) en qualit&eacute; de travailleur/euse handicap&eacute;(e) ?</label>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group col-md-6">
                                        <label class="container_radio version_2 check"
                                            >Oui
                                            <input
                                                type="radio"
                                                name="handicape"
                                                [checked]="this.candidat.handicape"
                                                (click)="handicap(true)"
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group col-md-6">
                                        <label class="container_radio version_2 check"
                                            >Non
                                            <input
                                                type="radio"
                                                name="handicape"
                                                [checked]="!this.candidat.handicape"
                                                (click)="handicap(false)"
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div class="form-group inscription-row">
                        <div class="d-flex flex-wrap align-items-center">
                          <div class="col-4 col-md-4">
                            <span class="font-weight-bold">Importez votre CV (au format pdf)</span>
                          </div>
                          <div class="col-8 col-md-3">
                            <div *ngIf="candidat && candidat.cv && candidat.cv !== '' && candidat.cv.length < 100">
                              <a target="_blank" href="{{ url }}/documents/cv/{{ candidat.cv }}"
                              ><small>{{ candidat.cv }}</small> <fa-icon class="ml-2" [icon]="faFilePdf"></fa-icon
                              ></a>
                            </div>
                            <div *ngIf="candidat && candidat.cv && candidat.cv !== '' && candidat.cv.length >= 100">
                              <small>{{ candidat.cv }}</small> <fa-icon class="ml-2" [icon]="faFilePdf"></fa-icon
                            >
                            </div>
                          </div>
                          <div class="col-12 col-md-2 mt-3 mt-md-0">
                            <div class="file-field ml-3">
                              <div mdbBtn [rounded]="true" [outline]="false" color="secondary">
                                <span>{{ candidat.cv !== '' ? 'Modifier' : 'Ajouter' }}</span>
                                <input type="file" accept='.pdf' name="cvFile" mdbFileSelect (change)="cv($event)" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div class="form-group inscription-row">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <label>Quels sont vos atouts concurrentiels (pourquoi vous embaucher) ?</label>
                                </div>
                                <div class="col-12 col-md-6">
                                    <textarea
                                        placeholder="Exemple :&#10; 20 ans d’expérience en management d’équipes, de projets et de centres de projet en France et à l’international; Formation supérieure (MBA); Bilingue Français Anglais; Bon relationnel, pédagogue, dynamique, impliqué, adaptable, efficace"
                                        style="height: auto"
                                        rows="4"
                                        type="text"
                                        name="atoutConcurrenciel"
                                        id="atoutConcurrenciel"
                                        class="form-control"
                                        [(ngModel)]="this.candidat.atoutConcurrenciel"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                      <div class="form-group inscription-row">
                        <app-lettre-motivation-editor [(lettreMotivation)]='candidat.lettreMotivation'></app-lettre-motivation-editor>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 align-self-center">
                <div class="inscription-right"></div>
            </div>
        </div>

        <!-- STEP 6 -->

        <div class="row" [ngStyle]="{ display: this.candidat.etape === 4 ? 'flex' : 'none' }">
            <div class="col-md-10">
                <div class="inscription-left">
                    <h5>Votre recherche</h5>
                    <div class="inscription-form">
                        <div class="form-group inscription-row col-12 d-flex flex-wrap">
                            <div class="col-12 col-md-6">
                                <label>Etat *</label>
                                <span
                                    for="situation"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.situation ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >
                                <mdb-select-2
                                    placeholder="Choisir.."
                                    name="situation"
                                    class="custom-selectbox"
                                    [(ngModel)]="candidat.situation"
                                    [outline]="true"
                                >
                                    <mdb-select-option *ngFor="let option of typesSituations" [value]="option.value">{{
                                        option.label
                                    }}</mdb-select-option>
                                </mdb-select-2>
                            </div>
                          <div class="col-12 col-md-6 pt-4 pt-md-0">
                            <label>Télétravail souhaité *</label>
                            <span
                              for="teletravail"
                              class="error"
                              [ngStyle]="{
                                        display: this.error.teleTravail ? 'block' : 'none'
                                    }"
                            >Obligatoire</span
                            >
                            <mdb-select-2
                              placeholder="Choisir.."
                              name="teletravail"
                              class="custom-selectbox"
                              [(ngModel)]="candidat.teleTravail"
                              [outline]="true"
                            >
                              <mdb-select-option *ngFor="let option of typesTeletravail" [value]="option.value">{{
                                option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                          </div>
                        </div>
                        <div class="inscription-row">
                            <div class="form-group add_bottom_30">
                                <label>Disponibilité *</label>
                                <span
                                    for="disponibilite"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.disponibilite ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >
                                <div class="row pt-3">
                                    <div class="col-12 col-md-6">
                                      <div class='row'>
                                        <div class='col-4 col-md-4 font-weight-bold pr-0'>
                                          Je suis en poste
                                        </div>

                                        <div class="switch col-3 col-md-3">
                                          <label>
                                            <input [checked]="dispo.travail === '2'" type="checkbox" (change)="dispoTravail($event)" />
                                            <span class="lever"></span>
                                          </label>
                                        </div>
                                        <div class='col-5 col-md-4 font-weight-bold '>Je ne suis pas en poste</div>
                                      </div>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2 pt-md-0" *ngIf="dispo.travail == '1'">
                                      <div class='row'>
                                        <div class=' offset-md-2 col-4  col-md-4 font-weight-bold pr-0'>
                                          Je connais ma date de départ
                                        </div>

                                        <div class="switch col-3 col-md-2">
                                          <label>
                                            <input [checked]="dispo.preavis === '2'" type="checkbox" (change)="dispoPreavis($event)" />
                                            <span class="lever"></span>
                                          </label>
                                        </div>
                                        <div class='col-5 col-md-4 font-weight-bold '>Je ne connais pas ma date de départ</div>
                                      </div>

                                    </div>
                                    <div class="col-12 mt-3">
                                        <div
                                            class="styled-select clearfix col-12 col-md-6"
                                            *ngIf="dispo.travail == '1' && (dispo.preavis == '' || dispo.preavis == '2')"
                                        >
                                            Disponible sous (préavis) *
                                            <mdb-select-2
                                                placeholder="Choisir.."
                                                name="disponible"
                                                class="bottom-select"
                                                [(ngModel)]="candidat.disponibilite"
                                                [outline]="true"
                                            >
                                                <mdb-select-option *ngFor="let option of typesDispos" [value]="option.value">{{
                                                    option.label
                                                }}</mdb-select-option>
                                            </mdb-select-2>
                                        </div>
                                        <div
                                            class="styled-select clearfix col-12 col-md-6"
                                            *ngIf="dispo.travail == '2' || dispo.preavis == '1'"
                                        >
                                            Disponible dès le *
                                            <div *ngIf="dateDisponibiliteHistorique !== ''">
                                                {{ dateDisponibiliteHistorique | date: 'dd-MM-yyyy' }}
                                            </div>
                                            <span
                                                for="dateDisponibilite"
                                                class="error"
                                                [ngStyle]="{
                                                    display: this.error.disponibiliteDate ? 'block' : 'none'
                                                }"
                                                >Votre date de disponibilité ne peut pas être dans le passé ou vide</span
                                            >
                                          <app-datepicker [(date)]='candidat.dateDisponibilite'></app-datepicker>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="inscription-row">
                          <app-poste class='d-flex flex-wrap' [(data)]='candidat.posteRechercher' [(recherche)]='actuel' [typeMetier]='"Postes souhaités"'>
                            <label for="fonction">Poste(s) souhaité(s) * <small>Ce champ est multiple</small></label>
                            <span
                              for="fonction"
                              class="error"
                              [ngStyle]="{
                                        display: this.error.posteRechercher ? 'block' : 'none'
                                    }"
                            >Obligatoire</span
                            >
                          </app-poste>
                        </div>

                        <div class="inscription-row">
                          <app-secteur class='d-flex flex-wrap' [showHelp]='true' [(data)]='candidat.secteurRechercher' [typeSecteur]='"Souhaités"'>
                            <label for="secteursRechercher">Secteur(s) d'activité souhaité(s) <small>Ce champ est multiple</small></label>
                          </app-secteur>
                        </div>

                        <div class="inscription-row">
                            <label class="custom mb-3">Type(s) de poste recherché(s) <small>Ce champ est multiple</small></label>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Salarié
                                            <input type="checkbox" [(ngModel)]="poste.salarie" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Alternant
                                            <input type="checkbox" [(ngModel)]="poste.alternant" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Indépendant
                                            <input type="checkbox" [(ngModel)]="poste.independant" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Bénévole
                                            <input type="checkbox" [(ngModel)]="poste.benevole" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Stagiaire
                                            <input type="checkbox" [(ngModel)]="poste.stagiaire" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Autre
                                            <input type="checkbox" [(ngModel)]="poste.autre" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="inscription-row">
                            <label class="custom mb-3" *ngIf="afficherTypeContrat()">Type(s) de contrat recherché(s)</label>
                            <div class="row" *ngIf="afficherTypeContrat()">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="container_check"
                                            >CDD
                                            <input type="checkbox" [(ngModel)]="posteVoulu.cdd" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="container_check"
                                            >CDI
                                            <input type="checkbox" [(ngModel)]="posteVoulu.cdi" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <label class="custom mb-3 mt-3">Temps</label>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Complet
                                            <input type="checkbox" [(ngModel)]="temps.plein" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Partiel
                                            <input type="checkbox" [(ngModel)]="temps.partiel" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="inscription-row mb-3 form-group">
                            <label>Mobilité *</label>
                          <span
                            for="mobilite"
                            class="error"
                            [ngStyle]="{
                            display: this.error.mobilite ? 'block' : 'none'
                        }"
                          >Obligatoire</span
                          >
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Ville(s)
                                            <input type="checkbox" name="regional" [(ngModel)]="mobilite.regional" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Pays
                                            <input type="checkbox" name="national" [(ngModel)]="mobilite.national" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="container_check"
                                            >Tous les pays
                                            <input type="checkbox" name="international" [(ngModel)]="mobilite.international" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="mobilite.international">
                                <label style="color: green !important; font-size: 16px">Vous êtes disponible partout dans le monde</label>
                            </div>
                            <div *ngIf="mobilite.national" class="mt-2">
                                <label>Mobilité nationale : sélection des pays de mobilité</label>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <mdb-select-2
                                            [(ngModel)]="actuel.pays"
                                            placeholder="Pays de mobilité *"
                                            [notFoundMsg]="'Pays non trouvé'"
                                            name="mobilitePays"
                                            (selected)="choosePays($event)"
                                            [outline]="true"
                                        >
                                            <mdb-select-filter [ngModel]="searchPays | async" (inputChange)="searchPays.next($event)">
                                            </mdb-select-filter>
                                            <mdb-select-option *ngFor="let option of filteredOptions | async" [value]="option.pays">{{
                                                option.pays
                                            }}</mdb-select-option>
                                        </mdb-select-2>
                                    </div>
                                    <div class="col-12 col-md-6 mt-4 mt-md-0">
                                        <div style="position: relative" *ngFor="let p of candidat.mobilite_nationale">
                                            <label class="container_radio version_2" style="padding-left: 16px"
                                                >{{ p }}
                                                <input type="radio" name="positions" value="" disabled />
                                            </label>
                                            <div class="container-cross" (click)="removePays(p)">
                                                <div class="cross">x</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="mobilite.regional">
                                <hr style="" />
                                <label>Dans la / les ville(s) suivante(s) :</label>
                                <div class="row">
                                    <div class="col-md-6 pl-0">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <mdb-select-2
                                                    [(ngModel)]="mobiliteRayon.pays"
                                                    placeholder="Choisir un pays.."
                                                    [notFoundMsg]="'Pays non trouvé'"
                                                    name="paysMobilite"
                                                    [outline]="true"
                                                >
                                                    <mdb-select-filter
                                                        [ngModel]="searchPays | async"
                                                        (inputChange)="searchPays.next($event)"
                                                    >
                                                    </mdb-select-filter>
                                                    <mdb-select-option
                                                        *ngFor="let option of filteredOptions | async"
                                                        [value]="option.code"
                                                        >{{ option.pays }}</mdb-select-option
                                                    >
                                                </mdb-select-2>
                                            </div>
                                        </div>
                                        <div class="col-12 md-form">
                                            <app-select-city [ville]="mobiliteRayon"></app-select-city>
                                        </div>

                                        <div class="col-12" style="margin-bottom: 15px">
                                            <label>Et / ou dans un rayon de (km)</label>
                                            <input
                                                type="number"
                                                name="rayon"
                                                id="rayon"
                                                class="form-control"
                                                [(ngModel)]="mobiliteRayon.rayon"
                                            />
                                        </div>

                                        <div class="col-12">
                                            <div class="form-group" style="margin-top: 28px">
                                                <button
                                                    mdbBtn
                                                    [block]="true"
                                                    color="warning"
                                                    mdbWavesEffect
                                                    (click)="chooseAddrMobiliteAjout()"
                                                >
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 pl-0">
                                        <div class="col-12 mt-3" style="position: relative" *ngFor="let m of candidat.mobilite_locale">
                                            <label class="container_radio version_2" style="padding-left: 16px"
                                                >{{ m.ville }} - {{ m.pays }}. Rayon de {{ m.rayon }} km
                                                <input type="radio" name="positions" value="" disabled />
                                            </label>
                                            <div class="container-cross" (click)="removeMobilite(m)">
                                                <div class="cross">x</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group inscription-row">
                            <label>Rémunération souhaitée en euros (brut)</label>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            for="remunerationSouhaiter0"
                                            [ngClass]="{
                                                'fl-label-empty': this.candidat.remunerationSouhaiter[0] === '',
                                                'fl-label-not-empty': this.candidat.remunerationSouhaiter[0] !== ''
                                            }"
                                            >Par heure</label
                                        >
                                        <input
                                            type="number"
                                            name="remunerationSouhaiter0"
                                            id="remunerationSouhaiter0"
                                            class="form-control"
                                            [(ngModel)]="this.candidat.remunerationSouhaiter[0]"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            for="remunerationSouhaiter1"
                                            [ngClass]="{
                                                'fl-label-empty': this.candidat.remunerationSouhaiter[1] === '',
                                                'fl-label-not-empty': this.candidat.remunerationSouhaiter[1] !== ''
                                            }"
                                            >Par jour</label
                                        >
                                        <input
                                            type="number"
                                            name="remunerationSouhaiter1"
                                            id="remunerationSouhaiter1"
                                            class="form-control"
                                            [(ngModel)]="this.candidat.remunerationSouhaiter[1]"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            for="remunerationSouhaiter2"
                                            [ngClass]="{
                                                'fl-label-empty': this.candidat.remunerationSouhaiter[2] === '',
                                                'fl-label-not-empty': this.candidat.remunerationSouhaiter[2] !== ''
                                            }"
                                            >Par mois</label
                                        >
                                        <input
                                            type="number"
                                            name="remunerationSouhaiter2"
                                            id="remunerationSouhaiter2"
                                            class="form-control"
                                            [(ngModel)]="this.candidat.remunerationSouhaiter[2]"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            for="remunerationSouhaiter3"
                                            [ngClass]="{
                                                'fl-label-empty': this.candidat.remunerationSouhaiter[3] === '',
                                                'fl-label-not-empty': this.candidat.remunerationSouhaiter[3] !== ''
                                            }"
                                            >Par année</label
                                        >
                                        <input
                                            type="number"
                                            name="remunerationSouhaiter3"
                                            id="remunerationSouhaiter3"
                                            class="form-control"
                                            [(ngModel)]="this.candidat.remunerationSouhaiter[3]"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="position: relative">
                            <p
                                style="color: red !important"
                                [ngStyle]="{
                                    display: this.errorRemuneration.status ? 'block' : 'none'
                                }"
                            >
                                {{ errorRemuneration.message }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 align-self-center">
                <div class="inscription-right"></div>
            </div>
        </div>

        <!-- END STEP 7 -->

        <!-- STEP 7 -->

        <div class="row" [ngStyle]="{ display: this.candidat.etape >= 5 ? 'flex' : 'none' }">
            <div class="col-md-10">
                <div class="inscription-left">
                    <h5>On ne fait rien sans votre consentement</h5>
                    <div class="inscription-form">
                        <div class="inscription-row d-flex flex-wrap">
                            <div class="col-md-6">
                                <label
                                    >Enregistrement automatique de ma fiche contenant mon CV dans la CVthèque du site (accessible à tous les
                                    recruteurs) *</label
                                >
                                <span
                                    for="secteurActivite"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.etreDansCVTech ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Oui
                                        <input
                                            type="radio"
                                            (click)="clicAutorisation(2, true)"
                                            [(ngModel)]="candidat.etreDansCVTech"
                                            [value]="true"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Non
                                        <input
                                            type="radio"
                                            (click)="clicAutorisation(2, false)"
                                            [(ngModel)]="candidat.etreDansCVTech"
                                            [value]="false"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="inscription-row d-flex flex-wrap">
                            <div class="col-md-6">
                                <label>Afficher ma photo et mon métier sur la page d'accueil du site *</label>
                                <span
                                    for="secteurActivite"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.autorisationAccueil ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Oui
                                        <input
                                            type="radio"
                                            name="autorisationAccueil"
                                            (click)="clicAutorisation(3, true)"
                                            [(ngModel)]="candidat.autorisationAccueil"
                                            [value]="true"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Non
                                        <input
                                            type="radio"
                                            name="autorisationAccueil"
                                            (click)="clicAutorisation(3, false)"
                                            [(ngModel)]="candidat.autorisationAccueil"
                                            [value]="false"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="inscription-row d-flex flex-wrap">
                            <div class="col-md-6">
                                <label>Autoriser les recruteurs à consulter ma photo de profil dans ma fiche *</label>
                                <span
                                    for="secteurActivite"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.photoProfil ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Oui
                                        <input
                                            type="radio"
                                            name="photoProfil"
                                            (click)="clicAutorisation(4, true)"
                                            [(ngModel)]="candidat.photoProfil"
                                            [value]="true"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Non
                                        <input
                                            type="radio"
                                            name="photoProfil"
                                            (click)="clicAutorisation(4, false)"
                                            [(ngModel)]="candidat.photoProfil"
                                            [value]="false"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                      <div class="inscription-row d-flex flex-wrap">
                        <div class="col-md-6">
                          <label>Autoriser les recruteurs à m’envoyer des offres d’emploi par sms et mail *</label>
                          <span
                            for="sendOffer"
                            class="error"
                            [ngStyle]="{
                                        display: this.error.sendOffer ? 'block' : 'none'
                                    }"
                          >Obligatoire</span
                          >
                        </div>
                        <div class="col-md-3">
                          <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                            >Oui
                              <input
                                type="radio"
                                name="sendOffer"
                                [(ngModel)]="candidat.sendOffer"
                                [value]="true"
                                (click)="clicAutorisation(5, true)"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                            >Non
                              <input
                                type="radio"
                                name="sendOffer"
                                [(ngModel)]="candidat.sendOffer"
                                [value]="false"
                                (click)="clicAutorisation(5, false)"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div *ngIf='!candidat.sendOffer' class="col-12 col-md-6 mt-5">
                          <label>Affiner mes préférences :</label>
                        </div>
                        <div *ngIf='!candidat.sendOffer' class="col-12 col-md-6 mt-2 mt-md-5">
                          <mdb-select-2
                            placeholder="Choisir.."
                            name="status"
                            class="custom-selectbox"
                            [outline]="true"
                            [(ngModel)]='candidat.sendOfferType'
                          >
                            <mdb-select-option *ngFor="let option of offerTypes" [value]="option.value">{{
                              option.label
                              }}</mdb-select-option>
                          </mdb-select-2>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3 p-2">
                        <div class="form-group">
                            <div class="form-group terms">
                                <label class="container_check"
                                    >En cochant cette case, j'accepte sans réserve les
                                    <a target="_blank" href="/conditions-generales-d-utilisation-et-de-vente"
                                        >conditions d'utilisation et de vente du site.</a
                                    > *
                                    <input type="checkbox" name="terms" value="" [checked]="acceptation" (click)="accepter(1)" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div style="color: red" *ngIf="error.acceptation">
                                Vous devez accepter nos CGUV avant de finaliser votre inscription.
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-group terms">
                                <div class="form-group terms">
                                    <label class="container_check"
                                        >En cochant cette case, je reconnais avoir donné mon autorisation (ou non) pour chacun des points
                                        précédents *
                                        <input type="checkbox" name="terms" value="" [checked]="acceptation2" (click)="accepter(2)" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div style="color: red" *ngIf="error.acceptation2">Vous devez cliquer sur cette case.</div>
                            </div>
                        </div>
                      <div class="form-group">
                        <div class="form-group terms">
                          <div class="form-group terms">
                            <label class="container_check"
                            >Je certifie être majeur(e) pour utiliser les services d'Avenue Des Recruteurs. *
                              <input type="checkbox" name="terms" value="" [checked]="majeur" (click)="accepter(3)" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div style="color: red" *ngIf="error.majeur">Vous devez être majeur(e) afin de finaliser votre inscription.</div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 align-self-center">
                <div class="inscription-right"></div>
            </div>
        </div>

        <!-- END STEP 7 -->

        <div
            [ngStyle]="{
                display: !fin && this.candidat.etape !== -1 ? 'block' : 'none'
            }"
            class="inscription-buttons text-center pt-4 mb-3"
        >
            <p
                style="color: red !important"
                [ngStyle]="{
                    display: this.errorPage.status ? 'block' : 'none'
                }"
            >
                {{ errorPage.message }}
            </p>
            <p class="mention-obligatoire" style="color: red !important">* Obligatoire</p>
            <p *ngIf="photoUpload == '2'">
                <img src="./assets/img/gif.gif" style="max-width: 25px" /> Photo en cours de sauvegarde, veuillez patienter
            </p>
            <input
                class="inscription-prev"
                type="submit"
                [ngStyle]="{
                    display: this.candidat.etape === 0 ? 'none' : 'inline'
                }"
                (click)="precedent()"
                value="Précédent"
            />
            <input class="inscription-next" type="submit" (click)="suivant()" value="Suivant" />
        </div>
    </div>
</section>
<app-site-footer></app-site-footer>
