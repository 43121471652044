<app-site-header page="tarifs"></app-site-header>
<!-- tarrifs -->
<section class="tarifs py-5">
    <div class="container mt-3" style='padding-top: 80px'>
        <div class="row">
            <div class="col-12">
                <h4 class="text-primary fs-2 text-center">NOS TARIFS</h4>
                <p class="fs-23 text-muted text-center">Consultez les tarifs relatifs à l’ensemble de nos services</p>
            </div>
        </div>

        <div class="row mt-4 justify-content-between">
            <div
                [ngClass]="role == 'candidat' ? 'col-lg-12' : 'col-lg-6'"
                class="p-5"
                *ngIf="role == 'candidat' || role == '' || role == null"
            >
                <div class="card br-30 border-dark h-100 mb-md-3">
                    <div class="card-body">
                        <div class="text-center">
                            <h4 class="fs-20 fw-bold text-primary">Candidat(e)s</h4>
                        </div>
                        <table class="table mb-0 mt-3 table1">
                          <tr>
                            <td class="border-0 border-bottom" colspan="2">
                              <p class="mb-0">Bénéficiez des avantages candidats   </p>
                            </td>
                            <td class="border-0 border-bottom">
                              <span  class="text-primary text-decoration-none"> Gratuit </span>
                            </td>
                          </tr>
                            <tr>
                                <td class="border-0 border-bottom" colspan="2">
                                    <p class="mb-0">Consultation des fiches recruteurs / recruteuses</p>
                                </td>
                                <td class="border-0 border-bottom">
                                    <span  class="text-primary text-decoration-none"> Gratuit </span>
                                </td>
                            </tr>

                            <tr>
                                <td class="border-0 border-bottom" colspan="2">
                                    <p class="mb-0">Envoi de CV (tarif par commande non cumulatif)</p>
                                </td>
                                <td class="border-0 border-bottom"></td>
                            </tr>
                            <tr *ngFor="let t of tarifs; let i=index;">
                                <td class="border-0 border-bottom" colspan="2">
                                    <p class="mb-0">
                                        - De
                                        {{ i == 0 ? 1 : tarifs[i-1].nombreDeCourrielMinimum + 1}}
                                       à
                                      {{ t.nombreDeCourrielMinimum }}
                                    </p>
                                </td>
                                <td class="border-0 border-bottom text-end" colspan="">
                                    <p class="mb-0 text-primary">
                                        {{ (t.prixUnitaireHt * 1.2).toFixed(2) }}
                                        <sup>€</sup> / envoi
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td class="border-0 border-bottom" colspan="2">
                                    <p class="mb-0">- Plus de 25601</p>
                                </td>
                                <td class="border-0 border-bottom text-end" colspan="">
                                    <p class="mb-0 text-primary">0.07 <sup>€</sup> / envoi</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="text-center">
                    <p class="mb-0 text-primary">Le tarif est dégressif. Plus vous envoyez de CV, plus le prix par envoi baisse ! 😀</p>
                </div>
            </div>
            <div
                [ngClass]="role == 'recruteur' ? 'col-lg-12' : 'col-lg-6'"
                class="p-5 mt-5 mt-md-0"
                *ngIf="role == 'recruteur' || role == '' || role == null"
            >
                <div class="card br-30 border-dark h-100 mb-3">
                    <div class="card-body">
                        <div class="text-center">
                            <h4 class="fs-20 fw-bold text-primary">Recruteurs/teuses</h4>
                        </div>
                        <table class="table mb-0 mt-3 table1">
                          <tr>
                            <td class="border-0 border-bottom" colspan="2">
                              <p class="mb-0">Bénéficiez des avantages recruteurs   </p>
                            </td>
                            <td class="border-0 border-bottom">
                              <span  class="text-primary text-decoration-none"> Gratuit </span>
                            </td>
                          </tr>
                            <tr>
                                <td class="border-0 border-bottom" colspan="2">
                                    <span class="mb-0">Consultation de la CVthèque</span>
                                </td>
                                <td class="border-0 border-bottom">
                                    <a class="text-primary text-decoration-none"> Gratuit </a>
                                </td>
                            </tr>

                            <tr>
                                <td class="border-0 border-bottom" colspan="2">
                                    <p class="mb-0">Réception de candidatures spontanées</p>
                                </td>
                                <td class="border-0 border-bottom">
                                    <span class="text-primary text-decoration-none"> Gratuit </span>
                                </td>
                            </tr>

                            <tr>
                                <td class="border-0 border-bottom" colspan="2">
                                    <p class="mb-0">Historique des candidatures reçues</p>
                                </td>
                                <td class="border-0 border-bottom">
                                    <span  class="text-primary text-decoration-none"> Gratuit </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- <div class="text-center">
            <p class="mb-0 text-primary">
                Le tarif est dégressif. Plus vous envoyez de CV, plus le prix par envoi baisse !
            </p>
        </div> -->
            </div>
        </div>
    </div>
</section>
<!-- tarrifs -->
<app-site-footer></app-site-footer>
