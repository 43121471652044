import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private httpClient: HttpClient, private authService: AuthService, private globalService: GlobalService) {}

    getAccount(): any {
        return this.httpClient
            .get<any>(`${this.globalService.url}/${this.authService.role}/${this.authService.id}`, {})
            .toPromise()
            .then((response) => {
                return response;
            });
    }
    loggedInForRole(role: string): boolean {
        return this.authService.loggedIn() && this.authService.role === role;
    }
}
