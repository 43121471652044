<app-site-header page="rgpd"></app-site-header>
<div
    class="row light_bg full-height align-items-center d-flex contact-1"
    style="width: 100%; margin: 0; padding-top: 80px; padding-bottom: 80px"
>
    <div class="container">
        <div class="vc_column-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="oi_vc_heading oi_border_position_center">
                        <h1 class="oi_icon_titile pb-3" style="text-align: center; font-size: 2rem">
                            POLITIQUE DE CONFIDENTIALITÉ ET D'UTILISATION DES DONNÉES PERSONNELLES
                        </h1>
                        <h6 class='pb-2' style="text-align: center">Mise à jour : 09/11/2021</h6>
                        <div class="mention-text">
                            <h3>Définition des termes utilisés dans la politique de confidentialité</h3>
                            On désignera par la suite :<br /><br />
                            <ul>
                                <li>
                                    « <strong>Donnée personnelle</strong> » : se définit comme «
                                    <i
                                        >toute information relative à une personne physique identifiée ou qui peut être identifiée,
                                        directement ou indirectement, par référence à un numéro d'identification ou à un ou plusieurs
                                        éléments qui lui sont propres</i
                                    >
                                    », conformément à la loi Informatique et libertés du 6 janvier 1978.
                                </li>
                                <li>« <strong>Service</strong> » : le site Internet https://avenuedesrecruteurs.fr.</li>
                                <li>
                                    « <strong>Editeur</strong> » ou « <strong>Nous</strong> » : RECRUT'INNOV, personne morale ou physique
                                    responsable de l'édition et du contenu du Service.
                                </li>
                                <li>
                                    « <strong>Utilisateur</strong> » ou « <strong>Vous</strong> » : l'internaute visitant et utilisant le
                                    Service.
                                </li>
                            </ul>
                        </div>
                        <div class="mention-text">
                            <h2>Article 1 - Introduction et rôle de la Politique de confidentialité</h2>
                            La présente charte vise à vous informer des engagements du Service eu égard au respect de votre vie privée et à
                            la protection des Données personnelles vous concernant, collectées et traitées à l'occasion de votre utilisation
                            du Service.<br /><br />
                            Il est important que vous lisiez la présente politique de confidentialité afin que vous soyez conscient des
                            raisons pour lesquelles nous utilisons vos données et comment nous le faisons.<br /><br />
                            En vous inscrivant sur le Service, vous vous engagez à nous fournir des informations véritables vous concernant.
                            La communication de fausses informations est contraire aux conditions générales figurant sur le Service.<br /><br />
                            Veuillez noter que la présente Politique de confidentialité est susceptible d’être modifiée ou complétée à tout
                            moment, notamment en vue de se conformer à toute évolution législative, réglementaire, jurisprudentielle ou
                            technologique. La date de sa mise à jour sera clairement mentionnée, le cas échéant.<br /><br />
                            Ces modifications vous engagent dès leur mise en ligne et nous vous invitons donc à consulter régulièrement la
                            présente Politique de confidentialité afin de prendre connaissance des éventuelles modifications.<br /><br />
                            Vous trouverez également la description de vos droits à la protection de la vie privée et la façon dont la loi
                            vous protège.<br /><br />
                            Si vous avez des questions concernant la présente politique de confidentialité ou si vous voulez exercer vos
                            droits tels que décrit à l’article 10 de la présente Politique de confidentialité, veuillez nous contacter par
                            e-mail à l’adresse :
                            <a href="mailto:contact@avenuedesrecruteurs.fr">contact@avenuedesrecruteurs.fr</a>;
                        </div>
                        <div class="mention-text">
                            <h2>Article 2 - Données collectées sur le Site</h2>
                            Les Données collectées et ultérieurement traitées par le Service sont celles que vous nous transmettez
                            volontairement en remplissant les différents formulaires présents au sein du Service. Pour certaines opérations
                            sur les contenus, vous pourrez être amenés à transmettre des Données vous concernant à des tiers partenaires au
                            travers de leurs propres services, plus spécifiquement lors des paiements que vous pourrez effectuer. Nous ne
                            disposerons pas des dites données, leur collecte et leur traitement étant régis par les conditions propres à ces
                            intervenants. Nous vous invitons à consulter leurs conditions avant de communiquer vos Données dans ce cadre.<br /><br />
                            Votre adresse IP (numéro d'identification attribué sur Internet à votre ordinateur) ainsi que votre « user agent
                            » (modèle d’ordinateur, navigateur, système d’exploitation) sont collectés automatiquement.<br /><br />
                            Ce site n'a pas de cookies, cependant, notre système de chat en utilise.<br />
                            Vous trouverez ici sa politique de confidentialité. (<a href="https://crisp.chat/fr/privacy/"
                                >https://crisp.chat/fr/privacy/</a
                            >)<br /><br />
                            D’une manière générale, il vous est possible de visiter le Service https://avenuedesrecruteurs.fr sans
                            communiquer aucune information personnelle vous concernant. Dans tous les cas, vous n’avez aucune obligation de
                            transmettre ces informations. Néanmoins, en cas de refus, il se peut que vous ne puissiez pas bénéficier de
                            certaines informations ou services.<br /><br />
                            Nous recueillons, utilisons et partageons également des données agrégées telles que des données statistiques ou
                            démographiques quel qu’en soit l’usage. Les données agrégées peuvent provenir de vos informations personnelles
                            mais ne sont pas concernées comme telles par la loi car ces données ne révèlent pas directement votre identité.
                            Par exemple, nous pouvons agréger vos données d’utilisation afin de calculer le pourcentage d’utilisateurs qui
                            accèdent à une fonctionnalité spécifique du Service.<br /><br />
                            Aux fins de fournir de meilleurs contenus et services, le Service https://avenuedesrecruteurs.fr utilise le
                            service d’analyse de Google Analytics. Google Analytics ne suit pas vos habitudes de navigation sur des services
                            tiers. Les informations vous concernant auxquelles Google Analytics a accès ne contiennent aucune donnée
                            personnelle vous concernant.<br /><br />
                            Nous ne collectons pas de données dites « sensibles ».<br /><br />
                            Les coordonnées des Utilisateurs du Service qui se seront inscrits sur celui-ci seront sauvegardées, dans le
                            respect des dispositions de la loi informatique et liberté du 6 janvier 1978. Conformément à cette dernière, ils
                            disposent d’un droit d’accès, de retrait, de modification ou de rectification des Données qu’ils ont fournies.
                            Pour cela, il leur suffit d’en faire la demande à l’adresse électronique suivante :
                            <a href="mailto:contact@avenuedesrecruteurs.fr">contact@avenuedesrecruteurs.fr</a>.<br /><br />
                            La collecte des Données personnelles des Utilisateurs par l'Editeur ne nécessite pas de déclaration auprès de
                            l’autorité française de protection des Données personnelles (la Commission Nationale de l’Informatique et des
                            Libertés – CNIL).
                        </div>
                        <div class="mention-text">
                            <h2>Article 3 - Identité du responsable du traitement</h2>
                            Le responsable du traitement est la société RECRUT'INNOV, ayant son siège social à l'adresse suivante : 5 rue de
                            l'Abbé de l'Epée, 75005 PARIS.
                        </div>
                        <div class="mention-text">
                            <h2>Article 4 - Finalité des Données collectées</h2>
                            Les Données identifiées comme étant obligatoires sur les formulaires du Service sont nécessaires afin de pouvoir
                            bénéficier des fonctionnalités correspondantes du Service, et plus spécifiquement des opérations sur les
                            contenus proposés au sein de celui-ci.<br /><br />
                            Le Service est susceptible de collecter et traiter les Données de ses Utilisateurs :<br /><br />
                            <ul>
                                <li>
                                    Aux fins de vous fournir les informations ou les services auxquels vous avez souscrit, notamment : Envoi
                                    des CV des candidats aux recruteurs, CVthèque;
                                </li>
                                <li>
                                    Aux fins de recueillir des informations nous permettant d’améliorer notre Service, nos produits et
                                    fonctionnalités;
                                </li>
                                <li>Aux fins de vous informer des offres promotionnelles de nos partenaires;</li>
                                <li>Aux fins de pouvoir vous contacter afin de vous informer, d’avoir votre avis…</li>
                            </ul>
                            et cela par courrier postal, courrier électronique (courriel) et sms.
                        </div>
                        <div class="mention-text">
                            <h2>Article 5 - Destinataires et utilisation des Données collectées</h2>
                            Les Données collectées par nos soins sont traitées pour les besoins d’exécution des opérations sur les contenus
                            du Service.<br /><br />
                            Vous êtes susceptible de recevoir des courriers électroniques (emails) de notre Service, notamment dans le cadre
                            de newsletters que vous avez acceptées. Vous pouvez demander à ne plus recevoir ces courriers électroniques en
                            cliquant sur le lien prévu à cet effet dans chacun des courriers électroniques qui vous seront adressés.<br /><br />
                            Vos informations personnelles sont destinées à RECRUT’INNOV, à ses partenaires, prestataires et sous-traitants :
                            Paypal (Paiement), Sendinblue (envoi de courriels), Stripe (paiement), Mailjet (envoi de courriels)...<br /><br />
                            Sauf accord préalable des personnes concernées, RECRUT’INNOV ne procède pas à la commercialisation seule des
                            données personnelles des visiteurs et utilisateurs de son Service.<br /><br />
                            Par contre, RECRUT’INNOV est autorisée à vendre à un tiers l’intégralité du Service (programmes et bases de
                            données, dont les données personnelles font partie).<br /><br />
                            Seul RECRUT'INNOV est destinataire de vos Informations personnelles. Celles-ci ne sont jamais transmises à un
                            tiers, nonobstant les sous-traitants auxquels RECRUT'INNOV fait appel. Ni RECRUT'INNOV ni ses sous-traitants ne
                            procèdent à la commercialisation des données personnelles des visiteurs et utilisateurs de son Service.<br /><br />
                        </div>
                        <div class="mention-text">
                            <h2>Article 6 - Fondements légaux régissant le traitement des données</h2>
                            Conformément au Règlement Général sur la Protection des Données (RGPD), RECRUT'INNOV ne traite des données à
                            caractère personnel que dans les situations suivantes :<br /><br />
                            <ul>
                                <li>avec votre <strong>consentement</strong> ;</li>
                                <li>
                                    lorsqu'il existe une
                                    <strong>obligation contractuelle</strong>
                                    (un contrat entre RECRUT'INNOV et vous) ;
                                </li>
                                <li>
                                    pour répondre à une
                                    <strong>obligation légale</strong> (en vertu de la législation UE ou nationale).
                                </li>
                            </ul>
                        </div>
                        <div class="mention-text">
                            <h2>Article 7 - Sécurité des Données</h2>
                            Vous êtes informés que vos Données pourront être divulguées en application d'une loi, d'un règlement ou en vertu
                            d'une décision d'une autorité réglementaire ou judiciaire compétente ou encore, si cela s'avère nécessaire, aux
                            fins, pour l'Editeur, de préserver ses droits et intérêts.<br /><br />
                            Nous avons mis en place des mesures de sécurité afin d’empêcher que vos données personnelles ne soient
                            accidentellement perdues, utilisées, modifiées, dévoilées ou consultées sans autorisation.
                        </div>
                        <div class="mention-text">
                            <h2>Article 8 - Durée de conservation des Données</h2>
                            Les Données sont stockées chez l'hébergeur du Service, dont les coordonnées figurent dans les mentions légales
                            du Service, et sont conservées pour la durée strictement nécessaire à la réalisation des finalités visées
                            ci-avant et ne saurait excéder 24 mois. Au-delà de cette durée, elles seront conservées à des fins exclusivement
                            statistiques et ne donneront lieu à aucune exploitation, de quelque nature que ce soit.
                        </div>
                        <div class="mention-text">
                            <h2>Article 9 - Prestataires habilités et transfert vers un pays tiers de l’Union Européenne</h2>
                            RECRUT'INNOV vous informe qu’il a recours à des prestataires pour faciliter le recueil et le traitement des
                            données que vous nous avez communiquées. Ces prestataires peuvent être situés en dehors de l’Union Européenne.
                        </div>
                        <div class="mention-text">
                            <h2>Article 10 - Droits informatiques et libertés</h2>
                            Conformément à la législation sur la protection des données personnelles, vous avez les droits détaillés
                            ci-après que vous pouvez exercer, comme indiqué à l’Article 1 de la Présente Politique de confidentialité en
                            nous écrivant à l’adresse postale mentionnée en tête () ou en envoyant un courriel à
                            <a href="mailto:contact@avenuedesrecruteurs.fr">contact@avenuedesrecruteurs.fr</a>
                            :<br /><br />
                            <ul>
                                <li>
                                    Le droit d’information : nous avons l’obligation de vous informer de la manière dont nous utilisons vos
                                    données personnelles (tel que décrit dans la présente politique de confidentialité).
                                </li>
                                <li>
                                    Le droit d’accès : c’est votre droit d’effectuer une demande d’accès aux données vous concernant afin de
                                    recevoir une copie des données à caractère personnel que nous détenons ; Toutefois, en raison de
                                    l’obligation de sécurité et de confidentialité dans le traitement des données à caractère personnel qui
                                    incombe à RECRUT'INNOV, vous êtes informé que votre demande sera traitée sous réserve que vous
                                    rapportiez la preuve de votre identité, notamment par la production d’un scan ou d’une photocopie de
                                    votre titre d’identité valide.
                                </li>
                                <li>
                                    Le droit de rectification : le droit de nous demander de rectifier des données personnelles vous
                                    concernant qui seraient incomplètes ou inexactes. Au titre de ce droit, la législation vous autorise à
                                    demander la rectification, la mise à jour, le verrouillage ou encore l’effacement des données vous
                                    concernant qui peuvent être inexactes, erronées, incomplètes ou obsolètes.
                                </li>
                                <li>
                                    Le droit à l’effacement, aussi connu sous le nom de « droit à l’oubli » : dans certains cas, vous pouvez
                                    nous demander de supprimer les données personnelles que nous avons vous concernant (mis à part s’il
                                    existe une raison juridique impérieuse qui nous oblige à les conserver).
                                </li>
                                <li>
                                    Le droit à la limitation du traitement : vous avez le droit dans certains cas de nous demander de
                                    suspendre le traitement des données personnelles.
                                </li>
                                <li>
                                    Le droit à la portabilité des données : vous avez le droit de nous demander une copie de vos données
                                    personnelles dans un format courant (par exemple un fichier .csv).
                                </li>
                                <li>
                                    Le droit d’opposition : vous avez le droit de vous opposer au traitement de vos données personnelles
                                    (par exemple, en nous interdisant de traiter vos données à des fins de marketing direct).
                                </li>
                            </ul>
                            Cependant, l’exercice de ce droit n’est possible que dans l’une des deux situations suivantes : lorsque
                            l’exercice de ce droit est fondé sur des motifs légitimes ou lorsque l’exercice de ce droit vise à faire
                            obstacle à ce que les données recueillies soient utilisées à des fins de prospection commerciale.<br /><br />
                            Contactez-nous si vous souhaitez exercer l’un des droits décrits ci-dessus en nous écrivant à ou par courriel à
                            <a href="mailto:contact@avenuedesrecruteurs.fr">contact@avenuedesrecruteurs.fr</a><br /><br />
                            Vous pouvez exercer directement vos droits d’accès et de rectification dans votre espace (Candidat ou
                            Recruteur).<br /><br />
                            Vous pouvez exercer votre droit à l’effacement dans votre espace (Candidat ou Recruteur), en cliquant sur le
                            bouton rouge » Me désinscrire d’Avenuedesrecruteurs.fr » situé en bas à droite de la page « Désabonnements ».
                            Dans ce cas, toutes les informations qui vous concernent (en dehors des factures) seront immédiatement et
                            irrévocablement supprimées.<br /><br />
                            Vous n’aurez pas de frais à payer pour l’accès à vos données personnelles (ni pour l’exercice de tout autre
                            droit). Cependant, nous pourrons vous facturer des frais raisonnables si votre demande est manifestement
                            infondée, répétitive ou excessive. Dans ce cas, nous pouvons aussi refuser de répondre à votre demande.<br /><br />
                            RECRUT'INNOV sera en droit, le cas échéant, de s’opposer aux demandes manifestement abusives de par leur
                            caractère systématique, répétitif, ou leur nombre.<br /><br />
                            Nous pouvons vous demander des informations spécifiques afin de confirmer votre identité et d’assurer votre
                            droit d’accès à vos données personnelles (ou pour exercer tout autre droit). Il s’agit d’une mesure de sécurité
                            pour garantir que ces données personnelles ne soient pas délivrées à une personne non autorisée à les recevoir.
                            Nous pouvons aussi vous contacter pour obtenir plus d’informations concernant votre demande, afin de vous donner
                            une réponse plus rapide.<br /><br />
                            Nous essayons de répondre à toutes les demandes légitimes dans un délai d’un mois. Ce délai d’un mois peut être
                            dépassé dans le cas où votre demande est particulièrement complexe ou si vous en avez fait plusieurs. Dans ce
                            cas, nous vous préviendrons et vous tiendrons informé.
                        </div>
                        <div class="mention-text">
                            <h2>Article 11 - Plainte auprès de l’Autorité de protection des données</h2>
                            Si vous considérez que RECRUT'INNOV ne respecte pas ses obligations au regard de vos Informations Personnelles,
                            vous pouvez adresser une plainte ou une demande auprès de l’autorité compétente.
                        </div>
                        <div class="mention-text">
                            <h2>Article 12 - Politique relative aux cookies</h2>
                            Le Service n’utilise pas de « cookies ».<br />
                            Cependant, nous utilisons
                            <a href="https://crisp.chat/fr/">Crisp</a> en tant que chatbox qui lui en utilise. Vous trouverez ici sa
                            <a href="https://crisp.chat/fr/privacy/">politique de confidentialité.</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
