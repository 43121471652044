import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { ValidateService } from '../../../services/validate.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css', './../../../../assets/css/site/main.css'],
})
export class ContactComponent implements OnInit {
    faPhone = faPhone;
    faEnvelope = faEnvelope;

    contact = {
        nom: '',
        email: '',
        telephone: '',
        sujet: '',
        message: '',
    };
    contactError = {
        nom: false,
        email: false,
        telephone: false,
        sujet: false,
        message: false,
    };
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private validateService: ValidateService,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {}
    renouveller(): void {
        this.contactError = {
            nom: false,
            email: false,
            telephone: false,
            sujet: false,
            message: false,
        };
    }
    isOk(): boolean {
        return !this.contactError.nom && !this.contactError.email && !this.contactError.sujet && !this.contactError.message;
    }
    envoyer(): void {
        this.renouveller();
        if (this.contact.nom === '') {
            this.contactError.nom = true;
        }
        if (this.contact.email === '' || !this.validateService.validateEmail(this.contact.email)) {
            this.contactError.email = true;
        }
        if (this.contact.sujet === '') {
            this.contactError.sujet = true;
        }
        if (this.contact.message === '') {
            this.contactError.message = true;
        }
        if (this.isOk()) {
            this.httpClient
                .put<any>(this.globalService.url + '/mail/contact', {
                    prenom: this.contact.nom,
                    email: this.contact.email,
                    telephone: this.contact.telephone,
                    objet: this.contact.sujet,
                    message: this.contact.message,
                })
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this.toastrService.info(
                                'Votre message a bien été transmis. Nous vous répondrons dans les plus brefs délais',
                                '',
                                { opacity: 1 }
                            );
                            this.contact = {
                                nom: '',
                                email: '',
                                telephone: '',
                                sujet: '',
                                message: '',
                            };
                        }
                    },
                    () => {}
                );
        }
    }
}
