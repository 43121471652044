<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-color" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10">
        <div class="page-inner">
            <div class="page-title">
                <h2 class="breadcrumb-header">Accueil</h2>
            </div>

            <section>
                <div class="row row-background p-3">
                    <div class="col-xl-3 col-sm-12 col-12 col-md-6 mb-2">
                      <a routerLink="/espace-candidat/mes-selections">
                        <div class="card">
                            <div class="card-body" style='padding: 1rem'>
                                <div class="d-flex justify-content-between px-md-1">
                                    <div>
                                        <p class="mb-0">Panier{{ nombrePanier | plural}}</p>
                                        <h3 class="text-warning">
                                            {{ nombrePanier }}
                                        </h3>
                                    </div>
                                    <div class="align-self-center">
                                        <fa-icon class="text-warning fa-3x" [icon]="faCartArrowDown"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-xl-3 col-sm-12 col-12 col-md-6 mb-2">
                      <a routerLink="/espace-candidat/mes-commandes">
                        <div class="card">
                            <div class="card-body" style='padding: 1rem'>
                                <div class="d-flex justify-content-between px-md-1">
                                    <div>
                                        <p class="mb-0">Commande{{ nombreCommande | plural}}</p>
                                        <h3 class="text-pink">
                                            {{ nombreCommande }}
                                        </h3>
                                    </div>
                                    <div class="align-self-center">
                                        <fa-icon class="text-pink fa-3x" [icon]="faShoppingBag"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-xl-3 col-sm-12 col-12 col-md-6 mb-2">
                      <a routerLink="/espace-candidat/mes-commandes">
                        <div class="card">
                            <div class="card-body" style='padding: 1rem'>
                                <div class="d-flex justify-content-between px-md-1">
                                    <div>
                                        <p class="mb-0">Candidature{{ nombreMailEnvoyer | plural}} envoyée{{ nombreMailEnvoyer | plural}}</p>
                                        <h3 class="text-alt">
                                            {{ nombreMailEnvoyer }}
                                        </h3>
                                    </div>
                                    <div class="align-self-center">
                                        <fa-icon class="text-alt fa-3x" [icon]="faPaperPlane"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-xl-3 col-sm-12 col-12 col-md-6 mb-2">
                      <a routerLink="/espace-candidat/desabonnement-recruteur">
                        <div class="card">
                            <div class="card-body" style='padding: 1rem'>
                                <div class="d-flex justify-content-between px-md-1">
                                    <div>
                                        <p class="mb-0">Désabonnement{{ nombreRetrait | plural}} recruteur{{ nombreRetrait | plural}}</p>
                                        <h3 class="text-primary">
                                            {{ nombreRetrait }}
                                        </h3>
                                    </div>
                                    <div class="align-self-center">
                                        <fa-icon class="text-primary fa-3x" [icon]="faTimesCircle"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </a>
                    </div>
                </div>
            </section>

            <div id="main-wrapper" class="p-lg-5">
                <h2 class="breadcrumb-header">Les étapes</h2>
                <p class="p-2">Voici la liste des étapes afin de diffuser votre CV et votre lettre de motivation</p>
                <div class="row mb-3">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <!--<div class="blockHead">
                <div class="row">
                  <div class="left"><fa-icon class="text-primary arrow-icon fa-2x" [icon]="faTimesCircle"></fa-icon></div>
                  <div class="center">  <span class="blockText">Complétez vos informations personnelles</span></div>
                  <div class="right"></div>
                </div>
              </div>-->
                        <div class="blockHead mt-2">
                            <div class="blockText d-flex flex-nowrap align-items-center" style="top: 10px !important">
                                <img class="arrow-icon" src="./assets/img/Trace120.png" />
                                <li class="d-block">
                                    <span>1 - Complétez vos informations personnelles</span>
                                </li>
                            </div>
                        </div>

                        <div class="blockHead mt-2">
                            <span class="blockText"><img class="arrow-icon" src="./assets/img/Trace252.png" />2 - Créez votre panier</span>
                        </div>
                        <div class="blockHead mt-2">
                            <div class="blockText d-flex flex-nowrap align-items-center" style="top: 10px !important">
                                <img class="arrow-icon" src="./assets/img/Groupe%201200.png" />
                                <li class="d-block">
                                    <span>3 - Remplissez votre panier</span>
                                    <small class="d-block"
                                        >Il vous suffit d’ajouter les recruteurs et recruteuses qui vous intéressent</small
                                    >
                                </li>
                            </div>
                        </div>
                        <div class="blockHead mt-2">
                            <span class="blockText"
                                ><img class="arrow-icon" src="./assets/img/Groupe%201201.png" />4 - Passez commande</span
                            >
                        </div>
                        <div class="blockHead mt-2">
                            <div class="blockText d-flex flex-nowrap align-items-center" style="top: 10px !important">
                                <img class="arrow-icon" src="./assets/img/Trace199.png" />
                                <li class="d-block">
                                    <span>5 - Activez votre expéditeur</span>
                                    <small class="d-block"
                                        >Lors de votre 1ere diffusion, vous recevrez un mail pour activer votre expéditeur</small
                                    >
                                </li>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 d-none d-md-block">
                        <img src="./assets/img/dashboard-01.png" style="text-align: center; width: 90%; position: relative; height: 90%" />
                    </div>
                </div>

                <div class="pt-3 pb-3">
                    <div class="col text-center">
                        <a
                            href="espace-candidat/mon-cv"
                            mdbBtn
                            style="color: white"
                            color="warning"
                            [rounded]="true"
                            size="lg"
                            mdbWavesEffect
                            >{{ cvSet ? 'Modifiez votre cv': 'Téléchargez votre cv' }}</a
                        >
                    </div>
                </div>

                <div class="div mt-3 mb-3">
                    <h2 class="breadcrumb-header">Nos conseils avant de diffuser votre CV</h2>
                </div>

                <div class="hero-dashboard position-relative d-none d-md-block" style="margin-top: 3.5rem">
                    <div class="login-slider mt-3">
                        <div class="login-slide">
                            <div
                                class="slide-content"
                                *ngFor="let slide of candidatSlides; let title = index"
                                [ngClass]="title === this.index ? 'slide-active' : ''"
                            >
                                <h3>
                                    {{ slide.title }}
                                </h3>
                                <div class="d-flex align-items-center py-2">
                                    <h1>{{ title + 1 }}</h1>
                                    <h5>
                                        {{ slide.text }}
                                    </h5>
                                </div>
                            </div>
                            <div class="slide-icons d-flex justify-content-between align-items-center">
                                <img class="prev" (click)="prev()" src="./assets/img/arrow-left.png" alt="next" />
                                <img class="next" (click)="next()" src="./assets/img/arrow-right.png" alt="next" />
                            </div>
                        </div>
                    </div>
                </div>

                <mdb-carousel
                    #carousel
                    [isControls]="true"
                    class="carousel-multi-item multi-animation d-sm-none"
                    [type]="'carousel-multi-item'"
                    [animation]="'slide'"
                >
                    <mdb-carousel-item *ngFor="let item of slides; let i = index">
                        <div class="col-md-4 pb-2" *ngFor="let card of item; let cardIndex = index">
                            <mdb-card class="my-1" style="height: 360px">
                                <mdb-card-body>
                                    <mdb-card-title>
                                        <h4>{{ card.title }}</h4>
                                    </mdb-card-title>
                                    <p>{{ card.description }}</p>
                                </mdb-card-body>
                            </mdb-card>
                        </div>
                    </mdb-carousel-item>
                </mdb-carousel>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
