import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faArrowLeft, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-cv-candidats',
    templateUrl: './mon-cv.component.html',
    styleUrls: [
        './mon-cv.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class MonCVComponent implements OnInit {
    arrowLeft = faArrowLeft;
    faFilePdf = faFilePdf;

    url = '';
    candidat: any = {
        password: '',
    };
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private userService: UserService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {
        this.candidat._id = localStorage.getItem('id');
        this.url = this.globalService.url;

        this.getAccount();
    }
    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.candidat = response.candidat;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    sendCv(): void {
        this.httpClient.patch(this.globalService.url + '/candidat/cv', { id: this.candidat._id, cv: this.candidat.cv }, {}).subscribe(
            (response: any) => {
                if (!response.status) {
                    alert('Erreur lors de la modification');
                } else {
                    this.candidat.cv = response.cv;
                    this.toastrService.info('Cv mis à jour', '', {
                        opacity: 1,
                    });
                }
            },
            () => {}
        );
    }
    cv(f: any): void {
        const file = f.target.files.item(0);
        const reader = new FileReader();
        if (file.type === 'application/pdf') {
            if (file.size <= 3000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.candidat.cv = reader.result;
                    this.sendCv();
                };
            } else {
                alert('Le document importé est supérieur à 3Mo. veuillez réduire sa taille');
            }
        } else {
            alert(
                "Le document que vous venez d'essayer d'importer n'est pas au format PDF. Vous pouvez uniquement importer un CV au format PDF."
            );
        }
    }
}
