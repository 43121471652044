import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-accepter-commande',
    templateUrl: './accepter-commande.component.html',
    styleUrls: [
        './accepter-commande.component.css',
        './../../../../assets/css/inscription/style.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class AccepterCommandeComponent implements OnInit {
    id = '';
    commande: any = {};
    isValid = false;
    send = false;
    generic = false;
    faCheck = faCheck;
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        if (this.route.snapshot.params.id !== undefined && this.route.snapshot.params.id !== null) {
            this.id = this.route.snapshot.params.id;
        } else {
            this.generic = true;
        }
        if (!this.generic) {
            if (!this.send) {
                this.sendMail();
            }
            setInterval(() => {
                if (!this.isValid) {
                    this.isValidate();
                }
            }, 3000);
        }
    }
    getCommande(): void {
        this.httpClient.get<any>(this.globalService.url + '/commande/' + this.id).subscribe(
            (response) => {
                if (response.status) {
                    this.commande = response.commande;
                    this.isValidate();
                }
            },
            () => {}
        );
    }
    isValidate(): void {
        this.httpClient
            .get<any>(this.globalService.url + '/commande/isValidate/' + this.id, {
                headers: this.globalService.hideProgressBarHttp(),
            })
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.isValid = response.isValidate;
                    }
                },
                () => {}
            );
    }
    sendMail(): void {
        this.httpClient.patch<any>(this.globalService.url + '/commande/confirm', { idCommande: this.id }, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.send = true;
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }
}
