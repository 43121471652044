import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { MdbTablePaginationComponent, MdbTableDirective } from '../../../components/mdb/free/tables';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { GlobalVariable } from '../../../global';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-liste-commande',
    templateUrl: './liste-commande.component.html',
    styleUrls: [
        './liste-commande.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class ListeCommandeComponent implements OnInit, AfterViewInit {
    @ViewChild(MdbTablePaginationComponent, { static: true })
    mdbTablePagination!: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true })
    mdbTable!: MdbTableDirective;
    commandes: any = [];

    faEye = faEye;
    displayByPages = GlobalVariable.PAGES;
    previous: any = [];
    headElements: any = [];
    isMobile = false;
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private cdRef: ChangeDetectorRef,
        private deviceService: DeviceDetectorService
    ) {}

    ngOnInit(): void {
        this.getCommande();
        this.isMobile = this.deviceService.isMobile();
        this.headElements = this.isMobile
            ? ['Numéro', 'Date', 'Statut', '']
            : [
                  'N° de commande',
                  'Date de commande',
                  'Date et heure de diffusion',
                  'Candidature(s) envoyée(s)',
                  'Prix TTC',
                  'Statut',
                  'Consulter',
                  'Motif refus',
              ];
    }

    ngAfterViewInit(): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
    display(): string {
        if (this.commandes.length === 0) {
            return 'none';
        }
        return 'table';
    }
    getCommande(): void {
        this.httpClient
            .post<any>(this.globalService.url + '/commande/historique-commandes', { id: localStorage.getItem('id') }, {})
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.commandes = response.commandes;
                        this.mdbTable.setDataSource(this.commandes);
                        this.commandes = this.mdbTable.getDataSource();
                        this.previous = this.mdbTable.getDataSource();
                    }
                },
                (err) => {}
            );
    }
    changeItemsByPage(event: any): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(event);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
    }
}
