import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MdbTableDirective, MdbTablePaginationComponent } from '../../../components/mdb/free/tables';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-liste-desabonnement-candidat',
    templateUrl: './liste-desabonnement.component.html',
    styleUrls: [
        './liste-desabonnement.component.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/space.min.css',
    ],
})
export class ListeDesabonnementCandidatComponent implements OnInit, AfterViewInit {
    @ViewChild(MdbTablePaginationComponent, { static: true })
    mdbTablePagination!: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true })
    mdbTable!: MdbTableDirective;

    id: string | null = '';
    desabonnements: any = [];
    previous: any = [];
    headElements = ['Recruteur/teuse(Reference)', 'Date de désabonnement'];

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private router: Router,
        private authService: AuthService,
        private cdRef: ChangeDetectorRef
    ) {}
    ngAfterViewInit(): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
    ngOnInit(): void {
        this.id = this.authService.id;
        this.httpClient.get<any>(this.globalService.url + '/candidat/getDesinscrit/' + this.id, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.desabonnements = response.desabonnements;
                    this.mdbTable.setDataSource(this.desabonnements);
                    this.desabonnements = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
