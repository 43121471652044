import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { GlobalVariable } from '../../../global';

@Component({
    selector: 'app-home-recruteur',
    templateUrl: './home.component.html',
    styleUrls: [
        './home.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class HomeRecruteurComponent implements OnInit, AfterViewInit {
    index = 0;
    recruteurSlides = GlobalVariable.RECRUTEUR_SLIDES;
    stop1 = false;

    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}
    ngOnInit() {}
    ngAfterViewInit(): void {
        setInterval(() => {
            if (!this.stop1) {
                this.index = (this.index + 1) % this.recruteurSlides.length;
            }
            this.next();
        }, 10000);
    }
    next(): void {
        this.index = (this.index + 1) % this.recruteurSlides.length;
        this.stop1 = true;
    }
    prev(): void {
        this.index = (this.index - 1 + this.recruteurSlides.length) % this.recruteurSlides.length;
        this.stop1 = true;
    }
}
