import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { MdbTableDirective, MdbTablePaginationComponent } from '../../../components/mdb/free/tables';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-liste-desabonnement',
    templateUrl: './liste-desabonnement.component.html',
    styleUrls: [
        './liste-desabonnement.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/space.min.css',
    ],
})
export class ListeDesabonnementComponent implements OnInit, AfterViewInit {
    @ViewChild(MdbTablePaginationComponent, { static: true })
    mdbTablePagination!: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true })
    mdbTable!: MdbTableDirective;

    headElements = ['Candidats/tes (Référence)', 'Date de désabonnement'];

    id: string | null | undefined;
    desabonnements: any = [];
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private authService: AuthService,
        private cdRef: ChangeDetectorRef
    ) {}
    ngOnInit(): void {
        this.id = this.authService.id;
        this.httpClient.get<any>(this.globalService.url + '/recruteur/getDesinscrit/' + this.id, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.desabonnements = response.desabonnements;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    ngAfterViewInit(): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
