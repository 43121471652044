import { Component, NgModule, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
    selector: 'app-sitemap',
    templateUrl: './sitemap.component.html',
    styleUrls: ['./sitemap.component.css'],
})
export class SitemapComponent implements OnInit {
    apiLoaded: Observable<boolean>;
    @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

    center: any = '';

    addr = {
        adresse: 'Place du Pont Neuf, 75001 Paris',
        longitude: 2.3488,
        latitude: 48.8594,
        rayon: 10,
    };

    addrsCheck = false;
    addrs: any = [];

    suggest: any = [];
    cacher = true;

    faTrash = faTrash;
    faEdit = faEdit;

    recruteurs: any = [];
    recruteur: any = {};
    actif = false;
    zoom = 12;
    googleMapType: any = '';
    url = '';
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {
        this.apiLoaded = httpClient
            .jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDvHTzQGMFPttPyyfKRVMH254b8i9EDaug', 'callback')
            .pipe(
                map(() => {
                    this.center = new google.maps.LatLng(this.addr.latitude, this.addr.longitude);
                    this.googleMapType = google.maps.MapTypeId.ROADMAP;
                    return true;
                }),
                catchError((e) => {
                    console.log(e);
                    return of(false);
                })
            );

        console.log(this.apiLoaded);
    }

    ngOnInit(): void {
        this.url = this.globalService.url;
        this.getRecruteur();
    }
    getRecruteurPos(lat: number, long: number): google.maps.LatLng {
        return new google.maps.LatLng(lat, long);
    }
    openInfoWindow(marker: MapMarker, recruteur: any): void {
        this.infoWindow.open(marker);
        this.recruteur = recruteur;
    }
    changeAddr(): void {
        this.addrsCheck = true;
        const addr = this.addr.adresse.split(' ').join('+');
        this.httpClient.get<any>(this.globalService.url + '/adresse/' + addr).subscribe(
            (response) => {
                if (response.status) {
                    this.addrs = response.addrs;
                }
            },
            () => {}
        );
    }
    chooseAddr(adresse: string, codePostal: string, ville: string, latitude: number, longitude: number): void {
        this.addr.adresse = adresse + ' ' + codePostal + ' ' + ville;
        this.addr.latitude = latitude;
        this.addr.longitude = longitude;
        this.center = new google.maps.LatLng(this.addr.latitude, this.addr.longitude);
        this.addrs = [];
        this.addrsCheck = false;
        this.getRecruteur();
    }
    getRecruteur(): void {
        this.httpClient
            .post<any>(this.globalService.url + '/recruteur/recherche', {
                page: 0,
                nbParPage: 500,
                motCles: '',
                latitude: this.addr.latitude,
                longitude: this.addr.longitude,
                pays: 'France',
                codeNaf: '',
                fonction: '',
                typeEntreprise: '',
                nombreSalarie: '',
                activiteSpecifique: '',
            })
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.recruteurs = response.recruteurs;
                        console.log(this.recruteurs);
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    fiche(): void {
        this.actif = true;
    }
}
