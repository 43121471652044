<app-site-header page="cguv"></app-site-header>
<div
    class="row light_bg full-height align-items-center d-flex contact-1"
    style="width: 100%; margin: 0; padding-top: 80px; padding-bottom: 80px"
>
    <div class="container">
        <div class="vc_column-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="oi_vc_heading oi_border_position_center">
                        <h1 class="oi_icon_titile text-primary pb-5" style="text-align: center; font-size: 2rem">
                            CONDITIONS GÉNÉRALES D'UTILISATION ET DE VENTE
                        </h1>
                        <h6 style="text-align: center">Mise à jour : 09/11/2021</h6>
                        <div class="mention-text">
                            Les présentes Conditions Générales de Vente (ci-après les « CGV ») sont proposées par RECRUT'INNOV SARL, au
                            capital de 10000 €, entreprise immatriculée au RCS de Paris sous le numéro 49892950400013, dont le siège social
                            est sis au 5 rue de l'Abbé de l'Epée, 75005 PARIS (ci-après «
                            <strong>LE VENDEUR</strong> »).<br /><br />
                            LE VENDEUR propose, par l’intermédiaire de son site internet https://avenuedesrecruteurs.fr (ci-après le «
                            <strong>Site</strong> ») de nombreux services : multidiffusion de CV, accès à une CVthèque… (ci-après les «
                            <strong>Services</strong> »).<br /><br />
                            Chaque client reconnaît avoir eu connaissance, de manière lisible et compréhensible, des présentes CGUV ainsi
                            que de l’ensemble des informations nécessaires à l’exécution des présentes, conformément aux articles L.111-1 à
                            L.111-8 du Code de la consommation, préalablement à la passation de sa commande et avant toute conclusion de son
                            contrat avec RECRUT'INNOV SARL au capital de 10000 €, entreprise immatriculée au RCS de Paris sous le numéro
                            49892950400013.
                        </div>
                        <div class="mention-text">
                            <h2>1. APPLICATION ET OPPOSABILITE DES CGUV</h2>
                            Les présentes CGUV s’appliquent à toute utilisation du Site ainsi qu’à toute commande de Services par tout
                            client, personne physique, agissant à des fins qui n'entrent pas dans le cadre de son activité professionnelle
                            ou tout client, personne physique ou morale, agissant dans le cadre de son activité commerciale, industrielle,
                            artisanale ou libérale (ci-après le « <strong>Client</strong> ») réalisée sur le Site, à compter de la date de
                            mise à jour des présentes conditions.<br /><br />
                            Les présentes CGUV ont pour objet de définir les conditions de la commande des Services, et de déterminer les
                            droits et obligations respectifs de chacune des parties dans le cadre de la fourniture des Services.<br /><br />
                            Sauf accord écrit contraire des parties, ces CGUV priment sur toutes clauses contraires issues de conditions
                            générales antérieurement rédigées, et s’appliquent ainsi à l’exclusion de toute autre convention.<br /><br />
                            Les présentes CGUV doivent être considérées comme faisant partie intégrante et essentielle du contrat conclu
                            entre LE VENDEUR et chacun de ses Clients.<br /><br />
                            Ces CGUV sont systématiquement notifiées au Client lors de la création de son compte personnel sur le Site et de
                            sa commande sur le Site. Chaque Client doit nécessairement en prendre connaissance et les accepter afin de
                            pouvoir valider sa commande sur le Site. Cette acceptation consiste à cocher la case correspondant à la phrase
                            d’acceptation des présentes CGUV, telle que, par exemple, «
                            <i>Je reconnais avoir lu et accepté l’ensemble des conditions générales de vente publiées sur le Site</i>
                            ». Le fait de cocher cette case sera réputé avoir la même valeur qu’une signature manuscrite de la part du
                            Client.<br /><br />
                            En cas de modification et/ou adaptation des présentes CGUV par LE VENDEUR, seule la version en vigueur au jour
                            de la commande du Client sur le Site sera valablement applicable.<br /><br />
                            Le fait que LE VENDEUR ne se prévale pas, à un moment donné, de l’une quelconque des dispositions des présentes
                            CGUV ne peut être interprété comme valant renonciation à s’en prévaloir ultérieurement.<br /><br />
                            LE VENDEUR invite chaque Client à lire attentivement les présentes CGUV, à les imprimer et/ou les sauvegarder
                            sur tout support durable, avant de procéder à la commande de tout Service sur son Site.
                        </div>
                        <div class="mention-text">
                            <h2>2. ACCÈS ET DISPONIBILITÉ DU SITE</h2>
                            <strong
                                >L'accès aux Services est <u>exclusivement</u> réservé aux personnes physiques ayant plus de 18 ans, à la
                                recherche d’un emploi (à titre personnel) et aux personnes morales à la recherche de collaborateurs (H/F).
                                Il ne peut être utilisé qu’à ces fins.</strong
                            ><br /><br />
                            <strong
                                >Tout autre usage (constitution de fichiers à d’autres fins qu’un recrutement précis; promotion des services
                                d’une entreprise ; promotion de produits…) est formellement interdit.</strong
                            ><br /><br />
                            <strong
                                >En cas de commande d’un Service en infraction avec les présentes conditions générales, si elle est
                                détectée, elle ne sera pas réalisée et les sommes payées resteront définitivement acquises au
                                VENDEUR.</strong
                            ><br /><br />
                            LE VENDEUR propose un accès gratuit à son Site, le Client demeurant en toute hypothèse responsable de son
                            équipement informatique et de sa connexion à Internet, dont les coûts sont à sa charge.<br /><br />
                            L’accès au Site peut être réalisé :<br /><br />
                            • depuis un ordinateur ou un terminal équivalant disposant d'un accès à un ou plusieurs réseaux de
                            télécommunications permettant l'accès au réseau Internet et d'un logiciel de navigation sur le réseau Internet
                            (de type Internet Explorer, Mozilla Firefox, etc.) ;<br /><br />
                            • un terminal téléphonique disposant d'un accès à un réseau de télécommunications permettant l'accès au réseau
                            Internet (connexion 3G, 4G, Edge, wifi etc.).<br /><br />
                            LE VENDEUR fait ses meilleurs efforts pour rendre le Site accessible en permanence, sous réserve des opérations
                            de maintenance nécessaires au bon fonctionnement du Site ou des serveurs sur lesquels il est hébergé. En cas
                            d’interruption pour maintenance, LE VENDEUR ne pourra être tenu pour responsable de l’impact éventuel de cette
                            indisponibilité sur les activités du Client.<br /><br />
                            Le Client est averti des aléas techniques inhérents à l’Internet et des interruptions d’accès qui peuvent en
                            résulter. En conséquence, LE VENDEUR ne pourra être tenu responsable des éventuelles indisponibilités ou
                            ralentissements du Site.<br /><br />
                            Le Client est informé que les serveurs du VENDEUR sont hébergés par la société INFOMANIAK, Rue Eugène-Marziano
                            25, 1227 Genève, Suisse.<br /><br />
                            Tout manquement du Client aux obligations lui incombant en vertu des présentes CGUV sera susceptible d’entrainer
                            la suspension ou l’interdiction de l’accès du Client au Site.
                        </div>
                        <div class="mention-text">
                            <h2>3. MODALITES DE CONCLUSION DU CONTRAT</h2>
                            Les commandes de Service(s) sont réalisées par l’intermédiaire du Site.<br /><br />
                            La détention d’un compte utilisateur personnel est un préalable nécessaire et obligatoire afin de procéder à la
                            commande des Services sur le Site.<br /><br />
                            La création d'un compte utilisateur se fait grâce aux champs obligatoires suivants : Nom, prénom, adresse,
                            email, soit via Linkedin, Facebook ou Gmail. Le compte utilisateur permet d'accéder à une interface
                            personnalisée, de gérer son profil et ses commandes. Toutefois en cas d'information erronée, le client peut ne
                            pas recevoir d'email ou rencontrer d'autres difficultés pour passer, suivre ou recevoir sa commande.<br /><br />
                            Le Client choisit directement sur le Site le(s) Service(s) qu’il souhaite commander. LE VENDEUR s’efforce de
                            fournir des visuels et des descriptions les plus fidèles possibles aux Services. Toutefois, ces visuels et
                            textes d’illustration n’étant pas contractuels, le Client ne saurait engager la responsabilité du VENDEUR à ce
                            titre.<br /><br />
                            Le Client est tenu de renseigner un certain nombre d’informations le concernant en vue de valider sa commande.
                            Toutes les commandes doivent être dûment remplies et doivent contenir ces informations strictement nécessaires à
                            la commande. Le Client est responsable de la véracité, de l’exactitude et de la pertinence des données
                            fournies.<br /><br />
                            Le Client pourra procéder à des changements, des corrections, des ajouts ou même annuler sa commande, et ce,
                            jusqu'à la validation de celle-ci sur la page récapitulative de commande, avant le paiement.<br /><br />
                            La commande du Client, dont le montant ne peut être inférieure à un euro ne sera réalisée que si elle est
                            conforme aux présentes conditions générales et après validation par le VENDEUR.<br /><br />
                            Les délais de réalisation mentionnés par le Client ne sont que des souhaits et non une obligation pour le
                            VENDEUR.<br /><br />
                            En cas de validation d’une commande après la date d’envoi souhaitée, cette dernière sera automatiquement envoyée
                            dès validation.
                        </div>
                        <div class="mention-text">
                            <h2>4. PRIX ET MODALITES DE PAIEMENT</h2>
                            <h3>4.1. Prix</h3>
                            L’accès au Site ainsi qu’à la présentation des Services est gratuit pour le Client. Seule la commande d’un ou
                            plusieurs Services fera l’objet d’un règlement par ce dernier.<br /><br />
                            Les prix des Services sont mentionnés sur le Site en euros et toutes taxes comprises. Les prix applicables sont
                            ceux valables au jour de la commande sur le Site par le Client.<br /><br />
                            Les prix des Services et les éventuels coûts supplémentaires liés à la commande sont indiqués, de manière claire
                            et compréhensible, sur le récapitulatif de la commande. Avant de passer la commande, le Client est tenu de
                            confirmer ce récapitulatif.<br /><br />
                            <h3>4.2. Modalités de paiement</h3>
                            Les prix seront facturés sur la base des tarifs en vigueur au moment de la commande. Une facture récapitulant
                            l’ensemble des Services commandés par le Client ainsi que leur coût respectif sera systématiquement adressée à
                            ce dernier.<br /><br />
                            Le Client réglera le prix, directement sur le Site, et impérativement avant toute réalisation par LE VENDEUR, et
                            ce, conformément au processus prévu à cet effet.<br /><br />
                            LE VENDEUR utilise des systèmes de paiement sécurisé tiers : Paypal et Stripe.<br /><br />
                            Le règlement par le Client est un préalable nécessaire à la validation de sa commande.<br /><br />
                            Le défaut de paiement à l’échéance entraînera automatiquement, sans mise en demeure préalable et de plein droit,
                            la suspension ou l’invalidation de la commande du Client, sans préjudice de toute autre voie d’action.
                        </div>
                        <div class="mention-text">
                            <h2>5. DROIT DE RETRACTATION</h2>
                            <strong
                                >Le Site propose la vente de services pour lesquels le droit de rétractation des Clients Consommateurs ne
                                peut s'appliquer, en vertu de l'article L.221-28 du Code de la consommation.</strong
                            ><br /><br />
                            En effet, il s’agit de « services totalement exécutés avant la fin du délai de rétractation et dont l'exécution
                            a commencé avec votre accord ou votre demande expresse ».
                        </div>
                        <div class="mention-text">
                            <h2>6. RESPONSABILITE</h2>
                            <h3>6.1. Responsabilité du Client</h3>
                            <strong
                                >Le Client admet expressément utiliser le Site à ses propres risques et sous sa responsabilité
                                exclusive.</strong
                            ><br />
                            <strong>Il s’engage à fournir des informations fiables et à respecter la législation en vigueur.</strong><br />
                            Il est seul responsable de la qualité, de la précision, de la pertinence et de l’exactitude des informations
                            qu’il renseigne sur le Site aux fins de sa commande.<br />
                            Le Client est ainsi seul responsable à l’égard du VENDEUR et, le cas échéant, des tiers, de tous dommages,
                            directs ou indirects, de quelque nature que ce soit, causés par une information ou tout autre publication
                            communiquée, transmise ou diffusée à l’occasion des présentes, ainsi que de tout manquement de sa part aux
                            présentes stipulations contractuelles.<br />
                            Le Client est, en outre, seul responsable du choix des Services qu’il a commandés par l’intermédiaire du
                            Site.<br />
                            Tout Client s’engage à ne pas utiliser le Site en contravention de l’ensemble des lois, règles et
                            règlementations en vigueur.<br /><br />
                            <h3>6.2. Responsabilité du VENDEUR</h3>
                            La version actuelle du Site ne répond pas à tous les critères d’accessibilité aux handicapés. Elle est fournie «
                            en l’état », « avec toutes ses imperfections » et « telle que disponible ». Son utilisation se fait donc aux
                            risques et périls du Client. LE VENDEUR exclut toute garantie expresse ou implicite.<br /><br />
                            LE VENDEUR ne garantit pas que le service sera en parfaite adéquation avec les besoins et attentes du Client ;
                            qu’il sera ininterrompu, opportun, sûr ou dépourvu de toute erreur ; que les informations qu’il pourra y trouver
                            son fiables…<br /><br />
                            <strong>En tout état de cause, LE VENDEUR ne pourra en aucun cas être tenu responsable :</strong><br /><br />
                            – de tout dommage direct ou indirect, notamment en ce qui concerne les pertes d’opportunités ou de de profits,
                            le manque à gagner, les pertes de Clientèle, de données pouvant entre autres résulter de l’utilisation du site,
                            ou au contraire de l’impossibilité de son utilisation ;<br /><br />
                            – d’un dysfonctionnement, d’une indisponibilité d’accès, d’une mauvaise utilisation, d’une mauvaise
                            configuration de l’ordinateur de l’Utilisateur, ou encore de l’emploi d’un navigateur peu usité par
                            l’Utilisateur ;<br /><br />
                            – du contenu des publicités et autres liens ou sources externes accessibles par l’Utilisateur à partir du
                            site.<br /><br />
                            LE VENDEUR ne peut être tenu responsable de tout dommage, qui serait imputable soit au Client, soit au fait
                            imprévisible et insurmontable d’un tiers étranger au contrat, soit à un cas de force majeure.<br /><br />
                            <strong
                                >De façon générale, il est expressément convenu que LE VENDEUR n’a aucune obligation de résultat.</strong
                            >
                        </div>
                        <div class="mention-text">
                            <h2>7. FORCE MAJEURE</h2>
                            La « force majeure » se définit comme tout évènement échappant au contrôle de l’une des parties et insusceptible
                            d’être raisonnablement prévu lors de la conclusion des présentes CGUV. Un tel évènement sera caractérisé dès
                            lors que la partie, victime d’un tel évènement, serait empêchée d’exécuter convenablement ses obligations
                            contractuelles, et ce, malgré la mise en œuvre de mesures adéquates et appropriées destinées à en limiter les
                            effets.<br /><br />
                            Aucune des deux parties ne sera tenue pour responsable vis-à-vis de l'autre de la non-exécution ou des retards
                            dans l'exécution d'une obligation née des présentes CGUV qui seraient dus au fait de l'autre partie
                            consécutivement à la survenance d'un cas de force majeure, tel que reconnu et défini par la jurisprudence
                            française.<br /><br />
                            Le cas de force majeure suspend les obligations nées des présentes CGUV pendant toute la durée de son existence,
                            et aucune des parties ne pourra, pendant cette période, valablement se prévaloir de l’existence d’un tel cas de
                            force majeure au sens de l’article 1218 du Code civil afin de justifier la fin de sa relation contractuelle avec
                            l’autre partie. Toutefois, si le cas de force majeure avait une durée d'existence supérieure à trente (30) jours
                            consécutifs, il ouvrirait droit à la résiliation de plein droit des présentes CGUV par l'une ou l'autre des
                            parties, huit (8) jours après l'envoi d'une lettre recommandée avec avis de réception notifiant cette décision.
                        </div>
                        <div class="mention-text">
                            <h2>8. PROPRIETE INTELLECTUELLE</h2>
                            Le Site, ainsi que les bases de données, textes, documents, informations, images, photographies, graphismes,
                            logos, ou toutes autres données demeurent la propriété exclusive du VENDEUR ou, le cas échéant, de leurs
                            titulaires respectifs dont LE VENDEUR a obtenu les autorisations d’exploitation.<br /><br />
                            LE VENDEUR demeure titulaire de l’ensemble des droits de propriété intellectuelle afférents à sa marque déposée,
                            ainsi que de l’ensemble des droits de propriété intellectuelle et des droits d’auteur afférents à tout autre
                            signe distinctif lui appartenant.<br /><br />
                            Toute reproduction et/ou représentation, téléchargement, traduction, adaptation, exploitation, distribution,
                            diffusion et/ou communication, sous quelque forme que ce soit, à titre commercial ou non, de toute ou partie de
                            la marque et/ou d’une œuvre de l’esprit originale ou donnée contenue sur le Site est formellement interdite. Le
                            Client s’interdit également tout agissement et tout acte susceptible de porter atteinte directement ou
                            indirectement aux droits de propriété intellectuelle du VENDEUR.
                        </div>
                        <div class="mention-text">
                            <h2>9. PROTECTION DES DONNEES PERSONNELLES</h2>
                            Le Client est informé que la création de son compte personnel sur le Site ainsi que sa commande de Services sur
                            le Site donne lieu à la collecte et au traitement automatisé de données à caractère personnel le concernant par
                            LE VENDEUR dont l'utilisation est soumise aux dispositions de la loi n° 78-17 du 6 janvier 1978 relative à
                            l'Informatique, aux Fichiers et aux Libertés, telle que modifiée par la loi n° 2016-1321 du 7 octobre 2016 et le
                            Règlement européen 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du
                            traitement des données à caractère personnel et à la libre circulation de ces données (ci-après le « RGPD »).<br /><br />
                            LE VENDEUR met à la disposition du Client, sur son Site, une charte de confidentialité spécifiant l’ensemble des
                            informations afférentes à l’utilisation des données à caractère personnel du Client collectées par LE VENDEUR et
                            aux droits dont le Client dispose vis-à-vis de ces données personnelles.
                        </div>
                        <div class="mention-text">
                            <h2>10. COOKIES</h2>
                            Ce site n'a pas de cookies, cependant, notre système de chat en utilise.<br />
                            Vous trouverez ici sa politique de confidentialité. (<a href="https://crisp.chat/fr/privacy/"
                                >https://crisp.chat/fr/privacy/</a
                            >)
                        </div>
                        <div class="mention-text">
                            <h2>11. LIENS HYPERTEXTES</h2>
                            Le Site peut inclure des liens hypertextes vers d’autres sites.<br /><br />
                            LE VENDEUR ne peut, en aucun cas, être tenu de la disponibilité technique de sites Internet ou d’applications
                            mobiles exploités par des tiers auxquels le Client accéderait par l’intermédiaire du Site. En outre, LE VENDEUR
                            ne peut donc supporter aucune responsabilité quant aux contenus, publicités, produits et services disponibles
                            sur ou à partir de ces sites, applications mobiles ou sources externes.<br /><br />
                            Si, en dépit des efforts du VENDEUR, un des liens hypertextes présents sur le Site pointait vers un site ou une
                            source internet dont le contenu était ou paraissait non conforme aux exigences de la loi française à un Client,
                            celui-ci s’engage à prendre immédiatement contact avec le directeur de la publication du Site, dont les
                            coordonnées figurent dans les mentions légales publiées sur le Site, afin de lui communiquer l’adresse des pages
                            du site tiers en cause. LE VENDEUR fera alors le nécessaire pour supprimer le lien hypertexte concerné.
                        </div>
                        <div class="mention-text">
                            <h2>12. DROIT APPLICABLE ET JURIDICTION COMPETENTE</h2>
                            Les présentes CGUV sont régies par le droit Français.<br /><br />
                            En cas de litige auquel les présentes CGUV (ou l’une de leurs clauses) et/ou les relations entre les parties
                            pourraient donner lieu, les Tribunaux de Paris seront seuls compétents pour connaître du litige.<br /><br />
                            Selon l'article L.612-1 du Code de la consommation, il est rappelé que «
                            <i
                                >tout consommateur a le droit de recourir gratuitement à un médiateur de la consommation en vue de la
                                résolution amiable du litige qui l’oppose à un professionnel. A cet effet, le professionnel garantit au
                                consommateur le recours effectif à un dispositif de médiation de la consommation</i
                            >
                            ».<br /><br />
                            Conformément à l'ordonnance n°2015-1033 du 20 août 2015 et au décret d’application n°2015-1382 du 30 octobre
                            2015, tout différend ou litige dit de consommation, sous réserve de l'article L.612-2 du code de la
                            consommation, peut faire l'objet d'un règlement amiable par médiation auprès d’un médiateur dont les coordonnées
                            vous seront transmises sur demande.<br /><br />
                            Il est rappelé que la médiation n'est pas obligatoire mais uniquement proposée afin de résoudre les litiges en
                            évitant un recours à la justice.
                        </div>
                        <div class="mention-text">
                            <h2>13. SERVICE CLIENT</h2>
                            Le service clientèle du présent Site est accessible par courrier électronique à l’adresse suivante :<br />
                            <a href="mailto:contact@avenuedesrecruteurs.fr">contact@avenuedesrecruteurs.fr</a>.
                        </div>
                        <div class="mention-text">
                            <h2>14. PARTENARIAT AVEC LE CLUB BUTTERFLY</h2>
                            Lorsque leur inscription aura été validée, les Recruteurs\teuses recevront un courriel contenant un code leur
                            permettant de bénéficier des privilèges du Club Butterfly (<a href="https://club-butterfly.fr"
                                >https://club-butterfly.fr</a
                            >) hors services nécessitant l’acquisition d’une carte Butterfly. Ce cadeau est temporaire et peut-être
                            interrompu à tout instant sans indemnité ni préavis. Profitez-en vite !
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
