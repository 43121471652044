import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-modifier-mail',
    templateUrl: './modifier-mail.component.html',
    styleUrls: ['./modifier-mail.component.css'],
})
export class ModifierMailComponent implements OnInit {
    mail = {
        _id: '',
        nom: '',
        objet: '',
        titre: '',
        texte: '',
    };
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.mail._id = this.route.snapshot.params.id;
        this.httpClient.get<any>(this.globalService.url + '/mail/' + this.mail._id).subscribe(
            (response) => {
                if (response.status) {
                    this.mail = response.mail;
                    console.log(this.mail);
                }
            },
            () => {}
        );
    }

    modifier(): void {
        this.httpClient.post<any>(this.globalService.url + '/mail/update', this.mail).subscribe(
            (response) => {
                if (response.status) {
                    alert('Courriel modifié');
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
