import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MdbTablePaginationComponent, MdbTableDirective } from '../../../components/mdb/free/tables';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { GlobalService } from '../../../services/global.service';
import { faTimes, faSort } from '@fortawesome/free-solid-svg-icons';
import { GlobalVariable } from '../../../global';
import { DeviceDetectorService } from 'ngx-device-detector';
import Utils from '../../../utils';
import { ValidateService } from '../../../services/validate.service';

@Component({
    selector: 'app-diffusion-non-souhaitee',
    templateUrl: './diffusion-non-souhaitee.component.html',
    styleUrls: [
        './diffusion-non-souhaitee.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class DiffusionNonSouhaiteeComponent implements OnInit, AfterViewInit {
    @ViewChild(MdbTablePaginationComponent, { static: true })
    mdbTablePagination!: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true })
    mdbTable!: MdbTableDirective;

    faTimes = faTimes;
    public retrait: string[] = [];
    public tableData: any[] = [];
    public nouveau = '';
    previous: any = [];
    headElements = ['Email / domaine à retirer', 'Retirer'];
    displayByPages = GlobalVariable.PAGES;
    faSort = faSort;

    isMobile = false;

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private cdRef: ChangeDetectorRef,
        private toastrService: ToastService,
        private validateService: ValidateService,
        private deviceService: DeviceDetectorService
    ) {}

    ngOnInit(): void {
        this.getNonSouhaitee();
        this.isMobile = this.deviceService.isMobile();
    }
    ngAfterViewInit(): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
    refreshData(retrait: any): void {
        this.tableData = [];
        retrait.forEach((element: string) => {
            this.tableData.push({
                text: element,
            });
        });
        this.mdbTable.setDataSource(this.tableData);
        this.tableData = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
    }
    getNonSouhaitee(): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/diffusion-non-souhaitee/' + localStorage.getItem('id')).subscribe(
            (response) => {
                if (response.status) {
                    this.retrait = response.retrait;
                    this.refreshData(this.retrait);
                }
            },
            () => {}
        );
    }
    display(): string {
        if (this.retrait.length === 0) {
            return 'none';
        }
        return 'table';
    }
    ajout(): void {
        if (this.validateService.containAt(this.nouveau)) {
            this.retrait.push(this.nouveau);
            this.nouveau = '';
            this.mdbTable.setDataSource(this.retrait);
            this.retrait = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            this.modifier();
        } else {
            this.toastrService.error('Le texte doit contenir un @', '', {
                opacity: 1,
            });
        }
    }
    retirer(text: string): void {
        const newRetrait = [];
        for (const r of this.retrait) {
            if (r !== text) {
                newRetrait.push(r);
            }
        }
        this.retrait = newRetrait;
        this.modifier();
    }
    modifier(): void {
        this.refreshData(this.retrait);
        this.httpClient
            .patch<any>(
                this.globalService.url + '/candidat/diffusion-non-souhaitee',
                {
                    id: localStorage.getItem('id'),
                    diffusionNonSouhaitee: this.retrait,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.toastrService.info('Votre liste de retrait a bien été sauvegardée', '', { opacity: 1 });
                    }
                },
                () => {}
            );
    }
    changeItemsByPage(event: any): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(event);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
    }
}
