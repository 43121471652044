<app-site-header></app-site-header>

<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-recruteur-header></app-recruteur-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10">
        <div class="page-inner">
            <div class="page-title">
                <h3 class="breadcrumb-header">Mes avantages</h3>
            </div>
            <section>
              <section class="my-5">

                <div class='mb-5 mt-5' *ngFor="let avantage of avantages">

                  <div class="page-sub-title">
                    <h2 class="breadcrumb-sub">{{ avantage.titre }}</h2>
                  </div>
                  <!-- Grid row -->
                  <span *ngFor="let post of avantage.posts; let last = last;">
                  <div class="row mb-5 mt-5" >

                    <!-- Grid column -->
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3">

                      <!-- Featured image -->
                      <div class="view overlay rounded z-depth-0 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                        <img class='mx-auto mx-md-0' style='width: 167px; height: 167px' src="{{ url }}/images/avantages/{{ post.image }}">
                      </div>

                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-12 col-md-6 col-lg-8 col-xl-9  pl-md-0" >
                      <div class='text-description' [innerHTML]='post.contenu | safeHtml'>

                      </div>
                      <button
                        [disabled]='recruteur.formations.includes(post.course)'
                        *ngIf='post.showButton'
                        (click)="joinAdvantage(post)"
                        class="ml-4 d-flex mx-auto mt-4"
                        mdbBtn
                        [rounded]="true"
                        color="warning"
                      >
                        {{ post.price > 0 ? 'Acheter' : 'Rejoindre' }}
                      </button>
                    </div>

                    <!-- Grid column -->

                  </div>
<!--                     <hr *ngIf='!last' />-->
                    </span>

                  <!-- Grid row -->
                </div>




              </section>
              <!-- Section: Blog v.1 -->

            </section>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
