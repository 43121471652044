import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { ModalDirective } from '../../../components/mdb/free/modals';

@Component({
    selector: 'app-accueil',
    templateUrl: './accueil.component.html',
    styleUrls: ['./accueil.component.css', './../../../../assets/css/site/main.css'],
})
export class AccueilComponent implements OnInit, AfterViewInit {
    @ViewChild('ululModal', { static: true }) ululModal!: ModalDirective;

    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}
    ngOnInit(): void {}
    ngAfterViewInit(): void {
        // this.ululModal.show();
    }
}
