import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from '../../mdb/free/modals';
import { ReportModalComponent } from '../report-modal/report-modal.component';

@Component({
    selector: 'app-modal-recruiter',
    templateUrl: './recruiter-form.component.html',
    styleUrls: ['./recruiter-form.component.css', './../../../../assets/css/administration/space.min.css'],
})
export class RecruiterFormComponent implements OnInit {
    @ViewChild('recruteurModal', { static: true }) recruteurModal!: ModalDirective;
    @ViewChild('reportModal', { static: true }) reportModal!: ReportModalComponent;

    @Input() recruteur: any;
    @Output() selected: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnInit(): void {}
    openForm(): void {
        this.recruteurModal.show();
    }
}
