import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'firstUpperText',
})
export class FirstUpperTextPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        // @ts-ignore
        if (!value) return value;
        return value[0].toUpperCase() + value.substr(1).toLowerCase();
    }
}
