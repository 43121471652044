<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-candidat/informations-personnelles" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pl-0 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-candidat/informations-personnelles" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span>Informations personnelles</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Informations personnelles</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Modification de vos informations personnelles</h2>
            </div>

            <div class="pt-2 pb-5 d-flex justify-content-center" style="font-size: 25px; font-weight: bold; color: #0149b3">
                {{ candidat.nom }} {{ candidat.prenom }}
            </div>
            <div class="pt-2 d-flex justify-content-center">
                <img
                    style="object-fit: cover; width: 100px; height: 100px"
                    class="img-preload rounded-circle img-fluid"
                    *ngIf="this.candidat.photo !== ''"
                    [src]="this.candidat.photo"
                />
                <label class="ml-2" for="photo"
                    >Photo d'identité<br /><small>(Fichier accepté: .png, .jpg - Taille maximum: 5Mo)</small></label
                >
                <span for="photo" class="error" [ngStyle]="{ display: this.error.photo ? 'block' : 'none' }">Obligatoire</span>
            </div>
            <div class="d-flex justify-content-center">
                <div class="file-field md-form">
                    <div mdbBtn [rounded]="true" [outline]="true" color="secondary" size="md" mdbWavesEffect>
                        <span class="pt-1">Choisir un fichier</span>
                        <input type="file" name="photo" mdbFileSelect (change)="photo($event)" />
                    </div>
                </div>
            </div>

            <div class="inscription-row d-flex flex-wrap">
                <div class="col-12 col-md-6">
                    <label for="nationalite">Nationalité(s) * <small>( Ce champ est multiple )</small></label>
                  <span
                    for="nationalite"
                    class="error"
                    [ngStyle]="{
                                        display: this.error.nationalites ? 'block' : 'none'
                                    }"
                  >Obligatoire</span
                  >
                    <div class="md-form">
                        <input
                            type="text"
                            name="nationalite"
                            class="completer-input form-control mdb-autocomplete mb-0"
                            [ngModel]="searchNatio | async"
                            (ngModelChange)="searchNatio.next($event)"
                            [mdbAutoCompleter]="auto"
                            placeholder="Rechercher"
                        />
                        <mdb-auto-completer class="visible" #auto="mdbAutoCompleter" textNoResults="Pas de résultat">
                            <mdb-option
                                *ngFor="let option of resultsNatio | async"
                                [value]="option.nationalite"
                                (click)="chooseNationalite(option.nationalite)"
                            >
                                <div class="d-flex flex-column">
                                    <strong>{{ option.nationalite }}</strong>
                                </div>
                            </mdb-option>
                        </mdb-auto-completer>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <div style="position: relative" *ngFor="let n of candidat.nationalites">
                            <label class="container_radio version_2" style="padding-left: 16px"
                                >{{ n }}
                                <input type="radio" name="positions" value="Backend-Developer" disabled />
                            </label>
                            <div class="container-cross" (click)="removeNationalite(n)">
                                <div class="cross">x</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group inscription-row col-12">
                <div class="col-12 col-md-6">
                    <label>Lieu de résidence *</label>
                    <app-select-country [model]="candidat.paysCode" (selected)="setPaysCode($event)"></app-select-country>
                </div>
            </div>
            <div class="inscription-row d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-2">
                    <div class="form-group">
                        <label
                            for="adresse"
                            [ngClass]="{
                                'fl-label-empty': this.candidat.adresse === '',
                                'fl-label-not-empty': this.candidat.adresse !== ''
                            }"
                            >Adresse *</label
                        >
                        <span
                            for="adresse"
                            class="error"
                            [ngStyle]="{
                                display: this.error.adresse ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >

                        <app-select-adress [user]="candidat"></app-select-adress>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-2">
                    <div class="form-group">
                        <label
                            for="complementAdresse"
                            [ngClass]="{
                                'fl-label-empty': this.candidat.complementAdresse === '',
                                'fl-label-not-empty': this.candidat.complementAdresse !== ''
                            }"
                            >Complément d'adresse</label
                        >
                        <input
                            type="text"
                            name="complementAdresse"
                            id="complementAdresse"
                            class="form-control"
                            [(ngModel)]="this.candidat.complementAdresse"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-3">
                    <div class="form-group">
                        <label
                            for="codePostal"
                            [ngClass]="{
                                'fl-label-empty': this.candidat.codePostal === '',
                                'fl-label-not-empty': this.candidat.codePostal !== ''
                            }"
                            >Code postal *</label
                        >
                        <span
                            for="codePostal"
                            class="error"
                            [ngStyle]="{
                                display: this.error.codePostal ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <input type="text" name="codePostal" id="codePostal" class="form-control" [(ngModel)]="this.candidat.codePostal" />
                    </div>
                </div>
                <div class="col-6 mt-3">
                    <div class="form-group">
                        <label
                            for="ville"
                            [ngClass]="{
                                'fl-label-empty': this.candidat.ville === '',
                                'fl-label-not-empty': this.candidat.ville !== ''
                            }"
                            >Ville *</label
                        >
                        <span
                            for="ville"
                            class="error"
                            [ngStyle]="{
                                display: this.error.ville ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <input type="text" name="ville" id="ville" class="form-control" [(ngModel)]="this.candidat.ville" />
                    </div>
                </div>
<!--                <div class="col-6 mt-3">-->
<!--                    <div class="form-group">-->
<!--                        <div class="form-group d-inline">-->
<!--                            {{ this.candidat.pays }}-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>

            <div class="form-group inscription-row">
                <label>Téléphone * (au moins un)</label>
                <span
                    for="telephonePortable"
                    class="error"
                    [ngStyle]="{
                        display: this.error.telephonePortable ? 'block' : 'none'
                    }"
                    >Obligatoire</span
                >
                <div class="row mt-2">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="telephonePortable">Téléphone Portable</label>
                            <input
                                type="tel"
                                name="telephonePortable"
                                id="telephonePortable"
                                class="form-control"
                                [(ngModel)]="this.candidat.telephonePortable"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="telephoneFixe">Téléphone Fixe</label>
                            <input
                                type="tel"
                                name="telephoneFixe"
                                id="telephoneFixe"
                                class="form-control"
                                [(ngModel)]="this.candidat.telephoneFixe"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center" style="position: relative">
                <p
                    style="color: red !important"
                    [ngStyle]="{
                        display: this.errorPage.status ? 'block' : 'none'
                    }"
                >
                    {{ errorPage.message }}
                </p>
                <p class="mention-obligatoire" style="color: red !important">* Obligatoire</p>
                <p *ngIf="photoUpload == '2'">
                    <img src="./assets/img/gif.gif" style="max-width: 25px" />
                    Photo en cours de sauvegarde, veuillez patienter
                </p>
            </div>
            <div class="d-flex float-right mb-3">
                <button mdbBtn class="text-center" [rounded]="true" color="warning" size="lg" (click)="sendActuel()">Valider</button>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
