import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
import { debounceTime, map, startWith } from 'rxjs/operators';
import Utils from '../../../utils';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-select-adress',
    templateUrl: './adress-select.component.html',
    styleUrls: [
        './adress-select.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class AdressSelectComponent implements OnInit {
    @Input() model: any;
    @Input() user: any;
    @Output() selected: EventEmitter<any> = new EventEmitter();

    paysNonSelectionner = false;
    oldSearch = '';
    loading = false;
    addrs: any = [];
    searchAdresse = new Subject<string>();
    resultsAdresse: Observable<any> | undefined;
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService
    ) {
        this.resultsAdresse = this.searchAdresse.pipe().pipe(
            startWith(''),
            map((value: string) => Utils.filterAdresse(value, this.addrs)),
            debounceTime(500)
        );
    }
    ngOnInit(): void {}
    changeAddr(event: any): void {
        this.user.adresse = event;
        if (this.user.paysCode !== '') {
            this.paysNonSelectionner = false;

            this.loading = true;
            setTimeout(() => {
                if (this.oldSearch !== this.user.adresse) {
                    this.oldSearch = this.user.adresse;
                    this.getAddrs();
                }
            }, 2000);
        } else {
            this.paysNonSelectionner = true;
        }
    }
    getAddrs(): void {
        let addr = this.user.adresse.split(' ').join('+');
        if (this.user.complementAdresse !== '') {
            addr += '+' + this.user.complementAdresse.split(' ').join('+');
        }
        if (this.user.codePostal !== '') {
            addr += '+' + this.user.codePostal.split(' ').join('+');
        }
        if (this.user.ville !== '') {
            addr += '+' + this.user.ville.split(' ').join('+');
        }
        if (addr === '') {
            return;
        }
        this.httpClient.get<any>(this.globalService.url + '/adresse/' + this.user.paysCode + '/' + addr, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.addrs = response.addrs;
                    this.searchAdresse.next(this.user.adresse);
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                }
            },
            () => {}
        );
    }
    chooseAddr(adresse: string, codePostal: string, ville: string, latitude: number, longitude: number): void {
        this.user.adresse = adresse;
        this.user.codePostal = codePostal;
        this.user.ville = ville;
        // this.user.pays = 'France';

        this.user.latitude = latitude.toString();
        this.user.longitude = longitude.toString();

        this.addrs = [];
    }
    emit(event: any): void {
        this.selected.emit(event);
    }
}
