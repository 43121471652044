<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Témoignages de candidat(e)s</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table class="display table dataTable">
                                    <thead>
                                        <tr>
                                            <th>Photo</th>
                                            <th>Modifier photo</th>
                                            <th>Nom client</th>
                                            <th>Témoignage</th>
                                            <th>Ordre</th>
                                            <th>Modifier</th>
                                            <th>Supprimer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input type="file" name="photo" id="photo" (change)="photo($event, '-1')" />
                                            </td>
                                            <td>#</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Nom client..."
                                                    class="form-control"
                                                    [(ngModel)]="nouveau.nom"
                                                    [ngStyle]="{
                                                        border: erreurAjout ? '1px solid red' : ''
                                                    }"
                                                />
                                            </td>
                                            <td>
                                                <textarea
                                                    rows="6"
                                                    placeholder="Témoignage..."
                                                    class="form-control"
                                                    [(ngModel)]="nouveau.texte"
                                                    [ngStyle]="{
                                                        border: erreurAjout ? '1px solid red' : ''
                                                    }"
                                                ></textarea>
                                            </td>
                                            <td colspan="3">
                                                <div>
                                                    <button class="btn btn-info" (click)="ajouter()">Ajouter un témoignage</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let t of temoignages">
                                            <td>
                                                <img style="max-height: 90px" src="{{ url }}/images/temoignages/{{ t.photo }}" />
                                            </td>
                                            <td>
                                                <input
                                                    type="file"
                                                    class="form-control"
                                                    [(ngModel)]="t.nouvellePhoto"
                                                    (change)="photo($event, t._id)"
                                                />
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" [(ngModel)]="t.nom" />
                                            </td>
                                            <td>
                                                <textarea rows="6" type="number" class="form-control" [(ngModel)]="t.texte"></textarea>
                                            </td>
                                            <td>
                                                <select class="form-control" (change)="modifierOrdre(t._id, $event)">
                                                    <option *ngFor="let tt of tableau" value="{{ tt }}" [selected]="estOrdre(t._id, tt)">
                                                        {{ tt }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <button class="btn btn-success" (click)="modifier(t._id)">Modifier</button>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <button class="btn btn-danger" (click)="supprimer(t._id)">Supprimer</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
