import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCheckCircle, faTimes, faArrowLeft, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { GlobalVariable } from '../../../global';
import Utils from '../../../utils';
import { UserService } from '../../../services/user.service';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

@Component({
    selector: 'app-recherche-candidats',
    templateUrl: './recherche.component.html',
    styleUrls: [
        './recherche.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class RechercheComponent implements OnInit {
    faFilePdf = faFilePdf;
    arrowLeft = faArrowLeft;
    faError = faTimes;
    faOk = faCheckCircle;

    searchCity = new Subject<string>();
    resultsCity: Observable<any> | undefined;

    dateDisponibilite = '';
    dateDisponibiliteHistorique = '';

    mobilite = {
        regional: false,
        national: false,
        international: false,
    };
    addrsCheck = false;

    pays: any = [];
    listePays: any = [];

    searchPays = new Subject<string>();
    filteredOptions: Observable<any[]>;

    addrs: any = [];

    mobiliteRayon = {
        ville: '',
        villeSave: '',
        pays: '',
        latitude: '',
        longitude: '',
        rayon: 5,
    };
    typesDispos = GlobalVariable.DISPOS;
    typesTeletravail = GlobalVariable.TELETRAVAIL;
    typesSituations = GlobalVariable.SITUATIONS;

    dispo = {
        travail: '',
        preavis: '',
    };
    ancienDateDisponibilite = '';

    candidat: any = {
        password: '',
        situation: '',
    };
    errorPage = {
        status: false,
        message: '',
    };
    actuel = {
        pays: '',
        paysCache: '',
        metier: '',
        metierCache: '',
        secteur: '',
        secteurCache: '',
        langue: '',
        langueCache: '',
        secteurRechercher: '',
        secteurRechercherCache: '',
    };
    poste = {
        salarie: false,
        independant: false,
        stagiaire: false,
        alternant: false,
        benevole: false,
        autre: false,
    };
    temps = {
        plein: false,
        partiel: false,
    };
    posteVoulu = {
        cdd: false,
        cdi: false,
    };
    error = {
        fonctionLibre: false,
        secteurActivite: false,
        experienceSecteur: false,
        experience: false,
        posteRechercher: false,
        niveauEtude: false,
        langues: false,
        fonction: false,
        situation: false,
        mobilite: false,
        teleTravail: false,
        disponibiliteDate: false,
        disponibilite: false,
    };

    errorRemuneration = {
        status: false,
        message: '',
    };

    url = '';
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private userService: UserService,
        private toastrService: ToastService
    ) {
        this.filteredOptions = this.searchPays.pipe(
            startWith(''),
            map((value: string) => Utils.filter(value, this.listePays))
        );

        this.resultsCity = this.searchCity.pipe(
            startWith(''),
            map((value: string) => Utils.filterCity(value, this.addrs))
        );
    }

    ngOnInit(): void {
        this.candidat._id = localStorage.getItem('id');
        this.url = this.globalService.url;
        this.getPays();
        this.getAccount();
    }

    getPays(): void {
        this.httpClient.get<any>(this.globalService.url + '/pays/getPays').subscribe(
            (response) => {
                if (response.status) {
                    this.listePays = response.pays;
                    this.searchPays.next('');
                }
            },
            () => {}
        );
    }

    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.candidat = response.candidat;
                    if (this.candidat.dispo !== undefined && this.candidat.dispo !== null) {
                        this.dispo.travail = this.candidat.dispo.travail;
                        this.dispo.preavis = this.candidat.dispo.preavis;
                    }
                    if (this.candidat.rayon === -1) {
                        this.candidat.rayon = 0;
                    }

                    this.ancienDateDisponibilite = this.candidat.dateDisponibilite;

                    if (this.candidat.remunerationSouhaiter === []) {
                        this.candidat.remunerationSouhaiter = [0, 0, 0, 0];
                    }
                    if (this.candidat.mobilite_locale.length > 0) {
                        this.mobilite.regional = true;
                    }
                    this.mobilite.national = this.candidat.mobilite_nationale.length > 0;

                    this.mobilite.international = this.candidat.mobilite_internationale;

                    for (const t of this.candidat.typePoste) {
                        if (t === 'salarie') {
                            this.poste.salarie = true;
                        } else if (t === 'independant') {
                            this.poste.independant = true;
                        } else if (t === 'stagiaire') {
                            this.poste.stagiaire = true;
                        } else if (t === 'alternant') {
                            this.poste.alternant = true;
                        } else if (t === 'benevole') {
                            this.poste.benevole = true;
                        } else if (t === 'autre') {
                            this.poste.autre = true;
                        }
                    }
                    for (const t of this.candidat.typePosteVoulu) {
                        if (t === 'cdd') {
                            this.posteVoulu.cdd = true;
                        } else if (t === 'cdi') {
                            this.posteVoulu.cdi = true;
                        }
                    }
                    for (const t of this.candidat.temps) {
                        if (t === 'plein') {
                            this.temps.plein = true;
                        } else if (t === 'partiel') {
                            this.temps.partiel = true;
                        }
                    }
                    this.dateDisponibiliteHistorique = this.candidat.dateDisponibilite;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    renouvellerErreur(): void {
        this.errorPage.status = false;
        this.error = {
            fonction: false,
            fonctionLibre: false,
            secteurActivite: false,
            teleTravail: false,
            posteRechercher: false,
            mobilite: false,
            niveauEtude: false,
            langues: false,
            experienceSecteur: false,
            experience: false,
            situation: false,
            disponibiliteDate: false,
            disponibilite: false,
        };
    }
    send(): void {
        this.renouvellerErreur();
        const typePoste = [];

        if (this.poste.alternant) {
            typePoste.push('alternant');
        }
        if (this.poste.autre) {
            typePoste.push('autre');
        }
        if (this.poste.benevole) {
            typePoste.push('benevole');
        }
        if (this.poste.independant) {
            typePoste.push('independant');
        }
        if (this.poste.salarie) {
            typePoste.push('salarie');
        }
        if (this.poste.stagiaire) {
            typePoste.push('stagiaire');
        }

        const typePosteVoulu = [];
        if (this.posteVoulu.cdd) {
            typePosteVoulu.push('cdd');
        }
        if (this.posteVoulu.cdi) {
            typePosteVoulu.push('cdi');
        }

        const temps = [];
        if (this.temps.partiel) {
            temps.push('partiel');
        }
        if (this.temps.plein) {
            temps.push('plein');
        }
        let ok = true;
        if (!this.mobilite.regional && !this.mobilite.national && !this.mobilite.international) {
            this.error.mobilite = true;
            ok = false;
        }
        if (this.candidat.situation === '') {
            this.error.situation = true;
            ok = false;
        }
        if (this.candidat.teleTravail === '') {
            this.error.teleTravail = true;
            ok = false;
        }
        if (this.candidat.posteRechercher.length === 0) {
            this.error.posteRechercher = true;
            ok = false;
        }
        if (this.dispo.travail === '1' && this.dispo.preavis === '2' && this.candidat.disponibilite === '') {
            this.error.disponibilite = true;
            ok = false;
        } else if (this.dispo.travail === '2' || this.dispo.preavis === '1') {
            if (this.dispo.travail === '2') {
                this.dispo.preavis = '1';
            }
            // if (this.dateDisponibilite === '' && this.ancienDateDisponibilite !== '') {
            //     this.dateDisponibilite = dayjs(this.ancienDateDisponibilite, 'YYYY-MM-DD').format('DD-MM-YYYY');
            // }
            if (this.dateDisponibilite !== '') {
                const isAfter = dayjs(this.dateDisponibilite, 'DD-MM-YYYY').isAfter(dayjs().subtract(1, 'day'));
                if (!isAfter) {
                    this.error.disponibiliteDate = true;
                    ok = false;
                }
            } else if (this.dateDisponibilite === '' && this.ancienDateDisponibilite !== '') {
                this.dateDisponibilite = dayjs(this.ancienDateDisponibilite, 'YYYY-MM-DD').format('DD-MM-YYYY');
            } else {
                this.error.disponibiliteDate = true;
                ok = false;
            }
        }
        if (ok) {
            if (this.mobilite.international) {
                this.candidat.mobilite_internationale = true;
            } else {
                this.candidat.mobilite_internationale = false;
            }
            let remu = [];
            // tslint:disable-next-line:radix
            if (Number.parseInt(this.candidat.remunerationSouhaiter[0]) > 0) {
                remu.push(this.candidat.remunerationSouhaiter[0]);
            } else {
                remu.push(0);
            }
            // tslint:disable-next-line:radix
            if (Number.parseInt(this.candidat.remunerationSouhaiter[1]) > 0) {
                remu.push(this.candidat.remunerationSouhaiter[1]);
            } else {
                remu.push(0);
            }
            // tslint:disable-next-line:radix
            if (Number.parseInt(this.candidat.remunerationSouhaiter[2]) > 0) {
                remu.push(this.candidat.remunerationSouhaiter[2]);
            } else {
                remu.push(0);
            }
            // tslint:disable-next-line:radix
            if (Number.parseInt(this.candidat.remunerationSouhaiter[3]) > 0) {
                remu.push(this.candidat.remunerationSouhaiter[3]);
            } else {
                remu.push(0);
            }

            const rechercheRequest = this.httpClient.patch(
                this.globalService.url + '/candidat/situation',
                {
                    id: this.candidat._id,
                    situation: this.candidat.situation,
                    disponibilite: this.candidat.disponibilite,
                    dateDisponibilite: this.dateDisponibilite ? dayjs(this.dateDisponibilite, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
                    posteRechercher: this.candidat.posteRechercher,
                    teleTravail: this.candidat.teleTravail,
                    secteurRechercher: this.candidat.secteurRechercher,
                    typePoste,
                    typePosteVoulu,
                    temps,
                    dispo: this.dispo,
                },
                {}
            );

            const complementRequest = this.httpClient.patch(
                this.globalService.url + '/candidat/complementSituation',
                {
                    id: this.candidat._id,
                    mobilite_locale: this.candidat.mobilite_locale,
                    mobilite_regionale: this.candidat.mobilite_regionale,
                    mobilite_nationale: this.candidat.mobilite_nationale,
                    mobilite_internationale: this.candidat.mobilite_internationale,
                    remunerationSouhaiter: remu,
                },
                {}
            );

            forkJoin([rechercheRequest, complementRequest]).subscribe((results: Array<any>) => {
                if (results && results[0].status && results[1].status) {
                    this.toastrService.info('Recherche enregistrée', '', { opacity: 1 });
                    this.getAccount();
                } else {
                    this.toastrService.error('Erreur', '', { opacity: 1 });
                }
            });
        }
    }

    dispoTravail(event: any): void {
        const travail = event.currentTarget.checked;
        if (travail) {
            this.dispo.travail = '2';
        } else {
            this.dispo.travail = '1';
        }
    }
    dispoPreavis(event: any): void {
        const travail = event.currentTarget.checked;
        if (travail) {
            this.dispo.preavis = '2';
        } else {
            this.dispo.preavis = '1';
        }
    }
    afficherTypeContrat(): boolean {
        if (this.poste.salarie) {
            return true;
        }
        this.posteVoulu.cdd = false;
        this.posteVoulu.cdi = false;
        return false;
    }
    choosePays(pays: any): void {
        this.actuel.pays = '';
        this.actuel.paysCache = '';
        let ajouter = true;
        for (const p of this.candidat.mobilite_nationale) {
            if (p === pays) {
                ajouter = false;
                break;
            }
        }
        if (ajouter) {
            this.candidat.mobilite_nationale.push(pays);
        }
        this.searchPays.next('');
    }

    removePays(p: string): void {
        const newPays = [];
        for (const pays of this.candidat.mobilite_nationale) {
            if (p !== pays) {
                newPays.push(pays);
            }
        }
        this.candidat.mobilite_nationale = newPays;
    }

    chooseAddrMobiliteAjout(): void {
        if (this.mobiliteRayon.rayon < 0) {
            this.mobiliteRayon.rayon = 0;
        }
        let pays = '';
        for (let p of this.listePays) {
            if (p.code === this.mobiliteRayon.pays) {
                pays = p.pays;
                break;
            }
        }
        const newMobilite = {
            latitude: this.mobiliteRayon.latitude,
            longitude: this.mobiliteRayon.longitude,
            ville: this.mobiliteRayon.villeSave,
            pays,
            rayon: this.mobiliteRayon.rayon,
        };
        this.candidat.mobilite_locale.push(newMobilite);
        this.mobiliteRayon.ville = '';
        this.mobiliteRayon.pays = '';
        this.mobiliteRayon.rayon = 5;
        this.addrs = [];
        this.addrsCheck = false;
        this.searchCity.next('');
    }
    removeMobilite(m: any): void {
        const newMobilites = [];
        for (const mo of this.candidat.mobilite_locale) {
            if (mo.ville !== m.ville) {
                newMobilites.push(mo);
            }
        }
        this.candidat.mobilite_locale = newMobilites;
    }
}
