<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-candidat/informations-personnelles" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pl-0 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-candidat/informations-personnelles" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span style="margin-right: 5rem">Ma recherche</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Ma recherche</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Que recherchez-vous actuellement ?</h2>
            </div>

            <div class="form-group inscription-row col-12 d-flex flex-wrap">
                <div class="col-12 col-md-6 pl-0 pr-0">
                    <label>Etat *</label>
                    <span
                        for="situation"
                        class="error"
                        [ngStyle]="{
                            display: this.error.situation ? 'block' : 'none'
                        }"
                        >Obligatoire</span
                    >
                    <mdb-select-2
                        placeholder="Choisir.."
                        name="situation"
                        class="custom-selectbox"
                        [outline]="true"
                        [(ngModel)]="this.candidat.situation"
                    >
                        <mdb-select-option *ngFor="let option of typesSituations" [value]="option.value">{{
                            option.label
                        }}</mdb-select-option>
                    </mdb-select-2>
                </div>
              <div class="col-12 col-md-6 pt-4 pt-md-0 pl-0 pl-md-3 pr-0">
                <label>Télétravail souhaité *</label>
                <span
                  for="teletravail"
                  class="error"
                  [ngStyle]="{
                                        display: this.error.teleTravail ? 'block' : 'none'
                                    }"
                >Obligatoire</span
                >
                <mdb-select-2
                  placeholder="Choisir.."
                  name="teletravail"
                  class="custom-selectbox"
                  [(ngModel)]="candidat.teleTravail"
                  [outline]="true"
                >
                  <mdb-select-option *ngFor="let option of typesTeletravail" [value]="option.value">{{
                    option.label
                    }}</mdb-select-option>
                </mdb-select-2>
              </div>
            </div>
            <div class="inscription-row">
                <div class="form-group add_bottom_30">
                    <label>Disponibilité *</label>
                    <span
                        for="disponibilite"
                        class="error"
                        [ngStyle]="{
                            display: this.error.disponibilite ? 'block' : 'none'
                        }"
                        >Obligatoire</span
                    >
                    <div class="row pt-3">
                      <div class="col-12 col-md-6">
                        <div class='row'>
                          <div class='col-4 col-md-4 font-weight-bold pr-0'>
                            Je suis en poste
                          </div>

                          <div class="switch col-3 col-md-3">
                            <label>
                              <input [checked]="dispo.travail === '2'" type="checkbox" (change)="dispoTravail($event)" />
                              <span class="lever"></span>
                            </label>
                          </div>
                          <div class='col-5 col-md-4 font-weight-bold '>Je ne suis pas en poste</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 pt-2 pt-md-0" *ngIf="dispo.travail == '1'">
                        <div class='row'>
                          <div class=' offset-md-2 col-4  col-md-4 font-weight-bold pr-0'>
                            Je connais ma date de départ
                          </div>

                          <div class="switch col-3 col-md-2">
                            <label>
                              <input [checked]="dispo.preavis === '2'" type="checkbox" (change)="dispoPreavis($event)" />
                              <span class="lever"></span>
                            </label>
                          </div>
                          <div class='col-5 col-md-4 font-weight-bold '>Je ne connais pas ma date de départ</div>
                        </div>

                      </div>
                        <div class="col-12 mt-3">
                            <div
                                class="styled-select clearfix col-12 col-md-6"
                                *ngIf="dispo.travail == '1' && (dispo.preavis == '' || dispo.preavis == '2')"
                            >
                                Disponible sous (préavis) *
                                <mdb-select-2
                                    placeholder="Choisir.."
                                    name="disponible"
                                    class="bottom-select"
                                    [(ngModel)]="candidat.disponibilite"
                                    [outline]="true"
                                >
                                    <mdb-select-option *ngFor="let option of typesDispos" [value]="option.value">{{
                                        option.label
                                    }}</mdb-select-option>
                                </mdb-select-2>
                            </div>
                            <div class="styled-select clearfix col-12 col-md-6" *ngIf="dispo.travail == '2' || dispo.preavis == '1'">
                                Disponible dès le *
                                <div *ngIf="dateDisponibiliteHistorique !== ''">
                                    {{ dateDisponibiliteHistorique | date: 'dd-MM-yyyy' }}
                                </div>
                                <span
                                    for="dateDisponibilite"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.disponibiliteDate ? 'block' : 'none'
                                    }"
                                    >Votre date de disponibilité ne peut pas être dans le passé ou vide</span
                                >
                              <app-datepicker [(date)]='dateDisponibilite'></app-datepicker>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="inscription-row">
              <app-poste class='d-flex flex-wrap' [(data)]='candidat.posteRechercher' [(recherche)]='actuel' [typeMetier]='"Postes souhaités"'>
                <label for="fonction">Poste(s) souhaité(s) * <small>Ce champ est multiple</small></label>
                <span
                  for="fonction"
                  class="error"
                  [ngStyle]="{
                                        display: this.error.posteRechercher ? 'block' : 'none'
                                    }"
                >Obligatoire</span
                >
              </app-poste>
            </div>

            <div class="inscription-row">
              <app-secteur class='d-flex flex-wrap' [showHelp]='true' [(data)]='candidat.secteurRechercher' [typeSecteur]='"Souhaités"'>
                <label for="secteursRechercher">Secteur(s) d'activité souhaité(s) <small>Ce champ est multiple</small></label>
              </app-secteur>
            </div>

            <div class="inscription-row">
                <label class="custom mb-3">Type(s) de poste recherché(s) <small>Ce champ est multiple</small></label>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="container_check"
                                >Salarié
                                <input type="checkbox" [(ngModel)]="poste.salarie" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >Alternant
                                <input type="checkbox" [(ngModel)]="poste.alternant" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="container_check"
                                >Indépendant
                                <input type="checkbox" [(ngModel)]="poste.independant" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >Bénévole
                                <input type="checkbox" [(ngModel)]="poste.benevole" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="container_check"
                                >Stagiaire
                                <input type="checkbox" [(ngModel)]="poste.stagiaire" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label class="container_check"
                                >Autre
                                <input type="checkbox" [(ngModel)]="poste.autre" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="inscription-row">
                <label class="custom mb-3" *ngIf="afficherTypeContrat()">Type(s) de contrat recherché(s)</label>
                <div class="row" *ngIf="afficherTypeContrat()">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="container_check"
                                >CDD
                                <input type="checkbox" [(ngModel)]="posteVoulu.cdd" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="container_check"
                                >CDI
                                <input type="checkbox" [(ngModel)]="posteVoulu.cdi" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <label class="custom mb-3 mt-3">Temps</label>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="container_check"
                                >Complet
                                <input type="checkbox" [(ngModel)]="temps.plein" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="container_check"
                                >Partiel
                                <input type="checkbox" [(ngModel)]="temps.partiel" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="inscription-row mb-3 form-group">
                <label>Mobilité *</label>
              <span
                for="mobilite"
                class="error"
                [ngStyle]="{
                            display: this.error.mobilite ? 'block' : 'none'
                        }"
              >Obligatoire</span
              >
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="container_check"
                                >Ville(s)
                                <input type="checkbox" name="regional" [(ngModel)]="mobilite.regional" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="container_check"
                                >Pays
                                <input type="checkbox" name="national" [(ngModel)]="mobilite.national" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="container_check"
                                >Tous les pays
                                <input type="checkbox" name="international" [(ngModel)]="mobilite.international" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div *ngIf="mobilite.international">
                    <label style="color: green !important; font-size: 16px">Vous êtes disponible partout dans le monde</label>
                </div>
                <div *ngIf="mobilite.national" class="mt-2">
                    <label>Mobilité nationale : sélection des pays de mobilité</label>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <mdb-select-2
                                [(ngModel)]="actuel.pays"
                                placeholder="Pays de mobilité *"
                                [notFoundMsg]="'Pays non trouvé'"
                                name="mobilitePays"
                                (selected)="choosePays($event)"
                                [outline]="true"
                            >
                                <mdb-select-filter [ngModel]="searchPays | async" (inputChange)="searchPays.next($event)">
                                </mdb-select-filter>
                                <mdb-select-option *ngFor="let option of filteredOptions | async" [value]="option.pays">{{
                                    option.pays
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </div>
                        <div class="col-12 col-md-6 mt-4 mt-md-0">
                            <div style="position: relative" *ngFor="let p of candidat.mobilite_nationale">
                                <label class="container_radio version_2" style="padding-left: 16px"
                                    >{{ p }}
                                    <input type="radio" name="positions" value="" disabled />
                                </label>
                                <div class="container-cross" (click)="removePays(p)">
                                    <div class="cross">x</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="mobilite.regional">
                    <hr />
                    <label>Dans la / les ville(s) suivante(s) :</label>
                    <div class="row">
                        <div class="col-md-6 pl-0">
                            <div class="col-12">
                                <div class="form-group">
                                    <mdb-select-2
                                        [(ngModel)]="mobiliteRayon.pays"
                                        (selected)="searchCity.next('')"
                                        placeholder="Choisir un pays.."
                                        [notFoundMsg]="'Pays non trouvé'"
                                        name="paysMobilite"
                                        [outline]="true"
                                    >
                                        <mdb-select-filter [ngModel]="searchPays | async" (inputChange)="searchPays.next($event)">
                                        </mdb-select-filter>
                                        <mdb-select-option *ngFor="let option of filteredOptions | async" [value]="option.code">{{
                                            option.pays
                                        }}</mdb-select-option>
                                    </mdb-select-2>
                                </div>
                            </div>
                            <div class="col-12">
                                <app-select-city [ville]="mobiliteRayon"></app-select-city>
                            </div>

                            <div class="col-12" style="margin-bottom: 15px">
                                <label>Et / ou dans un rayon de (km)</label>
                                <input type="number" name="rayon" id="rayon" class="form-control" [(ngModel)]="mobiliteRayon.rayon" />
                            </div>

                            <div class="col-12">
                                <div class="form-group" style="margin-top: 28px">
                                    <button mdbBtn [block]="true" color="warning" mdbWavesEffect (click)="chooseAddrMobiliteAjout()">
                                        Ajouter
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pl-0">
                            <div class="col-12 mt-3" style="position: relative" *ngFor="let m of candidat.mobilite_locale">
                                <label class="container_radio version_2" style="padding-left: 16px"
                                    >{{ m.ville }} - {{ m.pays }}. Rayon de {{ m.rayon }} km
                                    <input type="radio" name="positions" value="" disabled />
                                </label>
                                <div class="container-cross" (click)="removeMobilite(m)" style="right: 0px">
                                    <div class="cross">x</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.candidat.remunerationSouhaiter" class="form-group inscription-row">
                <label>Rémunération souhaitée en euros (brut)</label>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label
                                for="remunerationSouhaiter0"
                                [ngClass]="{
                                    'fl-label-empty': this.candidat.remunerationSouhaiter[0] === '',
                                    'fl-label-not-empty': this.candidat.remunerationSouhaiter[0] !== ''
                                }"
                                >Par heure</label
                            >
                            <input
                                type="number"
                                name="remunerationSouhaiter0"
                                id="remunerationSouhaiter0"
                                class="form-control"
                                [(ngModel)]="this.candidat.remunerationSouhaiter[0]"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label
                                for="remunerationSouhaiter1"
                                [ngClass]="{
                                    'fl-label-empty': this.candidat.remunerationSouhaiter[1] === '',
                                    'fl-label-not-empty': this.candidat.remunerationSouhaiter[1] !== ''
                                }"
                                >Par jour</label
                            >
                            <input
                                type="number"
                                name="remunerationSouhaiter1"
                                id="remunerationSouhaiter1"
                                class="form-control"
                                [(ngModel)]="this.candidat.remunerationSouhaiter[1]"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label
                                for="remunerationSouhaiter2"
                                [ngClass]="{
                                    'fl-label-empty': this.candidat.remunerationSouhaiter[2] === '',
                                    'fl-label-not-empty': this.candidat.remunerationSouhaiter[2] !== ''
                                }"
                                >Par mois</label
                            >
                            <input
                                type="number"
                                name="remunerationSouhaiter2"
                                id="remunerationSouhaiter2"
                                class="form-control"
                                [(ngModel)]="this.candidat.remunerationSouhaiter[2]"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label
                                for="remunerationSouhaiter3"
                                [ngClass]="{
                                    'fl-label-empty': this.candidat.remunerationSouhaiter[3] === '',
                                    'fl-label-not-empty': this.candidat.remunerationSouhaiter[3] !== ''
                                }"
                                >Par année</label
                            >
                            <input
                                type="number"
                                name="remunerationSouhaiter3"
                                id="remunerationSouhaiter3"
                                class="form-control"
                                [(ngModel)]="this.candidat.remunerationSouhaiter[3]"
                            />
                        </div>
                    </div>
                </div>
                <div style="position: relative">
                    <p
                        style="color: red !important"
                        [ngStyle]="{
                            display: this.errorRemuneration.status ? 'block' : 'none'
                        }"
                    >
                        {{ errorRemuneration.message }}
                    </p>
                </div>
            </div>

            <div class="d-flex justify-content-center" style="position: relative">
                <p class="mention-obligatoire" style="color: red !important">* Obligatoire</p>
            </div>
            <div class="d-flex float-right mb-3">
                <button mdbBtn class="text-center" [rounded]="true" color="warning" size="lg" (click)="send()">Valider</button>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
