import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCheckCircle, faTimes, faArrowLeft, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalVariable } from '../../../global';
import { ToastService } from '../../../components/mdb/pro/alerts';
import Utils from '../../../utils';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-situation-candidats',
    templateUrl: './situation.component.html',
    styleUrls: [
        './situation.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class SituationComponent implements OnInit {
    faFilePdf = faFilePdf;
    arrowLeft = faArrowLeft;
    faError = faTimes;
    faOk = faCheckCircle;

    searchLangues = new Subject<string>();
    resultsLangues: Observable<any> | undefined;
    dataLangues: any = [];

    typesExperiencesSecteurs = GlobalVariable.EXPERIENCES_SECTEURS;

    typesExperiences = GlobalVariable.EXPERIENCES;

    typesEtudes = GlobalVariable.ETUDES;

    candidat: any = {
        password: '',
    };
    langues = [];
    errorPage = {
        status: false,
        message: '',
    };

    actuel = {
        metier: '',
        metierCache: '',
        secteur: '',
        secteurCache: '',
        langue: '',
        langueCache: '',
        secteurRechercher: '',
        secteurRechercherCache: '',
    };
    error = {
        fonctionLibre: false,
        secteurActivite: false,
        experienceSecteur: false,
        experience: false,
        niveauEtude: false,
        langues: false,
        fonction: false,
    };

    url = '';
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private userService: UserService,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {
        this.httpClient.get<any>(this.globalService.url + '/langues/' + this.actuel.langue).subscribe((data: any) => {
            this.dataLangues = data;
            this.resultsLangues = this.searchLangues.pipe(
                startWith(''),
                map((value: string) => Utils.filterLangue(value, this.dataLangues))
            );
        });
    }

    ngOnInit(): void {
        this.candidat._id = localStorage.getItem('id');
        this.url = this.globalService.url;

        this.getAccount();

        setInterval(() => {
            this.changeLangues();
        }, 200);
    }
    chooseLangues(nom: string): void {
        this.searchLangues.next('');
        this.actuel.langue = '';
        this.actuel.langueCache = '';
        let exist = false;
        for (const l of this.candidat.langues) {
            if (nom === l.langue) {
                exist = true;
                break;
            }
        }
        if (!exist) {
            this.candidat.langues.push({ langue: nom, parle: '', ecrit: '' });
        }
    }
    changeLangues(): void {
        if (this.actuel.langue !== '' && this.actuel.langue !== this.actuel.langueCache) {
            this.actuel.langueCache = this.actuel.langue;
            this.getLangues();
        }
    }
    getLangues(): void {
        this.httpClient.get<any>(this.globalService.url + '/langues/' + this.actuel.langue, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.langues = [];
                    for (const l of response.langues) {
                        // @ts-ignore
                        // tslint:disable-next-line:prefer-const
                        this.langues.push(l.langue);
                    }
                }
            },
            () => {}
        );
    }
    removeLangue(nom: string): void {
        const newLangues = [];
        for (const l of this.candidat.langues) {
            if (l.langue !== nom) {
                newLangues.push(l);
            }
        }
        this.candidat.langues = newLangues;
    }
    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.candidat = response.candidat;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    renouvellerErreur(): void {
        this.errorPage.status = false;
        this.error = {
            fonction: false,
            fonctionLibre: false,
            secteurActivite: false,
            niveauEtude: false,
            langues: false,
            experienceSecteur: false,
            experience: false,
        };
    }
    sendSituation(): void {
        this.renouvellerErreur();
        let ok = true;
        if (this.candidat.fonction === '' || this.candidat.fonction == null) {
            this.error.fonction = true;
            ok = false;
        }
        if (this.candidat.fonctionLibre === '') {
            this.error.fonctionLibre = true;
            ok = false;
        }
        if (this.candidat.secteurActivite === [] || this.candidat.secteurActivite.length === 0) {
            this.error.secteurActivite = true;
            ok = false;
        }
        if (this.candidat.experienceSecteur === '') {
            this.error.experienceSecteur = true;
            ok = false;
        }
        if (this.candidat.experience === '') {
            this.error.experience = true;
            ok = false;
        }
        if (this.candidat.niveauEtude === '') {
            this.error.niveauEtude = true;
            ok = false;
        }
        if (this.candidat.langues.length === 0) {
            this.error.langues = true;
            ok = false;
        }
        if (ok) {
            this.httpClient
                .patch(
                    this.globalService.url + '/candidat/actuel',
                    {
                        id: this.candidat._id,
                        fonction: this.candidat.fonction,
                        fonctionLibre: this.candidat.fonctionLibre,
                        secteurActivite: this.candidat.secteurActivite,
                        experienceSecteur: this.candidat.experienceSecteur,
                        experience: this.candidat.experience,
                        niveauEtude: this.candidat.niveauEtude,
                        langues: this.candidat.langues,
                    },
                    {}
                )
                .subscribe(
                    (response: any) => {
                        if (!response.status) {
                            this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                        } else {
                            this.toastrService.info('Situation actuelle enregistrée', '', { opacity: 1 });
                            this.getAccount();
                        }
                    },
                    () => {}
                );
        }
    }
}
