import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-modifier-texte-site',
    templateUrl: './modifier-texte-site.component.html',
    styleUrls: ['./modifier-texte-site.component.css'],
})
export class ModifierTexteSiteComponent implements OnInit {
    page = 'Accueil';
    text: any = [];
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.getTexte();
    }
    changerChoix(text: string): void {
        this.page = text;
        this.getTexte();
    }
    getTexte(): void {
        this.httpClient.post<any>(this.globalService.url + '/multilingue', { page: this.page }, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.text = response.text;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    updateTexte(id: string): void {
        for (const t of this.text) {
            if (t._id === id) {
                this.httpClient.patch<any>(this.globalService.url + '/multilingue', { id: id, fr: t.fr }, {}).subscribe(
                    (response) => {},
                    () => {}
                );
            }
        }
    }
}
