<div class="cookie_content" *ngIf='!cookie'>
    <div
        mdbModal
        #cookieModal="mdbModal"
        class="modal fade bottom modal-scrolling cookie_display"
        id="frameModalTop"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        [config]="{backdrop: false, ignoreBackdropClick: false, show : true }"
    >
        <div class="modal-dialog modal-frame modal-bottom modal-notify modal-info" role="document">
            <!--Content-->
            <div class="modal-content">
                <!--Body-->
                <div class="modal-body">
                    <div class="row d-flex justify-content-center align-items-center">
                        <p class="pt-1 pr-2">
                            Ce site n'a pas de cookies, cependant, notre système de chat en utilise.<br />
                            Vous trouverez ici sa
                            <a href="https://crisp.chat/fr/privacy/">politique de confidentialité.</a>
                        </p>
                        <a
                            type="button"
                            mdbBtn
                            color="primary"
                            [outline]="true"
                            class="waves-light"
                            mdbWavesEffect
                            data-dismiss="modal"
                            (click)="cookie_compris(); cookieModal.hide()"
                            >J'ai compris</a
                        >
                    </div>
                </div>
            </div>
            <!--/.Content-->
        </div>
    </div>
</div>
<div
    mdbModal
    #logoutModal="mdbModal"
    class="modal fade top"
    id="frameModalTop2"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-notify modal-danger" role="document">
        <!--Content-->
        <div class="modal-content text-center">
            <!--Body-->
            <div class="modal-body">
                <h4 class="font-weight-bold pb-2">DECONNEXION</h4>
                Me déconnecter de mon espace ?
            </div>
            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a
                    (click)="deconnexion()"
                    mdbBtn
                    color="danger"
                    style="border: 2px solid #d89e9e !important; color: #d89e9e !important"
                    size="lg"
                    [outline]="true"
                    class="waves-effect"
                    mdbWavesEffect
                    >OUI</a
                >
                <a
                    type="button"
                    mdbBtn
                    color="danger"
                    class="waves-effect"
                    style="border-color: #d89e9e !important; background-color: #d89e9e !important"
                    size="lg"
                    mdbWavesEffect
                    data-dismiss="modal"
                    (click)="logoutModal.hide()"
                    >NON</a
                >
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<section id="Header">
    <div class="header-wrapper">
        <nav class="navbar navbar-expand-lg w-100">
            <div class="container" style="max-width: 95% !important">
                <a class="navbar-brand" href="/"><img src="./assets/img/logo-hd-nav.png" alt="logo" class="header-logo" /></a>

                <div class="collapse navbar-collapse" id="navbarNav" style='margin-top: 5px'>
                    <ul class="navbar-nav ms-auto mr-lg-5 mr-xl-0">
                      <li *ngIf='!hideCandidat' class="nav-item">
                        <a class="nav-link prices-link" href="/candidat">Espace Candidat</a>
                      </li>
                      <li *ngIf='!hideRecruteur' class="nav-item">
                        <a class="nav-link prices-link" href="/recruteur">Espace Recruteur</a>
                      </li>
                        <li class="nav-item">
                            <a class="nav-link prices-link" href="/nos-tarifs">Nos tarifs</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link nav-btn" href="/nous-contacter">Nous contacter</a>
                        </li>
                      <li *ngIf='logged' class="nav-item avatar dropdown" dropdown style='position: relative; bottom: 5px; left: 20px'>
                        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                          <img [src]='photo' alt="" class="img-fluid rounded-circle z-depth-0" style='height: 35px'></a>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-custom" role="menu">
                          <a class="dropdown-item waves-light" (click)='redirectDashboard()' mdbWavesEffect>Mon espace</a>
                          <a class="dropdown-item waves-light" (click)='logoutModal.show();' mdbWavesEffect>Déconnexion</a>
                        </div>
                      </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</section>
<section class="mobileNav py-0" style="margin-top: 3rem" [ngClass]="open ? 'active' : ''">
    <div class="container pl-0">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button
                    style="padding: 0.5rem 1rem !important"
                    id="home-tab"
                    [ngClass]="tabIndex == 1 ? 'nav-link active' : 'nav-link'"
                    (click)="tabIndex = 1"
                    data-toggle="tab"
                    href="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                >
                    Accueil
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    style="padding: 0.5rem 1rem !important"
                    [ngClass]="tabIndex == 2 ? 'nav-link active' : 'nav-link'"
                    id="profile-tab"
                    data-toggle="tab"
                    (click)="tabIndex = 2"
                    href="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                >
                    Notre accompagnement
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    style="padding: 0.5rem 1rem !important"
                    [ngClass]="tabIndex == 3 ? 'nav-link active' : 'nav-link'"
                    id="contact-tab"
                    data-toggle="tab"
                    (click)="tabIndex = 3"
                    href="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                >
                    Nous contacter
                </button>
            </li>
        </ul>
        <div class="mt-4"></div>
        <div class="tab-content" id="myTabContent">
            <div
                *ngIf="tabIndex == 1 && type !== 'candidat' && type !== 'recruteur'"
                [@inOutAnimation]
                class="tab-pane fade active show ml-3"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
            >
                <a href="candidat/" class="text-muted text-decoration-none d-block"> Espace candidat </a>
                <a href="recruteur/" class="text-muted text-decoration-none d-block"> Espace recruteur </a>
                <a href="/conditions-generales-d-utilisation-et-de-vente" class="text-muted text-decoration-none d-block"> CGUV </a>
                <a
                    href="/politique-de-confidentialite-et-d-utilisation-des-donnees-personnelles"
                    class="text-muted text-decoration-none d-block"
                >
                    Protection des données
                </a>
                <a href="/mentions-legales" class="text-muted text-decoration-none d-block"> Mentions légales </a>
            </div>
            <div
                *ngIf="tabIndex == 1 && type === 'candidat'"
                [@inOutAnimation]
                class="tab-pane fade active show ml-3"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
            >
                <p class="fw-bold fs-14 mb-1">Espace candidat</p>
                <div class="line w-50 h-1"></div>

                <ul class="list-unstyled mb-2 pl-3">
                    <li>
                        <a href="espace-candidat/dashboard" class="text-muted text-decoration-none"> Accueil </a>
                    </li>
                    <li>
                        <a href="espace-candidat/informations-personnelles" class="text-muted text-decoration-none">
                            Mes informations personnelles
                        </a>
                    </li>
                    <li>
                        <a href="espace-candidat/panier/new" class="text-muted text-decoration-none"> Créer mes paniers </a>
                    </li>
                    <li>
                        <a href="espace-candidat/mes-selections" class="text-muted text-decoration-none"> Consulter mes paniers </a>
                    </li>
                    <li>
                        <a href="espace-candidat/mes-commandes" class="text-muted text-decoration-none"> Mes commandes </a>
                    </li>
                    <li>
                        <a href="espace-candidat/mes-factures" class="text-muted text-decoration-none"> Mes factures </a>
                    </li>
                  <li>
                    <a href="espace-candidat/offres" class="text-muted text-decoration-none"> Offres d'emploi</a>
                  </li>
                  <li>
                    <a href="espace-candidat/avantages" class="text-muted text-decoration-none"> Mes avantages </a>
                  </li>
                    <li>
                        <a href="espace-candidat/diffusion-non-souhaitee" class="text-muted text-decoration-none">
                            Recruteurs à ne pas contacter
                        </a>
                    </li>
                </ul>

                <a href="/conditions-generales-d-utilisation-et-de-vente" class="text-muted text-decoration-none d-block"> CGUV </a>
                <a
                    href="/politique-de-confidentialite-et-d-utilisation-des-donnees-personnelles"
                    class="text-muted text-decoration-none d-block"
                >
                    Protection des données
                </a>
                <a href="/mentions-legales" class="text-muted text-decoration-none d-block"> Mentions légales </a>
                <a (click)="logoutModal.show()" class="text-muted text-decoration-none d-block"> Déconnexion </a>
            </div>
            <div
                *ngIf="tabIndex == 1 && type === 'recruteur'"
                [@inOutAnimation]
                class="tab-pane fade active show ml-3"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
            >
                <p class="fw-bold fs-14 mb-1">Espace recruteur</p>
                <div class="line w-50 h-1"></div>

                <ul class="list-unstyled mb-2 pl-3">
                    <li>
                        <a href="espace-recruteur/dashboard" class="text-muted text-decoration-none"> Accueil </a>
                    </li>
                    <li>
                        <a href="espace-recruteur/informations-personnelles" class="text-muted text-decoration-none">
                            Mes informations personnelles
                        </a>
                    </li>
                    <li>
                        <a href="espace-recruteur/candidatures-recues" class="text-muted text-decoration-none"> Candidatures reçues </a>
                    </li>
                    <li>
                        <a href="espace-recruteur/desabonnements" class="text-muted text-decoration-none"> Mes désabonnements </a>
                    </li>
                    <li>
                        <a href="espace-recruteur/cvtheque" class="text-muted text-decoration-none"> CVThèque </a>
                    </li>
                  <li>
                    <a href="espace-recruteur/avantages" class="text-muted text-decoration-none"> Mes avantages </a>
                  </li>
                </ul>

                <a href="/conditions-generales-d-utilisation-et-de-vente" class="text-muted text-decoration-none d-block"> CGUV </a>
                <a
                    href="/politique-de-confidentialite-et-d-utilisation-des-donnees-personnelles"
                    class="text-muted text-decoration-none d-block"
                >
                    Protection des données
                </a>
                <a href="/mentions-legales" class="text-muted text-decoration-none d-block"> Mentions légales </a>
                <a (click)="logoutModal.show()" class="text-muted text-decoration-none d-block"> Déconnexion </a>
            </div>
            <div
                *ngIf="tabIndex == 2"
                [@inOutAnimation]
                class="tab-pane fade active show ml-3"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
            >
                <a href="/nos-tarifs" class="text-muted text-decoration-none d-block"> Nos tarifs </a>
                <a href="/nos-conseils" class="text-muted text-decoration-none d-block"> Nos conseils </a>
                <a href="https://app.livestorm.co/avenuedesrecruteurs" class="text-muted text-decoration-none d-block"> Nos webinaires </a>
                <a href="https://help.avenuedesrecruteurs.fr/fr/" class="text-muted text-decoration-none d-block"> FAQ </a>
            </div>
            <div
                *ngIf="tabIndex == 3"
                [@inOutAnimation]
                class="tab-pane fade active show ml-3"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
            >
                <div class="text-center">
                    <p class="fw-bold mb-0">Téléphone</p>
                    <a href="" class="text-dark text-decoration-none">+ 33 (0) 6 51 88 66 54</a>
                </div>
                <div class="line w-50 h-1 my-4 mx-auto bg-light" style="background-color: #e4a700 !important"></div>
                <div class="text-center">
                    <p class="fw-bold mb-0">Adresse e-mail</p>
                    <a href="" class="text-dark text-decoration-none">contact@avenuedesrecruteurs.fr </a>
                </div>

                <div action="" class="mt-4">
                    <div class="row">
                        <div class="col-md-6 p-3">
                            <input
                                type="text"
                                name="your-name"
                                [(ngModel)]="contact.nom"
                                placeholder="VOTRE NOM, PRENOM *"
                                class="border-0 border-bottom form-control br-0"
                            />
                            <span
                                for="adresse"
                                class="error"
                                [ngStyle]="{
                                    display: this.contactError.nom ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                        </div>
                        <div class="col-md-6 p-3">
                            <input
                                type="text"
                                name="your-email"
                                [(ngModel)]="contact.email"
                                placeholder="VOTRE EMAIL *"
                                class="border-0 border-bottom form-control br-0"
                            />
                            <span
                                for="adresse"
                                class="error"
                                [ngStyle]="{
                                    display: this.contactError.email ? 'block' : 'none'
                                }"
                                >Obligatoire / Mauvais format</span
                            >
                        </div>
                        <div class="col-md-6 p-3">
                            <input
                                type="text"
                                name="telephone"
                                [(ngModel)]="contact.telephone"
                                placeholder="VOTRE TELEPHONE"
                                class="border-0 border-bottom form-control br-0"
                            />
                        </div>
                        <div class="col-md-12 p-3">
                            <input
                                type="text"
                                name="subject"
                                [(ngModel)]="contact.sujet"
                                placeholder="OBJET DE VOTRE DEMANDE *"
                                class="border-0 border-bottom form-control br-0"
                            />
                            <span
                                for="adresse"
                                class="error"
                                [ngStyle]="{
                                    display: this.contactError.sujet ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                        </div>
                        <div class="col-md-12 p-3">
                            <label for="" class="text-muted ml-3"> VOTRE MESSAGE * </label>
                            <textarea
                                name="your-message"
                                [(ngModel)]="contact.message"
                                style="border-radius: 30px; border: 1px solid #e4a700 !important; height: 150px; text-indent: 10px"
                                class="form-control"
                                cols="30"
                                rows="5"
                            ></textarea>
                            <span
                                for="adresse"
                                class="error"
                                [ngStyle]="{
                                    display: this.contactError.message ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                        </div>

                        <div class="col-md-12 p-1" style="margin-bottom: 10rem">
                            <div class="text-center">
                                <button
                                    style="padding: 0.375rem 0.75rem !important"
                                    (click)="envoyer()"
                                    class="btn btn-warning px-4 rounded-pill"
                                >
                                    Envoyer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="tabIndex !== 3" class="mobile-footer text-center">
            <img class="mw-100" src="./assets/img/logo.png" alt="mobile-footer" />
        </div>
    </div>
</section>

<div class="hamburger_holder menu_mobile" style="top: 16px">
    <fa-icon (click)="menu()" class="icon_menu" [icon]="icon"></fa-icon>
</div>
