<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10" style="margin-top: 20px">
        <div class="page-inner">
            <div class="page-title">
                <h3 class="breadcrumb-header">Consulter mes paniers</h3>
            </div>
            <div class="no-result" *ngIf="paniers.length === 0">Aucun résultat</div>
            <table [ngStyle]="{ display: display() }" id="tableSort" mdbTable #tableEl="mdbTable" class="display custom-table mt-5">
                <thead>
                    <tr>
                        <th
                            *ngFor="let head of headElements; let i = index"
                            aria-controls="tableSortExample"
                            scope="col"
                            [mdbTableSort]="paniers"
                            [sortBy]="headElements[i]"
                        >
                            {{ head }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let p of paniers; let i = index">
                        <td
                            style="word-break: break-word"
                            *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                        >
                            {{ p.nomPanier }}
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            {{ p.recruteurSelectionner.length }}
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            <a routerLink="/espace-candidat/panier/{{ p._id }}"
                                ><fa-icon class="text-info fa-2x" [icon]="faEdit"></fa-icon
                            ></a>
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            <button (click)="supprimer(p._id)" mdbBtn type="button" color="danger" mdbWavesEffect>
                                <fa-icon [icon]="faTimes"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot class="grey lighten-5 w-100">
                    <tr>
                        <td colspan="4">
                            <mdb-table-pagination ofKeyword="de" [tableEl]="tableEl" [searchDataSource]="paniers"></mdb-table-pagination>
                        </td>
                    </tr>
                    <tr>
                        <td [colSpan]="headElements.length" style="border-top: none !important; border-bottom: none !important">
                            <mdb-select-2
                                placeholder="Afficher 10/ page"
                                (selected)="changeItemsByPage($event)"
                                style="float: right; min-width: 174px"
                                name="itemsByPage"
                                [outline]="true"
                            >
                                <mdb-select-option *ngFor="let option of displayByPages" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <a
                href="/espace-candidat/panier/new"
                style="color: white"
                class="mb-3"
                mdbBtn
                [rounded]="true"
                type="button"
                color="warning"
                mdbWavesEffect
            >
                <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Créer nouveau panier
            </a>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
