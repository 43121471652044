<div mdbModal #loginModal="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">CONNEXION</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="loginModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="input-group mb-5" style='border: 1px solid #e4a700;'>
          <input type="email" [(ngModel)]="login.identifiant" id="defaultForm-email" placeholder='Email'
                 >
        </div>
        <div class="mb-4 input-group" style='border: 1px solid #1c4aad;'>
          <input [type]="type1" name="password" id="password" [(ngModel)]="login.password"
                 placeholder='Mot de passe'>
          <span class="eye"><fa-icon class="eye" (click)="eye()" [icon]="eye1"></fa-icon></span>
        </div>
        <span class="or">OU</span>
        <div class="social-login">
          <div class="google">
            <a href='{{url}}/auth/google?type=candidat&redirect={{redirect}}'><fa-icon class='fa fa-2x text-warning'  [icon]="faGoogle"></fa-icon>Continuer avec Google</a>
          </div>
          <div class="facebook">
            <a
              href='{{url}}/auth/facebook?type=candidat&redirect={{redirect}}'
            ><fa-icon class='fa fa-2x'  [icon]="faFacebook"></fa-icon>Continuer avec Facebook</a
            >
          </div>
          <div class="linkedin">
            <a
              href='{{url}}/auth/linkedin?type=candidat&redirect={{redirect}}'
            ><fa-icon class='fa fa-2x'  [icon]="faLinkedin"></fa-icon>Continuer avec Linkedin</a
            >
          </div>
        </div>
        <p *ngIf="error !== ''" class="error pr-0 text-center pt-1">
          {{ error }}
        </p>
        <p *ngIf="loading" class="form-row" style="display: block; width: 20px; margin: auto">
          <img src="./assets/img/gif.gif" class='mt-2 ml-4' style="max-width: 25px; text-align: center" />
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn [rounded]='true' color="warning" (click)='connexion()' class="waves-light" mdbWavesEffect>Connexion</button>
      </div>

    </div>
  </div>
</div>
