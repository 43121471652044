<div>
    <div
        mdbModal
        #logoutModal="mdbModal"
        class="modal fade top"
        id="frameModalTop"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-notify modal-danger" role="document">
            <!--Content-->
            <div class="modal-content text-center">
                <!--Body-->
                <div class="modal-body">
                    <h4 class="font-weight-bold pb-2">DECONNEXION</h4>
                    Me déconnecter de mon espace recruteur ?
                </div>
                <!--Footer-->
                <div class="modal-footer justify-content-center">
                    <a
                        (click)="deconnexion()"
                        mdbBtn
                        color="danger"
                        style="border: 2px solid #d89e9e !important; color: #d89e9e !important"
                        size="lg"
                        [outline]="true"
                        class="waves-effect"
                        mdbWavesEffect
                        >OUI</a
                    >
                    <a
                        type="button"
                        mdbBtn
                        color="danger"
                        class="waves-effect"
                        style="border-color: #d89e9e !important; background-color: #d89e9e !important"
                        size="lg"
                        mdbWavesEffect
                        data-dismiss="modal"
                        (click)="logoutModal.hide()"
                        >NON</a
                    >
                </div>
            </div>
            <!--/.Content-->
        </div>
    </div>
    <div class="logo_holder centrer_logo menu_site">
        <div class="page-sidebar">
            <div class="page-sidebar-inner">
                <div class="page-sidebar-menu">
                    <ul class="accordion-menu">
                        <li [ngClass]="{ 'active-page': url === 'dashboard' }">
                            <a href="espace-recruteur/dashboard"> <fa-icon [icon]="faHome"></fa-icon><span>Accueil</span> </a>
                        </li>
                        <li
                            [ngClass]="{
                                'active-page': url === 'informations-personnelles'
                            }"
                        >
                            <a href="espace-recruteur/informations-personnelles">
                                <fa-icon [icon]="faUser"></fa-icon><span>Mes informations personnelles</span>
                            </a>
                        </li>
                        <li
                            [ngClass]="{
                                'active-page': url === 'candidatures-recues'
                            }"
                        >
                            <a href="espace-recruteur/candidatures-recues">
                                <fa-icon [icon]="faInboxIn"></fa-icon><span>Candidatures reçues</span>
                            </a>
                        </li>
                        <li
                            [ngClass]="{
                                'active-page': url === 'desabonnements'
                            }"
                        >
                            <a href="espace-recruteur/desabonnements">
                                <fa-icon [icon]="faTrash"></fa-icon><span>Mes désabonnements</span>
                            </a>
                        </li>
                        <li [ngClass]="{ 'active-page': url === 'cvtheque' }">
                            <a href="espace-recruteur/cvtheque"> <fa-icon [icon]="faCvTech"></fa-icon><span>CVthèque</span> </a>
                        </li>
                      <li [ngClass]="{ 'active-page': url === 'avantages' }">
                        <a href="espace-recruteur/avantages"> <fa-icon [icon]="faGift"></fa-icon><span>Mes avantages</span> </a>
                      </li>
                        <li>
                            <a target="_blank" href="https://help.avenuedesrecruteurs.fr/fr/">
                                <fa-icon [icon]="faQuestionCircle"></fa-icon><span>FAQ</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="logoutModal.show()"> <fa-icon [icon]="faUnlock"></fa-icon><span>Déconnexion</span> </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
