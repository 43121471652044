export default class Utils {
    public static filterSecteur(value: string, data: any): string[] | undefined {
        if (value) {
            const filterValue = value
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            if (data && data.length > 0) {
                return data.filter((item: any) =>
                    item
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .includes(filterValue)
                );
            }
        }
        return [];
    }
    public static filterMetier(value: string, data: any): string[] | undefined {
        if (value.length <= 2) {
            return [];
        }
        const filterValue = value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        if (data && data.metiers.length > 0) {
            return data.metiers.filter((item: any) =>
                item.nomMetier
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .includes(filterValue)
            );
        }
        return [];
    }
    public static filterCity(value: string, data: any): any | undefined {
        // tslint:disable-next-line:no-shadowed-variable
        return data.filter(
            (value: { city: any; postcode: any }, index: any, self: { city: any; postcode: any }[]) =>
                index === self.findIndex((t: { city: any; postcode: any }) => t.city === value.city && t.postcode === value.postcode)
        );
    }
    public static filter(value: string, data: any): any[] {
        if (value === '' || value === undefined || value === null) {
            return data;
        }
        const filterValue = value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        return data.filter((option: any) =>
            option.pays
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(filterValue)
        );
    }
    public static filterAdresse(value: string, data: any): any | undefined {
        return data;
    }
    public static filterNatio(value: string, data: any): string[] | undefined {
        if (value) {
            const filterValue = value
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            if (data && data.nationalites.length > 0) {
                return data.nationalites.filter((item: any) =>
                    item.nationalite
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .includes(filterValue)
                );
            }
            return [];
        }
        return [];
    }
    public static filterLangue(value: string, data: any): string[] | undefined {
        if (value) {
            const filterValue = value
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            if (data && data.langues.length > 0) {
                return data.langues.filter((item: any) =>
                    item.langue
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .includes(filterValue)
                );
            }
            return [];
        }
        return [];
    }
}
