<div
  mdbModal
  #verificationCode="mdbModal"
  class="modal fade top"
  id="verificationModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  [config]="{ backdrop: 'static', keyboard: false }"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-warning" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header justify-content-center">
        <p class="heading lead">VERIFICATION REQUISE</p>
      </div>
      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <p>
            Merci de consulter votre messagerie et d'écrire ci-dessous le code envoyé à l'adresse
            {{ email }}.
          </p>
          <code-input
            [isCodeHidden]="false"
            [codeLength]="5"
            (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)"
          >
          </code-input>

          <a
            type="button"
            style="color: white"
            (click)="sendMailVerificationEmail()"
            mdbBtn
            [rounded]="true"
            color="warning"
            class="waves-effect mt-4"
            mdbWavesEffect
          ><fa-icon class="mr-2" [icon]="faArrowCircleRight"></fa-icon>Renvoyer le code
          </a>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" style="color: white" href="/" mdbBtn [rounded]="true" color="warning" class="waves-effect" mdbWavesEffect
        >Retour à l'accueil
        </a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<div mdbModal #registerModal="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">S'enregistrer</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="registerModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="input-group mb-5" style='border: 1px solid #e4a700;'>
          <input type="email" [(ngModel)]="email" id="defaultForm-email" placeholder='Email'
                 >
        </div>
        <p *ngIf="error !== ''" class="error">
          {{ error }}
        </p>
        <p *ngIf="loading" class="form-row" style="display: block; width: 20px; margin: auto">
          <img src="./assets/img/gif.gif" class='mt-2 ml-4' style="max-width: 25px; text-align: center" />
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn [rounded]='true' color="warning" (click)='register()' class="waves-light" mdbWavesEffect>S'enregistrer</button>
      </div>

    </div>
  </div>
</div>
