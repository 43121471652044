import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { MdbTableDirective, MdbTablePaginationComponent } from '../../../components/mdb/free/tables';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { GlobalVariable } from '../../../global';
import { ReportModalComponent } from '../../../components/modals/report-modal/report-modal.component';
@Component({
    selector: 'app-signalements',
    templateUrl: './signalements.component.html',
    styleUrls: [
        './signalements.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class SignalementsComponent implements OnInit, AfterViewInit {
    @ViewChild(MdbTablePaginationComponent, { static: true })
    mdbTablePagination!: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true })
    mdbTable!: MdbTableDirective;
    @ViewChild('reportModal', { static: true }) reportModal!: ReportModalComponent;

    url = '';
    faSort = faSort;
    archived = false;
    previous: any = [];

    reports: any = [];
    displayByPages = GlobalVariable.PAGES;

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private cdRef: ChangeDetectorRef,
        private toastrService: ToastService,
        private globalService: GlobalService
    ) {}

    ngOnInit(): void {
        this.url = this.globalService.url;
        this.getReports();
    }
    ngAfterViewInit(): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
    changeItemsByPage(event: any): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(event);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
    }
    loadReport(id: string): void {
        this.reportModal.setReportId(id);
        this.reportModal.openForm();
    }
    archive(id: string, note: string): void {
        this.httpClient.patch(this.globalService.url + '/reports/' + id, { note, archived: !this.archived }, {}).subscribe(
            (response: any) => {
                if (!response.status) {
                    this.toastrService.error('Erreur', '', { opacity: 1 });
                } else {
                    this.toastrService.info('Ok', '', { opacity: 1 });
                    this.getReports();
                }
            },
            () => {}
        );
    }
    getReports(): void {
        this.reports = [];
        this.httpClient.get<any>(this.globalService.url + '/reports?archived=' + this.archived).subscribe(
            (response) => {
                if (response.status) {
                    this.reports = response.reports;
                    this.mdbTable.setDataSource(this.reports);
                    this.reports = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                } else {
                }
            },
            () => {}
        );
    }
}
