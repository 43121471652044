import { Pipe, PipeTransform } from '@angular/core';
import { GlobalVariable } from '../global';

@Pipe({
    name: 'etudes',
})
export class EtudesPipe implements PipeTransform {
    etudes = GlobalVariable.ETUDES;

    transform(value: unknown, ...args: unknown[]): unknown {
        const obj = this.etudes.find((a: { value: any }) => a.value === value);
        return obj ? obj.label : '';
    }
}
