<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css" integrity="sha512-PgQMlq+nqFLV4ylk1gwUOgm6CtIIXkKwaIHp/PAIWHzig/lKZSEGKEysh0TCVbHJXCLN7WetD8TFecIky75ZfQ==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<div mdbModal #postModal="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"  [config]="{backdrop: true, ignoreBackdropClick: true }"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Avantage</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="postModal.hide(); reset()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3" style='max-height: calc(100vh - 210px);
    overflow-y: auto;'>
        <label for="defaultForm-pass">Titre</label>
        <input type="text" id="defaultForm-pass"
               class="form-control mb-3" [(ngModel)]='post.titre'>

        <label for="image">Image</label><br><br>
        <div class='row mb-3'>
          <div class='col-4'>
            <img [hidden]="post.image === ''" style="max-height: 90px" class='ml-3' [src]="post._id === '' ? post.image : (post.nouvelleImage === '' ? (this.url + '/images/avantages/' + post.image) : post.nouvelleImage)" />

          </div>
          <div class='col-8'>
            <div class="d-flex">


              <div class="file-field md-form">
                <div mdbBtn [rounded]="true" [outline]="true" color="info" size="md" mdbWavesEffect>
                  <span class="pt-1">Choisir un fichier</span>
                  <input type="file" name="photo" mdbFileSelect  [(ngModel)]="post.image" (change)="photo($event, post._id ? post._id : '-1')" />
                </div>
              </div>
            </div>
          </div>
        </div>



        <quill-editor class='mt-3' [(ngModel)]='post.contenu' placeholder='Ecrire ici...' [styles]="{'min-height': '300px'}" [modules]='modules'></quill-editor>
        <br>
        <mdb-checkbox [(ngModel)]='post.showButton'>Afficher bouton</mdb-checkbox>
        <br>
        <div *ngIf='post.showButton'>

          <label for="price">Prix</label>
          <input type="number" id='price'
                 class="form-control mb-3" [(ngModel)]='post.price'>

          <label for="courseId">Id du cours</label>
          <input type="number" id='courseId'
                 class="form-control mb-3" [(ngModel)]='post.course'>

          <label for="title">Titre du cours (pour la facture)</label>
          <input type="text" id='title'
                 class="form-control mb-3" [(ngModel)]='post.courseTitle'>

        </div>


      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button (click)='addPost(); postModal.hide()' mdbBtn color="info" class="waves-light" mdbWavesEffect>Enregistrer</button>
      </div>
    </div>
  </div>
</div>
<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Avantages</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                        </div>
                      <div id="kaban">
                        <div id="scroller">
                          <div id="boards">
                            <button class="btn btn-info mb-4" (click)="ajouter()">Ajouter une catégorie</button>

                            <div class="wrapper"  dragula="COLUMNS" [(dragulaModel)]="avantages" (dragulaModelChange)="updateOrders()">
                            <div class="board " *ngFor="let group of avantages">
                               <header class='group-handle' [hidden]="editing">
                                  {{group.ordre }} ) {{group.titre}} <fa-icon class='ml-2' (click)="editing = true" [icon]="faPen"></fa-icon> <fa-icon class='ml-2' (click)='supprimerCategorie(group._id)' [icon]="faTrash"></fa-icon>  <button style='float: right' class="btn btn-sm btn-info" (click)="openModal(group._id)">+</button>
                                </header>
                               <header [hidden]="!editing">
                                  <input type="text" [(ngModel)]="group.titre">
                                 <fa-icon class='ml-2' (click)="editing = false; saveCategories(group)" [icon]="faSave"></fa-icon>
                                </header>
<!--                              <header class='group-handle'>{{group.titre}}</header>-->
                              <div dragula="ITEMS" [(dragulaModel)]="group.posts" (dragulaModelChange)="saveCategories(group)" class="cards" id="b1">
                                <div class="card" *ngFor="let item of group.posts">
                                  <span class="cardtitle noselect">{{item.titre}}  <fa-icon class='ml-2' style='float: right' (click)='supprimerPost(item._id)' [icon]="faTrash"></fa-icon> <fa-icon class='ml-2 mr-2' (click)="loadPost(item._id)" style='float: right' [icon]="faPen"></fa-icon></span>

                                </div>
                              </div>
                            </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
