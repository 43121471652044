import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-tracking',
    templateUrl: './tracking.component.html',
    styleUrls: ['./tracking.component.css'],
})
export class TrackingComponent implements OnInit {
    tracking: any = [];
    identifiantUtilisateur = '';
    typeCompte = '';
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        if (
            this.route.snapshot.params.id !== undefined &&
            this.route.snapshot.params.id !== null &&
            this.route.snapshot.params.typeCompte !== undefined &&
            this.route.snapshot.params.typeCompte !== null
        ) {
            this.identifiantUtilisateur = this.route.snapshot.params.id;
            this.typeCompte = this.route.snapshot.params.typeCompte;
            this.httpClient.get<any>(this.globalService.url + '/tracking/' + this.identifiantUtilisateur + '/' + this.typeCompte).subscribe(
                (response) => {
                    if (response.status) {
                        this.tracking = response.tracking;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
        }
    }
}
