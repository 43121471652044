import { Pipe, PipeTransform } from '@angular/core';
import { GlobalVariable } from '../global';

@Pipe({
    name: 'typesPoste',
})
export class TypesPostePipe implements PipeTransform {
    typesPoste = GlobalVariable.TYPES_POSTES;
    transform(value: unknown, ...args: unknown[]): unknown {
        const obj = this.typesPoste.find((a: { value: any }) => a.value === value);
        return obj ? obj.label : '';
    }
}
