<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <div class="page-inner">
            <div class="page-title">
                <h2 class="breadcrumb-header">Mes informations personnelles</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Gardez vos informations à jour</h2>
            </div>
            <div class="row mt-3">
                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/mail 2.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-candidat/mot-de-passe" class="btn btn-light shadow">
                                Mot de passe
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/mail 7.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-candidat/informations" class="btn btn-light shadow">
                                Informations Personnelles
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/mail.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-candidat/situation" class="btn btn-light shadow">
                                Ma situation
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/mail 6.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-candidat/recherche" class="btn btn-light shadow">
                                Ma recherche
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/mail 4.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-candidat/informations-complementaires" class="btn btn-light shadow">
                                Informations Complémentaires
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 p-3 mb-3 mb-md-0">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/mail 3.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-candidat/autorisations" class="btn btn-light shadow">
                                Mes autorisations
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
