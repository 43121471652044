<app-site-header page="recruteur-proximite"></app-site-header>
<div class="row light_bg full-height align-items-center d-flex contact-1" style="padding-top: 80px; width: 100%; margin: 0">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="oi_icon_titile" style="text-align: center; margin-bottom: 30px">Recruteurs/teuses à proximité</h1>
                <p>
                    Vous pouvez visualiser la localisation des recruteurs/teuses proches de vous (dans un rayon de 10km) en saisissant votre
                    adresse. En cliquant sur le pictogramme rouge, vous pourrez consulter la fiche du recruteur.
                </p>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <input
                            class="form-control pac-target-input"
                            type="text"
                            (input)="changeAddr()"
                            [(ngModel)]="addr.adresse"
                            placeholder="Adresse..."
                        />
                        <div
                            [ngStyle]="{
                                display: this.addrsCheck ? 'block' : 'none'
                            }"
                        >
                            <label>Cliquer sur mon adresse</label>
                            <div class="row">
                                <div class="col-md-12">
                                    <div>
                                        <div class="form-group" style="margin: 0">
                                            <div>
                                                <div
                                                    class="addr"
                                                    *ngFor="let addr of addrs"
                                                    (click)="
                                                        chooseAddr(addr.adresse, addr.postcode, addr.city, addr.latitude, addr.longitude)
                                                    "
                                                >
                                                    {{ addr.adresse }},
                                                    {{ addr.postcode }}
                                                    {{ addr.city }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="apiLoaded | async" id="map form-group" style="margin-top: 15px; height: 600px">
                  <google-map
                    class="agm"
                    [center]="center"
                    [mapTypeId]="googleMapType"
                    [zoom]="zoom"
                  >
                    <map-marker
                      #marker="mapMarker"
                      *ngFor="let r of recruteurs"
                      [position]="getRecruteurPos(r.latitude, r.longitude)"
                      (mapClick)="openInfoWindow(marker, r)"
                    >


                    </map-marker>
                    <map-info-window>
                      <div class="bull">
                      <div>
                        <strong>Organisme :</strong>
                        {{ recruteur.nomSociete }}
                      </div>
                      <div>
                        <strong>Secteur(s) :</strong>
                        <span *ngFor="let s of recruteur.secteurEntreprise"> {{ s.nom }}</span>
                      </div>
                      <div>
                        <button class="btn btn-success" (click)="fiche()">Consulter</button>
                      </div>
                    </div>
                    </map-info-window>
                  </google-map>
                </div>
            </div>
            <div class="offset-md-2 col-md-4" *ngIf="actif">
                <div class="information">Recruteur/teuse {{ recruteur.referenceRecruteur }}</div>
                <div class="information">Nom de l'organisme : {{ recruteur.nomSociete }}</div>
                <div class="information" *ngIf="recruteur.logoGroupe != ''">
                    Logo de l'organisme :
                    <img style="max-width: 100px" src="{{ this.url }}/images/entreprise/{{ recruteur.logoGroupe }}" />
                </div>
                <div class="information">Type d'organisme : {{ recruteur.typeEntreprise | typesEntreprises }}</div>
                <div class="information">
                    Secteur(s) d'activité(s) :
                    <span *ngFor="let s of recruteur.secteurEntreprise"> {{ s.nom }}</span>
                </div>
                <div class="information">Activité(s) détaillée(s) : {{ recruteur.activiteTexte }}</div>
                <div class="information">Nombre de salariés : {{ recruteur.nombreSalarie | workersNumber }}</div>
                <div class="information" *ngIf="recruteur.nomGroupe != ''">Groupe : {{ recruteur.nomGroupe }}</div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
