import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-tarif',
    templateUrl: './tarif.component.html',
    styleUrls: ['./tarif.component.css', './../../../../assets/css/site/main.css'],
})
export class TarifComponent implements OnInit {
    isMobile = false;
    role = '' || null;
    tarifs: any = [];
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private deviceService: DeviceDetectorService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.getTarifs();
        this.isMobile = this.deviceService.isMobile();

        // @ts-ignore
        this.role = this.authService.role;
    }
    getTarifs(): void {
        this.httpClient.get<any>(this.globalService.url + '/tarif/Candidat/').subscribe(
            (response) => {
                if (response.status) {
                    this.tarifs = response.liste.reverse();
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
