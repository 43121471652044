import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-conseil',
    templateUrl: './conseil.component.html',
    styleUrls: ['./conseil.component.css'],
})
export class ConseilComponent implements OnInit {
    faqs = [
        {
            question: 'Pourquoi se faire accompagner par un professionnel de l’accompagnement au retour à l’emploi ?',
            reponse:
                "Tout simplement, parce qu'il est aujourd'hui long et difficile de trouver un emploi et que l’accompagnement par un consultant expérimenté va vous permettre non seulement de <strong>gagner beaucoup de temps</strong>, mais aussi d'<strong>éviter de commettre des erreurs</strong> préjudiciables (qui sont parfois irréparables).<br /><br />" +
                "On peut, par exemple, faire le parallèle avec le ski. Vous pouvez apprendre seul, mais cela vous prendra plus de temps seul qu'avec un moniteur expérimenté et vous risquez de belles chutes...",
        },
    ];
    constructor() {}

    ngOnInit(): void {}
}
