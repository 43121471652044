<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Tracking</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table class="display table dataTable">
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Page</td>
                                            <td>IP</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let t of tracking">
                                            <td>
                                                {{ t.date * 1000 | date: 'dd-MM-yyyy HH:mm:ss' }}
                                            </td>
                                            <td>
                                                {{ t.requete }}
                                            </td>
                                            <td>
                                                {{ t.ip }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
