<div class='col-12 col-md-6 pl-0 pr-0'>
  <ng-content></ng-content>
  <div class='md-form'>
    <mdb-select-2 class='custom-selectbox' style='width: 100%' [multiple]='true' placeholder='Choisir' label=''  [outline]="true">
<!--      <mdb-select-filter [ngModel]="searchSecteur | async" (inputChange)="searchSecteur.next($event)">-->
<!--      </mdb-select-filter>-->
      <mdb-option-group (selectionChange)='selectAll($event, secteur)' *ngFor='let secteur of secteurs' [label]='secteur.name'>
        <mdb-select-option [_selected]='option.selected'  *ngFor='let option of secteur.options' [value]='option.value'
                           (click)='chooseSecteurs(option.value)'>{{ option.label }}</mdb-select-option>
      </mdb-option-group>
    </mdb-select-2>

  <div *ngIf='!admin'>
  <br>
  <br>
  <a *ngIf='!showRequest' (click)='showRequest=true' class='font-weight-bold'
     style='text-decoration: underline; font-size: 15px '>Signaler au responsable du site que ne trouve pas le secteur
    afin qu'il soit ajouté à la liste</a>

  <div *ngIf='showRequest' class='row'>
    <div class='col-8'>
      <input
        type='text'
        class='form-control'
        [(ngModel)]='request'
        placeholder='Saisir'
      />
    </div>
    <div class='col-4'>
      <a
        type='button'
        style='color: white'
        (click)='sendSecteurRequest()'
        mdbBtn
        [rounded]='true'
        color='warning'
        class='waves-effect'
        mdbWavesEffect
      >Envoyer
      </a>
    </div>
  </div>
</div>
</div>
</div>

<div class='col-12 col-md-6'>
  <div class='form-group'>
    <div style='position: relative' *ngFor='let s of data'>
      <label class='container_radio version_2' style='padding-left: 16px'
      >{{ s.nom }}
        <input type='radio' name='positions' value='Backend-Developer' disabled />
      </label>
      <div class='container-cross' (click)='removeSecteurs(s.nom)'>
        <div class='cross'>x</div>
      </div>
    </div>
  </div>
</div>
