import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { PaymentService } from 'src/app/services/payment.service';
import { AuthService } from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';
import { ToastService } from '../../../components/mdb/pro/alerts';

@Component({
    selector: 'app-avantages-candidat',
    templateUrl: './avantages.component.html',
    styleUrls: [
        './avantages.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class AvantagesCandidatComponent implements OnInit {
    avantages: any = [];
    url = '';
    candidat: any = [];
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private paymentService: PaymentService,
        private authservice: AuthService,
        private userService: UserService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {
        this.url = this.globalService.url;
        this.getAvantages();
        this.getAccount();
    }

    getAvantages(): void {
        this.avantages = [];
        this.httpClient.get<any>(this.globalService.url + '/avantages?type=candidat').subscribe(
            (response) => {
                if (response.status) {
                    this.avantages = response.avantages;
                } else {
                }
            },
            () => {}
        );
    }
    getAccount(): void {
        this.userService.getAccount().then((response: any) => {
            if (response.status) {
                this.candidat = response.candidat;
                if (!this.candidat.formations) {
                    this.candidat.formations = [];
                }
            } else {
                this.toastrService.error('Erreur lors de la récupération des informations', '', { opacity: 1 });
            }
        });
    }
    joinAdvantage(post: any): void {
        if (post.price > 0) {
            this.httpClient
                .post<any>(this.globalService.url + '/create-avantage-checkout-session/', {
                    id: this.authservice.id,
                    role: this.authservice.role,
                    price: post.price,
                    course: post.course,
                    courseTitle: post.courseTitle,
                })
                .subscribe(
                    (response) => {
                        this.paymentService.redirectToStripeCheckout(response.sessionId);
                    },
                    () => {}
                );
        } else {
            this.httpClient
                .post<any>(this.globalService.url + '/avantages/enroll', {
                    id: this.authservice.id,
                    role: this.authservice.role,
                    course: post.course,
                })
                .subscribe(
                    (response) => {
                        this.toastrService.info('Inscription réussie, merci de vérifier vos emails', '', {
                            opacity: 1,
                        });
                    },
                    () => {}
                );
        }
    }
}
