<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div>
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">Commandes acceptées</h3>
                </div>
                <div id="main-wrapper">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="panel panel-white">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="display table dataTable">
                                            <thead>
                                                <tr>
                                                    <th>N° commande</th>
                                                    <th>Date de commande</th>
                                                    <th>Date de diffusion</th>
                                                    <th>Heure de diffusion</th>
                                                    <th>Nombre de recruteurs</th>
                                                    <th>Prix</th>
                                                    <th>Consulter</th>
                                                    <th>Sender vérifié</th>
                                                    <th>Facture</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let c of commandes">
                                                    <td>
                                                        {{ c.numeroCommande }}
                                                    </td>
                                                    <td>
                                                        {{ c.dateCommande * 1000 | date: 'dd-MM-yyyy HH:mm' }}
                                                    </td>
                                                    <td>
                                                        <div *ngIf="c.dateDebutDiffusion === 0">Sans préférence</div>
                                                        <div *ngIf="c.dateDebutDiffusion !== 0">
                                                            {{ c.dateDebutDiffusion | date: 'dd-MM-yyyy' }}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="c.heureDebutDiffusion === 0">Sans préférence</div>
                                                        <div *ngIf="c.heureDebutDiffusion !== 0">
                                                            {{ c.heureDebutDiffusion }}
                                                            heure
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {{ c.nombreCourriel - c.recruteursRetrait.length }}
                                                    </td>
                                                    <td>
                                                        <div
                                                            *ngIf="
                                                                c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                                    c.reduction.montantTtc -
                                                                    c.credit >
                                                                    0 &&
                                                                c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                                    c.reduction.montantTtc -
                                                                    c.credit <
                                                                    1
                                                            "
                                                        >
                                                            1 €
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                                    c.reduction.montantTtc -
                                                                    c.credit <=
                                                                0
                                                            "
                                                        >
                                                            Gratuit
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                                    c.reduction.montantTtc -
                                                                    c.credit >=
                                                                1
                                                            "
                                                        >
                                                            {{
                                                                (
                                                                    c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                                    c.reduction.montantTtc -
                                                                    c.credit
                                                                ).toFixed(2)
                                                            }}
                                                            €
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a
                                                            class="btn btn-info"
                                                            target="_blank"
                                                            href="/administration/detail-commande/{{ c._id }}"
                                                        >
                                                            Consulter
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="c.emailValider">Oui</div>
                                                        <div *ngIf="!c.emailValider">Non</div>
                                                    </td>
                                                    <td>
                                                        <a
                                                            target="_blank"
                                                            href="{{ url }}/documents/facture/{{ c.numeroCommande }}.pdf?document={{
                                                                c.numeroCommande
                                                            }}&token={{ token }}"
                                                            download="facture"
                                                            ><fa-icon [icon]="faPdf"></fa-icon
                                                        ></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
