import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { MdbTableDirective, MdbTablePaginationComponent } from '../../../components/mdb/free/tables';
import { GlobalVariable } from '../../../global';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-liste-facture',
    templateUrl: './liste-facture.component.html',
    styleUrls: [
        './liste-facture.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class ListeFactureComponent implements OnInit, AfterViewInit {
    @ViewChild(MdbTablePaginationComponent, { static: true })
    mdbTablePagination!: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true })
    mdbTable!: MdbTableDirective;
    factures: any = [];
    previous: any = [];
    headElements = ['N° de commande', 'Date de facturation', 'Facture'];
    url = '';
    faPdf = faFilePdf;
    displayByPages = GlobalVariable.PAGES;
    isMobile = false;
    token = '';

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private cdRef: ChangeDetectorRef,
        private authService: AuthService,
        private deviceService: DeviceDetectorService
    ) {}

    ngOnInit(): void {
        this.getCommande();
        this.token = this.authService.authToken;
        this.url = this.globalService.url;
        this.isMobile = this.deviceService.isMobile();
    }
    ngAfterViewInit(): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
    display(): string {
        if (this.factures.length === 0) {
            return 'none';
        }
        return 'table';
    }
    getCommande(): void {
        this.httpClient.post<any>(this.globalService.url + '/commande/get-commandes', { id: this.authService.id }, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.factures = response.commandes;
                    this.mdbTable.setDataSource(this.factures);
                    this.factures = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                }
            },
            (err) => {}
        );
    }
    changeItemsByPage(event: any): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(event);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
    }
}
