<div
  mdbModal
  #reportModal="mdbModal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myBasicModalLabel"
  aria-hidden="true"
  style="overflow: auto; overflow-x: hidden; z-index: 1051"

>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style='display: block'>
        <button type="button" class="close pull-right" aria-label="Close" (click)="reportModal.hide();modal ? modal.show() : null">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Signalement</h4>
      </div>
      <!--Body-->
      <div class="modal-body" *ngIf='!admin'>
        <div class="text-center">
          <mdb-icon far icon="file-alt" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
          <p>
            <strong>Un problème avec une fiche ?</strong>
          </p>
          <p>Veuillez remplir ci-dessous quelques informations.
          </p>
        </div>
        <hr>
        <!-- Radio -->
        <p class="text-center pb-3">
          <strong>Veuillez expliquer la raison du signalement</strong>
        </p>
        <!--Basic textarea-->
        <div class="md-form">
          <textarea [(ngModel)]='description' type="text" id="form79textarea" class="md-textarea form-control" rows="3"
                    mdbInput></textarea>
          <label for="form79textarea">Votre message</label>
        </div>
        <div class='d-flex justify-content-center pt-2'>
          {{ fileName }}
        </div>
        <div class="file-field md-form d-flex justify-content-center ">
          <div mdbBtn [rounded]="true" [outline]="true" color="primary" size="md" mdbWavesEffect>
            <span class="pt-1">Choisir un fichier</span>
            <input type="file" (change)="fichier($event)" mdbFileSelect>
          </div>
        </div>
      </div>
      <div class="modal-body" *ngIf='admin'>
        <div class="md-form">
          <textarea [(ngModel)]='description' disabled type="text" id="form81textarea" class="md-textarea form-control" rows="3"
                    mdbInput></textarea>
          <label for="form81textarea">Message</label>
        </div>
        <div *ngIf='fileName' class='d-flex justify-content-center pt-2 pb-2'>
          <a style='text-decoration: underline !important;' target="_blank" class="ml-2" href="{{ url }}/images/reports/{{ fileName }}">Fichier : {{ fileName }}</a>
        </div>
        <div class="md-form pt-2">
          <textarea [(ngModel)]='note' (ngModelChange)='update()' type="text" id="form80textarea" class="md-textarea form-control" rows="3"
                    mdbInput></textarea>
          <label for="form80textarea">Note</label>
        </div>
        <div class="md-form pt-2">
          Statut <br><br>
          <mdb-select-2
            placeholder="Choisir.."
            name="status"
            class="custom-selectbox"
            (selected)='update()'
            [outline]="true"
            [(ngModel)]="status"
          >
            <mdb-select-option *ngFor="let option of reportsStates" [value]="option.value">{{
              option.label
              }}</mdb-select-option>
          </mdb-select-2>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          mdbBtn
          [rounded]='true'
          color="primary"
          class="waves-light"
          aria-label="Envoyer"
          (click)="sendReport()"
          mdbWavesEffect
          *ngIf='!admin'
        >
          Envoyer
        </button>
        <button
          type="button"
          mdbBtn
          [rounded]='true'
          color="warning"
          class="waves-light"
          aria-label="Fermer"
          (click)="reportModal.hide();modal ? modal.show() : null"
          mdbWavesEffect
        >
          Fermer
        </button>
      </div>
    </div>
  </div>
</div>
