import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from '../../mdb/free/modals';
import { faFileContract, faEye } from '@fortawesome/free-solid-svg-icons';
import { GlobalService } from '../../../services/global.service';
import { ReportModalComponent } from '../report-modal/report-modal.component';

@Component({
    selector: 'app-modal-candidat',
    templateUrl: './candidat-form.component.html',
    styleUrls: ['./candidat-form.component.css', './../../../../assets/css/administration/space.min.css'],
})
export class CandidatFormComponent implements OnInit {
    @ViewChild('candidatModal', { static: true }) candidatModal!: ModalDirective;
    @ViewChild('reportModal', { static: true }) reportModal!: ReportModalComponent;

    faFileContract = faFileContract;
    faEye = faEye;

    @Input() candidat: any;
    @Input() cv: any;
    @Input() lettreMotivation: any;
    @Input() showLm = true;
    url = '';
    constructor(private globalService: GlobalService) {}
    ngOnInit(): void {
        this.url = this.globalService.url;
    }
    openForm(): void {
        this.candidatModal.show();
    }
}
