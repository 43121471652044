<div class='form-group'>
  <p class='section_title'>
                                <span class='font-weight-bold'>Rédigez ci-dessous votre lettre de motivation (100 caractères minimum) {{ required ? '* ' : ''}}</span
                                >
    <fa-icon [icon]='faInfoCircle'  mdbPopover="Ouvrez la parenthèse et choisissez l’élément qui sera remplacé dans les lettres de motivation par l’information de chaque recruteur concerné.
Exemple : (prenom_recruteur) (nom_recruteur) sera remplacé par « Jean Jerecrute » si le prénom du recruteur est « Jean » et son nom « Jerecrute »" placement="right" >
    </fa-icon><br />
    Vous pouvez la personnaliser avec ces éléments :
    <br />
    (civilite_recruteur), (nom_recruteur), (prenom_recruteur), (nom_organisme)
  </p>
  <p style='color: red'>Il est interdit de mettre dans votre message un lien vers un site Internet.</p>
  <div class='form-group'>
    <quill-editor #editor class='mt-3' [(ngModel)]='lettreMotivation' (ngModelChange)='updateLm($event)'
                  placeholder='Bonjour (civilite_recruteur) (nom_recruteur)...' [styles]="{'min-height': '300px', 'background' : 'white', 'display' : 'grid'}"
                  [modules]='modules'></quill-editor>
    <div id='mat-quill-counter'></div>

  </div>
</div>
