import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-comptes-administrateur',
    templateUrl: './comptes-administrateur.component.html',
    styleUrls: [
        './comptes-administrateur.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/jquery.datatables.min.css',
    ],
})
export class ComptesAdministrateurComponent implements OnInit {
    admins = [
        {
            _id: '',
            identifiant: '',
            newPassword: '',
            typeAdministrateur: '',
            status: true,
        },
    ];
    newAdmin = {
        password: '',
        identifiant: '',
        typeAdministrateur: 'validateur',
        status: false,
    };
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.recuperer();
    }
    ajouter(): void {
        this.httpClient.put(this.globalService.url + '/admin', this.newAdmin).subscribe(
            (response: any) => {
                if (response.status) {
                    this.recuperer();
                    this.newAdmin.identifiant = '';
                    this.newAdmin.password = '';
                    this.newAdmin.typeAdministrateur = '';
                } else {
                    alert("L'identifiant est déjà utilisé par un autre administrateur");
                }
            },
            () => {
                alert("L'identifiant est déjà utilisé par un autre administrateur");
            }
        );
    }
    changerStatus(id: string): void {
        for (let a of this.admins) {
            if (a._id === id) {
                this.httpClient.patch(this.globalService.url + '/admin/' + id, { status: a.status }, {}).subscribe(
                    (response: any) => {
                        if (response.status) {
                            this.recuperer();
                        }
                    },
                    () => {}
                );
            }
        }
    }
    modifier(id: string): void {
        for (const a of this.admins) {
            if (a._id === id) {
                this.httpClient
                    .post(
                        this.globalService.url + '/admin/' + id,
                        {
                            password: a.newPassword,
                            typeAdministrateur: a.typeAdministrateur,
                        },
                        {}
                    )
                    .subscribe(
                        (response: any) => {
                            if (response.status) {
                                a.newPassword = '';
                            }
                        },
                        () => {}
                    );
            }
        }
    }
    supprimer(id: string): void {
        this.httpClient.delete(this.globalService.url + '/admin/' + id).subscribe(
            (response: any) => {
                if (response.status) {
                    this.recuperer();
                }
            },
            () => {}
        );
    }
    recuperer(): void {
        this.httpClient.get<any>(this.globalService.url + '/admin').subscribe(
            (response: any) => {
                if (response.status) {
                    this.admins = response.admins;
                }
            },
            () => {}
        );
    }
}
