<app-site-header [hideRecruteur]='true'></app-site-header>
<!-- Login Hero Section HTML Start -->
<div
    mdbModal
    #confirmMailModal="mdbModal"
    class="modal fade top"
    id="frameModalTop2"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-notify modal-danger" role="document">
        <!--Content-->
        <div class="modal-content text-center">
            <!--Body-->
            <div class="modal-body">
                <h4 class="font-weight-bold pb-2">CONFIRMATION</h4>
                Merci de confirmer votre adresse E-mail
                {{ this.register.email }}. Elle ne sera plus modifiable par la suite. Est-elle correcte ?
            </div>
            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a
                    (click)="createAccount()"
                    mdbBtn
                    color="danger"
                    style="border: 2px solid #d89e9e !important; color: #d89e9e !important"
                    [outline]="true"
                    class="waves-effect"
                    mdbWavesEffect
                    >OUI</a
                >
                <a
                    type="button"
                    mdbBtn
                    color="danger"
                    class="waves-effect"
                    style="border-color: #d89e9e !important; background-color: #d89e9e !important"
                    mdbWavesEffect
                    data-dismiss="modal"
                    (click)="confirmMailModal.hide()"
                    >NON</a
                >
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<section id="Login-hero">
    <div class="login-hero-wrapper">
        <div class="row">
            <div class="col-md-6 pl-md-4 pl-lg-0">
                <div [@inOutAnimation] [hidden]="inscription" class="login-hero-left">
                    <h2>Connectez-vous</h2>
                    <p>Rentrez vos identifiants afin d’accéder à votre espace personnel</p>
                    <form>
                        <div class="input-group email-input">
                            <input name="email" id="email" [(ngModel)]="login.identifiant" type="email" placeholder="Email" />
                        </div>
                        <div class="input-group password-input">
                            <input [type]="type1" name="password" id="password" [(ngModel)]="login.password" placeholder="Mot de passe" />
                            <span class="eye"><fa-icon class="eye" (click)="eye()" [icon]="eye1"></fa-icon></span>
                        </div>
                        <p *ngIf="error !== ''" class="error pr-0 text-center pt-1">
                            {{ error }}
                        </p>
                        <p *ngIf="tentativeConnexion" class="form-row" style="display: block; width: 20px; margin: auto">
                            <img src="./assets/img/gif.gif" class='mt-2 ml-4' style="max-width: 25px; text-align: center" />
                        </p>
                        <a class="forgot-pass" href="/demande-de-renouvellement-de-mon-mot-de-passe">Mot de passe oublié ?</a>
                        <div class="input-group submit-btn">
                            <input type="submit" value="Connexion" (click)="connexion()" />
                        </div>
                        <span class="or">OU</span>
                        <div class="social-login">
                          <div class="google">
                            <a (click)="socialLogin('google')"><fa-icon class='fa fa-2x text-warning'  [icon]="faGoogle"></fa-icon>Continuer avec Google</a>
                          </div>
                          <div class="facebook">
                            <a
                              (click)="socialLogin('facebook')"
                            ><fa-icon class='fa fa-2x'  [icon]="faFacebook"></fa-icon>Continuer avec Facebook</a
                            >
                          </div>
                          <div class="linkedin">
                            <a
                              (click)="socialLogin('linkedin')"
                            ><fa-icon class='fa fa-2x'  [icon]="faLinkedin"></fa-icon>Continuer avec Linkedin</a
                            >
                          </div>
                            <div class="register-btn pt-4">
                                <h6>
                                    Je n’ai pas encore de compte ?
                                    <a (click)="showInscription()">Je m’inscris !</a>
                                </h6>
                            </div>
                            <div class="chevron-down text-center pt-3">
                                <img src="./assets/img/chevron-down.png" alt="chevron-down" />
                            </div>
                        </div>
                    </form>
                </div>
                <div [@inOutAnimation] class="login-hero-left" [hidden]="!inscription">
                    <h2>Inscrivez-vous</h2>
                    <p>Créez votre compte afin d’accéder à votre espace personnel</p>
                    <form>
                        <div class="input-group email-input">
                            <input name="email" id="email" [(ngModel)]="register.email" type="email" placeholder="Email" />
                        </div>
                        <p *ngIf="error !== ''" class="error">
                            {{ error }}
                        </p>
                        <p *ngIf="tentativeConnexion" class="form-row" style="display: block; width: 20px; margin: auto">
                            <img src="./assets/img/gif.gif" class='mt-2 ml-4' style="max-width: 25px; text-align: center" />
                        </p>
                        <div class="input-group submit-btn">
                            <input type="submit" value="Inscription" (click)="inscriptionAction()" />
                        </div>
                        <span class="or">OU</span>
                        <div class="social-login">
                          <div class="google">
                            <a href='{{url}}/auth/google?type=recruteur'><fa-icon class='fa fa-2x text-warning'  [icon]="faGoogle"></fa-icon>S'inscrire avec Google</a>
                          </div>
                          <div class="facebook">
                            <a
                              href='{{url}}/auth/facebook?type=recruteur'
                            ><fa-icon class='fa fa-2x'  [icon]="faFacebook"></fa-icon>S'inscrire avec Facebook</a
                            >
                          </div>
                          <div class="linkedin">
                            <a
                              href='{{url}}/auth/linkedin?type=recruteur'
                            ><fa-icon class='fa fa-2x'  [icon]="faLinkedin"></fa-icon>S'inscrire avec Linkedin</a
                            >
                          </div>
                            <div class="register-btn pt-4">
                                <h6>
                                    J'ai déjà un compte ?
                                    <a (click)="showInscription()">Je me connecte !</a>
                                </h6>
                            </div>
                            <div class="chevron-down text-center pt-3">
                                <img src="./assets/img/chevron-down.png" alt="chevron-down" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <div class="login-hero-recruteur-right position-relative">
                    <div class="login-slider">
                        <div class="login-slide">
                            <h3>
                                Espace recruteur <br />
                                Comment ça fonctionne ?
                            </h3>
                            <div
                                class="slide-content align-items-center py-2"
                                *ngFor="let slide of recruteurSlides; let title = index"
                                [ngClass]="title === this.index ? 'slide-active' : ''"
                            >
                                <h1>{{ title + 1 }}</h1>
                                <h5>
                                    {{ slide.text }}
                                </h5>
                            </div>
                            <div class="slide-icons d-flex justify-content-between align-items-center">
                                <img class="prev-recruteur" (click)="prev()" src="./assets/img/arrow-left.png" alt="next" />
                                <img class="next-recruteur" (click)="next()" src="./assets/img/arrow-right.png" alt="next" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="Mobile-login" class="d-sm-none">
    <div class="container">
        <div class="mobile-login-tab">
            <div class="login-tab-btn d-flex justify-content-center mb-3 position-relative">
                <a (click)="showInscription()"
                    ><li data-tab-target="#Login" [ngClass]="!inscription ? 'tab-btn tab-btn-active' : 'tab-btn'">Connexion</li></a
                >
                <a (click)="showInscription()"
                    ><li data-tab-target="#Register" [ngClass]="inscription ? 'tab-btn tab-btn-active' : 'tab-btn'">Inscription</li></a
                >
            </div>
        </div>
        <div [hidden]="inscription" id="Login" [@inOutAnimation] data-tab-content class="login-tab tab-active">
            <p>Rentrez vos identifiants afin d’accéder à votre espace personnel</p>
            <form>
                <div class="input-group email-input">
                    <input name="email" id="email-mobile" [(ngModel)]="login.identifiant" type="email" placeholder="Email" />
                </div>
                <div class="input-group password-input">
                    <input [type]="type1" name="password" id="password-mobile" [(ngModel)]="login.password" placeholder="Mot de passe" />
                    <span class="eye"><fa-icon class="eye" (click)="eye()" [icon]="eye1"></fa-icon></span>
                </div>
                <p *ngIf="error !== ''" class="error">
                    {{ error }}
                </p>
                <p *ngIf="tentativeConnexion" class="form-row" style="display: block; width: 20px; margin: auto">
                    <img src="./assets/img/gif.gif" class='mt-2 ml-4'  style="max-width: 25px; text-align: center" />
                </p>
                <a class="forgot-pass" href="/demande-de-renouvellement-de-mon-mot-de-passe">Mot de passe oublié ?</a>
                <div class="input-group submit-btn">
                    <input type="submit" value="Connexion" (click)="connexion()" />
                </div>
                <span class="or">OU</span>
                <div class="social-login">
                  <div class="google">
                    <a href='{{url}}/auth/google?type=recruteur'><fa-icon class='fa fa-2x text-warning'  [icon]="faGoogle"></fa-icon>Continuer avec Google</a>
                  </div>
                  <div class="facebook">
                    <a
                      href='{{url}}/auth/facebook?type=recruteur'
                    ><fa-icon class='fa fa-2x'  [icon]="faFacebook"></fa-icon>Continuer avec Facebook</a
                    >
                  </div>
                  <div class="linkedin">
                    <a
                      href='{{url}}/auth/linkedin?type=recruteur'
                    ><fa-icon class='fa fa-2x'  [icon]="faLinkedin"></fa-icon>Continuer avec Linkedin</a
                    >
                  </div>
                </div>
            </form>
        </div>
        <div [@inOutAnimation] [hidden]="!inscription" data-tab-content class="login-tab tab-active">
            <p>Créez votre compte afin d’accéder à votre espace personnel</p>
            <form>
                <div class="input-group email-input">
                    <input name="email" id="register-mobile" [(ngModel)]="register.email" type="email" placeholder="Email" />
                </div>
                <p *ngIf="error !== ''" class="error">
                    {{ error }}
                </p>
                <p *ngIf="tentativeConnexion" class="form-row" style="display: block; width: 20px; margin: auto">
                    <img src="./assets/img/gif.gif" class='mt-2 ml-4' style="max-width: 25px; text-align: center" />
                </p>
                <div class="input-group submit-btn">
                    <input type="submit" value="Inscription" (click)="inscriptionAction()" />
                </div>
                <span class="or">OU</span>
                <div class="social-login">
                  <div class="google">
                    <a href='{{url}}/auth/google?type=recruteur'><fa-icon class='fa fa-2x text-warning'  [icon]="faGoogle"></fa-icon>S'inscrire avec Google</a>
                  </div>
                  <div class="facebook">
                    <a
                      href='{{url}}/auth/facebook?type=recruteur'
                    ><fa-icon class='fa fa-2x'  [icon]="faFacebook"></fa-icon>S'inscrire avec Facebook</a
                    >
                  </div>
                  <div class="linkedin">
                    <a
                      href='{{url}}/auth/linkedin?type=recruteur'
                    ><fa-icon class='fa fa-2x'  [icon]="faLinkedin"></fa-icon>S'inscrire avec Linkedin</a
                    >
                  </div>
                </div>
            </form>
        </div>
    </div>
</section>

<!-- Login Hero Section HTML End -->

<!-- Avenue Hero Section HTML Start -->

<section id="Avenue">
    <div class="avenue-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="avenue-content">
                        <h2 class="section-title">Avenue Des Recruteurs vous permet :</h2>
                        <div class="avenue-list">
                          <ul>
                            <li>d’accéder à une CVthèque de qualité</li>
                            <li>de recevoir des candidatures ciblées</li>
                            <li>d’améliorer (si souhaité) votre visibilité sur Internet</li>
                            <li>de participer à des webinaires sur le thème de la prévention et de la santé au travail</li>
                            <li>de bénéficier de formations en e-learning : Word, Excel, PowerPoint, Photoshop, diagnostic et prévention des risques psychosociaux…</li>
                            <li>d’accéder, à titre personnel, à des tarifs privilégiés auprès de milliers d’enseignes : auto-moto ; beauté ; cadeaux ; équipement de la maison ; fleurs ; hébergement ; loisirs… (offre limitée dans le temps)</li>
                            <li>de télécharger des musiques de relaxation</li>
                            <li>etc…</li>
                          </ul>
                        </div>
                        <div class="avenue-counters d-none justify-content-center align-items-center">
                            <div class="counter-item text-center">
                              <h3 [countUp]="nombreRecruteurs" [options]="{ enableScrollSpy: true }">0</h3>
                              <h6 style='font-size: 20px; font-weight: bold'>Recruteurs</h6>
                            </div>
                            <div class="counter-item text-center">
                              <h3 [countUp]="nombreCandidats" [options]="{ enableScrollSpy: true }">0</h3>
                              <h6 style='font-size: 20px; font-weight: bold'>Candidats</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Avenue Hero Section HTML End -->

<!-- Strengths Section HTML Start -->

<section id="Strengths">
    <div class="container strengths-wrapper">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="strengths-content">
                    <h2 class="section-title">Nos principaux atouts :</h2>
                    <div class="avenue-list pt-2">
                        <ul>
                            <li>Mise à jour régulière de la CVthèque</li>
                            <li>
                                Fiches candidats détaillées : poste(s) recherché(s), disponibilité, mobilité, atouts concurrentiels,
                                rémunération souhaitée…
                            </li>
                            <li>Système de recherche précis</li>
                            <li>
                                Référencement automatique possible de votre profil (photo, société, groupe, secteur d’activité,
                                localisation…) sur Internet
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="strengths-img">
                    <img src="./assets/img/strengths.png" alt="strengths" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Strengths Section HTML End -->

<!-- Service Section HTML Start -->

<section id="Service">
    <div class="service-wrapper">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="service-left">
                        <h2 class="section-title">Le savez-vous ?</h2>
                        <p>
                            Avenue Des Recruteurs réalise régulièrement des études auprès de recruteurs et de candidats afin de vous aider
                            dans votre recherche.
                        </p>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="service-right">
                        <div class="service-items d-sm-flex">
                            <div class="service-item gray-bg">
                                <h3>Candidatures spontanées</h3>
                                <p>
                                    D’après une étude réalisée fin 2020 par l’équipe d’Avenue Des Recruteurs, 100% des recruteurs et
                                    recruteuses interrogés aiment recevoir des candidatures spontanées et 95% de ces dernier/ères ont déjà
                                    recruté par ce biais.
                                </p>
                            </div>
                            <div class="service-item">
                                <h3>Ce qu’ils apprécient</h3>
                                <p>
                                    D’après cette même étude, les recruteurs et recruteuses apprécient tout particulièrement la démarche
                                    spontanée des candidat(e)s motivé(e)s.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Service Section HTML End -->

<!-- Testimonial Section HTML Start -->

<section id="Testimonial" style='display: none'>
    <div class="container">
        <div class="testimonial-wrapper">
            <div class="testimonial-item d-md-flex justify-content-between align-items-center">
                <div class="col-md-6">
                    <img class="testimonial-img" src="./assets/img/client-1.png" alt="client-1" />
                </div>
                <div class="col-md-6">
                    <div class="testimonial-content">
                        <h2 class="section-title pb-3">Témoignages de recruteurs</h2>
                        <div
                            class="client-slide"
                            *ngFor="let t of temoignages"
                            [ngClass]="this.index2 + 1 === t.ordre ? 'active-class' : ''"
                            [ngStyle]="{
                                display: this.index2 + 1 === t.ordre ? 'block' : 'none'
                            }"
                        >
                            <img
                                class="rounded-circle img-fluid d-block ml-auto mr-auto p-2"
                                style="object-fit: cover; width: 100px; height: 100px"
                                src="{{ url }}/images/temoignages/{{ t.photo }}"
                                alt="user"
                            />
                            <p>
                                {{ t.texte }}
                            </p>
                            <div class="client-name pb-3">
                                <h4>{{ t.nom }}</h4>
                            </div>
                        </div>

                        <div class="testimonial-icon text-center">
                            <img class="arrow-left" (click)="left()" src="./assets/img/arrow-left.png" alt="arrow-left" />
                            <img class="arrow-right" (click)="right()" src="./assets/img/arrow-right.png" alt="arrow-right" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Testimonial Section HTML End -->

<!-- Recruiters Section HTML Start -->

<section id="Recruiters">
    <div class="recruiters-wrapper">
        <h2>Derniers candidats inscrits</h2>
    </div>
    <div class="recruiters-item-wrapper">
        <div class="recruteurs d-flex justify-content-center">
            <div
                [class]="'recruteurs-item ' + (i >= 1 && i <= 3 ? 'bg-dif' : '')"
                class="recruteurs-item"
                *ngFor="let account of dernierInscrit.slice(0, 5); let i = index"
            >
                <img
                    class="rounded-circle img-fluid"
                    style="object-fit: cover; width: 100px; height: 100px"
                    src="{{ url }}/images/candidat/{{ account.photo }}"
                    alt="user"
                />
                <h6>{{ account.fonctionLibre | firstUpperText }}</h6>
            </div>
        </div>
    </div>
</section>
<app-site-footer></app-site-footer>
