<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div>
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">Commandes refusées temporairement</h3>
                </div>
                <div id="main-wrapper">
                    <div class="row">
                        <div class="col-md-12" *ngIf="commandes.length === 0">Aucune commande en attente</div>
                        <div class="col-md-12" *ngIf="commandes.length !== 0">
                            <div class="panel panel-white">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="display table dataTable">
                                            <thead>
                                                <tr>
                                                    <th>Date du refus</th>
                                                    <th>Motif de refus</th>
                                                    <th>Nom du candidat</th>
                                                    <th>Email du candidat</th>
                                                    <th>Téléphone du candidat</th>
                                                    <th>Nombre de recruteurs</th>
                                                    <th>Consulter</th>
                                                    <th>Refuser définitivement</th>
                                                    <th>Facture</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let c of commandes">
                                                    <td>
                                                        {{ c.dateRefus * 1000 | date: 'dd-MM-yyyy HH:mm' }}
                                                    </td>
                                                    <td>{{ c.motifRefus }}</td>
                                                    <td>
                                                        {{ c.candidat.nom }}
                                                        {{ c.candidat.prenom }}
                                                    </td>
                                                    <td>
                                                        {{ c.candidat.email }}
                                                    </td>
                                                    <td>
                                                        {{ c.candidat.telephonePortable }}
                                                    </td>
                                                    <td>
                                                        {{ c.nombreCourriel - c.recruteursRetrait.length }}
                                                    </td>
                                                    <td>
                                                        <a
                                                            class="btn btn-info"
                                                            target="_blank"
                                                            href="/administration/detail-commande/{{ c._id }}"
                                                        >
                                                            Consulter
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <button (click)="refuserDefinitivement(c._id)" class="btn btn-danger">
                                                            Refuser
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <a
                                                            target="_blank"
                                                            href="{{ url }}/documents/facture/{{ c.numeroCommande }}.pdf?document={{
                                                                c.numeroCommande
                                                            }}&token={{ token }}"
                                                            download="facture"
                                                            ><fa-icon [icon]="faPdf"></fa-icon
                                                        ></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--div class="col-md-12">
              <div class="container-pagination">
                <a class="paginate_button previous disabled">Précédent</a>
                <span>
                  <a class="paginate_button current">1</a>
                  <a class="paginate_button">2</a>
                  <a class="paginate_button">3</a>
                  <a class="paginate_button">4</a>
                  <a class="paginate_button">5</a>
                  <a class="paginate_button">6</a>
                  <a class="paginate_button">7</a>
                  <a class="paginate_button">8</a>
                  <a class="paginate_button">9</a>
                </span>
                <a class="paginate_button next">Suivant</a>
              </div>
            </div-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
