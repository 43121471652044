import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {
    faBars,
    faBook,
    faFilePdf,
    faHome,
    faSignOutAlt,
    faTimes,
    faUser,
    faReply,
    faInfoCircle,
    faQuestionCircle,
    faUnlock,
    faGift,
} from '@fortawesome/free-solid-svg-icons';

import { faCheckSquare, faIdBadge, faTrash, faInbox } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
@Component({
    selector: 'app-recruteur-header',
    templateUrl: './recruteur-header.component.html',
    styleUrls: [
        './recruteur-header.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/site/header.css',
    ],
    animations: [
        trigger('inOutAnimation', [transition(':enter', [style({ opacity: 0 }), animate('.25s ease-out', style({ opacity: 1 }))])]),
    ],
})
export class RecruteurHeaderComponent implements OnInit {
    faHome = faHome;
    faUser = faIdBadge;
    faPdf = faFilePdf;
    faGift = faGift;
    faTime = faTimes;
    faCvTech = faBook;
    faSignOut = faSignOutAlt;
    faReply = faReply;
    faTrash = faTrash;
    faInfoCircle = faInfoCircle;
    faQuestionCircle = faQuestionCircle;
    faUnlock = faUnlock;
    faCheck = faCheckSquare;
    faInboxIn = faInbox;

    url = '';
    cookie = false;
    gif = true;

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService,
        private router: Router
    ) {}

    ngOnInit(): void {
        const url = window.location.href;
        if (url.split('home').length > 1) {
            this.url = 'home';
            this.tracking('Home');
        } else if (url.split('dashboard').length > 1) {
            this.url = 'dashboard';
            this.tracking('Dashboard');
        } else if (
            url.split('informations-personnelles').length > 1 ||
            url.split('mot-de-passe').length > 1 ||
            url.split('informations').length > 1 ||
            url.split('informations-complementaires').length > 1 ||
            url.split('recherche').length > 1 ||
            url.split('autorisations').length > 1 ||
            url.split('mon-organisme').length > 1
        ) {
            this.url = 'informations-personnelles';
            this.tracking('Informations personnelles');
        } else if (url.split('candidatures-recues').length > 1) {
            this.url = 'candidatures-recues';
            this.tracking('Candidatures recues');
        } else if (url.split('desabonnements').length > 1) {
            this.url = 'desabonnements';
            this.tracking('Désabonnements');
        } else if (url.split('cvtheque').length > 1) {
            this.url = 'cvtheque';
            this.tracking('CVTheque');
        } else if (url.split('avantages').length > 1) {
            this.url = 'avantages';
            this.tracking('Mes avantages');
        }
    }
    tracking(requete: string): void {
        this.httpClient
            .put<any>(
                this.globalService.url + '/tracking',
                {
                    identifiantUtilisateur: this.authService.id,
                    typeCompte: 'Recruteur',
                    url: requete,
                },
                {}
            )
            .subscribe(
                (response) => {},
                () => {}
            );
    }
    deconnexion(): void {
        this.authService.logout();
    }
}
