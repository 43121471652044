import { Component, OnInit } from '@angular/core';
import {
    faHome,
    faUser,
    faUserTie,
    faUserCog,
    faPercent,
    faBarcode,
    faPortrait,
    faGift,
    faBriefcase,
    faMailBulk,
    faQuestion,
    faChartPie,
    faTextWidth,
    faAt,
    faFlag,
    faAngleLeft,
    faSignOutAlt,
    faFolder,
} from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
@Component({
    selector: 'app-admin-header',
    templateUrl: './admin-header.component.html',
    styleUrls: [
        './admin-header.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class AdminHeaderComponent implements OnInit {
    faHome = faHome;
    faUser = faUser;
    faUserTie = faUserTie;
    faUserCog = faUserCog;
    faBriefcase = faBriefcase;
    faChartPie = faChartPie;
    faPercent = faPercent;
    faBarcode = faBarcode;
    faPortrait = faPortrait;
    faFlag = faFlag;
    faGift = faGift;
    faMailBuck = faMailBulk;
    faQuestion = faQuestion;
    faAt = faAt;
    faTextWidth = faTextWidth;
    faSignOut = faSignOutAlt;
    faAngleLeft = faAngleLeft;
    faFolder = faFolder;
    openCandidats = false;
    openRecruteurs = false;
    openDiffusions = false;
    openCourriels = false;
    openMetiers = false;
    openSecteurs = false;
    nombreCandidats = {
        fini: 0,
        nonFini: 0,
        bloque: 0,
        supprime: 0,
        desinscrits: 0,
    };
    nombreDemandesMetiers = 0;
    nombreDemandesSecteurs = 0;
    nombreRecruteurs = {
        fini: 0,
        nonFini: 0,
        bloque: 0,
        supprime: 0,
        desinscrits: 0,
    };
    nombreDiffusions = {
        total: 0,
        enAttente: -1,
        enCours: 0,
        annulerTemporairement: 0,
        annulerDefinitivement: 0,
        fini: 0,
    };
    nombreAdministrateurs = 0;
    nombrePhotos = {
        candidat: 0,
        recruteurPhoto: 0,
        recruteurLogo: 0,
    };
    url = '';
    mails = [
        {
            _id: '',
            nom: '',
        },
    ];
    sonEnAttente = 0;
    type: string | null = '';
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService,
        private router: Router
    ) {}

    ngOnInit(): void {
        const id = localStorage.getItem('id') !== undefined && localStorage.getItem('id') !== null;
        const type = localStorage.getItem('type') !== undefined && localStorage.getItem('type') !== null;
        const token = localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null;
        // @ts-ignore
        if (!id || !type || !token || localStorage.getItem('type') !== 'Administrateur') {
            let intermediaire = false;
            if (localStorage.getItem('intermediaire') !== undefined && localStorage.getItem('intermediaire') !== null) {
                intermediaire = true;
            }
            localStorage.clear();
            if (intermediaire) {
                localStorage.setItem('intermediaire', 'OK');
            }
            this.router.navigate(['/eMB1lsIVVnvKbJditHNo5kL4jWc46uy7fpJoQ5fj']);
        }
        this.type = localStorage.getItem('typeAdministrateur');
        const url = window.location.href;
        if (url.split('dashboard').length > 1) {
            this.url = 'dashboard';
        } else if (url.split('candidats').length > 1) {
            this.url = 'candidats';
        } else if (url.split('recruteurs').length > 1) {
            this.url = 'recruteurs';
        } else if (url.split('administrateurs').length > 1) {
            this.url = 'administrateurs';
        } else if (url.split('newsletters').length > 1) {
            this.url = 'newsletters';
        }
        this.nombreCandidatBloquer();
        this.nombreCandidatFinaliser();
        this.nombreCandidatNonFinaliser();
        this.nombreCandidatSupprimes();
        this.nombreCandidatDesinscrits();
        this.nombreDemandesMetiersFunc();
        this.nombreDemandesSecteursFunc();

        this.nombreRecruteurBloquer();
        this.nombreRecruteurFinaliser();
        this.nombreRecruteurNonFinaliser();
        this.nombreRecruteurSupprimes();
        this.nombreRecruteurDesinscrits();
        this.nombreAdmin();
        this.nombrePhotos1();
        this.nombrePhotos2();
        this.nombreCommande();
        this.getMails();
        setInterval(() => {
            this.nombreCommande();
            this.nombreCandidatBloquer();
            this.nombreCandidatFinaliser();
            this.nombreCandidatNonFinaliser();
            this.nombreCandidatSupprimes();
            this.nombreCandidatDesinscrits();
            this.nombreRecruteurBloquer();
            this.nombreRecruteurFinaliser();
            this.nombreRecruteurNonFinaliser();
            this.nombreRecruteurSupprimes();
            this.nombreRecruteurDesinscrits();
            this.nombreAdmin();
            this.nombrePhotos1();
            this.nombrePhotos2();
            this.getMails();
        }, 60000);
    }
    deconnexion(): void {
        this.authService.logout();
    }
    candidats(): void {
        this.openCandidats = !this.openCandidats;
    }
    recruteurs(): void {
        this.openRecruteurs = !this.openRecruteurs;
    }
    diffusions(): void {
        this.openDiffusions = !this.openDiffusions;
    }
    courriels(): void {
        this.openCourriels = !this.openCourriels;
    }
    metiers(): void {
        this.openMetiers = !this.openMetiers;
    }
    secteurs(): void {
        this.openSecteurs = !this.openSecteurs;
    }
    nombreCandidatNonFinaliser(): void {
        this.httpClient
            .post(this.globalService.url + '/candidat/nombreCompte', { ficheComplete: false, status: true, deleted: false }, {})
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.nombreCandidats.nonFini = response.nombre;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    nombreDemandesMetiersFunc(): void {
        this.httpClient.post(this.globalService.url + '/requests/count', { type: 'metier' }, {}).subscribe(
            (response: any) => {
                if (response.status) {
                    this.nombreDemandesMetiers = response.nombre;
                } else {
                }
            },
            () => {}
        );
    }
    nombreDemandesSecteursFunc(): void {
        this.httpClient.post(this.globalService.url + '/requests/count', { type: 'secteur' }, {}).subscribe(
            (response: any) => {
                if (response.status) {
                    this.nombreDemandesSecteurs = response.nombre;
                } else {
                }
            },
            () => {}
        );
    }
    nombreCandidatFinaliser(): void {
        this.httpClient
            .post(this.globalService.url + '/candidat/nombreCompte', { ficheComplete: true, status: true, deleted: false }, {})
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.nombreCandidats.fini = response.nombre;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    nombreCandidatBloquer(): void {
        this.httpClient
            .post(this.globalService.url + '/candidat/nombreCompte', { ficheComplete: true, status: false, deleted: false }, {})
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.nombreCandidats.bloque = response.nombre;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    nombreCandidatSupprimes(): void {
        this.httpClient
            .post(this.globalService.url + '/candidat/nombreCompte', { ficheComplete: true, status: false, deleted: true }, {})
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.nombreCandidats.supprime = response.nombre;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    nombreCandidatDesinscrits(): void {
        this.httpClient
            .post(
                this.globalService.url + '/candidat/nombreCompte',
                { ficheComplete: true, status: false, deleted: false, desinscription: true },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.nombreCandidats.desinscrits = response.nombre;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    nombreRecruteurDesinscrits(): void {
        this.httpClient
            .post(
                this.globalService.url + '/recruteur/nombreCompte',
                { ficheComplete: true, status: false, deleted: false, desinscription: true },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.nombreRecruteurs.desinscrits = response.nombre;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    nombreRecruteurNonFinaliser(): void {
        this.httpClient
            .post(this.globalService.url + '/recruteur/nombreCompte', { ficheComplete: false, status: true, deleted: false }, {})
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.nombreRecruteurs.nonFini = response.nombre;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    nombreRecruteurFinaliser(): void {
        this.httpClient
            .post(this.globalService.url + '/recruteur/nombreCompte', { ficheComplete: true, status: true, deleted: false }, {})
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.nombreRecruteurs.fini = response.nombre;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    nombreRecruteurBloquer(): void {
        this.httpClient
            .post(this.globalService.url + '/recruteur/nombreCompte', { ficheComplete: true, status: false, deleted: false }, {})
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.nombreRecruteurs.bloque = response.nombre;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    nombreRecruteurSupprimes(): void {
        this.httpClient
            .post(this.globalService.url + '/recruteur/nombreCompte', { ficheComplete: true, status: false, deleted: true }, {})
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.nombreRecruteurs.supprime = response.nombre;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    nombreCommande(): void {
        this.httpClient.get(this.globalService.url + '/commande/nombre').subscribe(
            (response: any) => {
                if (response.status) {
                    if (response.total !== this.nombreDiffusions.total) {
                        this.nombreDiffusions.total = response.total;
                    }
                    if (response.enAttente !== this.nombreDiffusions.enAttente) {
                        this.nombreDiffusions.enAttente = response.enAttente;
                        if (
                            this.sonEnAttente != response.enAttente &&
                            this.nombreDiffusions.enAttente != -1 &&
                            response.enAttente >= this.nombreDiffusions.enAttente
                        ) {
                            // const audio = new Audio('./assets/sons/pieces.wav');
                            // audio.play();
                        }
                        this.sonEnAttente = response.enAttente;
                    }
                    if (response.temporaire !== this.nombreDiffusions.annulerTemporairement) {
                        this.nombreDiffusions.annulerTemporairement = response.temporaire;
                    }
                    if (response.temporaire !== this.nombreDiffusions.annulerDefinitivement) {
                        this.nombreDiffusions.annulerDefinitivement = response.temporaire;
                    }
                    if (response.definitif !== this.nombreDiffusions.annulerDefinitivement) {
                        this.nombreDiffusions.annulerDefinitivement = response.definitif;
                    }
                    if (response.enCours !== this.nombreDiffusions.enCours) {
                        this.nombreDiffusions.enCours = response.enCours;
                    }
                    if (response.terminer !== this.nombreDiffusions.fini) {
                        this.nombreDiffusions.fini = response.terminer;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    nombreAdmin(): void {
        this.httpClient.post(this.globalService.url + '/admin/nombreCompte', null).subscribe(
            (response: any) => {
                if (response.status) {
                    if (response.nombre !== this.nombreAdministrateurs) {
                        this.nombreAdministrateurs = response.nombre;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    nombrePhotos1(): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/getForVerification').subscribe(
            (response) => {
                if (response.status) {
                    if (response.candidats.length !== this.nombrePhotos.candidat) {
                        this.nombrePhotos.candidat = response.candidats.length;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    nombrePhotos2(): void {
        this.httpClient.get<any>(this.globalService.url + '/recruteur/getForVerification/photo', {}).subscribe(
            (response) => {
                if (response.status) {
                    if (this.nombrePhotos.recruteurPhoto !== response.recruteurs.length) {
                        this.nombrePhotos.recruteurPhoto = response.recruteurs.length;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
        this.httpClient.get<any>(this.globalService.url + '/recruteur/getForVerification/logo', {}).subscribe(
            (response) => {
                if (response.status) {
                    if (this.nombrePhotos.recruteurLogo !== response.recruteurs.length) {
                        this.nombrePhotos.recruteurLogo = response.recruteurs.length;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    getMails(): void {
        this.httpClient.get<any>(this.globalService.url + '/mail').subscribe(
            (response) => {
                if (response.status) {
                    this.mails = response.mails;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
