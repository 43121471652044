<div class="offcanvas offcanvas-end fixed bottom-0 flex flex-col max-w-full bg-white bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-96" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" [ngClass]="state ? 'show' : ''">
  <div class="offcanvas-header flex items-center justify-between p-4">
    <h5 class="offcanvas-title mb-0 leading-normal font-semibold" id="offcanvasExampleLabel">{{title}}</h5>
    <button (click)='closeDrawer()' type="button" class="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body flex-grow p-4 overflow-y-auto">
    <ng-content></ng-content>
  </div>
</div>
<div *ngIf='state' class="offcanvas-backdrop fade show"></div>
