import { Component, Inject, Input, OnInit, Renderer2, HostListener, ViewChild } from '@angular/core';

import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { ToastService } from '../../mdb/pro/alerts';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth.service';
import { ValidateService } from '../../../services/validate.service';
import { TextService } from '../../../services/text.service';

@Component({
    selector: 'app-site-header',
    templateUrl: './site-header.component.html',
    styleUrls: ['./site-header.component.css', './../../../../assets/css/site/header.css'],
    animations: [
        trigger('inOutAnimation', [transition(':enter', [style({ opacity: 0 }), animate('.25s ease-out', style({ opacity: 1 }))])]),
    ],
})
export class SiteHeaderComponent implements OnInit {
    @Input() page: string | undefined;
    @Input() url: string | undefined;
    @Input() hideCandidat = false;
    @Input() hideRecruteur = false;
    icon = faBars;
    open = false;
    cookie = false;
    logged = false;
    type = '';
    photo = '';
    gif = true;
    tabIndex = 1;
    contact = {
        nom: '',
        email: '',
        telephone: '',
        sujet: '',
        message: '',
    };
    contactError = {
        nom: false,
        email: false,
        telephone: false,
        sujet: false,
        message: false,
    };
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService,
        private textService: TextService,
        private validateService: ValidateService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private toastrService: ToastService
    ) {}
    ngOnInit(): void {
        const intermediaire = localStorage.getItem('intermediaire') !== undefined && localStorage.getItem('intermediaire') !== null;
        this.type = this.authService.role;
        this.logged = this.authService.loggedIn();
        if (this.logged) {
            this.photo = this.authService.getPhoto();
            const url = window.location.href;
            if (url.split('candidat').length > 1) {
                this.page = 'acces-candidats';
                this.tracking('Accès candidats');
            } else if (url.split('recruteur').length > 1) {
                this.url = 'acces-recruteurs';
                this.tracking('Accès recruteurs');
            } else if (url.split('conseils').length > 1) {
                this.url = 'conseils';
                this.tracking('Conseils');
            } else if (url.split('tarifs').length > 1) {
                this.url = 'tarifs';
                this.tracking('Tarifs');
            } else if (url.split('recruteur-proximite').length > 1) {
                this.url = 'recruteur-proximite';
                this.tracking('Recruteurs à proximité');
            } else if (url.split('contact').length > 1) {
                this.url = 'contact';
                this.tracking('Nous contacter');
            }
        }
        // @ts-ignore
        this.cookie = localStorage.hasOwnProperty('cookie');

        if (!intermediaire && environment.type == 'dev') {
            localStorage.clear();
            this.router.navigate(['/connexion-intermediaire']);
        }
        setTimeout(() => {
            this.gif = false;
        }, 1500);
    }
    tracking(requete: string): void {
        if (localStorage.getItem('type') === 'Candidat' || localStorage.getItem('type') === 'Recruteur') {
            this.httpClient
                .put<any>(
                    this.globalService.url + '/tracking',
                    {
                        identifiantUtilisateur: this.authService.id,
                        typeCompte: this.textService.firstUpperCase(this.authService.role),
                        url: requete,
                    },
                    {}
                )
                .subscribe(
                    (response) => {},
                    () => {}
                );
        }
    }
    redirectDashboard(): void {
        this.router.navigate([`espace-${this.authService.role}/dashboard`]);
    }
    cookie_compris(): void {
        localStorage.setItem('cookie', 'OK');
        this.cookie = true;
    }
    menu(): void {
        this.open = !this.open;
        if (this.open) {
            this.icon = faTimes;
            let navbar = document.querySelector('.navbar');
            if (navbar) {
                navbar.classList.add('nav-active');
            }

            this.renderer.addClass(this.document.body, 'overflow-hidden');
        } else {
            this.renderer.removeClass(this.document.body, 'overflow-hidden');
            this.icon = faBars;
        }
    }
    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        let navbar = document.querySelector('.navbar');
        let menuMobile = document.querySelector('.menu_mobile');
        if (navbar) {
            navbar.classList.toggle('nav-active', window.scrollY > 0);
        }
    }
    deconnexion(): void {
        this.authService.logout();
    }
    renouveller(): void {
        this.contactError = {
            nom: false,
            email: false,
            telephone: false,
            sujet: false,
            message: false,
        };
    }
    isOk(): boolean {
        return !this.contactError.nom && !this.contactError.email && !this.contactError.sujet && !this.contactError.message;
    }
    envoyer(): void {
        this.renouveller();
        if (this.contact.nom === '') {
            this.contactError.nom = true;
        }
        if (this.contact.email === '' || !this.validateService.validateEmail(this.contact.email)) {
            this.contactError.email = true;
        }
        if (this.contact.sujet === '') {
            this.contactError.sujet = true;
        }
        if (this.contact.message === '') {
            this.contactError.message = true;
        }
        if (this.isOk()) {
            this.httpClient
                .put<any>(
                    this.globalService.url + '/mail/contact',
                    {
                        prenom: this.contact.nom,
                        email: this.contact.email,
                        telephone: this.contact.telephone,
                        objet: this.contact.sujet,
                        message: this.contact.message,
                    },
                    {}
                )
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this.toastrService.info(
                                'Votre message a bien été transmis. Nous vous répondrons dans les plus brefs délais',
                                '',
                                { opacity: 1 }
                            );
                            this.contact = {
                                nom: '',
                                email: '',
                                telephone: '',
                                sujet: '',
                                message: '',
                            };
                        }
                    },
                    () => {}
                );
        }
    }
}
