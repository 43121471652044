<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-candidat/mes-commandes" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pl-0 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-candidat/mes-commandes" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span style="margin-right: 5rem">Modifier ma commande</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Modifier ma commande</h2>
            </div>
            <div>
                <div class="form-group">
                    <span class="font-weight-bold">Nom de l'expéditeur : </span><span style="color: red">{{ nomCandidat }}</span>
                </div>
                <div class="form-group col-12 col-md-6 p-0">
                    <label for="mail-title" class="font-weight-bold">Objet du mail</label>
                    <input
                        type="text"
                        name="mail-title"
                        class="form-control"
                        [(ngModel)]="sujet"
                        placeholder="Exemple : candidature spontanée"
                    />
                </div>
                <div class="form-group col-12 col-md-6 p-0">
                    <label for="candidat-mail" class="font-weight-bold">Votre adresse mail</label>
                    <input name="candidat-mail" type="text" class="form-control" [(ngModel)]="emailDiffusion" />
                </div>
                <div class="form-group pt-3 pb-3">
                    <div class="d-flex flex-wrap align-items-center">
                        <div class="col-4 col-md-2 pl-0">
                            <span class="font-weight-bold">Mon CV (.pdf)</span>
                        </div>
                        <div class="col-8 col-md-3">
                            <div *ngIf="ancienCv !== ''">
                                <a target="_blank" href="{{ url }}/documents/cv/{{ ancienCv }}"
                                    ><small>{{ ancienCv }}</small> <fa-icon class="ml-2" [icon]="faFilePdf"></fa-icon
                                ></a>
                            </div>
                        </div>
                        <div class="col-12 col-md-2 mt-3 mt-md-0">
                            <div class="file-field ml-3">
                                <div mdbBtn [rounded]="true" [outline]="false" color="secondary">
                                    <span class="text-center">{{ ancienCv !== '' ? 'Modifier' : 'Ajouter' }}</span>
                                    <input type="file" mdbFileSelect (change)="uploadCv($event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <app-lettre-motivation-editor [(lettreMotivation)]='lettreMotivation'></app-lettre-motivation-editor>

              <div style="color: red; text-align: center" *ngIf="error">
                    {{ erreurTexte }}
                </div>
                <div class="form-group d-flex justify-content-center">
                    <p *ngIf="cvUpload == '2'">
                        <img src="./assets/img/gif.gif" style="max-width: 25px" />
                        CV en cours de sauvegarde, veuillez patienter
                    </p>
                </div>
              <div class='d-flex float-right mb-3'>
                <button (click)="suivant()" mdbBtn [rounded]="true" color="warning" size="lg" class="mt-4">Enregistrer</button>
              </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
