import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-linkedin',
    templateUrl: './linkedin.component.html',
    styleUrls: ['./linkedin.component.css'],
})
export class LinkedinComponent implements OnInit {
    token = '';
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.get();
    }

    update(): void {
        this.httpClient.put<any>(this.globalService.url + '/linkedin', { token: this.token }, {}).subscribe(
            (response) => {
                if (response.status) {
                    alert('Modifié');
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    get(): void {
        this.httpClient.get<any>(this.globalService.url + '/linkedin').subscribe(
            (response) => {
                if (response.status) {
                    if (response.token.length > 0) {
                        this.token = response.token[0].token;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
