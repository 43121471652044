import { Pipe, PipeTransform } from '@angular/core';
import { GlobalVariable } from '../global';

@Pipe({
    name: 'activities',
})
export class ActivitiesPipe implements PipeTransform {
    activitiesType = GlobalVariable.ACTIVITES;

    transform(value: any, ...args: any[]): any {
        if (!value.includes(' ')) {
            const obj = this.activitiesType.find((a: { value: any }) => a.value === value);
            return obj ? obj.label : '';
        } else {
            return '';
        }
    }
}
