import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
    name: 'timestamp',
})
export class TimestampPipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        // @ts-ignore
        return dayjs.unix(value).format('DD-MM-YYYY HH:mm');
    }
}
