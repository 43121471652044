import { Component, OnInit } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-liste-diffusion-refuser-definitivement',
    templateUrl: './liste-diffusion-refuser-definitivement.component.html',
    styleUrls: ['./liste-diffusion-refuser-definitivement.component.css'],
})
export class ListeDiffusionRefuserDefinitivementComponent implements OnInit {
    faPdf = faFilePdf;
    commandes: any = [];
    url = '';
    token: string | null = '';
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.getCommande();
        this.url = this.globalService.url;
        this.token = localStorage.getItem('token');
    }
    getCommande(): void {
        this.httpClient
            .post<any>(
                this.globalService.url + '/commande/get-all',
                {
                    attente: false,
                    enCours: false,
                    terminer: false,
                    temporaire: false,
                    definitif: true,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.commandes = response.commandes;
                        this.getCandidats();
                    }
                },
                (err) => {}
            );
    }
    getCandidats(): void {
        for (let c of this.commandes) {
            this.httpClient.get<any>(this.globalService.url + '/candidat/' + c.idCandidat, {}).subscribe(
                (response) => {
                    if (response.status) {
                        c.candidat = response.candidat;
                    }
                },
                (err) => {}
            );
        }
    }
    retablir(id: string): void {
        this.httpClient.patch<any>(this.globalService.url + '/commande/retablir/', { id: id }, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.getCommande();
                }
            },
            (err) => {}
        );
    }
}
