<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-recruteur-header></app-recruteur-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-recruteur/informations-personnelles" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-recruteur/informations-personnelles" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span style="margin-right: 5rem">Mon organisme</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Mon organisme</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Mettez à jour les informations relatives à votre organisme</h2>
            </div>

            <div class="pt-2 d-flex justify-content-center">
                <img
                    style="object-fit: cover; width: 100px; height: 100px"
                    class="img-preload rounded-circle img-fluid"
                    *ngIf="this.recruteur.logoGroupe !== ''"
                    [src]="this.logoRecruteur"
                />
                <label for="logoGroupe">Logo<br /><small>(Fichier accepté: .png, .jpg - Taille maximum: 5Mo)</small></label>
                <span
                    for="logoGroupe"
                    class="error"
                    [ngStyle]="{
                        display: this.error.logoGroupe ? 'block' : 'none'
                    }"
                    >Obligatoire</span
                >
            </div>
            <div class="d-flex justify-content-center">
                <div class="file-field md-form">
                    <div mdbBtn [rounded]="true" [outline]="true" color="secondary" size="md" mdbWavesEffect>
                        <span class="pt-1">Choisir un fichier</span>
                        <input type="file" name="logoGroupe" mdbFileSelect (change)="logo($event)" />
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap inscription-row align-items-center">
                <div class="col-12 col-md-6">
                    <label>Pays d'immatriculation de mon organisme *</label>
                </div>
                <div class="col-12 col-md-4">
                    <mdb-select-2 [notFoundMsg]="'Pays non trouvé'" name="paysCode" [(ngModel)]="recruteur.paysCode" [outline]="true">
                        <mdb-select-filter [ngModel]="searchPays | async" (inputChange)="searchPays.next($event)"> </mdb-select-filter>
                        <mdb-select-option *ngFor="let option of filteredOptions | async" [value]="option.code">{{
                            option.pays
                        }}</mdb-select-option>
                    </mdb-select-2>
                </div>
            </div>

            <div class="d-flex flex-wrap inscription-row align-items-center">
                <div class="col-12 col-md-6">
                  <b>Numéro d’identification (Siret) ou numéro de votre entreprise étrangère</b>
                  : numéro présent sur vos factures, dans les mentions légales du site Internet de votre organisme, sur
                  societe.com, dans vos fiches de paie *

                    <label
                        for="siret"
                        [ngClass]="{
                            'fl-label-empty': this.recruteur.siret === '',
                            'fl-label-not-empty': this.recruteur.siret !== ''
                        }"
                        >Ex : 522964261000XX *</label
                    >
                    <span
                        for="siret"
                        class="error"
                        [ngStyle]="{
                            display: this.error.siret ? 'block' : 'none'
                        }"
                        >Obligatoire</span
                    >
                </div>
                <div class="col-12 col-md-4">
                    <input type="text" name="siret" id="siret" class="form-control" [(ngModel)]="this.recruteur.siret" />
                </div>
            </div>

            <div class="d-flex flex-wrap inscription-row align-items-center">
                <div class="col-12 col-md-6">
                    <label
                        for="nomSociete"
                        [ngClass]="{
                            'fl-label-empty': this.recruteur.nomSociete === '',
                            'fl-label-not-empty': this.recruteur.nomSociete !== ''
                        }"
                        >Nom de l'organisme *</label
                    >
                    <span
                        for="nomSociete"
                        class="error"
                        [ngStyle]="{
                            display: this.error.nomSociete ? 'block' : 'none'
                        }"
                        >Obligatoire</span
                    >
                </div>
                <div class="col-12 col-md-4">
                    <input type="text" name="nomSociete" id="nomSociete" class="form-control" [(ngModel)]="this.recruteur.nomSociete" />
                </div>
            </div>

            <div class="d-flex flex-wrap inscription-row align-items-center">
                <div class="col-12 col-md-6">
                    <label
                        for="nomGroupe"
                        [ngClass]="{
                            'fl-label-empty': this.recruteur.nomGroupe === '',
                            'fl-label-not-empty': this.recruteur.nomGroupe !== ''
                        }"
                        >Nom du groupe</label
                    >
                </div>
                <div class="col-12 col-md-4">
                    <input type="text" name="nomGroupe" id="nomGroupe" class="form-control" [(ngModel)]="this.recruteur.nomGroupe" />
                </div>
            </div>

            <div class="inscription-row d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-2">
                    <div class="form-group">
                        <label
                            for="adresse"
                            [ngClass]="{
                                'fl-label-empty': this.recruteur.adresse === '',
                                'fl-label-not-empty': this.recruteur.adresse !== ''
                            }"
                            >Adresse *</label
                        >
                        <span
                            for="adresse"
                            class="error"
                            [ngStyle]="{
                                display: this.error.adresse ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <app-select-adress [user]="recruteur"></app-select-adress>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-2">
                    <div class="form-group">
                        <label
                            for="complementAdresse"
                            [ngClass]="{
                                'fl-label-empty': this.recruteur.complementAdresse === '',
                                'fl-label-not-empty': this.recruteur.complementAdresse !== ''
                            }"
                            >Complément d'adresse</label
                        >
                        <input
                            type="text"
                            name="complementAdresse"
                            id="complementAdresse"
                            class="form-control"
                            [(ngModel)]="this.recruteur.complementAdresse"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-3">
                    <div class="form-group">
                        <label
                            for="codePostal"
                            [ngClass]="{
                                'fl-label-empty': this.recruteur.codePostal === '',
                                'fl-label-not-empty': this.recruteur.codePostal !== ''
                            }"
                            >Code postal *</label
                        >
                        <span
                            for="codePostal"
                            class="error"
                            [ngStyle]="{
                                display: this.error.codePostal ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <input type="text" name="codePostal" id="codePostal" class="form-control" [(ngModel)]="this.recruteur.codePostal" />
                    </div>
                </div>
                <div class="col-6 mt-3">
                    <div class="form-group">
                        <label
                            for="ville"
                            [ngClass]="{
                                'fl-label-empty': this.recruteur.ville === '',
                                'fl-label-not-empty': this.recruteur.ville !== ''
                            }"
                            >Ville *</label
                        >
                        <span
                            for="ville"
                            class="error"
                            [ngStyle]="{
                                display: this.error.ville ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <input type="text" name="ville" id="ville" class="form-control" [(ngModel)]="this.recruteur.ville" />
                    </div>
                </div>
<!--                <div class="col-6 mt-3">-->
<!--                    <div class="form-group">-->
<!--                        <div class="form-group d-inline">-->
<!--                            {{ this.recruteur.pays }}-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>

            <div class="d-flex flex-wrap inscription-row align-items-center">
                <div class="col-12 col-md-6">
                    <label
                        for="numeroTva"
                        [ngClass]="{
                            'fl-label-empty': this.recruteur.numeroTva === '',
                            'fl-label-not-empty': this.recruteur.numeroTva !== ''
                        }"
                        >Numéro de TVA</label
                    >
                </div>
                <div class="col-12 col-md-4">
                    <input type="text" name="numeroTva" id="numeroTva" class="form-control" [(ngModel)]="this.recruteur.numeroTva" />
                </div>
            </div>
            <div class="d-flex inscription-row mb-2 flex-wrap">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label
                            for="codeNaf"
                            [ngClass]="{
                                'fl-label-empty': this.recruteur.activite.codeNaf === '',
                                'fl-label-not-empty': this.recruteur.activite.codeNaf !== ''
                            }"
                            >Code NAF</label
                        >
                        <span
                            for="codeNaf"
                            class="error"
                            [ngStyle]="{
                                display: this.error.codeNaf ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <input type="text" name="codeNaf" id="codeNaf" class="form-control" [(ngModel)]="this.recruteur.activite.codeNaf" />
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label
                            for="activiteNom"
                            [ngClass]="{
                                'fl-label-empty': this.recruteur.activite.nom === '',
                                'fl-label-not-empty': this.recruteur.activite.nom !== ''
                            }"
                            >Activité correspondante</label
                        >
                        <span
                            for="activiteNom"
                            class="error"
                            [ngStyle]="{
                                display: this.error.activiteNom ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <input
                            type="text"
                            name="activiteNom"
                            id="activiteNom"
                            class="form-control"
                            [(ngModel)]="this.recruteur.activite.nom"
                        />
                    </div>
                </div>

            </div>

            <div class="d-flex justify-content-center" style="position: relative">
                <p class="mention-obligatoire" style="color: red !important">* Obligatoire</p>
                <p *ngIf="logoUpload == '2'">
                    <img src="./assets/img/gif.gif" style="max-width: 25px" />
                    Logo en cours de sauvegarde, veuillez patienter
                </p>
            </div>
            <div class="d-flex float-right mb-3">
                <button mdbBtn class="text-center" [rounded]="true" color="warning" size="lg" (click)="sendEntreprise()">Valider</button>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
