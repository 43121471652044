import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-liste-diffusion-candidat',
    templateUrl: './liste-diffusion-candidat.component.html',
    styleUrls: ['./liste-diffusion-candidat.component.css'],
})
export class ListeDiffusionCandidatComponent implements OnInit {
    candidat: any = {};
    commandes: any = [];
    id = '';
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}
    ngOnInit(): void {
        if (this.route.snapshot.params.id !== undefined && this.route.snapshot.params.id !== null) {
            this.id = this.route.snapshot.params.id;
            this.getCommande();
            this.getCandidat();
        }
    }
    getCandidat(): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/' + this.id).subscribe(
            (response) => {
                if (response.status) {
                    this.candidat = response.candidat;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    getCommande(): void {
        this.httpClient.post<any>(this.globalService.url + '/commande/historique-commandes', { id: this.id }, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.commandes = response.commandes;
                }
            },
            (err) => {}
        );
    }
}
