<app-site-header page="accueil" url="http://localhost:4200/"></app-site-header>
<div mdbModal #ululModal="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-lg modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <h3 class="heading text-primary d-flex ml-auto mr-auto">Campagne de Crowdfunding en cours
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="ululModal.hide()">
          <span aria-hidden="true" class="text-black">&times;</span>
        </button>
      </div>
      <!--Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-3">
            <p></p>
            <p class="text-center">
              <img src='https://d2homsd77vx6d2.cloudfront.net/files/161/894/573/5/399a560ce76eb37b45aec7dbea3492bc.png'/>
            </p>
          </div>
          <div class="col-9">
              <p>Après 2 années de travail intense, nous avons besoin de votre aide pour finaliser la première version de notre site. A cet effet, nous lançons une campagne de Crowdfunding sur Ulule et offrons des réductions allant jusqu'à 70%.</p>
              <p>Nous vous remercions par avance pour votre contribution à la réussite de « la plus belle avenue pour trouver votre emploi » 😀</p>
              <p>Pour participer à notre campagne Ulule, cliquez sur le bouton ci-dessous 👇</p>
          </div>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a href='https://fr.ulule.com/avenue-des-recruteurs/' target="_blank" type="button" mdbBtn color="primary" size='lg' class="waves-effect" mdbWavesEffect>Participer</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!-- banner -->
<section class="banner pt-lg-5 pt-md-5">
    <div class="container title-homepage">
        <div class="row">
            <div class="col-md-7 p-3 m-auto">
                <div class="text-end">
                    <h4 class="text-brown fw-bold fs-45">BIENVENUE SUR</h4>
                    <h1 class="text-primary fw-bold">
                        Avenue Des <br />
                        Recruteurs
                    </h1>
                    <a mdbPageScroll class="nav-link fs-20 btn btn-warning rounded-pill d-inline-block px-4 ms-4" href="#login-cards">
                        En savoir plus
                    </a>
                </div>
            </div>
            <div class="col-md-5 p-3 m-auto">
                <img src="./assets/img/gallery.png" class="img-fluid" alt="" />
            </div>
        </div>
    </div>
</section>
<!-- banner -->

<!-- cards -->
<section class="cards py-0 py-md-5 bg-light " id="login-cards">
    <div class="container">
        <div class="row">
            <div class="col-md-6 p-0 p-md-3">
                <div class="card border-0 bg-transparent position-relative">
                    <img src="./assets/img/Group 1174.png" class="img-fluid card-img-top" alt="" />
                    <div class="card-body mt-auto p-3">
                        <div class="col mt-auto">
                            <div class="text-end">
                                <h4 class="text-white fs-35 fw-bold">Je suis</h4>
                                <h3 class="fw-bold text-white fs-65">Candidat</h3>
                                <a class="nav-link fs-20 btn btn-warning rounded-pill px-4" href="/candidat"> Espace candidat </a>
                                <a href="/about-candidat" class="ms-2 mt-4 text-white moblink text-decoration-underline mb-3">
                                    En savoir plus
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white pp">
                    <div class="p-4 text-center">
                        <p class="text-muted mb-0 fs-20">
                            Diffusez rapidement votre CV à de nombreux recruteuses et recruteurs afin d’obtenir un CDI, un CDD, une mission
                            d’intérim, un stage, un contrat d’alternance/de professionnalisation, une mission…
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 p-0 p-md-3">
                <div class="card border-0 bg-transparent position-relative a1180">
                    <img src="./assets/img/Group 1175.png" class="img-fluid card-img-top" alt="" />
                    <div class="card-body mt-auto p-3 flex-start">
                        <div class="col mt-auto">
                            <div class="text-start">
                                <h4 class="text-white fs-35 fw-bold">Je suis</h4>
                                <h3 class="fw-bold text-white fs-65">Recruteur</h3>
                                <a class="nav-link fs-20 btn btn-warning rounded-pill px-4" href="/recruteur"> Espace recruteur </a>
                                <a href="/about-recruteur" class="ms-2 mt-4 text-white moblink text-decoration-underline mb-3">
                                    En savoir plus
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white pp">
                    <div class="p-4 text-center">
                        <p class="text-muted mb-0 fs-20">
                            100 % GRATUIT : accédez à une CVthèque; recevez des candidatures de qualité; participez à des webinaires;
                            améliorez la visibilité de votre organisation… et bénéficiez de nombreux privilèges du Club Butterfly !
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- cards -->

<!-- gallery -->
<section class="gallery py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6 p-3 m-auto">
                <img src="./assets/img/Group 486.png" class="img-fluid" alt="" />
            </div>
            <div class="col-md-6 p-3 m-auto">
                <h4 class="text-primary fs-45 fw-bold">Qui sommes-nous ?</h4>
                <p class="fs-20 mb-0">
                    <b>Avenue Des Recruteurs</b> est un site Internet développé par une entreprise française indépendante et dynamique
                    spécialisée dans le domaine des ressources humaines depuis 2007. <br />
                    <br />
                    Dès 2009, nous avons développé une activité grandissante d’année en année de multidiffusion de CV et de lettres de
                    motivation (diffusion multiple de candidatures spontanées) qui a été confortée par les retours positifs de clients qui
                    arrivaient à retrouver rapidement un emploi.
                    <br />
                    <br />
                    <b>Avenue Des Recruteurs</b> est né de notre volonté de rendre encore plus rapide la recherche des recruteuses et
                    recruteurs, de diminuer le coût pour les candidat(e)s, de générer des rendez-vous et d’offrir plus de services et
                    fonctionnalités aux personnes chargées du recrutement.
                    <br />
                    <br />
                    Pour nous, travailler avec tous les acteurs en charge du recrutement est important car cela permet d’offrir aux
                    candidat(e)s le plus d’opportunités professionnelles possibles et d’améliorer notre plateforme pour répondre au mieux
                    aux besoins des recruteuses et recruteurs.
                </p>
            </div>
        </div>
    </div>
</section>
<!-- gallery -->
<!--<div id="home" class="ppslide no-gutters bubbles full-height main-1" style="box-shadow: inset 0 0 400px 1100px rgb(0 0 0 / 25%);">
  <div class="main-1-2">
    <div class="box-text" style="margin-top: 150px;">
      <div class="sous-titre">{{texte.un}}</div>
      <div class="sous-titre">{{texte.deux}}</div>
      <div class="sous-titre">{{texte.trois}}</div>
    </div>
  </div>
  <button type="button" mdbBtn color="primary" mdbWavesEffect>tjhm</button>

  <div class="row conteneur-acces">
    <div class="offset-1 offset-sm-1 col-md-5 col-10 contours">
      <h2 style="color: #0149b3 !important;">{{texte.quatre}}</h2>
      <div style="color: white !important; text-align: center;">
        {{texte.cinq}}
      </div>
      <a style="width: 88%;" href="/candidat" class="btn btn-candidat">{{texte.six}}</a>
    </div>
    <div class="d-none d-md-block" style="margin: 5px;"></div>
    <div class="d-sm-block d-md-none col-6" style="margin: 5px;"></div>
    <div class="col-md-5 offset-1 offset-md-0 col-10 contours">
      <h2 style="color: #0149b3 !important;">{{texte.sept}}</h2>
      <div style="color: white !important; text-align: center;">
        {{texte.huit}}<a target="_blank" style="color: white; text-decoration: underline;" href="https://membres.club-butterfly.fr/">{{texte.neuf}}</a>
      </div>
      <a style="width: 88%;" href="/recruteur" class="btn btn-recruteur">{{texte.dix}}</a>
    </div>
  </div>
</div>-->
<app-site-footer></app-site-footer>
