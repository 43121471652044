<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <div>
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">Mes factures</h3>
                </div>
                <div class="page-sub-title">
                    <h3 class="breadcrumb-sub">Téléchargez vos factures</h3>
                </div>
                <div id="main-wrapper" class="mb-3">
                    <div class="no-result" *ngIf="factures.length === 0">Aucun résultat</div>
                    <table [ngStyle]="{ display: display() }" id="tableSort" mdbTable #tableEl="mdbTable" class="display custom-table mt-5">
                        <thead>
                            <tr>
                                <th
                                    *ngFor="let head of headElements; let i = index"
                                    aria-controls="tableSortExample"
                                    scope="col"
                                    [mdbTableSort]="factures"
                                    [sortBy]="headElements[i]"
                                >
                                    {{ head }}
                                    <mdb-icon fas icon="sort"></mdb-icon>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of factures; let i = index">
                                <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                    {{ f.numeroCommande }}
                                </td>
                                <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                    {{ f.dateCommande * 1000 | date: 'dd-MM-yyyy HH:mm' }}
                                </td>
                                <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                    <a
                                        class="isDisabled text-center"
                                        target="_blank"
                                        href="{{ url }}/documents/facture/{{ f.numeroCommande }}.pdf?document={{
                                            f.numeroCommande
                                        }}&token={{ token }}"
                                        download="facture"
                                        ><fa-icon [icon]="faPdf"></fa-icon
                                    ></a>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="grey lighten-5 w-100">
                            <tr>
                                <td colspan="3">
                                    <mdb-table-pagination
                                        ofKeyword="de"
                                        [tableEl]="tableEl"
                                        [searchDataSource]="factures"
                                    ></mdb-table-pagination>
                                </td>
                            </tr>
                            <tr>
                                <td [colSpan]="headElements.length" style="border-top: none !important; border-bottom: none !important">
                                    <mdb-select-2
                                        placeholder="Afficher 10/ page"
                                        (selected)="changeItemsByPage($event)"
                                        style="float: right; min-width: 174px"
                                        name="itemsByPage"
                                        [outline]="true"
                                    >
                                        <mdb-select-option *ngFor="let option of displayByPages" [value]="option.value">{{
                                            option.label
                                        }}</mdb-select-option>
                                    </mdb-select-2>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
