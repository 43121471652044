import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { ToastService } from '../../../components/mdb/pro/alerts';

@Component({
    selector: 'app-compte-candidat',
    templateUrl: './compte-candidat.component.html',
    styleUrls: ['./compte-candidat.component.css'],
})
export class CompteCandidatComponent implements OnInit {
    candidat: any = {};
    url = '';
    id = '';
    nouvelleNote = '';
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {
        this.url = this.globalService.url;
        if (this.route.snapshot.params.id !== undefined && this.route.snapshot.params.id !== null) {
            this.id = this.route.snapshot.params.id;

            this.httpClient.get<any>(this.globalService.url + '/candidat/' + this.id).subscribe(
                (response) => {
                    if (response.status) {
                        this.candidat = response.candidat;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
        }
    }
    supprimer(id: string): void {
        if (confirm('Voulez-vous vraiment supprimer ce compte ?')) {
            this.httpClient.delete(this.globalService.url + '/candidat/' + id).subscribe(
                (response) => {
                    this.toastrService.info('Compte supprimé', '', { opacity: 1 });
                },
                () => {}
            );
        }
    }
    ajouterNote(): void {
        if (this.nouvelleNote === '') {
            alert('La nouvelle note est vide');
        } else {
            let nouvelleNotes = this.candidat.notes;
            if (nouvelleNotes === null || nouvelleNotes === '') {
                nouvelleNotes = this.nouvelleNote;
            } else {
                nouvelleNotes.unshift(this.nouvelleNote);
            }
            this.httpClient.patch<any>(this.globalService.url + '/candidat/note', { id: this.id, notes: nouvelleNotes }, {}).subscribe(
                (response) => {
                    if (response.status) {
                        this.candidat.notes = nouvelleNotes;
                        this.nouvelleNote = '';
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
        }
    }
    supprimerNote(note: string): void {
        let newNotes: any = [];
        for (let n of this.candidat.notes) {
            if (n != note) {
                newNotes.push(n);
            }
        }
        this.httpClient.patch<any>(this.globalService.url + '/candidat/note', { id: this.id, notes: newNotes }, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.candidat.notes = newNotes;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    bloquer(): void {
        if (confirm('Bloquer ce compte ?')) {
            this.httpClient.patch<any>(this.globalService.url + '/candidat/status', { id: this.id, status: false }, {}).subscribe(
                (response) => {
                    if (response.status) {
                        this.candidat.status = false;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
        }
    }
    debloquer(): void {
        if (confirm('Débloquer ce compte ?')) {
            this.httpClient.patch<any>(this.globalService.url + '/candidat/status', { id: this.id, status: true }, {}).subscribe(
                (response) => {
                    if (response.status) {
                        this.candidat.status = true;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
        }
    }
    envoiSansConnexion(): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/sansConnexion/' + this.id, {}).subscribe(
            (response) => {
                if (response.status) {
                    alert('Mail envoyé');
                } else {
                    alert("Erreur lors de l'envoi");
                }
            },
            () => {}
        );
    }
}
