<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-color" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <div>
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">Conseils à lire avant diffusion !</h3>
                </div>
                <div class="page-sub-title">
                    <h5 class="breadcrumb-sub">Quelques conseils pour vous aider dans vos démarches</h5>
                </div>
                <div id="main-wrapper">
                    <div class="row">
                        <div class="col-md-12 p-5 d-flex justify-content-center">
                            <video
                                class="video-fluid z-depth-1"
                                style="width: 100%; max-height: 1200px; max-width: 1200px"
                                loop
                                controls
                                muted
                            >
                                <source src="./assets/video/conseils.webm" type="video/webm" />
                                <source src="./assets/video/conseils.mp4" type="video/mp4" />
                                Problème lors du chargement de la vidéo
                            </video>
                        </div>
                        <div class="offset-md-1 col-md-10" style="margin-top: 50px; margin-bottom: 120px">
                            <div class="mention-text" style="font-size: 18px; color: rgb(1, 73, 179) !important">
                                Complétez vos atouts concurrentiels. Ils vont vous permettre de vous démarquer des autres candidats.<br /><br />
                                Vérifiez que les informations vous concernant sur Internet sont positives et cohérentes avec votre CV et
                                votre lettre de motivation.<br /><br />
                                Dans le cas d’un entretien vidéo (Skype par exemple), vérifiez préalablement la qualité de votre connexion
                                Internet et des accessoires (casque et micro).<br /><br />
                                Vérifiez la qualité du message délivré par votre messagerie.<br /><br />
                                Laissez votre téléphone sur vibreur. En cas d’appel d’un recruteur, ne décrochez pas pour avoir le temps de
                                vous renseigner sur lui et son organisme avant de le recontacter.<br /><br />
                                Ecoutez régulièrement les messages laissés sur votre répondeur et enregistrez les messages importants dans
                                un fichier de suivi. Supprimez ensuite les messages afin que votre messagerie ne soit pas saturée.
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
