<app-site-header page='candidatures-spontanees'></app-site-header>
<app-modal-login #loginModal (successLogin)="checkLogin($event)" [redirect]="'%2Fcandidatures-spontanees%2F' + reference"></app-modal-login>
<app-modal-register #registerModal (successRegister)="checkRegister($event)"></app-modal-register>
<div class='container'>
  <div class='col-12 pr-0 pl-0'
       style='width: 100%; margin: 0; padding-top: 80px; padding-bottom: 80px'>
    <div *ngIf='loading' class='d-flex mt-4 ml-auto mr-auto justify-content-center text-center'>
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div [hidden]='loading' class='text-center'>
      <h1>{{ recruteur.nomSociete }}</h1>
      <img *ngIf='recruteur.photo'
           class='rounded-circle img-fluid d-block ml-auto mr-auto p-2'
           style='object-fit: cover; width: 120px; height: 120px'
           src='{{ url }}/images/recruteur/{{ recruteur.photo }}'
           alt=''
      />
      <h2 *ngIf='!sent' class='mt-4'>
        Pour centraliser la gestion des candidatures spontanées,
        nous utilisons les services d’AvenueDesRecruteurs.fr
      </h2>
      <div class='mt-4' *ngIf='sent'>
        <h4>Nous avons bien reçu votre candidature et vous en remercions.<br><br>

        Nous vous conseillons de compléter au maximum votre fiche sur avenuedesrecruteurs.fr pour avoir plus
        d’informations sur votre profil.<br><br>

          Nous reviendrons vers vous dès qu’une opportunité vous correspond.</h4>
      </div>
      <div [hidden]='haveAccount !== 0' [class]="haveAccount !== 0 ? 'animate__animated animate__fadeOut animate__fast' : ''">
        <h3 class='mt-4 mb-4'>
          Avez-vous un compte sur Avenue Des Recruteurs ?
        </h3>
        <div class='row p-3 justify-content-between'>
          <div class='col'>
            <input class='sr-only peer' type='button' value='yes' (click)="handleChangeHaveAccount($event)" name='answer' id='answer_yes'>
            <label
              class='d-flex justify-content-center p-5 bg-white border border-gray-300 rounded-lg peer-hover-green cursor-pointer focus:outline-none hover-bg-gray'
              for='answer_yes'>Oui</label>
          </div>

          <div class='col'>
            <input class='sr-only peer' type='button' value='no' (click)="handleChangeHaveAccount($event)" name='answer' id='answer_no'>
            <label
              class='d-flex justify-content-center p-5 bg-white border border-gray-300 rounded-lg peer-hover-red cursor-pointer focus:outline-none hover-bg-gray '
              for='answer_no'>Non</label>
          </div>

        </div>
      </div>
    </div>
    <div [hidden]='!displayForm' class='animate__animated animate__fadeIn animate__fast'>
      <div class='pt-2 pb-2'>
        Pour nous adresser votre candidature spontanée, merci de compléter les informations ci-dessous.
        <span class='text-decoration-underline'>Un formulaire plus rempli aura plus de chances d’obtenir un retour positif.</span>
      </div>

      <div class='d-flex inscription-row d-flex flex-wrap w-100'>
        <div class='col-12 col-md-6 mb-4 mb-md-0 p-0' style='text-align: center; margin-top: auto'>
          <div class='form-group radio_input d-inline'>
                                    <span
                                      for='gender'
                                      class='error'
                                      [ngStyle]="{
                                            display: this.error.civilite ? 'block' : 'none'
                                        }"
                                    >Obligatoire</span
                                    >
            <label class='container_radio mr-1'
            >Monsieur
              <input [disabled]='alreadyExist'
                type='radio'
                name='gender'
                (click)="this.candidat.civilite = 'Monsieur'"
                [checked]="candidat.civilite === 'Monsieur'"
              />
              <span class='checkmark'></span>
            </label>
            <label class='container_radio'
            >Madame
              <input [disabled]='alreadyExist' type='radio' name='gender' (click)="this.candidat.civilite = 'Madame'"
                     [checked]="candidat.civilite === 'Madame'" />
              <span class='checkmark'></span>
            </label>
            <label class='container_radio ml-1'
            >Autre
              <input [disabled]='alreadyExist' type='radio' name='gender' (click)="this.candidat.civilite = 'Autre'"
                     [checked]="candidat.civilite === 'Autre'" />
              <span class='checkmark'></span>
            </label>
          </div>
        </div>
        <div class='col-12 col-md-6'>
          <div class='form-group'>
            <label
              for='nom'
              [ngClass]="{
                                            'fl-label-empty': this.candidat.nom === '',
                                            'fl-label-not-empty': this.candidat.nom !== ''
                                        }"
            >Nom *</label
            >
            <span
              for='nom'
              class='error'
              [ngStyle]="{
                                            display: this.error.nom ? 'block' : 'none'
                                        }"
            >Obligatoire</span
            >
            <input type='text' [disabled]='alreadyExist' name='nom' id='nom' class='form-control' [(ngModel)]='this.candidat.nom' />
          </div>
        </div>
        <div class='col-12 offset-md-6 col-md-6'>
          <div class='form-group'>
            <label
              for='prenom'
              [ngClass]="{
                                            'fl-label-empty': this.candidat.prenom === '',
                                            'fl-label-not-empty': this.candidat.prenom !== ''
                                        }"
            >Prénom *</label
            >
            <span
              for='prenom'
              class='error'
              [ngStyle]="{
                                            display: this.error.prenom ? 'block' : 'none'
                                        }"
            >Obligatoire</span
            >
            <input type='text' [disabled]='alreadyExist' name='prenom' id='prenom' class='form-control' [(ngModel)]='this.candidat.prenom' />
          </div>
        </div>
      </div>

      <div class='d-flex flex-wrap inscription-row'>
        <div class='col-12 col-md-6'>
          <label for='fonction'>Intitulé du poste actuellement ou dernièrement occupé</label>
        </div>
        <div class='col-12 col-md-6'>
          <input type='text' [(ngModel)]='candidat.fonctionLibre' name='fonction' id='fonction' class='form-control' />
        </div>
      </div>

      <div class='d-flex flex-wrap inscription-row'>
        <div class='cold-12 col-md-4'>
          <label>Expérience dans ce métier</label>
          <mdb-select-2
            placeholder='Choisir..'
            name='secteurActivite'
            class='custom-selectbox'
            [(ngModel)]='candidat.experienceSecteur'
            [outline]='true'
          >
            <mdb-select-option *ngFor='let option of typesExperiencesSecteurs' [value]='option.value'>{{
              option.label
              }}</mdb-select-option>
          </mdb-select-2>
        </div>

        <div class='cold-12 col-md-4 mt-3 mt-md-0'>
          <label>Expérience globale</label>
          <mdb-select-2
            placeholder='Choisir..'
            name='typeEntreprise'
            class='custom-selectbox'
            [(ngModel)]='candidat.experience'
            [outline]='true'
          >
            <mdb-select-option *ngFor='let option of typesExperiences' [value]='option.value'>{{
              option.label
              }}</mdb-select-option>
          </mdb-select-2>
        </div>

        <div class='cold-12 col-md-4 mt-3 mt-md-0'>
          <label>Niveau d'étude</label>
          <mdb-select-2
            placeholder='Choisir..'
            name='typeEntreprise'
            class='custom-selectbox'
            [(ngModel)]='candidat.niveauEtude'
            [outline]='true'
          >
            <mdb-select-option *ngFor='let option of typesEtudes' [value]='option.value'>{{
              option.label
              }}</mdb-select-option>
          </mdb-select-2>
        </div>
      </div>

      <div class="inscription-row">

        <app-secteur class='d-flex flex-wrap' [showHelp]='true' [(data)]='candidat.secteurActivite' [typeSecteur]='"Perso"'>
          <label for="secteursRechercher">Secteur(s) d'activité <small>Ce champ est multiple</small></label>
        </app-secteur>
      </div>

      <div class='inscription-row'>
        <div class='form-group add_bottom_30'>
          <label>Disponibilité</label>
          <div class='row pt-3'>
            <div class='col-12 col-md-6'>
              <div class='row'>
                <div class='col-4 col-md-4 font-weight-bold pr-0'>
                  Je suis en poste
                </div>

                <div class='switch col-3 col-md-3'>
                  <label>
                    <input [checked]="dispo.travail === '2'" type='checkbox' (change)='dispoTravail($event)' />
                    <span class='lever'></span>
                  </label>
                </div>
                <div class='col-5 col-md-4 font-weight-bold '>Je ne suis pas en poste</div>
              </div>
            </div>
            <div class='col-12 col-md-6 pt-2 pt-md-0' *ngIf="dispo.travail == '1'">
              <div class='row'>
                <div class=' offset-md-2 col-4  col-md-4 font-weight-bold pr-0'>
                  Je connais ma date de départ
                </div>

                <div class='switch col-3 col-md-2'>
                  <label>
                    <input [checked]="dispo.preavis === '2'" type='checkbox' (change)='dispoPreavis($event)' />
                    <span class='lever'></span>
                  </label>
                </div>
                <div class='col-5 col-md-4 font-weight-bold '>Je ne connais pas ma date de départ</div>
              </div>

            </div>
            <div class='col-12 mt-3'>
              <div
                class='styled-select clearfix col-12 col-md-6'
                *ngIf="dispo.travail == '1' && (dispo.preavis == '' || dispo.preavis == '2')"
              >
                Disponible sous (préavis) *
                <span
                  for="dateDisponibilite"
                  class="error"
                  [ngStyle]="{
                                        display: this.error.disponibilite ? 'block' : 'none'
                                    }"
                >Obligatoire</span
                >
                <mdb-select-2
                  placeholder='Choisir..'
                  name='disponible'
                  class='bottom-select'
                  [(ngModel)]='candidat.disponibilite'
                  [outline]='true'
                >
                  <mdb-select-option *ngFor='let option of typesDispos' [value]='option.value'>{{
                    option.label
                    }}</mdb-select-option>
                </mdb-select-2>
              </div>
              <div
                class='styled-select clearfix col-12 col-md-6'
                *ngIf="dispo.travail == '2' || dispo.preavis == '1'"
              >
                Disponible dès le *
                <div *ngIf="dateDisponibiliteHistorique !== ''">
                  {{ dateDisponibiliteHistorique | date: 'dd-MM-yyyy' }}
                </div>
                <span
                  for="dateDisponibilite"
                  class="error"
                  [ngStyle]="{
                                        display: this.error.disponibiliteDate ? 'block' : 'none'
                                    }"
                >Votre date de disponibilité ne peut pas être dans le passé ou vide</span
                >
                <app-datepicker [(date)]='dateDisponibilite'></app-datepicker>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class='inscription-row mb-3 form-group'>
        <label>Mobilité</label>
        <div class='row'>
          <div class='col-md-4'>
            <div class='form-group'>
              <label class='container_check'
              >Ville(s)
                <input type='checkbox' name='regional' [(ngModel)]='mobilite.regional' />
                <span class='checkmark'></span>
              </label>
            </div>
          </div>
          <div class='col-md-4'>
            <div class='form-group'>
              <label class='container_check'
              >Pays
                <input type='checkbox' name='national' [(ngModel)]='mobilite.national' />
                <span class='checkmark'></span>
              </label>
            </div>
          </div>
          <div class='col-md-4'>
            <div class='form-group'>
              <label class='container_check'
              >Tous les pays
                <input type='checkbox' name='international' [(ngModel)]='mobilite.international' />
                <span class='checkmark'></span>
              </label>
            </div>
          </div>
        </div>
        <div *ngIf='mobilite.international'>
          <label style='color: green !important; font-size: 16px'>Vous êtes disponible partout dans le monde</label>
        </div>
        <div *ngIf='mobilite.national' class='mt-2'>
          <label>Mobilité nationale : sélection des pays de mobilité</label>
          <div class='row'>
            <div class='col-12 col-md-6'>
              <mdb-select-2
                [(ngModel)]='actuel.pays'
                placeholder='Pays de mobilité *'
                [notFoundMsg]="'Pays non trouvé'"
                name='mobilitePays'
                (selected)='choosePays($event)'
                [outline]='true'
              >
                <mdb-select-filter [ngModel]='searchPays | async' (inputChange)='searchPays.next($event)'>
                </mdb-select-filter>
                <mdb-select-option *ngFor='let option of filteredOptions | async' [value]='option.pays'>{{
                  option.pays
                  }}</mdb-select-option>
              </mdb-select-2>
            </div>
            <div class='col-12 col-md-6 mt-4 mt-md-0'>
              <div style='position: relative' *ngFor='let p of candidat.mobilite_nationale'>
                <label class='container_radio version_2' style='padding-left: 16px'
                >{{ p }}
                  <input type='radio' name='positions' value='' disabled />
                </label>
                <div class='container-cross' (click)='removePays(p)'>
                  <div class='cross'>x</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf='mobilite.regional'>
          <hr style='' />
          <label>Dans la / les ville(s) suivante(s) :</label>
          <div class='row'>
            <div class='col-md-6 pl-0'>
              <div class='col-12'>
                <div class='form-group'>
                  <mdb-select-2
                    [(ngModel)]='mobiliteRayon.pays'
                    (selected)="searchCity.next('')"

                    placeholder='Choisir un pays..'
                    [notFoundMsg]="'Pays non trouvé'"
                    name='paysMobilite'
                    [outline]='true'
                  >
                    <mdb-select-filter
                      [ngModel]='searchPays | async'
                      (inputChange)='searchPays.next($event)'
                    >
                    </mdb-select-filter>
                    <mdb-select-option
                      *ngFor='let option of filteredOptions | async'
                      [value]='option.code'
                    >{{ option.pays }}</mdb-select-option
                    >
                  </mdb-select-2>
                </div>
              </div>
              <div class='col-12 md-form'>
                <app-select-city [ville]='mobiliteRayon'></app-select-city>
              </div>

              <div class='col-12' style='margin-bottom: 15px'>
                <label>Et / ou dans un rayon de (km)</label>
                <input
                  type='number'
                  name='rayon'
                  id='rayon'
                  class='form-control'
                  [(ngModel)]='mobiliteRayon.rayon'
                />
              </div>

              <div class='col-12'>
                <div class='form-group' style='margin-top: 28px'>
                  <button
                    mdbBtn
                    [block]='true'
                    color='warning'
                    mdbWavesEffect
                    (click)='chooseAddrMobiliteAjout()'
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
            <div class='col-md-6 pl-0'>
              <div class='col-12 mt-3' style='position: relative' *ngFor='let m of candidat.mobilite_locale'>
                <label class='container_radio version_2' style='padding-left: 16px'
                >{{ m.ville }} - {{ m.pays }}. Rayon de {{ m.rayon }} km
                  <input type='radio' name='positions' value='' disabled />
                </label>
                <div class='container-cross' (click)='removeMobilite(m)'>
                  <div class='cross'>x</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class='form-group inscription-row'>
        <span
          for='lettreMotivation'
          class='error'
          [ngStyle]="{
                                            display: this.error.lettreMotivation ? 'block' : 'none'
                                        }"
        >Obligatoire</span
        >
        <app-lettre-motivation-editor [required]='true' [(lettreMotivation)]='candidat.lettreMotivation'></app-lettre-motivation-editor>
      </div>

      <div class="form-group inscription-row">
        <span
          for='cv'
          class='error'
          [ngStyle]="{
                                            display: this.error.cv ? 'block' : 'none'
                                        }"
        >Obligatoire</span
        >
        <div class="d-flex flex-wrap align-items-center">
          <div class="col-4 col-md-4">
            <span class="font-weight-bold">Importez votre CV (au format pdf) *</span>
          </div>
          <div class="col-8 col-md-3">
            <div *ngIf="candidat && candidat.cv && candidat.cv !== '' && candidat.cv.length < 100">
              <a target="_blank" href="{{ url }}/documents/cv/{{ candidat.cv }}"
              ><small>{{ candidat.cv }}</small> <fa-icon class="ml-2" [icon]="faFilePdf"></fa-icon
              ></a>
            </div>
            <div *ngIf="candidat && candidat.cv && candidat.cv !== '' && candidat.cv.length >= 100">
             <small>{{ cvFileName }}</small> <fa-icon class="ml-2" [icon]="faFilePdf"></fa-icon
              >
            </div>
          </div>
          <div class="col-12 col-md-2 mt-3 mt-md-0">
            <div class="file-field ml-3">
              <div mdbBtn [rounded]="true" [outline]="false" color="secondary">
                <span>{{ candidat.cv !== '' ? 'Modifier' : 'Ajouter' }}</span>
                <input type="file" accept='.pdf' name="cvFile" mdbFileSelect (change)="cv($event)" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 p-3 inscription-row">
        <div class="form-group" *ngIf='alreadyExist'>
          <div class="form-group terms">
            <div class="form-group terms">
              <label class="container_check"
              >Je ne souhaite pas que mon CV et ma lettre de motivation soient mis à jour dans ma fiche sur Avenue Des
                Recruteurs
                <input type="checkbox" name="terms" value="" [(ngModel)]='updateInformations' />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group pt-3">
          <div class="form-group terms">
            <label class="container_check"
            >En cochant cette case, j'accepte sans réserve les
              <a target="_blank" href="/conditions-generales-d-utilisation-et-de-vente"
              >conditions d'utilisation et de vente du site. *</a
              >
              <input type="checkbox" name="terms" value="" [(ngModel)]='acceptation' />
              <span class="checkmark"></span>
            </label>
          </div>
          <div style="color: red" *ngIf="error.acceptation">
            Vous devez accepter nos CGUV avant de finaliser votre inscription.
          </div>
        </div>
        <small class='pt-4'>
          Votre fiche sur Avenue Des Recruteurs sera automatiquement créée ou mise à jour avec ces informations (hors CV et
          LM si souhaité)
          <br>(*) Obligatoire</small>
      </div>

      <div class='d-flex justify-content-center mt-4'>
        <input class="inscription-next"  type="submit" (click)="send()" value="Envoyer" />
      </div>

    </div>
  </div>
</div>
<app-site-footer></app-site-footer>
