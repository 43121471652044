import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

import { faCopy, faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-code-reduction',
    templateUrl: './code-reduction.component.html',
    styleUrls: [
        './code-reduction.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/jquery.datatables.min.css',
    ],
})
export class CodeReductionComponent implements OnInit {
    faEnvelope = faEnvelope;
    faCopy = faCopy;
    faTrash = faTrash;
    codeReductions: any = [];

    nouveauCode = {
        _id: '',
        nom: '',
        dateDeDebut: '',
        dateDeFin: '',
        pourLeCandidat: '',
        pourLeCandidatId: '',
        nombreUtilisationMaximum: 1,
        nombreUtilisation: 0,
        montant: 0,
        montantMinimumDuPanier: 0,
        montantMinimumHtOuTtc: 'ttc',
        typeReduction: 'euro',
        pourUnePremiereCommande: false,
        usageUnique: false,
    };
    candidats: any = [
        {
            _id: '',
            nom: '',
            prenom: '',
            dateNaissance: '',
            email: '',
        },
    ];
    recherche = {
        pourCandidat: '',
        pourCandidatCache: '',
        secteur: '',
        secteurCache: '',
    };
    pourCandidatCheck = false;

    nomNonSaisie = false;
    dateDebutNonSaisie = false;
    montantNonSaisie = false;
    typeReductionNonSaisie = false;
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.recuperer();
        setInterval(() => {
            if (this.recherche.pourCandidat.length > 3) {
                this.changePourCandidat();
            }
        }, 200);
    }
    changePourCandidat(): void {
        if (this.recherche.pourCandidat !== '' && this.recherche.pourCandidat !== this.recherche.pourCandidatCache) {
            this.pourCandidatCheck = true;
            this.recherche.pourCandidatCache = this.recherche.pourCandidat;
            this.getCandidats();
        }
    }
    getCandidats(): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/getWithText/' + this.recherche.pourCandidat).subscribe(
            (response) => {
                if (response.status) {
                    this.candidats = [];
                    for (const a of response.accounts) {
                        // @ts-ignore
                        // tslint:disable-next-line:prefer-const
                        let ns = {
                            _id: a._id,
                            nom: a.nom,
                            prenom: a.prenom,
                            dateNaissance: a.dateNaissance,
                            email: a.email,
                        };
                        this.candidats.push(ns);
                    }
                }
            },
            () => {}
        );
    }
    chooseCandidats(id: string, nom: string): void {
        this.recherche.pourCandidat = nom;
        this.recherche.pourCandidatCache = '';
        this.nouveauCode.pourLeCandidat = id;
        this.pourCandidatCheck = false;
    }
    getCandidatById(): void {
        for (let c of this.codeReductions) {
            if (c.pourLeCandidat !== '') {
                this.httpClient.get<any>(this.globalService.url + '/candidat/' + c.pourLeCandidat, {}).subscribe(
                    (response) => {
                        if (response.status) {
                            c.pourLeCandidatId = c.pourLeCandidat;
                            c.pourLeCandidat = response.candidat.prenom + ' ' + response.candidat.nom;
                        }
                    },
                    () => {}
                );
            }
        }
        console.log(this.codeReductions);
    }
    ajouter(): void {
        this.nomNonSaisie = false;
        this.dateDebutNonSaisie = false;
        this.montantNonSaisie = false;
        this.typeReductionNonSaisie = false;

        if (this.nouveauCode.nom === '') {
            this.nomNonSaisie = true;
        }

        if (this.nouveauCode.dateDeDebut === '') {
            this.dateDebutNonSaisie = true;
        }

        if (this.nouveauCode.montant === 0) {
            this.montantNonSaisie = true;
        }

        if (this.nouveauCode.typeReduction === '') {
            this.typeReductionNonSaisie = true;
        }

        if (!this.nomNonSaisie && !this.dateDebutNonSaisie && !this.montantNonSaisie && !this.typeReductionNonSaisie) {
            if (this.nouveauCode.dateDeDebut === 'NaN') {
                this.nouveauCode.dateDeDebut = '';
            }
            if (this.nouveauCode.dateDeFin === 'NaN') {
                this.nouveauCode.dateDeFin = '';
            }
            this.httpClient.put(this.globalService.url + '/reduction', this.nouveauCode).subscribe(
                (response: any) => {
                    if (response.status) {
                        this.recuperer();
                        if (this.nouveauCode.pourLeCandidat !== '') {
                            this.envoyerMail(response.id);
                        }
                    }
                },
                () => {}
            );
            this.nouveauCode = {
                _id: '',
                nom: '',
                dateDeDebut: '',
                dateDeFin: '',
                pourLeCandidat: '',
                pourLeCandidatId: '',
                nombreUtilisationMaximum: 1,
                nombreUtilisation: 0,
                montant: 0,
                montantMinimumDuPanier: 0,
                montantMinimumHtOuTtc: 'ttc',
                typeReduction: 'euro',
                pourUnePremiereCommande: false,
                usageUnique: false,
            };
        }
    }
    supprimer(id: string): void {
        this.httpClient.delete(this.globalService.url + '/reduction/' + id).subscribe(
            (response: any) => {
                if (response.status) {
                    this.recuperer();
                }
            },
            () => {}
        );
    }
    dupliquer(id: string): void {
        for (let c of this.codeReductions) {
            if (c._id === id) {
                if ((this.nouveauCode.dateDeDebut === 'NaN' || this.nouveauCode.dateDeDebut === '') && c.dateDeDebut !== '') {
                    this.nouveauCode.dateDeDebut = c.dateDeDebut;
                }
                if ((this.nouveauCode.dateDeFin === 'NaN' || this.nouveauCode.dateDeFin === '') && c.dateDeFin !== '') {
                    this.nouveauCode.dateDeFin = c.dateDeFin;
                }

                if (this.nouveauCode.pourLeCandidat === '' && c.pourLeCandidatId !== '') {
                    this.nouveauCode.pourLeCandidat = c.pourLeCandidatId;
                }

                if (this.nouveauCode.nombreUtilisationMaximum === 1 && c.nombreUtilisationMaximum !== 1) {
                    this.nouveauCode.nombreUtilisationMaximum = c.nombreUtilisationMaximum;
                }

                if (this.nouveauCode.montant === 0 && c.montant !== 0) {
                    this.nouveauCode.montant = c.montant;
                }

                if (this.nouveauCode.montantMinimumDuPanier === 0 && c.montantMinimumDuPanier !== 0) {
                    this.nouveauCode.montantMinimumDuPanier = c.montantMinimumDuPanier;
                }

                if (this.nouveauCode.montantMinimumHtOuTtc === 'ttc' && c.dateDeDebut !== 'ttc') {
                    this.nouveauCode.montantMinimumHtOuTtc = c.montantMinimumHtOuTtc;
                }

                if (this.nouveauCode.typeReduction === 'euro' && c.typeReduction !== 'euro') {
                    this.nouveauCode.typeReduction = c.typeReduction;
                }

                if (!this.nouveauCode.pourUnePremiereCommande && c.pourUnePremiereCommande) {
                    this.nouveauCode.pourUnePremiereCommande = true;
                }

                if (!this.nouveauCode.usageUnique && c.usageUnique) {
                    this.nouveauCode.pourUnePremiereCommande = true;
                }
            }
        }
    }
    recuperer(): void {
        this.httpClient.get<any>(this.globalService.url + '/reduction').subscribe(
            (response: any) => {
                if (response.status) {
                    this.codeReductions = response.codeReductions;
                    this.getCandidatById();
                }
            },
            () => {}
        );
    }
    envoyerMail(id: string): void {
        this.httpClient.put(this.globalService.url + '/mail/code-reduction', { id: id }, {}).subscribe(
            (response: any) => {
                if (response.status) {
                    alert('Envoyé');
                }
            },
            () => {}
        );
    }
}
