<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Gestion de la FAQ</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table class="display table dataTable">
                                    <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Réponse</th>
                                            <th>Ordre</th>
                                            <th>Modifier</th>
                                            <th>Supprimer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Question..."
                                                    class="form-control"
                                                    [(ngModel)]="nouveau.question"
                                                    [ngStyle]="{
                                                        border: erreurAjout ? '1px solid red' : ''
                                                    }"
                                                />
                                            </td>
                                            <td>
                                                <textarea
                                                    rows="6"
                                                    placeholder="Réponse..."
                                                    class="form-control"
                                                    [(ngModel)]="nouveau.reponse"
                                                    [ngStyle]="{
                                                        border: erreurAjout ? '1px solid red' : ''
                                                    }"
                                                ></textarea>
                                            </td>
                                            <td colspan="3">
                                                <div>
                                                    <button class="btn btn-info" (click)="ajouter()">Ajouter une FAQ</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let f of faqs">
                                            <td>
                                                <input type="text" class="form-control" [(ngModel)]="f.question" />
                                            </td>
                                            <td>
                                                <textarea rows="6" type="number" class="form-control" [(ngModel)]="f.reponse"></textarea>
                                            </td>
                                            <td>
                                                <select class="form-control" (change)="modifierOrdre(f._id, $event)">
                                                    <option *ngFor="let t of tableau" value="{{ t }}" [selected]="estOrdre(f._id, t)">
                                                        {{ t }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <button class="btn btn-success" (click)="modifier(f._id)">Modifier</button>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <button class="btn btn-danger" (click)="supprimer(f._id)">Supprimer</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
