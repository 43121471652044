import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-email-verifier',
    templateUrl: './email-verifier.component.html',
    styleUrls: ['./email-verifier.component.css'],
})
export class EmailVerifierComponent implements OnInit {
    ref = '';
    type = '';
    nombre = 0;
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.ref = this.route.snapshot.params.text;
        this.type = this.route.snapshot.params.type;
        this.nombre = this.route.snapshot.params.nombre;

        this.httpClient.patch(this.globalService.url + '/' + this.type + '/mail/' + this.ref + '/' + this.nombre, {}).subscribe(
            (response) => {},
            () => {}
        );

        if (this.type.includes('didat')) this.router.navigate(['/inscription-candidat']);
        else this.router.navigate(['/inscription-recruteur']);
    }
}
