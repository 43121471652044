import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-sauvegardes',
    templateUrl: './sauvegardes.component.html',
    styleUrls: ['./sauvegardes.component.css'],
})
export class SauvegardesComponent implements OnInit {
    url = '';
    sauvegardes: any = [];
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.url = this.globalService.url;
        this.getSauvegardes();
    }
    getSauvegardes(): void {
        this.sauvegardes = [];
        this.httpClient.get<any>(this.globalService.url + '/sauvegardes').subscribe(
            (response) => {
                if (response.status) {
                    this.sauvegardes = response.sauvegardes;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
