import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ClipboardModule } from '@angular/cdk/clipboard';

import {
    CarouselModule,
    ButtonsModule,
    CollapseModule,
    ModalModule,
    TableModule,
    WavesModule,
    InputsModule,
    CardsModule,
    DropdownModule,
    CheckboxModule,
    TooltipModule,
    PopoverModule,
} from './components/mdb/free/mdb-free.module';
import {
    AutoCompleterModule,
    MdbSelectModule,
    FileInputModule,
    RangeModule,
    DatepickerModule,
    SmoothscrollModule,
    CharCounterModule,
    ChipsModule,
} from './components/mdb/pro/mdb-pro.module';
import { ToastModule } from './components/mdb/pro/alerts';

import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { QuillModule } from 'ngx-quill';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { CountrySelectComponent } from './components/selects/country-select/country-select.component';
import { AdressSelectComponent } from './components/selects/adress-select/adress-select.component';
import { PoleEmploiLocalisationSelectComponent } from './components/selects/pole-emploi-localisation-select/pole-emploi-localisation-select.component';
import { CitySelectComponent } from './components/selects/city-select/city-select.component';
import { ReportModalComponent } from './components/modals/report-modal/report-modal.component';
import { LoginModalComponent } from './components/modals/login-modal/login-modal.component';
import { RegisterModalComponent } from './components/modals/register-modal/register-modal.component';
import { AccueilComponent } from './pages/site/accueil/accueil.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { FaqComponent } from './pages/site/faq/faq.component';
import { CguComponent } from './pages/site/cgu/cgu.component';
import { RecruiterFormComponent } from './components/modals/recruiter-form/recruiter-form.component';
import { CandidatFormComponent } from './components/modals/candidat-form/candidat-form.component';
import { OfferFormComponent } from './components/modals/offer-form/offer-form.component';
import { DrawerComponent } from './components/modals/drawer/drawer.component';
import { LettreMotivationEditorComponent } from './components/editor/lettre-motivation-editor/lettre-motivation-editor.component';
import { MentionsLegalesComponent } from './pages/site/mentions-legales/mentions-legales.component';
import { ContactComponent } from './pages/site/contact/contact.component';
import { SitemapComponent } from './pages/site/sitemap/sitemap.component';
import { InscriptionCandidatComponent } from './pages/site/inscription-candidat/inscription-candidat.component';
import { InscriptionRecruteurComponent } from './pages/site/inscription-recruteur/inscription-recruteur.component';
import { TarifComponent } from './pages/site/tarif/tarif.component';
import { ConseilComponent } from './pages/site/conseil/conseil.component';
import { DashboardComponent } from './pages/espaceAdministrateur/dashboard/dashboard.component';
import { DashboardCandidatComponent } from './pages/espaceCandidat/dashboard/dashboard.component';
import { DashboardRecruteurComponent } from './pages/espaceRecruteur/dashboard/dashboard.component';
import { AvantagesRecruteurComponent } from './pages/espaceRecruteur/avantages/avantages.component';
import { AvantagesCandidatComponent } from './pages/espaceCandidat/avantages/avantages.component';
import { AvantagesComponent } from './pages/espaceAdministrateur/avantages/avantages.component';
import { ComptesAdministrateurComponent } from './pages/espaceAdministrateur/comptes-administrateur/comptes-administrateur.component';
import { ListeCompteRecruteurComponent } from './pages/espaceAdministrateur/liste-compte-recruteur/liste-compte-recruteur.component';
import { ListeCompteCandidatComponent } from './pages/espaceAdministrateur/liste-compte-candidat/liste-compte-candidat.component';
import { ListeMetiersComponent } from './pages/espaceAdministrateur/liste-metiers/liste-metiers.component';
import { ListeSecteursComponent } from './pages/espaceAdministrateur/liste-secteurs/liste-secteurs.component';
import { MetiersNonAssignesComponent } from './pages/espaceAdministrateur/metiers-non-assignes/metiers-non-assignes.component';
import { SecteursNonAssignesComponent } from './pages/espaceAdministrateur/secteurs-non-assignes/secteurs-non-assignes.component';
import { ListeDiffusionEnAttenteComponent } from './pages/espaceAdministrateur/liste-diffusion-en-attente/liste-diffusion-en-attente.component';
import { ListeDiffusionRefusComponent } from './pages/espaceAdministrateur/liste-diffusion-refus/liste-diffusion-refus.component';
import { ListeDiffusionAccepteComponent } from './pages/espaceAdministrateur/liste-diffusion-accepte/liste-diffusion-accepte.component';
import { DetailDiffusionComponent } from './pages/espaceAdministrateur/detail-diffusion/detail-diffusion.component';
import { InformationsPersonnellesComponent } from './pages/espaceRecruteur/informations-personnelles/informations-personnelles.component';
import { InformationsPersonnellesCandidatsComponent } from './pages/espaceCandidat/informations-personnelles/informations-personnelles.component';
import { ListeCandidatureRecuComponent } from './pages/espaceRecruteur/liste-candidature-recu/liste-candidature-recu.component';
import { CvThequeComponent } from './pages/espaceRecruteur/cv-theque/cv-theque.component';
import { ListeDesabonnementComponent } from './pages/espaceRecruteur/liste-desabonnement/liste-desabonnement.component';
import { ListeDesabonnementCandidatComponent } from './pages/espaceCandidat/liste-desabonnement/liste-desabonnement.component';
import { ListeCommandeComponent } from './pages/espaceCandidat/liste-commande/liste-commande.component';
import { DetailCommandeComponent } from './pages/espaceCandidat/detail-commande/detail-commande.component';
import { ListeFactureComponent } from './pages/espaceCandidat/liste-facture/liste-facture.component';
import { ListePanierComponent } from './pages/espaceCandidat/liste-panier/liste-panier.component';
import { RenouvellerMotDePasseComponent } from './pages/site/renouveller-mot-de-passe/renouveller-mot-de-passe.component';
import { Erreur404Component } from './pages/site/erreur404/erreur404.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './services/auth.service';
import { GlobalService } from './services/global.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AdminHeaderComponent } from './components/header/admin-header/admin-header.component';
import { CodeReductionComponent } from './pages/espaceAdministrateur/code-reduction/code-reduction.component';
import { FaqsComponent } from './pages/espaceAdministrateur/faqs/faqs.component';
import { FormsModule } from '@angular/forms';
import { CompteCandidatComponent } from './pages/espaceAdministrateur/compte-candidat/compte-candidat.component';
import { CompteRecruteurComponent } from './pages/espaceAdministrateur/compte-recruteur/compte-recruteur.component';
import { SurveillancePhotoComponent } from './pages/espaceAdministrateur/surveillance-photo/surveillance-photo.component';
import { TarifsAdminComponent } from './pages/espaceAdministrateur/tarifs-admin/tarifs-admin.component';
import { DesabonnementComponent } from './pages/espaceAdministrateur/desabonnement/desabonnement.component';
import { RgpdComponent } from './pages/site/rgpd/rgpd.component';
import { EnvoiMailRenouvellementComponent } from './pages/site/envoi-mail-renouvellement/envoi-mail-renouvellement.component';
import { CandidatHeaderComponent } from './components/header/candidat-header/candidat-header.component';
import { RecruteurHeaderComponent } from './components/header/recruteur-header/recruteur-header.component';
import { SiteFooterComponent } from './components/footer/site-footer/site-footer.component';
import { SiteHeaderComponent } from './components/header/site-header/site-header.component';
import { DoublonDiffusionComponent } from './pages/espaceCandidat/doublon-diffusion/doublon-diffusion.component';
import { RefusCommandeComponent } from './pages/espaceCandidat/refus-commande/refus-commande.component';
import { AccepterCommandeComponent } from './pages/espaceCandidat/accepter-commande/accepter-commande.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { AccueilCandidatComponent } from './pages/site/accueil-candidat/accueil-candidat.component';
import { AccueilRecruteurComponent } from './pages/site/accueil-recruteur/accueil-recruteur.component';
import { ConnexionAdministrateurComponent } from './pages/site/connexion-administrateur/connexion-administrateur.component';
import { EmailVerifierComponent } from './pages/site/email-verifier/email-verifier.component';
import { DesabonnementSiteComponent } from './pages/site/desabonnement-site/desabonnement-site.component';
import { Desabo } from './pages/site/desabonnement/desabonnement.component';
import { ModifierMailComponent } from './pages/espaceAdministrateur/modifier-mail/modifier-mail.component';
import { ListeDiffusionRefuserDefinitivementComponent } from './pages/espaceAdministrateur/liste-diffusion-refuser-definitivement/liste-diffusion-refuser-definitivement.component';
import { ConnexionIntermediaireComponent } from './pages/site/connexion-intermediaire/connexion-intermediaire.component';
import { DiffusionNonSouhaiteeComponent } from './pages/espaceCandidat/diffusion-non-souhaitee/diffusion-non-souhaitee.component';
import { ModifierCommandeComponent } from './pages/espaceCandidat/modifier-commande/modifier-commande.component';
import { TemoignagesComponent } from './pages/espaceAdministrateur/temoignages/temoignages.component';
import { TemoignagesRecruteursComponent } from './pages/espaceAdministrateur/temoignages-recruteurs/temoignages-recruteurs.component';
import { TraducteurComponent } from './pages/espaceAdministrateur/traducteur/traducteur.component';
import { ListeDiffusionCandidatComponent } from './pages/espaceAdministrateur/liste-diffusion-candidat/liste-diffusion-candidat.component';
import { ConseilsComponent } from './pages/espaceCandidat/conseils/conseils.component';
import { ListeDiffusionTerminerComponent } from './pages/espaceAdministrateur/liste-diffusion-terminer/liste-diffusion-terminer.component';
import { TrackingComponent } from './pages/espaceAdministrateur/tracking/tracking.component';
import { ModifierTexteSiteComponent } from './pages/espaceAdministrateur/modifier-texte-site/modifier-texte-site.component';
import { SauvegardesComponent } from './pages/espaceAdministrateur/sauvegardes/sauvegardes.component';
import { ListeNewslettersComponent } from './pages/espaceAdministrateur/liste-newsletters/liste-newsletters.component';
import { LogSocialComponent } from './pages/site/log-social/log-social.component';
import { LinkedinComponent } from './pages/espaceAdministrateur/linkedin/linkedin.component';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeCandidatComponent } from './pages/espaceCandidat/home/home.component';
import { HomeRecruteurComponent } from './pages/espaceRecruteur/home/home.component';
import { PanierComponent } from './pages/espaceCandidat/panier/panier.component';
import { MotDePasseComponent } from './pages/espaceCandidat/mot-de-passe/mot-de-passe.component';
import { InformationsComponent } from './pages/espaceCandidat/informations/informations.component';
import { SituationComponent } from './pages/espaceCandidat/situation/situation.component';
import { RechercheComponent } from './pages/espaceCandidat/recherche/recherche.component';
import { InformationsComplementairesComponent } from './pages/espaceCandidat/informations-complementaires/informations-complementaires.component';
import { AutorisationsComponent } from './pages/espaceCandidat/autorisations/autorisations.component';
import { ValiderPanierComponent } from './pages/espaceCandidat/valider-panier/valider-panier.component';
import { MotDePasseRecruteurComponent } from './pages/espaceRecruteur/mot-de-passe/mot-de-passe.component';
import { InformationsRecruteurComponent } from './pages/espaceRecruteur/informations/informations.component';
import { MonOrganismeComponent } from './pages/espaceRecruteur/mon-organisme/mon-organisme.component';
import { InformationsComplementairesRecruteursComponent } from './pages/espaceRecruteur/informations-complementaires/informations-complementaires.component';
import { MaRechercheComponent } from './pages/espaceRecruteur/recherche/recherche.component';
import { AutorisationsRecruteursComponent } from './pages/espaceRecruteur/autorisations/autorisations.component';
import { MonCVComponent } from './pages/espaceCandidat/mon-cv/mon-cv.component';
import { AboutRecruteurComponent } from './pages/site/about-recruteur/about-recruteur.component';
import { AboutCandidatComponent } from './pages/site/about-candidat/about-candidat.component';
import { WorkersNumberPipe } from './pipes/workersnumber.pipe';
import { ActivitiesPipe } from './pipes/activities.pipe';
import { TypesEntreprisesPipe } from './pipes/types-entreprises.pipe';
import { ExperiencePipe } from './pipes/experience.pipe';
import { TeletravailPipe } from './pipes/teletravail.pipe';
import { EtudesPipe } from './pipes/etudes.pipe';
import { TypesPostePipe } from './pipes/types-poste.pipe';
import { DisposPipe } from './pipes/dispos.pipe';
import { SituationPipe } from './pipes/situation.pipe';
import { RemunerationPipe } from './pipes/remuneration.pipe';
import { FirstUpperTextPipe } from './pipes/first-upper-text.pipe';
import { ArrayToLineTextPipe } from './pipes/arrayToLineText.pipe';
import { CodeInputModule } from 'angular-code-input';
import { AuthGuard } from './guards/auth.guard';
import { CountUpModule } from 'ngx-countup';
import { DragulaModule } from 'ng2-dragula';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { GoogleMapsModule } from '@angular/google-maps';
import Counter from './utils/counter';
import { SignalementsComponent } from './pages/espaceAdministrateur/signalements/signalements.component';
import { TimestampPipe } from './pipes/timestamp.pipe';
import { CvComponent } from './components/candidat/cv/cv.component';
import { SecteurComponent } from './components/users/secteur/secteur.component';
import { SecteurFilterComponent } from './components/filters/secteur/secteur.component';
import { PosteComponent } from './components/users/poste/poste.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { authInterceptorProviders } from './services/auth.interceptor';
import { PluralPipe } from './pipes/plurial.pipe';
import { OffresComponent } from './pages/espaceCandidat/offres/offres.component';
import { CandidaturesSpontaneesComponent } from './pages/site/candidatures-spontanees/candidatures-spontanees.component';

declare var Hammer: any;

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = {
        pan: { direction: Hammer.DIRECTION_All },
        swipe: { direction: Hammer.DIRECTION_VERTICAL },
    } as any;
    buildHammer(element: HTMLElement): any {
        const mc = new Hammer(element, {
            touchAction: 'auto',
            inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
            recognizers: [
                [
                    Hammer.Swipe,
                    {
                        direction: Hammer.DIRECTION_HORIZONTAL,
                    },
                ],
            ],
        });
        return mc;
    }
}
const appRoutes: Routes = [
    {
        path: 'connexion-intermediaire',
        component: ConnexionIntermediaireComponent,
    },
    {
        path: 'ne-minteresse-pas/:idCommande',
        component: Desabo,
    },
    {
        path: 'inscription-candidat',
        component: InscriptionCandidatComponent,
    },
    {
        path: 'inscription-candidat/:email',
        component: InscriptionCandidatComponent,
    },
    {
        path: 'inscription-candidat/:id/:ref',
        component: InscriptionCandidatComponent,
    },
    {
        path: 'modifier-compte-candidat-sans-connexion/:id/:ref',
        component: InscriptionCandidatComponent,
    },
    {
        path: 'inscription-recruteur',
        component: InscriptionRecruteurComponent,
    },
    {
        path: 'inscription-recruteur/:email',
        component: InscriptionRecruteurComponent,
    },
    {
        path: 'inscription-recruteur/:id/:ref',
        component: InscriptionRecruteurComponent,
    },
    {
        path: 'modifier-compte-recruteur-sans-connexion/:id/:ref',
        component: InscriptionRecruteurComponent,
    },
    {
        path: 'nous-contacter',
        component: ContactComponent,
    },
    {
        path: 'conditions-generales-d-utilisation-et-de-vente',
        component: CguComponent,
    },
    {
        path: 'candidatures-spontanees/:reference',
        component: CandidaturesSpontaneesComponent,
    },
    {
        path: 'mentions-legales',
        component: MentionsLegalesComponent,
    },
    {
        path: 'politique-de-confidentialite-et-d-utilisation-des-donnees-personnelles',
        component: RgpdComponent,
    },
    {
        path: 'eMB1lsIVVnvKbJditHNo5kL4jWc46uy7fpJoQ5fj',
        component: ConnexionAdministrateurComponent,
    },
    {
        path: 'nos-tarifs',
        component: TarifComponent,
    },
    {
        path: 'renouveller-mon-mot-de-passe/:typeCompte/:id',
        component: RenouvellerMotDePasseComponent,
    },
    {
        path: 'demande-de-renouvellement-de-mon-mot-de-passe',
        component: EnvoiMailRenouvellementComponent,
    },
    {
        path: '',
        component: AccueilComponent,
    },
    {
        path: 'social/:typeAccount/:social/:id/:email',
        component: LogSocialComponent,
    },
    {
        path: 'candidat',
        component: AccueilCandidatComponent,
    },
    {
        path: 'recruteur',
        component: AccueilRecruteurComponent,
    },
    {
        path: 'about-recruteur',
        component: AboutRecruteurComponent,
    },
    {
        path: 'about.html',
        component: AccueilComponent,
    },
    {
        path: 'about-candidat',
        component: AboutCandidatComponent,
    },
    {
        path: 'recruteurs-a-proximite',
        component: SitemapComponent,
    },
    {
        path: 'email-verifier/:type/:text/:nombre',
        component: EmailVerifierComponent,
    },
    {
        path: 'desabonnement/:id',
        component: DesabonnementSiteComponent,
    },
    {
        path: 'nos-conseils',
        component: ConseilComponent,
    },
    {
        path: 'administration',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
            },
            {
                path: 'avantages/:type',
                component: AvantagesComponent,
            },
            {
                path: 'linkedin',
                component: LinkedinComponent,
            },
            {
                path: 'signalements',
                component: SignalementsComponent,
            },
            {
                path: 'tracking/:id/:typeCompte',
                component: TrackingComponent,
            },
            {
                path: 'multilingues',
                component: ModifierTexteSiteComponent,
            },
            {
                path: 'sauvegardes',
                component: SauvegardesComponent,
            },
            {
                path: 'newsletters',
                component: ListeNewslettersComponent,
            },
            {
                path: 'comptes-candidats/:type',
                component: ListeCompteCandidatComponent,
            },
            {
                path: 'liste-metiers',
                component: ListeMetiersComponent,
            },
            {
                path: 'liste-secteurs',
                component: ListeSecteursComponent,
            },
            {
                path: 'metiers-non-assignes',
                component: MetiersNonAssignesComponent,
            },
            {
                path: 'secteurs-non-assignes',
                component: SecteursNonAssignesComponent,
            },
            {
                path: 'modifier-mail/:id',
                component: ModifierMailComponent,
            },
            {
                path: 'candidat/:id',
                component: CompteCandidatComponent,
            },
            {
                path: 'comptes-recruteurs/:type',
                component: ListeCompteRecruteurComponent,
            },
            {
                path: 'recruteur/:id',
                component: CompteRecruteurComponent,
            },
            {
                path: 'comptes-administrateurs',
                component: ComptesAdministrateurComponent,
            },
            {
                path: 'tarifs',
                component: TarifsAdminComponent,
            },
            {
                path: 'codes-reductions',
                component: CodeReductionComponent,
            },
            {
                path: 'surveillance-photos',
                component: SurveillancePhotoComponent,
            },
            {
                path: 'temoignages-candidat',
                component: TemoignagesComponent,
            },
            {
                path: 'temoignages-recruteur',
                component: TemoignagesRecruteursComponent,
            },
            {
                path: 'faqs',
                component: FaqsComponent,
            },
            {
                path: 'desabonnements',
                component: DesabonnementComponent,
            },
            {
                path: 'commande-en-attente',
                component: ListeDiffusionEnAttenteComponent,
            },
            {
                path: 'commande-candidat/:id',
                component: ListeDiffusionCandidatComponent,
            },
            {
                path: 'commande-en-cours',
                component: ListeDiffusionAccepteComponent,
            },
            {
                path: 'commande-terminer',
                component: ListeDiffusionTerminerComponent,
            },
            {
                path: 'commande-en-refuser-temporairement',
                component: ListeDiffusionRefusComponent,
            },
            {
                path: 'commande-en-refuser-definitivement',
                component: ListeDiffusionRefuserDefinitivementComponent,
            },
            {
                path: 'detail-commande/:id',
                component: DetailDiffusionComponent,
            },
            {
                path: 'traducteur',
                component: TraducteurComponent,
            },
        ],
    },
    {
        path: 'espace-candidat',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'home',
                component: HomeCandidatComponent,
            },
            {
                path: 'avantages',
                component: AvantagesCandidatComponent,
            },
            {
                path: 'dashboard',
                component: DashboardCandidatComponent,
            },
            {
                path: 'panier/:id',
                component: PanierComponent,
            },
            {
                path: 'conseils',
                component: ConseilsComponent,
            },
            {
                path: 'offres',
                component: OffresComponent,
            },
            {
                path: 'informations-personnelles',
                component: InformationsPersonnellesCandidatsComponent,
            },
            {
                path: 'mes-selections',
                component: ListePanierComponent,
            },
            {
                path: 'mot-de-passe',
                component: MotDePasseComponent,
            },
            {
                path: 'informations',
                component: InformationsComponent,
            },
            {
                path: 'informations-complementaires',
                component: InformationsComplementairesComponent,
            },
            {
                path: 'autorisations',
                component: AutorisationsComponent,
            },
            {
                path: 'situation',
                component: SituationComponent,
            },
            {
                path: 'recherche',
                component: RechercheComponent,
            },
            {
                path: 'valider-panier/:id/:retour',
                component: ValiderPanierComponent,
            },
            {
                path: 'confirmation-doublons/:id',
                component: DoublonDiffusionComponent,
            },
            {
                path: 'commande-refuser/:id',
                component: RefusCommandeComponent,
            },
            {
                path: 'commande-accepter/:id',
                component: AccepterCommandeComponent,
            },
            {
                path: 'commande-accepter',
                component: AccepterCommandeComponent,
            },
            {
                path: 'commande-refuser',
                component: RefusCommandeComponent,
            },
            {
                path: 'mes-commandes',
                component: ListeCommandeComponent,
            },
            {
                path: 'commande/:id',
                component: DetailCommandeComponent,
            },
            {
                path: 'modifier-commande/:id',
                component: ModifierCommandeComponent,
            },
            {
                path: 'mes-factures',
                component: ListeFactureComponent,
            },
            {
                path: 'desabonnement-recruteur',
                component: ListeDesabonnementCandidatComponent,
            },
            {
                path: 'diffusion-non-souhaitee',
                component: DiffusionNonSouhaiteeComponent,
            },
            {
                path: 'mon-cv',
                component: MonCVComponent,
            },
        ],
    },
    {
        path: 'espace-recruteur',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'home',
                component: HomeRecruteurComponent,
            },
            {
                path: 'avantages',
                component: AvantagesRecruteurComponent,
            },
            {
                path: 'dashboard',
                component: DashboardRecruteurComponent,
            },
            {
                path: 'informations-personnelles',
                component: InformationsPersonnellesComponent,
            },
            {
                path: 'candidatures-recues',
                component: ListeCandidatureRecuComponent,
            },
            {
                path: 'cvtheque',
                component: CvThequeComponent,
            },
            {
                path: 'informations',
                component: InformationsRecruteurComponent,
            },
            {
                path: 'desabonnements',
                component: ListeDesabonnementComponent,
            },
            {
                path: 'mot-de-passe',
                component: MotDePasseRecruteurComponent,
            },
            {
                path: 'mon-organisme',
                component: MonOrganismeComponent,
            },
            {
                path: 'informations-complementaires',
                component: InformationsComplementairesRecruteursComponent,
            },
            {
                path: 'recherche',
                component: MaRechercheComponent,
            },
            {
                path: 'autorisations',
                component: AutorisationsRecruteursComponent,
            },
        ],
    },
    {
        path: '**',
        component: Erreur404Component,
    },
];

@NgModule({
    declarations: [
        AppComponent,
        AccueilComponent,
        FaqComponent,
        CguComponent,
        MentionsLegalesComponent,
        ContactComponent,
        SitemapComponent,
        InscriptionCandidatComponent,
        InscriptionRecruteurComponent,
        TarifComponent,
        ConseilComponent,
        DashboardComponent,
        DashboardCandidatComponent,
        DashboardRecruteurComponent,
        HomeCandidatComponent,
        HomeRecruteurComponent,
        ComptesAdministrateurComponent,
        ListeCompteRecruteurComponent,
        ListeCompteCandidatComponent,
        ListeMetiersComponent,
        ListeSecteursComponent,
        MetiersNonAssignesComponent,
        SecteursNonAssignesComponent,
        DrawerComponent,
        ListeDesabonnementCandidatComponent,
        ListeDiffusionEnAttenteComponent,
        ListeDiffusionRefusComponent,
        ListeDiffusionAccepteComponent,
        DetailDiffusionComponent,
        InformationsPersonnellesComponent,
        InformationsPersonnellesCandidatsComponent,
        ListeCandidatureRecuComponent,
        CvThequeComponent,
        ListeDesabonnementComponent,
        ListeCommandeComponent,
        DetailCommandeComponent,
        AvantagesRecruteurComponent,
        LoginModalComponent,
        AvantagesCandidatComponent,
        AvantagesComponent,
        ListeFactureComponent,
        CitySelectComponent,
        ListePanierComponent,
        RenouvellerMotDePasseComponent,
        Erreur404Component,
        AdminHeaderComponent,
        CodeReductionComponent,
        FaqsComponent,
        CompteCandidatComponent,
        CompteRecruteurComponent,
        SurveillancePhotoComponent,
        TarifsAdminComponent,
        DesabonnementComponent,
        RgpdComponent,
        EnvoiMailRenouvellementComponent,
        CandidatHeaderComponent,
        RecruteurHeaderComponent,
        SiteFooterComponent,
        SiteHeaderComponent,
        Desabo,
        DoublonDiffusionComponent,
        RefusCommandeComponent,
        AccepterCommandeComponent,
        AccueilCandidatComponent,
        AccueilRecruteurComponent,
        ConnexionAdministrateurComponent,
        EmailVerifierComponent,
        DesabonnementSiteComponent,
        LettreMotivationEditorComponent,
        ModifierMailComponent,
        ListeDiffusionRefuserDefinitivementComponent,
        ConnexionIntermediaireComponent,
        DiffusionNonSouhaiteeComponent,
        ModifierCommandeComponent,
        TemoignagesComponent,
        TemoignagesRecruteursComponent,
        TraducteurComponent,
        ListeDiffusionCandidatComponent,
        ConseilsComponent,
        RecruiterFormComponent,
        CandidaturesSpontaneesComponent,
        RegisterModalComponent,
        CandidatFormComponent,
        OfferFormComponent,
        ListeDiffusionTerminerComponent,
        TrackingComponent,
        ModifierTexteSiteComponent,
        SauvegardesComponent,
        ListeNewslettersComponent,
        LogSocialComponent,
        SignalementsComponent,
        LinkedinComponent,
        PanierComponent,
        MotDePasseComponent,
        InformationsComponent,
        SituationComponent,
        RechercheComponent,
        InformationsComplementairesComponent,
        AutorisationsComponent,
        ValiderPanierComponent,
        MotDePasseRecruteurComponent,
        InformationsRecruteurComponent,
        MonOrganismeComponent,
        InformationsComplementairesRecruteursComponent,
        MaRechercheComponent,
        AutorisationsRecruteursComponent,
        MonCVComponent,
        AboutRecruteurComponent,
        AboutCandidatComponent,
        WorkersNumberPipe,
        SecteurFilterComponent,
        ActivitiesPipe,
        ReportModalComponent,
        TypesEntreprisesPipe,
        ExperiencePipe,
        TimestampPipe,
        TeletravailPipe,
        EtudesPipe,
        TypesPostePipe,
        DisposPipe,
        CountrySelectComponent,
        AdressSelectComponent,
        PoleEmploiLocalisationSelectComponent,
        SituationPipe,
        RemunerationPipe,
        FirstUpperTextPipe,
        ArrayToLineTextPipe,
        SafeHtmlPipe,
        CvComponent,
        SecteurComponent,
        PosteComponent,
        DatepickerComponent,
        PluralPipe,
        OffresComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CarouselModule,
        RangeModule,
        DatepickerModule,
        CardsModule,
        DropdownModule.forRoot(),
        SmoothscrollModule.forRoot(),
        CheckboxModule,
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        MdbSelectModule,
        WavesModule,
        FileInputModule,
        ButtonsModule,
        CollapseModule,
        CharCounterModule,
        ChipsModule,
        InputsModule,
        ModalModule.forRoot(),
        AutoCompleterModule,
        TableModule,
        ToastModule.forRoot(),
        ClipboardModule,
        HttpClientModule,
        HttpClientJsonpModule,
        InfiniteScrollModule,
        CodeInputModule,
        HammerModule,
        CountUpModule,
        // for HttpClient use:
        LoadingBarHttpClientModule,
        // for Core use:
        LoadingBarModule,
        QuillModule.forRoot({
            customModules: [
                {
                    implementation: Counter,
                    path: 'modules/counter',
                },
            ],
        }),
        DragulaModule.forRoot(),

        // ToastModule.forRoot(),
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'top',
            anchorScrolling: 'enabled',
            useHash: false,
        }),
        FontAwesomeModule,
        FormsModule,
        GoogleMapsModule,
    ],
    exports: [RouterModule],
    providers: [
        GlobalService,
        authInterceptorProviders,
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        AuthService,
        AuthGuard,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy /*HashLocationStrategy*/,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
