import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-tarifs-admin',
    templateUrl: './tarifs-admin.component.html',
    styleUrls: [
        './tarifs-admin.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/jquery.datatables.min.css',
    ],
})
export class TarifsAdminComponent implements OnInit {
    tarifs = [
        {
            _id: '',
            prixUnitaireHt: 0,
            nombreDeCourrielMinimum: 0,
            pourQui: 'Candidat',
        },
    ];
    nouveauTarif = {
        prixUnitaireHt: 0,
        nombreDeCourrielMinimum: 0,
        pourQui: 'Candidat',
    };
    erreurAjout = false;
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.recuperer();
    }
    ajouter(): void {
        this.erreurAjout = false;
        if (this.nouveauTarif.prixUnitaireHt === 0 || this.nouveauTarif.nombreDeCourrielMinimum === 0) {
            this.erreurAjout = true;
        } else {
            this.httpClient.put(this.globalService.url + '/tarif', this.nouveauTarif).subscribe(
                (response: any) => {
                    if (response.status) {
                        this.recuperer();
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );

            this.nouveauTarif = {
                prixUnitaireHt: 0,
                nombreDeCourrielMinimum: 0,
                pourQui: 'Candidat',
            };
        }
    }
    modifier(id: string): void {
        for (let t of this.tarifs) {
            if (t._id === id) {
                this.httpClient.post(this.globalService.url + '/tarif/' + id, t).subscribe(
                    (response: any) => {
                        if (!response.status) {
                            alert('Erreur lors de la modification');
                        }
                    },
                    () => {}
                );
            }
        }
    }
    supprimer(id: string): void {
        this.httpClient.delete(this.globalService.url + '/tarif/' + id).subscribe(
            (response: any) => {
                if (response.status) {
                    let nouveauTarifs = [];
                    for (let t of this.tarifs) {
                        if (t._id !== id) {
                            nouveauTarifs.push(t);
                        }
                    }
                    this.tarifs = nouveauTarifs;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    recuperer(): void {
        this.httpClient.get<any>(this.globalService.url + '/tarif/Candidat').subscribe(
            (response) => {
                if (response.status) {
                    this.tarifs = response.liste;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
