import { Pipe, PipeTransform } from '@angular/core';
import { GlobalVariable } from '../global';

@Pipe({
    name: 'arrayToLineText',
})
export class ArrayToLineTextPipe implements PipeTransform {
    activitiesType = GlobalVariable.ACTIVITES;

    transform(value: [], arg1: boolean, arg2: string = ''): any {
        if (arg2 === 'activities') {
            // @ts-ignore
            value = value.map((item) => this.activitiesType.find((a: { value: any }) => a.value === item).label);
        }

        if (!arg1) {
            return value.join(', ').replace(/,([^,]*)$/, ' et$1');
        }
        // @ts-ignore
        const result = value.map((a) => a.nom);
        return result.join(', ').replace(/,([^,]*)$/, ' et$1');
    }
}
