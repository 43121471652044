<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10">
        <div>
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">Tableau de bord (Dernière mise à jour : {{ git }})</h3>
                </div>
                <div id="main-wrapper">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            Date de début
                            <input class="form-control" type="date" [(ngModel)]="dateDebut" (input)="genererData()" />
                        </div>
                        <div class="col-lg-6 col-md-6">
                            Date de fin
                            <input class="form-control" type="date" [(ngModel)]="dateFin" (input)="genererData()" />
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ uniqueCandidat }}</span>
                                        <p class="stats-info">Connexions uniques candidats</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ nombreConnexionCandidat }}</span>
                                        <p class="stats-info">Connexions totales</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ uniqueRecruteur }}</span>
                                        <p class="stats-info">Connexions uniques recruteurs</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ nombreConnexionRecruteur }}</span>
                                        <p class="stats-info">Connexions totales recruteurs</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ candidats + candidatsNonFinaliser }}</span>
                                        <p class="stats-info">Candidats totaux</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ candidatsNonFinaliser }}</span>
                                        <p class="stats-info">Inscriptions candidats non finalisées</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ candidats }}</span>
                                        <p class="stats-info">Candidats finalisés</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ recruteurs + recruteursNonFinaliser }}</span>
                                        <p class="stats-info">Recruteurs totaux</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ recruteursNonFinaliser }}</span>
                                        <p class="stats-info">Inscriptions recruteurs non finalisées</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ recruteurs }}</span>
                                        <p class="stats-info">Recruteurs finalisés</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ commandes }}</span>
                                        <p class="stats-info">Commandes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ ca }} &euro;</span>
                                        <p class="stats-info">CA</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ panierMoyen }} &euro;</span>
                                        <p class="stats-info">Panier moyen</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="panel panel-white stats-widget">
                                <div class="panel-body">
                                    <div class="pull-left">
                                        <span class="stats-number">{{ envoyer }}</span>
                                        <p class="stats-info">Candidatures envoyées</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
