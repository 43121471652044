import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCheckCircle, faTimes, faArrowLeft, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToastService } from '../../../components/mdb/pro/alerts';
import Utils from '../../../utils';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-mon-organisme-recruteurs',
    templateUrl: './mon-organisme.component.html',
    styleUrls: [
        './mon-organisme.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class MonOrganismeComponent implements OnInit {
    faFilePdf = faFilePdf;
    arrowLeft = faArrowLeft;
    faError = faTimes;
    faOk = faCheckCircle;

    searchPays = new Subject<string>();
    filteredOptions: Observable<any[]>;

    recruteur: any = {
        activite: {
            codeNaf: '',
            nom: '',
        },
        siret: '',
    };
    addrs: any = [];
    addr = {
        adresse: '',
        postcode: '',
        city: '',
    };
    errorPage = {
        status: false,
        message: '',
    };
    paysNonSelectionner = false;
    logoRecruteur: string | ArrayBuffer | null = '';
    listePays: any = [
        {
            code: '1',
            pays: 'france',
        },
    ];
    chercher = '';
    logoUpload = '1';
    error = {
        password: false,
        secteurEntreprise: false,
        activiteNom: false,
        passwordDeux: false,
        civilite: false,
        nom: false,
        prenom: false,
        fonctionRecruteur: false,
        informationComplementaireFonction: false,
        telephonePortable: false,
        telephoneFixe: false,
        email: false,
        emailPro: false,
        photo: false,
        siret: false,
        nomSociete: false,
        nomGroupe: false,
        adresse: false,
        complementAdresse: false,
        codePostal: false,
        ville: false,
        pays: false,
        numeroTva: false,
        codeNaf: false,
        nomActivite: false,
        ancienPassword: false,
        logoGroupe: false,
        presentationActivite: false,
        activiteTexte: false,
        typeEntreprise: false,
        nombreSalarie: false,
        activiteSpecifique: false,
        siteInternet: false,
        profilRechercher: false,
        metiersRechercher: false,
        secteurActivites: false,
    };
    url = '';
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private userService: UserService,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {
        this.filteredOptions = this.searchPays.pipe(
            startWith(''),
            map((value: string) => Utils.filter(value, this.listePays))
        );
    }

    ngOnInit(): void {
        this.recruteur._id = localStorage.getItem('id');
        this.url = this.globalService.url;
        this.getPays();
        this.getAccount();

        setInterval(() => {
            if (this.recruteur.siret.length === 14 && this.chercher !== this.recruteur.siret && this.recruteur.estFrancaise) {
                this.getSociete();
                this.chercher = this.recruteur.siret;
            }
            this.recruteur.siret = this.recruteur.siret.split(' ').join('');
            if (this.recruteur.paysCode === 'FR' || this.recruteur.paysCode === '') {
                this.recruteur.estFrancaise = true;
            } else {
                this.recruteur.estFrancaise = false;
            }
        }, 200);
    }
    getSociete(): void {
        this.httpClient.get<any>(this.globalService.url + '/recruteur/getBySiret/' + this.recruteur.siret).subscribe(
            (response) => {
                this.recruteur.nomSociete = response.etablissement.uniteLegale.denominationUniteLegale;
                this.recruteur.activite.codeNaf = response.etablissement.uniteLegale.activitePrincipaleUniteLegale.split('.').join('');
                this.getActiviteWithNaf();
                // this.recruteur.numeroTva = response.etablissement.unite_legale.numero_tva_intra;
                this.recruteur.adresse = `${response.etablissement.adresseEtablissement.numeroVoieEtablissement} ${response.etablissement.adresseEtablissement.typeVoieEtablissement} ${response.etablissement.adresseEtablissement.libelleVoieEtablissement}`;
                this.recruteur.codePostal = response.etablissement.adresseEtablissement.codePostalEtablissement;
                this.recruteur.ville = response.etablissement.adresseEtablissement.libelleCommuneEtablissement;
                this.recruteur.pays = 'France';
            },
            () => {}
        );
    }
    getActiviteWithNaf(): void {
        this.httpClient.get<any>(this.globalService.url + '/recruteur/getActivite/' + this.recruteur.siret, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.recruteur.activite.nom = response.activite;
                }
            },
            () => {}
        );
    }
    getPays(): void {
        this.httpClient.get<any>(this.globalService.url + '/pays/getPays').subscribe(
            (response) => {
                if (response.status) {
                    this.listePays = response.pays;
                    this.searchPays.next(''); // force refresh select
                }
            },
            () => {}
        );
    }
    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.recruteur = response.recruteur;
                    this.chercher = this.recruteur.siret;
                    if (this.recruteur.activite === null) {
                        this.recruteur.activite = {
                            codeNaf: '',
                            nom: '',
                        };
                    }
                    this.addr.adresse = this.recruteur.adresse;
                    this.addr.postcode = this.recruteur.codePostal;
                    this.addr.city = this.recruteur.ville;
                    this.recruteur.password = '';
                    this.logoRecruteur = this.globalService.url + '/images/entreprise/' + this.recruteur.logoGroupe;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    renouvellerErreur(): void {
        this.errorPage.status = false;
        this.error = {
            password: false,
            secteurEntreprise: false,
            activiteNom: false,
            passwordDeux: false,
            civilite: false,
            nom: false,
            prenom: false,
            fonctionRecruteur: false,
            informationComplementaireFonction: false,
            telephonePortable: false,
            telephoneFixe: false,
            email: false,
            emailPro: false,
            photo: false,
            siret: false,
            nomSociete: false,
            nomGroupe: false,
            adresse: false,
            complementAdresse: false,
            codePostal: false,
            ville: false,
            pays: false,
            numeroTva: false,
            codeNaf: false,
            nomActivite: false,
            ancienPassword: false,
            logoGroupe: false,
            presentationActivite: false,
            activiteTexte: false,
            typeEntreprise: false,
            nombreSalarie: false,
            activiteSpecifique: false,
            siteInternet: false,
            profilRechercher: false,
            metiersRechercher: false,
            secteurActivites: false,
        };
    }
    sendEntreprise(): void {
        this.renouvellerErreur();
        this.errorPage.status = false;
        let any = false;
        if (this.recruteur.siret === '') {
            this.error.siret = true;
            any = true;
        }
        if (this.recruteur.nomSociete === '') {
            this.error.nomSociete = true;
            any = true;
        }
        if (this.recruteur.adresse === '') {
            this.error.adresse = true;
            any = true;
        }
        if (this.recruteur.codePostal === '') {
            this.error.codePostal = true;
            any = true;
        }
        if (this.recruteur.ville === '') {
            this.error.ville = true;
            any = true;
        }
        if (this.recruteur.pays === '') {
            this.error.pays = true;
            any = true;
        }

        if (this.recruteur.activite.nom === '') {
            this.error.nomActivite = true;
        }
        if (!any) {
            this.httpClient
                .patch(
                    this.globalService.url + '/recruteur/entreprise',
                    {
                        id: this.recruteur._id,
                        siret: this.recruteur.siret,
                        nomSociete: this.recruteur.nomSociete,
                        nomGroupe: this.recruteur.nomGroupe,
                        adresse: this.recruteur.adresse,
                        complementAdresse: this.recruteur.complementAdresse,
                        codePostal: this.recruteur.codePostal,
                        ville: this.recruteur.ville,
                        pays: this.recruteur.pays,
                        paysCode: this.recruteur.paysCode,
                        latitude: this.recruteur.latitude,
                        longitude: this.recruteur.longitude,
                        numeroTva: this.recruteur.numeroTva,
                        activite: this.recruteur.activite,
                    },
                    {}
                )
                .subscribe(
                    (response: any) => {
                        if (!response.status) {
                            this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                        } else {
                            this.toastrService.info('Informations modifiées', '', { opacity: 1 });
                            this.getAccount();
                        }
                    },
                    () => {}
                );
        }
    }

    sendLogo(): void {
        let logo = '';
        if (this.recruteur.logoGroupe.split(';base64,').length > 1) {
            logo = this.recruteur.logoGroupe;
        }
        this.httpClient.patch(this.globalService.url + '/recruteur/logo', { id: this.recruteur._id, logo: logo }, {}).subscribe(
            (response: any) => {
                if (!response.status) {
                    alert('Erreur lors de la modification');
                    this.logoUpload = '';
                } else {
                    this.logoUpload = '';
                }
            },
            () => {
                this.logoUpload = '';
            }
        );
    }
    logo(f: any): void {
        this.logoUpload = '2';
        const file = f.target.files.item(0);
        const reader = new FileReader();
        const extensions = ['image/jpg', 'image/jpeg', 'image/png'];

        if (extensions.indexOf(file.type) > -1) {
            if (file.size <= 5000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.recruteur.logoGroupe = reader.result;
                    this.logoRecruteur = reader.result;
                    this.sendLogo();
                };
            } else {
                this.logoUpload = '';
                alert("L'image importée est supérieur à 5Mo. veuillez réduire sa taille");
            }
        } else {
            this.logoUpload = '';
            alert("Le document que vous venez d'importer n'est pas dans un format valide");
        }
    }
}
