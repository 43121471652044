import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCheckCircle, faTimes, faArrowLeft, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { GlobalVariable } from '../../../global';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-informations-complementaires-recruteurs',
    templateUrl: './informations-complementaires.component.html',
    styleUrls: [
        './informations-complementaires.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class InformationsComplementairesRecruteursComponent implements OnInit {
    faFilePdf = faFilePdf;
    arrowLeft = faArrowLeft;
    faError = faTimes;
    faOk = faCheckCircle;

    recherche = {
        metier: '',
        metierCache: '',
    };
    secteurs = [
        {
            nom: '',
            codeNaf: '',
        },
    ];
    error = {
        password: false,
        secteurEntreprise: false,
        activiteNom: false,
        passwordDeux: false,
        civilite: false,
        nom: false,
        prenom: false,
        fonctionRecruteur: false,
        informationComplementaireFonction: false,
        telephonePortable: false,
        telephoneFixe: false,
        email: false,
        emailPro: false,
        photo: false,
        siret: false,
        nomSociete: false,
        nomGroupe: false,
        adresse: false,
        complementAdresse: false,
        codePostal: false,
        ville: false,
        pays: false,
        numeroTva: false,
        codeNaf: false,
        nomActivite: false,
        ancienPassword: false,
        logoGroupe: false,
        presentationActivite: false,
        activiteTexte: false,
        typeEntreprise: false,
        nombreSalarie: false,
        activiteSpecifique: false,
        siteInternet: false,
        profilRechercher: false,
        metiersRechercher: false,
        secteurActivites: false,
    };
    recruteur: any = {};
    actSpecifique = {
        handicap: false,
        agence_interim: false,
        management_transition: false,
        outplacement: false,
        cabinet_recrutement: false,
        service_domicile: false,
    };
    url = '';
    typesEntreprises = GlobalVariable.TYPES_ENTREPRISES;
    activities = GlobalVariable.ACTIVITES;
    workersNumbers = GlobalVariable.WORKERS_NUMBERS;

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private userService: UserService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {
        this.recruteur._id = localStorage.getItem('id');
        this.url = this.globalService.url;

        this.getAccount();
    }
    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.recruteur = response.recruteur;
                    for (const t of this.recruteur.activiteSpecifique) {
                        if (t === 'handicap') {
                            this.actSpecifique.handicap = true;
                        } else if (t === 'agence_interim') {
                            this.actSpecifique.agence_interim = true;
                        } else if (t === 'management_transition') {
                            this.actSpecifique.management_transition = true;
                        } else if (t === 'outplacement') {
                            this.actSpecifique.outplacement = true;
                        } else if (t === 'cabinet_recrutement') {
                            this.actSpecifique.cabinet_recrutement = true;
                        } else if (t === 'service_domicile') {
                            this.actSpecifique.service_domicile = true;
                        }
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    removeSecteursEntreprise(codeNaf: string): void {
        const newSecteurs = [];
        for (const s of this.recruteur.secteurEntreprise) {
            if (s.codeNaf !== codeNaf) {
                newSecteurs.push(s);
            }
        }
        this.recruteur.secteurEntreprise = newSecteurs;
    }
    setActs(event: any): void {
        this.actSpecifique.handicap =
            this.actSpecifique.agence_interim =
            this.actSpecifique.management_transition =
            this.actSpecifique.outplacement =
            this.actSpecifique.cabinet_recrutement =
            this.actSpecifique.service_domicile =
                false;
        if (event.includes('handicap')) {
            this.actSpecifique.handicap = true;
        }
        if (event.includes('agence_interim')) {
            this.actSpecifique.agence_interim = true;
        }
        if (event.includes('management_transition')) {
            this.actSpecifique.management_transition = true;
        }
        if (event.includes('outplacement')) {
            this.actSpecifique.outplacement = true;
        }

        if (event.includes('cabinet_recrutement')) {
            this.actSpecifique.cabinet_recrutement = true;
        }
        if (event.includes('service_domicile')) {
            this.actSpecifique.service_domicile = true;
        }
    }
    renouvellerErreur(): void {
        this.error = {
            password: false,
            secteurEntreprise: false,
            activiteNom: false,
            passwordDeux: false,
            civilite: false,
            nom: false,
            prenom: false,
            fonctionRecruteur: false,
            informationComplementaireFonction: false,
            telephonePortable: false,
            telephoneFixe: false,
            email: false,
            emailPro: false,
            photo: false,
            siret: false,
            nomSociete: false,
            nomGroupe: false,
            adresse: false,
            complementAdresse: false,
            codePostal: false,
            ville: false,
            pays: false,
            numeroTva: false,
            codeNaf: false,
            nomActivite: false,
            ancienPassword: false,
            logoGroupe: false,
            presentationActivite: false,
            activiteTexte: false,
            typeEntreprise: false,
            nombreSalarie: false,
            activiteSpecifique: false,
            siteInternet: false,
            profilRechercher: false,
            metiersRechercher: false,
            secteurActivites: false,
        };
    }

    sendComplementEntreprise(): void {
        this.renouvellerErreur();
        let any = false;
        if (this.recruteur.secteurEntreprise === '' || this.recruteur.secteurEntreprise.length === 0) {
            this.error.secteurEntreprise = true;
            any = true;
        }
        // if (this.recruteur.activiteTexte === '') {
        //     this.error.activiteTexte = true;
        //     any = true;
        // }
        if (this.recruteur.typeEntreprise === '') {
            this.error.typeEntreprise = true;
            any = true;
        }
        if (this.recruteur.nombreSalarie === '') {
            this.error.nombreSalarie = true;
            any = true;
        }
        if (!any) {
            const newActSpecifique = [];
            if (this.actSpecifique.handicap) {
                newActSpecifique.push('handicap');
            }

            if (this.actSpecifique.agence_interim) {
                newActSpecifique.push('agence_interim');
            }

            if (this.actSpecifique.management_transition) {
                newActSpecifique.push('management_transition');
            }

            if (this.actSpecifique.outplacement) {
                newActSpecifique.push('outplacement');
            }

            if (this.actSpecifique.cabinet_recrutement) {
                newActSpecifique.push('cabinet_recrutement');
            }

            if (this.actSpecifique.service_domicile) {
                newActSpecifique.push('service_domicile');
            }
            this.httpClient
                .patch(
                    this.globalService.url + '/recruteur/entrepriseComplement',
                    {
                        id: this.recruteur._id,
                        activiteTexte: this.recruteur.activiteTexte,
                        typeEntreprise: this.recruteur.typeEntreprise,
                        nombreSalarie: this.recruteur.nombreSalarie,
                        activiteSpecifique: newActSpecifique,
                        siteInternet: this.recruteur.siteInternet,
                        secteurEntreprise: this.recruteur.secteurEntreprise,
                    },
                    {}
                )
                .subscribe(
                    (response: any) => {
                        if (!response.status) {
                            this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                        } else {
                            this.toastrService.info('Informations complémentaires modifiées', '', { opacity: 1 });
                            this.getAccount();
                        }
                    },
                    () => {}
                );
        }
    }
}
