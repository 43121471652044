import { AfterViewInit, Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faPaperPlane, faEnvelope, faUsers, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { GlobalVariable } from '../../../global';
import { CarouselComponent } from '../../../components/mdb/free/carousel';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-dashboard-recruteur',
    templateUrl: './dashboard.component.html',
    styleUrls: [
        './dashboard.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class DashboardRecruteurComponent implements OnInit, AfterViewInit {
    @ViewChild('carousel', { static: true }) carousel!: CarouselComponent;

    faPaperPlane = faPaperPlane;
    faEnvelope = faEnvelope;
    faCopy = faCopy;
    faCheck = faCheck;
    faUsers = faUsers;

    candUrl = '';
    urlCopied = false;
    devMode = false;

    id: string | null = '';
    candidatCvTheque = 0;
    candidatRechercheActive = 0;
    candidatureRecues = 0;

    index = 0;
    recruteurSlides = GlobalVariable.RECRUTEUR_DASHBOARD;
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private userService: UserService
    ) {}

    cards = [
        {
            title: '1',
            description: 'Vous vous inscrivez sur le site en remplissant les informations demandées.',
        },
        {
            title: '2',
            description: 'En cas d’accord de votre part, votre fiche recruteur sera soumise au référencement à Google.',
        },
        {
            title: '3',
            description:
                'Vous recevrez gratuitement par courriel les candidatures qui vous concernent et pourrez les retrouver dans votre CVthèque personnelle (Candidatures reçues).',
        },
        {
            title: '4',
            description: 'Vous pourrez accéder gratuitement à la CVthèque du site.',
        },
    ];

    slides: any = [[]];
    stop1 = false;

    chunk(arr: any, chunkSize: number) {
        const R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            R.push(arr.slice(i, i + chunkSize));
        }
        return R;
    }

    ngOnInit(): void {
        this.id = localStorage.getItem('id');
        this.getInfo();
        this.getAccount();
        this.slides = this.chunk(this.cards, 1);
        this.devMode = this.globalService.envType !== 'prod';
    }
    ngAfterViewInit(): void {
        setInterval(() => {
            if (!this.stop1) {
                this.index = (this.index + 1) % this.recruteurSlides.length;
            }
        }, 10000);
    }
    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.candUrl = `${this.globalService.domain}/candidatures-spontanees/${response.recruteur.referenceRecruteur.replace(
                        'RECRUT-',
                        ''
                    )}`;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    getInfo(): void {
        this.httpClient.get<any>(this.globalService.url + '/recruteur/dashboard/' + this.id, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.candidatCvTheque = response.candidatCvTheque ?? 0;
                    this.candidatRechercheActive = response.candidatRechercheActive ?? 0;
                    this.candidatureRecues = response.candidatureRecues ?? 0;
                }
            },
            () => {}
        );
    }
    next(): void {
        this.index = (this.index + 1) % this.recruteurSlides.length;
        this.stop1 = true;
    }
    prev(): void {
        this.index = (this.index - 1 + this.recruteurSlides.length) % this.recruteurSlides.length;
        this.stop1 = true;
    }
    @HostListener('swipeleft', ['$event'])
    swipePrev(event: any) {
        this.carousel.previousSlide();
    }

    @HostListener('swiperight', ['$event'])
    swipeNext(event: any) {
        this.carousel.nextSlide();
    }
}
