import { Component, OnInit } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-liste-diffusion-en-attente',
    templateUrl: './liste-diffusion-en-attente.component.html',
    styleUrls: ['./liste-diffusion-en-attente.component.css'],
})
export class ListeDiffusionEnAttenteComponent implements OnInit {
    faPdf = faFilePdf;
    commandes: any = [];
    numero = 0;
    url = '';
    motif = '';
    sousTotalTtc = 0;
    sousTotalHt = 0;
    totalTtc = 0;
    totalHt = 0;
    tva = 0;
    credit = 0;
    id = '';
    commande: any = {};
    candidat: any = {};
    creditUtiliser = 0;
    creditRestant = 0;
    retraitDesabo = 0;
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.url = this.globalService.url;
        this.getCommande(0);
    }
    getCommande(numero: number): void {
        this.httpClient
            .post<any>(
                this.globalService.url + '/commande/get-all',
                {
                    attente: true,
                    enCours: false,
                    terminer: false,
                    temporaire: false,
                    definitif: false,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.commandes = response.commandes;
                        if (this.commandes.length > 0) {
                            this.commandes[numero].prix.ttc = parseFloat(this.commandes[numero].prix.ttc);
                            this.getCandidat(this.commandes[numero].idCandidat, numero);
                        }
                    }
                },
                (err) => {
                    console.log(err);
                }
            );
    }
    accepter(id: string): void {
        this.httpClient.get<any>(this.globalService.url + '/commande/validate/' + id).subscribe(
            (response) => {
                if (response.status) {
                    this.getCommande(0);
                }
            },
            (err) => {}
        );
        this.httpClient.patch<any>(this.globalService.url + '/commande/confirmer-diffusion', { idCommande: id }, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.getCommande(0);
                }
            },
            (err) => {}
        );
    }
    refuserTemporairement(id: string): void {
        if (this.motif === '') {
            alert('Veuillez saisir un motif de refus');
        } else {
            this.httpClient
                .patch<any>(this.globalService.url + '/commande/reject/', { id: id, definitive: false, motif: this.motif }, {})
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this.getCommande(0);
                            this.motif = '';
                        }
                    },
                    (err) => {}
                );
        }
    }
    refuserDefinitivement(id: string): void {
        if (this.motif === '') {
            alert('Veuillez saisir un motif de refus');
        } else {
            this.httpClient
                .patch<any>(this.globalService.url + '/commande/reject/', { id: id, definitive: true, motif: this.motif }, {})
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this.getCommande(0);
                            for (let c of this.commandes) {
                                if (c._id === id) {
                                    this.bloquer(c.idCandidat);
                                    break;
                                }
                            }
                            this.motif = '';
                        }
                    },
                    (err) => {}
                );
        }
    }
    bloquer(id: string): void {
        this.httpClient.patch<any>(this.globalService.url + '/candidat/status', { id: id, status: false }, {}).subscribe(
            (response) => {},
            () => {}
        );
    }
    precedent(): void {
        let nouveauNumero = this.numero - 1;
        if (nouveauNumero < 0) {
            nouveauNumero = this.commandes.length - 1;
        }
        this.numero = nouveauNumero;
        this.getCommande(this.numero);
    }
    suivant(): void {
        let nouveauNumero = this.numero + 1;
        if (nouveauNumero >= this.commandes.length) {
            nouveauNumero = 0;
        }
        this.numero = nouveauNumero;
        this.getCommande(this.numero);
    }

    getCandidat(id: string, numero: number): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/' + id).subscribe(
            (response) => {
                if (response.status) {
                    this.candidat = response.candidat;
                    this.calculPrix(numero);
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    calculPrix(numero: number): void {
        this.retraitDesabo = this.commandes[numero].recruteursRetrait.length;
        this.sousTotalTtc = this.commandes[numero].prix.ttc * (this.commandes[numero].nombreCourriel - this.retraitDesabo);
        this.sousTotalTtc = parseFloat(this.sousTotalTtc.toFixed(2));
        this.sousTotalHt = parseFloat((this.sousTotalTtc / 1.2).toFixed(2));
        this.creditUtiliser = this.commandes[numero].credit;
        this.creditRestant = this.commandes[numero].creditRestant;
        this.totalTtc =
            this.commandes[numero].prix.ttc * (this.commandes[numero].nombreCourriel - this.retraitDesabo) -
            this.commandes[numero].reduction.montantTtc -
            this.creditUtiliser;
        this.totalTtc = parseFloat(this.totalTtc.toFixed(2));
        this.totalHt = parseFloat((this.totalTtc / 1.2).toFixed(2));
        this.tva = parseFloat((this.totalTtc - this.totalHt).toFixed(2));
        if (this.totalTtc > 0 && this.totalTtc <= 1) {
            this.totalTtc = 1;
            this.tva = 0.17;
        }
    }
}
