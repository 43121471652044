<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-color" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <div class="page-inner">
            <div class="page-title">
                <h3 class="breadcrumb-header">Recruteurs à ne pas contacter</h3>
            </div>
            <div class="page-sub-title">
                <h5 class="breadcrumb-sub">Gérez vos désabonnements</h5>
            </div>

            <p class="section_title">
                Vous ne souhaitez pas qu’une personne en particulier reçoive votre courriel ? Vous pouvez, par sécurité, retirer son adresse
                mail (ex : prenom.nom@societe.fr) et/ou retirer un nom de domaine (ex : @societedupont.fr) de vos listes de diffusion.
            </p>
            <div class="row mt-5 mb-5">
                <div class="col-12 col-lg-6">
                    <div class="custom-search">
                        <input
                            type="text"
                            class="form-control custom-search-input"
                            [(ngModel)]="nouveau"
                            placeholder="Email / Nom de domaine à retirer de l'envoi"
                        />
                        <button class="btn btn-danger custom-search-botton" (click)="ajout()">Ajouter</button>
                    </div>
                </div>
            </div>
            <table [ngStyle]="{ display: display() }" id="tableSort" mdbTable #tableEl="mdbTable" class="">
                <thead>
                    <tr>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="tableData" sortBy="text">
                            Email / domaine à retirer
                            <fa-icon [icon]="faSort"></fa-icon>
                        </th>
                        <th aria-controls="tableSortExample" scope="col">Retirer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let r of tableData; let i = index">
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            {{ r.text }}
                        </td>
                        <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                            <button
                                mdbBtn
                                color="danger"
                                [rounded]="true"
                                type="button"
                                class="m-0"
                                mdbWavesEffect
                                (click)="retirer(r.text)"
                            >
                                <fa-icon [icon]="faTimes"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot class="grey lighten-5 w-100">
                    <tr>
                        <td colspan="4">
                            <mdb-table-pagination ofKeyword="de" [tableEl]="tableEl" [searchDataSource]="retrait"></mdb-table-pagination>
                        </td>
                    </tr>
                    <tr>
                        <td [colSpan]="headElements.length" style="border-top: none !important; border-bottom: none !important">
                            <mdb-select-2
                                placeholder="Afficher 10/ page"
                                (selected)="changeItemsByPage($event)"
                                style="float: right; min-width: 174px"
                                name="itemsByPage"
                                [outline]="true"
                            >
                                <mdb-select-option *ngFor="let option of displayByPages" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
