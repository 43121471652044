import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-cv',
    templateUrl: './cv.component.html',
    styleUrls: ['./cv.component.css'],
})
export class CvComponent implements OnInit {
    constructor(private globalService: GlobalService, private httpClient: HttpClient) {}

    faFilePdf = faFilePdf;
    @Input() ancienCv: any;
    @Output() cvUpload: EventEmitter<any> = new EventEmitter();

    @Output() errorImport: EventEmitter<any> = new EventEmitter();

    @Input() cv: any;
    @Output() cvChange = new EventEmitter<string>();

    url = '';

    ngOnInit(): void {
        this.url = this.globalService.url;
    }
    uploadCv(f: any): void {
        this.cvUpload.emit('2');
        const file = f.target.files.item(0);
        const reader = new FileReader();
        if (file.type === 'application/pdf') {
            if (file.size <= 3000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.cv = reader.result;
                    this.errorImport.emit(false);
                    this.save();
                };
            } else {
                this.cvUpload.emit('');

                alert('Le document importé est supérieur à 3Mo. veuillez réduire sa taille');
                this.errorImport.emit(true);
            }
        } else {
            this.cvUpload.emit('');
            alert(
                "Le document que vous venez d'essayer d'importer n'est pas au format PDF. Vous pouvez uniquement importer un CV au format PDF."
            );
            this.errorImport.emit(false);
        }
    }
    save(): void {
        let cv = this.cv;
        this.httpClient
            .patch<any>(
                this.globalService.url + '/commande/lettre-motivation',
                {
                    // idCommande: this.id,
                    // sujet: this.sujet,
                    // email: this.emailDiffusion,
                    // lettreMotivation: this.lettreMotivation,
                    // cv: cv,
                    // modification: 2,
                },
                {}
            )
            .subscribe(
                () => {
                    this.cvUpload.emit('');
                    // this.getCommande();
                },
                () => {
                    this.cvUpload.emit('');
                }
            );
    }
}
