<app-modal-report [reportedId]='candidat._id' reportedRole='candidat' [modal]='candidatModal'
                  #reportModal></app-modal-report>
<div
  mdbModal
  #acModal='mdbModal'
  class='modal fade'
  tabindex='-1'
  role='dialog'
  aria-labelledby='myBasicModalLabel'
  aria-hidden='true'
  style='overflow: auto; z-index: 1051; overflow-x: hidden'
>
  <div class='modal-dialog modal-full-width' role='document'>
    <div class='modal-content'>
      <div class='modal-header'>
        <button type='button' class='close pull-right' aria-label='Close'
                (click)='acModal.hide();candidatModal.show();'>
          <span aria-hidden='true'>×</span>
        </button>
        <h4 class='modal-title w-100'>Atouts concurrentiels</h4>
      </div>
      <div class='modal-body' [innerHTML]='candidat.atoutConcurrenciel'>
      </div>
      <div class='modal-footer'>
        <button type='button' mdbBtn color='danger' class='waves-light' aria-label='Fermer'
                (click)='acModal.hide();candidatModal.show();' mdbWavesEffect>
          Fermer
        </button>
      </div>
    </div>
  </div>
</div>
<div
  mdbModal
  #lmModal='mdbModal'
  class='modal fade'
  tabindex='-1'
  role='dialog'
  aria-labelledby='myBasicModalLabel'
  aria-hidden='true'
  style='overflow: auto; z-index: 1051; overflow-x: hidden'
>
  <div class='modal-dialog modal-full-width' role='document'>
    <div class='modal-content'>
      <div class='modal-header'>
        <button type='button' class='close pull-right' aria-label='Close'
                (click)='lmModal.hide();candidatModal.show();'>
          <span aria-hidden='true'>×</span>
        </button>
        <h4 class='modal-title w-100'>Lettre de motivation</h4>
      </div>
      <div class='modal-body' [innerHTML]='lettreMotivation'>
      </div>
      <div class='modal-footer'>
        <button type='button' mdbBtn color='danger' class='waves-light' aria-label='Fermer'
                (click)='lmModal.hide();candidatModal.show();' mdbWavesEffect>
          Fermer
        </button>
      </div>
    </div>
  </div>
</div>
<div
  mdbModal
  #candidatModal='mdbModal'
  class='modal fade'
  tabindex='-1'
  role='dialog'
  aria-labelledby='myBasicModalLabel'
  aria-hidden='true'
  style='overflow: auto'
>
  <div class='modal-dialog modal-full-width' role='document' *ngIf="(candidat | json) != '{}'">
    <div class='modal-content'>
      <div class='modal-header'>
        <button type='button' class='close pull-right' aria-label='Close' (click)='candidatModal.hide()'>
          <span aria-hidden='true'>×</span>
        </button>
        <h4 class='modal-title w-100' id='myModalLabel'>Fiche candidat</h4>
      </div>
      <div class='modal-body'>
        <div>
          <div class='row'>
            <div class='col-4 col-md-2' style='z-index: 3'>
              <div class='candidat-picture'>
                <img
                  style='object-fit: cover; width: 100px; height: 100px'
                  class='img-preload rounded-circle img-fluid'
                  *ngIf="candidat.photoProfil && this.candidat.photo !== ''"
                  [src]='this.candidat.photo'
                />
              </div>
            </div>
            <div class='col-8 col-md-5'>
              <div class='center-text'>
                                                <span class='candidat-name'>
                                                    {{ candidat.nom }}
                                                  {{ candidat.prenom }} </span
                                                ><br />
                <span>
                                                    Adresse postale :
                  {{ candidat.ville }}
                  {{ candidat.codePostal ? '( ' + candidat.codePostal + ' )' : '' }}</span
                ><br />
                <span>
                                                    Pays de résidence :
                  {{ candidat.pays }}</span
                >
              </div>
            </div>
            <div class='col-12 mt-5 mt-md-0 col-md-5'>
              <div class='center-text' >
                <h3 class='d-none d-md-block font-weight-bold candidat-poste'>
                  Poste recherché : <br/> {{
                  candidat.posteRechercher && candidat.posteRechercher[0]
                    ? (candidat.posteRechercher[0].nom | firstUpperText)
                    : ''
                  }}
                </h3>
                <h3 class='d-sm-block d-md-none font-weight-bold candidat-poste'>
                  Poste recherché : {{
                  candidat.posteRechercher && candidat.posteRechercher[0]
                    ? (candidat.posteRechercher[0].nom | firstUpperText)
                    : ''
                  }}
                </h3>
              </div>
            </div>
            <div class='col-md-6 mt-5'>
              <h5>Ma recherche d'emploi</h5>
              <table class='display table dataTable custom-table'>
                <tr *ngIf='candidat.situation'>
                  <td>Etat</td>
                  <td style='text-align: right'>
                    {{ candidat.situation | situation }}
                  </td>
                </tr>
                <tr *ngIf='candidat.teleTravail'>
                  <td>Télétravail souhaité</td>
                  <td style='text-align: right'>
                    {{ candidat.teleTravail | teletravail }}
                  </td>
                </tr>
                <tr *ngIf='candidat.dispo && candidat.dispo.travail'>
                  <td>En poste ?</td>
                  <td style='text-align: right'>
                    <div *ngIf="candidat.dispo.travail == '1'">Oui</div>
                    <div *ngIf="candidat.dispo.travail == '2'">Non</div>
                  </td>
                </tr>
                <tr *ngIf='candidat.dispo && candidat.dispo.preavis'>
                  <td>Connait sa date de disponibilité ?</td>
                  <td style='text-align: right'>
                    <div *ngIf="candidat.dispo.preavis == '1'">Oui</div>
                    <div *ngIf="candidat.dispo.preavis != '1'">Non</div>
                  </td>
                </tr>
                <tr
                  *ngIf="candidat.dateDisponibilite && (candidat.dispo.travail == '2' || candidat.dispo.preavis == '1')">
                  <td>Disponible le</td>
                  <td style='text-align: right'>
                    <div>
                      {{ candidat.dateDisponibilite | date: 'dd-MM-yyyy' }}
                    </div>
                  </td>
                </tr>
                <tr
                  *ngIf="candidat.dispo && candidat.dispo.travail == '1' && candidat.dispo.preavis == '2' && candidat.disponibilite">
                  <td>Disponible (préavis)</td>
                  <td style='text-align: right'>
                    {{ candidat.disponibilite | dispos }}
                  </td>
                </tr>
                <tr *ngIf='candidat.posteRechercher && candidat.posteRechercher.length > 0'>
                  <td>Poste(s) souhaité(s)</td>
                  <td style='text-align: right'>
                    <div *ngFor='let p of candidat.posteRechercher'>
                      {{ p.nom }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf='candidat.secteurRechercher && candidat.secteurRechercher.length > 0'>
                  <td>Secteur(s) d'activité souhaité(s)</td>
                  <td style='text-align: right'>
                    <div *ngFor='let s of candidat.secteurRechercher'>
                      {{ s.nom }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf='candidat.typePoste && candidat.typePoste.length > 0'>
                  <td>Type(s) de poste recherché(s)</td>
                  <td style='text-align: right'>
                    <div *ngFor='let po of candidat.typePoste'>
                      {{ po | typesPoste }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf='candidat.typePosteVoulu.length > 0'>
                  <td>Type(s) de contrat recherché(s)</td>
                  <td style='text-align: right; text-transform: uppercase'>
                    <div *ngFor='let po of candidat.typePosteVoulu'>
                      {{ po }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf='candidat.temps.length > 0'>
                  <td>Temps</td>
                  <td style='text-align: right; text-transform: uppercase'>
                    <div *ngFor='let te of candidat.temps'>
                      {{ te }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf='candidat.ficheComplete'>
                  <td>Mobilité(s) locale(s)</td>
                  <td style='text-align: right' *ngIf='candidat.mobilite_locale.length > 0'>
                    <div *ngFor='let m of candidat.mobilite_locale'>
                      {{ m.ville }} - {{ m.pays }} : {{ m.rayon }} km
                    </div>
                  </td>
                  <td style='text-align: right' *ngIf='candidat.mobilite_locale.length <= 0'>
                    <div>Aucune mobilité locale</div>
                  </td>
                </tr>
                <tr *ngIf='candidat.ficheComplete'>
                  <td>Mobilité(s) nationale(s)</td>
                  <td style='text-align: right' *ngIf='candidat.mobilite_nationale.length > 0'>
                    <div *ngFor='let m of candidat.mobilite_nationale'>
                      {{ m }}
                    </div>
                  </td>
                  <td style='text-align: right' *ngIf='candidat.mobilite_nationale.length <= 0'>
                    <div>Aucune mobilité nationale</div>
                  </td>
                </tr>
                <tr *ngIf='candidat'>
                  <td>Mobilité internationale</td>
                  <td style='text-align: right'>
                    <div *ngIf='candidat.mobilite_internationale'>Oui</div>
                    <div *ngIf='!candidat.mobilite_internationale'>Non</div>
                  </td>
                </tr>
                <tr *ngIf='candidat.remunerationSouhaiter'>
                  <td>Rémunération souhaitée (brut)</td>
                  <td style='text-align: right'>
                    {{ candidat.remunerationSouhaiter | remuneration }}
                  </td>
                </tr>
                <tr *ngIf='candidat.atoutConcurrenciel'>
                  <td style='white-space: nowrap;width: auto'>Atouts concurrentiels</td>
                  <td style='text-align: right'>
                    <a (click)='acModal.show();candidatModal.hide();'
                    >
                      <fa-icon [icon]='faEye'></fa-icon
                      >
                      Voir</a>
                  </td>
                </tr>
              </table>
            </div>
            <div class='col-md-6 mt-5'>
              <h5>Mon profil</h5>
              <table class='display table dataTable custom-table'>
                <tr *ngIf='candidat.fonctionLibre'>
                  <td>Intitulé du poste actuellement ou dernièrement occupé</td>
                  <td style='text-align: right'>
                    {{ candidat.fonctionLibre }}
                  </td>
                </tr>
                <tr *ngIf='candidat.fonction'>
                  <td>Fonction s’en rapprochant le plus</td>
                  <td style='text-align: right'>
                    <div>
                      {{ candidat.fonction.nom }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf='candidat.experienceSecteur'>
                  <td>Expérience dans cette fonction</td>
                  <td style='text-align: right'>
                    {{ candidat.experienceSecteur | experience }}
                  </td>
                </tr>
                <tr *ngIf='candidat.secteurActivite.length > 0'>
                  <td>Secteur(s) d’activité actuel(s)</td>
                  <td style='text-align: right'>
                    <div *ngFor='let s of candidat.secteurActivite'>
                      {{ s.nom }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf='candidat.experience'>
                  <td>Expérience globale</td>
                  <td style='text-align: right'>
                    {{ candidat.experience | experience }}
                  </td>
                </tr>
                <tr *ngIf='candidat.niveauEtude'>
                  <td>Niveau d'étude</td>
                  <td style='text-align: right'>
                    {{ candidat.niveauEtude | etudes }}
                  </td>
                </tr>
                <tr *ngIf='candidat.langues.length > 0'>
                  <td>Langue(s)</td>
                  <td style='text-align: right'>
                    <div *ngFor='let l of candidat.langues'>
                      {{ l.langue }} : parlé {{ l.parle }}, écrit {{ l.ecrit }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf='candidat.permis.length > 0'>
                  <td>Permis de conduire</td>
                  <td style='text-align: right'>
                                        <span *ngFor='let p of candidat.permis' style='color: black !important'>
                                            {{ p }}
                                        </span>
                  </td>
                </tr>
                <tr *ngIf='candidat && candidat.etape > 2'>
                  <td>Est reconnu(e) en qualité de travailleur/euse handicapé(e)</td>
                  <td style='text-align: right'>
                    <div *ngIf='candidat.handicape'>Oui</div>
                    <div *ngIf='!candidat.handicape'>Non</div>
                  </td>
                </tr>
                <tr *ngIf='candidat.nationalites.length > 0'>
                  <td>Nationalité(s)</td>
                  <td style='text-align: right'>
                    <div *ngFor='let n of candidat.nationalites'>
                      {{ n }}
                    </div>
                  </td>
                </tr>
                <h5 *ngIf='candidat.cv || candidat.lettreMotivation' style='white-space: nowrap' class='mt-5'>Les
                  documents du candidat</h5>
                <tr *ngIf='candidat.cv'>
                  <td>CV</td>
                  <td style='text-align: right'>
                    <fa-icon *ngIf="cv != ''" [icon]='faFileContract'></fa-icon>
                    <a target='_blank' class='ml-2' href='{{ url }}/documents/cv/{{ cv }}'>Télécharger</a>
                  </td>
                </tr>
                <tr *ngIf='showLm && candidat.lettreMotivation'>
                  <td>Lettre de motivation</td>
                  <td style='text-align: right'>
                    <a *ngIf="lettreMotivation != ''" (click)='lmModal.show();candidatModal.hide();'
                    >
                      <fa-icon [icon]='faFileContract'></fa-icon
                      >
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class='modal-footer'>
        <button
          type='button'
          mdbBtn
          [rounded]='true'
          color='danger'
          class='waves-light'
          aria-label='Signaler'
          (click)='reportModal.openForm(); candidatModal.hide()'
          mdbWavesEffect
        >
          Signaler
        </button>
        <button
          type='button'
          mdbBtn
          [rounded]='true'
          color='warning'
          class='waves-light'
          aria-label='Fermer'
          (click)='candidatModal.hide()'
          mdbWavesEffect
        >
          Fermer
        </button>
      </div>
    </div>
  </div>
</div>
