import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Utils from '../../../utils';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../services/auth.service';
import { ToastService } from '../../mdb/pro/alerts';

@Component({
    selector: 'app-secteur',
    templateUrl: './secteur.component.html',
    styleUrls: ['./secteur.component.css', '../../../../assets/css/site/form.scss'],
})
export class SecteurComponent implements OnInit {
    searchSecteur = new Subject<string>();
    resultsSecteurs: Observable<any> | undefined;
    dataSecteurs: any = [];
    aideSecteur = false;
    requestSent = false;
    showRequest = false;
    groupe = '';
    sousGroupe = '';
    prefixe = '';
    listeGroupe: any = [];
    listeSousGroupe: any = [];
    listePrefixe: any = [];
    faFilePdf = faFilePdf;
    url = '';
    role = '';
    request = '';
    secteurs = [
        {
            name: '',
            options: [{ value: '', label: '', selected: false }],
        },
    ];

    @Input() showHelp = false;

    @Input() data: any;
    @Output() dataChange = new EventEmitter<any>();

    @Input() admin = false;
    @Input() idCompte = '';
    @Input() typeCompte = '';
    @Input() typeSecteur = '';

    constructor(
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private authService: AuthService,
        private toastrService: ToastService
    ) {
        // this.httpClient
        //     .post<any>(this.globalService.url + '/secteurs/groupe', { groupe: this.groupe, sousGroupe: this.sousGroupe }, {})
        //     .subscribe((data) => {
        //         this.dataSecteurs = data.liste.map((row: any) => {
        //             return row.sousGroupe;
        //         });
        //         this.resultsSecteurs = this.searchSecteur.pipe(
        //             startWith(''),
        //             map((value: string) => Utils.filterSecteur(value, this.dataSecteurs))
        //         );
        //     });
    }

    ngOnInit(): void {
        this.url = this.globalService.url;
        this.role = this.authService.role;
        this.getGroupe();
    }

    chooseSecteurs(nom: string, selectAll = false, selectAllValue = false): void {
        let exist = false;
        for (const s of this.data) {
            if (s.nom === nom) {
                exist = true;
                break;
            }
        }
        if (!exist) {
            this.data.push({ nom });
        } else if (!selectAll || (selectAll && !selectAllValue)) {
            const indexOfObject = this.data.findIndex((object: { nom: string }) => {
                return object.nom === nom;
            });
            this.data.splice(indexOfObject, 1);
        }
        if (this.admin) {
            this.updateSecteurs(this.data);
        }
    }
    removeSecteurs(nom: string): void {
        const newSecteurs = [];
        for (const s of this.data) {
            if (s.nom !== nom) {
                newSecteurs.push(s);
            }
        }
        this.dataChange.emit(newSecteurs);
        if (this.admin) {
            this.updateSecteurs(newSecteurs);
        }
    }
    getGroupe(): void {
        this.listeGroupe = [];
        this.listeSousGroupe = [];
        this.httpClient
            .post<any>(this.globalService.url + '/secteurs/groupe', { groupe: this.groupe, sousGroupe: this.sousGroupe }, {})
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.secteurs = [];
                        const categoriesDones = [];
                        for (const g of response.liste) {
                            if (categoriesDones.indexOf(g.groupe) !== -1) {
                                continue;
                            }
                            const categorie = g.groupe;
                            const sousCategories = [];

                            for (const g2 of response.liste) {
                                if (g2.groupe === categorie) {
                                    sousCategories.push({
                                        value: g2.sousGroupe,
                                        label: g2.sousGroupe,
                                        selected: false,
                                    });
                                }
                            }
                            sousCategories.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

                            let i = sousCategories.length;
                            while (i--) {
                                if (sousCategories[i].label.includes('- autre')) {
                                    // @ts-ignore
                                    const backup = sousCategories[i];
                                    sousCategories.splice(i, 1);
                                    sousCategories.push(backup);
                                }
                            }

                            this.secteurs.push({
                                // @ts-ignore
                                name: categorie,
                                // @ts-ignore
                                options: sousCategories,
                            });
                            categoriesDones.push(categorie);
                        }
                    }
                },
                () => {}
            );
    }
    // getPrefixe(): void {
    //     let listePrefixe: any[] = [];
    //     this.httpClient.get<any>(this.globalService.url + '/secteurs/prefixe/' + this.prefixe, {}).subscribe(
    //         (response) => {
    //             if (response.status) {
    //                 for (const l of response.liste) {
    //                     if (l.codeNaf.startsWith(this.prefixe)) {
    //                         const ns = {
    //                             nom: l.description,
    //                             codeNaf: l.codeNaf,
    //                         };
    //                         listePrefixe.push(ns);
    //                     }
    //                 }
    //                 this.listePrefixe = listePrefixe;
    //             }
    //         },
    //         () => {}
    //     );
    // }
    sendSecteurRequest(): void {
        this.httpClient
            .post(this.globalService.url + '/requests/add', {
                idCompte: this.authService.id,
                typeCompte: this.authService.role,
                request: this.request,
                extra: this.typeSecteur,
                type: 'secteur',
            })
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.toastrService.info('Demande envoyée', '', { opacity: 1 });
                        if (!this.requestSent) {
                            this.chooseSecteurs('Autre');
                            this.requestSent = true;
                        }
                        this.request = '';
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    updateSecteurs(secteurs: any): void {
        this.httpClient
            .post(
                this.globalService.url +
                    '/' +
                    this.typeCompte +
                    '/' +
                    (this.typeSecteur == 'Perso' || this.typeSecteur == 'Entreprise' ? 'updateSecteursActuel' : 'updateSecteursRecherches'),
                {
                    id: this.idCompte,
                    secteurs,
                }
            )
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.toastrService.info('Enregistrement réussi', '', { opacity: 1 });
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    selectAll(select: any, secteur: any): void {
        const result = this.secteurs.filter((obj: any) => {
            return obj.name === secteur.name;
        })[0];
        for (let act of result.options) {
            this.chooseSecteurs(act.value, true, select);
            act.selected = select;
        }
    }
}
