import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-desabonnement-site',
    templateUrl: './desabonnement-site.component.html',
    styleUrls: ['./desabonnement-site.component.css'],
})
export class DesabonnementSiteComponent implements OnInit {
    id = '';
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.id = this.route.snapshot.params.id;
        /*this.httpClient
      .patch(this.globalService.url + '/' + this.type + '/mail/' + this.ref).subscribe((response) => {}, () => {});*/
    }
}
