<!-- Newsletter Section HTML Start -->

<section id="Newsletter">
    <div class="newsletter-wrapper">
        <div class="container">
            <div class="newsletter">
                <h2>NEWSLETTER</h2>
                <h3>Inscrivez-vous pour recevoir nos bons plans, conseils et actualités</h3>
                <form>
                    <input name="newsletter" [(ngModel)]="emailNewsletters" type="email" placeholder="Adresse e-mail" />
                    <br />
                    <input (click)="addNewsletters()" class="newsletter-btn" type="submit" value="Envoyer" />
                </form>
            </div>
        </div>
    </div>
  <div class="mobile-footer text-center d-sm-none">
    <img src="./assets/img/logo.png" alt="mobile-footer" />
  </div>
</section>

<!-- Newsletter Section HTML End -->



<!-- Footer Section HTML Start -->

<footer id="Footer">
    <div class="footer-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-md-4 col-6">
                    <div class="footer-logo">
                        <img src="./assets/img/logo-hd.png" alt="logo" />
                    </div>
                </div>
                <div class="offset-lg-4 offset-md-0 offset-12 col-lg-2 col-md-3 col-6">
                    <div class="footer-links">
                        <ul class="p-0">
                            <li><a href="/">Accueil</a></li>
                            <li><a href="/candidat">Espace candidat</a></li>
                            <li><a href="/recruteur">Espace recruteur</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-6">
                    <div class="footer-links">
                        <ul class="p-0">
                            <li><a href="/nos-conseils">Nos conseils</a></li>
                            <li>
                                <a href="https://app.livestorm.co/avenuedesrecruteurs">Nos webinaires</a>
                            </li>
                            <li><a href="/nos-tarifs">Nos tarifs</a></li>
                            <li>
                                <a href="https://help.avenuedesrecruteurs.fr/fr/">FAQ</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-6">
                    <div class="footer-links">
                        <ul class="p-0">
                            <li>
                                <a href="/conditions-generales-d-utilisation-et-de-vente">CGUV</a>
                            </li>
                            <li>
                                <a href="/mentions-legales">Mentions légales</a>
                            </li>
                            <li>
                                <a href="/politique-de-confidentialite-et-d-utilisation-des-donnees-personnelles">Protection des données</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr class="divider" />
            <div class="footer-bottom d-flex justify-content-between align-items-center">
                <div class="footer-copy">
                    <p>&copy;2022</p>
                </div>
                <div class="footer-social">
                    <a href="https://www.facebook.com/profile.php?id=100086960710767"
                        ><fa-icon [icon]="faFacebook" class="fa-2x" style="color: #808080"></fa-icon
                    ></a>
                    <a href="https://www.linkedin.com/company/avenue-des-recruteurs/"
                        ><fa-icon [icon]="faLinkedin" class="fa-2x" style="color: #808080"></fa-icon
                    ></a>
                    <a href="#">
                        <fa-icon [icon]="faYoutube" class="fa-2x" style="color: #808080"></fa-icon
                    ></a>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- Footer Section HTML End -->
