import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-doublon-diffusion',
    templateUrl: './doublon-diffusion.component.html',
    styleUrls: ['./doublon-diffusion.component.css', './../../../../assets/css/inscription/style.css'],
})
export class DoublonDiffusionComponent implements OnInit {
    id = '';

    public arrowLeft = faArrowLeft;
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (this.route.snapshot.params.id !== undefined && this.route.snapshot.params.id !== null) {
            this.id = this.route.snapshot.params.id;
            this.getDoublons();
        }
    }
    getDoublons(): void {
        this.router.navigate(['/espace-candidat/creation-message/' + this.id]);
        /*this.httpClient
      .patch<any>(this.globalService.url + '/commande/nombre-doublons', {idCommande: this.id, idCandidat: localStorage.getItem('id')}, {}).subscribe((response) => {
        if (response.status) {
          if (response.nombreDoublons === 0) {
            this.router.navigate(['/espace-candidat/creation-message/' + this.id]);
          }
        }
      }, () => {
      });*/
    }
    suivant(choix: boolean): void {
        this.httpClient
            .patch<any>(this.globalService.url + '/commande/garder-doublons', { idCommande: this.id, garderDoublons: choix }, {})
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.router.navigate(['/espace-candidat/creation-message/' + this.id]);
                    }
                },
                () => {}
            );
    }
}
