<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-recruteur-header></app-recruteur-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-recruteur/informations-personnelles" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-recruteur/informations-personnelles" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span style="margin-right: 5rem">Mot de passe</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Mot de passe</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Modification du mot de passe</h2>
            </div>

            <div class="row">
                <div class="col-12 col-md-5">
                    <div class="form-group position-relative">
                        <label
                            for="ancienPassword"
                            [ngClass]="{
                                'fl-label-empty': this.ancienPassword === '',
                                'fl-label-not-empty': this.ancienPassword !== ''
                            }"
                            >Ancien mot de passe *</label
                        >
                        <span
                            for="ancienPassword"
                            class="error"
                            [ngStyle]="{
                                display: this.error.ancienPassword ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <input
                            [type]="type1"
                            name="ancienPassword"
                            id="ancienPassword"
                            class="form-control"
                            [(ngModel)]="this.ancienPassword"
                        /><fa-icon class="eye" (click)="eye(1)" [icon]="eye1"></fa-icon>
                    </div>
                    <div class="form-group position-relative">
                        <label
                            for="password"
                            [ngClass]="{
                                'fl-label-empty': this.recruteur.password === '',
                                'fl-label-not-empty': this.recruteur.password !== ''
                            }"
                            >Nouveau mot de passe *</label
                        >
                        <span
                            for="password"
                            class="error"
                            [ngStyle]="{
                                display: this.error.password ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <input
                            [type]="type2"
                            name="password"
                            id="password"
                            class="form-control"
                            [(ngModel)]="this.recruteur.password"
                        /><fa-icon class="eye" (click)="eye(2)" [icon]="eye2"></fa-icon>
                    </div>
                    <div class="form-group position-relative">
                        <label
                            for="passwordRenew"
                            [ngClass]="{
                                'fl-label-empty': this.passwordDeux === '',
                                'fl-label-not-empty': this.passwordDeux !== ''
                            }"
                            >Répéter le nouveau mot de passe *</label
                        >
                        <span
                            for="passwordRenew"
                            class="error"
                            [ngStyle]="{
                                display: this.error.passwordDeux ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <input
                            [type]="type3"
                            name="passwordRenew"
                            id="passwordRenew"
                            class="form-control"
                            [(ngModel)]="this.passwordDeux"
                        /><fa-icon class="eye" (click)="eye(3)" [icon]="eye3"></fa-icon>
                    </div>
                    <p
                        style="color: red !important"
                        [ngStyle]="{
                            display: this.errorPassword.status ? 'block' : 'none'
                        }"
                    >
                        {{ errorPassword.message }}
                    </p>
                    <div style="position: relative">
                        <p class="mention-obligatoire" style="color: red !important">* Obligatoire</p>
                    </div>
                    <div style="margin: 15px 0">
                        <div class="mention-text">
                            <div
                                class="err"
                                [ngStyle]="{
                                    color: analyse.longueur ? 'green' : 'red'
                                }"
                            >
                                <fa-icon [icon]="faOk" *ngIf="analyse.longueur"></fa-icon>
                                <fa-icon [icon]="faError" *ngIf="!analyse.longueur"></fa-icon>
                                Contient 10 caractères.
                            </div>
                            <div
                                class="err"
                                [ngStyle]="{
                                    color: analyse.miniscule ? 'green' : 'red'
                                }"
                            >
                                <fa-icon [icon]="faOk" *ngIf="analyse.miniscule"></fa-icon>
                                <fa-icon [icon]="faError" *ngIf="!analyse.miniscule"></fa-icon>
                                Contient au moins une minuscule.
                            </div>
                            <div
                                class="err"
                                [ngStyle]="{
                                    color: analyse.majuscule ? 'green' : 'red'
                                }"
                            >
                                <fa-icon [icon]="faOk" *ngIf="analyse.majuscule"></fa-icon>
                                <fa-icon [icon]="faError" *ngIf="!analyse.majuscule"></fa-icon>
                                Contient au moins une majuscule.
                            </div>
                            <div
                                class="err"
                                [ngStyle]="{
                                    color: analyse.chiffre ? 'green' : 'red'
                                }"
                            >
                                <fa-icon [icon]="faOk" *ngIf="analyse.chiffre"></fa-icon>
                                <fa-icon [icon]="faError" *ngIf="!analyse.chiffre"></fa-icon>
                                Contient au moins un chiffre.
                            </div>
                            <div
                                class="err"
                                [ngStyle]="{
                                    color: analyse.caractereSpeciale ? 'green' : 'red'
                                }"
                            >
                                <fa-icon [icon]="faOk" *ngIf="analyse.caractereSpeciale"></fa-icon>
                                <fa-icon [icon]="faError" *ngIf="!analyse.caractereSpeciale"></fa-icon>
                                Contient au moins un caractère spécial (@ # & " ' ; . , ? : \ / !).
                            </div>
                            <div
                                class="err"
                                [ngStyle]="{
                                    color: analyse.identique ? 'green' : 'red'
                                }"
                            >
                                <fa-icon [icon]="faOk" *ngIf="analyse.identique"></fa-icon>
                                <fa-icon [icon]="faError" *ngIf="!analyse.identique"></fa-icon>
                                Les deux mots de passe sont identiques.
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <button mdbBtn class="text-center" [rounded]="true" color="warning" size="lg" (click)="sendPassword()">
                            Valider
                        </button>
                    </div>
                </div>
                <div class="col-md-6 offset-md-1 d-none d-md-block">
                    <img class="mw-100" src="./assets/img/password.png" />
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
