import { Pipe, PipeTransform } from '@angular/core';
import { GlobalVariable } from '../global';

@Pipe({
    name: 'experience',
})
export class ExperiencePipe implements PipeTransform {
    experiences = GlobalVariable.EXPERIENCES;
    transform(value: unknown, ...args: unknown[]): unknown {
        const obj = this.experiences.find((a: { value: any }) => a.value === value);
        return obj ? obj.label : '';
    }
}
