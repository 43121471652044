import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TextService {
    constructor() {}
    firstUpperCase(value: string): string {
        if (!value) {
            return value;
        }
        return value[0].toUpperCase() + value.substr(1).toLowerCase();
    }
}
