<app-site-header></app-site-header>
<div class='row' style='width: 100%; margin: 0'>
  <div class='col-xl-2 col-xs-2 sidebar-custom' style='padding: 0'>
    <app-candidat-header></app-candidat-header>
  </div>
  <div class='main-page-layout col-xl-10 col-xs-10 pl-1 pr-0'>
    <section id='Sign-up'>
      <div class=''>
        <div class='sign-up-top'>
          <div class='form-group'>
            <a style='color: rgb(1, 73, 179) !important' (click)='retourFonction()'
            >
              <fa-icon [icon]='arrowLeft' style='margin-left: 15px; margin-right: 15px'></fa-icon>
              Retour</a
            >
          </div>
          <h2 *ngIf='etape === 0' class='section-title'>Validez le panier</h2>
          <h2 *ngIf='etape === 1' class='section-title'>A ne pas contacter</h2>
          <h2 *ngIf='etape === 2' class='section-title'>Ajoutez votre CV et votre LM</h2>
          <h2 *ngIf='etape === 3' class='section-title'>Choisir une date de diffusion</h2>
          <h2 *ngIf='etape === 4' class='section-title'>Validez et passez au paiement</h2>
          <div class='signup-top-wrapper'>
            <div class='steps-container text-center'>
              <div
                [ngStyle]="{
                                    display: (this.isMobile && this.etape === 0) || !this.isMobile ? 'inline-block' : 'none'
                                }"
                [ngClass]="this.etape === 0 ? 'active' : ''"
                class='steps'
              >
                                <span
                                ><h1>1</h1>
                                    <h6>Validez le panier</h6></span
                                >
              </div>
              <div
                [ngStyle]="{
                                    display: (this.isMobile && this.etape <= 1) || !this.isMobile ? 'inline-block' : 'none'
                                }"
                [ngClass]="this.etape === 1 ? 'active' : ''"
                class='steps'
              >
                                <span
                                ><h1>2</h1>
                                    <h6>A ne pas contacter</h6></span
                                >
              </div>
              <div
                [ngStyle]="{
                                    display:
                                        (this.isMobile && this.etape >= 1 && this.etape <= 2) || !this.isMobile ? 'inline-block' : 'none'
                                }"
                [ngClass]="this.etape === 2 ? 'active' : ''"
                class='steps'
              >
                                <span
                                ><h1>3</h1>
                                    <h6>
                                        Ajoutez votre <br />
                                        CV et votre LM
                                    </h6></span
                                >
              </div>
              <div
                [ngStyle]="{
                                    display:
                                        (this.isMobile && (this.etape == 2 || this.etape == 3 || this.etape == 4)) || !this.isMobile
                                            ? 'inline-block'
                                            : 'none'
                                }"
                [ngClass]="this.etape === 3 ? 'active' : ''"
                class='steps'
              >
                                <span
                                ><h1>4</h1>
                                    <h6>
                                        Choisir une date<br />
                                        de diffusion
                                    </h6></span
                                >
              </div>
              <div
                [ngStyle]="{
                                    display:
                                        (this.isMobile && (this.etape == 3 || this.etape == 4)) || !this.isMobile ? 'inline-block' : 'none'
                                }"
                [ngClass]="this.etape === 4 ? 'active' : ''"
                class='steps'
              >
                                <span
                                ><h1>5</h1>
                                    <h6>
                                        Validez et passez <br />
                                        au paiement
                                    </h6></span
                                >
              </div>
            </div>
          </div>

          <div
            id='step1'
            class='p-3'
            [ngStyle]="{
                            display: this.etape === 0 ? 'block' : 'none'
                        }"
          >
            <div class='row mt-3 mb-3 mr-0'>
              <div class='col d-flex justify-content-end'>
                <button mdbBtn type='button' (click)='nextStep()' color='warning' [rounded]='true' mdbWavesEffect>
                  Je valide mon panier<br />
                  et passe à l'étape suivante
                </button>
              </div>
            </div>
            <table
              [ngStyle]='{ display: display() }'
              id='tableSort'
              mdbTable
              #mdbTable='mdbTable'
              class='display custom-table mt-2 table-responsive'
            >
              <thead>
              <tr>
                <th
                  *ngFor='let head of headElements; let i = index'
                  aria-controls='tableSortExample'
                  scope='col'
                  [mdbTableSort]='recruteurs'
                  [sortBy]='headElements[i]'
                >
                  {{ head }}
                  <mdb-icon fas icon='sort'></mdb-icon>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor='let r of recruteurs; let i = index'>
                <td *ngIf='i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex'>
                  {{ r.referenceRecruteur }}
                </td>
                <td *ngIf='i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex'>
                  {{ r.codePostal }}
                </td>
                <td *ngIf='i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex'>
                  {{ r.typeEntreprise | typesEntreprises }}
                </td>
                <td *ngIf='i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex'>
                  {{ r.nombreSalarie | workersNumber }}
                </td>
                <td *ngIf='i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex'>
                  <div *ngFor='let a of r.activiteSpecifique'>
                    {{ a | activities }}
                  </div>
                </td>
                <td *ngIf='i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex'>
                  <div *ngFor="let s of r.secteurEntreprise; let index = index; let isOdd = odd">
                    <span *ngIf="index <= 1">{{ s.nom }}</span>
                    <span *ngIf="isOdd && index == 3">...</span>
                  </div>
                </td>
              </tr>
              </tbody>
              <tfoot class='grey lighten-5 w-100'>
              <tr>
                <td [colSpan]='headElements.length'>
                  <mdb-table-pagination
                    #mdbTablePagination
                    ofKeyword='de'
                    [tableEl]='mdbTable'
                    [searchDataSource]='recruteurs'
                  ></mdb-table-pagination>
                </td>
              </tr>
              <tr>
                <td [colSpan]='headElements.length' style='border-top: none !important; border-bottom: none !important'>
                  <mdb-select-2
                    placeholder='Afficher 10/ page'
                    (selected)='changeItemsByPage($event)'
                    style='float: right; min-width: 174px'
                    name='itemsByPage'
                    [outline]='true'
                  >
                    <mdb-select-option *ngFor='let option of displayByPages' [value]='option.value'>{{
                      option.label
                      }}</mdb-select-option>
                  </mdb-select-2>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>

          <div
            id='step2'
            class='mt-5 p-3'
            [ngStyle]="{
                            display: this.etape === 1 ? 'block' : 'none'
                        }"
          >
            <div>
              <p class='font-weight-bold'>A ne pas contacter</p>

              <p>Peut-être souhaitez vous exclure des recruteurs de votre liste de diffusion.</p>

              <p>
                Si vous ne souhaitez pas qu'une personne ou un organisme reçoive ce courriel, il vous suffit d'ajouter
                son
                email ou nom de domaine ci-dessous. Il sera automatiquement supprimé de votre liste de diffusion.
              </p>

              <p>
                Vous pouvez également gérer vos désabonnements recruteur dans l’onglet « Recruteurs à ne pas contacter »
                de
                votre espace candidat.
              </p>
            </div>

            <div class='row pt-4 pb-4'>
              <div class='col-12 col-md-6'>
                <div class='custom-search'>
                  <input
                    type='text'
                    class='form-control custom-search-input'
                    [(ngModel)]='nouveau'
                    placeholder="Email / Nom de domaine à retirer de l'envoi"
                  />
                  <button class='btn btn-pink custom-search-botton' (click)='ajout()'>Ajouter</button>
                </div>
              </div>
              <div
                class='col-12 col-md-6'
                [ngClass]="{
                                    'd-flex justify-content-center pt-3': isMobile
                                }"
              >
                <button
                  mdbBtn
                  [rounded]='true'
                  [outline]='true'
                  style='border: 1px solid #e4a700 !important; color: #e4a700 !important'
                  color='warning'
                  size='lg'
                  (click)='precedent()'
                >
                  Précédent
                </button>
                <button (click)='nextStep()' size='lg' mdbBtn type='button' color='warning' [rounded]='true'
                        mdbWavesEffect>
                  Continuer
                </button>
              </div>
            </div>

            <table
              [ngStyle]='{ display: displayNoContact() }'
              id='tableSortContact'
              mdbTable
              #mdbTableNoContact='mdbTable'
              class='display custom-table mt-4 mb-4'
            >
              <thead>
              <tr>
                <th
                  *ngFor='let head of headElementsNoContact; let i = index'
                  aria-controls='tableSortContact'
                  scope='col'
                  [mdbTableSort]='retrait'
                  [sortBy]='headElementsNoContact[i]'
                >
                  {{ head }}
                  <mdb-icon fas icon='sort'></mdb-icon>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor='let r of retrait; let i = index'>
                <td
                  *ngIf='
                                            i + 1 >= mdbTablePaginationNoContact.firstItemIndex &&
                                            i < mdbTablePaginationNoContact.lastItemIndex
                                        '
                >
                  {{ r }}
                </td>
                <td
                  *ngIf='
                                            i + 1 >= mdbTablePaginationNoContact.firstItemIndex &&
                                            i < mdbTablePaginationNoContact.lastItemIndex
                                        '
                >
                  <button
                    mdbBtn
                    color='danger'
                    [rounded]='true'
                    type='button'
                    class='m-0'
                    mdbWavesEffect
                    (click)='retirer(r)'
                  >
                    <fa-icon [icon]='faTimes'></fa-icon>
                  </button>
                </td>
              </tr>
              </tbody>
              <tfoot class='grey lighten-5 w-100'>
              <tr>
                <td [colSpan]='headElementsNoContact.length'>
                  <mdb-table-pagination
                    #mdbTablePaginationNoContact
                    ofKeyword='de'
                    [tableEl]='mdbTableNoContact'
                    [searchDataSource]='retrait'
                  ></mdb-table-pagination>
                </td>
              </tr>
              <tr>
                <td
                  [colSpan]='headElementsNoContact.length'
                  style='border-top: none !important; border-bottom: none !important'
                >
                  <mdb-select-2
                    placeholder='Afficher 10/ page'
                    (selected)='changeItemsByPageNoContact($event)'
                    style='float: right; min-width: 174px'
                    name='itemsByPage'
                    [outline]='true'
                  >
                    <mdb-select-option *ngFor='let option of displayByPages' [value]='option.value'>{{
                      option.label
                      }}</mdb-select-option>
                  </mdb-select-2>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>

          <div
            id='step3'
            class='mt-5 p-3'
            [ngStyle]="{
                            display: this.etape === 2 ? 'block' : 'none'
                        }"
          >
            <div class='pb-2'>
              <p class='font-weight-bold'>
                Attention : après validation de cette étape, la commande ne pourra plus être modifiée.
              </p>

              <p>
                Pensez à contrôler vos documents. Si vous avez déjà ajouté des documents dans votre espace personnel (CV
                et/ou Lettre de motivation), ils seront repris ci-dessous pour l’envoi aux recruteurs. Vous pouvez les
                modifier ici, mais aussi rédiger une nouvelle lettre de motivation.
              </p>

              <p>
                                <span class='font-weight-bold'>Nom de l'expéditeur : </span
                                ><span style='color: red'>{{ nomCandidat }}</span>
              </p>
            </div>

            <div class='form-group col-12 col-md-6 p-0'>
              <label for='mail-title' class='font-weight-bold'>Objet du mail *</label>
              <span
                for='mail-title'
                class='error'
                [ngStyle]="{
                                    display: this.errorValidation.objet ? 'block' : 'none'
                                }"
              >Obligatoire</span
              >
              <input
                type='text'
                name='mail-title'
                class='form-control'
                [(ngModel)]='sujet'
                placeholder='Exemple : candidature spontanée'
              />
            </div>
            <div class='form-group col-12 col-md-6 p-0'>
              <label for='candidat-mail' class='font-weight-bold'>Votre adresse mail *</label>
              <span
                for='candidat-mail'
                class='error'
                [ngStyle]="{
                                    display: this.errorValidation.mail ? 'block' : 'none'
                                }"
              >Obligatoire</span
              >
              <input name='candidat-mail' type='text' class='form-control' [(ngModel)]='emailDiffusion' />
            </div>

            <div class='form-group pt-3 pb-3'>
              <div class='d-flex flex-wrap align-items-center'>
                <div class='col-4 col-md-2 pl-0'>
                                    <span
                                      for='cv'
                                      class='error'
                                      [ngStyle]="{
                                            display: this.errorValidation.cv ? 'block' : 'none'
                                        }"
                                    >Obligatoire</span
                                    >

                  <span class='font-weight-bold'>Mon CV (.pdf) *</span>
                </div>
                <div class='col-8 col-md-3'>
                  <div *ngIf="ancienCv !== ''">
                    <a target='_blank' href='{{ url }}/documents/cv/{{ ancienCv }}'
                    ><small>{{ ancienCv }}</small>
                      <fa-icon class='ml-2' [icon]='faFilePdf'></fa-icon
                      >
                    </a>
                  </div>
                </div>
                <div class='col-12 col-md-2 mt-3 mt-md-0'>
                  <div class='file-field ml-3'>
                    <div mdbBtn [rounded]='true' [outline]='false' color='secondary'>
                      <span>{{ ancienCv !== '' ? 'Modifier' : 'Ajouter' }}</span>
                      <input type='file' mdbFileSelect (change)='uploadCv($event)' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <app-lettre-motivation-editor [required]='true' [(lettreMotivation)]='lettreMotivation'></app-lettre-motivation-editor>

            <div class='form-group col-12 col-md-10 p-0 pt-3 pb-3'>
              <mdb-select-2 [outline]='true' [(ngModel)]='modifier'>
                <mdb-select-option
                  style='height: 100%; white-space: initial; padding-top: 10px; padding-bottom: 10px'
                  [value]='1'
                >Je modifie mon CV et ma lettre de motivation aussi dans ma fiche candidat pour mes prochaines
                  commandes
                </mdb-select-option
                >
                <mdb-select-option
                  style='height: 100%; white-space: initial; padding-top: 10px; padding-bottom: 10px'
                  [value]='2'
                >Je ne modifie pas mon CV et ma lettre de motivation aussi dans ma fiche candidat pour mes prochaines
                  commandes
                </mdb-select-option
                >
              </mdb-select-2>
            </div>

            <div style='color: red; text-align: center' *ngIf='error'>
              {{ erreurTexte }}
            </div>
            <div class='form-group d-flex justify-content-center p-2'>
              <p *ngIf="cvUpload == '2'">
                <img src='./assets/img/gif.gif' style='max-width: 25px' />
                CV en cours de sauvegarde, veuillez patienter
              </p>

              <button
                mdbBtn
                [rounded]='true'
                [outline]='true'
                style='border: 1px solid #e4a700 !important; color: #e4a700 !important'
                color='warning'
                size='lg'
                class='mt-4'
                (click)='precedent()'
              >
                Précédent
              </button>
              <button (click)='suivant()' mdbBtn [rounded]='true' color='warning' size='lg' class='mt-4'>Suivant
              </button>
            </div>
          </div>

          <div id='step4' class='mt-5 p-3' *ngIf='this.etape === 3'>
            <div>
              <p class='font-weight-bold'>Quand souhaitez-vous que la diffusion de votre candidature commence ?</p>

              <p>
                Attention : cette date est donnée à titre indicatif, l'envoi pouvant se dérouler plus tard que la date
                choisie en cas d’acceptation tardive d'un webmaster.
              </p>
            </div>

            <div class='form-group col-12 col-md-6 p-0'>
              <mdb-select-2 placeholder='Dès que possible' [outline]='true' [(ngModel)]='date'>
                <mdb-select-option *ngFor='let t of tableauDate' value='{{ t.date }}'>
                  {{ t.texte }}
                </mdb-select-option>
              </mdb-select-2>
            </div>
            <div class='form-group' *ngIf='date != 0'>A partir de quelle heure ?</div>
            <div class='form-group col-12 col-md-6 p-0' *ngIf='date != 0'>
              <mdb-select-2 placeholder='Choisir..' [outline]='true' [(ngModel)]='heure'>
                <mdb-select-option value='0'>Sans préférence</mdb-select-option>
                <mdb-select-option value='6'>6H</mdb-select-option>
                <mdb-select-option value='7'>7H</mdb-select-option>
                <mdb-select-option value='8'>8H</mdb-select-option>
                <mdb-select-option value='9'>9H</mdb-select-option>
                <mdb-select-option value='10'>10H</mdb-select-option>
                <mdb-select-option value='11'>11H</mdb-select-option>
                <mdb-select-option value='12'>12H</mdb-select-option>
                <mdb-select-option value='13'>13H</mdb-select-option>
                <mdb-select-option value='14'>14H</mdb-select-option>
                <mdb-select-option value='15'>15H</mdb-select-option>
                <mdb-select-option value='16'>16H</mdb-select-option>
                <mdb-select-option value='17'>17H</mdb-select-option>
                <mdb-select-option value='18'>18H</mdb-select-option>
                <mdb-select-option value='19'>19H</mdb-select-option>
                <mdb-select-option value='20'>20H</mdb-select-option>
                <mdb-select-option value='21'>21H</mdb-select-option>
                <mdb-select-option value='22'>22H</mdb-select-option>
                <mdb-select-option value='23'>23H</mdb-select-option>
              </mdb-select-2>
            </div>

            <div class='form-group d-flex justify-content-center p-2'>
              <button
                mdbBtn
                [rounded]='true'
                [outline]='true'
                color='warning'
                size='lg'
                class='mt-4'
                style='border: 1px solid #e4a700 !important; color: #e4a700 !important'
                (click)='precedent()'
              >
                Précédent
              </button>
              <button (click)='suivantDate()' mdbBtn [rounded]='true' color='warning' size='lg' class='mt-4'>Suivant
              </button>
            </div>
          </div>

          <div
            id='step5'
            class='mt-3 p-3'
            [ngStyle]="{
                            display: this.etape === 4 ? 'block' : 'none'
                        }"
          >
            <div
              mdbModal
              #paypalModal='mdbModal'
              class='modal fade left modal-scrolling'
              id='frameModalTop'
              tabindex='-1'
              role='dialog'
              aria-labelledby='myModalLabel'
              aria-hidden='true'
              [config]='{
                                backdrop: false,
                                ignoreBackdropClick: true
                            }'
            >
              <div class='modal-dialog modal-side modal-bottom-left modal-notify modal-info' role='document'>
                <!--Content-->
                <div class='modal-content'>
                  <!--Header-->
                  <div
                    class='modal-header text-center justify-content-center'
                    style='background-color: #84c4b7 !important'
                  >
                    <p class='heading'>Paiement Paypal</p>
                    <button
                      type='button'
                      class='close'
                      data-dismiss='modal'
                      aria-label='Close'
                      (click)='paypalModal.hide()'
                    >
                      <span aria-hidden='true' class='white-text'>&times;</span>
                    </button>
                  </div>
                  <!--Body-->
                  <div class='modal-body'>
                    <div class='row'>
                      <div class='col-12 text-center'>
                        <div id='paypal-button'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--/.Content-->
              </div>
            </div>

            <div>
              <p class='font-weight-bold text-center'>Procédez au paiement de votre commande</p>

              <p class='text-center'>Vérifiez votre commande avant de procéder au paiement de cette dernière.</p>
            </div>

            <div class='row mt-5'>
              <div class='col-12 col-md-6 mb-3'>
                <table style='background-color: #d9f0f8; border-radius: 50px' class='display table dataTable'>
                  <tr>
                    <td colspan='2' style='font-weight: bold !important; font-size: 20px' class='text-center'>
                      VOTRE COMMANDE
                    </td>
                  </tr>
                  <tr>
                    <td>Nombre de candidatures dans le panier</td>
                    <td style='text-align: right; color: #e4a700'>
                      {{ commande.nombreCourriel }}
                    </td>
                  </tr>
                  <tr>
                    <td>Nombre de candidatures retirées</td>
                    <td style='text-align: right; color: #e4a700'>
                      {{ retraitDesabo }}
                    </td>
                  </tr>
                  <tr>
                    <td>Nombre de candidatures à envoyer</td>
                    <td style='text-align: right; color: #e4a700'>
                      {{ commande.nombreCourriel - retraitDesabo }}
                    </td>
                  </tr>
                  <tr>
                    <td>Prix unitaire TTC</td>
                    <td style='text-align: right; color: #e4a700' *ngIf='commande.prix'>{{ commande.prix.ttc }} €</td>
                  </tr>
                  <tr>
                    <td>Sous-total TTC</td>
                    <td style='text-align: right; color: #e4a700'>{{ sousTotalTtc }} €</td>
                  </tr>
                  <tr>
                    <td>Code de réduction</td>
                    <td *ngIf='commande.reduction' style='text-align: right; color: #e4a700'>
                      <div *ngIf="commande.reduction.nomCode === ''">Aucun</div>
                      <div *ngIf="commande.reduction.nomCode !== ''" style='position: relative'>
                        <div class='container-cross' (click)='removeCode()'>
                          <div class='cross'>x</div>
                        </div>
                        <div style='margin-right: 15px'>
                          {{ commande.reduction.nomCode }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Montant de la réduction TTC</td>
                    <td *ngIf='commande.reduction' style='text-align: right; color: #e4a700'>
                      <div *ngIf="commande.reduction.nomCode === ''">0 €</div>
                      <div *ngIf="commande.reduction.nomCode !== ''">
                        {{ commande.reduction.montantTtc.toFixed(2) }}
                        €
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Crédit TTC utilisé</td>
                    <td style='text-align: right; color: #e4a700'>{{ creditUtiliser }} €</td>
                  </tr>
                  <tr>
                    <td>MONTANT &Agrave; PAYER TTC</td>
                    <td style='text-align: right; color: #e4a700'>
                      <div *ngIf='totalTtc <= 0'>Gratuit</div>
                      <div *ngIf='totalTtc > 0'>{{ totalTtc }} €</div>
                    </td>
                  </tr>
                  <tr>
                    <td>(dont TVA 20%)</td>
                    <td style='text-align: right; color: #e4a700'>
                      <div *ngIf='totalTtc <= 0'>Pas de TVA</div>
                      <div *ngIf='totalTtc > 0'>{{ tva }} €</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Crédit TTC restant après commande</td>
                    <td style='text-align: right; color: #e4a700'>{{ creditRestant }} €</td>
                  </tr>
                  <tr>
                    <td colspan='2'>
                      <div class='custom-search'>
                        <input
                          type='text'
                          class='form-control custom-search-input'
                          [(ngModel)]='nomCode'
                          placeholder='Code de réduction'
                        />
                        <button class='btn btn-pink custom-search-botton' (click)='testerCode()'>Ajouter</button>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf='errorValidation.code'>
                    <td colspan='2' style='color: red; text-align: center'>
                      Le code saisi n'est pas bon ou n'est pas utilisable
                    </td>
                  </tr>
                </table>
              </div>

              <div class='col-12 col-md-6'>
                           <span style='font-weight: bold !important; font-size: 20px; margin-bottom: 4rem' class='text-center d-block mt-3'>
                                VOTRE PAIEMENT
                              </span>
                <div class='row'>
                  <div
                    [hidden]='totalTtc <= 0'
                    (click)="paymentMethod = 'card'"
                    class='col-12 col-md-6'
                    style='margin-bottom: auto; margin-top: auto'
                  >

                    <label for='cb' class='method card'>
                      <div class='card-logos'>
                        <img src='./assets/img/visa_logo.png' />
                        <img src='./assets/img/mastercard_logo.png' />
                      </div>

                      <div class='radio-input'>
                        <div class='form-check'>
                          <input
                            [(ngModel)]='paymentMethod'
                            id='cb'
                            name='cb'
                            type='radio'
                            class='form-check-input'
                            value='cb'
                            mdbInput
                          />
                          <label class='form-check-label' for='cb'>
                            Payer
                            {{ totalTtc <= 0 ? 'gratuit' : totalTtc + ' €' }}
                            par carte bancaire</label
                          >
                        </div>
                      </div>
                    </label>
                  </div>
                  <div
                    [hidden]='totalTtc <= 0'
                    (click)="paymentMethod = 'paypal'"
                    class='col-12 col-md-6'
                    style='margin-bottom: auto; margin-top: auto'
                  >
                    <label for='paypal' class='method paypal'>
                      <img src='./assets/img/paypal_logo.png' />
                      <div class='radio-input'>
                        <div class='form-check'>
                          <input
                            [(ngModel)]='paymentMethod'
                            id='paypal'
                            name='paypal'
                            type='radio'
                            class='form-check-input'
                            value='paypal'
                            mdbInput
                          />
                          <label class='form-check-label' for='paypal'>
                            Payer
                            {{ totalTtc <= 0 ? 'gratuit' : totalTtc + ' €' }}
                            par Paypal</label
                          >
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div class='p-2 p-md-5' style='margin-top: 2rem'>
                  <mdb-checkbox  [(ngModel)]='acceptation'
                  >Je demande le traitement immédiat de ma commande afin que mon CV puisse être envoyé à la date
                    souhaitée (si possible)
                  </mdb-checkbox
                  >
                </div>
                <div class='p-2 p-md-5' style='margin-top: 1.5rem'>
                  <div style='color: red' *ngIf='errorValidation.acceptation'>
                    Vous devez accepter le traitement immédiat de votre commande
                  </div>
                  <div style='color: red' *ngIf='errorValidation.paymentMethod'>
                    Vous devez selectionner un mode de paiement.
                  </div>
                  <div *ngIf='!isMobile' style='background-color: #f8f8f8' class='p-1'>
                    <span class='text-center'>Montant de la commande</span>
                    <br />
                    <span style='font-weight: bold'>
                                        TOTAL :
                      {{ totalTtc <= 0 ? 'gratuit' : totalTtc + ' €' }}
                                    </span>
                    <button
                      [disabled]='paymentMethod === "" && totalTtc > 0'
                      (click)='suivantValidation()'
                      class='ml-4'
                      style='width: 60%'
                      mdbBtn
                      [rounded]='true'
                      color='warning'
                      size='lg'
                    >
                      Payer
                    </button>
                  </div>
                  <div *ngIf='isMobile' style='background-color: #f8f8f8' class='p-1'>
                    <span class='text-center d-block'>Montant de la commande</span>
                    <span class='text-center d-block' style='font-weight: bold'>
                                        TOTAL :
                      {{ totalTtc <= 0 ? 'gratuit' : totalTtc + ' €' }}
                                    </span>
                    <button
                      [disabled]='paymentMethod === "" && totalTtc > 0'
                      (click)='suivantValidation()'
                      class='d-block text-center mx-auto'
                      style='width: 60%'
                      mdbBtn
                      [rounded]='true'
                      color='warning'
                      size='lg'
                    >
                      Payer
                    </button>
                  </div>
                  <br/>
                  <small class='font-weight-bold'>En cas de somme à payer (même après avoir utilisé un code de réduction), le montant minimum est de 1 euro. </small>
                  <br />
                  <br />
                  <small
                  >En procédant au paiement de ma commande, je renonce pleinement à mon droit de rétractation d’un délai
                    de 14 jours, conformément à l’article L 121-21-8 1° du Code de la consommation.
                  </small>
                </div>


              </div>

            </div>

            <div class='row'>
              <div class='col-12 col-md-5 mt-3 mb-3'>
                <button id='cb-button' [hidden]='true' class='btn btn-success'>
                  Payer par carte bancaire
                </button>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<app-site-footer></app-site-footer>
