<app-site-header></app-site-header>
<div
  mdbModal
  #verificationCode="mdbModal"
  class="modal fade top"
  id="verificationModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  [config]="{ backdrop: 'static', keyboard: false }"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-warning" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header justify-content-center">
        <p class="heading lead">VERIFICATION REQUISE</p>
      </div>
      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <!--          <div class="animate__animated animate__rotateIn">-->
          <!--            <fa-icon [icon]="faCheck" class="mb-3 fa-4x"></fa-icon>-->

          <!--          </div>-->
          <p>
            Merci de consulter votre messagerie et d'écrire ci-dessous le code envoyé à l'adresse
            {{ recruteur.emailPro }}.
          </p>
          <code-input
            [isCodeHidden]="false"
            [codeLength]="5"
            (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)"
          >
          </code-input>

          <a
            type="button"
            style="color: white"
            (click)="sendMailVerificationEmail()"
            mdbBtn
            [rounded]="true"
            color="warning"
            class="waves-effect mt-4"
            mdbWavesEffect
          ><fa-icon class="mr-2" [icon]="faArrowCircleRight"></fa-icon>Renvoyer le code
          </a>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" style="color: white" (click)='verificationCode.hide()' mdbBtn [rounded]="true" color="warning" class="waves-effect" mdbWavesEffect
        >Fermer
        </a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-recruteur-header></app-recruteur-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-recruteur/informations-personnelles" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-recruteur/informations-personnelles" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span>Informations personnelles</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Informations personnelles</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Modification de vos informations personnelles</h2>
            </div>

            <div class="pt-2 pb-5 d-flex justify-content-center" style="font-size: 25px; font-weight: bold; color: #0149b3">
                {{ recruteur.nom }} {{ recruteur.prenom }}
            </div>
            <div class="pt-2 d-flex justify-content-center">
                <img
                    style="object-fit: cover; width: 100px; height: 100px"
                    class="img-preload rounded-circle img-fluid"
                    *ngIf="this.recruteur.photo !== ''"
                    [src]="this.recruteur.photo"
                />
                <label class="ml-2" for="photo"
                    >Photo de profil (optionnelle mais vivement conseillée)<br /><small>(Fichier accepté: .png, .jpg - Taille maximum: 5Mo)</small></label
                >
                <span for="photo" class="error" [ngStyle]="{ display: this.error.photo ? 'block' : 'none' }">Obligatoire</span>
            </div>
            <div class="d-flex justify-content-center">
                <div class="file-field md-form">
                    <div mdbBtn [rounded]="true" [outline]="true" color="secondary" size="md" mdbWavesEffect>
                        <span class="pt-1">Choisir un fichier</span>
                        <input type="file" name="photo" mdbFileSelect (change)="photo($event)" />
                    </div>
                </div>
            </div>

            <div class="form-group inscription-row">
                <label
                    for="fonction"
                    [ngClass]="{
                        'fl-label-empty': this.recruteur.fonctionRecruteur === '',
                        'fl-label-not-empty': this.recruteur.fonctionRecruteur !== ''
                    }"
                    >Métier actuel *</label
                >
                <span
                    for="fonction"
                    class="error"
                    [ngStyle]="{
                        display: this.error.fonctionRecruteur ? 'block' : 'none'
                    }"
                    >Obligatoire</span
                >

                <input
                    type="text"
                    [(ngModel)]="this.recruteur.fonctionRecruteur"
                    name="fonction"
                    id="fonction"
                    class="form-control col-12 col-md-6"
                />
            </div>
            <div class="form-group inscription-row">
                <label>Téléphone (au moins un, ce dernier ne sera pas communiqué aux candidats) *</label>
                <span
                    for="telephonePortable"
                    class="error"
                    [ngStyle]="{
                        display: this.error.telephonePortable ? 'block' : 'none'
                    }"
                    >Obligatoire</span
                >
                <div class="row mt-2">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="telephonePortable">Téléphone Portable</label>
                            <input
                                type="tel"
                                name="telephonePortable"
                                id="telephonePortable"
                                class="form-control"
                                [(ngModel)]="this.recruteur.telephonePortable"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="telephoneFixe">Téléphone Fixe</label>
                            <input
                                type="tel"
                                name="telephoneFixe"
                                id="telephoneFixe"
                                class="form-control"
                                [(ngModel)]="this.recruteur.telephoneFixe"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="inscription-row">
                <label>Email</label>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label
                                for="email"
                                [ngClass]="{
                                    'fl-label-empty': this.recruteur.email === '',
                                    'fl-label-not-empty': this.recruteur.email !== ''
                                }"
                                >Identifiant du compte</label
                            >
                            <input
                                type="text"
                                name="email"
                                id="email"
                                class="form-control"
                                [(ngModel)]="this.recruteur.email"
                                (input)="emailMinuscule()"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label
                                for="emailPro"
                                [ngClass]="{
                                    'fl-label-empty': this.recruteur.emailPro === '',
                                    'fl-label-not-empty': this.recruteur.emailPro !== ''
                                }"
                                >Email professionnel *</label
                            >
                            <span
                                for="emailPro"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.emailPro ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <input
                                type="text"
                                name="emailPro"
                                id="emailPro"
                                class="form-control"
                                [(ngModel)]="this.recruteur.emailPro"
                                (input)="emailProMinuscule()"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center" style="position: relative">
                <p
                    style="color: red !important"
                    [ngStyle]="{
                        display: this.errorPage.status ? 'block' : 'none'
                    }"
                >
                    {{ errorPage.message }}
                </p>
                <p class="mention-obligatoire" style="color: red !important">* Obligatoire</p>
                <p *ngIf="photoUpload == '2'">
                    <img src="./assets/img/gif.gif" style="max-width: 25px" />
                    Photo en cours de sauvegarde, veuillez patienter
                </p>
            </div>
            <div class="d-flex float-right mb-3">
                <button mdbBtn class="text-center" [rounded]="true" color="warning" size="lg" (click)="sendInformationsPersonnelles()">
                    Valider
                </button>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
