import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-liste-newsletters',
    templateUrl: './liste-newsletters.component.html',
    styleUrls: ['./liste-newsletters.component.css'],
})
export class ListeNewslettersComponent implements OnInit {
    newsletters: any = [];
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.getNewsletters();
    }
    download(): void {
        this.httpClient.get<any>(this.globalService.url + '/newsletters/exporter').subscribe(
            (response) => {
                if (response.status) {
                    window.open(this.globalService.url + '/documents/excel/' + response.filename + '.xlsx');
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    getNewsletters(): void {
        this.httpClient.get<any>(this.globalService.url + '/newsletters').subscribe(
            (response) => {
                if (response.status) {
                    this.newsletters = response.newsletters;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
