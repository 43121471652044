import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faCheckCircle, faTimes, faArrowLeft, faRedo } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { UserService } from '../../../services/user.service';
import { ModalDirective } from '../../../components/mdb/free/modals';

@Component({
    selector: 'app-informations-recruteurs',
    templateUrl: './informations.component.html',
    styleUrls: [
        './informations.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class InformationsRecruteurComponent implements OnInit {
    @ViewChild('verificationCode', { static: true })
    verificationCode!: ModalDirective;
    faArrowCircleRight = faRedo;
    photoUpload = '1';
    newEmailVerified = false;
    dateNaissance = '';
    arrowLeft = faArrowLeft;
    faError = faTimes;
    oldProEmail = '';
    faOk = faCheckCircle;
    recruteur: any = {
        password: '',
    };
    errorPage = {
        status: false,
        message: '',
    };
    actuel = {
        nationalite: '',
        nationaliteCache: '',
        pays: '',
        paysCache: '',
    };
    error = {
        password: false,
        secteurEntreprise: false,
        activiteNom: false,
        passwordDeux: false,
        civilite: false,
        nom: false,
        prenom: false,
        fonctionRecruteur: false,
        informationComplementaireFonction: false,
        telephonePortable: false,
        telephoneFixe: false,
        email: false,
        emailPro: false,
        photo: false,
        siret: false,
        nomSociete: false,
        nomGroupe: false,
        adresse: false,
        complementAdresse: false,
        codePostal: false,
        ville: false,
        pays: false,
        numeroTva: false,
        codeNaf: false,
        nomActivite: false,
        ancienPassword: false,
        logoGroupe: false,
        presentationActivite: false,
        activiteTexte: false,
        typeEntreprise: false,
        nombreSalarie: false,
        activiteSpecifique: false,
        siteInternet: false,
        profilRechercher: false,
        metiersRechercher: false,
        secteurActivites: false,
    };
    photorecruteur: string | ArrayBuffer | null = '';
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private userService: UserService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {
        this.recruteur._id = localStorage.getItem('id');
        this.getAccount();
    }
    photo(f: any): void {
        this.photoUpload = '2';
        const file = f.target.files.item(0);
        const reader = new FileReader();
        const extensions = ['image/jpg', 'image/jpeg', 'image/png'];

        if (extensions.indexOf(file.type) > -1) {
            if (file.size <= 5000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.recruteur.photo = reader.result;
                    this.photorecruteur = reader.result;
                    this.sendPhoto();
                };
            } else {
                this.photoUpload = '';
                alert("L'image importé est supérieur à 5Mo. veuillez réduire sa taille");
            }
        } else {
            this.photoUpload = '';
            alert("Le document que vous venez d'importer n'est pas dans un format valide");
        }
    }
    sendPhoto(): void {
        let photo = '';
        if (this.recruteur.photo.split(';base64,').length > 1) {
            photo = this.recruteur.photo;
        }
        this.httpClient
            .patch(this.globalService.url + '/recruteur/photo-de-profil', { id: this.recruteur._id, photo: photo }, {})
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                        this.photoUpload = '';
                    } else {
                        this.photoUpload = '';
                        localStorage.setItem('photo', response.photo);
                    }
                },
                () => {
                    this.photoUpload = '';
                }
            );
    }

    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.recruteur = response.recruteur;

                    this.oldProEmail = this.recruteur.emailPro;

                    let dateNaissance = this.recruteur.dateNaissance.split('-');
                    if (dateNaissance.length == 1) {
                        dateNaissance = this.recruteur.dateNaissance.split('/');
                    }
                    dateNaissance = dateNaissance[0] + '-' + dateNaissance[1] + '-' + dateNaissance[2];
                    this.dateNaissance = dateNaissance;

                    if (this.recruteur.photo !== '') {
                        this.recruteur.photo = this.globalService.url + '/images/recruteur/' + this.recruteur.photo;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    renouvellerErreur(): void {
        this.errorPage.status = false;
        this.error = {
            password: false,
            secteurEntreprise: false,
            activiteNom: false,
            passwordDeux: false,
            civilite: false,
            nom: false,
            prenom: false,
            fonctionRecruteur: false,
            informationComplementaireFonction: false,
            telephonePortable: false,
            telephoneFixe: false,
            email: false,
            emailPro: false,
            photo: false,
            siret: false,
            nomSociete: false,
            nomGroupe: false,
            adresse: false,
            complementAdresse: false,
            codePostal: false,
            ville: false,
            pays: false,
            numeroTva: false,
            codeNaf: false,
            nomActivite: false,
            ancienPassword: false,
            logoGroupe: false,
            presentationActivite: false,
            activiteTexte: false,
            typeEntreprise: false,
            nombreSalarie: false,
            activiteSpecifique: false,
            siteInternet: false,
            profilRechercher: false,
            metiersRechercher: false,
            secteurActivites: false,
        };
    }
    emailMinuscule(): void {
        this.recruteur.email = this.recruteur.email.toLowerCase();
    }
    emailProMinuscule(): void {
        this.recruteur.emailPro = this.recruteur.emailPro.toLowerCase();
    }
    onCodeChanged(code: any): void {}

    onCodeCompleted(code: any): void {
        this.verifierCode(code);
    }
    verifierCode(code: string): void {
        this.httpClient
            .patch(
                this.globalService.url +
                    '/recruteur/mail/' +
                    this.recruteur.referenceRecruteur.replace('RECRUT', this.recruteur._id) +
                    '/' +
                    code,
                {}
            )
            .subscribe(
                (response) => {
                    this.newEmailVerified = true;
                    this.toastrService.info('Email vérifié', '', {
                        opacity: 1,
                    });
                    this.verificationCode.hide();
                    this.sendInformationsPersonnelles();
                },
                () => {
                    this.toastrService.error('Le code saisi est incorrect', '', { opacity: 1 });
                }
            );
    }
    sendMailVerificationEmail(): void {
        this.httpClient
            .put(this.globalService.url + '/mail/verifier-email', {
                id: this.recruteur._id,
                email: this.recruteur.emailPro,
                type: 'Recruteur',
            })
            .subscribe(
                (response) => {},
                () => {}
            );
    }
    checkProEmail(email: string): void {
        this.httpClient.get(this.globalService.url + '/recruteur/email-existe/' + email).subscribe(
            (response: any) => {
                if (response.status) {
                    const exist = response.exist;
                    if (exist) {
                        this.toastrService.error('Cette adresse email professionnelle est déjà utilisée.', '', {
                            opacity: 1,
                        });
                    } else {
                        this.verificationCode.show();
                        this.sendMailVerificationEmail();
                    }
                } else {
                }
            },
            () => {}
        );
    }
    sendInformationsPersonnelles(): void {
        this.renouvellerErreur();
        this.errorPage.status = false;
        let any = false;
        // if (this.recruteur.civilite === '') {
        //     this.error.civilite = true;
        //     any = true;
        // }
        // if (this.recruteur.nom === '') {
        //     this.error.nom = true;
        //     any = true;
        // }
        // if (this.recruteur.prenom === '') {
        //     this.error.prenom = true;
        //     any = true;
        // }
        if (this.recruteur.fonctionRecruteur === '') {
            this.error.fonctionRecruteur = true;
            any = true;
        }
        if (this.recruteur.emailPro === '') {
            this.error.emailPro = true;
            any = true;
        }
        if (this.recruteur.emailPro != this.oldProEmail && !this.newEmailVerified) {
            return this.checkProEmail(this.recruteur.emailPro);
        }
        if (this.recruteur.telephonePortable === '' && this.recruteur.telephoneFixe === '') {
            this.error.telephonePortable = true;
            this.errorPage.status = true;
            this.errorPage.message = "Aucun téléphone n'a été saisi";
            any = true;
        }
        // @ts-ignore
        document.getElementById('telephonePortable').focus();
        // @ts-ignore
        document.getElementById('telephoneFixe').focus();
        // @ts-ignore
        const errorPortable = document.getElementById('telephonePortable').style.borderColor === 'red';
        // @ts-ignore
        const errorFixe = document.getElementById('telephoneFixe').style.borderColor === 'red';
        if (errorPortable) {
            this.errorPage.status = true;
            this.errorPage.message = "Le téléphone portable n'est pas au bon format";
            any = true;
        }
        if (errorFixe && this.recruteur.telephoneFixe !== '' && this.recruteur.telephoneFixe !== '+33') {
            this.errorPage.status = true;
            this.errorPage.message = "Le téléphone fixe n'est pas au bon format";
            any = true;
        }
        if (!any) {
            this.httpClient
                .patch(
                    this.globalService.url + '/recruteur/recruteur',
                    {
                        id: this.recruteur._id,
                        civilite: this.recruteur.civilite,
                        nom: this.recruteur.nom,
                        prenom: this.recruteur.prenom,
                        dateNaissance: this.dateNaissance,
                        fonctionRecruteur: this.recruteur.fonctionRecruteur,
                        informationComplementaireFonction: this.recruteur.informationComplementaireFonction,
                        telephonePortable: this.recruteur.telephonePortable,
                        telephoneFixe: this.recruteur.telephoneFixe,
                        email: this.recruteur.email,
                        emailPro: this.recruteur.emailPro,
                    },
                    {}
                )
                .subscribe(
                    (response: any) => {
                        if (!response.status) {
                            this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                        } else {
                            this.toastrService.info('Informations personnelles modifiées', '', { opacity: 1 });
                            this.getAccount();
                            this.newEmailVerified = false;
                        }
                    },
                    () => {}
                );
        }
    }
}
