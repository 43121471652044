import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MdbTablePaginationComponent, MdbTableDirective } from '../../../components/mdb/free/tables';
import { GlobalService } from '../../../services/global.service';
import { faEdit, faTimes, faSort, faPlus } from '@fortawesome/free-solid-svg-icons';
import { GlobalVariable } from '../../../global';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../../../services/auth.service';
@Component({
    selector: 'app-liste-panier',
    templateUrl: './liste-panier.component.html',
    styleUrls: [
        './liste-panier.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
    ],
})
export class ListePanierComponent implements OnInit, AfterViewInit {
    @ViewChild(MdbTablePaginationComponent, { static: true })
    mdbTablePagination!: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true })
    mdbTable!: MdbTableDirective;

    faEdit = faEdit;
    faTimes = faTimes;
    faSort = faSort;
    faPlus = faPlus;

    id: string | null = '';
    paniers: any = [];
    isMobile = false;

    previous: any = [];
    headElements = ['Nom du panier', 'Nombre de recruteurs sélectionnés', 'Consulter et modifier', 'Supprimer'];
    displayByPages = GlobalVariable.PAGES;

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private cdRef: ChangeDetectorRef,
        private authService: AuthService,
        private deviceService: DeviceDetectorService
    ) {}

    ngOnInit(): void {
        this.id = this.authService.id;
        this.liste();
        this.isMobile = this.deviceService.isMobile();
    }
    ngAfterViewInit(): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
    supprimer(id: string): void {
        this.httpClient.delete<any>(this.globalService.url + '/panier/' + id).subscribe(
            (response) => {
                if (response.status) {
                    this.liste();
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    display(): string {
        if (this.paniers.length === 0) {
            return 'none';
        }
        return this.isMobile ? 'block' : 'table';
    }
    liste(): void {
        this.httpClient.get<any>(this.globalService.url + '/panier/' + this.id + '/user').subscribe(
            (response) => {
                if (response.status) {
                    let paniers = response.paniers;

                    this.paniers = paniers.sort((a: any, b: any) => (a.dateCreation >= b.dateCreation ? -1 : 1));
                    this.mdbTable.setDataSource(this.paniers);
                    this.paniers = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                    for (let p of this.paniers) {
                        let envoyer = 0;
                        for (let r of p.recruteurSelectionner) {
                            if (p.send) {
                                envoyer++;
                            }
                        }
                        p.nombreEnvoyer = 0;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    changeItemsByPage(event: any): void {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(event);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
    }
}
