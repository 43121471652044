import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-drawer',
    templateUrl: './drawer.component.html',
    styleUrls: ['./drawer.component.css'],
})
export class DrawerComponent implements OnInit {
    @Input() title = '';
    @Input() state = false;
    @Output() stateChange = new EventEmitter<boolean>();

    body: any = null;
    constructor() {}
    ngOnInit(): void {
        this.body = document.getElementsByTagName('body')[0];
    }
    openDrawer(): void {
        this.body.style.overflow = 'hidden';
        this.stateChange.emit(true);
    }
    closeDrawer(): void {
        this.body.removeAttribute('style');
        this.stateChange.emit(false);
    }
}
