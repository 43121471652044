<app-site-header></app-site-header>
<app-drawer #drawer [(state)]='openFilters' title='Filtres'>
  <div class='content'>
    <fieldset class='mt-2'>
      <legend class="form-legend">Contrat</legend>
      <ul class='list-unstyled'>
        <li class='checkbox' *ngFor='let contrat of typesContrat'>
          <mdb-checkbox  (change)='applySelection($event, filtres.typesContrat, contrat.value)'>{{contrat.label}}</mdb-checkbox>

        </li>
      </ul>
    </fieldset>
    <hr/>
    <fieldset class='mt-2'>
      <legend class="form-legend">Durée Hebdo.</legend>
      <ul class='list-unstyled'>
        <li class='checkbox' *ngFor='let field of durees'>
          <mdb-checkbox (change)='applySelection($event, filtres.durees, field.value)'>{{field.label}}</mdb-checkbox>

        </li>
      </ul>
    </fieldset>
    <hr/>
    <fieldset class='mt-2'>
      <legend class="form-legend">Domaine pro.</legend>
      <ul class='list-unstyled'>
        <li class='checkbox' *ngFor='let field of domainesPro'>
          <mdb-checkbox (change)='applySelection($event, filtres.domaines, field.value)'>{{field.label}}</mdb-checkbox>

        </li>
      </ul>
    </fieldset>
    <hr/>
    <fieldset class='mt-2'>
      <legend class="form-legend">Experience</legend>
      <ul class='list-unstyled'>
        <li class='checkbox' *ngFor='let field of experiences'>
          <mdb-checkbox (change)='applySelection($event, filtres.experiences, field.value)'>{{field.label}}</mdb-checkbox>

        </li>
      </ul>
    </fieldset>
    <div class='col-12 mt-4 mb-4'>
      <button
        (click)='drawer.closeDrawer();search(true)'
        [rounded]="true"
        mdbBtn
        [block]="true"
        type="button"
        color="warning"
        size="lg"
        mdbWavesEffect
      >
        <fa-icon [icon]="faSearch" class="mr-1"></fa-icon>Rechercher
      </button>
    </div>
  </div>

</app-drawer>
<app-modal-offer #offerModal></app-modal-offer>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-color" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <div>
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">Recherche d'offres d'emploi</h3>
                </div>
                <div class="page-sub-title">
                    <h5 class="breadcrumb-sub">Des centaines de milliers d'offres</h5>
                </div>
              <div>
                <div class='row p-4' style='background-color: #f6f7fb'>
                  <div class='form-group md-form mt-0 mb-2 mb-md-0 col-12 col-md-4 col-lg-4'>
                    <input type="text" placeholder='Métier, compétence, mot-clé' [(ngModel)]='filtres.motsCles' name="search" id="search" class="form-control" />
                  </div>
                  <div class='col-12 col-md-4 col-lg-4'>
                    <app-select-pole-place [(place)]='filtres.localisation'></app-select-pole-place>
                  </div>
                  <div class="col-12 d-sm-flex d-md-none mt-2 mb-2">
                    <label for="range" class="mb-2">Rayon</label>

                    <div class="d-flex">
                      <span class="font-weight-bold indigo-text mr-2">{{ filtres.rayon.min }}</span>
                      <form class="w-100">
                        <mdb-range-input
                          class=""
                          id="range"
                          name="range"
                          [min]="filtres.rayon.min"
                          [(ngModel)]="filtres.rayon.valeur"
                          [max]="filtres.rayon.max"
                        ></mdb-range-input>
                      </form>
                      <span class="font-weight-bold indigo-text ml-2">{{ filtres.rayon.max }}</span>
                    </div>
                  </div>
                  <div class='d-sm-block d-md-none col-12 mt-2 mb-2 mt-md-0'>
                    <button
                      (click)='drawer.openDrawer()'
                      [rounded]="true"
                      mdbBtn
                      [block]="true"
                      type="button"
                      color="warning"
                      size="lg"
                      mdbWavesEffect
                    >
                      Filtres
                    </button>
                  </div>
                  <div class='col-12 col-md-4 col-lg-4 mt-2 mb-2 mt-md-0'>
                    <button
                      (click)='search(true)'
                      [rounded]="true"
                      mdbBtn
                      [block]="true"
                      type="button"
                      color="warning"
                      size="lg"
                      mdbWavesEffect
                    >
                      <fa-icon [icon]="faSearch" class="mr-1"></fa-icon>Rechercher
                    </button>
                  </div>
                  <div class="offset-4 col-4 d-none d-md-block mt-2 mb-2">
                    <label for="range" class="mb-2">Rayon</label>

                    <div class="d-flex">
                      <span class="font-weight-bold indigo-text mr-2">{{ filtres.rayon.min }}</span>
                      <form class="w-100">
                        <mdb-range-input
                          class=""
                          id="range"
                          name="range"
                          [min]="filtres.rayon.min"
                          [(ngModel)]="filtres.rayon.valeur"
                          [max]="filtres.rayon.max"
                        ></mdb-range-input>
                      </form>
                      <span class="font-weight-bold indigo-text ml-2">{{ filtres.rayon.max }}</span>
                    </div>
                  </div>
                  <div class='d-none d-md-flex col-4'></div>
                 <!-- <div class='col-md-4 col-lg-2 d-none d-md-block'>
                    <div class="md-form">
                      <mdb-select-2 [(ngModel)]='filtres.date' [outline]='true' placeholder="Date de création">
                        <mdb-select-option *ngFor="let option of datesCreation" [value]="option.value">{{ option.label }}</mdb-select-option>
                      </mdb-select-2>
                    </div>
                  </div>-->
                  <div class='col-md-4 col-lg-3 d-none d-md-block'>
                    <div class="md-form">
                      <mdb-select-2 [(ngModel)]='filtres.typesContrat' [outline]='true' [multiple]="true" placeholder="Contrat">
                        <mdb-select-option *ngFor="let option of typesContrat" [value]="option.value">{{ option.label }}</mdb-select-option>
                      </mdb-select-2>
                    </div>
                  </div>
                  <div class='col-md-4 col-lg-3 d-none d-md-block'>
                    <div class="md-form">
                      <mdb-select-2 [(ngModel)]='filtres.durees' [outline]='true' [multiple]="true" placeholder="Durée Hebdo.">
                        <mdb-select-option *ngFor="let option of durees" [value]="option.value">{{ option.label }}</mdb-select-option>
                      </mdb-select-2>
                    </div>
                  </div>
                  <div class='col-md-6 col-lg-3 d-none d-md-block'>
                    <div class="md-form">
                      <mdb-select-2 [(ngModel)]='filtres.domaines' [outline]='true' [multiple]="true" placeholder="Domaine pro.">
                        <mdb-select-option *ngFor="let option of domainesPro" [value]="option.value">{{ option.label }}</mdb-select-option>
                      </mdb-select-2>
                    </div>
                  </div>
                  <div class='col-md-6 col-lg-3 d-none d-md-block'>
                    <div class="md-form">
                      <mdb-select-2 [(ngModel)]='filtres.experiences' [outline]='true' [multiple]="true" placeholder="Expérience">
                        <mdb-select-option *ngFor="let option of experiences" [value]="option.value">{{ option.label }}</mdb-select-option>
                      </mdb-select-2>
                    </div>
                  </div>
                  <div class='ml-auto'>
                    <button
                      (click)='reset()'
                      [rounded]="true"
                      mdbBtn
                      type="button"
                      color="success"
                      size="md"
                      mdbWavesEffect
                    >
                      Reinitialiser
                    </button>
                  </div>
<!--                  <mdb-material-chips [(ngModel)]="filtres.domaines"></mdb-material-chips>-->
                </div>
              </div>
              <div [hidden]='!loading'>
                <div  class="d-flex mt-4 ml-auto mr-auto justify-content-center text-center spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div [hidden]='offres.length <= 0 || loading' class='mt-4 mb-4 animate__animated animate__fadeIn animate__fast'>
                <h5 class='mb-2'>Nombre de résultats : {{nbResultats}}</h5>
                <div class='row mb-2'>
                  <button (click)='previous()' *ngIf='indexStart >= 10' mdbBtn type="button" color="info" [rounded]="true" mdbWavesEffect><fa-icon [icon]='faArrowLeft'></fa-icon></button>
                  <button (click)='next()' *ngIf='nbResultats > (indexEnd + 10) ' class='ml-auto' mdbBtn type="button" color="info" [rounded]="true" mdbWavesEffect><fa-icon [icon]='faArrowRight'></fa-icon></button>
                </div>
                <div class="bg-white shadow overflow-hidden rounded-md">
                  <ul class="divide-y divide-gray-200">
                    <li *ngFor='let offre of offres'>
                      <a (click)='loadForm(offre.id)' class="block hover:bg-gray-50">
                        <div class="px-4 py-4 sm:px-6">
                          <div class="flex items-center justify-between">
                            <p class="ml-sm-1 text-sm font-medium text-primary truncate">
                              {{ offre.intitule }}
                            </p>
                            <div class="ml-2 flex-shrink-0 flex d-none d-md-block">
                              <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                {{ offre.typeContratLibelle }}
                              </p>
                            </div>
                          </div>
                          <div class='dsm-block- d-md-none'>
                            <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              {{ offre.typeContratLibelle }}
                            </p>
                          </div>
                          <div class="mt-2 d-sm-flex justify-content-sm-between">
                            <div class="d-sm-flex">
                              <p class="flex items-center text-sm text-gray-500">
                                <!-- Heroicon name: solid/users -->
                                <svg class="flex-shrink-0 mr-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                </svg>
                                {{ offre.romeLibelle }}
                              </p>
                              <p class="mt-2 flex items-center text-sm text-gray-500 mt-sm-0 ml-sm-4">
                                <!-- Heroicon name: solid/location-marker -->
                                <svg class="flex-shrink-0 mr-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                </svg>
                                {{ offre.lieuTravail.libelle }}
                              </p>
                            </div>
                            <div class="mt-2 flex items-center text-sm text-gray-500 mt-sm-0">
                              <!-- Heroicon name: solid/calendar -->
                              <svg class="flex-shrink-0 mr-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                              </svg>
                              <p>
                                Mise à jour le
                                {{ offre.dateCreation | date: 'dd-MM-yyyy' }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class='row mt-2 mb-2 d-none d-none d-md-flex'>
                  <button (click)='previous()' *ngIf='indexStart >= 10' mdbBtn type="button" color="info" [rounded]="true" mdbWavesEffect><fa-icon [icon]='faArrowLeft'></fa-icon></button>
                  <button (click)='next()' *ngIf='nbResultats > (indexEnd + 10) ' class='ml-auto' mdbBtn type="button" color="info" [rounded]="true" mdbWavesEffect><fa-icon [icon]='faArrowRight'></fa-icon></button>
                </div>
              </div>

            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
