<mdb-date-picker
  name="mydate"
  #datePicker
  [selDate]='sel'
  [options]="myDatePickerOptions"
  [placeholder]="'Sélectionner une date'"
  [locale]="'fr'"
  [(ngModel)]="date"
  (dateChanged)="onDateChange($event)"

  required>
</mdb-date-picker>
