import { Component, OnInit, ViewChild } from '@angular/core';
import { faCheckCircle, faEye, faEyeSlash, faTimes, faFilePdf, faCheck, faRedo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { GlobalVariable } from '../../../global';
import Utils from '../../../utils';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { ModalDirective } from '../../../components/mdb/free/modals';
import { ValidateService } from '../../../services/validate.service';
import * as dayjs from 'dayjs';
import { UserService } from '../../../services/user.service';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { DOWN_ARROW, UP_ARROW } from '../../../components/mdb/free/utils/keyboard-navigation';

dayjs.extend(customParseFormat);
@Component({
    selector: 'app-inscription-recruteur',
    templateUrl: './inscription-recruteur.component.html',
    styleUrls: ['./inscription-recruteur.component.css', './../../../../assets/css/inscription/style.css'],
})
export class InscriptionRecruteurComponent implements OnInit {
    @ViewChild('verificationCode', { static: true })
    verificationCode!: ModalDirective;
    reminders = GlobalVariable.REMINDERS;

    faArrowCircleRight = faRedo;
    photoUpload = '1';
    logoUpload = '1';
    searchPays = new Subject<string>();
    filteredOptions: Observable<any[]>;
    isMobile = false;
    eye1 = faEye;
    eye2 = faEye;
    type1 = 'password';
    type2 = 'password';
    siretCompleted = false;
    chargement = 0;
    url = '';
    code = '';
    chercher = '';
    acceptation = false;
    majeur = false;
    fin = false;
    loading = false;
    siretType = 0;
    dob = '';
    faInfoCircle = faInfoCircle;

    typesEntreprises = GlobalVariable.TYPES_ENTREPRISES;
    workersNumbers = GlobalVariable.WORKERS_NUMBERS;
    activities = GlobalVariable.ACTIVITES;
    siretHelp =
        'numéro présent sur vos factures, dans les mentions légales du site Internet de votre organisme, sur societe.com, dans vos fiches de paie <br> <b>Ex : 522964261000XX</b>';
    actuel = {
        metier: '',
        metierCache: '',
        secteur: '',
        secteurCache: '',
        langue: '',
        langueCache: '',
        secteurRechercher: '',
        secteurRechercherCache: '',
    };
    listePays: any = [];
    ancienCodePays = 'FR';
    recruteur: any = {
        _id: '',
        estFrancaise: true,
        password: '',
        fonctionRecruteur: '',
        adresse: '',
        complementAdresse: '',
        codePostal: '',
        ville: '',
        pays: '',
        secteurActivites: [],
        secteurEntreprise: [],
        metiersRechercher: [],
        etape: 0,
        activite: {
            codeNaf: '',
            nom: '',
        },
        activiteSpecifique: [],
    };
    poste = {
        salarie: false,
        independant: false,
        stagiaire: false,
        alternant: false,
        benevole: false,
        autre: false,
    };
    posteVoulu = {
        cdd: false,
        cdi: false,
    };
    error = {
        emailInscription: false,
        password: false,
        dateNaissance: false,
        passwordDeux: false,
        civilite: false,
        acceptation: false,
        majeur: false,
        nom: false,
        prenom: false,
        fonctionRecruteur: false,
        informationComplementaireFonction: false,
        telephonePortable: false,
        telephoneFixe: false,
        email: false,
        emailPro: false,
        photo: false,
        activiteNom: false,
        siret: false,
        nomSociete: false,
        nomGroupe: false,
        adresse: false,
        complementAdresse: false,
        codePostal: false,
        ville: false,
        pays: false,
        numeroTva: false,
        codeNaf: false,
        nomActivite: false,
        logoGroupe: false,
        activiteTexte: false,
        typeEntreprise: false,
        nombreSalarie: false,
        activiteSpecifique: false,
        secteurEntreprise: false,
        siteInternet: false,
        profilRechercher: false,
        metiersRechercher: false,
        secteurActivites: false,
    };
    passwordDeux = '';
    errorPage = {
        status: false,
        message: '',
    };
    addr = {
        adresse: '',
        postcode: '',
        city: '',
    };
    analyse = {
        longueur: false,
        majuscule: false,
        miniscule: false,
        chiffre: false,
        caractereSpeciale: false,
        identique: false,
    };
    faError = faTimes;
    faOk = faCheckCircle;
    faFilePdf = faFilePdf;
    recherche = {
        metier: '',
        metierCache: '',
    };
    secteurs = [
        {
            nom: '',
            codeNaf: '',
        },
    ];
    metiers = [
        {
            codeOgr: '',
            groupePrincipal: '',
            groupeSecondaire: '',
            nomMetier: '',
        },
    ];
    actSpecifique = {
        handicap: false,
        agence_interim: false,
        management_transition: false,
        outplacement: false,
        cabinet_recrutement: false,
        service_domicile: false,
    };
    temps = {
        plein: false,
        partiel: false,
    };
    photoRecruteur: string | ArrayBuffer | null = '';
    logoRecruteur: string | ArrayBuffer | null = '';
    dernierPays = '';
    dernierIndicatif = '';

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private router: Router,
        private validateService: ValidateService,
        private userService: UserService,
        private toastrService: ToastService,
        private deviceService: DeviceDetectorService
    ) {
        this.filteredOptions = this.searchPays.pipe(
            startWith(''),
            map((value: string) => Utils.filter(value, this.listePays))
        );
    }

    ngOnInit(): void {
        this.getPays();
        this.isMobile = this.deviceService.isMobile();
        this.url = this.globalService.url;
        if (this.route.snapshot.params.email !== undefined && this.route.snapshot.params.email !== null) {
            this.recruteur.email = this.route.snapshot.params.email;
            if (localStorage.hasOwnProperty('social_picture') && localStorage.getItem('social_picture') !== null) {
                const pictureUrl = localStorage.getItem('social_picture');
                const xhr = new XMLHttpRequest();
                // @ts-ignore
                xhr.open('GET', pictureUrl);
                xhr.responseType = 'blob';
                xhr.send();
                xhr.addEventListener('load', () => {
                    const reader = new FileReader();
                    reader.readAsDataURL(xhr.response);
                    reader.addEventListener('loadend', () => {
                        this.recruteur.photo = reader.result;
                        this.sendPhoto();
                        localStorage.removeItem('social_picture');
                    });
                });
            }
        }

        if (this.route.snapshot.params.id !== undefined && this.route.snapshot.params.id !== null) {
            this.recruteur.email = this.route.snapshot.params.email;
            this.recruteur._id = this.route.snapshot.params.id;
            this.recruteur.referenceRecruteur = this.route.snapshot.params.ref;
            this.pair_exist();
        } else {
            const recruteurId = localStorage.getItem('id') !== undefined && localStorage.getItem('id') !== null;
            if (recruteurId) {
                this.recruteur._id = localStorage.getItem('id');
                this.getAccount();
            } else {
                this.router.navigate(['/recruteur']);
            }
        }

        setInterval(() => {
            if (this.ancienCodePays !== this.recruteur.paysCode) {
                this.ancienCodePays = this.recruteur.paysCode;
                for (const p of this.listePays) {
                    if (p.code === this.recruteur.paysCode) {
                        this.recruteur.pays = p.pays;
                        break;
                    }
                }
            }
            if (this.recruteur.paysCode == '' || this.recruteur.paysCode == undefined || this.recruteur.paysCode == null) {
                this.ancienCodePays = 'FR';
                this.recruteur.paysCode = 'FR';
                this.recruteur.pays = 'France';
            }
            if (this.recruteur.etape === 0 && this.recruteur._id !== '') {
                this.analyserPassword();
            } else if (this.recruteur.etape === 2) {
                if (this.recruteur.siret.length === 14 && this.chercher !== this.recruteur.siret && this.recruteur.estFrancaise) {
                    this.getSociete();
                    this.chercher = this.recruteur.siret;
                } else if (this.siretCompleted && this.recruteur.siret.length !== 14 && this.chercher !== this.recruteur.siret) {
                    this.recruteur.nomSociete = '';
                    this.recruteur.activite.codeNaf = '';
                    this.recruteur.nombreSalarie = '';
                    this.recruteur.adresse = '';
                    this.recruteur.complementAdresse = '';
                    this.recruteur.codePostal = '';
                    this.recruteur.ville = '';
                    this.recruteur.activite.nom = '';
                    this.chercher = this.recruteur.siret;
                }
                this.recruteur.siret = this.recruteur.siret.split(' ').join('');
            }
            if (this.recruteur.paysCode === 'FR' || this.recruteur.paysCode === '') {
                this.recruteur.estFrancaise = true;
            } else {
                this.recruteur.estFrancaise = false;
            }
            if ((this.dernierPays === '' && this.recruteur.paysCode !== '') || this.recruteur.paysCode !== this.dernierPays) {
                this.dernierPays = this.recruteur.paysCode;
                for (const pays of this.listePays) {
                    if (pays.code === this.dernierPays) {
                        this.recruteur.telephonePortable = pays.indicatif;
                        this.recruteur.telephoneFixe = pays.indicatif;
                        this.dernierIndicatif = pays.indicatif;
                    }
                }
            }
        }, 200);
    }
    async pair_exist(): Promise<any> {
        await this.httpClient
            .get<any>(this.globalService.url + '/recruteur/pair_exist/' + this.recruteur._id + '/' + this.recruteur.referenceRecruteur)
            .subscribe(
                (response) => {
                    if (response.status) {
                        localStorage.setItem('id', this.recruteur._id);
                        localStorage.setItem('token', response.token);
                        localStorage.setItem('type', 'Recruteur');
                        this.getAccount();
                    }
                },
                () => {}
            );
    }
    afficherTypeContrat(): boolean {
        if (this.poste.salarie) {
            return true;
        }
        this.posteVoulu.cdd = false;
        this.posteVoulu.cdi = false;
        return false;
    }
    eye(num: number): void {
        if (num === 1) {
            if (this.eye1 === faEye) {
                this.eye1 = faEyeSlash;
                this.type1 = 'text';
            } else {
                this.eye1 = faEye;
                this.type1 = 'password';
            }
        } else {
            if (this.eye2 === faEye) {
                this.eye2 = faEyeSlash;
                this.type2 = 'text';
            } else {
                this.eye2 = faEye;
                this.type2 = 'password';
            }
        }
    }
    getPays(): void {
        this.httpClient.get<any>(this.globalService.url + '/pays/getPays').subscribe(
            (response) => {
                if (response.status) {
                    this.listePays = response.pays;
                    this.searchPays.next(''); // force refresh select
                }
            },
            () => {}
        );
    }
    checkMailVerification(step: boolean): void {
        if (!this.recruteur.emailProVerifier) {
            if (
                (this.recruteur.idGmail === '' && this.recruteur.idFacebook === '' && this.recruteur.idLinkedin === '') ||
                this.recruteur.email !== this.recruteur.emailPro
            ) {
                if (this.recruteur.email !== this.recruteur.emailPro) {
                    this.checkProEmail(this.recruteur.emailPro);
                } else {
                    this.verificationCode.show();
                    this.sendMailVerificationEmail();
                }
            } else {
                this.recruteur.emailProVerifier = true;
                this.sendInformations();
                if (step) {
                    this.recruteur.etape = this.recruteur.etape + 1;
                }
                // @ts-ignore
                window.scroll(0, 0);
            }
        } else {
            this.sendInformations();
            if (step) {
                this.recruteur.etape = this.recruteur.etape + 1;
            }
            // @ts-ignore
            window.scroll(0, 0);
        }
    }
    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.recruteur = response.recruteur;
                    if (this.recruteur.ficheComplete) {
                        this.router.navigate(['/espace-recruteur/dashboard']);
                    }
                    if (localStorage.hasOwnProperty('social_first_name')) {
                        this.recruteur.prenom = localStorage.getItem('social_first_name');
                    }
                    if (localStorage.hasOwnProperty('social_last_name')) {
                        this.recruteur.nom = localStorage.getItem('social_last_name');
                    }
                    if (this.recruteur.etape > 1) {
                        this.checkMailVerification(false);
                    }
                    if (this.recruteur.paysCode === '') {
                        this.recruteur.paysCode = 'FR';
                        this.recruteur.pays = 'France';
                    } else {
                        for (let p of this.listePays) {
                            if (p.code === this.recruteur.paysCode) {
                                this.recruteur.pays = p.pays;
                            }
                        }
                    }
                    if (this.recruteur.etape === 6) {
                        this.fin = true;
                    }
                    if (this.recruteur.activite === null) {
                        this.recruteur.activite = {
                            codeNaf: '',
                            nom: '',
                        };
                    }
                    for (const t of this.recruteur.typePoste) {
                        if (t === 'salarie') {
                            this.poste.salarie = true;
                        } else if (t === 'independant') {
                            this.poste.independant = true;
                        } else if (t === 'stagiaire') {
                            this.poste.stagiaire = true;
                        } else if (t === 'alternant') {
                            this.poste.alternant = true;
                        } else if (t === 'benevole') {
                            this.poste.benevole = true;
                        } else if (t === 'autre') {
                            this.poste.autre = true;
                        }
                    }
                    for (const t of this.recruteur.typePosteVoulu) {
                        if (t === 'cdd') {
                            this.posteVoulu.cdd = true;
                        } else if (t === 'cdi') {
                            this.posteVoulu.cdi = true;
                        }
                    }
                    this.chargement = (this.recruteur.etape / 5) * 100;
                    this.addr.adresse = this.recruteur.adresse;
                    this.addr.postcode = this.recruteur.codePostal;
                    this.addr.city = this.recruteur.ville;
                    this.dernierPays = this.recruteur.paysCode;
                    if (this.recruteur.dateNaissance) {
                        this.dob = this.recruteur.dateNaissance;
                        // this.recruteur.dateNaissance = dayjs(this.recruteur.dateNaissance, 'DD-MM-YYYY').toDate();
                    }
                    this.recruteur.password = '';
                    this.photoRecruteur = this.globalService.url + '/images/recruteur/' + this.recruteur.photo;
                    this.logoRecruteur = this.globalService.url + '/images/entreprise/' + this.recruteur.logoGroupe;
                    for (const t of this.recruteur.activiteSpecifique) {
                        if (t === 'handicap') {
                            this.actSpecifique.handicap = true;
                        } else if (t === 'agence_interim') {
                            this.actSpecifique.agence_interim = true;
                        } else if (t === 'management_transition') {
                            this.actSpecifique.management_transition = true;
                        } else if (t === 'outplacement') {
                            this.actSpecifique.outplacement = true;
                        } else if (t === 'cabinet_recrutement') {
                            this.actSpecifique.cabinet_recrutement = true;
                        } else if (t === 'service_domicile') {
                            this.actSpecifique.service_domicile = true;
                        }
                    }
                    for (const t of this.recruteur.temps) {
                        if (t === 'plein') {
                            this.temps.plein = true;
                        } else if (t === 'partiel') {
                            this.temps.partiel = true;
                        }
                    }
                    if (response.recruteur.etape == 1) {
                        this.recruteur.emailPro = this.recruteur.email.toLowerCase();
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    checkProEmail(email: string): void {
        this.httpClient.get(this.globalService.url + '/recruteur/email-existe/' + email).subscribe(
            (response: any) => {
                if (response.status) {
                    const exist = response.exist;
                    if (exist) {
                        this.toastrService.error('Cette adresse email professionnelle est déjà utilisée.', '', {
                            opacity: 1,
                        });
                    } else {
                        this.verificationCode.show();
                        this.sendMailVerificationEmail();
                    }
                } else {
                }
            },
            () => {}
        );
    }
    analyserPassword(): void {
        if (this.recruteur.password.length >= 10) {
            this.analyse.longueur = true;
        } else {
            this.analyse.longueur = false;
        }
        if (this.recruteur.password.match(/[a-z]/g)) {
            this.analyse.miniscule = true;
        } else {
            this.analyse.miniscule = false;
        }
        if (this.recruteur.password.match(/[A-Z]/g)) {
            this.analyse.majuscule = true;
        } else {
            this.analyse.majuscule = false;
        }
        if (this.recruteur.password.match(/[0-9]/g)) {
            this.analyse.chiffre = true;
        } else {
            this.analyse.chiffre = false;
        }
        if (this.recruteur.password.match(/[@#&"';.,?:\/\\!]/g)) {
            this.analyse.caractereSpeciale = true;
        } else {
            this.analyse.caractereSpeciale = false;
        }
        if (this.recruteur.password === this.passwordDeux) {
            this.analyse.identique = true;
        } else {
            this.analyse.identique = false;
        }
    }
    monsieur(): void {
        this.recruteur.civilite = 'Monsieur';
    }
    madame(): void {
        this.recruteur.civilite = 'Madame';
    }
    autre(): void {
        this.recruteur.civilite = 'Autre';
    }
    photo(f: any): void {
        this.photoUpload = '2';
        const file = f.target.files.item(0);
        const reader = new FileReader();
        const extensions = ['image/jpg', 'image/jpeg', 'image/png'];
        if (extensions.indexOf(file.type) > -1) {
            if (file.size <= 5000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.recruteur.photo = reader.result;
                    this.photoRecruteur = reader.result;
                    this.sendPhoto();
                };
            } else {
                this.photoUpload = '';
                alert("L'image importée est supérieur à 5Mo. veuillez réduire sa taille");
            }
        } else {
            this.photoUpload = '';
            alert("Le document que vous venez d'importer n'est pas dans un format valide");
        }
    }
    logo(f: any): void {
        this.logoUpload = '2';
        const file = f.target.files.item(0);
        const reader = new FileReader();
        const extensions = ['image/jpg', 'image/jpeg', 'image/png'];

        if (extensions.indexOf(file.type) > -1) {
            if (file.size <= 5000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.recruteur.logoGroupe = reader.result;
                    this.logoRecruteur = reader.result;
                    this.sendLogo();
                };
            } else {
                this.logoUpload = '';
                alert("L'image importée est supérieur à 5Mo. veuillez réduire sa taille");
            }
        } else {
            this.logoUpload = '';
            alert("Le document que vous venez d'importer n'est pas dans un format valide");
        }
    }
    verifierCode(): void {
        this.httpClient
            .patch(
                this.globalService.url +
                    '/recruteur/mail/' +
                    this.recruteur.referenceRecruteur.replace('RECRUT', this.recruteur._id) +
                    '/' +
                    this.code,
                {}
            )
            .subscribe(
                (response) => {
                    this.toastrService.info('Email vérifié', '', {
                        opacity: 1,
                    });
                    this.recruteur.emailVerifier = true;
                    this.recruteur.emailProVerifier = true;
                    this.verificationCode.hide();
                    this.sendInformations();
                    this.recruteur.etape = this.recruteur.etape + 1;
                    // @ts-ignore
                    window.scroll(0, 0);
                },
                () => {
                    this.toastrService.error('Le code saisi est incorrect', '', { opacity: 1 });
                }
            );
    }
    // this called every time when user changed the code
    onCodeChanged(code: any) {}

    // this called only if user entered full code
    onCodeCompleted(code: any) {
        this.code = code;
        this.verifierCode();
    }
    precedent(): void {
        this.recruteur.etape = this.recruteur.etape - 1;
        // @ts-ignore
        window.scroll(0, 0);
    }

    renouvellerErreur(): void {
        this.error = {
            emailInscription: false,
            password: false,
            majeur: false,
            passwordDeux: false,
            civilite: false,
            dateNaissance: false,
            nom: false,
            acceptation: false,
            activiteNom: false,
            prenom: false,
            fonctionRecruteur: false,
            informationComplementaireFonction: false,
            telephonePortable: false,
            telephoneFixe: false,
            secteurEntreprise: false,
            email: false,
            emailPro: false,
            photo: false,
            siret: false,
            nomSociete: false,
            nomGroupe: false,
            adresse: false,
            complementAdresse: false,
            codePostal: false,
            ville: false,
            pays: false,
            numeroTva: false,
            codeNaf: false,
            nomActivite: false,
            logoGroupe: false,
            activiteTexte: false,
            typeEntreprise: false,
            nombreSalarie: false,
            activiteSpecifique: false,
            siteInternet: false,
            profilRechercher: false,
            metiersRechercher: false,
            secteurActivites: false,
        };
    }
    setActs(event: any): void {
        this.actSpecifique.handicap =
            this.actSpecifique.agence_interim =
            this.actSpecifique.management_transition =
            this.actSpecifique.outplacement =
            this.actSpecifique.cabinet_recrutement =
            this.actSpecifique.service_domicile =
                false;
        if (event.includes('handicap')) {
            this.actSpecifique.handicap = true;
        }
        if (event.includes('agence_interim')) {
            this.actSpecifique.agence_interim = true;
        }
        if (event.includes('management_transition')) {
            this.actSpecifique.management_transition = true;
        }
        if (event.includes('outplacement')) {
            this.actSpecifique.outplacement = true;
        }

        if (event.includes('cabinet_recrutement')) {
            this.actSpecifique.cabinet_recrutement = true;
        }
        if (event.includes('service_domicile')) {
            this.actSpecifique.service_domicile = true;
        }
    }

    emailMinuscule(): void {
        this.recruteur.email = this.recruteur.email.toLowerCase();
    }
    emailProMinuscule(): void {
        this.recruteur.emailPro = this.recruteur.emailPro.toLowerCase();
    }

    getSociete(): void {
        this.httpClient.get<any>(this.globalService.url + '/recruteur/getBySiret/' + this.recruteur.siret).subscribe(
            (response) => {
                this.recruteur.nomSociete = response.etablissement.uniteLegale.denominationUniteLegale;
                this.recruteur.activite.codeNaf = response.etablissement.uniteLegale.activitePrincipaleUniteLegale.split('.').join('');
                this.getActiviteWithNaf(this.recruteur.activite.codeNaf);

                const effectif = response.etablissement.trancheEffectifsEtablissement;

                switch (effectif) {
                    case null:
                    case '':
                    case 'NN':
                    case '0':
                    case '00':
                        this.recruteur.nombreSalarie = '0';
                        break;
                    case '1':
                    case '2':
                    case '3':
                    case '01':
                    case '02':
                    case '03':
                        this.recruteur.nombreSalarie = '1-9';
                        break;
                    case '11':
                    case '12':
                        this.recruteur.nombreSalarie = '10-49';
                        break;
                    case '21':
                    case '22':
                    case '31':
                    case '32':
                        this.recruteur.nombreSalarie = '50-499';
                        break;
                    case '41':
                        this.recruteur.nombreSalarie = '500-999';
                        break;
                    default:
                        this.recruteur.nombreSalarie = '1000+';
                        break;
                }

                // this.recruteur.numeroTva = response.etablissement.unite_legale.numero_tva_intra;
                this.recruteur.adresse = `${response.etablissement.adresseEtablissement.numeroVoieEtablissement} ${response.etablissement.adresseEtablissement.typeVoieEtablissement} ${response.etablissement.adresseEtablissement.libelleVoieEtablissement}`;
                this.recruteur.codePostal = response.etablissement.adresseEtablissement.codePostalEtablissement;
                this.recruteur.ville = response.etablissement.adresseEtablissement.libelleCommuneEtablissement;
                this.getLatitudeLongitude();
                this.recruteur.pays = 'France';

                this.siretCompleted = true;
            },
            () => {}
        );
    }
    getLatitudeLongitude(): void {
        let addr = this.recruteur.adresse.split(' ').join('+');
        if (this.recruteur.complementAdresse !== '') {
            addr += '+' + this.recruteur.complementAdresse.split(' ').join('+');
        }
        if (this.recruteur.codePostal !== '') {
            addr += '+' + this.recruteur.codePostal.split(' ').join('+');
        }
        if (this.recruteur.ville !== '') {
            addr += '+' + this.recruteur.ville.split(' ').join('+');
        }
        this.httpClient.get<any>(this.globalService.url + '/adresse/FR/' + addr).subscribe(
            (response) => {
                if (response.status) {
                    this.recruteur.latitude = response.addrs[0].latitude.toString();
                    this.recruteur.longitude = response.addrs[0].longitude.toString();
                }
            },
            () => {
                console.log('err');
            }
        );
    }
    getActiviteWithNaf(naf: any): void {
        if (naf.length != 5) return;

        this.httpClient.get<any>(this.globalService.url + '/recruteur/getActivite/' + this.recruteur.siret).subscribe(
            (response) => {
                if (response.status) {
                    this.recruteur.activite.nom = response.activite;
                }
            },
            () => {
                this.getWithNaf(naf);
            }
        );
    }

    getWithNaf(naf: string): void {
        this.httpClient.post<any>(this.globalService.url + '/secteurs/naf', { naf }).subscribe(
            (response) => {
                if (response.status) {
                    this.recruteur.activite.nom = response.secteursActivite.description;
                }
            },
            () => {}
        );
    }

    suivant(): void {
        this.renouvellerErreur();
        this.errorPage.status = false;
        if (this.recruteur.etape === 0) {
            if (
                this.analyse.longueur &&
                this.analyse.miniscule &&
                this.analyse.majuscule &&
                this.analyse.chiffre &&
                this.analyse.caractereSpeciale &&
                this.analyse.identique
            ) {
                this.sendPassword();
                this.recruteur.etape = this.recruteur.etape + 1;
                // @ts-ignore
                window.scroll(0, 0);
            }
            let any = false;
            if (this.recruteur.password === '') {
                this.error.password = true;
                any = true;
            }
            if (this.passwordDeux === '') {
                this.error.passwordDeux = true;
                any = true;
            }
            if (!any) {
                if (this.recruteur.password !== this.passwordDeux) {
                    this.errorPage.status = true;
                    this.errorPage.message = 'Les deux mot de passe ne sont pas identique';
                } else {
                    this.errorPage.status = true;
                    this.errorPage.message = "Votre mot de passe n'est pas assez fort";
                }
            }
        } else if (this.recruteur.etape === 1) {
            let any = false;
            if (this.recruteur.civilite === '') {
                this.error.civilite = true;
                any = true;
            }
            if (this.recruteur.nom === '') {
                this.error.nom = true;
                any = true;
            }
            // if (this.recruteur.dateNaissance === '') {
            //     this.error.dateNaissance = true;
            //     any = true;
            // }
            // const age = dayjs().diff(dayjs(this.recruteur.dateNaissance, 'DD-MM-YYYY'), 'years');
            //
            // if (age < 18) {
            //     any = true;
            //     this.errorPage.status = true;
            //     this.errorPage.message = 'Vous ne pouvez pas vous inscrire sur notre site si vous avez moins de 18 ans';
            // }
            // if (age >= 100) {
            //     any = true;
            //     this.errorPage.status = true;
            //     this.errorPage.message = 'La date de naissance est erronée';
            // }
            if (this.recruteur.prenom === '') {
                this.error.prenom = true;
                any = true;
            }
            if (this.recruteur.fonctionRecruteur === '') {
                this.error.fonctionRecruteur = true;
                any = true;
            }
            if (this.recruteur.email === '') {
                this.error.email = true;
                any = true;
            }
            // if (this.recruteur.photo === '') {
            //     this.error.photo = true;
            //     any = true;
            // }
            if (!this.validateService.validateEmail(this.recruteur.emailPro)) {
                this.error.emailPro = true;
                any = true;
            }
            if (this.recruteur.telephonePortable === '' && this.recruteur.telephoneFixe === '') {
                this.error.telephonePortable = true;
                this.errorPage.status = true;
                this.errorPage.message = "Aucun téléphone n'a été saisi";
                any = true;
            }
            // @ts-ignore
            document.getElementById('telephonePortable').focus();
            // @ts-ignore
            document.getElementById('telephoneFixe').focus();
            // @ts-ignore
            const errorPortable = document.getElementById('telephonePortable').style.borderColor === 'red';
            // @ts-ignore
            const errorFixe = document.getElementById('telephoneFixe').style.borderColor === 'red';
            if (errorPortable) {
                this.errorPage.status = true;
                this.errorPage.message = "Le téléphone portable n'est pas au bon format";
                any = true;
            }
            if (errorFixe && this.recruteur.telephoneFixe !== '' && this.recruteur.telephoneFixe !== this.dernierIndicatif) {
                this.errorPage.status = true;
                this.errorPage.message = "Le téléphone fixe n'est pas au bon format";
                any = true;
            }
            if (this.recruteur.telephonePortable === this.dernierIndicatif && this.recruteur.telephoneFixe === this.dernierIndicatif) {
                this.error.telephonePortable = true;
                this.errorPage.status = true;
                this.errorPage.message = "Certains champs obligatoires n'ont pas été remplis";
                any = true;
            }
            if (!any) {
                if (this.recruteur.telephoneFixe === this.dernierIndicatif) {
                    this.recruteur.telephoneFixe = '';
                }
                this.checkMailVerification(true);
            }
        } else if (this.recruteur.etape === 2) {
            let any = false;
            // if (this.recruteur.siret === '') {
            //     this.error.siret = true;
            //     any = true;
            // }
            if (this.recruteur.nomSociete === '') {
                this.error.nomSociete = true;
                any = true;
            }
            if (this.recruteur.adresse === '') {
                this.error.adresse = true;
                any = true;
            }
            if (this.recruteur.codePostal === '') {
                this.error.codePostal = true;
                any = true;
            }
            if (this.recruteur.ville === '') {
                this.error.ville = true;
                any = true;
            }
            if (this.recruteur.pays === '') {
                this.error.pays = true;
                any = true;
            }
            if (this.recruteur.activite.nom === '') {
                this.error.nomActivite = true;
            }
            if (!any) {
                this.sendEntreprise();
                this.recruteur.etape = this.recruteur.etape + 1;
                // @ts-ignore
                window.scroll(0, 0);
            }
        } else if (this.recruteur.etape === 3) {
            let any = false;
            if (this.recruteur.secteurEntreprise === '' || this.recruteur.secteurEntreprise.length === 0) {
                this.error.secteurEntreprise = true;
                any = true;
            }
            // if (this.recruteur.activiteTexte === '') {
            //     this.error.activiteTexte = true;
            //     any = true;
            // }
            if (this.recruteur.typeEntreprise === '') {
                this.error.typeEntreprise = true;
                any = true;
            }
            if (this.recruteur.nombreSalarie === '') {
                this.error.nombreSalarie = true;
                any = true;
            }
            if (!any) {
                this.sendComplementEntreprise();
                this.recruteur.etape = this.recruteur.etape + 2;
                // @ts-ignore
                window.scroll(0, 0);
            }
        } else if (this.recruteur.etape === 4) {
            this.sendRecherche();
            this.recruteur.etape = this.recruteur.etape + 1;
            // @ts-ignore
            window.scroll(0, 0);
        } else if (this.recruteur.etape === 5) {
            let any = false;
            if (!this.acceptation) {
                this.error.acceptation = true;
                any = true;
            }
            if (!this.majeur) {
                this.error.majeur = true;
                any = true;
            }
            if (!any) {
                this.sendAutorisation();
                this.fin = true;

                this.toastrService.info('Inscription terminée !', '', {
                    opacity: 1,
                });
                setTimeout(() => {
                    window.location.href = 'espace-recruteur/dashboard';
                }, 1500);
            }
        }
    }

    sendPassword(): void {
        this.httpClient
            .patch(this.globalService.url + '/recruteur/password', { id: this.recruteur._id, password: this.recruteur.password }, {})
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    } else {
                        window.location.reload();
                    }
                },
                (e) => {
                    console.log(e);
                }
            );
    }
    sendInformations(): void {
        this.httpClient
            .patch(
                this.globalService.url + '/recruteur/recruteur',
                {
                    id: this.recruteur._id,
                    civilite: this.recruteur.civilite,
                    nom: this.recruteur.nom,
                    prenom: this.recruteur.prenom,
                    dateNaissance: dayjs(this.recruteur.dateNaissance, 'DD-MM-YYYY').format('DD-MM-YYYY'),
                    fonctionRecruteur: this.recruteur.fonctionRecruteur,
                    informationComplementaireFonction: this.recruteur.informationComplementaireFonction,
                    telephonePortable: this.recruteur.telephonePortable,
                    telephoneFixe: this.recruteur.telephoneFixe,
                    email: this.recruteur.email,
                    emailPro: this.recruteur.emailPro,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    } else {
                        if (localStorage.hasOwnProperty('social_first_name')) {
                            localStorage.removeItem('social_first_name');
                        }
                        if (localStorage.hasOwnProperty('social_last_name')) {
                            localStorage.removeItem('social_last_name');
                        }
                    }
                },
                () => {}
            );
    }
    sendPhoto(): void {
        let photo = '';
        if (this.recruteur.photo.split(';base64,').length > 1) {
            photo = this.recruteur.photo;
        }
        this.httpClient
            .patch(this.globalService.url + '/recruteur/photo-de-profil', { id: this.recruteur._id, photo: photo }, {})
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                        this.photoUpload = '';
                    } else {
                        this.photoUpload = '';
                        localStorage.setItem('photo', response.photo);
                    }
                },
                () => {
                    this.photoUpload = '';
                }
            );
    }
    sendEntreprise(): void {
        if (this.recruteur.activite.nom !== '') {
            let exist = false;
            for (const a of this.recruteur.secteurEntreprise) {
                if (this.recruteur.activite.nom == a.nom) {
                    exist = true;
                    break;
                }
            }
            if (!exist) {
                this.recruteur.secteurEntreprise.push(this.recruteur.activite);
            }
        }

        this.httpClient
            .patch(
                this.globalService.url + '/recruteur/entreprise',
                {
                    id: this.recruteur._id,
                    siret: this.recruteur.siret,
                    nomSociete: this.recruteur.nomSociete,
                    nomGroupe: this.recruteur.nomGroupe,
                    adresse: this.recruteur.adresse,
                    complementAdresse: this.recruteur.complementAdresse,
                    codePostal: this.recruteur.codePostal,
                    ville: this.recruteur.ville,
                    pays: this.recruteur.pays,
                    paysCode: this.recruteur.paysCode,
                    latitude: this.recruteur.latitude,
                    longitude: this.recruteur.longitude,
                    numeroTva: this.recruteur.numeroTva,
                    activite: this.recruteur.activite,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    accepter(num: number): void {
        if (num === 1) {
            this.acceptation = !this.acceptation;
        } else {
            this.majeur = !this.majeur;
        }
    }
    sendLogo(): void {
        let logo = '';
        if (this.recruteur.logoGroupe.split(';base64,').length > 1) {
            logo = this.recruteur.logoGroupe;
        }
        this.httpClient.patch(this.globalService.url + '/recruteur/logo', { id: this.recruteur._id, logo: logo }, {}).subscribe(
            (response: any) => {
                if (!response.status) {
                    alert('Erreur lors de la modification');
                    this.logoUpload = '';
                } else {
                    this.logoUpload = '';
                }
            },
            () => {
                this.logoUpload = '';
            }
        );
    }
    sendComplementEntreprise(): void {
        const newActSpecifique = [];
        if (this.actSpecifique.handicap) {
            newActSpecifique.push('handicap');
        }

        if (this.actSpecifique.agence_interim) {
            newActSpecifique.push('agence_interim');
        }

        if (this.actSpecifique.management_transition) {
            newActSpecifique.push('management_transition');
        }

        if (this.actSpecifique.outplacement) {
            newActSpecifique.push('outplacement');
        }

        if (this.actSpecifique.cabinet_recrutement) {
            newActSpecifique.push('cabinet_recrutement');
        }

        if (this.actSpecifique.service_domicile) {
            newActSpecifique.push('service_domicile');
        }
        this.httpClient
            .patch(
                this.globalService.url + '/recruteur/entrepriseComplement',
                {
                    id: this.recruteur._id,
                    activiteTexte: this.recruteur.activiteTexte,
                    typeEntreprise: this.recruteur.typeEntreprise,
                    nombreSalarie: this.recruteur.nombreSalarie,
                    activiteSpecifique: newActSpecifique,
                    siteInternet: this.recruteur.siteInternet,
                    secteurEntreprise: this.recruteur.secteurEntreprise,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    sendRecherche(): void {
        const typePoste = [];
        if (this.poste.alternant) {
            typePoste.push('alternant');
        }
        if (this.poste.autre) {
            typePoste.push('autre');
        }
        if (this.poste.benevole) {
            typePoste.push('benevole');
        }
        if (this.poste.independant) {
            typePoste.push('independant');
        }
        if (this.poste.salarie) {
            typePoste.push('salarie');
        }
        if (this.poste.stagiaire) {
            typePoste.push('stagiaire');
        }
        const posteVoulu = [];
        if (this.posteVoulu.cdd) {
            posteVoulu.push('cdd');
        }
        if (this.posteVoulu.cdi) {
            posteVoulu.push('cdi');
        }

        const temps = [];
        if (this.temps.partiel) {
            temps.push('partiel');
        }
        if (this.temps.plein) {
            temps.push('plein');
        }
        this.httpClient
            .patch(
                this.globalService.url + '/recruteur/recherche',
                {
                    id: this.recruteur._id,
                    profilRechercher: this.recruteur.profilRechercher,
                    metiersRechercher: this.recruteur.metiersRechercher,
                    secteurActivites: this.recruteur.secteurActivites,
                    typePoste,
                    typePosteVoulu: posteVoulu,
                    temps: temps,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    sendAutorisation(): void {
        this.httpClient
            .patch(
                this.globalService.url + '/recruteur/autorisation',
                {
                    id: this.recruteur._id,
                    autorisationAccueil: this.recruteur.autorisationAccueil,
                    autorisationReferencement: this.recruteur.autorisationReferencement,
                    autorisationOffre: this.recruteur.autorisationOffre,
                    autorisationReception: this.recruteur.autorisationReception,
                    reminder: this.recruteur.reminder,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    sendMailVerificationEmail(): void {
        this.httpClient
            .put(this.globalService.url + '/mail/verifier-email', {
                id: this.recruteur._id,
                email: this.recruteur.emailPro,
                type: 'Recruteur',
            })
            .subscribe(
                (response) => {},
                () => {}
            );
    }
}
