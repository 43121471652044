import { Pipe, PipeTransform } from '@angular/core';
import { GlobalVariable } from '../global';

@Pipe({
    name: 'dispos',
})
export class DisposPipe implements PipeTransform {
    dispos = GlobalVariable.DISPOS;
    transform(value: unknown, ...args: unknown[]): unknown {
        const obj = this.dispos.find((a: { value: any }) => a.value === value);
        return obj ? obj.label : '';
    }
}
