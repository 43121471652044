<app-site-header page="cguv"></app-site-header>

<section class="contact py-5 position-relative i1185 contact">
    <div class="container mt-3">
        <div class="row">
            <div class="col-md-10 offset-md-2">
                <h4 class="text-primary fs-45 fw-bold">Nous contacter</h4>
                <p class="fs-23 text-muted">
                    Notre priorité : votre satisfaction. <br />
                    C’est pourquoi vous pouvez nous joindre facilement.
                </p>
            </div>
        </div>

        <div class="row mt-4 justify-content-between">
            <div class="col-md-8 p-3 offset-md-1">
                <div class="row">
                    <div class="col-md-7 p-2">
                        <div class="card shadow br-30">
                            <div class="card-body p-4">
                                <form action="">
                                    <div class="row">
                                        <div class="col-md-6 p-3">
                                            <input
                                                type="text"
                                                name="your-name"
                                                [(ngModel)]="contact.nom"
                                                placeholder="NOM, PRENOM *"
                                                class="border-0 border-bottom form-control br-0"
                                            />
                                            <span
                                                for="adresse"
                                                class="error"
                                                [ngStyle]="{
                                                    display: this.contactError.nom ? 'block' : 'none'
                                                }"
                                                >Obligatoire</span
                                            >
                                        </div>
                                        <div class="col-md-6 p-3">
                                            <input
                                                type="text"
                                                name="your-email"
                                                [(ngModel)]="contact.email"
                                                placeholder="VOTRE EMAIL *"
                                                class="border-0 border-bottom form-control br-0"
                                            />
                                            <span
                                                for="adresse"
                                                class="error"
                                                [ngStyle]="{
                                                    display: this.contactError.email ? 'block' : 'none'
                                                }"
                                                >Obligatoire / Mauvais format</span
                                            >
                                        </div>
                                        <div class="col-md-6 p-3">
                                            <input
                                                type="text"
                                                name="telephone"
                                                [(ngModel)]="contact.telephone"
                                                placeholder="VOTRE TELEPHONE"
                                                class="border-0 border-bottom form-control br-0"
                                            />
                                        </div>
                                        <div class="col-md-12 p-3">
                                            <input
                                                type="text"
                                                name="subject"
                                                [(ngModel)]="contact.sujet"
                                                placeholder="OBJET DE VOTRE DEMANDE *"
                                                class="border-0 border-bottom form-control br-0"
                                            />
                                            <span
                                                for="adresse"
                                                class="error"
                                                [ngStyle]="{
                                                    display: this.contactError.sujet ? 'block' : 'none'
                                                }"
                                                >Obligatoire</span
                                            >
                                        </div>
                                        <div class="col-md-12 p-3">
                                            <label for="" class="text-muted ml-3"> VOTRE MESSAGE * </label>
                                            <textarea
                                                name="your-message"
                                                [(ngModel)]="contact.message"
                                                class="form-control br-0"
                                                cols="30"
                                                rows="5"
                                            ></textarea>
                                            <span
                                                for="adresse"
                                                class="error"
                                                [ngStyle]="{
                                                    display: this.contactError.message ? 'block' : 'none'
                                                }"
                                                >Obligatoire</span
                                            >
                                        </div>

                                        <div class="col-md-12 p-3">
                                            <div class="text-center">
                                                <button (click)="envoyer()" class="btn btn-warning px-4 rounded-pill">Envoyer</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 p-2 m-auto">
                        <div class="card br-30 shadow">
                            <div class="card-body">
                                <div class="text-center">
                                    <p class="fs-30">Informations de contact</p>
                                </div>
                                <table class="table mb-0" style="word-break: break-word">
                                    <tr>
                                        <td class="border-0">
                                            <fa-icon [icon]="faPhone"></fa-icon>
                                        </td>
                                        <td class="border-0">
                                            <a href="+33651886654">+33(0)6 51 88 66 54</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="border-0">
                                            <fa-icon [icon]="faEnvelope"></fa-icon>
                                        </td>
                                        <td class="border-0">
                                            <a href="mailto:contact@avenuedesrecruteurs.fr">contact@avenuedesrecruteurs.fr</a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-site-footer></app-site-footer>
