import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
import { debounceTime, map, startWith } from 'rxjs/operators';
import Utils from '../../../utils';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-select-pole-place',
    templateUrl: './pole-emploi-localisation-select.component.html',
    styleUrls: [
        './pole-emploi-localisation-select.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class PoleEmploiLocalisationSelectComponent implements OnInit {
    @Input() place = {
        code: '',
        libelle: '',
        type: '',
    };
    @Output() placeChange: EventEmitter<any> = new EventEmitter();

    oldSearch = '';
    loading = false;

    places: any = [];

    searchAdresse = new Subject<string>();

    resultsPlace: Observable<any> | undefined;

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService
    ) {
        this.resultsPlace = this.searchAdresse.pipe().pipe(
            startWith(''),
            map((value: string) => Utils.filterAdresse(value, this.places)),
            debounceTime(500)
        );
    }
    ngOnInit(): void {}
    changePlace(event: any): void {
        this.place.libelle = event;
        if (this.place.libelle !== '') {
            this.loading = true;
            setTimeout(() => {
                if (this.oldSearch !== this.place.libelle) {
                    this.oldSearch = this.place.libelle;
                    this.getPlaces();
                }
            }, 2000);
        }
    }
    getPlaces(): void {
        this.httpClient.get<any>(this.globalService.url + '/offres/place/' + this.place.libelle, {}).subscribe(
            (response) => {
                if (response.status) {
                    this.places = response.places;
                    this.searchAdresse.next(this.place.code);
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                }
            },
            () => {}
        );
    }
    choosePlace(code: string, libelle: string, type: string): void {
        this.place = {
            code,
            libelle,
            type,
        };
        this.placeChange.emit(this.place);
    }
    clear(): void {
        this.place = {
            code: '',
            libelle: '',
            type: '',
        };
        this.placeChange.emit(this.place);
    }
}
