<div *ngIf="values && values.length" class="md-chip-list" [ngClass]="focused" #chipComponent>
  <span *ngFor="let value of values" class="md-chip" selected>
    {{ value }} <fa-icon class="close" [icon]='faTimes' aria-hidden="true" (click)="removeValue(value)"></fa-icon>
  </span>

<!--  <span>
    <input
      (blur)="addValue(box.value, $event)"
      [(ngModel)]="labelToAdd"
      (keyup.enter)="addValue(box.value, $event)"
      (focus)="onFocus()"
      (focusout)="focusOutFunction()"
      (keydown)="handleKeydown($event)"
      #box
    />
  </span>-->
</div>
<!--<div *ngIf="!values || !values.length">
  <input
    (blur)="addValue(tbox.value, $event)"
    class="md-chips-input"
    placeholder="{{ placeholder }}"
    #tbox
    (keyup.enter)="addValue(tbox.value, $event)"
  />
</div>-->
