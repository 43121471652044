<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-recruteur-header></app-recruteur-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-recruteur/informations-personnelles" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div
            mdbModal
            #unsubModal="mdbModal"
            class="modal fade top"
            id="frameModalTop2"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-notify modal-danger" role="document">
                <!--Content-->
                <div class="modal-content text-center">
                    <!--Body-->
                    <div class="modal-body">
                        <h4 class="font-weight-bold pb-2">DESINSCRIPTION</h4>
                        Etes-vous sûr(e) de vouloir vous désinscrire du site AvenueDesRecruteurs ? Si OUI, toutes vos informations seront
                        supprimées.
                    </div>
                    <!--Footer-->
                    <div class="modal-footer justify-content-center">
                        <a (click)="desinscrire()" mdbBtn color="danger" size="lg" [outline]="true"  style="border: 2px solid #d89e9e !important; color: #d89e9e !important"
                           class="waves-effect" mdbWavesEffect
                            >Oui</a
                        >
                        <a
                            type="button"
                            mdbBtn
                            color="danger"
                            class="waves-effect"
                            size="lg"
                            mdbWavesEffect
                            data-dismiss="modal"
                            (click)="unsubModal.hide()"
                            >Non</a
                        >
                    </div>
                </div>
                <!--/.Content-->
            </div>
        </div>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-recruteur/informations-personnelles" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span style="margin-right: 5rem">Mes autorisations</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Mes autorisations</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Mettez à jour vos autorisations</h2>
            </div>

            <div class="inscription-form">
                <div class="inscription-row d-flex flex-wrap">
                    <div class="col-md-6">
                        <label>Afficher ma photo et mon métier sur la page d'accueil du site.</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                                >Oui
                                <input
                                    type="radio"
                                    name="positions"
                                    [(ngModel)]="recruteur.autorisationAccueil"
                                    [value]="true"
                                    [checked]="recruteur.autorisationAccueil"
                                />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                                >Non
                                <input
                                    type="radio"
                                    name="positions"
                                    [(ngModel)]="recruteur.autorisationAccueil"
                                    [value]="false"
                                    [checked]="!recruteur.autorisationAccueil"
                                />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="inscription-row d-flex flex-wrap">
                    <div class="col-md-6">
                        <label
                            >Soumettre pour référencement ma fiche Recruteur à Google ? Le référencement / déréférencement par Google peut
                            prendre plusieurs semaines.</label
                        >
                    </div>
                    <div class="col-md-3">
                        <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                                >Oui
                                <input
                                    type="radio"
                                    name="autorisationReferencement"
                                    [(ngModel)]="recruteur.autorisationReferencement"
                                    [value]="true"
                                    [checked]="recruteur.autorisationReferencement"
                                />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                                >Non
                                <input
                                    type="radio"
                                    name="autorisationReferencement"
                                    [(ngModel)]="recruteur.autorisationReferencement"
                                    [value]="false"
                                    [checked]="!recruteur.autorisationReferencement"
                                />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="inscription-row d-flex flex-wrap">
                    <div class="col-md-6">
                        <label
                            >Etre informé(e) des offres préférentielles de nos partenaires (voir
                            <a target="_blank" href="/conditions-generales-d-utilisation-et-de-vente">CGUV</a>).</label
                        >
                    </div>
                    <div class="col-md-3">
                        <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                                >Oui
                                <input
                                    type="radio"
                                    name="autorisationOffre"
                                    [(ngModel)]="recruteur.autorisationOffre"
                                    [value]="true"
                                    [checked]="recruteur.autorisationOffre"
                                />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                                >Non
                                <input
                                    type="radio"
                                    name="autorisationOffre"
                                    [(ngModel)]="recruteur.autorisationOffre"
                                    [value]="false"
                                    [checked]="!recruteur.autorisationOffre"
                                />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="inscription-row d-flex flex-wrap">
                    <div class="col-md-6">
                        <label>Recevoir par email les candidatures des candidat(e)s qui souhaitent m'envoyer leur CV.</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                                >Oui
                                <input
                                    type="radio"
                                    name="autorisationReception"
                                    [(ngModel)]="recruteur.autorisationReception"
                                    [value]="true"
                                    [checked]="recruteur.autorisationReception"
                                />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group col-md-6">
                            <label class="container_radio version_2 check"
                                >Non
                                <input
                                    type="radio"
                                    name="autorisationReception"
                                    [(ngModel)]="recruteur.autorisationReception"
                                    [value]="false"
                                    [checked]="!recruteur.autorisationReception"
                                />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                  <div *ngIf='!recruteur.autorisationReception' class="col-12 col-md-6 mt-5">
                    <label>Recevoir par email un rappel du nombre de candidatures en attente de lecture :</label>
                  </div>
                  <div *ngIf='!recruteur.autorisationReception' class="col-12 col-md-3 mt-5">
                    <mdb-select-2
                      placeholder="Choisir.."
                      name="status"
                      class="custom-selectbox"
                      [outline]="true"
                      [(ngModel)]='recruteur.reminder'
                    >
                      <mdb-select-option *ngFor="let option of reminders" [value]="option.value">{{
                        option.label
                        }}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="float-right mt-2 mb-3">
                        <button mdbBtn class="text-center" [rounded]="true" color="warning" size="lg" (click)="sendAutorisation()">
                            Valider
                        </button>
                    </div>
                </div>
            </div>

            <div class="" style="font-size: 10px; float: right; margin-top: 50px">
                <button mdbBtn [rounded]="true" color="danger" size="sm" (click)="unsubModal.show()">
                    Me désinscrire d'Avenue Des Recruteurs
                </button>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
