<div class="row" style="width: 100%">
    <div class="col-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div>
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">Fiche de {{ recruteur.nom }} {{ recruteur.prenom }}</h3>
                    <div>{{ recruteur.dateNaissance }}</div>

                </div>
                <div id="wizard_container">
                    <div id="middle-wizard">
                        <div>
                            <div class="row">
                                <div class="col-md-6">
                                    <table style='table-layout: fixed' class="display table dataTable">
                                        <tr *ngIf="(recruteur.ficheComplete || recruteur.etape >= 2) && recruteur.photo">
                                            <td>Photo du recruteur</td>
                                            <td style="text-align: right">
                                                <img style="width: 150px" src="{{ url }}/images/recruteur/{{ recruteur.photo }}" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Référence recruteur</td>
                                            <td style="text-align: right">
                                                {{ recruteur.referenceRecruteur }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 2">
                                            <td>Email(s)</td>
                                            <td style="text-align: right">
                                                <a href="mailto:{{ recruteur.email }}">{{ recruteur.email }}</a>
                                                <span *ngIf="recruteur.emailPro !== ''"
                                                    >/ <a href="mailto:{{ recruteur.emailPro }}">{{ recruteur.emailPro }}</a></span
                                                >
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 2">
                                            <td>Téléphone(s)</td>
                                            <td style="text-align: right">
                                                <span *ngIf="recruteur.telephonePortable !== ''">{{ recruteur.telephonePortable }}</span>
                                                <span *ngIf="recruteur.telephonePortable !== '' && recruteur.telephoneFixe !== ''">
                                                    /
                                                </span>
                                                <span *ngIf="recruteur.telephoneFixe !== ''">{{ recruteur.telephoneFixe }}</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 2">
                                            <td>Métier du recruteur</td>
                                            <td style="text-align: right">
                                                {{ recruteur.fonctionRecruteur }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 5">
                                          <td colspan='2'>
                                            <app-poste class='d-flex flex-wrap'  [(data)]='recruteur.metiersRechercher' [admin]='true' [idCompte]='recruteur._id' [typeCompte]='"recruteur"'>
                                              <label for="fonction">Métier(s) recherché(s) par le recruteur</label>
                                            </app-poste>
                                          </td>

                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 5">
                                            <td>Type(s) de poste proposé(s)</td>
                                            <td style="text-align: right">
                                                <div *ngFor="let t of recruteur.typePoste">
                                                    <div *ngIf="t === 'salarie'">Salarié</div>
                                                    <div *ngIf="t === 'independant'">Indépendant</div>
                                                    <div *ngIf="t === 'stagiaire'">Stagiaire</div>
                                                    <div *ngIf="t === 'alternant'">Alternant</div>
                                                    <div *ngIf="t === 'benevole'">Bénévole</div>
                                                    <div *ngIf="t === 'autre'">Autre</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 5">
                                            <td>Type(s) de contrat proposé(s)</td>
                                            <td style="text-align: right">
                                                <div *ngFor="let t of recruteur.typePosteVoulu">
                                                    <div *ngIf="t === 'cdd'">CDD</div>
                                                    <div *ngIf="t === 'cdi'">CDI</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 5">
                                            <td>Temps proposé(s)</td>
                                            <td style="text-align: right">
                                                <div *ngFor="let t of recruteur.temps">
                                                    <div>
                                                        {{ t }}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 5">
                                            <td>Complément d'information(s) sur les profil(s) recherché(s)</td>
                                            <td style="text-align: right">
                                                {{ recruteur.profilRechercher }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 5">
                                          <td colspan='2'>
                                            <app-secteur class='d-flex flex-wrap' [(data)]='recruteur.secteurActivites' [admin]='true' [idCompte]='recruteur._id' [typeCompte]='"recruteur"'>
                                              <label for="secteursRechercher">Secteur(s) d'activité recherché(s) <small>Ce champ est multiple</small></label>
                                            </app-secteur>
                                          </td>
<!--                                            <td>Secteur(s) recherché(s)</td>-->
<!--                                            <td style="text-align: right">-->
<!--                                                <div *ngFor="let s of recruteur.secteurActivites">-->
<!--                                                    {{ s.nom }}-->
<!--                                                </div>-->
<!--                                            </td>-->
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <table class="display table dataTable">
                                        <tr>
                                            <td>
                                                <button class="form-control btn btn-success" (click)="envoiSansConnexion()">
                                                    Lien modification compte sans connexion
                                                </button>
                                            </td>
                                        </tr>
                                      <tr>
                                        <td colspan="2">
                                          <button class="form-control btn btn-danger" (click)="supprimer(recruteur._id)">Supprimer le compte</button>
                                        </td>
                                      </tr>
                                        <tr *ngIf="recruteur.status">
                                            <td>
                                                <button class="form-control btn btn-danger" (click)="bloquer()">Bloquer</button>
                                            </td>
                                        </tr>
                                        <tr *ngIf="!recruteur.status">
                                            <td>
                                                <button class="form-control btn btn-success" (click)="debloquer()">Débloquer</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <textarea
                                                    class="form-control"
                                                    rows="10"
                                                    type="text"
                                                    [(ngModel)]="nouvelleNote"
                                                    placeholder="Nouvelle note..."
                                                ></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <button class="form-control btn btn-success" (click)="ajouterNote()">Ajouter</button>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let n of recruteur.notes">
                                            <td>
                                                {{ n }}
                                                <button class="btn btn-danger form-control" (click)="supprimerNote(n)">Supprimer</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <table class="display table dataTable">
                                        <tr *ngIf="(recruteur.ficheComplete || recruteur.logoGroupe != '') && recruteur.logoGroupe">
                                            <td *ngIf="recruteur.etape >= 3">Logo de l'organisme</td>
                                            <td style="text-align: right">
                                                <img style="width: 150px" src="{{ url }}/images/entreprise/{{ recruteur.logoGroupe }}" />
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 4">
                                            <td>Type d'organisme</td>
                                            <td style="text-align: right">
                                                {{ recruteur.typeEntreprise }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 3">
                                            <td>Nom de l'organisme</td>
                                            <td style="text-align: right">
                                                {{ recruteur.nomSociete }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 4">
                                            <td>Site internet</td>
                                            <td style="text-align: right">
                                                <div *ngIf="recruteur.siteInternet !== ''">
                                                    <a target="_blank" href="{{ recruteur.siteInternet }}">{{ recruteur.siteInternet }}</a>
                                                </div>
                                                <div *ngIf="recruteur.siteInternet === ''">Non renseigné</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 3">
                                            <td>Nom du groupe</td>
                                            <td style="text-align: right">
                                                <div *ngIf="recruteur.nomGroupe === ''">Non renseigné</div>
                                                <div *ngIf="recruteur.nomGroupe !== ''">
                                                    {{ recruteur.nomGroupe }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 3">
                                            <td>Numéro d'identification (siret)</td>
                                            <td style="text-align: right">
                                                <a
                                                    href="https://www.societe.com/etablissement/societe-{{ recruteur.siret }}.html"
                                                    target="_blank"
                                                    >{{ recruteur.siret }}</a
                                                >
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 3">
                                            <td>Numéro de TVA</td>
                                            <td style="text-align: right">
                                                {{ recruteur.numeroTva }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 3">
                                            <td>Adresse</td>
                                            <td style="text-align: right">
                                                {{ recruteur.adresse }}
                                                {{ recruteur.complementAdresse }}, {{ recruteur.codePostal }} -
                                                {{ recruteur.ville }}
                                                {{ recruteur.pays }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 3">
                                            <td>Pays immatriculation</td>
                                            <td style="text-align: right">
                                                {{ recruteur.paysCode }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="(recruteur.ficheComplete || recruteur.etape >= 3) && recruteur.activite">
                                            <td>Activité de l'organisme</td>
                                            <td style="text-align: right">
                                                {{ recruteur.activite.nom }} -
                                                {{ recruteur.activite.codeNaf }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 4">
                                          <td colspan='2'>
                                            <app-secteur class='d-flex flex-wrap' [(data)]='recruteur.secteurEntreprise' [admin]='true' [idCompte]='recruteur._id' [typeCompte]='"recruteur"'>
                                              <label for="secteurEntreprise"
                                              >Secteur(s) d'activité de votre organisme * <small>Ce champ est multiple</small></label
                                              >
                                            </app-secteur>
                                          </td>
<!--                                            <td>Secteur(s) d'activité de l'organisme</td>-->
<!--                                            <td style="text-align: right">-->
<!--                                                <div *ngFor="let s of recruteur.secteurEntreprise">-->
<!--                                                    {{ s.nom }} - -->
<!--                                                    {{ s.codeNaf }}-->
<!--                                                </div>-->
<!--                                            </td>-->
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 4">
                                            <td>Activité(s) spécifique(s) de l'organisme</td>
                                            <td style="text-align: right">
                                                <div *ngFor="let s of recruteur.activiteSpecifique">
                                                    <span *ngIf="s === 'handicap'"> Handicap </span>
                                                    <span *ngIf="s === 'agence_interim'"> Agence d'intérim </span>
                                                    <span *ngIf="s === 'management_transition'"> Management de transition </span>
                                                    <span *ngIf="s === 'outplacement'"> Outplacement </span>
                                                    <span *ngIf="s === 'cabinet_recrutement'"> Cabinet de recrutement </span>
                                                    <span *ngIf="s === 'service_domicile'"> Service à domicile </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 4">
                                            <td>Complément d'information(s) sur l'activité</td>
                                            <td style="text-align: right">
                                                {{ recruteur.activiteTexte }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 4">
                                            <td>Nombre de salarié(s)</td>
                                            <td style="text-align: right">
                                                {{ recruteur.nombreSalarie }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 6">
                                            <td>Autorisation : Affichage de sa photo et de son métier sur la page d'accueil du site</td>
                                            <td style="text-align: right">
                                                <div *ngIf="recruteur.autorisationAccueil">Oui</div>
                                                <div *ngIf="!recruteur.autorisationAccueil">Non</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 6">
                                            <td>Autorisation : Soumission pour référencement de sa fiche Recruteur à Google</td>
                                            <td style="text-align: right">
                                                <div *ngIf="recruteur.autorisationReferencement">Oui</div>
                                                <div *ngIf="!recruteur.autorisationReferencement">Non</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 6">
                                            <td>Autorisation : être informé(e) des offres préférentielles des partenaires</td>
                                            <td style="text-align: right">
                                                <div *ngIf="recruteur.autorisationOffre">Oui</div>
                                                <div *ngIf="!recruteur.autorisationOffre">Non</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="recruteur.ficheComplete || recruteur.etape >= 6">
                                            <td>Autorisation : Recevoir les emails</td>
                                            <td style="text-align: right">
                                                <div *ngIf="recruteur.autorisationReception">Oui</div>
                                                <div *ngIf="!recruteur.autorisationReception">Non</div>
                                                <div *ngIf="!recruteur.autorisationReception && recruteur.reminder">Rappel : {{ recruteur.reminder }}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="!recruteur.ficheComplete">
                                            <td>Etape</td>
                                            <td style="text-align: right">
                                                <div *ngIf="recruteur.etape == 0">Password</div>
                                                <div *ngIf="recruteur.etape == 1">Informations Personnelles</div>
                                                <div *ngIf="recruteur.etape == 2">Son entreprise</div>
                                                <div *ngIf="recruteur.etape == 3">Complément d'informations</div>
                                                <div *ngIf="recruteur.etape == 4">Sa recherche</div>
                                                <div *ngIf="recruteur.etape == 5">Autorisation</div>
                                                <div *ngIf="recruteur.etape == 6">Fin</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
