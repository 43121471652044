<app-site-header></app-site-header>
<div class="row p-0" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="col-xl-10 col-xs-10 p-0">
        <div>
            <div class="">
                <div id="main-wrapper" class="">
                    <div class="login-hero-candidat-right-home position-relative">
                        <div class="login-slider">
                            <div class="login-slide slide-active">
                                <h3>
                                    Espace candidat <br />
                                    Comment ça fonctionne ?
                                </h3>
                                <div
                                    class="slide-content align-items-center py-2"
                                    *ngFor="let slide of candidatSlides; let title = index"
                                    [ngClass]="title === this.index ? 'slide-active' : ''"
                                >
                                    <h1>{{ title + 1 }}</h1>
                                    <h5>
                                        {{ slide.text }}
                                    </h5>
                                </div>
                                <div class="slide-icons d-flex justify-content-between align-items-center">
                                    <img class="prev" (click)="prev()" src="./assets/img/prev.png" alt="next" />
                                    <img class="next" (click)="next()" src="./assets/img/next.png" alt="next" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
