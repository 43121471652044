import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'remuneration',
})
export class RemunerationPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        let remuneration: string[] = [];

        if (value[0] === 0 && value[1] === 0 && value[2] === 0 && value[3] === 0) {
            return 'Aucune information spécifiée par le candidat';
        }

        if (value[0] !== 0) {
            remuneration.push(`${value[0]} € / Heure${value[1] !== 0 ? ', ' : ''}`);
        }

        if (value[1] !== 0) {
            remuneration.push(`${value[1]} € / Jour${value[2] !== 0 ? ', ' : ''}`);
        }

        if (value[2] !== 0) {
            remuneration.push(`${value[2]} € / Mois${value[3] !== 0 ? ', ' : ''}`);
        }

        if (value[3] !== 0) {
            remuneration.push(`${value[3]} € / Année`);
        }

        return remuneration.join(' ');
    }
}
