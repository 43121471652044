<app-site-header page="renouvellement-mot-de-passe"></app-site-header>
<div class="row light_bg full-height align-items-center d-flex contact-1" style="width: 100%; margin: 0">
    <div class="container">
        <div class="vc_column-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="oi_vc_heading oi_border_position_center">
                        <h1 class="oi_icon_titile" style="text-align: center">Demande de renouvellement de mon mot de passe</h1>
                        <div class="mention-text row">
                            <div class="woocommerce offset-md-3 col-md-6">
                                <div style="margin: 15px 0">
                                    <div class="mention-text">
                                        <div
                                            style="color: green !important"
                                            [ngStyle]="{
                                                color: email.valid ? 'green' : 'red'
                                            }"
                                        >
                                            <fa-icon [icon]="faOk" *ngIf="email.valid"></fa-icon>
                                            <fa-icon [icon]="faError" *ngIf="!email.valid"></fa-icon>
                                            {{ errorFormat }}
                                        </div>
                                        <div
                                            style="color: green !important"
                                            [ngStyle]="{
                                                color: email.present ? 'green' : 'red'
                                            }"
                                        >
                                            <fa-icon [icon]="faOk" *ngIf="email.present"></fa-icon>
                                            <fa-icon [icon]="faError" *ngIf="!email.present"></fa-icon>
                                            {{ errorCompte }}
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    <label for="typeCompte">Je suis un(e)</label>
                                    <select class="form-control" id="typeCompte" [(ngModel)]="typeCompte">
                                        <option value="">Choisir...</option>
                                        <option value="Candidat">Candidat(e)</option>
                                        <option value="Recruteur">Recruteur/teuse</option>
                                    </select>
                                </p>
                                <p class="pt-3">
                                    <label for="email">Mon e-mail</label>
                                    <input type="text" name="email" id="email" [(ngModel)]="email.text" />
                                </p>
                                <div class="form-row" style="display: block">
                                    <div style="display: flex; margin: auto">
                                        <button
                                            (click)="envoyer()"
                                            class="btn"
                                            style="margin: auto; background: rgb(228, 167, 0); color: white"
                                        >
                                            Changer mon mot de passe
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
