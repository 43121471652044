import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-surveillance-photo',
    templateUrl: './surveillance-photo.component.html',
    styleUrls: [
        './surveillance-photo.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/jquery.datatables.min.css',
    ],
})
export class SurveillancePhotoComponent implements OnInit {
    photos: any = [];
    typeCompte = '';
    sousTypeCompte = '';
    nombrePhotos = {
        candidat: 0,
        recruteurPhoto: 0,
        recruteurLogo: 0,
    };
    disabledRecherche = false;
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}
    ngOnInit(): void {
        setInterval(() => {
            this.nombrePhotos1();
            this.nombrePhotos2();
        }, 1000);
    }
    supprimer(id: string, doc: string): void {
        for (const p of this.photos) {
            if (p._id === id) {
                this.updateStatus(id, p.typeUtilisateur, p.doc, false);
                let t;
                if (p.typeUtilisateur === 'candidat') {
                    t = 'Candidat';
                } else {
                    t = 'Recruteur';
                }
                this.sendMailErr(id, t, doc);
                break;
            }
        }
        this.getDoc();
    }
    validerPage(): void {
        for (let p of this.photos) {
            this.updateStatus(p._id, p.typeUtilisateur, p.doc, true);
            let t;
            if (p.typeUtilisateur === 'candidat') {
                t = 'Candidat';
            } else {
                t = 'Recruteur';
            }
        }
        setTimeout(() => {
            this.getDoc();
        }, 500);
    }
    validerPhoto(id: string): void {
        for (let p of this.photos) {
            if (id === p._id) {
                this.updateStatus(id, p.typeUtilisateur, p.doc, true);
                let t;
                if (p.typeUtilisateur === 'candidat') {
                    t = 'Candidat';
                } else {
                    t = 'Recruteur';
                }
                break;
            }
        }
        setTimeout(() => {
            this.getDoc();
        }, 500);
    }
    updateStatus(id: string, type: string, document: string, status: boolean): void {
        this.httpClient.patch<any>(this.globalService.url + '/' + type + '/verifier/' + document, { id: id, status: status }, {}).subscribe(
            (response) => {},
            () => {}
        );
    }
    getDoc(): void {
        if (this.typeCompte === 'candidat') {
            this.getForCandidat();
        } else {
            this.getForRecruteur(this.sousTypeCompte);
        }
    }
    getForCandidat(): void {
        this.disabledRecherche = true;
        this.typeCompte = 'candidat';
        this.photos = [];
        this.httpClient.get<any>(this.globalService.url + '/candidat/getForVerification').subscribe(
            (response) => {
                if (response.status) {
                    for (const c of response.candidats) {
                        this.photos.push({
                            _id: c._id,
                            typeUtilisateur: 'candidat',
                            photo: this.globalService.url + '/images/candidat/' + c.photo,
                            doc: 'photo',
                        });
                    }
                    this.disabledRecherche = false;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    getForRecruteur(type: string): void {
        this.disabledRecherche = true;
        this.typeCompte = 'recruteur';
        if (type !== '') {
            this.sousTypeCompte = type;
            this.photos = [];
            this.httpClient.get<any>(this.globalService.url + '/recruteur/getForVerification/' + type, {}).subscribe(
                (response) => {
                    if (response.status) {
                        for (const r of response.recruteurs) {
                            let name;
                            if (type === 'photo') {
                                name = r.photo;
                            } else {
                                name = r.logoGroupe;
                            }
                            let dossier;
                            if (type === 'photo') {
                                dossier = 'recruteur';
                            } else {
                                dossier = 'entreprise';
                            }
                            this.photos.push({
                                _id: r._id,
                                typeUtilisateur: 'recruteur',
                                photo: this.globalService.url + '/images/' + dossier + '/' + name,
                                doc: type,
                            });
                        }
                        this.disabledRecherche = false;
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
        } else {
            this.disabledRecherche = false;
        }
    }
    nombrePhotos1(): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/getForVerification').subscribe(
            (response) => {
                if (response.status) {
                    if (response.candidats.length !== this.nombrePhotos.candidat) {
                        this.nombrePhotos.candidat = response.candidats.length;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    nombrePhotos2(): void {
        this.httpClient.get<any>(this.globalService.url + '/recruteur/getForVerification/photo', {}).subscribe(
            (response) => {
                if (response.status) {
                    if (this.nombrePhotos.recruteurPhoto !== response.recruteurs.length) {
                        this.nombrePhotos.recruteurPhoto = response.recruteurs.length;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
        this.httpClient.get<any>(this.globalService.url + '/recruteur/getForVerification/logo', {}).subscribe(
            (response) => {
                if (response.status) {
                    if (this.nombrePhotos.recruteurLogo !== response.recruteurs.length) {
                        this.nombrePhotos.recruteurLogo = response.recruteurs.length;
                    }
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    sendMailErr(id: string, type: string, doc: string): void {
        this.httpClient.put(this.globalService.url + '/mail/refus-document', { id: id, type: type, doc: doc }, {}).subscribe(
            (response) => {},
            () => {}
        );
    }
}
