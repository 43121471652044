import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mentions-legales',
    templateUrl: './mentions-legales.component.html',
    styleUrls: ['./mentions-legales.component.css'],
})
export class MentionsLegalesComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
