<app-site-header page="faqs"></app-site-header>
<div class="row light_bg full-height align-items-center d-flex contact-1" style="padding-top: 80px; width: 100%; margin: 0">
    <div class="container">
        <div class="vc_column-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="oi_vc_heading oi_border_position_center">
                        <h1 class="oi_icon_titile" style="text-align: center; margin-bottom: 30px">Questions - Réponses</h1>
                        <div class="row">
                            <div class="col-md-12" *ngFor="let f of faqs">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h1 class="oi_icon_titile oi_white" [innerHTML]="f.question"></h1>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="padding: 0" [innerHTML]="f.reponse"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
