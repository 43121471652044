<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="margin-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Gestion des administrateurs</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table class="display table dataTable">
                                    <thead>
                                        <tr>
                                            <th class="sorting">Identifiant</th>
                                            <th class="sorting_asc">Mot de passe</th>
                                            <th class="sorting_desc">Type</th>
                                            <th>Modifier mdp / type</th>
                                            <th class="sorting_desc">&Eacute;tat</th>
                                            <th>Supprimer</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    name="identifiant"
                                                    id="identifiant"
                                                    [(ngModel)]="newAdmin.identifiant"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    class="form-control"
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    [(ngModel)]="newAdmin.password"
                                                    placeholder="Nouveau mot de passe..."
                                                />
                                            </td>
                                            <td>
                                                <select class="form-control" [(ngModel)]="newAdmin.typeAdministrateur">
                                                    <option value="validateur">Validateur</option>
                                                    <option value="admin">Administrateur</option>
                                                    <option value="super_admin">Super Administrateur</option>
                                                </select>
                                            </td>
                                            <td colspan="3">
                                                <div>
                                                    <button class="btn btn-info" (click)="ajouter()">Ajouter</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let a of admins">
                                            <td>{{ a.identifiant }}</td>
                                            <td>
                                                <input class="form-control" type="password" [(ngModel)]="a.newPassword" />
                                            </td>
                                            <td>
                                                <select class="form-control" [(ngModel)]="a.typeAdministrateur">
                                                    <option value="validateur" [selected]="a.typeAdministrateur == 'validateur'">
                                                        Validateur
                                                    </option>
                                                    <option value="admin" [selected]="a.typeAdministrateur == 'admin'">
                                                        Administrateur
                                                    </option>
                                                    <option value="super_admin" [selected]="a.typeAdministrateur == 'super_admin'">
                                                        Super Administrateur
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <button class="btn btn-success" (click)="modifier(a._id)">Modifier le compte</button>
                                                </div>
                                            </td>
                                            <td>
                                                <select class="form-control" [(ngModel)]="a.status" (ngModelChange)="changerStatus(a._id)">
                                                    <option [ngValue]="true" [selected]="a.status === true">Actif</option>
                                                    <option [ngValue]="false" [selected]="!a.status">Inactif</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <button class="btn btn-danger" (click)="supprimer(a._id)">Supprimer</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
