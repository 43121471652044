<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-candidat/dashboard" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pl-0 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-candidat/informations-personnelles" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span style="margin-right: 5rem">Ajouter mon CV</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Ajouter mon CV</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Télécharger ici les documents qui seront transmis aux recruteurs lors de votre commande.</h2>
            </div>

            <div class="form-group inscription-row">
                <div class="d-flex flex-wrap align-items-center">
                    <div class="col-4 col-md-2">
                        <span class="font-weight-bold">Mon CV (.pdf)</span>
                    </div>
                    <div class="col-8 col-md-3">
                        <div *ngIf="candidat.cv !== ''">
                            <a target="_blank" href="{{ url }}/documents/cv/{{ candidat.cv }}"
                                ><small>{{ candidat.cv }}</small> <fa-icon class="ml-2" [icon]="faFilePdf"></fa-icon
                            ></a>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mt-3 mt-md-0">
                        <div class="file-field ml-3">
                            <div mdbBtn [rounded]="true" [outline]="false" color="secondary">
                                <span>{{ candidat.cv !== '' ? 'Modifier' : 'Ajouter' }}</span>
                                <input type="file" accept='.pdf' name="cvFile" mdbFileSelect (change)="cv($event)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
