import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from '../../mdb/free/modals';
import { ToastService } from '../../mdb/pro/alerts';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { GlobalVariable } from '../../../global';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'app-modal-login',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.css', './../../../../assets/css/administration/space.min.css'],
})
export class LoginModalComponent implements OnInit {
    @ViewChild('loginModal', { static: true }) loginModal!: ModalDirective;

    @Input() redirect = '';
    @Output() successLogin = new EventEmitter();

    error = '';
    loading = false;
    url = '';
    type1 = 'password';
    eye1 = faEye;
    faFacebook = faFacebook;
    faGoogle = faGoogle;
    faLinkedin = faLinkedin;

    login = {
        identifiant: '',
        password: '',
    };

    constructor(
        private toastrService: ToastService,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService
    ) {}
    ngOnInit(): void {
        this.url = this.globalService.url;
    }
    openForm(): void {
        this.loginModal.show();
    }
    eye(): void {
        if (this.eye1 === faEye) {
            this.eye1 = faEyeSlash;
            this.type1 = 'text';
        } else {
            this.eye1 = faEye;
            this.type1 = 'password';
        }
    }
    connexion(): void {
        this.loading = true;
        this.error = '';
        if (this.login.identifiant === '') {
            this.error = "Veuillez saisir vos codes d'accès";
            this.loading = false;
        } else if (this.login.password === '') {
            this.error = 'Veuillez saisir votre mot de passe';
            this.loading = false;
        } else {
            this.httpClient.post<any>(this.globalService.url + '/candidat/login', this.login).subscribe(
                (response) => {
                    this.loading = false;
                    if (response.status) {
                        if (response.ficheComplete) {
                            this.authService.storeUserData(response, 'candidat');
                            this.loginModal.hide();
                            this.successLogin.emit(true);
                        } else {
                            this.loading = false;
                            this.error =
                                "Vous n'avez pas encore accès à votre compte. Vous recevrez un courriel une fois votre compte débloqué.";
                        }
                    } else {
                        this.loading = false;
                        this.error = 'Identifiant ou mot de passe incorrect';
                    }
                },
                () => {
                    this.loading = false;
                    this.error = 'Identifiant ou mot de passe incorrect';
                }
            );
        }
    }
}
