import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Utils from '../../../utils';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../services/auth.service';
import { ToastService } from '../../mdb/pro/alerts';

@Component({
    selector: 'app-poste',
    templateUrl: './poste.component.html',
    styleUrls: ['./poste.component.css', '../../../../assets/css/site/form.scss'],
})
export class PosteComponent implements OnInit {
    searchMetiers = new Subject<string>();
    resultsMetiers: Observable<any> | undefined;
    dataMetiers: any = [];
    faFilePdf = faFilePdf;
    showRequest = false;
    requestSent = false;
    url = '';
    role = '';
    request = '';

    @Input() data: any;
    @Output() dataChange = new EventEmitter<any>();

    @Input() recherche: any;
    @Output() rechercheChange = new EventEmitter<any>();

    @Input() single = false;
    @Input() admin = false;
    @Input() idCompte = '';
    @Input() typeCompte = '';
    @Input() typeMetier = '';

    constructor(
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private authService: AuthService,
        private toastrService: ToastService
    ) {
        this.httpClient.get(this.globalService.url + '/metiers').subscribe((data: any) => {
            this.dataMetiers = data;
            this.resultsMetiers = this.searchMetiers.pipe(
                startWith(''),
                map((value: string) => Utils.filterMetier(value, this.dataMetiers))
            );
        });
    }

    ngOnInit(): void {
        this.url = this.globalService.url;
        this.role = this.authService.role;
    }

    choosePosteRecherche(codeOgr: string, poste: string): void {
        if (!this.admin) {
            this.recherche.metier = '';
            this.recherche.metierCache = '';
        }

        let exist = false;
        for (const p of this.data) {
            if (p.codeOgr === codeOgr) {
                exist = true;
                break;
            }
        }
        if (!exist) {
            this.data.push({ codeOgr, nom: poste });
        }
        this.searchMetiers.next('');
        if (this.admin) {
            this.updatePostes(this.data);
        }
    }
    removePosteRecherche(codeOgr: string): void {
        const newPostes = [];
        for (const p of this.data) {
            if (p.codeOgr !== codeOgr) {
                newPostes.push(p);
            }
        }
        this.dataChange.emit(newPostes);
        if (this.admin) {
            this.updatePostes(newPostes);
        }
    }
    setSingle(codeOgr: string, nom: string): void {
        this.dataChange.emit({ codeOgr, nom });
        if (this.admin) {
            this.updatePostes({ codeOgr, nom });
        }
    }
    removeSingle(): void {
        this.dataChange.emit(null);
        if (this.admin) {
            this.updatePostes({});
        }
    }
    sendPosteRequest(): void {
        this.httpClient
            .post(this.globalService.url + '/requests/add', {
                idCompte: this.authService.id,
                typeCompte: this.authService.role,
                request: this.request,
                extra: this.typeMetier,
                type: 'metier',
            })
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.toastrService.info('Demande envoyée', '', { opacity: 1 });
                        if (!this.requestSent) {
                            this.single ? this.setSingle('autre', 'Autre') : this.choosePosteRecherche('autre', 'Autre');
                            this.requestSent = true;
                        }
                        this.request = '';
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    updatePostes(postes: any): void {
        this.httpClient
            .post(this.globalService.url + '/' + this.typeCompte + '/' + (this.single ? 'updateFonction' : 'updatePostesRecherches'), {
                id: this.idCompte,
                postes,
            })
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.toastrService.info('Enregistrement réussi', '', { opacity: 1 });
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
}
