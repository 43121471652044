import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ValidateService {
    constructor() {}

    validateEmail(email: string): boolean {
        if (email === '') {
            return false;
        }
        const regularExpression =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regularExpression.test(String(email).toLowerCase());
    }

    containAt(email: string): boolean {
        if (email === '') {
            return false;
        }
        return email.includes('@');
    }
}
