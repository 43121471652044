import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
    authToken: any;
    data: any;
    role: any;
    id: any;
    picture: any;

    constructor(
        public jwtHelper: JwtHelperService,
        private globalService: GlobalService,
        private httpClient: HttpClient,
        private router: Router
    ) {
        const token = this.getToken();
        if (token && token !== 'undefined') {
            const decodedUser = this.jwtHelper.decodeToken(token);
            this.id = decodedUser.id;
            this.role = decodedUser.accountType;
            this.authToken = token;
        }
    }

    storeUserData(data: any, role: any): any {
        localStorage.setItem('id', data.id);
        localStorage.setItem('token', data.token);
        if (role === 'candidat') {
            localStorage.setItem('type', 'Candidat');
        } else if (role === 'recruteur') {
            localStorage.setItem('type', 'Recruteur');
        } else {
            localStorage.setItem('type', 'Administrateur');
            localStorage.setItem('typeAdministrateur', data.typeAdministrateur);
        }
        this.authToken = data.token;
        this.data = data;
        this.id = data.id;
        this.role = role;
        this.picture = data.photo;
        localStorage.setItem('photo', data.photo);
    }

    getToken(): any {
        return localStorage.getItem('token');
    }
    getPhoto(): any {
        if (localStorage.hasOwnProperty('photo')) {
            const photo = localStorage.getItem('photo');
            if (photo == null || photo === 'undefined') {
                return 'https://media.istockphoto.com/vectors/profile-placeholder-image-gray-silhouette-no-photo-vector-id1016744004?k=20&m=1016744004&s=612x612&w=0&h=Z4W8y-2T0W-mQM-Sxt41CGS16bByUo4efOIJuyNBHgI=';
            }
            return `${this.globalService.url}/images/${this.role}/${photo}`;
        }
        return 'https://media.istockphoto.com/vectors/profile-placeholder-image-gray-silhouette-no-photo-vector-id1016744004?k=20&m=1016744004&s=612x612&w=0&h=Z4W8y-2T0W-mQM-Sxt41CGS16bByUo4efOIJuyNBHgI=';
    }
    loggedIn(): any {
        return this.id != null && this.authToken != null && !this.jwtHelper.isTokenExpired(this.getToken());
    }

    async isUserEnabled(): Promise<boolean> {
        if (this.role !== 'candidat' && this.role !== 'recruteur') {
            return true;
        }
        return this.httpClient
            .get<any>(`${this.globalService.url}/${this.role}/get-status/${this.id}`, {})
            .toPromise()
            .then((response) => {
                if (response.status) {
                    if (!response.ficheComplete) {
                        this.router.navigate(this.role === 'candidat' ? ['/inscription-candidat'] : ['/inscription-recruteur']);
                    }

                    return response.enabled && response.emailVerified;
                }
                return false;
            });
    }

    logout(): any {
        this.authToken = null;
        this.data = null;
        this.role = null;
        localStorage.clear();
        window.location.href = '/';
    }
}
