import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from '../../mdb/free/modals';
import { ToastService } from '../../mdb/pro/alerts';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { GlobalVariable } from '../../../global';

@Component({
    selector: 'app-modal-report',
    templateUrl: './report-modal.component.html',
    styleUrls: ['./report-modal.component.css', './../../../../assets/css/administration/space.min.css'],
})
export class ReportModalComponent implements OnInit {
    @ViewChild('reportModal', { static: true }) reportModal!: ModalDirective;
    reportsStates = GlobalVariable.REPORTS;

    description = '';
    note = '';
    status = '';
    file = '';
    fileName = '';
    reportId = '';
    url = '';
    archived = false;

    @Input() admin = false;
    @Input() reportedId = '';
    @Input() reportedRole: any;
    @Input() modal: any;
    @Output() selected: EventEmitter<any> = new EventEmitter();
    @Output() statusChange: EventEmitter<any> = new EventEmitter();
    constructor(
        private toastrService: ToastService,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService
    ) {}
    ngOnInit(): void {
        this.url = this.globalService.url;
    }
    openForm(): void {
        this.reportModal.show();
        if (this.reportId !== '' && this.admin) {
            this.getReport();
        }
    }
    fichier(f: any): void {
        const file = f.target.files.item(0);
        this.fileName = file.name;
        const reader = new FileReader();
        const extensions = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

        if (extensions.indexOf(file.type) > -1) {
            if (file.size <= 5000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.file = reader.result;
                };
            } else {
                this.toastrService.error("L'image importée est supérieur à 5Mo. veuillez réduire sa taille", '', { opacity: 1 });
            }
        } else {
            this.toastrService.error("Le document que vous venez d'importer n'est pas dans un format valide", '', { opacity: 1 });
        }
    }
    sendReport(): void {
        if (this.description === '') {
            this.toastrService.error('Veuillez renseigner une raison', '', { opacity: 1 });
            return;
        }

        this.httpClient
            .post(
                this.globalService.url + '/reports',
                {
                    description: this.description,
                    reportedId: this.reportedId,
                    reportedRole: this.reportedRole,
                    id: this.authService.id,
                    file: this.file,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        this.toastrService.error('Erreur', '', { opacity: 1 });
                    } else {
                        this.toastrService.info('Signalement envoyé', '', { opacity: 1 });
                        this.modal.show();
                        this.reportModal.hide();
                        this.description = '';
                        this.file = '';
                        this.fileName = '';
                    }
                },
                () => {}
            );
    }
    getReport(): void {
        this.httpClient.get(this.globalService.url + '/reports/' + this.reportId, {}).subscribe(
            (response: any) => {
                if (!response.status) {
                    this.toastrService.error('Erreur', '', { opacity: 1 });
                } else {
                    this.description = response.report.description;
                    this.fileName = response.report.fichier;
                    this.status = response.report.status;
                    this.archived = response.report.archived;
                }
            },
            () => {}
        );
    }
    update(): void {
        this.httpClient
            .patch(
                this.globalService.url + '/reports/' + this.reportId,
                { note: this.note, archived: this.archived, status: this.status },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        this.toastrService.error('Erreur', '', { opacity: 1 });
                    } else {
                        this.statusChange.emit();
                    }
                },
                () => {}
            );
    }
    setReportId(id: string): void {
        this.reportId = id;
    }
}
