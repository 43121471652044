<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10 content-right">
        <div id="wizard_container">
            <div id="middle-wizard">
                <div>
                    <h2 class="section_title" style="text-align: left; margin-bottom: 20px">Recruteurs déjà contactés</h2>
                    <p class="section_title">
                        Dans votre sélection, 10 recruteurs ont déjà reçu votre CV. Souhaitez-vous leur adresser à nouveau votre CV ?
                    </p>
                    <div class="form-group">
                        <button (click)="suivant(true)" class="btn btn-success">Oui</button>
                    </div>
                    <div class="form-group">
                        <button (click)="suivant(false)" class="btn btn-danger">Non</button>
                    </div>
                    <div class="form-group">
                        <a style="color: rgb(1, 73, 179) !important" routerLink="/espace-candidat/email-a-retirer/{{ id }}"
                            ><fa-icon [icon]="arrowLeft" style="margin-right: 15px"></fa-icon>Retour</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
