import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-log-social',
    templateUrl: './log-social.component.html',
    styleUrls: ['./log-social.component.css'],
})
export class LogSocialComponent implements OnInit {
    typeAccount = '';
    social = '';
    id = '';
    email = '';
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private authService: AuthService,
        private globalService: GlobalService,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (this.route.snapshot.params.typeAccount !== undefined && this.route.snapshot.params.typeAccount !== null) {
            this.typeAccount = this.route.snapshot.params.typeAccount;
        }
        if (this.route.snapshot.params.social !== undefined && this.route.snapshot.params.social !== null) {
            this.social = this.route.snapshot.params.social;
        }
        if (this.route.snapshot.params.id !== undefined && this.route.snapshot.params.id !== null) {
            this.id = this.route.snapshot.params.id;
        }
        if (this.route.snapshot.params.email !== undefined && this.route.snapshot.params.email !== null) {
            this.email = this.route.snapshot.params.email;
        }
        if (this.route.snapshot.queryParams.firstname !== undefined && this.route.snapshot.queryParams.firstname !== null) {
            localStorage.setItem('social_first_name', this.route.snapshot.queryParams.firstname);
        }
        if (this.route.snapshot.queryParams.lastname !== undefined && this.route.snapshot.queryParams.lastname !== null) {
            localStorage.setItem('social_last_name', this.route.snapshot.queryParams.lastname);
        }
        if (this.route.snapshot.queryParams.picture !== undefined && this.route.snapshot.queryParams.picture !== null) {
            localStorage.setItem('social_picture', this.route.snapshot.queryParams.picture);
            console.log(this.route.snapshot.queryParams.picture);
        }
        this.getAccount();
    }
    getAccount(): void {
        this.httpClient
            .get<any>(this.globalService.url + '/' + this.typeAccount + '/social/' + this.social + '/' + this.id + '/' + this.email)
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.authService.storeUserData(response, this.typeAccount);

                        if (
                            this.route.snapshot.queryParams.redirect !== undefined &&
                            this.route.snapshot.queryParams.redirect !== null &&
                            this.route.snapshot.queryParams.redirect !== ''
                        ) {
                            this.router.navigate([this.route.snapshot.queryParams.redirect]);
                        } else {
                            if (response.inscriptionFini) {
                                if (this.typeAccount === 'candidat') {
                                    this.router.navigate(['/espace-candidat/dashboard']);
                                } else {
                                    this.router.navigate(['/espace-recruteur/dashboard']);
                                }
                            } else {
                                if (this.typeAccount === 'candidat') {
                                    this.router.navigate(['/inscription-candidat/' + this.email]);
                                } else {
                                    this.router.navigate(['/inscription-recruteur/' + this.email]);
                                }
                            }
                        }
                    }
                },
                () => {}
            );
    }
}
