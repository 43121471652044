<span style="color: red !important" *ngIf="paysNonSelectionner">Choisissez d'abord le pays</span>
<div class="md-form mb-0 mt-0">
    <input
        type="text"
        name="villeRechercher"
        class="completer-input form-control mdb-autocomplete bc-white"
        [ngModel]="user.adresse"
        (ngModelChange)="changeAddr($event)"
        [mdbAutoCompleter]="autoAdresse"
    />
    <mdb-auto-completer class="visible" #autoAdresse="mdbAutoCompleter" textNoResults="Pas de résultat">
        <mdb-option *ngIf="loading">
            <div class="spinner-border spinner-border-sm ml-auto mr-auto text-dark" role="status">
                <span class="sr-only">Chargement...</span>
            </div>
        </mdb-option>
        <mdb-option
            [hidden]="loading"
            *ngFor="let addr of resultsAdresse | async"
            [value]="addr.adresse"
            (click)="chooseAddr(addr.adresse, addr.postcode, addr.city, addr.latitude, addr.longitude)"
        >
            <div class="d-flex flex-column">
                <strong>{{ addr.adresse }}, {{ addr.postcode }} {{ addr.city }}</strong>
            </div>
        </mdb-option>
    </mdb-auto-completer>
</div>
