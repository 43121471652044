import { Component, OnInit } from '@angular/core';
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { ToastService } from '../../../components/mdb/pro/alerts';

@Component({
    selector: 'app-envoi-mail-renouvellement',
    templateUrl: './envoi-mail-renouvellement.component.html',
    styleUrls: ['./envoi-mail-renouvellement.component.css'],
})
export class EnvoiMailRenouvellementComponent implements OnInit {
    faError = faTimes;
    faOk = faCheckCircle;
    typeCompte = '';
    email = {
        text: '',
        valid: false,
        present: false,
        fini: false,
    };

    afficher = false;
    errorFormat = "L'e-mail saisi n'est pas au bon format";
    errorCompte = "L'e-mail saisi ne correspond à aucun compte";

    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {}

    ngOnInit(): void {
        setInterval(() => {
            this.analyserValide();
            if (this.email.text.length > 3 && !this.email.fini) {
                this.analyseExist();
            }
        }, 200);
    }
    analyseExist(): void {
        let t = '';
        if (this.typeCompte === 'Candidat') {
            t = 'candidat';
        } else if (this.typeCompte === 'Recruteur') {
            t = 'recruteur';
        }
        if (t !== '') {
            this.httpClient.get(this.globalService.url + '/' + t + '/email-existe/' + this.email.text).subscribe(
                (response: any) => {
                    if (response.status) {
                        this.email.present = response.exist;
                        if (this.email.present) {
                            this.errorCompte = "L'e-mail saisi correspond à un de nos comptes";
                        } else {
                            this.errorCompte = "L'e-mail saisi ne correspond à aucun de nos comptes";
                        }
                    } else {
                        this.email.present = false;
                    }
                },
                () => {
                    this.email.present = false;
                }
            );
        }
    }
    analyserValide(): void {
        this.email.text = this.email.text.toLowerCase();
        if (this.email.text.match(/[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i)) {
            this.email.valid = true;
            this.errorFormat = "L'e-mail saisi est au bon format";
        } else {
            this.email.valid = false;
            this.errorFormat = "L'e-mail saisi n'est pas au bon format";
        }
    }
    envoyer(): void {
        this.afficher = true;
        if (this.email.valid && this.email.present && this.typeCompte !== '') {
            this.httpClient
                .put<any>(this.globalService.url + '/mail/renouvellement-password', { email: this.email.text, type: this.typeCompte })
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this.email.fini = true;
                            this.toastrService.info(
                                'Votre demande a bien été prise en compte. Vous allez recevoir un courriel avec un lien de renouvellement.',
                                '',
                                { opacity: 1 }
                            );
                        }
                    },
                    () => {}
                );
        } else if (this.typeCompte === '') {
            this.email.fini = false;
            this.toastrService.error('Veuillez choisir un type de compte.', '', { opacity: 1 });
        } else if (!this.email.present) {
            this.email.fini = false;
            this.toastrService.error("L'e-mail ne correspond à aucun compte.", '', { opacity: 1 });
        } else {
            this.email.fini = false;
            this.toastrService.error('E-mail non conforme.', '', {
                opacity: 1,
            });
        }
    }
}
