<app-site-header page="cguv"></app-site-header>
<div class="row light_bg full-height align-items-center d-flex contact-1" style="width: 100%; margin: 0; padding-top: 80px">
    <div class="container">
        <div class="vc_column-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="oi_vc_heading oi_border_position_center">
                        <h1 class="oi_icon_titile" style="text-align: center">Votre désabonnement</h1>
                        <div class="mention-text" style="text-align: center">
                            Votre adresse mail a bien été ajouté à notre liste de désabonnement.<br />
                            Vous ne recevrez plus aucun mail de notre part.<br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
