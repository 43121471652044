import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { DragulaService } from 'ng2-dragula';
import { faPen, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ModalDirective } from '../../../components/mdb/free/modals';
import { ToastService } from '../../../components/mdb/pro/alerts';
@Component({
    selector: 'app-avantages',
    templateUrl: './avantages.component.html',
    styleUrls: ['./avantages.component.css', './../../../../assets/css/administration/space.min.css'],
})
export class AvantagesComponent implements OnInit {
    @ViewChild('postModal', { static: true }) postModal!: ModalDirective;
    url = '';
    faPen = faPen;
    faSave = faSave;
    faTrash = faTrash;
    avantages: any = [];
    posts: any = [];
    editing = false;
    type = '';
    post = {
        _id: '',
        titre: '',
        contenu: '',
        image: '',
        categorie: '',
        showButton: false,
        price: '',
        course: '',
        courseTitle: '',
        nouvelleImage: '',
    };
    titre = 'Sans titre';
    modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ['clean'], // remove formatting button
            ['link'],
        ],
    };
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private toastrService: ToastService,
        private globalService: GlobalService,
        private dragulaService: DragulaService
    ) {
        this.dragulaService.createGroup('COLUMNS', {
            direction: 'horizontal',
            moves: (el, source, handle) => (handle ? handle.className === 'group-handle' : false),
        });
    }

    ngOnInit(): void {
        if (this.route.snapshot.params.type !== undefined && this.route.snapshot.params.type !== null) {
            this.type = this.route.snapshot.params.type;
        }
        this.url = this.globalService.url;
        this.getAvantages();
    }
    reset(): void {
        this.post = {
            _id: '',
            titre: '',
            contenu: '',
            image: '',
            courseTitle: '',
            price: '',
            showButton: false,
            course: '',
            categorie: '',
            nouvelleImage: '',
        };
    }
    photo(f: any, id: string): void {
        const file = f.target.files.item(0);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (id === '-1') {
                // @ts-ignore
                this.post.image = reader.result;
            } else {
                // @ts-ignore
                this.post.nouvelleImage = reader.result;
            }
        };
    }
    updateOrders(): void {
        for (const [index, val] of this.avantages.entries()) {
            val.ordre = index + 1;
            this.saveCategories(val);
        }
    }
    getAvantages(): void {
        this.avantages = [];
        this.httpClient.get<any>(`${this.globalService.url}/avantages?type=${this.type}`).subscribe(
            (response) => {
                if (response.status) {
                    this.avantages = response.avantages;
                } else {
                }
            },
            () => {}
        );
    }
    supprimerCategorie(id: string): void {
        this.httpClient.delete<any>(this.globalService.url + '/avantages/' + id).subscribe(
            (response) => {
                if (response.status) {
                    this.getAvantages();
                    this.toastrService.info('Enregistrement réussi', '', { opacity: 1 });
                } else {
                }
            },
            () => {}
        );
    }
    supprimerPost(id: string): void {
        this.httpClient.delete<any>(this.globalService.url + '/avantages-posts/' + id).subscribe(
            (response) => {
                if (response.status) {
                    this.getAvantages();
                    this.toastrService.info('Enregistrement réussi', '', { opacity: 1 });
                } else {
                }
            },
            () => {}
        );
    }
    ajouter(): void {
        this.httpClient.post(this.globalService.url + '/avantages', { titre: this.titre, type: this.type }).subscribe(
            (response: any) => {
                if (response.status) {
                    this.getAvantages();
                }
            },
            () => {}
        );
    }
    openModal(categoryId: string): void {
        this.post.categorie = categoryId;
        this.postModal.show();
    }
    loadPost(postId: string): void {
        this.httpClient.get(this.globalService.url + '/avantages-posts/' + postId).subscribe(
            (response: any) => {
                if (response.status) {
                    this.post = response.post;
                    this.post.nouvelleImage = '';
                    this.postModal.show();
                }
            },
            () => {}
        );
    }
    addPost(): void {
        if (this.post._id === '') {
            this.httpClient
                .post(this.globalService.url + '/avantages-posts', {
                    titre: this.post.titre,
                    image: this.post.image,
                    contenu: this.post.contenu,
                    category: this.post.categorie,
                    showButton: this.post.showButton,
                    price: this.post.price,
                    course: this.post.course,
                    courseTitle: this.post.courseTitle,
                })
                .subscribe(
                    (response: any) => {
                        if (response.status) {
                            const category = this.avantages.find((a: { _id: string }) => a._id === this.post.categorie);
                            category.posts.push(response.id);
                            this.saveCategories(category);
                            this.reset();
                            this.toastrService.info('Enregistrement réussi', '', { opacity: 1 });
                        }
                    },
                    (error) => {
                        this.toastrService.error(error.message, '', { opacity: 1 });
                    }
                );
        } else {
            this.httpClient
                .put(this.globalService.url + '/avantages-posts/' + this.post._id, {
                    titre: this.post.titre,
                    image: this.post.image,
                    nouvellePhoto: this.post.nouvelleImage,
                    contenu: this.post.contenu,
                    category: this.post.categorie,
                    showButton: this.post.showButton,
                    price: this.post.price,
                    course: this.post.course,
                    courseTitle: this.post.courseTitle,
                })
                .subscribe(
                    (response: any) => {
                        this.getAvantages();
                        if (response.status) {
                            this.reset();
                            this.toastrService.info('Enregistrement réussi', '', { opacity: 1 });
                        }
                    },
                    (error) => {
                        this.toastrService.error(error.message, '', { opacity: 1 });
                    }
                );
        }
    }
    saveCategories(category: { ordre: any; _id: any; titre: any; posts: any }): void {
        this.httpClient
            .patch(this.globalService.url + '/avantages', {
                id: category._id,
                titre: category.titre,
                posts: category.posts,
                ordre: category.ordre,
            })
            .subscribe(
                (response: any) => {
                    if (response.status) {
                        this.getAvantages();
                    }
                },
                () => {}
            );
    }
}
