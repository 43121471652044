<div class="md-form mt-0 mb-0">
    <input
        type="text"
        placeholder='Localisation'
        name="villeRechercher"
        class="completer-input form-control mdb-autocomplete bc-white"
        [ngModel]="place.libelle"
        (ngModelChange)="changePlace($event)"
        [mdbAutoCompleter]="autoAdresse"
        (clearBtnClicked)="clear()"
    />
    <mdb-auto-completer class="visible" #autoAdresse="mdbAutoCompleter" textNoResults="Pas de résultat">
        <mdb-option *ngIf="loading">
            <div class="spinner-border spinner-border-sm ml-auto mr-auto text-dark" role="status">
                <span class="sr-only">Chargement...</span>
            </div>
        </mdb-option>
        <mdb-option
            [hidden]="loading"
            *ngFor="let addr of resultsPlace | async"
            [value]="addr.libelle"
            (click)="choosePlace(addr.code, addr.libelle, addr.type)"
        >
            <div class="d-flex flex-column">
                <strong>{{ addr.libelle }}</strong>
            </div>
        </mdb-option>
    </mdb-auto-completer>
</div>
