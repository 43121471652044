<app-site-header page="cguv"></app-site-header>
<div class="row light_bg full-height align-items-center d-flex contact-1" style="width: 100%; margin: 0; padding-top: 80px">
    <div class="container">
        <div class="vc_column-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="oi_vc_heading oi_border_position_center">
                        <h1 class="oi_icon_titile" style="text-align: center">Votre e-mail a été vérifié</h1>
                        <div class="mention-text" style="text-align: center">
                            Votre e-mail a bien été vérifié.<br />
                            Vous recevrez prochainement un second courriel quand votre compte aura été contrôlé et sera accessible.<br />
                            <a class="btn btn-success" routerLink="/">Aller à l'accueil</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
