<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div>
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">
                        Commandes de {{ candidat.civilite }} {{ candidat.nom }}
                        {{ candidat.prenom }}
                    </h3>
                </div>
                <div id="main-wrapper">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="panel panel-white">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <div *ngIf="commandes.length === 0">Ce candidat n'a passé aucune commande</div>
                                        <table class="display table dataTable" *ngIf="commandes.length !== 0">
                                            <thead>
                                                <tr>
                                                    <th>N° de commande</th>
                                                    <th>Date de commande</th>
                                                    <th>Date de diffusion</th>
                                                    <th>Heure de diffusion</th>
                                                    <th>Nombre de candidature(s) envoyé(e)s</th>
                                                    <th>Prix TTC</th>
                                                    <th>Statut</th>
                                                    <th>Consulter</th>
                                                    <th>Motif refus</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let c of commandes">
                                                    <td>
                                                        {{ c.numeroCommande }}
                                                    </td>
                                                    <td>
                                                        {{ c.dateCommande * 1000 | date: 'dd-MM-yyyy' }}
                                                    </td>
                                                    <td>
                                                        <div *ngIf="c.dateDebutDiffusion === 0">Sans préférence</div>
                                                        <div *ngIf="c.dateDebutDiffusion !== 0">
                                                            {{ c.dateDebutDiffusion | date: 'dd-MM-yyyy' }}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="c.heureDebutDiffusion === 0">Sans préférence</div>
                                                        <div *ngIf="c.heureDebutDiffusion !== 0">
                                                            {{ c.heureDebutDiffusion }}
                                                            heure
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {{ c.nombreCourriel - c.recruteursRetrait.length }}
                                                    </td>
                                                    <td>
                                                        <div
                                                            *ngIf="
                                                                c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                                    c.reduction.montantTtc -
                                                                    c.credit >
                                                                    0 &&
                                                                c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                                    c.reduction.montantTtc -
                                                                    c.credit <
                                                                    1
                                                            "
                                                        >
                                                            1 €
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                                    c.reduction.montantTtc -
                                                                    c.credit <=
                                                                0
                                                            "
                                                        >
                                                            Gratuit
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                                    c.reduction.montantTtc -
                                                                    c.credit >=
                                                                1
                                                            "
                                                        >
                                                            {{
                                                                (
                                                                    c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                                    c.reduction.montantTtc -
                                                                    c.credit
                                                                ).toFixed(2)
                                                            }}
                                                            €
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="c.commandeAccepter">Accepté</span>
                                                        <span *ngIf="c.commandeRefuserTemporaire">Refuser temporairement</span>
                                                        <span *ngIf="c.commandeRefuserDefinitif">Refuser définitivement</span>
                                                        <span
                                                            *ngIf="
                                                                !c.commandeAccepter &&
                                                                !c.commandeRefuserTemporaire &&
                                                                !c.commandeRefuserDefinitif
                                                            "
                                                            >En attente de validation</span
                                                        >
                                                    </td>
                                                    <td>
                                                        <a
                                                            *ngIf="!c.commandeRefuserTemporaire"
                                                            class="btn btn-success"
                                                            target="_blank"
                                                            routerLink="/espace-candidat/commande/{{ c._id }}"
                                                            >Consulter</a
                                                        >
                                                        <a
                                                            *ngIf="c.commandeRefuserTemporaire"
                                                            class="btn btn-success"
                                                            target="_blank"
                                                            routerLink="/espace-candidat/modifier-commande/{{ c._id }}"
                                                            >Modifier</a
                                                        >
                                                    </td>
                                                    <td>
                                                        <span *ngIf="!c.commandeRefuserTemporaire && !c.commandeRefuserDefinitif">#</span>
                                                        <span *ngIf="c.commandeRefuserTemporaire || c.commandeRefuserDefinitif">{{
                                                            c.motifRefus
                                                        }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--div class="col-md-12">
              <div class="container-pagination">
                <a class="paginate_button previous disabled">Précédent</a>
                <span>
                  <a class="paginate_button current">1</a>
                  <a class="paginate_button">2</a>
                  <a class="paginate_button">3</a>
                  <a class="paginate_button">4</a>
                  <a class="paginate_button">5</a>
                  <a class="paginate_button">6</a>
                  <a class="paginate_button">7</a>
                  <a class="paginate_button">8</a>
                  <a class="paginate_button">9</a>
                </span>
                <a class="paginate_button next">Suivant</a>
              </div>
            </div-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
