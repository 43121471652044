<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Gestion des métiers</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table style="width: 100%">
                                    <tr>
                                        <td style="width: 50%; text-align: left">
                                            <button class="btn btn-success" (click)="download()">Exporter XLS</button>
                                        </td>
                                        <td style="width: 50%; text-align: right">
                                            <input
                                                class="form-control"
                                                type="text"
                                                (input)="getMetiers()"
                                                [(ngModel)]="recherche"
                                                placeholder="Rechercher..."
                                            />
                                        </td>
                                    </tr>
                                </table>
                                <table class="display mt-3 table dataTable">
                                    <thead>
                                        <tr>
                                          <th (click)="changeSens('codeMetier')" [ngClass]="tri == 'codeMetier' ? sensClass : 'sorting'">Code Métier</th>
                                          <th (click)="changeSens('codeOgr')" [ngClass]="tri == 'codeOgr' ? sensClass : 'sorting'">Code Ogr</th>
                                          <th (click)="changeSens('groupePrincipal')" [ngClass]="tri == 'groupePrincipal' ? sensClass : 'sorting'">Groupe Principal</th>
                                          <th (click)="changeSens('groupeSecondaire')" [ngClass]="tri == 'groupeSecondaire' ? sensClass : 'sorting'">Groupe Secondaire</th>
                                          <th (click)="changeSens('nomMetier')" [ngClass]="tri == 'nomMetier' ? sensClass : 'sorting'">Nom</th>
                                          <th>Utilisations</th>
                                          <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                      <td class="sansPadding">
                                        <input
                                          class="form-control"
                                          type="text"
                                          [(ngModel)]="nouveauMetier.codeMetier"
                                          [ngStyle]="{
                                                        border: nouveauMetier.codeMetierErr ? '1px solid red' : ''
                                                    }"
                                        />
                                      </td>
                                      <td class="sansPadding" >
                                        <input
                                          class="form-control"
                                          type="text"
                                          [(ngModel)]="nouveauMetier.codeOgr"
                                          [ngStyle]="{
                                                        border: nouveauMetier.codeOgrErr ? '1px solid red' : ''
                                                    }"
                                        />
                                      </td>
                                      <td class="sansPadding">
                                        <input
                                          class="form-control"
                                          type="text"
                                          [(ngModel)]="nouveauMetier.groupePrincipal"
                                          [ngStyle]="{
                                                        border: nouveauMetier.groupePrincipalErr ? '1px solid red' : ''
                                                    }"
                                        />
                                      </td>
                                      <td class="sansPadding">
                                        <input
                                          class="form-control"
                                          type="text"
                                          [(ngModel)]="nouveauMetier.groupeSecondaire"
                                          [ngStyle]="{
                                                        border: nouveauMetier.groupeSecondaireErr ? '1px solid red' : ''
                                                    }"
                                        />
                                      </td>
                                      <td class="sansPadding">
                                        <input
                                          class="form-control"
                                          type="text"
                                          [(ngModel)]="nouveauMetier.nomMetier"
                                          [ngStyle]="{
                                                        border: nouveauMetier.nomMetierErr ? '1px solid red' : ''
                                                    }"
                                        />
                                      </td>
                                      <td></td>
                                      <td class="sansPadding">
                                        <button class="btn btn-info" (click)="ajouter()">Ajouter</button>
                                      </td>
                                    </tr>
                                        <tr *ngFor="let m of metiers">
                                          <td>
                                            {{ m.codeMetier }}
                                          </td>
                                            <td>
                                                {{ m.codeOgr }}
                                            </td>
                                          <td>
                                            {{ m.groupePrincipal }}
                                          </td>
                                          <td>
                                            {{ m.groupeSecondaire }}
                                          </td>
                                          <td>
                                            {{ m.nomMetier }}
                                          </td>
                                          <td>
                                            {{ m.count }}
                                            <br>
                                            <a *ngIf='m.count > 0'  color="primary" class="waves-light" type="button" (click)="list.toggle()" mdbWavesEffect>
                                             Afficher
                                            </a>
                                            <div mdbCollapse [isCollapsed]="true" #list="bs-collapse">
                                              <ul>
                                                <li *ngFor='let account of m.assigned'>
                                                  <a   target="_blank"
                                                       routerLink="/administration/{{account.type === 'candidat' ? 'candidat' : 'recruteur'}}/{{ account.id }}" > {{account.nom}}</a>
                                                </li>
                                              </ul>
                                            </div>

                                          </td>
                                          <td>
                                            <button (click)="supprimer(m._id)" class="btn btn-danger">Supprimer</button>

                                          </td>
                                        </tr>
                                        <tr>
                                            <td [colSpan]="colspanUn"></td>
                                            <td [colSpan]="colspanDeux">
                                                <select class="form-control" [(ngModel)]="nbParPage" (ngModelChange)="changeNombre()">
                                                    <option value="10" [selected]="nbParPage === 10">Afficher 10 métiers</option>
                                                    <option value="25" [selected]="nbParPage === 25">Afficher 25 métiers</option>
                                                    <option value="50" [selected]="nbParPage === 50">Afficher 50 métiers</option>
                                                    <option value="100" [selected]="nbParPage === 100">Afficher 100 métiers</option>
                                                    <option value="250" [selected]="nbParPage === 250">Afficher 250 métiers</option>
                                                    <option value="500" [selected]="nbParPage === 500">Afficher 500 métiers</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="container-pagination">
                        <span>
                            <a *ngIf="page != 0" (click)="changePage(page - 1)" class="paginate_button">Précedent</a>
                            <span *ngFor="let p of pagination">
                                <span *ngIf="p == -1">...</span>
                                <a *ngIf="p != -1" (click)="changePage(p)" class="paginate_button" [ngClass]="{ current: page == p }">{{
                                    p + 1
                                }}</a>
                            </span>
                            <a *ngIf="page != nombrePage" (click)="changePage(page + 1)" class="paginate_button">Suivant</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
