import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-traducteur',
    templateUrl: './traducteur.component.html',
    styleUrls: ['./traducteur.component.css'],
})
export class TraducteurComponent implements OnInit {
    pays: any = [];
    langues: any = [];
    choix = 'pays';
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.getPays();
    }
    changerChoix(text: string): void {
        this.choix = text;
        if (text === 'pays') {
            this.getPays();
        } else if (text === 'langues') {
            this.getLangues();
        }
    }
    getLangues(): void {
        this.httpClient.get<any>(this.globalService.url + '/langues').subscribe(
            (response) => {
                if (response.status) {
                    this.langues = response.langues;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    updateLangue(id: string): void {
        for (const l of this.langues) {
            if (l._id === id) {
                this.httpClient.patch<any>(this.globalService.url + '/langues', { id: id, langue: l.langue }, {}).subscribe(
                    (response) => {},
                    () => {}
                );
            }
        }
    }
    getPays(): void {
        this.httpClient.get<any>(this.globalService.url + '/pays/getPays').subscribe(
            (response) => {
                if (response.status) {
                    this.pays = response.pays;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    updatePays(id: string): void {
        for (const p of this.pays) {
            if (p._id === id) {
                this.httpClient.patch<any>(this.globalService.url + '/ville', { id, texte: p.pays, indicatif: p.indicatif }, {}).subscribe(
                    (response) => {},
                    () => {}
                );
            }
        }
    }
}
