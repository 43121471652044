import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { ToastService } from '../../../components/mdb/pro/alerts';

@Component({
    selector: 'app-metiers-non-assignes',
    templateUrl: './metiers-non-assignes.component.html',
    styleUrls: [
        './metiers-non-assignes.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/jquery.datatables.min.css',
    ],
})
export class MetiersNonAssignesComponent implements OnInit {
    type: string | null = '';
    tri = 'dateCreation';
    sens = 'desc';
    sensClass = 'sorting_desc';
    recherche = '';
    requests: any = [];
    colspanUn = 10;
    colspanDeux = 2;
    nouveauMetier = {
        codeMetier: '',
        codeOgr: '',
        groupePrincipal: '',
        groupeSecondaire: '',
        nomMetier: '',
        groupeSecondaireErr: false,
        nomMetierErr: false,
        groupePrincipalErr: false,
        codeOgrErr: false,
        codeMetierErr: false,
    };
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {}
    ngOnInit(): void {
        this.getRequests();
    }
    getRequests(): void {
        this.httpClient
            .post<any>(
                this.globalService.url + '/requests',
                {
                    type: 'metier',
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.requests = response.requests;
                    } else {
                        this.requests = [];
                    }
                },
                () => {
                    this.requests = [];
                }
            );
    }

    ajouter(): void {
        if (this.nouveauMetier.codeMetier === '') {
            this.nouveauMetier.codeMetierErr = true;
            return;
        }

        if (this.nouveauMetier.codeOgr === '') {
            this.nouveauMetier.codeOgrErr = true;
            return;
        }
        if (this.nouveauMetier.groupePrincipal === '') {
            this.nouveauMetier.groupePrincipalErr = true;
            return;
        }
        if (this.nouveauMetier.groupeSecondaire === '') {
            this.nouveauMetier.groupeSecondaireErr = true;
            return;
        }
        if (this.nouveauMetier.nomMetier === '') {
            this.nouveauMetier.nomMetierErr = true;
            return;
        }
        this.httpClient
            .post(this.globalService.url + '/metiers/add', {
                codeMetier: this.nouveauMetier.codeMetier,
                codeOgr: this.nouveauMetier.codeOgr,
                groupePrincipal: this.nouveauMetier.groupePrincipal,
                groupeSecondaire: this.nouveauMetier.groupeSecondaire,
                nomMetier: this.nouveauMetier.nomMetier,
            })
            .subscribe(
                (response) => {
                    this.getRequests();
                    this.toastrService.info('Métier ajouté', '', { opacity: 1 });
                    this.nouveauMetier = {
                        codeMetier: '',
                        codeOgr: '',
                        groupePrincipal: '',
                        groupeSecondaire: '',
                        nomMetier: '',
                        groupeSecondaireErr: false,
                        nomMetierErr: false,
                        groupePrincipalErr: false,
                        codeOgrErr: false,
                        codeMetierErr: false,
                    };
                },
                () => {}
            );
    }

    supprimer(id: string): void {
        this.httpClient.delete(this.globalService.url + '/requests/' + id).subscribe(
            (response) => {
                this.getRequests();
                // this.toastrService.info('Métier supprimé', '', { opacity: 1 });
            },
            () => {}
        );
    }
}
