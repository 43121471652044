import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faArrowLeft, faFilePdf } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-modifier-commande',
    templateUrl: './modifier-commande.component.html',
    styleUrls: [
        './modifier-commande.component.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class ModifierCommandeComponent implements OnInit {
    arrowLeft = faArrowLeft;
    faFilePdf = faFilePdf;
    id = '';
    sujet = '';
    emailDiffusion = '';
    nomCandidat = '';
    nom = '';
    lettreMotivation = '';
    cv = '';
    errorImport = false;
    ancienCv = '';
    url = '';
    error = false;
    erreurTexte = '';
    cvUpload = '1';
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (this.route.snapshot.params.id !== undefined && this.route.snapshot.params.id !== null) {
            this.id = this.route.snapshot.params.id;
            this.url = this.globalService.url;
            this.getCommande();
        }
    }
    getAccount(idCandidat: string): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/' + idCandidat).subscribe(
            (response) => {
                if (response.status) {
                    this.nomCandidat = response.candidat.prenom + ' ' + response.candidat.nom;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    uploadCv(f: any): void {
        this.cvUpload = '2';
        const file = f.target.files.item(0);
        const reader = new FileReader();
        if (file.type === 'application/pdf') {
            if (file.size <= 3000000) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // @ts-ignore
                    this.cv = reader.result;
                    this.errorImport = false;
                    this.save();
                };
            } else {
                this.cvUpload = '';
                alert('Le document importé est supérieur à 3Mo. veuillez réduire sa taille');
                this.errorImport = true;
            }
        } else {
            this.cvUpload = '';
            alert(
                "Le document que vous venez d'essayer d'importer n'est pas au format PDF. Vous pouvez uniquement importer un CV au format PDF."
            );
            this.errorImport = true;
        }
    }
    save(): void {
        let cv = this.cv;
        this.httpClient
            .patch<any>(
                this.globalService.url + '/commande/lettre-motivation',
                {
                    idCommande: this.id,
                    sujet: this.sujet,
                    email: this.emailDiffusion,
                    lettreMotivation: this.lettreMotivation,
                    cv: cv,
                    modification: 2,
                },
                {}
            )
            .subscribe(
                () => {
                    this.cvUpload = '';
                    this.getCommande();
                },
                () => {
                    this.cvUpload = '';
                }
            );
    }
    getCommande(): void {
        this.httpClient.get<any>(this.globalService.url + '/commande/' + this.id).subscribe(
            (response) => {
                if (response.status) {
                    this.sujet = response.commande.sujet;
                    this.emailDiffusion = response.commande.email;
                    this.lettreMotivation = response.commande.lettreMotivation;
                    this.ancienCv = response.commande.lienCv;
                    this.getAccount(response.commande.idCandidat);
                }
            },
            () => {}
        );
    }
    suivant(): void {
        this.error = false;
        if (
            this.sujet !== '' &&
            this.emailDiffusion !== '' &&
            this.lettreMotivation !== '' &&
            this.lettreMotivation.length >= 100 &&
            !this.errorImport
        ) {
            this.httpClient
                .patch<any>(
                    this.globalService.url + '/commande/modifier-commande',
                    {
                        idCommande: this.id,
                        sujet: this.sujet,
                        email: this.emailDiffusion,
                        lettreMotivation: this.lettreMotivation,
                        cv: this.cv,
                    },
                    {}
                )
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this.router.navigate(['/espace-candidat/mes-commandes']);
                        }
                    },
                    () => {}
                );
        } else if (this.errorImport) {
            this.error = true;
            this.erreurTexte = "Le CV que vous avez tenté d'importer n'est pas conforme";
        } else if (this.lettreMotivation.length < 10) {
            this.error = true;
            this.erreurTexte = 'Votre lettre de motivation doit contenir au moins 100 caractères';
        } else {
            this.error = true;
            this.erreurTexte = 'Des informations sont manquantes';
        }
    }
}
