<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10 content-right">
        <div id="wizard_container">
            <div id="middle-wizard">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <img src="./assets/img/erreur.png" style="text-align: center" />
                        </div>
                        <div class="col-12">
                            <p>Votre commande n'a pas pu aboutir.</p>
                            <p>
                                Si vous rencontrez un problème quelconque pour procéder à votre paiement, vous pouvez nous écrire à
                                <a href="mailto:contact@avenuedesrecruteurs.fr">contact@avenuedesrecruteurs.fr</a>
                                pour nous en faire part.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
