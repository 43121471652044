<app-site-header page="conseils"></app-site-header>
<div class="row light_bg full-height align-items-center d-flex contact-1" style="padding-top: 80px; width: 100%; margin: 0">
    <div class="container">
        <div class="vc_column-inner">
            <div class="row">
                <div class="col-12">
                    <div class="oi_vc_heading oi_border_position_center">
                        <h2 class="oi_icon_titile" style="text-align: center; margin-bottom: 30px">
                            Conseils de François AUER co-fondateur d’AvenueDesRecruteurs.fr
                        </h2>
                        <div class="row">
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <div class="oi_icon_titile oi_white">
                                        <div class="col-12 p-0 p-md-5 d-flex justify-content-center">
                                            <video
                                                class="video-fluid z-depth-1"
                                                style="width: 100%; max-height: 1200px; max-width: 1200px"
                                                loop
                                                controls
                                                muted
                                            >
                                                <source src="./assets/video/conseils.webm" type="video/webm" />
                                                <source src="./assets/video/conseils.mp4" type="video/mp4" />
                                                Problème lors du chargement de la vidéo
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h3 class="oi_icon_titile oi_white">
                                        Pourquoi se faire accompagner par un professionnel de l'accompagnement au retour à l'emploi ?
                                    </h3>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="color: black !important; padding: 0">
                                                Tout simplement, parce qu'il est aujourd'hui long et difficile de trouver un emploi et que
                                                l'accompagnement par un consultant expérimenté va vous permettre non seulement de
                                                <strong>gagner beaucoup de temps</strong>, mais aussi d'<strong
                                                    >éviter de commettre des erreurs</strong
                                                >
                                                préjudiciables (qui sont parfois irréparables).<br /><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h3 class="oi_icon_titile oi_white">Qu'est-ce qu'un CV efficace ?</h3>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="color: black !important; padding: 0">
                                                C'est un CV qui vous permet d'obtenir rapidement des rendez-vous avec des recruteurs.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h3 class="oi_icon_titile oi_white">
                                        Quelles sont les conditions de base pour que votre CV soit efficace ?
                                    </h3>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="color: black !important; padding: 0">
                                                Votre CV doit :<br /><br />
                                                1 – être complet, c'est-à-dire comporter toutes les informations indispensables pour que le
                                                recruteur puisse le traiter et vous recontacter rapidement.<br /><br />
                                                Il doit donc indiquer les informations suivantes :<br />
                                                <ul>
                                                    <li>Vos coordonnées</li>
                                                    <li>Le poste que vous recherchez</li>
                                                    <li>La date à laquelle vous êtes disponible</li>
                                                    <li>L'endroit où vous recherchez une nouvelle mission</li>
                                                    <li>
                                                        Vos atouts concurrentiels (pourquoi le recruteur doit vous embaucher, vous, plutôt
                                                        qu'une autre personne).
                                                    </li>
                                                </ul>
                                                En effet, si ces informations sont manquantes, le recruteur n'aura pas obligatoirement le
                                                temps et/ou la volonté de vous recontacter pour vous les demander. Il aura tendance à
                                                privilégier les CV où ces informations sont présentes.<br /><br />
                                                2 – être accrocheur, structuré, aéré et attirer le regard du recruteur sur les points qui
                                                sont vraiment importants pour le poste recherché.<br /><br />
                                                Si le CV est composé de blocs imposants, si certains textes sont trop petits, si l'œil est
                                                attiré par les titres ou par des informations accessoires, le recruteur risque de passer à
                                                côté des informations importantes.<br /><br />
                                                3 – être rapidement compréhensible par tous (même par les personnes qui ne connaissent
                                                absolument rien de votre métier et/ou votre secteur d'activité)<br /><br />
                                                4 – montrer la plus-value (qualitative et/ou quantitative) que vous avez apportée dans vos
                                                précédentes expériences professionnelles et donc que vous seriez à même d'apporter à votre
                                                futur employeur<br /><br />
                                                Si votre CV ne répond pas à ces critères, je vous suggère de retravailler dessus, car c'est
                                                <strong>l'outil de base de la recherche d'emploi</strong>.<br /><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h3 class="oi_icon_titile oi_white">Comment rédiger un CV efficace ?</h3>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="color: black !important; padding: 0">
                                                1 - Tout d'abord, je vous conseille de le faire rédiger par un tiers. Soit par un
                                                professionnel de l'accompagnement au retour à l'emploi qui connait bien les attentes des
                                                recruteurs et qui est expérimenté dans la rédaction de CV, soit par un ami réputé pour sa
                                                franchise. Pourquoi par un tiers, c'est tout simplement parce qu'il est difficile de prendre
                                                de la hauteur par rapport à soi-même et d'utiliser des termes compréhensibles par tout le
                                                monde.<br /><br />
                                                2 – Renseignez-vous sur les savoir-faire et les savoir-être indispensables pour le poste
                                                concerné, afin de les faire ressortir dans votre CV.<br /><br />
                                                3 - En début de CV, il est important d'indiquer vos coordonnées, le poste recherché, votre
                                                mobilité, votre disponibilité, vos atouts concurrentiels ainsi que vos compétences
                                                principales.<br /><br />
                                                4 - Ensuite, présentez vos expériences (en faisant ressortir la plus-value que vous avez
                                                apportée dans chacune d'elles), puis votre formation (sauf si vous êtes un jeune
                                                professionnel. Dans ce cas, il est conseillé d'indiquer sa formation en premier).<br /><br />
                                                5 - N'oubliez pas d'intégrer les mots clés les plus importants afin que votre CV puisse être
                                                ultérieurement retrouvé facilement par les recruteurs dans leurs bases de données.<br /><br />
                                                6 - Il faut ensuite travailler sa mise en forme afin que votre CV soit accrocheur, simple,
                                                agréable, rapide à lire et ne fasse ressortir que les informations importantes...
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h3 class="oi_icon_titile oi_white">Faut-il faire un CV « 1 page » ou « 2 pages » ?</h3>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="color: black !important; padding: 0">
                                                Le but du CV n'est pas d'être exhaustif, mais de donner envie de vous rencontrer. Il ne doit
                                                comporter que des informations valorisantes et utiles par rapport au poste recherché.<br /><br />
                                                On ne peut répondre à la question concernant le nombre de pages qu'une fois que le fond du
                                                CV a été travaillé. La question à se poser alors est la suivante : Sur 1 page, mon CV est-il
                                                suffisamment complet et agréable à lire ? Si c'est le cas, il faut rester sur un CV 1 page.
                                                Si ce n'est pas le cas, il faut faire un CV sur 2 pages. Il vaut mieux un CV agréable à lire
                                                sur 2 pages qu'un CV peu lisible sur 1 page.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h3 class="oi_icon_titile oi_white">Faut-il mettre une photo sur son CV ?</h3>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="color: black !important; padding: 0">
                                                Une photo permet d'attirer l'attention du recruteur. Il ne faut la mettre que si elle donne
                                                envie de vous rencontrer. Vous devez apparaitre professionnel(le) et sympathique.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h3 class="oi_icon_titile oi_white">
                                        Un consultant m'a dit que mon CV était bon et pourtant je n'arrive pas à obtenir de rendez-vous…
                                    </h3>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="color: black !important; padding: 0">
                                                Peut-être ce consultant n'est-il pas suffisamment expérimenté, disponible et/ou prêt à vous
                                                consacrer plusieurs heures pour retravailler en profondeur votre CV … ?
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h3 class="oi_icon_titile oi_white">
                                        Quels sont vos principaux conseils concernant la lettre de motivation ?
                                    </h3>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="color: black !important; padding: 0">
                                                La lettre de motivation a pour unique objectif de donner envie de lire votre CV et de vous
                                                rencontrer. Elle doit donc être courte, claire et sympathique.<br />
                                                Evitez la phrase "Je recherche un emploi". Le rôle du recruteur n'est pas de vous rendre
                                                service. Préférez la formule "Je souhaiterais vous apporter mes compétences..."<br />
                                                Pour certains recruteurs et/ou pour certains postes, les fautes d'orthographe et/ou les
                                                manques de rigueur sont éliminatoires.<br />
                                                Faites relire votre lettre de motivation par des amis...
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h3 class="oi_icon_titile oi_white">
                                        Quels sont les moyens les plus efficaces pour trouver un emploi ?
                                    </h3>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="color: black !important; padding: 0">
                                                Pour moi, dans l'ordre décroissant d'efficacité :<br /><br />
                                                1 – Solliciter son réseau proche (familial, amical, professionnel…) : Les personnes qui vous
                                                connaissent et vous apprécient.<br />
                                                2 – Adresser des candidatures spontanées « qualitatives » : Définir une liste de 10/20/30…
                                                recruteurs potentiels pour lesquels vous avez des atouts concurrentiels significatifs, se
                                                renseigner sur leur activité et essayer de rencontrer les décideurs.<br />
                                                3 –
                                                <strong>Adresser des candidatures spontanées « quantitatives » (en grand nombre)</strong
                                                ><br />
                                                4 – Participer à des réunions réseaux<br />
                                                5 – Répondre aux offres d'emploi : fait partie des moyens les moins efficaces pour trouver
                                                un emploi<br />
                                                6 – ...
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h3 class="oi_icon_titile oi_white">
                                        Pourquoi la candidature spontanée « quantitative » est-elle aussi efficace ?
                                    </h3>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="color: black !important; padding: 0">
                                                La candidature spontanée « quantitative » consiste à adresser son CV à un maximum de
                                                recruteurs susceptibles d'être intéressés par vos compétences.<br /><br />
                                                C'est la même technique que pour la vente par correspondance, et comme pour la vente par
                                                correspondance ça fonctionne :<br /><br />
                                                Autre atout,
                                                <strong>vous êtes plus en situation de force</strong>
                                                que lorsque vous répondez à une offre d'emploi. Suite à une offre d'emploi, le recruteur
                                                reçoit de très nombreuses candidatures. Si ce n'est pas vous qu'il recrute, ce sera
                                                quelqu'un d'autre… Dans le cas d'une candidature spontanée, le recruteur n'aura pas
                                                obligatoirement diffusé d'offre d'emploi et donc de nombreux CV concurrents. Vous aurez
                                                ainsi plus de chance de le pousser à prendre rapidement une décision et à vous faire une
                                                offre attractive.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="oi_vc_heading oi_border_position_bottom text-justify">
                                    <h3 class="oi_icon_titile oi_white">
                                        Lorsqu'on se présente lors d'un entretien, faut-il donner beaucoup ou peu d'informations ?
                                    </h3>
                                    <div style="margin-bottom: 15px" class="oi_heading_border oi_border_position_bottom text-justify">
                                        <span class="bc-orange"></span>
                                    </div>
                                </div>
                                <div class="item-testimon">
                                    <div class="oi_testimonial_holder text-justify">
                                        <div style="padding: 0" class="oi_testimonial_content_holder">
                                            <div style="color: black !important; padding: 0">
                                                Présentez, en quelques minutes, la spécificité de votre expérience. Soyez concis et précis.
                                                Ne rentrez dans le détail qu'à la demande de votre interlocuteur.<br /><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
