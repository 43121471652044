<div *ngIf="!default" class="range-field" #rangeField>
  <div class="track">
    <div #rangeCloud class="range-cloud" title="range" [ngClass]="{ visible: this.visibility }">
      <span class="text-transform">{{ range }}</span>
    </div>
  </div>
  <input
    #input
    [name]="name"
    type="range"
    [disabled]="disabled"
    [id]="id"
    [min]="min"
    [max]="max"
    [step]="step"
    [value]="value"
    [(ngModel)]="range"
    (input)="coverage($event)"
    (mousedown)="focusRangeInput()"
    (mouseup)="blurRangeInput()"
    (touchstart)="focusRangeInput()"
    (touchend)="blurRangeInput()"
    (blur)="blurRangeInput()"
  />
</div>

<div *ngIf="default">
  <input
    #input
    class="custom-range"
    [name]="name"
    type="range"
    [id]="id"
    [min]="min"
    [max]="max"
    [step]="step"
    [attr.value]="value"
    [value]="value"
    [(ngModel)]="range"
    (input)="coverage($event)"
    (mousedown)="focusRangeInput()"
    (mouseup)="blurRangeInput()"
    (touchstart)="focusRangeInput()"
    (touchend)="blurRangeInput()"
    (blur)="blurRangeInput()"
  />
  <span class="{{ defaultRangeCounterClass }}">{{ range }}</span>
</div>
