import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../components/mdb/pro/alerts';
import { UserService } from '../../../services/user.service';
import { GlobalVariable } from '../../../global';

@Component({
    selector: 'app-autorisations-recruteurs',
    templateUrl: './autorisations.component.html',
    styleUrls: [
        './autorisations.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/inscription/style.css',
    ],
})
export class AutorisationsRecruteursComponent implements OnInit {
    arrowLeft = faArrowLeft;
    reminders = GlobalVariable.REMINDERS;

    recruteur: any = {
        password: '',
    };
    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private toastrService: ToastService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.recruteur._id = localStorage.getItem('id');
        this.getAccount();
    }
    getAccount(): void {
        this.userService.getAccount().then(
            (response: any) => {
                if (response.status) {
                    this.recruteur = response.recruteur;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    sendAutorisation(): void {
        this.httpClient
            .patch(
                this.globalService.url + '/recruteur/autorisation',
                {
                    id: this.recruteur._id,
                    autorisationAccueil: this.recruteur.autorisationAccueil,
                    autorisationReferencement: this.recruteur.autorisationReferencement,
                    autorisationOffre: this.recruteur.autorisationOffre,
                    autorisationReception: this.recruteur.autorisationReception,
                    reminder: this.recruteur.reminder,
                },
                {}
            )
            .subscribe(
                (response: any) => {
                    if (!response.status) {
                        this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                    } else {
                        this.toastrService.info('Autorisations modifiées', '', {
                            opacity: 1,
                        });
                        this.getAccount();
                    }
                },
                () => {}
            );
    }
    desinscrire(): void {
        this.httpClient
            .patch<any>(this.globalService.url + '/recruteur/status', { id: this.recruteur._id, status: false, desinscription: true }, {})
            .subscribe(
                (response) => {
                    if (response.status) {
                        localStorage.clear();
                        this.router.navigate(['/']);
                    } else {
                        this.toastrService.error('Erreur lors de la modification', '', { opacity: 1 });
                    }
                },
                () => {}
            );
    }
}
