import { Component, OnInit } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-liste-diffusion-terminer',
    templateUrl: './liste-diffusion-terminer.component.html',
    styleUrls: ['./liste-diffusion-terminer.component.css'],
})
export class ListeDiffusionTerminerComponent implements OnInit {
    faPdf = faFilePdf;
    commandes: any = [];
    url = '';
    token: string | null = '';
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.getCommande();
        this.url = this.globalService.url;
        this.token = localStorage.getItem('token');
    }
    getCommande(): void {
        this.httpClient
            .post<any>(
                this.globalService.url + '/commande/get-all',
                {
                    attente: false,
                    enCours: false,
                    terminer: true,
                    temporaire: false,
                    definitif: false,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.commandes = response.commandes;
                    }
                },
                (err) => {}
            );
    }
}
