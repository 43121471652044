<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10 content-right">
        <div class="page-inner">
            <div class="page-title">
                <h2 class="breadcrumb-header">Finalisation</h2>
            </div>
          <div *ngIf='generic'>
            <div class="row">
              <div class="col-12 col-md-6">
                <div style="font-size: 24px" class="font-weight-bold pb-5">
                  Votre commande a bien été enregistrée.
                  <fa-icon [icon]="faCheck" class="fa-3x text-warning"></fa-icon>
                </div>
              </div>
              <div class="col-md-6 d-none d-md-block">
                <img src="./assets/img/Group%201185.png" style="width: 85%" />
              </div>
            </div>
          </div>
            <div *ngIf='!generic'>
                <div class="row" *ngIf="isValid && send">
                    <div class="col-12 col-md-6">
                        <div style="font-size: 24px" class="font-weight-bold pb-5">
                            Votre commande {{ commande.numeroCommande }} a bien été enregistrée.
                            <fa-icon [icon]="faCheck" class="fa-3x text-warning"></fa-icon>
                        </div>
                        <div style="font-size: 20px">
                            Vous allez prochainement recevoir votre facture par courriel. Cette dernière sera également disponible dans la
                            partie "Mes factures" de votre espace candidat.
                            <br /><br />Votre commande est en cours de traitement pas nos équipes. Vous recevrez un courriel dès que l'envoi
                            de votre CV sera terminé.<br /><br />
                            Merci pour votre confiance :-)
                        </div>
                    </div>
                    <div class="col-md-6 d-none d-md-block">
                        <img src="./assets/img/Group%201185.png" style="width: 85%" />
                    </div>
                </div>
                <div class="row" *ngIf="!isValid || !send">
                    <div class="col-12">
                        <img src="./assets/img/gif.gif" style="max-width: 80px; text-align: center" />
                    </div>
                    <div class="col-12">
                        <p style="color: red !important; font-size: 24px">Dernière étape !!</p>
                        <p>
                            Vous allez recevoir un mail de la part de SendinBlue (contact@sendinblue.com) pour activer votre email en tant
                            que diffuseur.
                        </p>
                        <p>Cette action nous permet d'envoyer votre CV en votre nom avec votre adresse e-mail.</p>
                        <p>
                            Il vous suffit de cliquer sur le bouton présent dans ce courriel.
                            <img style="width: 300px" src="assets/img/activer_expediteur.png" />
                            Pas besoin de créer de compte chez eux.
                        </p>
                        <p>La synchronisation peut prendre jusqu'à 5 minutes.</p>
                        <p>Cette étape est à réaliser uniquement lors de votre première commande et à chaque fois que vous changerez d’adresse e-mail d’expéditeur.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
