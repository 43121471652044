<app-site-header></app-site-header>

<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-recruteur-header></app-recruteur-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10" style="margin-top: 25px">
        <div class="page-inner">
            <div class="page-title">
                <h2 class="breadcrumb-header">Mes informations personnelles</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Gardez vos informations à jour</h2>
            </div>
            <div class="row mt-3">
                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/mail 2.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-recruteur/mot-de-passe" class="btn btn-light shadow">
                                Mot de passe
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/mail 7.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-recruteur/informations" class="btn btn-light shadow">
                                Informations Personnelles
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/pexels-max-vakhtbovych-7511753.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-recruteur/mon-organisme" class="btn btn-light shadow">
                                Mon organisme
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/mail 4.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-recruteur/informations-complementaires" class="btn btn-light shadow">
                                Informations Complémentaires
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/mail 6.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-recruteur/recherche" class="btn btn-light shadow">
                                Profils recherchés
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 p-3 mb-3 mb-md-0">
                    <div class="card border-0 position-relative">
                        <div class="holder position-relative">
                            <img src="./assets/img/mail 3.png" class="img-fluid card-img-top" alt="" />
                            <div class="img-overlay"></div>
                        </div>
                        <div class="bottomm">
                            <a href="/espace-recruteur/autorisations" class="btn btn-light shadow">
                                Mes autorisations
                                <fa-icon [icon]="faArrowRight" class="ml-2"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
