import 'quill';

export interface Config {
    container: string;
    unit: 'mot' | 'caractère';
}

export interface QuillInstance {
    on: any;
    getText: any;
}

export default class Counter {
    quill: QuillInstance;
    options: Config;

    constructor(quill: QuillInstance, options: Config) {
        this.quill = quill;
        this.options = options;

        const container = document.querySelector(this.options.container);

        this.quill.on('editor-change', () => {
            const length = this.calculate();

            // @ts-ignore
            container.innerHTML = `${length} ${this.options.unit}${length > 1 ? 's' : ''}`;
        });
    }

    calculate(): number {
        const text = this.quill.getText().trim();

        if (this.options.unit === 'mot') {
            return !text ? 0 : text.split(/\s+/).length;
        }
        return text.length;
    }
}
