import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ReusableService {
    constructor(private httpClient: HttpClient, private globalService: GlobalService) {}

    getPays(): any {
        return this.httpClient
            .get<any>(this.globalService.url + '/pays/getPays')
            .toPromise()
            .then((response) => {
                return response.pays;
            });
    }
}
