<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Gestion des recruteurs</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table style="width: 100%">
                                    <tr>
                                        <td style="width: 50%; text-align: left">
                                            <button class="btn btn-success" (click)="download()">Exporter XLS</button>
                                        </td>
                                        <td style="width: 50%; text-align: right">
                                            <input
                                                class="form-control"
                                                type="text"
                                                (input)="getRecruteur()"
                                                [(ngModel)]="recherche"
                                                placeholder="Rechercher..."
                                            />
                                        </td>
                                    </tr>
                                </table>
                                <table class="display table dataTable">
                                    <thead>
                                        <tr>
                                            <th
                                                (click)="changeSens('dateCreation')"
                                                [ngClass]="tri == 'dateCreation' ? sensClass : 'sorting'"
                                            >
                                                Inscription
                                            </th>
                                            <th
                                                (click)="changeSens('dateMiseAJour')"
                                                [ngClass]="tri == 'dateMiseAJour' ? sensClass : 'sorting'"
                                            >
                                                Mise à jour
                                            </th>
                                            <th (click)="changeSens('referenceRecruteur')" [ngClass]="tri == 'referenceRecruteur' ? sensClass : 'sorting'">Référence</th>
                                            <th (click)="changeSens('nom')" [ngClass]="tri == 'nom' ? sensClass : 'sorting'">Nom</th>
                                            <th (click)="changeSens('prenom')" [ngClass]="tri == 'prenom' ? sensClass : 'sorting'">
                                                Prénom
                                            </th>
                                            <th (click)="changeSens('email')" [ngClass]="tri == 'email' ? sensClass : 'sorting'">Email</th>
                                            <th
                                                (click)="changeSens('telephonePortable')"
                                                [ngClass]="tri == 'telephonePortable' ? sensClass : 'sorting'"
                                            >
                                                Téléphone
                                            </th>
                                            <th>Nombre désabo</th>
                                            <th>Actions</th>
                                            <th>Fiche</th>
                                            <th>
                                                <div *ngIf="type == 'non-terminer'">E-mail</div>
                                                <div *ngIf="type == 'terminer'">Bloquer</div>
                                                <div *ngIf="type == 'bloquer'">Débloquer</div>
                                            </th>
                                            <th *ngIf="type != 'desinscrits' && type != 'supprimes'">Supp</th>
                                            <th *ngIf="type == 'bloquer'">Motif</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let r of recruteurs">
                                            <td>
                                                {{ r.dateCreation * 1000 | date: 'dd-MM-yyyy' }}
                                            </td>
                                            <td>
                                                {{ r.dateMiseAJour * 1000 | date: 'dd-MM-yyyy' }}
                                            </td>
                                          <td>
                                            {{ r.referenceRecruteur }}
                                          </td>
                                            <td>
                                                {{ r.nom }}
                                            </td>
                                            <td>
                                                {{ r.prenom }}
                                            </td>
                                            <td>
                                                {{ r.email }}
                                            </td>
                                            <td>
                                                {{ r.telephonePortable }}
                                                <span *ngIf="r.telephonePortable !== '' && r.telephoneFixe !== ''">/</span>
                                                {{ r.telephoneFixe }}
                                            </td>
                                            <td>
                                                {{ r.desabonnement.length }}
                                            </td>
                                            <td>
                                                <a routerLink="/administration/tracking/{{ r._id }}/Recruteur" class="btn btn-info"
                                                    >Tracking</a
                                                >
                                            </td>
                                            <td>
                                                <a
                                                    style="color: white"
                                                    target="_blank"
                                                    routerLink="/administration/recruteur/{{ r._id }}"
                                                    class="btn btn-info"
                                                    >Fiche</a
                                                >
                                            </td>
                                            <td>
                                                <button
                                                    *ngIf="type == 'non-terminer'"
                                                    (click)="envoyer(r._id, r.email)"
                                                    class="btn btn-success"
                                                    [disabled]="r.emailVerifier"
                                                >
                                                    Envoyer
                                                </button>
                                                <button *ngIf="type == 'terminer'" (click)="bloquer(r._id)" class="btn btn-success">
                                                    Bloquer
                                                </button>
                                                <button *ngIf="type == 'bloquer'" (click)="debloquer(r._id)" class="btn btn-success">
                                                    Débloquer
                                                </button>
                                            </td>
                                            <td *ngIf="type != 'desinscrits' && type != 'supprimes'">
                                                <button (click)="supprimer(r._id)" class="btn btn-danger">Supprimer</button>
                                            </td>
                                            <td *ngIf="type == 'bloquer'">
                                                <div *ngIf="r.desinscription">Désinscription</div>
                                                <div *ngIf="r.fermer">Entreprise fermée</div>
                                                <div *ngIf="!r.desinscription && !r.fermer">Bloqué</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="10"></td>
                                            <td colspan="2">
                                                <select class="form-control" [(ngModel)]="nbParPage" (ngModelChange)="changeNombre()">
                                                    <option value="10" [selected]="nbParPage === 10">Afficher 10 recruteurs</option>
                                                    <option value="25" [selected]="nbParPage === 25">Afficher 25 recruteurs</option>
                                                    <option value="50" [selected]="nbParPage === 50">Afficher 50 recruteurs</option>
                                                    <option value="100" [selected]="nbParPage === 100">Afficher 100 recruteurs</option>
                                                    <option value="250" [selected]="nbParPage === 250">Afficher 250 recruteurs</option>
                                                    <option value="500" [selected]="nbParPage === 500">Afficher 500 recruteurs</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="container-pagination">
                        <span>
                            <a *ngIf="page != 0" (click)="changePage(page - 1)" class="paginate_button">Précedent</a>
                            <span *ngFor="let p of pagination">
                                <span *ngIf="p == -1">...</span>
                                <a *ngIf="p != -1" (click)="changePage(p)" class="paginate_button" [ngClass]="{ current: page == p }">{{
                                    p + 1
                                }}</a>
                            </span>
                            <a *ngIf="page != nombrePage" (click)="changePage(page + 1)" class="paginate_button">Suivant</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
