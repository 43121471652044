import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-desabonnement',
    templateUrl: './desabonnement.component.html',
    styleUrls: [
        './desabonnement.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/jquery.datatables.min.css',
    ],
})
export class DesabonnementComponent implements OnInit {
    candidats: any = [];
    recruteurs: any = [];
    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private globalService: GlobalService) {}

    ngOnInit(): void {
        this.getCandidat();
        this.getRecruteur();
    }
    getCandidat(): void {
        this.httpClient.get<any>(this.globalService.url + '/candidat/desinscription').subscribe(
            (response) => {
                if (response.status) {
                    this.candidats = response.accounts;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
    getRecruteur(): void {
        this.httpClient.get<any>(this.globalService.url + '/recruteur/desinscription').subscribe(
            (response) => {
                if (response.status) {
                    this.recruteurs = response.accounts;
                } else {
                    alert('Erreur lors de la modification');
                }
            },
            () => {}
        );
    }
}
