<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div>
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">Commande {{ commande.numeroCommande }}</h3>
                </div>
                <div id="wizard_container">
                    <div id="middle-wizard">
                        <div>
                            <div class="row">
                                <div class="col-md-6">
                                    <table class="display table dataTable">
                                        <tr>
                                            <td>Nombre de candidatures dans le panier</td>
                                            <td style="text-align: right">
                                                {{ commande.nombreCourriel }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Nombre de candidatures retirées</td>
                                            <td style="text-align: right">
                                                {{ retraitDesabo }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Nombre de candidatures à envoyer</td>
                                            <td style="text-align: right">
                                                {{ commande.nombreCourriel - retraitDesabo }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Prix unitaire TTC</td>
                                            <td style="text-align: right">{{ commande.prix.ttc }} €</td>
                                        </tr>
                                        <tr>
                                            <td>Sous-total TTC</td>
                                            <td style="text-align: right">{{ sousTotalTtc }} €</td>
                                        </tr>
                                        <tr>
                                            <td>Code réduction</td>
                                            <td style="text-align: right">
                                                <div *ngIf="commande.reduction.nomCode === ''">Aucun</div>
                                                <div *ngIf="commande.reduction.nomCode !== ''" style="position: relative">
                                                    <div style="margin-right: 15px">
                                                        {{ commande.reduction.nomCode }}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Montant de la réduction TTC</td>
                                            <td style="text-align: right">
                                                <div *ngIf="commande.reduction.nomCode === ''">0 €</div>
                                                <div *ngIf="commande.reduction.nomCode !== ''">
                                                    {{ commande.reduction.montantTtc.toFixed(2) }}
                                                    €
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Crédit TTC utilisé</td>
                                            <td style="text-align: right">{{ creditUtiliser }} €</td>
                                        </tr>
                                        <tr>
                                            <td>Total TTC</td>
                                            <td style="text-align: right">
                                                <div *ngIf="totalTtc <= 0">Gratuit</div>
                                                <div *ngIf="totalTtc > 0">{{ totalTtc }} €</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>MONTANT &Agrave; PAYER</td>
                                            <td style="text-align: right">
                                                <div *ngIf="totalTtc <= 0">Gratuit</div>
                                                <div *ngIf="totalTtc > 0">{{ totalTtc }} €</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>(dont TVA 20%)</td>
                                            <td style="text-align: right">
                                                <div *ngIf="totalTtc <= 0">Pas de TVA</div>
                                                <div *ngIf="totalTtc > 0">{{ tva }} €</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Crédit TTC restant après commande</td>
                                            <td style="text-align: right">{{ commande.creditRestant }} €</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <table class="display table dataTable">
                                        <tr>
                                            <td>Sujet</td>
                                            <td>
                                                {{ commande.sujet }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Email expédition</td>
                                            <td>
                                                {{ commande.email }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Lettre de motivation</td>
                                            <td [innerHTML]='commande.lettreMotivation'>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CV</td>
                                            <td>
                                                <a target="_blank" href="{{ url }}/documents/cv/{{ commande.lienCv }}">Télécharger le CV</a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
