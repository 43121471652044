<app-site-header></app-site-header>
<!-- Sign Up section HTML Start -->
<div
    mdbModal
    #verificationCode="mdbModal"
    class="modal fade top"
    id="verificationModalTop"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    [config]="{ backdrop: 'static', keyboard: false }"
    aria-hidden="true"
>
    <div class="modal-dialog modal-notify modal-warning" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header justify-content-center">
                <p class="heading lead">VERIFICATION REQUISE</p>
            </div>
            <!--Body-->
            <div class="modal-body">
                <div class="text-center">
                    <!--          <div class="animate__animated animate__rotateIn">-->
                    <!--            <fa-icon [icon]="faCheck" class="mb-3 fa-4x"></fa-icon>-->

                    <!--          </div>-->
                    <p>
                      Merci de consulter votre messagerie et d'écrire ci-dessous le code envoyé à l'adresse
                        {{ recruteur.emailPro }}.
                    </p>
                    <code-input
                        [isCodeHidden]="false"
                        [codeLength]="5"
                        (codeChanged)="onCodeChanged($event)"
                        (codeCompleted)="onCodeCompleted($event)"
                    >
                    </code-input>

                    <a
                        type="button"
                        style="color: white"
                        (click)="sendMailVerificationEmail()"
                        mdbBtn
                        [rounded]="true"
                        color="warning"
                        class="waves-effect mt-4"
                        mdbWavesEffect
                        ><fa-icon class="mr-2" [icon]="faArrowCircleRight"></fa-icon>Renvoyer le code
                    </a>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" style="color: white" href="/" mdbBtn [rounded]="true" color="warning" class="waves-effect" mdbWavesEffect
                    >Retour à l'accueil
                </a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<section id="Sign-up">
    <div class="container-fluid">
        <div class="sign-up-top">
            <h2 *ngIf="this.recruteur.etape === 0 && this.recruteur._id !== ''" class="section-title">Mot de passe</h2>
            <h2 *ngIf="this.recruteur.etape === 1" class="section-title">Vos informations personnelles</h2>
            <h2 *ngIf="this.recruteur.etape === 2" class="section-title">Mon organisme</h2>
            <h2 *ngIf="this.recruteur.etape === 3" class="section-title">Activités</h2>
            <h2 *ngIf="this.recruteur.etape === 4" class="section-title">Votre recherche</h2>
            <h2 *ngIf="this.recruteur.etape === 5" class="section-title">Vos autorisations</h2>
            <div class="signup-top-wrapper">
                <div class="steps-container text-center">
                    <div
                        [ngStyle]="{
                            display: (this.isMobile && this.recruteur.etape < 1) || !this.isMobile ? 'inline-block' : 'none'
                        }"
                        [ngClass]="this.recruteur.etape === 0 && this.recruteur._id !== '' ? 'active' : ''"
                        class="steps"
                    >
                        <span
                            ><h1>1</h1>
                            <h6>Mot de passe</h6></span
                        >
                    </div>
                    <div
                        [ngStyle]="{
                            display:
                                (this.isMobile &&
                                    ((this.recruteur.etape == 0 && this.recruteur._id !== '') || this.recruteur.etape == 1)) ||
                                !this.isMobile
                                    ? 'inline-block'
                                    : 'none'
                        }"
                        [ngClass]="this.recruteur.etape === 1 ? 'active' : ''"
                        class="steps"
                    >
                        <span
                            ><h1>2</h1>
                            <h6>
                                Vos informations <br />
                                personnelles
                            </h6></span
                        >
                    </div>
                    <div
                        [ngStyle]="{
                            display:
                                (this.isMobile && (this.recruteur.etape == 2 || this.recruteur.etape == 1)) || !this.isMobile
                                    ? 'inline-block'
                                    : 'none'
                        }"
                        [ngClass]="this.recruteur.etape === 2 ? 'active' : ''"
                        class="steps"
                    >
                        <span
                            ><h1>3</h1>
                            <h6>Mon organisme</h6></span
                        >
                    </div>
                    <div
                        [ngStyle]="{
                            display:
                                (this.isMobile && (this.recruteur.etape == 3 || this.recruteur.etape == 2)) || !this.isMobile
                                    ? 'inline-block'
                                    : 'none'
                        }"
                        [ngClass]="this.recruteur.etape === 3 ? 'active' : ''"
                        class="steps"
                    >
                        <span
                            ><h1>4</h1>
                            <h6>Activités</h6></span
                        >
                    </div>
<!--                    <div-->
<!--                        [ngStyle]="{-->
<!--                            display:-->
<!--                                (this.isMobile && (this.recruteur.etape == 4 || this.recruteur.etape == 3 || this.recruteur.etape == 5)) ||-->
<!--                                !this.isMobile-->
<!--                                    ? 'inline-block'-->
<!--                                    : 'none'-->
<!--                        }"-->
<!--                        [ngClass]="this.recruteur.etape === 4 ? 'active' : ''"-->
<!--                        class="steps"-->
<!--                    >-->
<!--                        <span-->
<!--                            ><h1>5</h1>-->
<!--                            <h6>Votre recherche</h6></span-->
<!--                        >-->
<!--                    </div>-->
                    <div
                        [ngStyle]="{
                            display:
                                (this.isMobile && (this.recruteur.etape == 5 || this.recruteur.etape == 3)) || !this.isMobile
                                    ? 'inline-block'
                                    : 'none'
                        }"
                        [ngClass]="this.recruteur.etape === 5 ? 'active' : ''"
                        class="steps"
                    >
                        <span
                            ><h1>5</h1>
                            <h6>Vos autorisations</h6></span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Sign Up section HTML End -->

<section>
    <div class="inscription-wrapper">
        <!-- STEP 2 -->

        <div
            class="row"
            [ngStyle]="{
                display: this.recruteur.etape === 0 && this.recruteur._id !== '' ? 'block' : 'none'
            }"
        >
            <div class="col-md-12">
                <div class="inscription-left">
                    <h5>Mot de passe</h5>
                    <div class="inscription-form">
                        <div class="form-group inscription-row col-12 col-md-6">
                            <label
                                for="password"
                                [ngClass]="{
                                    'fl-label-empty': this.recruteur.password === '',
                                    'fl-label-not-empty': this.recruteur.password !== ''
                                }"
                                >Mot de passe *</label
                            >
                            <span
                                for="password"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.password ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <input
                                [type]="type1"
                                name="password"
                                id="password"
                                class="form-control"
                                [(ngModel)]="this.recruteur.password"
                            />
                            <fa-icon class="eye position-absolute" (click)="eye(1)" [icon]="eye1"></fa-icon>
                        </div>
                        <div class="form-group inscription-row col-12 col-md-6">
                            <label
                                for="passwordRenew"
                                [ngClass]="{
                                    'fl-label-empty': this.passwordDeux === '',
                                    'fl-label-not-empty': this.passwordDeux !== ''
                                }"
                                >Répéter le mot de passe *</label
                            >
                            <span
                                for="passwordRenew"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.passwordDeux ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <input
                                [type]="type2"
                                name="passwordRenew"
                                id="passwordRenew"
                                class="form-control"
                                [(ngModel)]="this.passwordDeux"
                            />
                            <fa-icon class="eye position-absolute" (click)="eye(2)" [icon]="eye2"></fa-icon>
                        </div>

                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.longueur ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.longueur"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.longueur"></fa-icon>
                            Contient 10 caractères.
                        </div>
                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.miniscule ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.miniscule"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.miniscule"></fa-icon>
                            Contient au moins une minuscule.
                        </div>
                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.majuscule ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.majuscule"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.majuscule"></fa-icon>
                            Contient au moins une majuscule.
                        </div>
                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.chiffre ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.chiffre"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.chiffre"></fa-icon>
                            Contient au moins un chiffre.
                        </div>
                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.caractereSpeciale ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.caractereSpeciale"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.caractereSpeciale"></fa-icon>
                            Contient au moins un caractère spécial (@ # & " ' ; . , ? : \ / !).
                        </div>
                        <div
                            class="err"
                            [ngStyle]="{
                                color: analyse.identique ? 'green' : 'red'
                            }"
                        >
                            <fa-icon [icon]="faOk" *ngIf="analyse.identique"></fa-icon>
                            <fa-icon [icon]="faError" *ngIf="!analyse.identique"></fa-icon>
                            Les deux mots de passe sont identiques.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- END STEP 2 -->

        <!-- STEP 3 -->

        <div
            class="row"
            [ngStyle]="{
                display: this.recruteur.etape === 1 ? 'flex' : 'none'
            }"
        >
            <div class="col-md-10">
                <div class="inscription-left">
                    <h5>Renseignez vos informations personnelles</h5>
                    <div class="inscription-form">
                        <h6 style="color: orange !important">Votre nom et votre prénom ne seront plus modifiables après cette étape</h6>
                        <div class="form-group d-flex justify-content-center">
                            <img
                                style="object-fit: cover; width: 100px; height: 100px"
                                class="img-preload rounded-circle img-fluid"
                                *ngIf="this.recruteur.photo !== ''"
                                [src]="this.photoRecruteur"
                            />
                        </div>
                        <div class="form-group d-flex justify-content-center">
                            <label for="photo"
                                >Photo de profil (optionnelle mais vivement conseillée)<br /><small>(Fichier accepté: .png, .jpg - Taille maximum: 5Mo)</small></label
                            >

                            <div class="file-field md-form mt-0 ml-2">
                                <div mdbBtn [rounded]="true" [outline]="true" color="secondary" size="md" mdbWavesEffect>
                                    <span class="pt-1">Choisir un fichier</span>
                                    <input type="file" name="photo" mdbFileSelect (change)="photo($event)" />
                                </div>
                                <span
                                    for="photo"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.photo ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >
                            </div>
                        </div>
                        <div class="d-flex inscription-row d-flex flex-wrap">
                            <div class="col-12 col-md-6 mb-4 mb-md-0 p-0" style="text-align: center; margin-top: auto">
                                <div class="form-group radio_input d-inline">
                                    <label class="container_radio mr-1"
                                        >Monsieur
                                        <input
                                            type="radio"
                                            name="gender"
                                            (click)="monsieur()"
                                            [checked]="recruteur.civilite === 'Monsieur'"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="container_radio"
                                        >Madame
                                        <input type="radio" name="gender" (click)="madame()" [checked]="recruteur.civilite === 'Madame'" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="container_radio ml-1"
                                        >Autre
                                        <input type="radio" name="gender" (click)="autre()" [checked]="recruteur.civilite === 'Autre'" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <span
                                        for="gender"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.civilite ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label
                                        for="nom"
                                        [ngClass]="{
                                            'fl-label-empty': this.recruteur.nom === '',
                                            'fl-label-not-empty': this.recruteur.nom !== ''
                                        }"
                                        >Nom *</label
                                    >
                                    <span
                                        for="nom"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.nom ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <input type="text" name="nom" id="nom" class="form-control" [(ngModel)]="this.recruteur.nom" />
                                </div>
                            </div>
                            <div class="col-12 offset-md-6 col-md-6">
                                <div class="form-group">
                                    <label
                                        for="prenom"
                                        [ngClass]="{
                                            'fl-label-empty': this.recruteur.prenom === '',
                                            'fl-label-not-empty': this.recruteur.prenom !== ''
                                        }"
                                        >Prénom *</label
                                    >
                                    <span
                                        for="prenom"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.prenom ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <input type="text" name="prenom" id="prenom" class="form-control" [(ngModel)]="this.recruteur.prenom" />
                                </div>
                            </div>
                        </div>
<!--                      <div class="d-flex flex-wrap inscription-row">-->
<!--                        <div class="col-12 col-md-6">-->
<!--                          <label>Date de naissance *</label>-->
<!--                        </div>-->
<!--                        <div class="col-12 col-md-6">-->
<!--                          <div class="form-group">-->
<!--                                    <span-->
<!--                                      for="dateNaissance"-->
<!--                                      class="error"-->
<!--                                      [ngStyle]="{-->
<!--                                            display: this.error.dateNaissance ? 'block' : 'none'-->
<!--                                        }"-->
<!--                                    >Obligatoire</span-->
<!--                                    >-->
<!--                            <app-datepicker [sel]='dob' [(date)]='recruteur.dateNaissance'></app-datepicker>-->

<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
                        <div class="form-group inscription-row">
                            <label
                                for="fonction"
                                [ngClass]="{
                                    'fl-label-empty': this.recruteur.fonctionRecruteur === '',
                                    'fl-label-not-empty': this.recruteur.fonctionRecruteur !== ''
                                }"
                                >Métier actuel *</label
                            >
                            <span
                                for="fonction"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.fonctionRecruteur ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >

                            <input
                                type="text"
                                [(ngModel)]="this.recruteur.fonctionRecruteur"
                                name="fonction"
                                id="fonction"
                                class="form-control col-12 col-md-6"
                            />
                        </div>
                        <div class="form-group inscription-row">
                            <label>Téléphone (au moins un, ce dernier ne sera pas communiqué aux candidats) *</label>
                            <span
                                for="telephonePortable"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.telephonePortable ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <div class="row mt-2">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="telephonePortable">Téléphone Portable</label>
                                        <input
                                            type="tel"
                                            name="telephonePortable"
                                            id="telephonePortable"
                                            class="form-control"
                                            [(ngModel)]="this.recruteur.telephonePortable"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="telephoneFixe">Téléphone Fixe</label>
                                        <input
                                            type="tel"
                                            name="telephoneFixe"
                                            id="telephoneFixe"
                                            class="form-control"
                                            [(ngModel)]="this.recruteur.telephoneFixe"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inscription-row">
                            <label>Email</label>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            for="email"
                                            [ngClass]="{
                                                'fl-label-empty': this.recruteur.email === '',
                                                'fl-label-not-empty': this.recruteur.email !== ''
                                            }"
                                            >Identifiant du compte</label
                                        >
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            class="form-control"
                                            [(ngModel)]="this.recruteur.email"
                                            (input)="emailMinuscule()"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            for="emailPro"
                                            [ngClass]="{
                                                'fl-label-empty': this.recruteur.emailPro === '',
                                                'fl-label-not-empty': this.recruteur.emailPro !== ''
                                            }"
                                            >Email professionnel *</label
                                        >
                                        <span
                                            for="emailPro"
                                            class="error"
                                            [ngStyle]="{
                                                display: this.error.emailPro ? 'block' : 'none'
                                            }"
                                            >Obligatoire</span
                                        >
                                        <input
                                            type="text"
                                            name="emailPro"
                                            id="emailPro"
                                            class="form-control"
                                            [(ngModel)]="this.recruteur.emailPro"
                                            (input)="emailProMinuscule()"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 align-self-center">
                <div class="inscription-right"></div>
            </div>
        </div>

        <!-- END STEP 3 -->

        <!-- STEP 4 -->

        <div
            class="row"
            [ngStyle]="{
                display: this.recruteur.etape === 2 ? 'flex' : 'none'
            }"
        >
            <div class="col-md-10">
                <div class="inscription-left">
                    <h5>Mon organisme (entreprise, administration, association...)</h5>
                    <div class="inscription-form">
                        <div class="form-group d-flex justify-content-center">
                            <img
                                style="object-fit: cover; width: 100px; height: 100px"
                                class="img-preload rounded-circle img-fluid"
                                *ngIf="this.recruteur.logoGroupe !== ''"
                                [src]="this.logoRecruteur"
                            />
                        </div>
                        <div class="form-group d-flex justify-content-center">
                            <label for="logoGroupe">Logo<br /><small>(Fichier accepté: .png, .jpg - Taille maximum: 5Mo)</small></label>
                            <span
                                for="logoGroupe"
                                class="error"
                                [ngStyle]="{
                                    display: this.error.logoGroupe ? 'block' : 'none'
                                }"
                                >Obligatoire</span
                            >
                            <div class="file-field md-form">
                                <div mdbBtn [rounded]="true" [outline]="true" color="secondary" size="md" mdbWavesEffect>
                                    <span class="pt-1">Choisir un fichier</span>
                                    <input type="file" name="logoGroupe" mdbFileSelect (change)="logo($event)" />
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap inscription-row align-items-center">
                            <div class="col-12 col-md-6">
                                <label>Pays d'immatriculation de mon organisme *</label>
                            </div>
                            <div class="col-12 col-md-4">
                                <mdb-select-2
                                    [notFoundMsg]="'Pays non trouvé'"
                                    name="paysCode"
                                    [(ngModel)]="recruteur.paysCode"
                                    [outline]="true"
                                >
                                    <mdb-select-filter [ngModel]="searchPays | async" (inputChange)="searchPays.next($event)">
                                    </mdb-select-filter>
                                    <mdb-select-option *ngFor="let option of filteredOptions | async" [value]="option.code">{{
                                        option.pays
                                    }}</mdb-select-option>
                                </mdb-select-2>
                            </div>
                        </div>
                      <div *ngIf='siretType == 0 && recruteur.estFrancaise' class="d-flex flex-wrap inscription-row align-items-center">
                        <div class='col-12 mb-3'>
                          <b>Numéro d’identification (Siret) ou numéro de votre entreprise étrangère  </b>
                          <ng-template #siretHelpChip><div [innerHtml]="siretHelp"></div></ng-template>
                          <fa-icon [icon]='faInfoCircle'  [mdbPopover]="siretHelpChip" placement="right" >
                          </fa-icon>

                          <br>Indiquer le Siret vous permet de pré-remplir automatiquement les informations de votre organisme et de gagner du temps à l'inscription.

                        </div>
                        <div class="col-12 col-md-6 text-center mb-2 mb-md-0">
                          <a
                            (click)='siretType = 1'
                            mdbBtn
                            color="danger"
                            size="lg"
                            [rounded]="true"
                            [outline]="true" color="secondary"
                            style='background-color: white !important;padding: 20px !important;'
                            class="waves-effect"
                            mdbWavesEffect
                          >Je connais le Siret</a
                          >
                        </div>
                        <div class="col-12 col-md-6 text-center">
                          <a
                            (click)='siretType = 2'
                            mdbBtn
                            size="lg"
                            [rounded]="true"
                            [outline]="true" color="secondary"
                            style='background-color: white !important;;padding: 20px !important;'
                            class="waves-effect"
                            mdbWavesEffect
                          >Je ne connais pas le Siret et le remplirai plus tard</a
                          >
                        </div>
                      </div>
                        <div *ngIf='siretType == 1 || !recruteur.estFrancaise' class="d-flex flex-wrap inscription-row align-items-center">
                            <div class="col-12 col-md-6">
                              <b>Numéro d’identification (Siret) ou numéro de votre entreprise étrangère {{ recruteur.estFrancaise ? '*' : '' }} </b>
                              <ng-template #siretHelpChip2><div [innerHtml]="siretHelp"></div></ng-template>
                              <fa-icon [icon]='faInfoCircle'  [mdbPopover]="siretHelpChip2" placement="right" >
                              </fa-icon>

                              <br>Indiquer le Siret vous permet de pré-remplir automatiquement les informations de votre organisme et de gagner du temps à l'inscription.

                                <label
                                    for="siret"
                                    [ngClass]="{
                                        'fl-label-empty': this.recruteur.siret === '',
                                        'fl-label-not-empty': this.recruteur.siret !== ''
                                    }"
                                    ></label
                                >
                                <span
                                    for="siret"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.siret ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >
                            </div>
                            <div class="col-12 col-md-4">
                                <input *ngIf='recruteur.estFrancaise' [length]="14" mdbCharCounter type="text" name="siret" id="siret" class="form-control" [(ngModel)]="this.recruteur.siret" />
                                <input *ngIf='!recruteur.estFrancaise'  type="text" name="siret" id="siret" class="form-control" [(ngModel)]="this.recruteur.siret" />
                            </div>
                        </div>

                      <div [hidden]='!siretCompleted && recruteur.estFrancaise && siretType != 2' class='animate__animated animate__fadeIn animate__slow'>
                        <div class="d-flex flex-wrap inscription-row align-items-center" >
                            <div class="col-12 col-md-6">
                                <label
                                    for="nomSociete"
                                    [ngClass]="{
                                        'fl-label-empty': this.recruteur.nomSociete === '',
                                        'fl-label-not-empty': this.recruteur.nomSociete !== ''
                                    }"
                                    >Nom de l'organisme *</label
                                >
                                <span
                                    for="nomSociete"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.nomSociete ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >
                            </div>
                            <div class="col-12 col-md-4">
                                <input
                                    type="text"
                                    name="nomSociete"
                                    id="nomSociete"
                                    class="form-control"
                                    [(ngModel)]="this.recruteur.nomSociete"
                                />
                            </div>
                        </div>

                        <div *ngIf='siretType != 2' class="d-flex flex-wrap inscription-row align-items-center">
                            <div class="col-12 col-md-6">
                                <label
                                    for="nomGroupe"
                                    [ngClass]="{
                                        'fl-label-empty': this.recruteur.nomGroupe === '',
                                        'fl-label-not-empty': this.recruteur.nomGroupe !== ''
                                    }"
                                    >Nom du groupe</label
                                >
                            </div>
                            <div class="col-12 col-md-4">
                                <input
                                    type="text"
                                    name="nomGroupe"
                                    id="nomGroupe"
                                    class="form-control"
                                    [(ngModel)]="this.recruteur.nomGroupe"
                                />
                            </div>
                        </div>

                        <div class="inscription-row d-flex flex-wrap">
                            <div class="col-12 col-md-6 mt-2">
                                <div class="form-group">
                                    <label
                                        for="adresse"
                                        [ngClass]="{
                                            'fl-label-empty': this.recruteur.adresse === '',
                                            'fl-label-not-empty': this.recruteur.adresse !== ''
                                        }"
                                        >Adresse *</label
                                    >
                                    <span
                                        for="adresse"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.adresse ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <app-select-adress [user]="recruteur"></app-select-adress>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mt-2">
                                <div class="form-group">
                                    <label
                                        for="complementAdresse"
                                        [ngClass]="{
                                            'fl-label-empty': this.recruteur.complementAdresse === '',
                                            'fl-label-not-empty': this.recruteur.complementAdresse !== ''
                                        }"
                                        >Complément d'adresse</label
                                    >
                                    <input
                                        type="text"
                                        name="complementAdresse"
                                        id="complementAdresse"
                                        class="form-control"
                                        [(ngModel)]="this.recruteur.complementAdresse"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mt-3">
                                <div class="form-group">
                                    <label
                                        for="codePostal"
                                        [ngClass]="{
                                            'fl-label-empty': this.recruteur.codePostal === '',
                                            'fl-label-not-empty': this.recruteur.codePostal !== ''
                                        }"
                                        >Code postal *</label
                                    >
                                    <span
                                        for="codePostal"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.codePostal ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <input
                                        type="text"
                                        name="codePostal"
                                        id="codePostal"
                                        class="form-control"
                                        [(ngModel)]="this.recruteur.codePostal"
                                    />
                                </div>
                            </div>
                            <div class="col-6 mt-3">
                                <div class="form-group">
                                    <label
                                        for="ville"
                                        [ngClass]="{
                                            'fl-label-empty': this.recruteur.ville === '',
                                            'fl-label-not-empty': this.recruteur.ville !== ''
                                        }"
                                        >Ville *</label
                                    >
                                    <span
                                        for="ville"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.ville ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <input type="text" name="ville" id="ville" class="form-control" [(ngModel)]="this.recruteur.ville" />
                                </div>
                            </div>
                        </div>

                        <div *ngIf='siretType != 2' class="d-flex flex-wrap inscription-row align-items-center">
                            <div class="col-12 col-md-6">
                                <label
                                    for="numeroTva"
                                    [ngClass]="{
                                        'fl-label-empty': this.recruteur.numeroTva === '',
                                        'fl-label-not-empty': this.recruteur.numeroTva !== ''
                                    }"
                                    >Numéro de TVA</label
                                >
                            </div>
                            <div class="col-12 col-md-4">
                                <input
                                    type="text"
                                    name="numeroTva"
                                    id="numeroTva"
                                    class="form-control"
                                    [(ngModel)]="this.recruteur.numeroTva"
                                />
                            </div>
                        </div>
                        <div *ngIf='siretType != 2' class="d-flex inscription-row mb-2 flex-wrap">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label
                                        for="codeNaf"
                                        [ngClass]="{
                                            'fl-label-empty': this.recruteur.activite.codeNaf === '',
                                            'fl-label-not-empty': this.recruteur.activite.codeNaf !== ''
                                        }"
                                        >Code NAF</label
                                    >
                                    <span
                                        for="codeNaf"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.codeNaf ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <input
                                        type="text"
                                        name="codeNaf"
                                        id="codeNaf"
                                        class="form-control"
                                        [(ngModel)]="this.recruteur.activite.codeNaf"
                                        (ngModelChange)='getActiviteWithNaf($event)'
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label
                                        for="activiteNom"
                                        [ngClass]="{
                                            'fl-label-empty': this.recruteur.activite.nom === '',
                                            'fl-label-not-empty': this.recruteur.activite.nom !== ''
                                        }"
                                        >Activité correspondante</label
                                    >
                                    <span
                                        for="activiteNom"
                                        class="error"
                                        [ngStyle]="{
                                            display: this.error.activiteNom ? 'block' : 'none'
                                        }"
                                        >Obligatoire</span
                                    >
                                    <input
                                        type="text"
                                        name="activiteNom"
                                        id="activiteNom"
                                        class="form-control"
                                        [(ngModel)]="this.recruteur.activite.nom"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 align-self-center">
                <div class="inscription-right"></div>
            </div>
        </div>

        <!-- END STEP 4 -->

        <!-- STEP 5 -->

        <div
            class="row"
            [ngStyle]="{
                display: this.recruteur.etape === 3 ? 'flex' : 'none'
            }"
        >
            <div class="col-md-10">
                <div class="inscription-left">
                    <h5>Renseignez quelques informations complémentaires</h5>
                    <h5 style="color: red !important">
                        Aucune information nominative et coordonnées de contact (adresse, téléphone, email, site Internet) ne doit être
                        mentionnée dans le champ "Activité(s)..." sous peine de voir votre compte bloqué.
                    </h5>
                    <div class="inscription-form">
                        <form action="">
                            <div class="inscription-select d-md-flex justify-content-between align-items-center mb-4">
                                <div class="inscription-selectbox form-group">
                                    <div class="ins-select d-flex align-items-center">
                                        <img src="./assets/img/org.png" alt="org" />
                                        <h6>Type d’organisme *</h6>
                                        <span
                                            for="typeEntreprise"
                                            class="error"
                                            [ngStyle]="{
                                                display: this.error.typeEntreprise ? 'block' : 'none'
                                            }"
                                            >Obligatoire</span
                                        >
                                    </div>
                                    <mdb-select-2
                                        placeholder="Choisir.."
                                        name="typeEntreprise"
                                        class="bottom-select"
                                        [(ngModel)]="recruteur.typeEntreprise"
                                        [outline]="true"
                                    >
                                        <mdb-select-option *ngFor="let option of typesEntreprises" [value]="option.value">{{
                                            option.label
                                        }}</mdb-select-option>
                                    </mdb-select-2>
                                </div>
                                <div class="inscription-selectbox form-group">
                                    <div class="ins-select d-flex align-items-center">
                                        <img src="./assets/img/employee.png" alt="employee" />
                                        <h6>Nombre de salariés *</h6>
                                        <span
                                            for="nombreSalarie"
                                            class="error"
                                            [ngStyle]="{
                                                display: this.error.nombreSalarie ? 'block' : 'none'
                                            }"
                                            >Obligatoire</span
                                        >
                                    </div>
                                    <mdb-select-2
                                        placeholder="Choisir.."
                                        name="nombreSalarie"
                                        class="bottom-select"
                                        [(ngModel)]="recruteur.nombreSalarie"
                                        [outline]="true"
                                    >
                                        <mdb-select-option *ngFor="let option of workersNumbers" [value]="option.value">{{
                                            option.label
                                        }}</mdb-select-option>
                                    </mdb-select-2>
                                </div>
                                <div class="inscription-selectbox">
                                    <div class="ins-select d-flex align-items-center">
                                        <img src="./assets/img/activity.png" alt="activity" />
                                        <h6>
                                            Activités spécifiques
                                            <span>(choix multiples)</span>
                                        </h6>
                                    </div>
                                    <mdb-select-2
                                        placeholder="Choisir.."
                                        name="activiteSpecifique"
                                        class="bottom-select"
                                        (valueChange)="setActs($event)"
                                        [(ngModel)]="recruteur.activiteSpecifique"
                                        [multiple]="true"
                                        [outline]="true"
                                    >
                                        <mdb-select-option *ngFor="let option of activities" [value]="option.value">{{
                                            option.label
                                        }}</mdb-select-option>
                                    </mdb-select-2>
                                </div>
                            </div>
                            <div class="inscription-row">
                              <app-secteur class='d-flex flex-wrap' [showHelp]='true' [(data)]='recruteur.secteurEntreprise' [typeSecteur]='"Entreprise"'>
                                <label for="secteurEntreprise"
                                >Secteur(s) d'activité de votre organisme * <small>Ce champ est multiple</small></label
                                >
                                <span
                                  for="secteurEntreprise"
                                  class="error"
                                  [ngStyle]="{
                                            display: this.error.secteurEntreprise ? 'block' : 'none'
                                        }"
                                >Obligatoire</span
                                >
                              </app-secteur>
                            </div>
<!--                            <div class="form-group inscription-row">-->
<!--                                <label-->
<!--                                    for="siteInternet"-->
<!--                                    [ngClass]="{-->
<!--                                        'fl-label-empty': this.recruteur.siteInternet === '',-->
<!--                                        'fl-label-not-empty': this.recruteur.siteInternet !== ''-->
<!--                                    }"-->
<!--                                    >Site Internet</label-->
<!--                                >-->

<!--                                <input-->
<!--                                    type="text"-->
<!--                                    name="siteInternet"-->
<!--                                    id="siteInternet"-->
<!--                                    class="form-control col-12 col-md-6"-->
<!--                                    [(ngModel)]="this.recruteur.siteInternet"-->
<!--                                />-->
<!--                            </div>-->
<!--                            <div class="form-group inscription-row">-->
<!--                                <label for="activiteTexte" style="color: black !important; font-weight: bold" class="mb-2"-->
<!--                                    >Activité(s) détaillée(s) de votre organisme</label-->
<!--                                >-->
<!--                                <span-->
<!--                                    for="activiteTexte"-->
<!--                                    class="error"-->
<!--                                    [ngStyle]="{-->
<!--                                        display: this.error.activiteTexte ? 'block' : 'none'-->
<!--                                    }"-->
<!--                                    >Obligatoire</span-->
<!--                                >-->
<!--                                <textarea-->
<!--                                    style="height: auto"-->
<!--                                    name="activiteTexte"-->
<!--                                    id="activiteTexte"-->
<!--                                    class="form-control mt-2 pt-2"-->
<!--                                    [(ngModel)]="this.recruteur.activiteTexte"-->
<!--                                    rows="4"-->
<!--                                ></textarea>-->
<!--                            </div>-->
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-2 align-self-center">
                <div class="inscription-right"></div>
            </div>
        </div>

        <!-- END STEP 5 -->

        <!-- STEP 6 -->

        <div
            class="row"
            [ngStyle]="{
                display: this.recruteur.etape === 4 ? 'flex' : 'none'
            }"
        >
            <div class="col-md-10">
                <div class="inscription-left">
                    <h5>Votre recherche</h5>

                    <div class="inscription-form">
                        <h5 style="color: orange !important">
                            Aucune information nominative, adresse, téléphone, email et site Internet ne doit être mentionné dans le champ
                            "Profils recherchés" sous peine de voir votre compte bloqué.
                        </h5>
                        <h5>Tous ces éléments sont facultatifs. Vous pourrez remplir cette rubrique plus tard.</h5>
                        <div class="form-group add_bottom_30">
                            <div class="inscription-row">
                              <app-poste class='d-flex flex-wrap' [(data)]='recruteur.metiersRechercher' [(recherche)]='recherche' [typeMetier]='"Profils recherchés"'>
                                <label for="fonction">Profil(s) recherché(s) <small>Ce champ est multiple</small></label>
                              </app-poste>
                            </div>

                            <div class="form-group inscription-row mt-2">
                                <label
                                    for="profilRechercher"
                                    [ngClass]="{
                                        'fl-label-empty': this.recruteur.profilRechercher === '',
                                        'fl-label-not-empty': this.recruteur.profilRechercher !== ''
                                    }"
                                    >Détailler en quelques mots le(s) profil(s) recherché(s)</label
                                >
                                <span
                                    for="profilRechercher"
                                    class="error"
                                    [ngStyle]="{
                                        display: this.error.profilRechercher ? 'block' : 'none'
                                    }"
                                    >Obligatoire</span
                                >
                                <textarea
                                    style="height: auto"
                                    name="profilRechercher"
                                    id="profilRechercher"
                                    class="form-control"
                                    [(ngModel)]="this.recruteur.profilRechercher"
                                    rows="4"
                                ></textarea>
                            </div>
                            <div class="inscription-row">
                              <app-secteur class='d-flex flex-wrap' [showHelp]='true' [(data)]='recruteur.secteurActivites' [typeSecteur]='"Souhaités"'>
                                <label for="secteursRechercher">Secteur(s) d'activité recherché(s) <small>Ce champ est multiple</small></label>
                              </app-secteur>
                            </div>
                            <div class="inscription-row">
                                <label class="custom mb-3">Type(s) de poste proposé(s)</label>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="container_check"
                                                >Salarié
                                                <input type="checkbox" [(ngModel)]="poste.salarie" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label class="container_check"
                                                >Alternant
                                                <input type="checkbox" [(ngModel)]="poste.alternant" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="container_check"
                                                >Indépendant
                                                <input type="checkbox" [(ngModel)]="poste.independant" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label class="container_check"
                                                >Bénévole
                                                <input type="checkbox" [(ngModel)]="poste.benevole" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="container_check"
                                                >Stagiaire
                                                <input type="checkbox" [(ngModel)]="poste.stagiaire" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label class="container_check"
                                                >Autre
                                                <input type="checkbox" [(ngModel)]="poste.autre" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="inscription-row">
                                <label class="custom mb-3" *ngIf="afficherTypeContrat()">Type(s) de contrat proposé(s)</label>
                                <div class="row" *ngIf="afficherTypeContrat()">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="container_check"
                                                >CDD
                                                <input type="checkbox" [(ngModel)]="posteVoulu.cdd" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="container_check"
                                                >CDI
                                                <input type="checkbox" [(ngModel)]="posteVoulu.cdi" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <label class="custom mb-3 mt-3">Temps</label>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="container_check"
                                                >Complet
                                                <input type="checkbox" [(ngModel)]="temps.plein" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="container_check"
                                                >Partiel
                                                <input type="checkbox" [(ngModel)]="temps.partiel" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 align-self-center">
                <div class="inscription-right"></div>
            </div>
        </div>

        <!-- END STEP 6 -->

        <!-- STEP 7 -->

        <div
            class="row"
            [ngStyle]="{
                display: this.recruteur.etape >= 5 ? 'flex' : 'none'
            }"
        >
            <div class="col-md-10">
                <div class="inscription-left">
                    <h5>Autorisations</h5>
                    <div class="inscription-form">
                        <div class="inscription-row d-flex flex-wrap">
                            <div class="col-md-6">
                                <label>Afficher ma photo et mon métier sur la page d'accueil du site. *</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Oui
                                        <input
                                            type="radio"
                                            name="positions"
                                            [(ngModel)]="recruteur.autorisationAccueil"
                                            [value]="true"
                                            [checked]="recruteur.autorisationAccueil"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Non
                                        <input
                                            type="radio"
                                            name="positions"
                                            [(ngModel)]="recruteur.autorisationAccueil"
                                            [value]="false"
                                            [checked]="!recruteur.autorisationAccueil"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="inscription-row d-flex flex-wrap">
                            <div class="col-md-6">
                                <label
                                    >Soumettre pour référencement ma fiche Recruteur à Google ? Le référencement / déréférencement par
                                    Google peut prendre plusieurs semaines. *</label
                                >
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Oui
                                        <input
                                            type="radio"
                                            name="autorisationReferencement"
                                            [(ngModel)]="recruteur.autorisationReferencement"
                                            [value]="true"
                                            [checked]="recruteur.autorisationReferencement"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Non
                                        <input
                                            type="radio"
                                            name="autorisationReferencement"
                                            [(ngModel)]="recruteur.autorisationReferencement"
                                            [value]="false"
                                            [checked]="!recruteur.autorisationReferencement"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="inscription-row d-flex flex-wrap">
                            <div class="col-md-6">
                                <label
                                    >Etre informé(e) des offres préférentielles de nos partenaires (voir
                                    <a target="_blank" href="/conditions-generales-d-utilisation-et-de-vente">CGUV</a>). *</label
                                >
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Oui
                                        <input
                                            type="radio"
                                            name="autorisationOffre"
                                            [(ngModel)]="recruteur.autorisationOffre"
                                            [value]="true"
                                            [checked]="recruteur.autorisationOffre"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Non
                                        <input
                                            type="radio"
                                            name="autorisationOffre"
                                            [(ngModel)]="recruteur.autorisationOffre"
                                            [value]="false"
                                            [checked]="!recruteur.autorisationOffre"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="inscription-row d-flex flex-wrap">
                            <div class="col-md-6">
                                <label>Recevoir par email les candidatures des candidat(e)s qui souhaitent m'envoyer leur CV. *</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Oui
                                        <input
                                            type="radio"
                                            name="autorisationReception"
                                            [(ngModel)]="recruteur.autorisationReception"
                                            [value]="true"
                                            [checked]="recruteur.autorisationReception"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group col-md-6">
                                    <label class="container_radio version_2 check"
                                        >Non
                                        <input
                                            type="radio"
                                            name="autorisationReception"
                                            [(ngModel)]="recruteur.autorisationReception"
                                            [value]="false"
                                            [checked]="!recruteur.autorisationReception"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                          <div *ngIf='!recruteur.autorisationReception' class="col-12 col-md-6 mt-5">
                            <label>Recevoir par email un rappel du nombre de candidatures en attente tous les :</label>
                          </div>
                          <div *ngIf='!recruteur.autorisationReception' class="col-12 col-md-3 mt-5">
                            <mdb-select-2
                              placeholder="Choisir.."
                              name="status"
                              class="custom-selectbox"
                              [outline]="true"
                              [(ngModel)]='recruteur.reminder'
                            >
                              <mdb-select-option *ngFor="let option of reminders" [value]="option.value">{{
                                option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                          </div>
                        </div>
                    </div>
                    <div class="mt-3 p-2">
                        <div class="form-group">
                            <label class="container_check"
                                >En cochant cette case, j'accepte sans réserve les
                                <a target="_blank" href="/conditions-generales-d-utilisation-et-de-vente"
                                    >conditions d'utilisation et de vente du site</a
                                >. *
                                <input type="checkbox" name="terms" value="" [checked]="acceptation" (click)="accepter(1)" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                      <div class="form-group">
                        <label class="container_check"
                        >Je certifie être majeur(e) pour utiliser les services d'Avenue Des Recruteurs. *
                          <input type="checkbox" name="terms" value="" [checked]="majeur" (click)="accepter(2)" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                        <div style="color: red" *ngIf="error.acceptation">
                            Vous devez accepter nos CGUV avant de finaliser votre inscription.
                        </div>
                      <div style="color: red" *ngIf="error.majeur">
                        Vous devez être majeur(e) afin de finaliser votre inscription.
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 align-self-center">
                <div class="inscription-right"></div>
            </div>
        </div>

        <!-- END STEP 7 -->

        <div
            [ngStyle]="{
                display: !fin && this.recruteur.etape !== -1 ? 'block' : 'none'
            }"
            class="inscription-buttons text-center pt-4 mb-3"
        >
            <p
                style="color: red !important"
                [ngStyle]="{
                    display: this.errorPage.status ? 'block' : 'none'
                }"
            >
                {{ errorPage.message }}
            </p>
            <p class="mention-obligatoire" style="color: red !important">* Obligatoire</p>
            <p *ngIf="photoUpload == '2'">
                <img src="./assets/img/gif.gif" style="max-width: 25px" /> Photo en cours de sauvegarde, veuillez patienter
            </p>
            <p *ngIf="logoUpload == '2'">
                <img src="./assets/img/gif.gif" style="max-width: 25px" /> Logo en cours de sauvegarde, veuillez patienter
            </p>
            <input
                class="inscription-prev"
                type="submit"
                [ngStyle]="{
                    display: this.recruteur.etape === 0 ? 'none' : 'inline'
                }"
                (click)="precedent()"
                value="Précédent"
            />
            <input class="inscription-next" type="submit" (click)="suivant()" value="Suivant" />
        </div>
    </div>
</section>
<app-site-footer></app-site-footer>
