<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-candidat-header></app-candidat-header>
    </div>
    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <div>
            <div class="page-inner">
                <div class="page-title">
                    <h3 class="breadcrumb-header">Mes commandes</h3>
                </div>
                <div id="main-wrapper">
                    <div class="no-result" *ngIf="commandes.length === 0">Aucun résultat</div>

                    <table
                        [ngStyle]="{ display: display() }"
                        id="tableSort"
                        mdbTable
                        #tableEl="mdbTable"
                        class="dataTable custom-table mt-5 mb-3 table-responsive center-table"
                    >
                        <thead>
                            <tr>
                                <th
                                    *ngFor="let head of headElements; let i = index"
                                    aria-controls="tableSortExample"
                                    scope="col"
                                    [mdbTableSort]="commandes"
                                    [sortBy]="headElements[i]"
                                >
                                    {{ head }}
                                    <mdb-icon fas icon="sort"></mdb-icon>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of commandes; let i = index">
                                <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                    {{ c.numeroCommande }}
                                </td>
                                <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                    {{ c.dateCommande * 1000 | date: 'dd-MM-yyyy' }}
                                </td>
                                <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex && !isMobile">
                                    <div *ngIf="c.dateDebutDiffusion === 0">Dès que possible</div>
                                    <div *ngIf="c.dateDebutDiffusion !== 0">
                                        {{ c.dateDebutDiffusion | date: 'dd-MM-yyyy' }}
                                    </div>
                                </td>
                                <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex && !isMobile">
                                    {{ c.nombreCourriel - c.recruteursRetrait.length }}
                                </td>
                                <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex && !isMobile">
                                    <div
                                        *ngIf="
                                            c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                c.reduction.montantTtc -
                                                c.credit >
                                                0 &&
                                            c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                c.reduction.montantTtc -
                                                c.credit <
                                                1
                                        "
                                    >
                                        1 €
                                    </div>
                                    <div
                                        *ngIf="
                                            c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                c.reduction.montantTtc -
                                                c.credit <=
                                            0
                                        "
                                    >
                                        Gratuit
                                    </div>
                                    <div
                                        *ngIf="
                                            c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                c.reduction.montantTtc -
                                                c.credit >=
                                            1
                                        "
                                    >
                                        {{
                                            (
                                                c.prix.ttc * (c.nombreCourriel - c.recruteursRetrait.length) -
                                                c.reduction.montantTtc -
                                                c.credit
                                            ).toFixed(2)
                                        }}
                                        €
                                    </div>
                                </td>
                                <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                    <span *ngIf="c.campagneFini"
                                        ><h6>
                                            <span class="badge badge-success badge-pill text-white">Terminée</span>
                                        </h6></span
                                    >
                                    <span *ngIf="c.commandeAccepter && !c.campagneFini"
                                        ><h6>
                                            <span class="badge badge-info badge-pill text-white">Acceptée</span>
                                        </h6></span
                                    >
                                    <span *ngIf="c.commandeRefuserTemporaire"
                                        ><h6>
                                            <span class="badge badge-warning badge-pill text-white">Refusée temporairement</span>
                                        </h6></span
                                    >
                                    <span *ngIf="c.commandeRefuserDefinitif"
                                        ><h6>
                                            <span class="badge badge-danger badge-pill text-white">Refusée définitivement</span
                                            >
                                        </h6></span
                                    >
                                    <span *ngIf="!c.commandeAccepter && !c.commandeRefuserTemporaire && !c.commandeRefuserDefinitif"
                                        ><h6>
                                            <span class="badge badge-warning badge-pill text-white">En attente de validation</span>
                                        </h6></span
                                    >
                                </td>
                                <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                    <a
                                        *ngIf="!c.commandeRefuserTemporaire"
                                        target="_blank"
                                        routerLink="/espace-candidat/commande/{{ c._id }}"
                                        ><fa-icon class="text-pink" [icon]="faEye"></fa-icon
                                    ></a>
                                    <a
                                        *ngIf="c.commandeRefuserTemporaire"
                                        target="_blank"
                                        routerLink="/espace-candidat/modifier-commande/{{ c._id }}"
                                        ><fa-icon class="text-pink" [icon]="faEye"></fa-icon
                                    ></a>
                                </td>
                                <td *ngIf="i + 1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex && !isMobile">
                                    <span *ngIf="!c.commandeRefuserTemporaire && !c.commandeRefuserDefinitif">#</span>
                                    <span *ngIf="c.commandeRefuserTemporaire || c.commandeRefuserDefinitif">{{ c.motifRefus }}</span>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="grey lighten-5 w-100">
                            <tr>
                                <td [colSpan]="headElements.length">
                                    <mdb-table-pagination
                                        ofKeyword="de"
                                        [tableEl]="tableEl"
                                        [searchDataSource]="commandes"
                                    ></mdb-table-pagination>
                                </td>
                            </tr>
                            <tr>
                                <td [colSpan]="headElements.length" style="border-top: none !important; border-bottom: none !important">
                                    <mdb-select-2
                                        placeholder="Afficher 10/ page"
                                        (selected)="changeItemsByPage($event)"
                                        style="float: right; min-width: 174px"
                                        name="itemsByPage"
                                        [outline]="true"
                                    >
                                        <mdb-select-option *ngFor="let option of displayByPages" [value]="option.value">{{
                                            option.label
                                        }}</mdb-select-option>
                                    </mdb-select-2>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
