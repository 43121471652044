<app-site-header></app-site-header>
<div class="row" style="width: 100%; margin: 0">
    <div class="col-xl-2 col-xs-2 sidebar-custom" style="padding: 0">
        <app-recruteur-header></app-recruteur-header>
    </div>

    <div class="main-page-layout col-xl-10 col-xs-10 pt-0 pt-md-4">
        <a href="espace-recruteur/informations-personnelles" class="text-dark d-none d-md-block" style="text-decoration: underline">
            <fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour
        </a>
        <div class="page-inner">
            <div class="page-title">
                <div class="row d-md-none">
                    <div class="col-12 pr-0">
                        <h2 class="breadcrumb-header">
                            <a href="espace-recruteur/informations-personnelles" class="text-dark" style="text-decoration: underline">
                                <span style="font-size: 1rem !important; float: left; font-weight: normal"
                                    ><fa-icon [icon]="arrowLeft" class="ml-2"></fa-icon> Retour</span
                                > </a
                            ><span>Informations complémentaires</span>
                        </h2>
                    </div>
                </div>
                <h2 class="breadcrumb-header d-none d-md-block">Informations complémentaires</h2>
            </div>
            <div class="page-sub-title">
                <h2 class="breadcrumb-sub">Gardez vos informations à jour</h2>
            </div>

            <h5 style="color: red !important; font-size: 1.1rem !important">
                Aucune information nominative et coordonnées de contact (adresse, téléphone, email, site Internet) ne doit être mentionnée
                dans le champ "Activité(s)..." sous peine de voir votre compte bloqué.
            </h5>
            <div class="inscription-form">
                <form action="">
                    <div class="inscription-select d-md-flex justify-content-between align-items-center mb-4">
                        <div class="inscription-selectbox">
                            <div class="ins-select d-flex align-items-center">
                                <img src="./assets/img/org.png" alt="org" />
                                <h6>Type d’organisme</h6>
                                <mdb-error class="ml-2" *ngIf="this.error.typeEntreprise" style="position: static">Requis</mdb-error>
                            </div>

                            <mdb-select-2
                                name="typeEntreprise"
                                class="bottom-select"
                                [(ngModel)]="recruteur.typeEntreprise"
                                [outline]="true"
                            >
                                <mdb-select-option *ngFor="let option of typesEntreprises" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </div>
                        <div class="inscription-selectbox">
                            <div class="ins-select d-flex align-items-center">
                                <img src="./assets/img/employee.png" alt="employee" />
                                <h6>Nombre de salariés</h6>
                                <mdb-error class="ml-2" *ngIf="this.error.nombreSalarie" style="position: static">Requis</mdb-error>
                            </div>
                            <mdb-select-2 name="nombreSalarie" class="bottom-select" [(ngModel)]="recruteur.nombreSalarie" [outline]="true">
                                <mdb-select-option *ngFor="let option of workersNumbers" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </div>
                        <div class="inscription-selectbox">
                            <div class="ins-select d-flex align-items-center">
                                <img src="./assets/img/activity.png" alt="activity" />
                                <h6>
                                    Activités spécifiques
                                    <span>(choix multiples)</span>
                                </h6>
                            </div>
                            <mdb-select-2
                                name="activiteSpecifique"
                                class="bottom-select"
                                (valueChange)="setActs($event)"
                                [(ngModel)]="recruteur.activiteSpecifique"
                                [multiple]="true"
                                [outline]="true"
                            >
                                <mdb-select-option *ngFor="let option of activities" [value]="option.value">{{
                                    option.label
                                }}</mdb-select-option>
                            </mdb-select-2>
                        </div>
                    </div>
                    <div class="inscription-row">
                      <app-secteur class='d-flex flex-wrap' [showHelp]='true' [(data)]='recruteur.secteurEntreprise' [typeSecteur]='"Entreprise"'>
                        <label for="secteurEntreprise"
                        >Secteur(s) d'activité de votre organisme * <small>Ce champ est multiple</small></label
                        >
                        <span
                          for="secteurEntreprise"
                          class="error"
                          [ngStyle]="{
                                            display: this.error.secteurEntreprise ? 'block' : 'none'
                                        }"
                        >Obligatoire</span
                        >
                      </app-secteur>
                    </div>
                    <div class="form-group inscription-row">
                        <label
                            for="siteInternet"
                            [ngClass]="{
                                'fl-label-empty': this.recruteur.siteInternet === '',
                                'fl-label-not-empty': this.recruteur.siteInternet !== ''
                            }"
                            >Site Internet</label
                        >

                        <input
                            type="text"
                            name="siteInternet"
                            id="siteInternet"
                            class="form-control col-12 col-md-6"
                            [(ngModel)]="this.recruteur.siteInternet"
                        />
                    </div>
                    <div class="form-group inscription-row">
                        <label for="activiteTexte" style="color: black !important; font-weight: bold" class="mb-2"
                            >Activité(s) détaillée(s) de votre organisme </label
                        >
                        <span
                            for="activiteTexte"
                            class="error"
                            [ngStyle]="{
                                display: this.error.activiteTexte ? 'block' : 'none'
                            }"
                            >Obligatoire</span
                        >
                        <textarea
                            style="height: auto"
                            name="activiteTexte"
                            id="activiteTexte"
                            class="form-control mt-2 pt-2"
                            [(ngModel)]="this.recruteur.activiteTexte"
                            rows="4"
                        ></textarea>
                    </div>
                </form>
            </div>

            <div class="d-flex justify-content-center" style="position: relative">
                <p class="mention-obligatoire" style="color: red !important">* Obligatoire</p>
            </div>
            <div class="d-flex float-right mb-3">
                <button mdbBtn class="text-center" [rounded]="true" color="warning" size="lg" (click)="sendComplementEntreprise()">
                    Valider
                </button>
            </div>
        </div>
    </div>
</div>
<app-site-footer></app-site-footer>
