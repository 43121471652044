import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../services/global.service';
import { ToastService } from '../../../components/mdb/pro/alerts';

@Component({
    selector: 'app-liste-metiers',
    templateUrl: './liste-metiers.component.html',
    styleUrls: [
        './liste-metiers.component.css',
        './../../../../assets/css/administration/space.min.css',
        './../../../../assets/css/administration/style.css',
        './../../../../assets/css/administration/jquery.datatables.min.css',
    ],
})
export class ListeMetiersComponent implements OnInit {
    type: string | null = '';
    page = 0;
    nbParPage = 10;
    nombreTotal = 0;
    nombrePage = 0;
    pagination: Array<number> = [];
    premierPoint = false;
    deuxiemePoint = false;
    tri = 'dateCreation';
    sens = 'desc';
    sensClass = 'sorting_desc';
    recherche = '';
    metiers: any = [];
    colspanUn = 10;
    colspanDeux = 2;
    nouveauMetier = {
        codeMetier: '',
        codeOgr: '',
        groupePrincipal: '',
        groupeSecondaire: '',
        nomMetier: '',
        groupeSecondaireErr: false,
        nomMetierErr: false,
        groupePrincipalErr: false,
        codeOgrErr: false,
        codeMetierErr: false,
    };
    constructor(
        private route: ActivatedRoute,
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private toastrService: ToastService
    ) {}
    ngOnInit(): void {
        this.getMetiers();
    }
    changeNombre(): void {
        this.page = 0;
        this.getMetiers();
    }
    changePage(page: number): void {
        this.page = page;
        this.getMetiers();
    }
    getMetiers(): void {
        this.premierPoint = false;
        this.deuxiemePoint = false;
        this.httpClient
            .post<any>(
                this.globalService.url + '/metiers/all/' + this.page + '/' + this.nbParPage,
                {
                    recherche: this.recherche,
                    type: this.type,
                    tri: this.tri,
                    sens: this.sens,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.metiers = response.metiers;
                        this.nombreTotal = response.nb;
                        const nombrePage = Math.ceil(this.nombreTotal / this.nbParPage) - 1;
                        this.nombrePage = nombrePage;
                        this.pagination = [];
                        if (this.nombrePage > 10) {
                            if (this.page < 3) {
                                this.premierPoint = true;
                            } else if (this.page >= this.nombrePage - 2) {
                                this.deuxiemePoint = true;
                            } else if (this.page >= 3 && this.page < this.nombrePage - 2) {
                                this.premierPoint = true;
                                this.deuxiemePoint = true;
                            }
                        }
                        if (this.premierPoint && this.deuxiemePoint) {
                            this.pagination.push(0);
                            if (this.nombrePage >= 2) {
                                this.pagination.push(1);
                            }
                            if (this.nombrePage >= 3 && this.page !== 3) {
                                this.pagination.push(2);
                            }
                            if (this.nombrePage >= this.page) {
                                this.pagination.push(-1);
                                this.pagination.push(this.page - 1);
                            }
                            if (this.nombrePage >= this.page + 1) {
                                this.pagination.push(this.page);
                            }
                            if (this.nombrePage >= this.page + 2) {
                                this.pagination.push(this.page + 1);
                            }
                            if (this.nombrePage - 2 >= this.page) {
                                this.pagination.push(-1);
                                if (this.page + 1 !== this.nombrePage - 2) {
                                    this.pagination.push(this.nombrePage - 2);
                                }
                            }
                            if (this.nombrePage - 1 >= this.page) {
                                this.pagination.push(this.nombrePage - 1);
                                this.pagination.push(this.nombrePage);
                            }
                        } else if (!this.premierPoint && !this.deuxiemePoint) {
                            for (let p = 0; p <= this.nombrePage; p++) {
                                this.pagination.push(p);
                            }
                        } else {
                            for (let p = 0; p <= 2; p++) {
                                this.pagination.push(p);
                            }
                            if (this.premierPoint || this.deuxiemePoint) {
                                this.pagination.push(-1);
                            }
                            for (let p = this.nombrePage - 2; p <= this.nombrePage; p++) {
                                this.pagination.push(p);
                            }
                        }
                    } else {
                        this.metiers = [];
                        this.pagination = [];
                    }
                },
                () => {
                    this.metiers = [];
                    this.pagination = [];
                }
            );
    }
    download(): void {
        this.httpClient
            .post<any>(
                this.globalService.url + '/metiers/exporter',
                {
                    recherche: this.recherche,
                    type: this.type,
                    tri: this.tri,
                    sens: this.sens,
                },
                {}
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        window.open(this.globalService.url + '/documents/excel/' + response.filename + '.xlsx');
                    } else {
                        alert('Erreur lors de la modification');
                    }
                },
                () => {}
            );
    }
    changeSens(choix: string): void {
        if (this.tri !== choix) {
            this.tri = choix;
            this.sens = 'desc';
            this.sensClass = 'sorting_desc';
        } else {
            if (this.sens === 'desc') {
                this.sens = 'asc';
                this.sensClass = 'sorting_asc';
            } else {
                this.sens = 'desc';
                this.sensClass = 'sorting_desc';
            }
        }
        this.getMetiers();
    }

    ajouter(): void {
        if (this.nouveauMetier.codeMetier === '') {
            this.nouveauMetier.codeMetierErr = true;
            return;
        }

        if (this.nouveauMetier.codeOgr === '') {
            this.nouveauMetier.codeOgrErr = true;
            return;
        }
        if (this.nouveauMetier.groupePrincipal === '') {
            this.nouveauMetier.groupePrincipalErr = true;
            return;
        }
        if (this.nouveauMetier.groupeSecondaire === '') {
            this.nouveauMetier.groupeSecondaireErr = true;
            return;
        }
        if (this.nouveauMetier.nomMetier === '') {
            this.nouveauMetier.nomMetierErr = true;
            return;
        }
        this.httpClient
            .post(this.globalService.url + '/metiers/add', {
                codeMetier: this.nouveauMetier.codeMetier,
                codeOgr: this.nouveauMetier.codeOgr,
                groupePrincipal: this.nouveauMetier.groupePrincipal,
                groupeSecondaire: this.nouveauMetier.groupeSecondaire,
                nomMetier: this.nouveauMetier.nomMetier,
            })
            .subscribe(
                (response) => {
                    this.getMetiers();
                    this.toastrService.info('Métier ajouté', '', { opacity: 1 });
                    this.nouveauMetier = {
                        codeMetier: '',
                        codeOgr: '',
                        groupePrincipal: '',
                        groupeSecondaire: '',
                        nomMetier: '',
                        groupeSecondaireErr: false,
                        nomMetierErr: false,
                        groupePrincipalErr: false,
                        codeOgrErr: false,
                        codeMetierErr: false,
                    };
                },
                () => {}
            );
    }

    supprimer(id: string): void {
        if (confirm('Voulez-vous vraiment supprimer ce métier ?')) {
            this.httpClient.delete(this.globalService.url + '/metiers/' + id).subscribe(
                (response) => {
                    this.getMetiers();
                    this.toastrService.info('Métier supprimé', '', { opacity: 1 });
                },
                () => {}
            );
        }
    }
}
