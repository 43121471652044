<div class="row" style="width: 100%">
    <div class="col-xl-2 col-xs-2 sidebar-custom">
        <app-admin-header></app-admin-header>
    </div>
    <div class="col-xl-10 col-xs-10" style="padding-top: 25px">
        <div class="page-title">
            <h3 class="breadcrumb-header">Gestion des demandes métiers</h3>
        </div>
        <div id="main-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-white">
                        <div class="panel-body">
                            <div class="table-responsive">

                              <table class="display table dataTable">
                                <thead>
                                <tr>
                                  <th>Code Métier</th>
                                  <th>Code Ogr</th>
                                  <th>Groupe Principal</th>
                                  <th>Groupe Secondaire</th>
                                  <th>Nom</th>
                                  <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td class="sansPadding">
                                    <input
                                      class="form-control"
                                      type="text"
                                      [(ngModel)]="nouveauMetier.codeMetier"
                                      [ngStyle]="{
                                                        border: nouveauMetier.codeMetierErr ? '1px solid red' : ''
                                                    }"
                                    />
                                  </td>
                                  <td class="sansPadding" >
                                    <input
                                      class="form-control"
                                      type="text"
                                      [(ngModel)]="nouveauMetier.codeOgr"
                                      [ngStyle]="{
                                                        border: nouveauMetier.codeOgrErr ? '1px solid red' : ''
                                                    }"
                                    />
                                  </td>
                                  <td class="sansPadding">
                                    <input
                                      class="form-control"
                                      type="text"
                                      [(ngModel)]="nouveauMetier.groupePrincipal"
                                      [ngStyle]="{
                                                        border: nouveauMetier.groupePrincipalErr ? '1px solid red' : ''
                                                    }"
                                    />
                                  </td>
                                  <td class="sansPadding">
                                    <input
                                      class="form-control"
                                      type="text"
                                      [(ngModel)]="nouveauMetier.groupeSecondaire"
                                      [ngStyle]="{
                                                        border: nouveauMetier.groupeSecondaireErr ? '1px solid red' : ''
                                                    }"
                                    />
                                  </td>
                                  <td class="sansPadding">
                                    <input
                                      class="form-control"
                                      type="text"
                                      [(ngModel)]="nouveauMetier.nomMetier"
                                      [ngStyle]="{
                                                        border: nouveauMetier.nomMetierErr ? '1px solid red' : ''
                                                    }"
                                    />
                                  </td>
                                  <td class="sansPadding">
                                    <button class="btn btn-info" (click)="ajouter()">Ajouter</button>
                                  </td>
                                </tr>

                                </tbody>
                              </table>
                                <table class="display table dataTable mt-2">
                                    <thead>
                                        <tr>
                                          <th style='text-align: center'>Demande</th>
                                          <th style='text-align: center'>Champ</th>
                                          <th style='text-align: center'>Compte</th>
                                          <th style='text-align: center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let r of requests">
                                          <td>
                                            {{ r.request }}
                                          </td>
                                          <td>
                                            {{ r.extra }}
                                          </td>
                                          <td>
                                            {{ r.typeCompte }}
                                          </td>
                                          <td>
                                            <button (click)="supprimer(r._id)" class="btn btn-success">Traitée</button>
                                            <a
                                              style="color: white"
                                              target="_blank"
                                              routerLink="/administration/{{r.typeCompte === 'candidat' ? 'candidat' : 'recruteur'}}/{{ r.idCompte }}"
                                              class="btn ml-3 btn-primary"
                                            >Fiche</a
                                            >

                                          </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
